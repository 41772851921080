import { ReactNode } from "react";
import bg_xl from "assets/img/errorHeader_1200px.svg";
import bg_l from "assets/img/errorHeader_1024px.svg";
import bg_m from "assets/img/errorHeader_768px.svg";
import bg_s from "assets/img/errorHeader_362px.svg";
import { Box, makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    host: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backgroundImageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.down("sm")]: {
        backgroundImage: `url(${bg_s})`,
        width: "362px",
        height: "760px",
      },
      [theme.breakpoints.up("md")]: {
        backgroundImage: `url(${bg_m})`,
        width: "768px",
        height: "1024px",
      },
      [theme.breakpoints.up("lg")]: {
        backgroundImage: `url(${bg_l})`,
        width: "1024px",
        height: "920px",
      },
      [theme.breakpoints.up("xl")]: {
        backgroundImage: `url(${bg_xl})`,
        width: "1200px",
        height: "920px",
      },
    },
    contentContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

export interface IErrorHostProps {
  content: ReactNode;
}

export const ErrorHost: React.FC<IErrorHostProps> = ({ content }) => {
  const classes = useStyles();
  return (
    <Box data-qatag="errorHost" id="errorHost" className={classes.host}>
      <Box
        data-qatag="errorHost.backgroundImageContainer"
        id="errorHost.backgroundImageContainer"
        className={classes.backgroundImageContainer}
      >
        <Box
          data-qatag="errorHost.contentContainer"
          id="errorHost.contentContainer"
          className={classes.contentContainer}
        >
          {content}
        </Box>
      </Box>
    </Box>
  );
};
