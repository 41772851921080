import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Typography, Box, Grid, Link } from "@material-ui/core";
import { Select, SelectList } from "solveiq.designsystem";
import { LabelIndicator, DotColors } from "solveiq.designsystem";
import { SearchTextField } from "solveiq.designsystem";
import { AppButton as Button, AppButtonStyles } from "solveiq.designsystem";
import { useMatchTabletVertical } from "solveiq.designsystem";
import styles from "./Form.module.css";
import {
  IManufacturers,
  IProductLine,
  IModel,
  IUpdateIntelligenceResponse,
} from "../types";
import {
  IntelligenceTypes,
  MatchTypes,
} from "model/messaging/payloads/IUpdateMachineIntelligencePayload";

import {
  getMachineBrand,
  getProductLine,
  getProductModel,
  fetchManufacturers,
  fetchProductLine,
  fetchProductModel,
  //Motherboards intelligence
  getMotherboards,
  getMotherboardFamilies,
  getMotherboardModels,
  fetchMotherboards,
  fetchMotherboardFamilies,
  fetchMotherboardModel,
  //Agent service
  updateMachineIntelligence,
  //Agent service response
  //getIntelligenceResponse,
  submitMachineIntelligence,
} from "../MachinePickerSlice";
import { RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core/styles";

export interface IForm {
  /* Default option for Intelligence Type Options for select component */
  defaultIntelligence?: IntelligenceTypes;
  /* Select Your Machine Brand */
  machineBrandSelector?: (state: RootState) => IManufacturers[];
  /* Select Your Product Model */
  productModelSelector?: (state: RootState) => IModel[];
  /* Select Your Product Line */
  productLineSelector?: (state: RootState) => IProductLine[];
  /* Select Your Motherboard */
  motherboardsSelector?: (state: RootState) => IManufacturers[];
  /* Select Motherboard Families */
  motherboardFamiliesSelector?: (state: RootState) => IProductLine[];
  /* Select Your Motherboard Model */
  motherboardModelSelector?: (state: RootState) => IModel[];
  /* Response to update Intelligence */
  updateIntelligenceSelector?: (
    state: RootState
  ) => IUpdateIntelligenceResponse;
  /* Default option for Select Your Machine Brand */
  defaultMachineBrand?: string;
  /* Default option for Select Your Product Line */
  defaultProductLine?: string;
  /* Default option for Select Your Product Model */
  defaultProductModel?: string;
}

/* Custom Styles */
const useStyles = makeStyles((theme: Theme) => ({
  universal: {
    display: "none",
  },
  error: {
    color: "#BE2222",
    marginLeft: 8,
  },
  title: {
    marginLeft: theme.spacing(19),
    marginRight: theme.spacing(19),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      fontSize: "14px",
    },
  },
  horizontalBar: {
    borderBottom: "1px solid #E5E5E5",
  },
  selectListItem: {},
}));

const Form: React.FC<IForm> = ({
  machineBrandSelector = (state: RootState) => getMachineBrand(state),
  productLineSelector = (state: RootState) => getProductLine(state),
  productModelSelector = (state: RootState) => getProductModel(state),
  motherboardsSelector = (state: RootState) => getMotherboards(state),
  motherboardFamiliesSelector = (state: RootState) =>
    getMotherboardFamilies(state),
  motherboardModelSelector = (state: RootState) => getMotherboardModels(state),
  // updateIntelligenceSelector = (state: RootState) =>
  //   getIntelligenceResponse(state),
  defaultIntelligence = IntelligenceTypes.OEM,
  defaultMachineBrand,
  defaultProductLine,
  defaultProductModel,
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "machine.picker.form";
  /* Responsive web design */
  const matchTablet = useMatchTabletVertical();
  /* Redux dispatcher */
  const dispatch = useDispatch();
  /* Custom styles to overwrite theme */
  const classes = useStyles();
  /* OEM Intelligence | Motherboard intelligence | Universal intelligence */
  const [intelligence, setIntelligence] = useState<IntelligenceTypes>(
    defaultIntelligence
  );
  /* Error */
  const [error, setError] = useState("");

  useEffect(() => {
    if (intelligence === IntelligenceTypes.OEM) {
      dispatch(fetchManufacturers());
    } else if (intelligence === IntelligenceTypes.MB) {
      dispatch(fetchMotherboards());
    }
    setSelectedProductLine("");
    setSelectedProductModel("");
  }, [dispatch, intelligence]);

  // Clear all if the intelligence changes
  useEffect(() => {
    setSelectedMachineBrand("");
    setSelectedProductLine("");
    setSelectedProductModel("");
  }, [intelligence]);

  /* Select Your Machine Brand */
  const machineBrand = useSelector<RootState>(
    machineBrandSelector
  ) as IManufacturers[];

  /* Select Your Motherboard Brand */
  const motherboards = useSelector<RootState>(
    motherboardsSelector
  ) as IManufacturers[];

  // Default value for Brand or MB
  useEffect(() => {
    setSelectedMachineBrand(defaultMachineBrand);
  }, [defaultMachineBrand, machineBrand.length, motherboards.length]);

  /* Select Your Product Line */
  const productLine = useSelector<RootState>(
    productLineSelector
  ) as IProductLine[];

  /* Select Your Motherboard Family */
  const motherboardFamily = useSelector<RootState>(
    motherboardFamiliesSelector
  ) as IProductLine[];

  // Default value for Family
  useEffect(() => {
    setSelectedProductLine(defaultProductLine);
  }, [defaultProductLine, productLine.length, motherboardFamily.length]);

  /* Select Your Product Line */
  const productModel = useSelector<RootState>(productModelSelector) as IModel[];

  /* Select Your Product Line */
  const motherboardModel = useSelector<RootState>(
    motherboardModelSelector
  ) as IModel[];

  // Default value for Model
  useEffect(() => {
    setSelectedProductModel(defaultProductModel);
  }, [defaultProductModel, productModel.length, motherboardModel.length]);

  // State for selected Machine Brand
  const [selectedMachineBrand, setSelectedMachineBrand] = useState<
    string | number | undefined
  >(defaultMachineBrand);

  // State for selected Machine Brand
  const [selectedProductLine, setSelectedProductLine] = useState<
    string | number | undefined
  >("");

  // State for selected Product Model
  const [selectedProductModel, setSelectedProductModel] = useState<
    string | number | undefined
  >("");

  // useEffect to retrieve Product Line
  useEffect(() => {
    if (selectedMachineBrand) {
      // Depending on the intelligence selected
      if (intelligence === IntelligenceTypes.OEM) {
        dispatch(fetchProductLine(selectedMachineBrand?.toString()));
      } else if (intelligence === IntelligenceTypes.MB) {
        dispatch(fetchMotherboardFamilies(selectedMachineBrand?.toString()));
      }
      // Reset model value
      setSelectedProductModel("");
    }
  }, [dispatch, intelligence, selectedMachineBrand]);

  // useEffect to retrieve Product Model
  useEffect(() => {
    if (selectedMachineBrand && selectedProductLine) {
      if (intelligence === IntelligenceTypes.OEM) {
        dispatch(
          fetchProductModel({
            manufacturerId: selectedMachineBrand?.toString(),
            familyId: selectedProductLine?.toString(),
          })
        );
      } else if (intelligence === IntelligenceTypes.MB) {
        dispatch(
          fetchMotherboardModel({
            motherboardId: selectedMachineBrand?.toString(),
            familyId: selectedProductLine?.toString(),
          })
        );
      }
    }
  }, [dispatch, intelligence, selectedMachineBrand, selectedProductLine]);

  // Clear error state
  useEffect(() => {
    if (selectedMachineBrand && selectedProductLine && selectedProductModel) {
      setError("");
    }
  }, [selectedMachineBrand, selectedProductLine, selectedProductModel]);

  // <FormattedMessage id="IntelligenceOptionOne" defaultMessage="Universal Match Intelligence (Only recommend me universal drivers)">
  // {(text) => (<option value="Universal">{text}</option>)}
  // </FormattedMessage>
  // <FormattedMessage id="IntelligenceOptionTwo" defaultMessage="OEM Match Intelligence (My computer is a Dell, HP, etc. - Prioritize OEM Drivers)" >
  // {(text) => (<option value="OEM">{text}</option>)}
  // </FormattedMessage>
  // <FormattedMessage
  // id="IntelligenceOptionThree"
  // defaultMessage="Motherboard Match Intelligence (My computer is customer built - Prioritize motherboard drivers)" >
  // {(text) => (<option value="Motherboard">{text}</option>)}
  // </FormattedMessage>
  const messages = defineMessages({
    universalMatch: {
      id: "machinePicker.messages.universalMatch",
      defaultMessage:
        "Universal Match Intelligence (Only recommend me universal drivers)",
      description: "select message for universal match intelligence",
    },
    oemMatch: {
      id: "machinePicker.messages.oemMatch",
      defaultMessage:
        "OEM Match Intelligence (My computer is a Dell, HP, etc. - Prioritize OEM Drivers)",
      description: "select message for OEM match intelligence",
    },
    motherboardMatch: {
      id: "machinePicker.messages.motherboardMatch",
      defaultMessage:
        "Motherboard Match Intelligence (My computer is customer built - Prioritize motherboard drivers)",
      description: "select message for motherboard match intelligence",
    },
  });

  return (
    <div data-qatag="container" className={styles.container}>
      <div data-qatag="content" className={styles.content}>
        {/* Title */}
        <Box data-qatag="titleContainer">
          <Typography
            data-qatag="textContainer"
            align="center"
            variant="h6"
            className={classes.title}
            color="primary"
          >
            <FormattedMessage
              data-qatag="title"
              id={`${prefix}.title`}
              defaultMessage="However, a more specific match may be available. Please use the form below to select your system."
            />
          </Typography>
        </Box>

        {/* Intelligence Type */}
        <Grid
          data-qatag="gridCentered"
          sm={12}
          md={11}
          lg={9}
          xl={8}
          className={styles.gridCentered}
        >
          <Box data-qatag="intelligenceTypeContainer">
            <Typography
              data-qatag="intelligenceTypeText"
              align={"center"}
              variant="h6"
            >
              <FormattedMessage
                data-qatag="intelligenceType"
                id={`${prefix}.intelligence.type`}
                defaultMessage="Intelligence Type:"
              />
            </Typography>
          </Box>
          <Box data-qatag="intelligenceSelectContainer">
            <Select
              data-qatag="intelligenceSelect"
              options={[
                {
                  value: IntelligenceTypes.OEM,
                  label: formatMessage(messages.oemMatch),
                },
                {
                  value: IntelligenceTypes.MB,
                  label: formatMessage(messages.motherboardMatch),
                },
                {
                  value: IntelligenceTypes.None,
                  label: formatMessage(messages.universalMatch),
                },
              ]}
              noneOption={false}
              onChange={(intelligenceSelect) =>
                setIntelligence(
                  IntelligenceTypes[intelligenceSelect as IntelligenceTypes]
                )
              }
              defaultValue={defaultIntelligence}
            />
          </Box>
        </Grid>

        {/* Divider */}
        <Grid
          data-qatag="gridCentered"
          sm={12}
          md={11}
          lg={9}
          xl={11}
          className={styles.gridCentered}
        >
          <Box data-qatag="verticalBar" mt={4}>
            <div data-qatag="horizontalBar" className={classes.horizontalBar} />
          </Box>
        </Grid>

        {/* Select your machine brand */}
        <Grid
          data-qatag="machineBrandContainer"
          container
          spacing={0}
          justify="center"
          className={
            intelligence === IntelligenceTypes.None ? classes.universal : ""
          }
        >
          <Grid
            data-qatag="gridCentered"
            sm={12}
            md={8}
            lg={4}
            xl={4}
            className={styles.gridCentered}
          >
            <Box data-qatag="selectListItem" className={styles.selectListItem}>
              <Box data-qatag="machineBrandContainer" mt={3}>
                <LabelIndicator
                  data-qatag="machineBrandLabel"
                  dotColor={
                    (intelligence === IntelligenceTypes.OEM
                      ? machineBrand
                      : motherboards
                    )?.length > 0
                      ? DotColors.Green
                      : DotColors.Red
                  }
                  text={formatMessage({
                    id: `${prefix}.machine.brand`,
                    defaultMessage: "Select Your Machine Brand",
                  })}
                />
              </Box>
              <Box data-qatag="machineBrandSelectContainer" mt={3}>
                <SelectList
                  style={{ maxHeight: "240px", minHeight: "240px" }}
                  data-qatag="machineBrandSelect"
                  onChange={(brand) => {
                    setSelectedMachineBrand(brand);
                    setSelectedProductLine("");
                    setSelectedProductModel("");
                  }}
                  options={(intelligence === IntelligenceTypes.OEM
                    ? machineBrand
                    : motherboards
                  )?.map((brand) => ({
                    value: brand?.key?.toString(),
                    label: brand.name,
                  }))}
                  defaultValue={defaultMachineBrand}
                  value={selectedMachineBrand}
                />
              </Box>
            </Box>
          </Grid>

          {/* Select your product line */}
          <Grid
            data-qatag="gridCentered"
            sm={12}
            md={8}
            lg={4}
            xl={4}
            className={styles.gridCentered}
          >
            <Box data-qatag="selectListItem" className={classes.selectListItem}>
              <Box data-qatag="labelProductLineContainer" mt={3}>
                <LabelIndicator
                  data-qatag="labelProductLine"
                  dotColor={
                    (intelligence === IntelligenceTypes.OEM
                      ? productLine
                      : motherboardFamily
                    )?.length > 0 && selectedMachineBrand
                      ? DotColors.Green
                      : DotColors.Red
                  }
                  text={formatMessage({
                    id: `${prefix}.product.line`,
                    defaultMessage: "Select Your Product Line",
                  })}
                />
              </Box>
              <Box data-qatag="selectProductLineContainer" mt={3}>
                <SelectList
                  style={{ maxHeight: "240px", minHeight: "240px" }}
                  data-qatag="selectProductLine"
                  onChange={(line) => {
                    setSelectedProductLine(line);
                    setSelectedProductModel("");
                  }}
                  options={
                    selectedMachineBrand
                      ? (intelligence === IntelligenceTypes.OEM
                          ? productLine
                          : motherboardFamily
                        ).map((line) => ({
                          label: line?.name,
                          value: line?.key?.toString(),
                        }))
                      : []
                  }
                  defaultValue={defaultProductLine}
                  value={selectedProductLine}
                />
              </Box>
            </Box>
          </Grid>

          {/* Select your product model */}
          <Grid
            data-qatag="gridCentered"
            sm={12}
            md={8}
            lg={4}
            xl={4}
            className={styles.gridCentered}
          >
            <Box data-qatag="selectListItem" className={styles.selectListItem}>
              <Box data-qatag="labelProductModelContainer" mt={3}>
                <LabelIndicator
                  data-qatag="labelProductModel"
                  dotColor={
                    (intelligence === IntelligenceTypes.OEM
                      ? productModel
                      : motherboardModel
                    )?.length > 0 && selectedProductLine
                      ? DotColors.Green
                      : DotColors.Red
                  }
                  text={formatMessage({
                    id: `${prefix}.product.model`,
                    defaultMessage: "Select Your Product Model",
                  })}
                />
              </Box>
              <Box data-qatag="selectProductModelContainer" mt={3}>
                <SelectList
                  style={{ maxHeight: "240px", minHeight: "240px" }}
                  data-qatag="selectProductModel"
                  onChange={setSelectedProductModel}
                  options={
                    selectedProductLine
                      ? (intelligence === IntelligenceTypes.OEM
                          ? productModel
                          : motherboardModel
                        ).map((line) => ({
                          label: line?.name,
                          value: line?.key?.toString(),
                        }))
                      : []
                  }
                  defaultValue={defaultProductModel}
                  value={selectedProductModel}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Send us email */}
        <Box data-qatag="emailContainer" mt={3}>
          <Typography data-qatag="emailText" align="center" variant="body2">
            <FormattedMessage
              data-qatag="emailLabel"
              id={`${prefix}.cant.find`}
              defaultMessage="Can't find your computer in the boxes above? "
            />
            <Link
              data-qatag="emailLink"
              href="#"
              onClick={() => {
                // universal intelligence
                const payload = {
                  intelligenceType: IntelligenceTypes.None,
                  matchType: MatchTypes.None,
                  manufacturerID: 0,
                  manufacturer: "",
                  familyID: 0,
                  family: "",
                  seriesIsLaptop: false,
                  modelID: 0,
                  model: "",
                  modelIsLaptop: false,
                };

                dispatch(submitMachineIntelligence());
                dispatch(updateMachineIntelligence(payload));
              }}
              color="secondary"
            >
              <FormattedMessage
                data-qatag="emailText"
                id={`${prefix}.sendInfo`}
                defaultMessage="Send us your machine info."
              />
            </Link>
          </Typography>
        </Box>

        {/* Scan now button */}
        <Box data-qatag="scanButtonContainer" mt={2} textAlign={"center"}>
          {error && (
            <Typography
              data-qatag="error"
              variant="body1"
              className={classes.error}
            >
              {error}
            </Typography>
          )}
          <Button
            data-qatag="customButton"
            buttonStyle={AppButtonStyles.Green}
            className={styles.customButton}
            onClick={() => {
              // Universal Intelligence
              if (intelligence === IntelligenceTypes.None) {
                dispatch(
                  updateMachineIntelligence({
                    intelligenceType: intelligence,
                    manufacturerID: 0,
                    manufacturer: "",
                    familyID: 0,
                    family: "",
                    seriesIsLaptop: false,
                    modelID: 0,
                    model: "",
                    modelIsLaptop: false,
                    matchType: MatchTypes.None,
                  })
                );
                return;
              }

              if (
                !selectedMachineBrand ||
                !selectedProductLine ||
                !selectedProductModel
              ) {
                setError("Selection incomplete");
                return;
              }

              const manufacturer = (IntelligenceTypes.OEM
                ? machineBrand
                : motherboards
              )?.filter(
                (brand) => brand.key === Number(selectedMachineBrand)
              )?.[0];

              const series = (IntelligenceTypes.OEM
                ? productLine
                : motherboardFamily
              )?.filter(
                (family) => family.key === Number(selectedProductLine)
              )?.[0];

              const model = (IntelligenceTypes.OEM
                ? productModel
                : motherboardModel
              )?.filter(
                (model) => model.key === Number(selectedProductModel)
              )?.[0];

              dispatch(
                updateMachineIntelligence({
                  intelligenceType: intelligence,
                  manufacturerID: selectedMachineBrand as number,
                  manufacturer: manufacturer?.name,
                  familyID: selectedProductLine as number,
                  family: series?.displayName,
                  seriesIsLaptop: series?.isLaptop,
                  modelID: selectedProductModel as number,
                  model: model?.displayName,
                  modelIsLaptop: model?.isLaptop,
                  matchType: MatchTypes.Model,
                })
              );
            }}
          >
            <Typography data-qatag="scanText" align="center" variant="body2">
              <FormattedMessage
                data-qatag="scanTextLabel"
                id={`${prefix}.scan.now`}
                defaultMessage="Scan Now"
              />
            </Typography>
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default Form;
