import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ScanStages from "./ScanStages";
import {
  // selectIsScanAvailable,
  selectProgress,
  startAllScans,
} from "./ScanSlice";
import {
  Modal,
  EModalStates,
  ModalStateContent,
  ProgressBar,
  useInterval,
  Alert,
  AlertIcon,
} from "solveiq.designsystem";
import styles from "./ScanScreen.module.css";
import { useHistory } from "react-router";
import { RootState } from "app/store";

const prefix = "scanningScreen.view";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 500,
      color: theme.palette.primary.light,
    },
    percentage: {
      color: theme.palette.secondary.dark,
    },
    subtitle: {
      fontWeight: 500,
    },
    statsElement: {
      fontWeight: 900,
    },
  })
);

export interface IScanSCreenProps {
  scanProgressSelector?: (state: RootState) => number | null;
}

const ScanScreen: React.FC<IScanSCreenProps> = ({
  scanProgressSelector = selectProgress,
}) => {
  const [truncatedProgress, setTruncatedProgress] = useState(0);
  const [secondsCount, setSecondsCount] = useState(0);
  const [elapsedTime, setElapsedTime] = useState("00:00:00");
  const [isScanComplete, setIsScanComplete] = useState(false);

  const progress = useSelector(scanProgressSelector) || 0;
  // const isScanAvailable = useSelector(selectIsScanAvailable);
  //const status = useSelector(selectScanStatus);
  const dispatch = useDispatch();
  const history = useHistory();

  const { formatMessage } = useIntl();
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  useEffect(() => {
    // if (history.location.search === "auto") {
    //   dispatch(startAllScans());
    // } else {
    //   dispatch(fetchHardwareScan());
    //   if (status === ReducerStatus.Succeeded)
    //     if (isScanAvailable) {
    //       history.push("/status");
    //     } else {
    //       dispatch(startAllScans());
    //     }
    // }
    dispatch(startAllScans());
  }, [dispatch]);

  useInterval(() => {
    setSecondsCount((prevState) => prevState + 1);
    setElapsedTime(getElapsedTime(secondsCount));
  }, 1000);

  useEffect(() => {
    setTruncatedProgress(truncate(progress));
  }, [progress]);

  useEffect(() => {
    setIsScanComplete(progress >= 1.0);
  }, [progress]);

  function goToSystemStatus() {
    history.push("/status");
  }

  return (
    <div data-qatag="container" className={styles.container}>
      <Alert
        data-qatag="scanCompleteAlert"
        approveHandler={goToSystemStatus}
        buttonText="OK"
        closeHandler={goToSystemStatus}
        icon={AlertIcon.Success}
        isOpen={isScanComplete}
        title={formatMessage({ id: `${prefix}.successModal.title` })}
        text={formatMessage({ id: `${prefix}.successModal.content` })}
      />
      <header data-qatag="header" className={styles.header}>
        <Typography data-qatag="title" variant="h2" className={classes.title}>
          {formatMessage({ id: `${prefix}.header.title` })}...{" "}
          <span data-qatag="percentage" className={classes.percentage}>
            {getProcessPercentage(truncatedProgress)}%
          </span>
        </Typography>
        <Box data-qatag="subtitleContainer" mt={2}>
          <Typography
            data-qatag="subtitle"
            variant="h6"
            className={classes.subtitle}
          >
            Once this scan is finished you will be guided through the protection
            and optimization features.
          </Typography>
        </Box>
      </header>

      <div data-qatag="progressBar" className={styles.progressBar}>
        <ProgressBar
          data-qatag="progressBar"
          fullWidth
          value={truncatedProgress}
        />
      </div>

      <ScanStages data-qatag="scanStages" progress={truncatedProgress} />

      <div data-qatag="stats" className={styles.stats}>
        <div data-qatag="statsElement" className={styles.statsElement}>
          <Typography data-qatag="typography" variant="body1">
            <span data-qatag="statsElement" className={classes.statsElement}>
              {formatMessage({ id: `${prefix}.stats.elapsed` })}
            </span>
            <span
              data-qatag="statsValue"
              data-testid="elapsedTime"
              className={styles.statsValue}
            >
              {elapsedTime}
            </span>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ScanScreen;

/**
 * Returns an elapsed time in the format HH:MM:SS.
 * @param elapsed seconds
 */
export function getElapsedTime(elapsed: number): string {
  const hours = Math.floor(elapsed / 3600);
  const minutes = Math.floor((elapsed - hours * 3600) / 60);
  const seconds = elapsed - hours * 3600 - minutes * 60;

  return `${zeroFill(hours)}:${zeroFill(minutes)}:${zeroFill(seconds)}`;
}

/**
 * Adds a leading zero to have a double digit format.
 * @param value number to check if needs a leading zero.
 */
function zeroFill(value: number): string {
  const textValue = String(value);
  if (textValue.length === 1) {
    return "0" + value;
  }
  return textValue;
}

/**
 * Receives a value between 0.0 and 1.0 and
 * returns the percentage between 0% - 100%.
 * @param value number in range 0.0 - 1.0.
 */
function getProcessPercentage(value: number): number {
  return Math.round(value * 100);
}

/**
 * Truncates any value not between 0.0 and 1.0.
 * @param value number to truncate.
 */
function truncate(value: number): number {
  if (value < 0) {
    value = 0;
  } else if (value > 1) {
    value = 1;
  }
  return value;
}
