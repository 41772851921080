import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { RootState } from "app/store";
import { debounce, sortByDeviceClass } from "core/Helpers";
import Fuse from "fuse.js";
import { IDriverUpdateModel } from "model/driver/DriverUpdateModel";
import { IDeviceInfo } from "model/scan/DeviceInfo";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { SearchTextField } from "solveiq.designsystem";
import { DeviceEntry } from "./DeviceEntry";
import { selectDriverUpdateMap } from "./DriverSlice";
import { FilterBar } from "./FilterBar";
import { SearchBar } from "./SearchBar";
import { NoSearchResults } from "./NoSearchResults";

//#region Component Styles
const useMobileStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flexGrow: 1,
    },
    paper: {
      borderRadius: "0px",
    },
    search_root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: theme.spacing(2),
    },
    search_bar: {
      height: "42px",
      borderRadius: "30px",
      width: "333px",
      background: "#ffffff",
    },
    noSearchResults_container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  })
);

const useStyles = makeStyles((theme) =>
  createStyles({
    device_root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        margin: theme.spacing(0),
        borderRadius: "0px",
      },
    },
    filter_root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: "20px",
      paddingBottom: "20px",
      padding: "15px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    search_bar: {
      height: "42px",
      borderRadius: "30px",
      width: "443px",
      [theme.breakpoints.between("md", "lg")]: {
        width: "254px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "333px",
      },
    },

    componentRoot: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
  })
);
//#endregion Component Styles

//#region Component Props
export interface IDriverListProps {
  allDevices: IDeviceInfo[];
  devicesWithUpdates: IDeviceInfo[];
  /** Properties of the DeviceInfo model to use when searching for a device */
  keys: Array<keyof IDeviceInfo>;
  /** Hides/Shows the toggle filter */
  isDeviceFilterVisible: boolean;
  /**
   * Ignore filters (Issues|All) when searching. If `true` searches from All devices,
   * `false` searches from devices with Issues only. Default is `true`.
   */
  ignoreFiltersOnSearch?: boolean;
  statusMapSelector?: (state: RootState) => IDriverUpdateModel[];
}
//#endregion Component Props

//#region Component
export const DriverList: React.FC<IDriverListProps> = ({
  allDevices,
  devicesWithUpdates,
  keys,
  isDeviceFilterVisible,
  ignoreFiltersOnSearch = true,
  statusMapSelector = selectDriverUpdateMap,
}) => {
  const [searchText, setSearchText] = useState("");
  const [isFiltering, setIsFiltering] = useState(true);
  const [filteredDevices, setFilteredDevices] = useState<React.ReactElement[]>(
    devicesToSortedEntries(
      isFiltering ? devicesWithUpdates : allDevices,
      statusMapSelector
    )
  );

  useEffect(() => {
    if (devicesWithUpdates.length === 0) {
      setIsFiltering(false);
    }
  }, [devicesWithUpdates.length]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onDeviceFilterToggle = (isFiltering: boolean) => {
    setIsFiltering(isFiltering);
  };

  // Hide filters when searching if filters are set to be ignored
  const hideFiltersOnSearch =
    ignoreFiltersOnSearch && searchText.trim().length === 0;

  // Builds a new Fuse object to filter devices
  const devicesToFilter = useMemo(() => {
    const options = { ignoreLocation: true, includeScore: true, keys };
    if (isFiltering && !ignoreFiltersOnSearch) {
      return new Fuse(devicesWithUpdates, options);
    }
    return new Fuse(allDevices, options);
  }, [
    keys,
    isFiltering,
    ignoreFiltersOnSearch,
    allDevices,
    devicesWithUpdates,
  ]);

  // Memoized debounced function to set filtered devices
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedSetFilteredDevices = useCallback(
    debounce((values: React.ReactElement[]) => {
      setFilteredDevices(values);
    }, 300),
    [setFilteredDevices]
  );

  useEffect(() => {
    // User is not searching for devices
    if (searchText.trim().length === 0) {
      if (isFiltering) {
        memoizedSetFilteredDevices(
          devicesToSortedEntries(devicesWithUpdates, statusMapSelector)
        );
      } else {
        memoizedSetFilteredDevices(
          devicesToSortedEntries(allDevices, statusMapSelector)
        );
      }
    }
    // User is searching for devices
    else {
      memoizedSetFilteredDevices(
        devicesToFilter
          .search(searchText)
          .filter((item) => (item.score || 1) < 0.1)
          .map((element) => element.item)
          // Place first elements with updates available
          .sort((a, b) => {
            if (a.hasUpdateAvailable === b.hasUpdateAvailable) {
              return 0;
            } else if (a.hasUpdateAvailable) {
              return -1;
            } else {
              return 1;
            }
          })
          .map((d, index) => (
            <DeviceEntry
              data-qatag="device_list.device_entry"
              key={`${d.deviceID}_${index}`}
              device={d}
              statusMapSelector={statusMapSelector}
            />
          ))
      );
    }
  }, [
    searchText,
    isFiltering,
    allDevices,
    devicesWithUpdates,
    memoizedSetFilteredDevices,
    devicesToFilter,
    statusMapSelector,
  ]);

  const onSearchType = (searchValue: string) => {
    setSearchText(searchValue);
  };

  const isToggleFilterVisible = isDeviceFilterVisible && hideFiltersOnSearch;

  const classes = useStyles();
  const mobileClasses = useMobileStyles();

  return (
    <Box
      data-qatag="driver.device_list"
      id="driver.device_list"
      className={classes.componentRoot}
    >
      {isMobile ? (
        <Box
          data-qatag="driver.device_list.mobile.root"
          id="driver.device_list.mobile.root"
          className={mobileClasses.root}
        >
          <Paper
            data-qatag="driver.device_list.mobile.paper"
            id="driver.device_list.mobile.paper"
            elevation={0}
            className={mobileClasses.paper}
          >
            <Box
              data-qatag="driver.device_list.mobile.filter_root"
              id="driver.device_list.mobile.filter_root"
              className={classes.filter_root}
            >
              <FilterBar
                data-qatag="driver.device_list.filterbar"
                SetFilterInitially={isFiltering}
                isVisible={true}
                isDisabled={!isToggleFilterVisible}
                onAllSelected={() => {
                  onDeviceFilterToggle(false);
                }}
                onFilterSelected={() => {
                  onDeviceFilterToggle(true);
                }}
                style={{ width: "185px", height: "44px" }}
              />
            </Box>
          </Paper>

          <Box
            data-qatag="driver.device_list.mobile.search_root"
            id="driver.device_list.mobile.search_root"
            className={mobileClasses.search_root}
          >
            <SearchTextField
              data-qatag="driver.device_list.mobile.searchfield"
              containerClassName={mobileClasses.search_bar}
              placeholder={"Search Device"}
              onChange={onSearchType}
              value={searchText}
              displayDivider={false}
            />
          </Box>

          {filteredDevices.length > 0 ? (
            filteredDevices
          ) : (
            <NoSearchResults data-qatag="driver.device_list.mobile.no_search_results" />
          )}
        </Box>
      ) : (
        <Paper
          data-qatag="driver.device_list.root"
          id="driver.device_list.root"
          elevation={0}
          className={classes.device_root}
        >
          <Box
            data-qatag="driver.device_list.filter_root"
            id="driver.device_list.filter_root"
            className={classes.filter_root}
          >
            <FilterBar
              data-qatag="driver.device_list.filterbar"
              SetFilterInitially={isFiltering}
              isVisible={true}
              isDisabled={!isToggleFilterVisible}
              onAllSelected={() => {
                onDeviceFilterToggle(false);
              }}
              onFilterSelected={() => {
                onDeviceFilterToggle(true);
              }}
              style={{
                width: "260px",
                height: "44px",
              }}
            />

            <SearchBar
              data-qatag="driver.device_list.searchbar"
              onSearchType={onSearchType}
              value={searchText}
            />
          </Box>

          {filteredDevices.length > 0 ? (
            filteredDevices
          ) : (
            <NoSearchResults data-qatag="driver.device_list.no_search_results" />
          )}
        </Paper>
      )}
    </Box>
  );
};
//#endregion Component

/**
 * Sorts devices by class and transforms each to a Device component
 * @param devices List of devices
 */
const devicesToSortedEntries = (
  devices: IDeviceInfo[],
  statusMapSelector: (state: RootState) => IDriverUpdateModel[]
) =>
  [...devices]
    .sort(sortByDeviceClass)
    .map((d, index) => (
      <DeviceEntry
        data-qatag="driver.device_list.device_entry"
        key={`${d.deviceID}_${index}`}
        device={d}
        statusMapSelector={statusMapSelector}
      />
    ));
