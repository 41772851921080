import { Typography, useMediaQuery } from "@material-ui/core";
import { IHelpForm } from "features/help/HelpScreen";
import { Field, Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectIsRemote,
  selectUserDescriptor,
} from "session/SessionSlice";
import {
  AppButton,
  AppButtonStyles,
  IRouteState,
  Panel,
  SimpleTextField,
  Spinner,
  Modal,
} from "solveiq.designsystem";
import {
  createTicket,
  fetchTickets,
  ITicket,
  selectAllTickets,
  selectTicketsStatus,
} from "./TicketsSlice";
import * as yup from "yup";
import styles from "./TicketsScreen.module.css";
import openState from "assets/img/tickets/icn-open.png";
import inProgressState from "assets/img/tickets/icn-in-progress.png";
import completedState from "assets/img/tickets/icn-complete.png";
import { EmptyState } from "features/Optimization";
import { IThunkStatus, ReducerStatus } from "model/IReducerState";
import { useHistory } from "react-router-dom";
import { RouterBreadcrumbs } from "features/teamManagement/employeeDetail/RouterBreadcrumbs";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { RootState } from "app/store";
import IUser from "model/user/IUser";

export interface ITicketsScreenProps {
  ticketsSelector?: (state: RootState) => ITicket[];
  userSelector?: (state: RootState) => IUser | null;
  ticketsStatusSelector?: (state: RootState) => IThunkStatus;
  isRemoteSelector?: (state: RootState) => boolean;
  userDescriptorSelector?: (state: RootState) => string;
}

export const TicketsScreen: React.FC<ITicketsScreenProps> = ({
  ticketsSelector = selectAllTickets,
  userSelector = selectCurrentUser,
  ticketsStatusSelector = selectTicketsStatus,
  isRemoteSelector = selectIsRemote,
  userDescriptorSelector = selectUserDescriptor,
}) => {
  const dispatch = useDispatch();
  const [showCreateTicket, setShowCreateTicket] = useState(false);
  const [processing, setProcessing] = useState(false);
  const user = useSelector(userSelector);
  const unsortedTickets = useSelector(ticketsSelector);
  const tickets = [...unsortedTickets].sort((a, b) => {
    if (a.isClosed != b.isClosed) {
      return a.isClosed ? 1 : -1;
    }

    const aDate = new Date(a.lastReferencedDate);
    const bDate = new Date(b.lastReferencedDate);
    return aDate > bDate ? -1 : 1;
  });
  const ticketsStatus = useSelector(ticketsStatusSelector);
  const history = useHistory<IRouteState>();
  const matchesTablet = useMediaQuery("(min-width: 768px)");
  const isRemote = useSelector(isRemoteSelector);
  const userDescriptor = useSelector(userDescriptorSelector);

  const initialValues: IHelpForm = useMemo(
    () => ({
      name: user ? `${user?.firstName} ${user.lastName}` : "",
      email: user?.email ?? "",
      subject: "",
      description: "",
    }),
    [user]
  );

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
    subject: yup.string().required("Subject is required"),
    description: yup.string().required("Description is required"),
  });

  useEffect(() => {
    if (
      ticketsStatus[fetchTickets.typePrefix] === ReducerStatus.Loading ||
      ticketsStatus[createTicket.typePrefix] === ReducerStatus.Loading
    ) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
  }, [ticketsStatus]);

  useEffect(() => {
    dispatch(fetchTickets());
  }, [dispatch]);

  function closeCreateTicket() {
    setShowCreateTicket(false);
  }

  function openCreateTicket() {
    setShowCreateTicket(true);
  }

  const onSubmit = async (values: IHelpForm) => {
    try {
      dispatch(createTicket(values));
      setShowCreateTicket(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div data-qatag="tickets_container" className={styles.container}>
      <Modal
        data-qatag="roleModal"
        open={showCreateTicket}
        onClose={closeCreateTicket}
      >
        <div
          data-qatag="createTicketContainer"
          className={styles.createTicketContainer}
        >
          <h1
            data-qatag="createTicketTitle"
            className={styles.createTicketTitle}
          >
            Submit a Ticket
          </h1>
          <h2
            data-qatag="createTicketSubitle"
            className={styles.createTicketSubitle}
          >
            What can we help with you today?
          </h2>

          <Formik
            data-qatag="createticketformik"
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ submitForm, isSubmitting }) => (
              <Form data-qatag="createticket_form" className={styles.helpForm}>
                <Field
                  data-qatag="help.form.field.subject"
                  id="help.form.field.subject"
                  component={SimpleTextField}
                  name="name"
                  type="text"
                  label="Name"
                  disabled={true}
                />
                <Field
                  data-qatag="help.form.field.subject"
                  id="help.form.field.subject"
                  component={SimpleTextField}
                  name="email"
                  type="email"
                  label="Email Address"
                  disabled={true}
                />
                <Field
                  data-qatag="help.form.field.subject"
                  id="help.form.field.subject"
                  component={SimpleTextField}
                  name="subject"
                  type="text"
                  label="Subject"
                />
                <Field
                  data-qatag="help.form.field.description"
                  id="help.form.field.description"
                  component={SimpleTextField}
                  type="text"
                  label="Description"
                  name="description"
                  multiline
                  rows={10}
                />
                <Typography
                  data-qatag="formInstructions"
                  className={styles.formInstructions}
                  variant="body2"
                >
                  <FormattedMessage
                    data-qatag="formInstructionsMessage"
                    id={"createTicket.body.formInstructions"}
                    defaultMessage="Please enter the details of your request. A member of our support staff will respond as soon as possible."
                  />
                </Typography>

                <div data-qatag="formActions" className={styles.formActions}>
                  <AppButton
                    data-qatag="createticket_submit"
                    buttonStyle={AppButtonStyles.Green}
                    disabled={isSubmitting}
                    className={styles.submitButton}
                    type="button"
                  >
                    <Typography
                      data-qatag="submitButtonText"
                      className={styles.submitButtonText}
                      variant="body2"
                    >
                      <FormattedMessage
                        data-qatag="submitButtonMessage"
                        id={"createTicket.body.submitButton"}
                        defaultMessage="Submit"
                      />
                    </Typography>
                  </AppButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>

      <Spinner
        data-qatag="loadingModal"
        isActive={processing}
        text="Loading..."
      />

      {isRemote ? (
        <RouterBreadcrumbs
          data-qatag="tickets.screen.remoteBreadcrumbs"
          style={{ width: "100%", height: "42px", alignSelf: "center" }}
          descriptors={[
            {
              to: `/employeeDetail/${user?.id}`,
              label: userDescriptor,
            },
            {
              to: "/",
              label: "Tickets",
            },
          ]}
        ></RouterBreadcrumbs>
      ) : (
        <Breadcrumbs
          data-qatag="tickets.screen.breadcrumbs"
          pathsMap={{ tickets: "Tickets" }}
        />
      )}

      {matchesTablet ? getContent() : getMobileContent()}
    </div>
  );

  function getContent() {
    return (
      <Panel data-qatag="tickets_content" className={styles.content}>
        <header data-qatag="tickets_header" className={styles.header}>
          <h1 data-qatag="tickets_title" className={styles.title}>
            Your Support Requests
          </h1>
          <div data-qatag="tickets_actions" className={styles.actions}>
            <AppButton
              data-qatag="tickets_create"
              buttonStyle={AppButtonStyles.Green}
              onClick={openCreateTicket}
              style={{ width: 200, height: 44, fontSize: "16px" }}
            >
              Create Ticket
            </AppButton>
          </div>
        </header>

        <div data-qatag="tickets" className={styles.tickets}>
          <div data-qatag="tickets_tableHeaders" className={styles.ticket}>
            <div
              data-qatag="tickets_header_ticket"
              style={{ whiteSpace: "nowrap" }}
            >
              Ticket #
            </div>
            <div data-qatag="tickets_header_topic">Topic</div>
            <div data-qatag="tickets_header_requeststatus">Request Status</div>
            <div data-qatag="tickets_header_lastupdated">Last Updated</div>
            <div data-qatag="tickets_header_createdby">Created By</div>
            <div data-qatag="tickets_header_details">Details</div>
          </div>
          {tickets.length === 0 ? (
            <EmptyState
              data-qatag="noTicketsEmptyState"
              error="Empty"
              suggestion="There are no tickets."
            />
          ) : (
            tickets.map((ticket, index) => {
              return (
                <div
                  key={`${ticket.id}_${index}`}
                  data-qatag="ticket"
                  className={styles.ticket}
                >
                  <div
                    data-qatag="ticketNumber"
                    className={styles.ticketNumber}
                  >
                    {ticket.caseNumber}
                  </div>
                  <div
                    data-qatag="topic"
                    className={styles.topic}
                    title={ticket.subject}
                  >
                    {getSubjectContent(ticket.subject)}
                  </div>
                  {getStatus(ticket.status)}
                  <div data-qatag="lastUpdated" className={styles.lastUpdated}>
                    {getDateAndTime(
                      ticket.lastViewedDate || ticket.createdDate
                    )}
                  </div>
                  <div data-qatag="createdBy" className={styles.createdBy}>
                    {ticket.suppliedName}
                  </div>
                  <div
                    data-qatag="detailsButton"
                    className={styles.detailsButton}
                  >
                    <AppButton
                      data-qatag="ticket_details"
                      buttonStyle={AppButtonStyles.Green}
                      style={{ width: 100 }}
                      onClick={() => {
                        history.push(`/tickets/${ticket.id}`);
                      }}
                    >
                      Details
                    </AppButton>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </Panel>
    );
  }

  function getMobileContent() {
    return (
      <Panel data-qatag="tickets_content" className={styles.mobileContent}>
        <header data-qatag="mobileHeader" className={styles.mobileHeader}>
          <h1 data-qatag="mobileTitle" className={styles.mobileTitle}>
            Your support requests
          </h1>
          <AppButton
            data-qatag="tickets_create"
            buttonStyle={AppButtonStyles.Green}
            onClick={openCreateTicket}
            style={{ width: 300, height: 44, fontSize: "16px" }}
          >
            Create Ticket
          </AppButton>
        </header>

        <div data-qatag="mobileTickets" className={styles.mobileTickets}>
          {tickets.length === 0 ? (
            <EmptyState
              data-qatag="noTicketsEmptyState"
              error="Empty"
              suggestion="There are no tickets."
            />
          ) : (
            tickets.map((ticket, index) => {
              return (
                <div
                  data-qatag="ticket"
                  key={`${ticket.id}_${index}`}
                  className={styles.mobileTicket}
                >
                  <h1
                    data-qatag="mobileTicketTitle"
                    className={styles.mobileTicketTitle}
                  >
                    Ticket # {ticket.caseNumber}
                  </h1>
                  <div
                    data-qatag="mobileTicketContent"
                    className={styles.mobileTicketContent}
                  >
                    <span
                      data-qatag="mobileLabel"
                      className={styles.mobileLabel}
                    >
                      Topic
                    </span>
                    <span
                      data-qatag="mobileValue"
                      className={styles.mobileValue}
                    >
                      {getSubjectContent(ticket.subject)}
                    </span>

                    <span
                      data-qatag="mobileLabel"
                      className={styles.mobileLabel}
                    >
                      Request Status
                    </span>
                    <span
                      data-qatag="mobileValue"
                      className={styles.mobileValue}
                    >
                      {getStatus(ticket.status)}
                    </span>

                    <span
                      data-qatag="mobileLabel"
                      className={styles.mobileLabel}
                    >
                      Last Updated
                    </span>
                    <span
                      data-qatag="mobileValue"
                      className={`${styles.mobileValue} ${styles.mobileValueDate}`}
                    >
                      {getDateAndTime(ticket.createdDate)}
                    </span>

                    <span
                      data-qatag="mobileLabel"
                      className={`${styles.mobileLabel} ${styles.mobileLabelCreatedBy}`}
                    >
                      Created By
                    </span>
                    <span
                      data-qatag="mobileValue"
                      className={`${styles.mobileValue} ${styles.mobileValueCreatedBy}`}
                    >
                      {ticket.origin}
                    </span>

                    <div
                      data-qatag="detailsButton"
                      className={styles.mobileDetailsButton}
                    >
                      <AppButton
                        data-qatag="ticket_details"
                        buttonStyle={AppButtonStyles.Green}
                        style={{ width: 300, height: 44, fontSize: "16px" }}
                        onClick={() => {
                          history.push(`/tickets/${ticket.id}`);
                        }}
                      >
                        Details
                      </AppButton>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </Panel>
    );
  }
};

function getSubjectContent(subject: string): string {
  const subjectRegex = /(^SolveIQ -)(.+)/;
  const result = subjectRegex.exec(subject);
  if (result !== null) {
    const [, , content] = result;
    return content;
  }
  return subject;
}

export function getStatus(status: string): React.ReactElement {
  switch (status) {
    case "New":
    case "On Hold":
      return (
        <div data-qatag="requestStatus" className={styles.requestStatus}>
          <img data-qatag="openstate" src={openState} alt="Empty circle" />{" "}
          {status}
        </div>
      );
    case "Escalated":
      return (
        <div data-qatag="requestStatus" className={styles.requestStatus}>
          <img
            data-qatag="openstate"
            src={inProgressState}
            alt="Half full orange circle"
          />{" "}
          {status}
        </div>
      );
    // Probably this state should not happen as "Deleted" is for Spam.
    // The list of tickets should come filtered from backed without deleted ones probably.
    case "Deleted":
      return (
        <div data-qatag="requestStatus" className={styles.requestStatus}>
          <img
            data-qatag="openstate"
            src={completedState}
            alt="Green circle with a check"
          />{" "}
          {status}
        </div>
      );
    default:
      return (
        <div data-qatag="requestStatus" className={styles.requestStatus}>
          {status}
        </div>
      );
  }
}

export function getDateAndTime(date: string): string {
  const d = new Date(date);
  return d.toLocaleString();
}

export default TicketsScreen;
