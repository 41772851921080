import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import { selectCurrentUser } from "session/SessionSlice";
import RESTGatewayAPI from "api/gatewayAPI";
import { IHelpForm } from "features/help/HelpScreen";
import { ISupportCasePayload } from "model/messaging/payloads/ISupportCasePayload";
import { IUser } from "model/user/IUser";

interface ITicketsScan {
  tickets: ITicket[];
}

export interface ITicket {
  id: string;
  accountId: string;
  caseNumber: string;
  origin: string;
  reason: string;
  subject: string;
  description: string;
  createdDate: string;
  priority: string;
  ownerId: string;
  isClosed: boolean;
  contactId: string;
  createdById: string;
  closedDate: string;
  isDeleted: boolean;
  lastViewedDate: string;
  lastReferencedDate: string;
  status: string;
  type: string;
  originalToAddress__c: string;
  priority_Flag__c: string;
  suppliedEmail: string;
  suppliedName: string;
  suppliedPhone: string;
  product_Id__c: string;
  product_Name__c: string;
  emailMessages: TicketEmailMessages | null;
}

export interface TicketEmailMessages {
  totalSize: number;
  records: ITicketEmailMessageRecord[];
}

export interface ITicketEmailMessageRecord {
  subject: string;
  textBody: string;
  messageDate: string;
}

export const fetchTickets = createAsyncThunk<
  ITicket[],
  void,
  { state: RootState }
>("tickets/fetchTickets", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const user = selectCurrentUser(state);

  // Admins will see all messages
  if (
    state.session?.data?.loggedInUser?.attributes
      ?.toLowerCase()
      ?.includes("admin")
  ) {
    // If user is an admin I'll need to retrieve all employees then their tickets
    try {
      const url = `/api/organization/users/${
        selectCurrentUser(state)?.registrationKey
      }`;
      // All users
      const users = await RESTGatewayAPI.get(url);

      // Active users
      const activeUsers = users?.data
        ?.filter((user: IUser) => user?.enabled)
        ?.map((user: IUser) => user?.email);

      // Generate array of promises
      const requestTicketPromises = activeUsers?.map((email: string) => {
        const url = `/api/salesforce/cases/${user?.registrationKey}/${email}`;
        return RESTGatewayAPI.get(url);
      });

      // All responses
      const responses = ((await Promise.allSettled(
        requestTicketPromises
      )) as unknown) as any[];

      //Tickets without exceptions
      const tickets = responses
        ?.filter((response: any) => response?.status === "fulfilled")
        ?.map((response: any) => response?.value?.data);
      // Flat the array
      return tickets.flat(1);
    } catch (error) {
      return thunkApi.rejectWithValue(
        `something went wrong retrieving tickets`
      );
    }
  }

  try {
    const url = `/api/salesforce/cases/${user?.registrationKey}/${user?.email}`;
    const response = await RESTGatewayAPI.get(url);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch tickets : ${error}`);
  }
});

const initialState: IReducerState<ITicketsScan> = {
  data: {
    tickets: [],
  },
  status: {
    [fetchTickets.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const createTicket = createAsyncThunk<
  string,
  IHelpForm,
  {
    state: RootState;
  }
>("tickets/createTicket", async (details, thunkApi) => {
  const { currentRegKey } = thunkApi.getState().session.data;

  // this is currently necessary for the support case call
  // this should be removed when contact creation has been automated
  // temp code
  const firstName =
    details.name.indexOf(" ") > 0
      ? details.name.split(" ").slice(0, 1)
      : details.name;
  const lastName =
    details.name.indexOf(" ") > 0 ? details.name.split(" ").slice(1, 2) : "";
  const addContactUrl = "/api/salesforce/contact";
  const addContactPayload = {
    RegistrationKey__c: currentRegKey,
    FirstName: Array.isArray(firstName) ? firstName[0] : firstName,
    LastName: Array.isArray(lastName) ? lastName[0] : lastName,
    Email: details.email,
    Description: "raul test contact",
  };
  try {
    await RESTGatewayAPI.post(addContactUrl, addContactPayload);
  } catch (e) {
    return thunkApi.rejectWithValue({
      email: details.email,
      error: e,
    });
  }
  // end temp code

  const url = `/api/salesforce/cases/${currentRegKey}`;
  const payload = {
    Origin: "SolveIQ Business UI",
    Reason: "User Created Issue",
    Description: details.description,
    Priority: "High",
    Status: "New",
    Subject: `SolveIQ - ${details.subject}`,
    Type: "User Contact",
    SuppliedEmail: details.email,
    SuppliedName: details.name,
    Product_Name__c: "SolveIQBusiness",
    Product_Id__c: 5,
  } as ISupportCasePayload;

  try {
    const response = await (await RESTGatewayAPI.post(url, payload)).data;
    thunkApi.dispatch(fetchTickets());
    return response.id;
  } catch (e) {
    return thunkApi.rejectWithValue({
      email: details.email,
      submitPayload: payload,
      error: e,
    });
  }
});

export const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTickets.pending, (state) => {
        state.status[fetchTickets.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(fetchTickets.fulfilled, (state, action) => {
        state.status[fetchTickets.typePrefix] = ReducerStatus.Succeeded;
        state.data.tickets = action.payload;
      })
      .addCase(fetchTickets.rejected, (state, action) => {
        state.status[fetchTickets.typePrefix] = ReducerStatus.Failed;
      })
      .addCase(createTicket.pending, (state) => {
        state.status[createTicket.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(createTicket.fulfilled, (state) => {
        state.status[createTicket.typePrefix] = ReducerStatus.Succeeded;
      })
      .addCase(createTicket.rejected, (state) => {
        state.status[createTicket.typePrefix] = ReducerStatus.Failed;
      });
  },
});

export const selectTicketsStatus = (state: RootState) => state.tickets.status;
export const selectAllTickets = (state: RootState) =>
  state.tickets.data.tickets;
export const selectTicketById = (id: string) => (state: RootState) => {
  for (let index = 0; index < state.tickets.data.tickets.length; index++) {
    if (id === state.tickets.data.tickets[index].id) {
      return state.tickets.data.tickets[index];
    }
  }
  return null;
};
export const selectOpenTickets = (state: RootState) =>
  state.tickets.data.tickets.filter((ticket) => !ticket.isClosed);

export const selectOpenTicketCount = (state: RootState) =>
  selectOpenTickets(state).length;

export const selectResolvedTicketCount = (state: RootState) =>
  state.tickets.data.tickets.filter((ticket) => ticket.isClosed).length;

export default ticketsSlice.reducer;
