import { useCallback, useMemo, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Typography, Box, IconButton, Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, FieldArray } from "formik";
import { object, string, array, addMethod } from "yup";
import { v4 as uuidv4 } from "uuid";
import {
  AppButton,
  AppButtonStyles,
  useMatchTabletVertical,
  Spinner,
  Alert,
  AlertIcon,
} from "solveiq.designsystem";
import { CallToAction, CallToActionIllustration } from "features/CallToAction";
import TeamMemberField from "./TeamMemberField";
import addTeamIcon from "assets/icon/icn-add-team.svg";
import menuTeamIcon from "assets/icon/icn-menu-team.svg";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./AddTeamMemberModal.module.css";
import { RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { ReducerStatus } from "model/IReducerState";
import { selectAccessToken, selectCurrentUser } from "session/SessionSlice";
import { IUser } from "model/user/IUser";
import { AlertError, AlertSuccess } from "features/customAlerts/AddTeamMember/";
import { fetchOrganizationUsers } from "model/user/UserSlice";
import RESTGatewayAPI from "api/gatewayAPI";
import { UserInterface, FieldInterface } from "./types";
import {
  selectMyPlan,
  selectMyPlanStatus,
} from "features/Billing/MyPlan/MyPlanSlice";
import { IBillingSummary } from "features/Billing/MyPlan/";

export interface IAddTeamMemberModal {
  /* Selector to retrieve the user registration key */
  userSelector?: (state: RootState) => IUser | null;
  /* Seats to create, default number of fields to create on first render */
  fieldNumbers?: number;
  /* Step to increment fields by the add team member button */
  step?: number;
  /* Controls to open the form */
  isOpen: boolean;
  /* Callback to update the isOpen on the parent*/
  handleOnClose: () => void;
  /* My plan summary */
  myPlanSelector?: (state: RootState) => IBillingSummary;
  /* My plan summary Request Status */
  myPlanStatusSelector?: (state: RootState) => ReducerStatus;
  /* Seats currently used */
  usedSeats?: number;
  /* seats length when the user is */
  seatCount?: number;
  /* isBeta flag */
  isBeta?: boolean;
}

/* Custom Yup method to validate unique emails */
addMethod(
  array,
  "unique",
  function (message, mapper = ({ email }: { email: string }) => email) {
    return this.test("unique", message, function (list = []) {
      return list.length === new Set(list.map(mapper)).size;
    });
  }
);

/* Custom style for Dialog */
const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: "896px",
    margin: 12,
    width: "100%",
    minWidth: 330,
    height: 640,
  },
}));

const AddTeamMemberModal: React.FC<IAddTeamMemberModal> = ({
  userSelector = (state) => selectCurrentUser(state),
  fieldNumbers = 1,
  step = 1,
  isOpen = true,
  handleOnClose,
  myPlanSelector = (state: RootState) => selectMyPlan(state),
  myPlanStatusSelector = (state: RootState) => selectMyPlanStatus(state),
  usedSeats = 1,
  seatCount,
  isBeta = false,
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "add.team.members.view";

  const dispatch = useDispatch();

  /* My Plan detail */
  // const myPlanSummary = useSelector(myPlanSelector);

  // If the user is a beta seatCountBeta should not bet undefined
  const availableSeats = (seatCount || 0) - usedSeats;
  // (seatCount || myPlanSummary?.SeatCount || 0) - usedSeats;

  /* My Plan Request Status */
  const requestStatusMyPlan = useSelector(myPlanStatusSelector);

  // Retrieve summary plan to determine seats length
  // useEffect(() => {
  //   if (ReducerStatus[requestStatusMyPlan] === ReducerStatus.Idle && !isBeta) {
  //     dispatch(fetchMyPlan());
  //   }
  // }, [dispatch, isBeta, requestStatusMyPlan]);

  /* Validation Scheme for Formik */
  const validationSchema = useMemo(
    () =>
      object().shape({
        seats: array()
          .of(
            object().shape({
              name: string()
                .required(
                  formatMessage({
                    id: `${prefix}.name.required`,
                  })
                )
                .matches(
                  /^[A-Za-z']+\s[A-Za-z']+$/,
                  "First and Last name are required"
                ),
              email: string()
                .email(
                  formatMessage({
                    id: `${prefix}.email.invalid`,
                  })
                )
                .required(
                  formatMessage({
                    id: `${prefix}.email.required`,
                  })
                ),
            })
          )
          .test(
            "Unique",
            formatMessage({
              id: `${prefix}.duplicate.email`,
            }),
            (values: FieldInterface[] | undefined) => {
              const emails = values?.map((value) => value.email) || [];
              return new Set(emails).size === emails.length;
            }
          ),
      }),
    [formatMessage]
  );

  // validation token
  const token = useSelector(selectAccessToken);

  /* Initial values for Formik Field */
  const initialValues = useMemo(
    () => ({
      seats: [
        {
          name: "",
          email: "",
        },
      ],
      token: token,
    }),
    [token]
  );

  const currentUser = useSelector<RootState, IUser | null>(userSelector);

  const [openSuccessSubmitModal, setOpenSuccessSubmitModal] = useState(false);
  const [openErrorSubmitModal, setOpenErrorSubmitModal] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [showAlertLimit, setShowAlertLimit] = useState(false);
  const [fieldsIds, setFieldsIds] = useState<string[]>(() => {
    if (fieldNumbers <= 0) return [uuidv4()]; //In case fields rows are negative
    if (fieldNumbers > availableSeats && availableSeats > 0) {
      //In case fields rows are greater than available seats
      return new Array(availableSeats).fill(uuidv4());
    }
    return new Array(fieldNumbers).fill(uuidv4());
  });

  /* method to submit team members */
  const handleSubmit = async (values: {
    seats: {
      name: string;
      email: string;
    }[];
    token: string | undefined;
  }) => {
    // Users to be inserted
    const newSeats = values?.seats;
    setStatus("loading");

    const getUsersUrl = `/api/organization/users/${currentUser?.registrationKey}`;

    const response = await RESTGatewayAPI.get<UserInterface[]>(getUsersUrl);
    const responsePayload = response.data;

    if (responsePayload == null || responsePayload.length === 0) {
      setSubmitError("Unable to verified previous users");
      setOpenErrorSubmitModal(true);
      setStatus("error");
      return;
    }

    // Emails from the form
    const emailSeats = newSeats?.map(
      (newSeat: FieldInterface) => newSeat.email
    );

    const found = emailSeats.some((email) =>
      responsePayload
        ?.filter((user) => user.enabled)
        ?.map((r: UserInterface) => r.email)
        ?.includes(email)
    );

    if (found) {
      setOpenErrorSubmitModal(true);
      setSubmitError(
        formatMessage({
          id: `${prefix}.email.exist`,
        })
      );
      setStatus("error");
      return;
    }

    const createUsersUrl = `/api/user`;

    const postUser = newSeats.map((seat: FieldInterface) => {
      const names = seat?.name?.split(" ");
      const body = {
        RegistrationKey: currentUser?.registrationKey,
        Email: seat.email,
        Uuid: uuidv4(),
        Name: seat.name,
        firstName: names?.[0],
        lastName: names?.[1],
        ComputerName: formatMessage({
          id: `${prefix}.computer.name`,
        }),
      };

      return RESTGatewayAPI.put(createUsersUrl, body);
    });
    setStatus("sending");
    Promise.all(postUser)
      .then(() => {
        setOpenSuccessSubmitModal(true);
        setStatus("success");
      })
      .catch(() => {
        setOpenErrorSubmitModal(true);
        setSubmitError(
          formatMessage({
            id: `${prefix}.unable.add`,
          })
        );
        setStatus("error");
      });
  };

  /* Responsive web design */
  const matchTablet = useMatchTabletVertical();

  const classes = useStyles();

  useEffect(() => {
    if (availableSeats === 0) {
      setError(
        formatMessage({
          id: `${prefix}.no.seats.available`,
        })
      );
    } else if (availableSeats < 0) {
      setError(
        formatMessage({
          id: `${prefix}.invalid.number.seats`,
        })
      );
    } else {
      setError("");
    }
  }, [availableSeats, formatMessage]);

  const increaseFields = useCallback(() => {
    // If current fields plus step overpassed available seats
    if (fieldsIds.length + step > availableSeats) {
      setShowAlertLimit(true);
      const remainingSeats = availableSeats - fieldsIds.length;
      if (remainingSeats > 0) {
        setFieldsIds([
          ...fieldsIds,
          ...new Array(remainingSeats).fill(uuidv4()),
        ]);
      }
    } else {
      setFieldsIds([...fieldsIds, ...new Array(step).fill(uuidv4())]);
    }
  }, [fieldsIds, step, availableSeats]);

  const removeField = useCallback((id: string) => {
    setFieldsIds((prevState) => prevState.filter((x) => x !== id));
  }, []);

  const renderFields = useCallback(
    (fields: string[], remove: (index: number) => void) =>
      fields.map((fieldId, index) => (
        <TeamMemberField
          data-qatag="teamMemberField"
          key={fieldId}
          fieldId={fieldId}
          index={index}
          remove={remove}
          removeField={removeField}
          fieldsLength={fieldsIds?.length}
        />
      )),
    [fieldsIds?.length, removeField]
  );

  // useEffect if the prop of max available seats change the array of ids should change too
  useEffect(() => {
    if (availableSeats > 0 && availableSeats < 3) {
      setFieldsIds(new Array(availableSeats).fill(uuidv4()));
    }
  }, [availableSeats]);

  console.log("status", status);

  return (
    <>
      <Dialog
        data-qatag="dialog"
        open={isOpen}
        onClose={handleOnClose}
        fullWidth
        classes={{ paper: classes.paper }}
      >
        <Formik
          data-qatag="formik"
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <form data-qatag="form" onSubmit={props.handleSubmit}>
              <div data-qatag="container" className={styles.container}>
                <div data-qatag="content" className={styles.content}>
                  {/* Close Icon */}
                  <div
                    data-qatag="closeIconContainer"
                    className={styles.closeIconContainer}
                  >
                    <IconButton
                      data-qatag="closeIcon"
                      aria-label="close"
                      onClick={handleOnClose}
                      className={styles.closeIcon}
                    >
                      <CloseIcon data-qatag="CloseIcon" />
                    </IconButton>
                  </div>

                  {/* Title */}
                  <Box data-qatag="titleContainer" mt={4}>
                    <Typography
                      data-qatag="titleText"
                      align="center"
                      variant="h6"
                      className={styles.tittleAddTeamMembers}
                    >
                      <img
                        data-qatag="menuTeamIcon"
                        className={styles.menuTeamIcon}
                        src={menuTeamIcon}
                        alt=""
                      />
                      <FormattedMessage
                        data-qatag="titleTextMessage"
                        id={`${prefix}.add.team.members`}
                      />
                    </Typography>
                  </Box>

                  {/* Subtitle */}
                  <Box
                    data-qatag="subtitle"
                    mb={1}
                    mt={1}
                    alignItems="center"
                    justifyContent="center"
                    className={styles.subtitle}
                    textAlign="center"
                  >
                    {!error ? (
                      <FormattedMessage
                        data-qatag="addTeamMembersMessage"
                        id={`${prefix}.additional.members.title`}
                        values={{
                          icon: (
                            <img
                              data-qatag="addTeamIcon"
                              className={styles.addTeamIcon}
                              src={addTeamIcon}
                              alt=""
                            />
                          ),
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Box>

                  {/* Separator */}
                  <div
                    data-qatag="horizontalBarContainer"
                    className={styles.horizontalBarContainer}
                  >
                    <hr
                      data-qatag="horizontalBar"
                      className={styles.horizontalBar}
                    />
                  </div>

                  {/* Error */}
                  {error ? (
                    <CallToAction
                      data-qatag="callToAction"
                      buttonLabel={formatMessage({
                        id: `${prefix}.buy.more.seats`,
                      })}
                      callForActionHandler={() => {}}
                      cancelHandler={handleOnClose}
                      error={error}
                      suggestion={formatMessage({
                        id: `${prefix}.purchase.additional.seats`,
                      })}
                      illustration={CallToActionIllustration.Seat}
                    />
                  ) : (
                    <>
                      {/* Available seats */}
                      <Box data-qatag="availableSeatsContainer" mt={2} mb={1}>
                        <Typography
                          data-qatag="availableSeats"
                          align="right"
                          variant={"body1"}
                        >
                          {fieldsIds.length}/{availableSeats}
                          <FormattedMessage
                            data-qatag="availableSeatsText"
                            id={`${prefix}.seats.available`}
                          />
                        </Typography>
                      </Box>

                      <FieldArray data-qatag="fieldArray" name="seats">
                        {({ remove, push }) => (
                          <>
                            <div
                              data-qatag="inputContainer"
                              className={styles.inputContainer}
                            >
                              {renderFields(fieldsIds, remove)}
                            </div>

                            {/* Add team member button */}
                            <Box
                              data-qatag="addTeamMemberButton"
                              mt={3}
                              mb={3}
                              display={"flex"}
                              justifyContent={
                                matchTablet ? "flex-start" : "center"
                              }
                              textAlign="center"
                            >
                              <Typography
                                data-qatag="addNewMemberButton"
                                align="center"
                                variant="body2"
                                className={styles.addNewMemberButton}
                                onClick={() => {
                                  push({ name: "", email: "" });
                                  increaseFields();
                                }}
                              >
                                <FormattedMessage
                                  data-qatag="addTeamIcon"
                                  id={`${prefix}.add.new`}
                                  values={{
                                    icon: (
                                      <img
                                        data-qatag="addTeamIcon"
                                        className={styles.addTeamIcon}
                                        src={addTeamIcon}
                                        alt=""
                                      />
                                    ),
                                  }}
                                />
                              </Typography>
                            </Box>
                          </>
                        )}
                      </FieldArray>

                      {/* Submit button send invites */}
                      <div
                        data-qatag="buttonContainer"
                        className={styles.buttonContainer}
                      >
                        <AppButton
                          data-qatag="customButton"
                          buttonStyle={AppButtonStyles.Green}
                          className={styles.customButton}
                          type="submit"
                        >
                          <Typography
                            data-qatag="sendInviteContainer"
                            variant="body2"
                          >
                            <FormattedMessage
                              data-qatag="sendInviteText"
                              id={`${prefix}.send.invite`}
                            />
                          </Typography>
                        </AppButton>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Dialog>
      <AlertError
        data-qatag="alertError"
        isOpen={openErrorSubmitModal}
        closeHandler={() => {
          setOpenErrorSubmitModal(false);
        }}
        text={submitError}
      />
      <AlertSuccess
        data-qatag="alertSuccess"
        isOpen={openSuccessSubmitModal}
        closeHandler={() => {
          setOpenSuccessSubmitModal(false);
          handleOnClose();
          dispatch(fetchOrganizationUsers());
        }}
      />
      {/* Loading modal */}
      <Spinner
        data-qatag="loadingModal"
        isActive={
          ReducerStatus[requestStatusMyPlan] === ReducerStatus.Loading ||
          status === "loading" ||
          status === "sending"
        }
        text={
          status === "sending"
            ? "sending..."
            : status === "loading"
            ? "processing..."
            : "loading..."
        }
      />
      {/* Alert the user when the limit of seas has been reach*/}
      <Alert
        data-qatag="limitAlert"
        approveHandler={() => setShowAlertLimit(false)}
        buttonText="Close"
        closeHandler={() => setShowAlertLimit(false)}
        isOpen={showAlertLimit}
        icon={AlertIcon.LimitError}
        title="You have reached the limit of seats you have available"
        text="You have no more invites to send."
      />
    </>
  );
};

export default AddTeamMemberModal;
