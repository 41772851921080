import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import { IFileCleanupScan } from "model/scan/IFileCleanupScan";
import { IServiceMessage, ServiceMessage, WSMessageType } from "ui.common";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { IFileScanCleanupPayload } from "model/messaging/payloads/IFileScanCleanupPayload";
import {
  IDirectoryCleanUpScanDetailsMessage,
  IFileCleanUpRemovalResultsMessage,
} from "model/messaging/messages/FileCleanupMessages";
import { ILast30DaysFileCleanupReport } from "./ILast30DaysFileCleanupReport";
import RESTGatewayAPI from "api/gatewayAPI";
import { IFetchScanPayload } from "model/IFetchScanPayload";
import { selectCurrentUuid } from "session/SessionSlice";

const localStorageStrings = {
  tempFiles: "fileCleanupScanTempFilesEnabled",
  internetFiles: "fileCleanupScanInternetFilesEnabled",
  recycling: "fileCleanupScanRecyclingEnabled",
};

interface IFileCleanupData {
  scan: IFileCleanupScan | null;
  details: IDirectoryCleanUpScanDetailsMessage | null;
  results: IFileCleanUpRemovalResultsMessage | null;
  last30DaysReport: ILast30DaysFileCleanupReport | null;
  doingFileCleanup: boolean;
  doingScan: boolean;
  options: IFileCleanupOptions | null;
}

export interface IFileCleanupOptions {
  tempFilesEnabled: boolean;
  internetFilesEnabled: boolean;
  recyclingEnabled: boolean;
}

export const fetchFileCleanupScan = createAsyncThunk<
  IFileCleanupScan | null,
  void,
  { state: RootState }
>("fileCleanupScan/fetchFileCleanupScan", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const uuid = selectCurrentUuid(state);
  const url = `/api/fileCleanup/scan/${uuid}`;

  try {
    const apiResponse = await RESTGatewayAPI.get<IFetchScanPayload>(url);
    const scan: IFileCleanupScan = JSON.parse(apiResponse.data.payload);
    return scan;
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch file cleanup scan : ${error}`
    );
  }
});

export const fetchFileCleanupResults = createAsyncThunk<
  IFileCleanUpRemovalResultsMessage | null,
  void,
  { state: RootState }
>("fileCleanupScan/fetchFileCleanupResults", async (_, thunkApi) => {
  const srhub = getSignalRHub();
  const message: IServiceMessage = new ServiceMessage();
  message.MessageType = WSMessageType.GET_FILE_CLEAN_UP_RESULTS;
  const response = await srhub.SendAsync(message);
  const data: IFileCleanUpRemovalResultsMessage = response.Payload;
  return data;
});

export const startFileCleanupScan = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("fileCleanupScan/startFileCleanupScan", async (_, thunkApi) => {
  const srhub = getSignalRHub();
  const message: IServiceMessage = new ServiceMessage();
  message.MessageType = WSMessageType.START_FILE_CLEANUP_SCAN;
  return srhub.SendAsync(message);
});

export const doFileCleanup = createAsyncThunk<
  void,
  IFileScanCleanupPayload,
  { state: RootState }
>("fileCleanupScan/doFileCleanup", async (payload, thunkApi) => {
  const srhub = getSignalRHub();
  const message: IServiceMessage = new ServiceMessage();
  message.MessageType = WSMessageType.DELETE_CLEAN_UP_FILES;
  message.Payload = payload;
  return srhub.SendAsync(message);
});

/*
export const getFileCleanupOpenApps = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("fileCleanupScan/getFileCleanupOpenApps", async (_, thunkApi) => {
  const uuid = thunkApi.getState().session.data.currentUuid;
  const srhub = getSignalRHub();
  const message: IServiceMessage = new ServiceMessage();
  message.MessageType = WSMessageType.GET_FILE_CLEAN_UP_OPEN_APPS;
  const response = await srhub.SendAsync(message);
  return response.Payload;
});
*/

export const getFileCleanupDetails = createAsyncThunk<
  IDirectoryCleanUpScanDetailsMessage,
  void,
  { state: RootState }
>("fileCleanupScan/getFileCleanupDetails", async (_, thunkApi) => {
  const srhub = getSignalRHub();
  const message: IServiceMessage = new ServiceMessage();
  message.MessageType = WSMessageType.GET_FILE_CLEAN_UP_DETAILS;
  const response = await srhub.SendAsync(message);
  const data: IDirectoryCleanUpScanDetailsMessage = response.Payload;
  return data;
});

export const fetchLast30DaysReport = createAsyncThunk<
  ILast30DaysFileCleanupReport,
  void,
  {
    state: RootState;
  }
>("fileCleanupScan/fetchLast30DaysReport", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const uuid = selectCurrentUuid(state);
  const url = `/api/fileCleanup/history/${uuid}`;

  try {
    const apiResponse = await RESTGatewayAPI.get<ILast30DaysFileCleanupReport>(
      url
    );
    return apiResponse.data;
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch last 30 days report : ${error}`
    );
  }
});

export const openRecycleBin = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("fileCleanupScan/openRecycleBin", async (_) => {
  const srhub = getSignalRHub();
  const message: IServiceMessage = new ServiceMessage();
  message.MessageType = WSMessageType.OPEN_RECYCLE_BIN;
  return srhub.SendAsync(message);
});

export const fetchScanOptions = createAsyncThunk<
  IFileCleanupOptions,
  void,
  { state: RootState }
>("fileCleanupScan/fetchScanOptions", async (_) => {
  const temp = localStorage.getItem(localStorageStrings.tempFiles);
  const internet = localStorage.getItem(localStorageStrings.internetFiles);
  const recycling = localStorage.getItem(localStorageStrings.recycling);

  const options: IFileCleanupOptions = {
    tempFilesEnabled: temp !== null ? temp === "true" : true,
    internetFilesEnabled: internet !== null ? internet === "true" : true,
    recyclingEnabled: recycling !== null ? recycling == "true" : true,
  };
  return options;
});

export const setScanOptions = createAsyncThunk<
  IFileCleanupOptions,
  IFileCleanupOptions,
  { state: RootState }
>("fileCleanupScan/setScanOptions", async (payload) => {
  localStorage.setItem(
    localStorageStrings.tempFiles,
    payload.tempFilesEnabled.toString()
  );
  localStorage.setItem(
    localStorageStrings.internetFiles,
    payload.internetFilesEnabled.toString()
  );
  localStorage.setItem(
    localStorageStrings.recycling,
    payload.recyclingEnabled.toString()
  );
  return payload;
});

const initialState: IReducerState<IFileCleanupData> = {
  data: {
    scan: null,
    details: null,
    results: null,
    last30DaysReport: null,
    doingFileCleanup: false,
    doingScan: false,
    options: null,
  },
  status: {
    [fetchLast30DaysReport.typePrefix]: ReducerStatus.Idle,
    [fetchFileCleanupScan.typePrefix]: ReducerStatus.Idle,
    [getFileCleanupDetails.typePrefix]: ReducerStatus.Idle,
    [startFileCleanupScan.typePrefix]: ReducerStatus.Idle,
    [doFileCleanup.typePrefix]: ReducerStatus.Idle,
    [openRecycleBin.typePrefix]: ReducerStatus.Idle,
    [fetchScanOptions.typePrefix]: ReducerStatus.Idle,
    [fetchFileCleanupResults.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const fileCleanupSlice = createSlice({
  name: "fileCleanupScan",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<IFileCleanupScan>) => {
      state.data.scan = action.payload;
    },
    completeFileCleanup: (
      state,
      action: PayloadAction<IFileCleanUpRemovalResultsMessage>
    ) => {
      state.data.doingFileCleanup = false;
      state.data.results = action.payload;
      state.data.scan = null;
    },
    completeScan: (state) => {
      state.data.doingScan = false;
    },
    clearResults: (state) => {
      state.data.results = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFileCleanupScan.pending, (state) => {
        state.status[fetchFileCleanupScan.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(fetchFileCleanupScan.fulfilled, (state, action) => {
        if (state.data) {
          state.data.scan = action.payload;
          state.error = "";
          state.status[fetchFileCleanupScan.typePrefix] =
            ReducerStatus.Succeeded;
        }
      })
      .addCase(fetchFileCleanupScan.rejected, (state, action) => {
        state.status[fetchFileCleanupScan.typePrefix] = ReducerStatus.Failed;
        state.error = action.payload as string;
      })
      .addCase(getFileCleanupDetails.pending, (state) => {
        state.status[getFileCleanupDetails.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(getFileCleanupDetails.fulfilled, (state, action) => {
        if (state.data) {
          state.data.details = action.payload;
          state.error = "";
          state.status[getFileCleanupDetails.typePrefix] =
            ReducerStatus.Succeeded;
        }
      })
      .addCase(getFileCleanupDetails.rejected, (state, action) => {
        state.status[getFileCleanupDetails.typePrefix] = ReducerStatus.Failed;
        state.error = action.payload as string;
      })
      .addCase(doFileCleanup.pending, (state) => {
        state.status[doFileCleanup.typePrefix] = ReducerStatus.Loading;
        state.data.results = null;
      })
      .addCase(doFileCleanup.fulfilled, (state) => {
        if (state.data) {
          state.data.doingFileCleanup = true;
          state.error = "";
          state.status[doFileCleanup.typePrefix] = ReducerStatus.Succeeded;
        }
      })
      .addCase(doFileCleanup.rejected, (state, action) => {
        state.status[doFileCleanup.typePrefix] = ReducerStatus.Failed;
        state.error = action.payload as string;
      })
      .addCase(fetchLast30DaysReport.pending, (state) => {
        state.status[fetchLast30DaysReport.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(fetchLast30DaysReport.fulfilled, (state, action) => {
        if (state.data) {
          state.data.last30DaysReport = action.payload;
          state.error = "";
          state.status[fetchLast30DaysReport.typePrefix] =
            ReducerStatus.Succeeded;
        }
      })
      .addCase(fetchLast30DaysReport.rejected, (state, action) => {
        state.status[fetchLast30DaysReport.typePrefix] = ReducerStatus.Failed;
        state.error = action.payload as string;
      })
      .addCase(startFileCleanupScan.pending, (state) => {
        state.status[startFileCleanupScan.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(startFileCleanupScan.fulfilled, (state) => {
        if (state.data) {
          state.data.doingScan = true;
          state.error = "";
          state.status[startFileCleanupScan.typePrefix] =
            ReducerStatus.Succeeded;
        }
      })
      .addCase(startFileCleanupScan.rejected, (state, action) => {
        state.status[startFileCleanupScan.typePrefix] = ReducerStatus.Failed;
        state.error = action.payload as string;
      })
      .addCase(openRecycleBin.pending, (state) => {
        state.status[openRecycleBin.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(openRecycleBin.fulfilled, (state) => {
        if (state.data) {
          state.error = "";
          state.status[openRecycleBin.typePrefix] = ReducerStatus.Succeeded;
        }
      })
      .addCase(openRecycleBin.rejected, (state, action) => {
        state.status[openRecycleBin.typePrefix] = ReducerStatus.Failed;
        state.error = action.payload as string;
      })
      .addCase(fetchScanOptions.pending, (state) => {
        state.status[fetchScanOptions.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(fetchScanOptions.fulfilled, (state, action) => {
        state.status[fetchScanOptions.typePrefix] = ReducerStatus.Succeeded;
        state.data.options = action.payload;
      })
      .addCase(fetchScanOptions.rejected, (state, action) => {
        state.status[fetchScanOptions.typePrefix] = ReducerStatus.Failed;
      })
      .addCase(setScanOptions.fulfilled, (state, action) => {
        state.data.options = action.payload;
      })
      .addCase(fetchFileCleanupResults.pending, (state, action) => {
        state.status[fetchFileCleanupResults.typePrefix] =
          ReducerStatus.Loading;
      })
      .addCase(fetchFileCleanupResults.fulfilled, (state, action) => {
        state.status[fetchFileCleanupResults.typePrefix] =
          ReducerStatus.Succeeded;
        state.data.results = action.payload;
      })
      .addCase(fetchFileCleanupResults.rejected, (state, action) => {
        state.status[fetchFileCleanupResults.typePrefix] = ReducerStatus.Failed;
      });
  },
});

export const {
  set,
  completeFileCleanup,
  completeScan,
  clearResults,
} = fileCleanupSlice.actions;

export const selectFileCleanupScan = (state: RootState) =>
  state.fileCleanupScan.data.scan;

export const selectFileCleanupResults = (state: RootState) =>
  state.fileCleanupScan.data.results;

export const selectDoingFileCleanup = (state: RootState) =>
  state.fileCleanupScan.data.doingFileCleanup;

export const selectDoingScan = (state: RootState) =>
  state.fileCleanupScan.data.doingScan;

export const selectLast30DaysReport = (state: RootState) =>
  state.fileCleanupScan.data.last30DaysReport;

export const selectFileCleaningDetails = (state: RootState) =>
  state.fileCleanupScan.data.details;

export const selectFileCleanupScanOptions = (state: RootState) =>
  state.fileCleanupScan.data.options;

export const selectTempFilesEnabled = (state: RootState) =>
  state.fileCleanupScan.data.options?.tempFilesEnabled ?? false;

export const selectInternetFilesEnabled = (state: RootState) =>
  state.fileCleanupScan.data.options?.internetFilesEnabled ?? false;

export const selectRecyclingEnabled = (state: RootState) =>
  state.fileCleanupScan.data.options?.recyclingEnabled ?? false;

export const selectFetchScanStatus = (state: RootState) =>
  state.fileCleanupScan.status[fetchFileCleanupScan.typePrefix];

export const selectFetchReportStatus = (state: RootState) =>
  state.fileCleanupScan.status[fetchLast30DaysReport.typePrefix];

export const selectGetDetailsStatus = (state: RootState) =>
  state.fileCleanupScan.status[getFileCleanupDetails.typePrefix];

export const selectStartScanStatus = (state: RootState) =>
  state.fileCleanupScan.status[startFileCleanupScan.typePrefix];

export const selectDoFileCleanupStatus = (state: RootState) =>
  state.fileCleanupScan.status[doFileCleanup.typePrefix];

export const selectStatus = (state: RootState) => state.fileCleanupScan.status;

// active (true) == has scan and has results
// needs attention (false) == no scan or results
export const selectIsFileCleaningTileActive = (state: RootState) => {
  if (state.fileCleanupScan.data.scan == null) {
    return false;
  }
  if (state.fileCleanupScan.data.results == null) {
    return false;
  }
  return (
    state.fileCleanupScan.data.scan != null &&
    state.fileCleanupScan.data.results != null
  );
};

export const selectFileCleaningNeedsAttention = (state: RootState) => {
  if (state.fileCleanupScan.data.results == null) {
    return true;
  }

  return state.fileCleanupScan.data.scan != null;
};

export default fileCleanupSlice.reducer;
