import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import RESTGatewayAPI from "api/gatewayAPI";
import { UserInterface, FieldInterface } from "./addTeamMemberModal/types";
import { ReducerStatus } from "model/IReducerState";
import { selectCurrentUser } from "session/SessionSlice";
import {
  selectIsAppOptimizationActive,
  selectIsDecptorBlockingActive,
  selectIsDnsProtectionActive,
} from "features/settings/SettingsSlice";
import { selectOutOfDateDriversCount } from "features/scan/ScanSlice";
import { selectIsFileCleaningTileActive } from "features/fileCleaning/FileCleaningSlice";

interface ITeamState {
  data: {
    addingTeamMember: boolean;
    deleteUser: string;
  };
  error: {
    insertUsers: string;
    deleteUser: string;
  };
  status: {
    deleteUser: ReducerStatus;
    resendInvite: ReducerStatus;
  };
}

const initialState: ITeamState = {
  data: {
    addingTeamMember: false,
    deleteUser: "",
  },
  error: {
    insertUsers: "",
    deleteUser: "",
  },
  status: {
    deleteUser: ReducerStatus.Idle,
    resendInvite: ReducerStatus.Idle,
  },
};

export const insertUsers = createAsyncThunk<
  {
    data: string[];
    error?: string;
  },
  {
    newSeats: {
      name: string;
      email: string;
    }[];
  },
  {
    state: RootState;
  }
>("userManagement/insertUsers", async ({ newSeats }, thunkApi) => {
  try {
    //Get currentRegKey
    const state = thunkApi.getState();
    const currentRegKey = state?.session?.data?.currentRegKey;

    // Retrieve latest no cache user list to verify if the users are repeated
    const getUsersUrl = `/api/organization/users/${currentRegKey}`;

    const response = await RESTGatewayAPI.get<UserInterface[]>(getUsersUrl);
    const responsePayload = response.data;

    //something went wrong, how handle?
    if (responsePayload == null || responsePayload.length === 0) {
      return {
        data: [],
        error: "Unable to verify previous users",
      };
    }

    // Emails from the form
    const emailSeats = newSeats?.map(
      (newSeat: FieldInterface) => newSeat.email
    );

    const found = emailSeats.some((email) =>
      responsePayload.map((r: UserInterface) => r.email).includes(email)
    );

    if (found) {
      return {
        data: [],
        error: "An email already exist",
      };
    }

    // const createUsersUrl = `/api/user`;

    // const postUser = newSeats.map((seat: FieldInterface) => {
    //   const names = seat?.name?.split(" ");
    //   const body = {
    //     RegistrationKey: currentRegKey,
    //     Email: seat.email,
    //     Name: seat.name,
    //     firstName: names?.[0],
    //     lastName: names?.[1],
    //   };

    //   return RESTGatewayAPI.put(createUsersUrl, body);
    // });

    // const insertResponse = await Promise.all(postUser);

    return {
      data: [],
      error: "",
    };
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch motherboard models : ${error}`
    );
  }
});

export const deleteUsers = createAsyncThunk<
  {
    status: ReducerStatus;
    error?: string;
    data: string;
  },
  {
    email: string;
  },
  {
    state: RootState;
  }
>("userManagement/deleteUser", async ({ email }, thunkApi) => {
  try {
    //Get currentRegKey
    const state = thunkApi.getState();
    const currentRegKey = state?.session?.data?.currentRegKey;
    const sessionEmail = selectCurrentUser(state)?.email;

    // Validation to avoid delete current user
    if (email === sessionEmail) {
      return {
        status: ReducerStatus.Failed,
        data: email,
        error: "You can't delete your own user",
      };
    }

    const usersAPI = `/api/user/${currentRegKey}/${email}`;
    const response = await RESTGatewayAPI.delete(usersAPI);

    // Verify if the response is correct
    if (response.status === 200 && response.statusText === "OK") {
      return {
        status: ReducerStatus.Succeeded,
        data: email,
        error: "",
      };
    } else {
      return {
        status: ReducerStatus.Failed,
        data: email,
        error: "",
      };
    }
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch motherboard models : ${error}`
    );
  }
});

export const resendInvite = createAsyncThunk<
  void,
  {
    email: string;
  },
  {
    state: RootState;
  }
>("userManagement/resendInvite", async ({ email }, thunkApi) => {
  try {
    const usersAPI = `/api/User/${email}/onboarding`;
    const response = await RESTGatewayAPI.post(usersAPI);

    // Verify if the response is correct
    if (!(response.status === 200 && response.statusText === "OK")) {
      throw new Error("Unable to re send invite");
    }
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch motherboard models : ${error}`
    );
  }
});

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setAddingTeamMember: (state, action: PayloadAction<boolean>) => {
      state.data.addingTeamMember = action.payload;
    },
    setDeleteStatus: (state) => {
      state.status.deleteUser = ReducerStatus.Idle;
      state.error.deleteUser = "";
      state.data.deleteUser = "";
    },
    resetResendInvite: (state) => {
      state.status.resendInvite = ReducerStatus.Idle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(insertUsers.fulfilled, (state, action) => {
      state.error.insertUsers = action.payload.error || "";
    });
    builder.addCase(deleteUsers.fulfilled, (state, action) => {
      state.status.deleteUser = action.payload.status;
      state.error.deleteUser = action.payload?.error || "";
      state.data.deleteUser = action.payload.data;
    });
    builder.addCase(deleteUsers.pending, (state) => {
      state.status.deleteUser = ReducerStatus.Loading;
    });
    // Resend invite reducers
    builder.addCase(resendInvite.pending, (state) => {
      state.status.resendInvite = ReducerStatus.Loading;
    });
    builder.addCase(resendInvite.fulfilled, (state) => {
      state.status.resendInvite = ReducerStatus.Succeeded;
    });
    builder.addCase(resendInvite.rejected, (state) => {
      state.status.resendInvite = ReducerStatus.Failed;
    });
  },
});

export const {
  setAddingTeamMember,
  setDeleteStatus,
  resetResendInvite,
} = teamSlice.actions;

export const selectAddingTeamMember = (state: RootState) =>
  state.team.data.addingTeamMember;

export const statusDeleteUser = (state: RootState) =>
  state.team.status.deleteUser;
export const errorDeleteUser = (state: RootState) =>
  state.team.error.deleteUser;
export const dataDeleteUser = (state: RootState) => state.team.data.deleteUser;

// Status for resend invite
export const getResendStatus = (state: RootState) =>
  state.team.status.resendInvite;

// this selector spans multiple slices and should probably be in its own file
export const selectNumServicesThatNeedAttention = (state: RootState) => {
  let count = 0;
  if (!selectIsAppOptimizationActive(state)) {
    count++;
  }
  if (!selectIsDnsProtectionActive(state)) {
    count++;
  }
  if (!selectIsDecptorBlockingActive(state)) {
    count++;
  }
  if (selectOutOfDateDriversCount(state) > 0) {
    count++;
  }
  if (!selectIsFileCleaningTileActive(state)) {
    count++;
  }
  // check for window_enhancement status here
  return count;
};

export default teamSlice.reducer;
