import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import installIcon from "assets/img/driver/icn-driver-takinglong.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      width: "504px",
      height: "588px",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        width: "327px",
        height: "529px",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(2),
      },
    },
    actionButton: {
      width: "225px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: "270px",
      },
    },
    description_container: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    done_container: {
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    done_button_container: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    divider: {
      width: "80%",
    },
    wait_container: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    wait_button_container: {
      marginTop: theme.spacing(2),
    },
  })
);
//#endregion Component Styles

//#region Component Props
export interface IInstallTimedOutProps {
  onWait: () => void;
  onForceInstall: () => void;
}
//#endregion Component Props

//#region Component
export const InstallTimedOut: React.FC<IInstallTimedOutProps> = (props) => {
  const classes = useStyles();
  return (
    <Box
      data-qatag="driver.modal.install.timed_out"
      id="driver.modal.install.timed_out"
      className={classes.modalRoot}
    >
      <img
        data-qatag="driver.modal.install.timed_out.icon"
        id="driver.modal.install.timed_out.icon"
        src={installIcon}
        alt=""
      />

      <FormattedMessage
        data-qatag="driver.modal.install.timed_out.title"
        id="driver.modal.install.timed_out.title"
        defaultMessage="It looks like your install is taking {br} longer than expected."
        values={{
          br: (
            <br
              data-qatag="driver.modal.install.timed_out.break"
              id="driver.modal.install.timed_out.break"
            />
          ),
        }}
      >
        {(text) => (
          <Typography
            data-qatag="driver.modal.install.timed_out.title.text"
            id="driver.modal.install.timed_out.title.text"
            variant="h5"
            color="textPrimary"
            align="center"
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <Box
        data-qatag="driver.modal.install.timed_out.description_container"
        id="driver.modal.install.timed_out.description_container"
        className={classes.description_container}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.timed_out.description"
          id="driver.modal.install.timed_out.description"
          defaultMessage="An excessive amount of time has passed and the driver install may have already finished."
          values={{
            br: (
              <br
                data-qatag="driver.modal.install.timed_out.break2"
                id="driver.modal.install.timed_out.break2"
              />
            ),
          }}
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.timed_out.description"
              id="driver.modal.install.timed_out.description"
              variant="body2"
              color="primary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
      <Box
        data-qatag="driver.modal.install.timed_out.action.done_container"
        id="driver.modal.install.timed_out.action.done_container"
        className={classes.done_container}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.timed_out.action.done_label"
          id="driver.modal.install.timed_out.action.done_label"
          defaultMessage="Is the installation already finished?"
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.timed_out.action.done_label.text"
              id="driver.modal.install.timed_out.action.done_label.text"
              variant="body2"
              color="primary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
        <Box
          data-qatag="driver.modal.install.timed_out.action.done.button_container"
          id="driver.modal.install.timed_out.action.done.button_container"
          className={classes.done_button_container}
        >
          <AppButton
            data-qatag="driver.modal.install.timed_out.action.done.button"
            id="driver.modal.install.timed_out.action.done.button"
            className={classes.actionButton}
            buttonStyle={AppButtonStyles.Green}
            onClick={props.onForceInstall}
          >
            <FormattedMessage
              data-qatag="driver.modal.install.timed_out.action.done.content"
              id="driver.modal.install.timed_out.action.done.content"
              defaultMessage="I Am Done Installing"
            >
              {(text) => (
                <Typography
                  data-qatag="driver.modal.install.timed_out.action.done.content.text"
                  id="driver.modal.install.timed_out.action.done.content.text"
                  variant="body2"
                  align="center"
                  style={{ fontWeight: 600 }}
                >
                  {text}
                </Typography>
              )}
            </FormattedMessage>
          </AppButton>
        </Box>
      </Box>
      <Box
        data-qatag="driver.modal.install.timed_out.divider_container"
        id="driver.modal.install.timed_out.divider_container"
        className={classes.divider}
      >
        <Divider
          data-qatag="driver.modal.install.timed_out.divider"
          id="driver.modal.install.timed_out.divider"
          variant="middle"
        />
      </Box>
      <Box
        data-qatag="driver.modal.install.timed_out.action.wait.container"
        id="driver.modal.install.timed_out.action.wait.container"
        className={classes.wait_container}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.timed_out.action.wait.label"
          id="driver.modal.install.timed_out.action.wait.label"
          defaultMessage="The driver is still installing, please wait a {br} few minutes longer."
          values={{
            br: (
              <br
                data-qatag="driver.modal.install.timed_out.action.wait.break3"
                id="driver.modal.install.timed_out.action.wait.break3"
              />
            ),
          }}
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.timed_out.action.wait.label.text"
              id="driver.modal.install.timed_out.action.wait.label.text"
              variant="body2"
              color="primary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
        <Box
          data-qatag="driver.modal.install.timed_out.action.wait.button_container"
          id="driver.modal.install.timed_out.action.wait.button_container"
          className={classes.wait_button_container}
        >
          <AppButton
            data-qatag="driver.modal.install.timed_out.action.wait.button"
            id="driver.modal.install.timed_out.action.wait.button"
            className={classes.actionButton}
            buttonStyle={AppButtonStyles.Green}
            onClick={props.onWait}
          >
            <FormattedMessage
              data-qatag="driver.modal.install.timed_out.action.wait.button.content"
              id="driver.modal.install.timed_out.action.wait.button.content"
              defaultMessage="I Am Still Installing"
            >
              {(text) => (
                <Typography
                  data-qatag="driver.modal.install.timed_out.action.wait.button.content.text"
                  id="driver.modal.install.timed_out.action.wait.button.content.text"
                  variant="body2"
                  align="center"
                  style={{ fontWeight: 600 }}
                >
                  {text}
                </Typography>
              )}
            </FormattedMessage>
          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};
//#endregion Component
