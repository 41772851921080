import { Typography, Box, Modal, IconButton } from "@material-ui/core";
import {
  AppButton,
  AppButtonStyles,
  useMatchTabletVertical,
  Spinner,
  Alert,
  AlertIcon,
} from "solveiq.designsystem";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./RoleModal.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IUser from "model/user/IUser";
import {
  updateUserRole,
  selectRoleStatus,
  setRoleStatus,
  selectError,
} from "../RoleSlice";
import { RootState } from "app/store";
import { ReducerStatus } from "model/IReducerState";
import {
  fetchOrganizationUsers,
  setUsersRoleStatus,
} from "model/user/UserSlice";
import { setLicenseStatus } from "model/license/LicenseSlice";

export interface IRoleModal {
  /* Currently user role */
  defaultRole: string;
  /* Controls to open the alert */
  isOpen: boolean;
  /* Callback to close the modal */
  closeHandler: () => void;
  /* User information */
  userInfo: IUser;
  /* Retrieve user update role status */
  updateUserRoleStatus?: (state: RootState) => ReducerStatus;
}

const RoleModal: React.FC<IRoleModal> = ({
  defaultRole,
  closeHandler,
  userInfo,
  isOpen,
  updateUserRoleStatus = (state: RootState) => selectRoleStatus(state),
}) => {
  /* Responsive web design */
  const matchTablet = useMatchTabletVertical();

  const [role, setRole] = useState(() => defaultRole);

  const [openInformativeAlert, setOpenInformativeAlert] = useState(false);

  const dispatch = useDispatch();
  /* Request status to update user role */
  const usersStatus = useSelector(updateUserRoleStatus);
  /* Request error */
  const roleUpdateError = useSelector(selectError);
  /* Callback to close the update role modal */
  const completeUpdateRole = () => {
    closeHandler();
    dispatch(setRoleStatus());
    dispatch(setUsersRoleStatus());
    dispatch(setLicenseStatus());
    dispatch(fetchOrganizationUsers());
  };

  useEffect(() => {
    setRole(defaultRole);
  }, [defaultRole]);

  return (
    <Modal data-qatag="roleModal" open={isOpen} onClose={closeHandler}>
      <div data-qatag="container" className={styles.container}>
        <div data-qatag="content" className={styles.content}>
          <div
            data-qatag="closeIconContainer"
            className={styles.closeIconContainer}
          >
            <IconButton
              data-qatag="closeIcon"
              aria-label="close"
              onClick={closeHandler}
              className={styles.closeIcon}
            >
              <CloseIcon data-qatag="closeIcon" />
            </IconButton>
          </div>
          <Box data-qatag="boxTitle" mt={matchTablet ? 3 : 2}>
            <Typography data-qatag="titleText" align="center" variant="h3">
              Choose Your Role
            </Typography>
          </Box>
          <Box data-qatag="messageText" mt={2} textAlign="center">
            You can designate your Team’s roles below.
          </Box>

          <Box
            data-qatag="messageText"
            mt={3}
            className={`${styles.optionRole} ${
              role === "admin" ? styles.optionRoleSelected : ""
            }`}
          >
            <input
              data-qatag="adminRole"
              type="radio"
              id="admin"
              value="admin"
              name="role"
              onChange={(e) => setRole(e.target.value)}
              checked={role === "admin"}
            />{" "}
            <label
              data-qatag="labelContainer"
              htmlFor="admin"
              className={styles.labelContainer}
            >
              Administrator / Billing
            </label>
          </Box>
          <Box
            data-qatag="messageText"
            mt={3}
            className={`${styles.optionRole} ${
              role === "employee" ? styles.optionRoleSelected : ""
            }`}
          >
            <input
              data-qatag="adminRole"
              type="radio"
              value="employee"
              name="role"
              id="employee"
              onChange={(e) => setRole(e.target.value)}
              checked={role === "employee"}
            />{" "}
            <label
              data-qatag="labelContainer"
              htmlFor="employee"
              className={styles.labelContainer}
            >
              Employee
            </label>
          </Box>
          <Box data-qatag="saveButtonBox" textAlign={"center"} mt={4}>
            <AppButton
              data-qatag="saveButton"
              buttonStyle={AppButtonStyles.Green}
              className={styles.customButton}
              onClick={() => {
                // In case the same user level is inserted
                if (defaultRole === role) {
                  setOpenInformativeAlert(true);
                  return;
                }
                dispatch(
                  updateUserRole({
                    user: userInfo,
                    role: role === "admin" ? 14 : 1,
                  })
                );
              }}
            >
              <Typography data-qatag="saveText" align="center" variant="body2">
                Save
              </Typography>
            </AppButton>
            <Box data-qatag="buttonContainer" mt={1} textAlign={"center"}>
              <AppButton
                data-qatag="cancelButton"
                buttonStyle={AppButtonStyles.TransparentWhite}
                className={styles.customButton}
                onClick={closeHandler}
              >
                <Typography
                  data-qatag="cancelText"
                  align="center"
                  variant="body2"
                  className={styles.cancelButtonText}
                >
                  Cancel
                </Typography>
              </AppButton>
            </Box>
          </Box>
        </div>
        {/* Loading Spinner*/}
        <Spinner
          data-qatag="loadingSpinner"
          isActive={usersStatus === ReducerStatus.Loading}
          text="Loading..."
        />

        {/* Success Alert */}
        <Alert
          icon={AlertIcon.Success}
          title="Completed"
          buttonText="Close"
          text="The role was changed"
          data-qatag="alertSuccess"
          isOpen={usersStatus === ReducerStatus.Succeeded}
          approveHandler={completeUpdateRole}
          closeHandler={completeUpdateRole}
        />

        {/* Error Alert */}
        <Alert
          icon={AlertIcon.Warning}
          title="Error"
          buttonText="Close"
          text={roleUpdateError || "Something went wrong"}
          data-qatag="alertError"
          isOpen={usersStatus === ReducerStatus.Failed}
          approveHandler={completeUpdateRole}
          closeHandler={completeUpdateRole}
        />

        {/* Informative Alert, another alert because the close handler */}
        <Alert
          icon={AlertIcon.Warning}
          title="Error"
          buttonText="Close"
          text={"Same user role submitted"}
          data-qatag="alertError"
          isOpen={openInformativeAlert}
          approveHandler={() => setOpenInformativeAlert(false)}
          closeHandler={() => setOpenInformativeAlert(false)}
        />
      </div>
    </Modal>
  );
};

export default RoleModal;
