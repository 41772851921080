import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  getCategoryFromName,
  getDeviceDriverStatusesFromName,
  getScanTypeFromName,
  SystemOptimizationCategory,
} from "core/enumerations/Scan";
import { IFetchScanPayload } from "model/IFetchScanPayload";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import {
  AllScansCompleteMessage,
  IDeviceMessage,
  IHardwareScanMessage,
  IHardwareScanPayload,
  IHardwareScanPayloadMessage,
  IMachineIntelligence,
  IScanProgressMessage,
  ISystemInfoPayload,
  ISystemInfoScanMessage,
} from "model/messaging/messages/scanMessages";
import { IDeviceInfo } from "model/scan/DeviceInfo";
import moment from "moment";
import { IServiceMessage, ServiceMessage } from "ui.common";
import { WSMessageType } from "ui.common/lib/signalR/apiTypes/WSMessageType";
import { IHardwareScanInfo } from "../../model/scan/HardwareScanInfo";
import { ISoftwareInfo } from "../../model/scan/SoftwareInfo";
import {
  getNewSystemInfoInfo,
  ISystemInfoInfo,
} from "../../model/scan/SystemInfo";
import {
  getNewSystemOptimizationScanDocument,
  IAOOptimizationSettingDocument,
  ISystemOptimizationScanDocument,
} from "../../model/scan/SystemOptimizationScanDocument";
import { formatBytes, humanFileSize } from "core/Helpers";
import { IServiceMessageGeneric } from "model/messaging/messages/IServiceMessageGeneric";
import { IntelligenceTypes } from "model/messaging/payloads/IUpdateMachineIntelligencePayload";
import { IDeviceOverviewProps } from "features/status/deviceOverview/DeviceOverview";
import { ISystemOptimizationInfo } from "model/scan/SystemOptimizationInfo";
import displayIcon from "../../assets/img/deviceCategories/display.png";
import inputIcon from "../../assets/img/deviceCategories/input.png";
import keyboardIcon from "../../assets/img/deviceCategories/keyboard.png";
import mediaIcon from "../../assets/img/deviceCategories/Media.png";
import mouseIcon from "../../assets/img/deviceCategories/mouse.png";
import networkIcon from "../../assets/img/deviceCategories/network.png";
import otherIcon from "../../assets/img/deviceCategories/other.png";
import printerIcon from "../../assets/img/deviceCategories/printer.png";
import soundIcon from "../../assets/img/deviceCategories/sound.png";
import storageIcon from "../../assets/img/deviceCategories/storage.png";
import usbIcon from "../../assets/img/deviceCategories/UsbDevice.png";
import defaultOverviewMachine from "../../assets/img/status/statusOverviewImage.png";
import RESTGatewayAPI from "api/gatewayAPI";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
// import { IAgentInfo } from "model/scan/AgentInfo";
import { fetchDriverState } from "features/driverInstall/Thunks";
import { selectCurrentUuid } from "session/SessionSlice";
import { statusDeleteUser } from "features/teamManagement/TeamSlice";

interface IScanState {
  hardwareScan: IHardwareScanInfo | null;
  systemOptimizationScan: ISystemOptimizationScanDocument;
  softwareScan: ISoftwareInfo[];
  systemInfoScan: ISystemInfoInfo;
  //hasAgentScan: boolean;
  systemOptimizations: ISystemOptimizationInfo[];
  installedSoftware: Record<string, unknown>;

  isScanning: boolean;
  scanProgress: number;
}

export const hardwareScanReceived = createAsyncThunk<
  IHardwareScanPayloadMessage,
  IHardwareScanPayloadMessage,
  { state: RootState }
>("scan/hardwareScanReceived", async (message, thunkApi) => {
  thunkApi.dispatch(fetchDriverState());
  return message;
});

export const startAllScans = createAsyncThunk<void, void, { state: RootState }>(
  "scan/startAllScans",
  async (_, thunkApi) => {
    const srhub = getSignalRHub();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.START_ALL_SCANS;
    const response = await srhub.SendAsync(message);
    thunkApi.dispatch(getSystemInfoScan());
    return response;
  }
);

export const fetchHardwareScan = createAsyncThunk<
  IHardwareScanPayload,
  void,
  { state: RootState }
>("scan/fetchHardwareScan", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const uuid = selectCurrentUuid(state);

  try {
    const url = `/api/scan/hardware/${uuid}`;

    const response = await RESTGatewayAPI.get(url);
    const apiResponse: IFetchScanPayload = response.data;

    if (!apiResponse.dataResult) {
      return thunkApi.rejectWithValue(
        "Unable to fetch hardware scan : response was empty"
      );
    }

    return JSON.parse(apiResponse.payload);
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch hardware scan : ${error}`);
  }
});

export const allScansCompleted = createAsyncThunk<
  AllScansCompleteMessage,
  AllScansCompleteMessage,
  { state: RootState }
>(
  "scan/allScansCompleted",
  async (message: AllScansCompleteMessage, thunkApi) => {
    const scan = message.HardwareScan;

    if (scan != null) {
      // const ids = scan.Devices.filter((d) => d.HasUpdateAvailable).map(
      //   (d) => d.DeviceID
      // );
      //thunkApi.dispatch(requestDriverDownloads(ids));
      //thunkApi.dispatch(agentScanReceived());
    }
    return message;
  }
);
export const fetchSystemOptimizationScan = createAsyncThunk<
  ISystemOptimizationScanDocument,
  void,
  { state: RootState }
>("scan/fetchSystemOptimizationScan", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const uuid = selectCurrentUuid(state);

  try {
    const url = `/api/scan/optimization/${uuid}`;

    const response = await RESTGatewayAPI.get(url);
    const apiResponse: IFetchScanPayload = response.data;

    if (!apiResponse.dataResult) {
      return thunkApi.rejectWithValue(
        "Unable to fetch system optimization scan : response was empty"
      );
    }

    return JSON.parse(apiResponse.payload);
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch system optimization scan : ${error}`
    );
  }
});

export const fetchSystemInfoScan = createAsyncThunk<
  ISystemInfoPayload,
  void,
  { state: RootState }
>("scan/fetchSystemInfoScan", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const uuid = selectCurrentUuid(state);

  try {
    const url = `/api/scan/systeminfo/${uuid}`;

    const response = await RESTGatewayAPI.get(url);
    const apiResponse: IFetchScanPayload = response.data;

    if (!apiResponse.dataResult) {
      return thunkApi.rejectWithValue(
        "Unable to fetch system info scan : response was empty"
      );
    }

    return JSON.parse(apiResponse.payload);
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch system info scan : ${error}`
    );
  }
});

export const getSystemInfoScan = createAsyncThunk<
  ISystemInfoScanMessage,
  void,
  { state: RootState }
>("scan/getSystemInfoScan", async (_, thunkApi) => {
  const srhub = getSignalRHub();
  const message: IServiceMessage = new ServiceMessage();
  message.MessageType = WSMessageType.GET_SYSTEM_INFO_SCAN;
  const response = (await srhub.SendAsync(
    message
  )) as IServiceMessageGeneric<ISystemInfoScanMessage>;
  return response.Payload;
});

// export const getHasAgentScan = createAsyncThunk<
//   boolean,
//   void,
//   { state: RootState }
// >("scan/getHasAgentScan", async (_, thunkApi) => {
//   try {
//     const uuid = thunkApi.getState().session.data.currentUuid;
//     const srhub = getSignalRHub();
//     const message: IServiceMessage = new ServiceMessage();
//     message.MessageType = WSMessageType.GET_AGENT_INFO;

//     const response = (await srhub.SendAsync(
//       uuid,
//       message
//     )) as IServiceMessageGeneric<IAgentInfo>;

//     const lastScanStart =
//       response.Payload.RegState.Current.AllScansInfo.LastStopTime;
//     const lastScanNull = lastScanStart == null;
//     return !lastScanNull;
//   } catch (error) {
//     return thunkApi.rejectWithValue(
//       `Unable to fetch agent scan existence : ${error}`
//     );
//   }
// });

const initialState: IReducerState<IScanState> = {
  data: {
    hardwareScan: null,
    systemOptimizationScan: getNewSystemOptimizationScanDocument(),
    softwareScan: [],
    systemInfoScan: getNewSystemInfoInfo(),
    systemOptimizations: [],
    installedSoftware: {},
    isScanning: false,
    scanProgress: 0.0,
    //hasAgentScan: false,
  },
  status: {
    [allScansCompleted.typePrefix]: ReducerStatus.Idle,
    [startAllScans.typePrefix]: ReducerStatus.Idle,
    [fetchHardwareScan.typePrefix]: ReducerStatus.Idle,
    [fetchSystemOptimizationScan.typePrefix]: ReducerStatus.Idle,
    [fetchSystemInfoScan.typePrefix]: ReducerStatus.Idle,
    [getSystemInfoScan.typePrefix]: ReducerStatus.Idle,
    // [getHasAgentScan.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const scanSlice = createSlice({
  name: "scan",
  initialState,
  reducers: {
    updateProgress: (state, action: PayloadAction<IScanProgressMessage>) => {
      if (state.data) {
        state.data.scanProgress = action.payload.PercentComplete / 100;
      }
    },
    // completeScan: (state, action: PayloadAction<AllScansCompleteMessage>) => {
    //   if (state.data) {
    //     state.data.isScanning = false;
    //     state.data.hardwareScan = processHardwareScan(
    //       action.payload.HardwareScan
    //     );
    //     state.data.systemOptimizationScan = action.payload.SystemScan;
    //     state.data.systemOptimizations = processSystemOptimizations(
    //       action.payload.SystemScan
    //     );
    //     state.data.softwareScan = action.payload.JitTriggers.map<ISoftwareInfo>(
    //       (jit) => getNewSoftwareInfo(jit)
    //     );
    //     //state.data.hasAgentScan = true;
    //   }
    //},
    // hardwareScanReceived: (
    //   state,
    //   action: PayloadAction<IHardwareScanPayloadMessage>
    // ) => {
    //   state.data.hardwareScan = processHardwareScan(
    //     action.payload.HardwareScanDocument
    //   );
    // },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(getHasAgentScan.pending, (state) => {
      //   state.status[getHasAgentScan.typePrefix] = ReducerStatus.Loading;
      // })
      // .addCase(getHasAgentScan.fulfilled, (state, action) => {
      //   state.status[getHasAgentScan.typePrefix] = ReducerStatus.Succeeded;
      //   state.data.hasAgentScan = action.payload;
      //   state.error = "";
      // })
      // .addCase(getHasAgentScan.rejected, (state, action) => {
      //   state.status[getHasAgentScan.typePrefix] = ReducerStatus.Failed;
      //   state.error = action.payload as string;
      // })
      .addCase(
        hardwareScanReceived.fulfilled,
        (state, action: PayloadAction<IHardwareScanPayloadMessage>) => {
          state.data.hardwareScan = processHardwareScan(
            action.payload.HardwareScanDocument
          );
        }
      )
      .addCase(startAllScans.pending, (state) => {
        state.status[startAllScans.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(startAllScans.fulfilled, (state) => {
        if (state.data) {
          state.data.isScanning = true;
          state.error = "";
          state.status[startAllScans.typePrefix] = ReducerStatus.Succeeded;
        }
      })
      .addCase(startAllScans.rejected, (state, action) => {
        state.status[startAllScans.typePrefix] = ReducerStatus.Failed;
        state.error = action.payload as string;
      })
      .addCase(fetchHardwareScan.pending, (state) => {
        state.status[fetchHardwareScan.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(fetchHardwareScan.fulfilled, (state, action) => {
        state.status[fetchHardwareScan.typePrefix] = ReducerStatus.Succeeded;
        state.data.hardwareScan = processHardwareScan(action.payload);
      })
      .addCase(fetchSystemOptimizationScan.pending, (state) => {
        state.status[fetchSystemOptimizationScan.typePrefix] =
          ReducerStatus.Loading;
      })
      .addCase(fetchSystemOptimizationScan.fulfilled, (state, action) => {
        state.status[fetchSystemOptimizationScan.typePrefix] =
          ReducerStatus.Succeeded;
        state.data.systemOptimizationScan = action.payload;
        state.data.systemOptimizations = processSystemOptimizations(
          action.payload
        );
      })
      .addCase(fetchSystemInfoScan.fulfilled, (state, action) => {
        state.status[fetchSystemInfoScan.typePrefix] = ReducerStatus.Succeeded;
        state.data.systemInfoScan = processSystemInfoScan(
          action.payload.EventData
        );
      })
      .addCase(getSystemInfoScan.fulfilled, (state, action) => {
        state.status[getSystemInfoScan.typePrefix] = ReducerStatus.Succeeded;
        state.data.systemInfoScan = processSystemInfoScan(action.payload);
      })
      .addCase(getSystemInfoScan.rejected, (state, action) => {
        state.status[getSystemInfoScan.typePrefix] = ReducerStatus.Failed;
        state.error = action.payload as string;
      })
      .addCase(allScansCompleted.fulfilled, (state) => {
        state.status[allScansCompleted.typePrefix] = ReducerStatus.Succeeded;
        //if we got all scans complete we have an agent scan.
        //state.data.hasAgentScan = true;
      });
    // .addCase(allScansCompleted.fulfilled, (state, action) => {
    //   state.data.isScanning = false;
    //   state.data.hardwareScan = {
    //     operatingSystem:
    //       action.payload.HardwareScan.OperatingSystemDisplayName,
    //     outOfDateDriverCount:
    //       action.payload.HardwareScan.DeviceScanSummary.OutOfDateCount,
    //     systemDriverCount:
    //       action.payload.HardwareScan.DeviceScanSummary.SystemDeviceCount,
    //     totalDriverCount:
    //       action.payload.HardwareScan.DeviceScanSummary.TotalCount,
    //     upToDateDriverCount:
    //       action.payload.HardwareScan.DeviceScanSummary.UpToDateCount,
    //     unavailableMissingDriverCount:
    //       action.payload.HardwareScan.DeviceScanSummary
    //         .UnavailableMissingCount,
    //     scanType: getScanTypeFromName(action.payload.HardwareScan.ScanType),
    //     devices: action.payload.HardwareScan.Devices.map((device) =>
    //       buildDevice(device)
    //     ),
    //   };
    // });
  },
});

function processHardwareScan(
  hs: IHardwareScanPayload | IHardwareScanMessage
): IHardwareScanInfo {
  return {
    operatingSystem: hs.OperatingSystemDisplayName,
    outOfDateDriverCount: hs.DeviceScanSummary.OutOfDateCount,
    systemDriverCount: hs.DeviceScanSummary.SystemDeviceCount,
    totalDriverCount: hs.DeviceScanSummary.TotalCount,
    upToDateDriverCount: hs.DeviceScanSummary.UpToDateCount,
    unavailableMissingDriverCount: hs.DeviceScanSummary.UnavailableMissingCount,
    scanType: getScanTypeFromName(hs.ScanType),
    devices: hs.Devices.map((device) => buildDevice(device)),
  };
}

function processSystemInfoScan(
  systemInfoScan: ISystemInfoScanMessage
): ISystemInfoInfo {
  const infoScan = getNewSystemInfoInfo();
  if (
    systemInfoScan.GeoData != null &&
    systemInfoScan.GeoData.IspLogo != null
  ) {
    infoScan.ispLogoUrl = systemInfoScan.GeoData.IspLogo.DownloadURI;
  }

  if (systemInfoScan.LastNetworkScan != null) {
    infoScan.downloadSpeed = systemInfoScan.LastNetworkScan.MegabitsPerSecond.toString();
    infoScan.uploadSpeed = systemInfoScan.LastNetworkScan.UploadMegabitsPerSecond.toString();
    infoScan.ping = systemInfoScan.LastNetworkScan.RTT.toString();
    infoScan.networkScanTime = new Date(
      systemInfoScan.LastNetworkScan.ScanInfo.StartTime
    ).toLocaleString();
  } else {
    infoScan.downloadSpeed = "Unknown";
    infoScan.uploadSpeed = "Unknown";
    infoScan.ping = "Unknown";
  }

  infoScan.operatingSystem = systemInfoScan.OperatingSystem.FriendlyName;

  if (
    systemInfoScan.MachineIntelligence.IntelligenceType === IntelligenceTypes.MB
  ) {
    infoScan.pcImageUrl = getMBMachine();
  } else {
    infoScan.pcImageUrl = getOEMMachineImage(
      systemInfoScan.MachineIntelligence
    );
  }

  if (systemInfoScan.MachineIntelligence != null) {
    const displayName = systemInfoScan.MachineIntelligence.MachineDisplayName;
    if (displayName == null || displayName === "") {
      infoScan.pcModel = "Custom PC";
    } else {
      infoScan.pcModel = displayName;
    }
  }

  if (systemInfoScan.MemoryStatus != null) {
    infoScan.totalMemory = formatBytes(
      systemInfoScan.MemoryStatus.TotalPhys,
      0
    );
  }

  if (systemInfoScan.GeoData != null) {
    infoScan.isp = systemInfoScan.GeoData.IspName;
  }

  return infoScan;
}

function processSystemOptimizations(
  optimizationScan: ISystemOptimizationScanDocument
): ISystemOptimizationInfo[] {
  const optimizationList = new Array<ISystemOptimizationInfo>();
  optimizationScan.AvailableInternetSettings.forEach((setting) =>
    optimizationList.push(
      buildSystemOptimization(setting, SystemOptimizationCategory.Internet)
    )
  );
  optimizationScan.AvailableStartUpSettings.forEach((setting) =>
    optimizationList.push(
      buildSystemOptimization(setting, SystemOptimizationCategory.Startup)
    )
  );
  optimizationScan.AvailableWindowsSettings.forEach((setting) =>
    optimizationList.push(
      buildSystemOptimization(setting, SystemOptimizationCategory.Windows)
    )
  );
  return optimizationList;
}

function buildSystemOptimization(
  document: IAOOptimizationSettingDocument,
  category: SystemOptimizationCategory
): ISystemOptimizationInfo {
  // TODO: this should be promoted to application store.
  const locale = "en-US";

  const info: ISystemOptimizationInfo = {
    friendlyName: document.Setting.Name,
    description: "",
    category: category,
    optimizationCount: 1,
  };

  if (document.Setting.SettingDescriptions != null) {
    const description = document.Setting.SettingDescriptions.find(
      (sd) => sd.Culture === locale
    );
    if (description != null) {
      info.description = description.DisplayDescription;
    }
  }

  // TODO: report exception if there is no description

  return info;
}

function buildDevice(device: IDeviceMessage): IDeviceInfo {
  const deviceManufacturer =
    device.Manufacturer == null || device.Manufacturer === ""
      ? "Unknown"
      : device.Manufacturer;

  const deviceInfo: Partial<IDeviceInfo> = {
    deviceID: device.DeviceID,
    category: getCategoryFromName(device.Category),
    categoryName: device.CategoryDisplayName,
    class: device.Class,
    classRank: device.ClassRank,
    description: device.Description,
    installedDriverVersion: device.DriverVersion,
    installedDriverDate:
      device.DriverDate == null
        ? "No Driver Found"
        : moment(device.DriverDate).format("L"),
    driverStatus: getDeviceDriverStatusesFromName(device.DriverStatus),
    friendlyName: `${deviceManufacturer} ${device.CategoryDisplayName} Driver`,
    hasUpdateAvailable: device.HasUpdateAvailable,
    instanceID: device.InstanceID,
    logoBase64: getDeviceImage(device.Category),
    isPnp: device.IsPnp,
    localIP: device.LocalIP,
  };

  if (device.DriverUpdates != null) {
    deviceInfo.latestDriverVersion =
      device.DriverUpdates.RecommendedDriverVersion;
    deviceInfo.latestDriverDate = moment(
      device.DriverUpdates.RecommendedDriverDate
    ).format("L");
    deviceInfo.latestDriverFileSize = humanFileSize(
      device.DriverUpdates.RecommendedDriverPackageFile.FileSize,
      true
    );
    deviceInfo.latesetDriverGuid = device.DriverUpdates.RecommendedDriverGUID;
    deviceInfo.driverProvider = device.DriverUpdates.DriverProvider;
  } else {
    deviceInfo.latestDriverVersion = device.DriverVersion;
    deviceInfo.latestDriverDate = deviceInfo.installedDriverDate;
    deviceInfo.driverProvider = device.DriverProvider;
  }

  if (device.ResourceFiles != null && device.ResourceFiles.length > 0) {
    deviceInfo.deviceImageURI = device.ResourceFiles[0].DownloadURI;
  }

  return deviceInfo as IDeviceInfo;
}

function getDeviceImage(category: string): string {
  const categoryLower = category != null ? category.toLowerCase() : "undefined";

  switch (categoryLower) {
    case "display":
      return displayIcon;
    case "input":
      return inputIcon;
    case "keyboard":
      return keyboardIcon;
    case "media":
      return mediaIcon;
    case "mouse":
      return mouseIcon;
    case "network":
      return networkIcon;
    case "printer":
      return printerIcon;
    case "sound":
      return soundIcon;
    case "storage":
      return storageIcon;
    case "usb":
      return usbIcon;
    default:
      return otherIcon;
  }
}

function getMBMachine(): string {
  return defaultOverviewMachine;
}

function getOEMMachineImage(mi: IMachineIntelligence): string {
  if (mi.ModelData != null && mi.ModelData.LogoUrl != null) {
    return mi.ModelData.LogoUrl;
  } else if (mi.FamilyData != null && mi.FamilyData.LogoUrl != null) {
    return mi.FamilyData.LogoUrl;
  } else if (
    mi.ManufacturerData != null &&
    mi.ManufacturerData.LogoUrl != null
  ) {
    return mi.ManufacturerData.LogoUrl;
  } else {
    return getMBMachine();
  }
}

export const selectProgress = (state: RootState) =>
  state.scan.data?.scanProgress;

export const selectIsScanAvailable = (state: RootState) =>
  state.scan.data.hardwareScan != null;

export const selectOutOfDateDriversCount = (state: RootState) => {
  if (state.scan.data.hardwareScan) {
    return state.scan.data.hardwareScan.outOfDateDriverCount;
  }
  return 0;
};

export const selectAllDevices = (state: RootState) => {
  if (state.scan.data.hardwareScan) {
    return state.scan.data.hardwareScan.devices;
  }
  return [];
};

export const selectDevicesWithUpdates = (state: RootState) => {
  if (state.scan.data.hardwareScan) {
    return state.scan.data.hardwareScan.devices.filter(
      (device) => device.hasUpdateAvailable
    );
  }
  return [];
};

export const selectDeviceCount = (state: RootState) => {
  if (state.scan.data.hardwareScan) {
    return state.scan.data.hardwareScan.devices.length;
  }
  return 0;
};

export const selectDeviceOverview = (
  state: RootState
): IDeviceOverviewProps => ({
  deviceName: state.scan.data.systemInfoScan.pcModel,
  operatingSystem: state.scan.data.systemInfoScan.operatingSystem,
  downloadSpeed: state.scan.data.systemInfoScan.downloadSpeed,
  uploadSpeed: state.scan.data.systemInfoScan.uploadSpeed,
  ping: state.scan.data.systemInfoScan.ping,
  networkScanTime: state.scan.data.systemInfoScan.networkScanTime,
  internetProvider: state.scan.data.systemInfoScan.isp,
  pcImage: state.scan.data.systemInfoScan.pcImageUrl,
});

export const selectSystemIssueCount = (state: RootState) => {
  if (state.scan.data.systemOptimizationScan) {
    return (
      state.scan.data.systemOptimizationScan.AvailableInternetSettingsCount +
      state.scan.data.systemOptimizationScan.AvailableStartUpSettingsCount +
      state.scan.data.systemOptimizationScan.AvailableWindowsSettingsCount
    );
  }
  return 0;
};

export const selectAllOptimizations = (state: RootState) =>
  state.scan.data.systemOptimizations;

export const selectScanStatus = (state: RootState) => state.scan.status;
// export const selectHasAgentScan = (state: RootState) =>
//   state.scan.data.hasAgentScan;
// export const selectAgentScanCheckStatus = (state: RootState) =>
//   state.scan.status[getHasAgentScan.typePrefix];

export const selectDeviceById = (deviceId: string | null) => (
  state: RootState
) => {
  if (deviceId == null) {
    return null;
  }
  let returnDevice;
  if (state.scan.data.hardwareScan != null) {
    returnDevice = state.scan.data.hardwareScan.devices.find(
      (d) => d.deviceID === deviceId
    );
    if (returnDevice === undefined) {
      return null;
    }
    return returnDevice;
  }
  return null;
};

export const selectScan = (state: RootState) => state.scan.data.hardwareScan;

export const fetchSystemOptimizationScanStatus = (state: RootState) =>
  state.scan.status[fetchSystemOptimizationScan.typePrefix];

export const selectFetchHardwareScanStatus = (state: RootState) =>
  state.scan.status[fetchHardwareScan.typePrefix];

export const { updateProgress } = scanSlice.actions;
export default scanSlice.reducer;
