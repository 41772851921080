import { FormattedMessage } from "react-intl";
import { Box, Typography } from "@material-ui/core";
import {
  AppButton as Button,
  AppButtonStyles,
  IRouteState,
} from "solveiq.designsystem";
import SeatIllustration from "assets/img/illustration-seats.svg";
import AddTeamIllustration from "assets/img/illustration-add-team.svg";
import styles from "./CallToAction.module.css";
import { useHistory } from "react-router";

export enum CallToActionIllustration {
  Seat = "SeatIllustration",
  Browser = "AddTeamIllustration",
}

export interface ICallToAction {
  /* Illustration to display */
  illustration: CallToActionIllustration;
  /* Error Message */
  error: string;
  /* Suggestion for the error */
  suggestion: string;
  /* Default option for Intelligence Type Options for select component */
  buttonLabel: string;
  /* Select Your Machine Brand */
  cancelButtonLabel?: string;
  /* Call for action handler */
  callForActionHandler: () => void;
  /* Cancel handler */
  cancelHandler: () => void;
}

function getIllustration(icon: CallToActionIllustration): string {
  if (icon === CallToActionIllustration.Seat) return SeatIllustration;
  if (icon === CallToActionIllustration.Browser) return AddTeamIllustration;
  return SeatIllustration;
}

const CallToAction: React.FC<ICallToAction> = ({
  illustration,
  error,
  suggestion,
  buttonLabel,
  cancelButtonLabel,
  callForActionHandler,
  cancelHandler,
}) => {
  /* Localization function and prefix */
  const prefix = "call.to.action";

  //Redirect to billing
  const history = useHistory<IRouteState>();
  return (
    <div data-qatag="container" className={styles.container}>
      <Box data-qatag="imageContainer" mt={1} textAlign={"center"}>
        <img
          data-qatag="icon"
          className={styles.icon}
          src={getIllustration(illustration)}
          alt=""
        />
      </Box>
      <Box data-qatag="errorContainer" mt={3}>
        <Typography data-qatag="error" align="center" variant="h5">
          {error}
        </Typography>
      </Box>
      <Box data-qatag="suggestion" mt={2} textAlign="center">
        {suggestion}
      </Box>
      <Box data-qatag="buttonContainer" mt={2} textAlign={"center"}>
        <Button
          data-qatag="customButton"
          buttonStyle={AppButtonStyles.Green}
          className={styles.customButton}
          onClick={() => history.push("/billing")}
        >
          {buttonLabel}
        </Button>
      </Box>
      <Box data-qatag="cancelButtonContainer" mt={1} textAlign={"center"}>
        <Button
          data-qatag="customButton"
          buttonStyle={AppButtonStyles.TransparentWhite}
          className={styles.customButton}
          onClick={cancelHandler}
        >
          <Typography
            data-qatag="cancelButtonText"
            align="center"
            variant="body2"
            className={styles.cancelButtonText}
          >
            {cancelButtonLabel ? (
              cancelButtonLabel
            ) : (
              <FormattedMessage
                data-qatag="cancelText"
                id={`${prefix}.cancel`}
              />
            )}
          </Typography>
        </Button>
      </Box>
    </div>
  );
};

export default CallToAction;
