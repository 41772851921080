import axios from "axios";
declare let gatewayUrl: string;

const RESTGatewayAPI = () => {
  const instance = axios.create({
    baseURL: gatewayUrl,
  });
  return instance;
};

export default RESTGatewayAPI();
