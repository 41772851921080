import { ReactElement } from "react";
import { Typography, Box, Modal } from "@material-ui/core";
import {
  AppButton as Button,
  AppButtonStyles,
  useMatchTabletVertical,
  IRouteState,
} from "solveiq.designsystem";
import expiredIcon from "assets/icon/icn-expired-subscription.svg";
import styles from "./ExpiredAlert.module.css";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";

export interface IExpiredAlert {
  /* Principal message of the alert */
  title?: string | ReactElement;
  /* Describe message adding details */
  text?: string | ReactElement;
  /* Controls to open the alert */
  isOpen: boolean;
  /* Logged user is an admin */
  isAdmin?: boolean;
  /* Callback to close the alert*/
  closeHandler?: () => void;
}

const useStyles = makeStyles(() => ({
  paper: { maxWidth: "516px", width: "90%" },
}));

export const ExpiredAlert: React.FC<IExpiredAlert> = ({
  isAdmin = false,
  title = "Your Subscription Has Expired",
  text = isAdmin
    ? "Please update your subscription."
    : "Please contact your administrator.",
  isOpen,
  closeHandler = () => {},
}) => {
  /* Responsive web design */
  const matchTablet = useMatchTabletVertical();
  // Custom styles
  const classes = useStyles();

  //Redirect to billing
  const history = useHistory<IRouteState>();

  return (
    <Modal
      data-qatag="ExpiredAlert"
      open={isOpen}
      onClose={closeHandler}
      classes={{ paper: classes.paper }}
    >
      <div data-qatag="container" className={styles.container}>
        <div data-qatag="content" className={styles.content}>
          <img
            data-qatag="icon"
            className={styles.icon}
            src={expiredIcon}
            alt=""
          />
          <Box data-qatag="titleContainer" mt={matchTablet ? 3 : 2}>
            <div data-qatag="title" className={styles.title}>
              {title}
            </div>
          </Box>
          <Box data-qatag="text" mt={2} textAlign="center">
            {text}
          </Box>
          <Box data-qatag="buttonContainer" textAlign={"center"} mt={4}>
            {isAdmin ? (
              <Button
                data-qatag="customButton"
                buttonStyle={AppButtonStyles.Green}
                className={styles.customButton}
                onClick={() => history.push("/billing")}
              >
                <Typography
                  data-qatag="buttonText"
                  align="center"
                  variant="body2"
                >
                  Go To Billing
                </Typography>
              </Button>
            ) : (
              ""
            )}
          </Box>
        </div>
      </div>
    </Modal>
  );
};
