import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, IconButton, Icon } from "@material-ui/core";
import downloadIcon from "assets/img/billing/icn-settings.svg";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import SvgIcon from "@material-ui/core/SvgIcon";
import defaultUserImage from "assets/img/team/avatar-default.svg";
import styles from "./Seat.module.css";
import { Fragment, useState } from "react";
import RoleModal from "../RoleModal";
import IUser from "model/user/IUser";

export interface IInvoice {
  info: IUser;
  index: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      color: "#535a6a",
      fontWeight: 600,
    },
    paymentContainer: {
      textAlign: "center",
      width: "100%",
      display: "flex",
      alignItems: "center",
      height: 80,
      padding: "20px 32px 20px 20px",
      [theme.breakpoints.down("md")]: {
        padding: "20px 10px 20px 8px",
        height: 72,
      },
    },
    editIcon: {
      width: 30,
      height: 30,
      marginTop: -3,
    },
    downloadLabel: {
      fontStyle: "italic",
      marginTop: "-16px",
    },
    indicatorImage: {
      height: 16,
      width: 16,
      marginTop: -32,
      marginLeft: -16,
    },
    online: {
      color: "#2ECC5F",
    },
    offline: {
      color: "#CC4040",
    },
  })
);

const Invoice: React.FC<IInvoice> = ({ info, index }) => {
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const [openRoleModal, setOpenRoleModal] = useState(false);

  const userLevel = info?.attributes?.toLowerCase()?.includes("admin")
    ? "Admin"
    : "Employee";

  return (
    <Fragment key={`${info.email}-${index}`}>
      <div data-qatag="seatRow" className={styles.invoiceRow}>
        <Box
          data-qatag="seatContainer"
          key={`${info.email}-${index}`}
          mt={index % 2 !== 0 ? 0 : 2}
          mb={index % 2 !== 0 ? 0 : 2}
          className={`${classes.paymentContainer} ${
            index % 2 !== 0 ? styles.coloredRow : ""
          }`}
        >
          <Box
            data-qatag="nameTextContainer"
            display="flex"
            alignItems="center"
            className={styles.nameTextContainer}
          >
            <img
              data-qatag="calendarIcon"
              className={styles.calendarIcon}
              src={defaultUserImage}
              alt="calendar"
            />
            <SvgIcon
              data-qatag="SvgIcon"
              component={CircleIcon}
              classes={{
                root: `${classes.indicatorImage} ${
                  info.isOnline ? classes.online : ""
                } ${info.isOnline === false ? classes.offline : ""}`,
              }}
            />
            <Box
              data-qatag="nameContainer"
              className={`${classes.titleText} ${styles.nameText}`}
            >
              {`${info.firstName} ${info.lastName}`}
              <Typography
                data-qatag="roleUnderName"
                variant="h6"
                className={styles.roleUnderName}
              >
                {userLevel}
              </Typography>
            </Box>
          </Box>
          <Box data-qatag="roleText" className={styles.roleText}>
            {userLevel}
          </Box>

          {/* Actions */}
          <Box data-qatag="settingsBox" className={styles.settingsBox}>
            <Box
              data-qatag="settingsContainer"
              className={styles.settingsContainer}
              onClick={() => setOpenRoleModal(true)}
            >
              <IconButton data-qatag="settingsSeats" aria-label="Settings">
                <Icon
                  data-qatag="settingsIcon"
                  className={`MuiSelect-icon MuiSvgIcon-root ${classes.editIcon}`}
                >
                  <img data-qatag="Icon" src={downloadIcon} alt="" />
                </Icon>
              </IconButton>
              <Typography
                data-qatag="downloadLabel"
                variant="body1"
                className={classes.downloadLabel}
              >
                Settings
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>
      <RoleModal
        data-qatag="roleModal"
        isOpen={openRoleModal}
        closeHandler={() => setOpenRoleModal(false)}
        userInfo={info}
        defaultRole={userLevel.toLowerCase()}
      />
    </Fragment>
  );
};

export default Invoice;
