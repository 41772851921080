export interface ProfileConditions {
  ConditionID: number;
  ConditionValue: number;
  Description: string;
  Name: string;
  ConditionType: number;
}

export interface ManagedSoftware {
  ManagedSoftwareDBID: number;
  OemSoftwareDBID: number;
  Description: string;
  Version: string;
  Provider: string;
  PrimaryExeFileName: string;
  ExpectedInstallPath: string;
  ProductCode: string;
  FileSize: number;
  MemoryFootprint: number;
  FileSize64: number;
  MemoryFootprint64: number;
  EnableJitTriggers: boolean;
  EnablePopActions: boolean;
  SoftwareTypeFlags: number;
  IsService: boolean;
  ResourceFiles: {
    ResourceFileID: string | null;
    ResourceFileSource: string | null;
    DownloadURI: string;
    Description: string;
    ResourceFileTypeID: number;
    ResourceFileTypeName: string;
    ResourceFileMapTypeID: number;
    ResourceFileMapTypeName: string;
    ResourceFileMapTypeCategoryID: number;
    ResourceFileMapTypeCategoryName: string | null;
    Culture: string | null;
  }[];
}

export interface InstalledExes {
  Name: string;
  PrimaryFileName: string;
  ProviderName: string | null;
  FilePath: string;
  FilePathName: string;
  FileInfo: {
    Name: string;
    FileDescription: string;
    FileVersion: string;
    ProductName: string;
    CompanyName: string;
    ProductVersion: string;
    Copyright: string;
    Size: number;
    DateModified: string;
    DateCreated: string;
    LastAccessed: string;
  };
  ResourceFiles: {
    ResourceFileID: string | null;
    ResourceFileSource: string | null;
    DownloadURI: string;
    Description: string;
    ResourceFileTypeID: number;
    ResourceFileTypeName: string;
    ResourceFileMapTypeID: number;
    ResourceFileMapTypeName: string;
    ResourceFileMapTypeCategoryID: number;
    ResourceFileMapTypeCategoryName: string | null;
    Culture: string | null;
  }[];
}

export interface JitTrigger {
  JitTriggerID: number;
  ProfileFlag: number;
  ManagedSoftwareID: number;
  Name: string;
  IsActive: boolean;
  Actions: any[];
  ActionConditions: any[];
  ProfileConditions: ProfileConditions[];
  ManagedSoftware: ManagedSoftware;
}
export interface TriggerInfo {
  JitTrigger: JitTrigger;
  InstalledExes: InstalledExes[];
}
export interface IGaming {
  TriggerInfo: TriggerInfo[];
  RegistrationKey: string | null;
  UUID: string;
  DHQCulture: string;
  AppVersion: string;
  IP: string | null;
  Reason: string;
  DocumentType: string;
  id: string | null;
  _rid: string | null;
  _self: string | null;
  _etag: string | null;
  _attachments: string | null;
  _ts: string | null;
}

export enum AOProfileFlag {
  NotSet = 0,
  Standard = 1,
  Productivity = 2,
  Browsing = 4,
  Gaming = 8,
}
