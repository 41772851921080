import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Icon,
  Divider,
} from "@material-ui/core";
import { useIntl, FormattedMessage } from "react-intl";
import { RootState } from "app/store";
import { Tabs } from "solveiq.designsystem";
import { fetchJittriggersScan } from ".";
import { getGamingSoftware, getGamingStatus } from "./OptimizationSlice";
import { Header, Settings, TabOptimization } from ".";
import { makeStyles, Theme } from "@material-ui/core/styles";
import softwareIcon from "assets/img/optimization/icn-app-optimization.svg";
import styles from "./Optimization.module.css";
import SearchIcon from "@material-ui/icons/Search";
import settingsIcon from "assets/icon/icn-settings.svg";
import { ReducerStatus } from "model/IReducerState";
import { IGaming, TriggerInfo } from "./types";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import {
  selectCurrentUser,
  selectIsRemote,
  selectUserDescriptor,
} from "session/SessionSlice";
import { RouterBreadcrumbs } from "features/teamManagement/employeeDetail/RouterBreadcrumbs";

export interface IOptimization {
  /* Software Gaming */
  gamingSelector?: (state: RootState) => IGaming;
  /* Software Gaming */
  gamingStatusSelector?: (state: RootState) => ReducerStatus;
}

/* Custom Styles */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    boxShadow: "none",
    color: theme.palette.text.secondary,
    backgroundColor: "white",
  },

  tab: {
    minWidth: 80,
  },

  logoText: {
    color: theme.palette.secondary.light,
    marginLeft: 16,
  },

  searchIcon: {
    color: theme.palette.info.light,
  },

  dividerBottom: {
    order: 2,
    [theme.breakpoints.down("md")]: {
      marginTop: 16,
    },
  },
}));

const Optimization: React.FC<IOptimization> = ({
  gamingSelector = (state: RootState) => getGamingSoftware(state),
  gamingStatusSelector = (state: RootState) => getGamingStatus(state),
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "body.app.optimization";
  const isRemote = useSelector(selectIsRemote);
  const user = useSelector(selectCurrentUser);
  const userDescriptor = useSelector(selectUserDescriptor);

  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchJittriggersScan());
  }, [dispatch]);

  const tabs = useMemo(
    () => [
      formatMessage({
        id: `${prefix}.productivity`,
      }),
      formatMessage({
        id: `${prefix}.gaming`,
      }),
      formatMessage({
        id: `${prefix}.browsing`,
      }),
    ],
    [formatMessage]
  );
  // Tab number to be displayed
  const [tabNumber, setTabNumber] = useState(0);
  // Filtering text
  const [inputText, setInputText] = useState("");
  // Open Settings modal
  const [openSettings, setOpenSettings] = useState(false);

  /* Retrieve data from the store */
  /* Software Gaming */
  const gaming = useSelector<RootState>(gamingSelector) as IGaming;
  /* Gaming Status */
  const gamingStatus = useSelector<RootState>(
    gamingStatusSelector
  ) as ReducerStatus;

  const filteredGaming = useMemo(
    () =>
      (gaming.TriggerInfo || [])
        ?.filter(
          (info: TriggerInfo) =>
            info?.JitTrigger?.ManagedSoftware?.Description?.toLowerCase()?.includes(
              inputText?.toLowerCase()
            ) ||
            info.InstalledExes?.[0]?.FileInfo?.CompanyName?.toLowerCase()?.includes(
              inputText?.toLowerCase()
            )
        )
        ?.filter((info: TriggerInfo) => info?.JitTrigger?.ProfileFlag === 8), // 8 is ID for Gaming software
    [gaming.TriggerInfo, inputText]
  );
  const filteredProductivity = useMemo(
    () =>
      (gaming.TriggerInfo || [])
        ?.filter(
          (info: TriggerInfo) =>
            info?.JitTrigger?.ManagedSoftware?.Description?.toLowerCase()?.includes(
              inputText?.toLowerCase()
            ) ||
            info?.InstalledExes?.[0]?.FileInfo?.CompanyName?.toLowerCase()?.includes(
              inputText?.toLowerCase()
            )
        )
        ?.filter((info: TriggerInfo) => info?.JitTrigger?.ProfileFlag === 2), // 2 is ID for Productivity
    [gaming.TriggerInfo, inputText]
  );

  const filteredBrowsing = useMemo(
    () =>
      (gaming.TriggerInfo || [])
        ?.filter(
          (info: TriggerInfo) =>
            info?.JitTrigger?.ManagedSoftware?.Description?.toLowerCase()?.includes(
              inputText?.toLowerCase()
            ) ||
            info?.InstalledExes?.[0]?.FileInfo?.CompanyName?.toLowerCase()?.includes(
              inputText?.toLowerCase()
            )
        )
        ?.filter((info: TriggerInfo) => info?.JitTrigger?.ProfileFlag === 4), // 4 is ID for Browsing
    [gaming.TriggerInfo, inputText]
  );

  const handleOpenSettings = useCallback(() => {
    setOpenSettings((prevState) => !prevState);
  }, []);

  return (
    <Box data-qatag="box_optimization_container">
      <Box
        data-qatag="box_breadcrumbs_container"
        className={styles.breadcrumbContainer}
      >
        {isRemote ? (
          <RouterBreadcrumbs
            data-qatag="optimization.screen.remoteBreadcrumbs"
            style={{ width: "100%", height: "42px", alignSelf: "center" }}
            descriptors={[
              {
                to: `/employeeDetail/${user?.id}`,
                label: userDescriptor,
              },
              {
                to: "/",
                label: "App Optimization",
              },
            ]}
          ></RouterBreadcrumbs>
        ) : (
          <Breadcrumbs
            data-qatag="optimizationBreadcrumbs"
            pathsMap={{
              optimizations: "App Optimization",
            }}
          />
        )}
      </Box>
      <Box data-qatag="box_optimization_header">
        <Header
          data-qatag="optimization_header"
          appsNumber={(gaming.TriggerInfo || []).length}
        />
      </Box>
      <Box data-qatag="container" className={styles.container}>
        <div data-qatag="content" className={styles.content}>
          <Tabs
            data-qatag="tabs"
            tabs={tabs}
            onChange={setTabNumber}
            defaultValue={tabNumber}
            customClasses={{
              root: `${classes.root} ${styles.root}`,
              tab: classes.tab,
            }}
            className={styles.tabsCustom}
          >
            {/* Logo section */}
            <Box
              data-qatag="logo"
              className={styles.logo}
              display="flex"
              alignItems="center"
            >
              <img
                data-qatag="logoImage"
                src={softwareIcon}
                className={styles.logoImage}
                alt=""
              />
              <Typography
                data-qatag="logoText"
                className={classes.logoText}
                variant="h6"
              >
                <FormattedMessage
                  data-qatag="softwareText"
                  id={`${prefix}.software`}
                />
              </Typography>
            </Box>

            {/* Actions Sections */}
            <Box
              data-qatag="actions"
              className={styles.actionsContainer}
              display="flex"
              alignItems="center"
            >
              <IconButton
                data-qatag="closeIcon"
                aria-label="close"
                className={styles.closeIcon}
              >
                <SearchIcon
                  data-qatag="searchIcon"
                  className={classes.searchIcon}
                />
              </IconButton>
              <TextField
                data-qatag="standard-basic"
                id="standard-basic"
                onChange={(e) => setInputText(e.target.value)}
                className={styles.searchTextField}
                label={formatMessage({
                  id: `${prefix}.search`,
                })}
              />
              <IconButton
                data-qatag="closeIcon"
                aria-label="close"
                className={styles.closeIcon}
                onClick={handleOpenSettings}
              >
                <Icon
                  data-qatag="settingsIcon"
                  className={"MuiSelect-icon MuiSvgIcon-root"}
                >
                  <img
                    data-qatag="settingsIconImg"
                    src={settingsIcon}
                    alt="settings"
                  />
                </Icon>
              </IconButton>
              <label
                data-qatag="settingsText"
                onClick={handleOpenSettings}
                className={styles.settingsText}
              >
                <FormattedMessage
                  data-qatag="settingsText"
                  id={`${prefix}.settings`}
                />
              </label>
            </Box>

            {/* Divider */}
            <Box
              data-qatag="dividerBottom"
              width="100%"
              className={classes.dividerBottom}
            >
              <Divider data-qatag="Divider" />
            </Box>

            {/* Tab panels */}
            {/* Productivity Panel*/}
            <TabOptimization
              data-qatag="productivityTab"
              gamingStatus={gamingStatus}
              tabNumber={tabNumber}
              tabIndex={0}
              software={filteredProductivity}
            />
            {/* Tab Panel Gaming */}
            <TabOptimization
              data-qatag="gamingTab"
              gamingStatus={gamingStatus}
              tabIndex={1}
              tabNumber={tabNumber}
              software={filteredGaming}
            />
            {/* Browsing Panel*/}
            <TabOptimization
              data-qatag="browsingTab"
              gamingStatus={gamingStatus}
              tabIndex={2}
              tabNumber={tabNumber}
              software={filteredBrowsing}
            />
          </Tabs>
        </div>
      </Box>
      <Settings
        data-qatag="Settings"
        isOpen={openSettings}
        handleOnClose={handleOpenSettings}
      />
    </Box>
  );
};

export default Optimization;
