import Billing from "./Billing";
import {
  fetchSeats,
  getPaymentMethods,
  getRequestStatus,
  getRequestStatusUsers,
  getUsersWithRol,
} from "./BillingSlice";
import AddPaymentMethod, {
  IAddPaymentMethod,
  CardForm,
  PayPalForm,
  ICardForm,
} from "./AddPaymentMethod";
import PaymentMethods, { IPaymentMethods } from "./PaymentMethods";
import BillingHistory, { IBillingHistoryTab } from "./BillingHistory";
import MyPlan, { IMyPlan } from "./MyPlan";

export type {
  IAddPaymentMethod,
  ICardForm,
  IPaymentMethods,
  IBillingHistoryTab,
  IMyPlan,
};
export {
  AddPaymentMethod,
  PayPalForm,
  CardForm,
  fetchSeats,
  getPaymentMethods,
  getRequestStatus,
  PaymentMethods,
  getRequestStatusUsers,
  getUsersWithRol,
  BillingHistory,
  MyPlan,
};
export default Billing;
