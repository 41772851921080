export enum PaymentType {
  card = "card",
  PayPal = "PayPal",
}

export enum PaymentMethod {
  Visa,
  MasterCard,
  Amex,
  Discover,
  PayPal,
}

export interface IPayment {
  paymentType: PaymentMethod;
  lastDigits?: number;
  isDefault: boolean;
  expireMonth?: number;
  expireYear?: number;
}

export interface IBillingHistory {
  date: string;
  total: number;
  plan: string;
  downloadURL?: string;
}

export interface ISeat {
  name: string;
  active: boolean;
  role: string;
  image?: string;
}
