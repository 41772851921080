import { NavBar } from "features/nav/NavBar";
import styles from "./App.module.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReducerStatus } from "model/IReducerState";
import { Loading } from "./Loading";
import {
  selectCurrentUuid,
  selectHasApplicationError,
  selectIsAdmin,
  selectIsBeta,
  selectIsRemote,
  selectRedirectToDownload,
  selectUserDescriptor,
} from "session/SessionSlice";

import AppRouter from "./AppRouter";
import {
  eConnectionStatus,
  initAgentCommunication,
  selectAgentInfoRequestStatus,
  selectAgentInitStatus,
  selectHasAgentScan,
  selectHasMachineIntelligence,
  selectSignalRHubConnectionStatus,
  setCongratsShown,
  selectOptimizationsFinalized,
  selectCongratsShownFlag,
  selectIsForceDisconnected,
} from "app/Agent/AgentSlice";
import { ErrorFallback } from "./ErrorFallback";
import { ErrorHost } from "./ErrorHost";
import { useHistory } from "react-router-dom";
import { RemoteBar } from "./RemoteBar";
import CongratsModal from "features/Congrats/CongratsModal";
import { SignalRHubConnector } from "app/SignalRHub/signalRHubConnector";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { useAppDispatch } from "app/store";
import { UuidSelector } from "./UuidSelector";
import { getMenu } from "./MenuBuilder";
import { ForceDisconnectScreen } from "./ForceDisconnectScreen";
import NoAgentAvailable from "./NoAgentAvailable/NoAgentAvailable";
import { selectLicenses } from "model/license/LicenseSlice";
import { ExpiredAlert } from "features/ExpiredAlert/ExpiredAlert";

const App: React.FC = () => {
  // const signalRHubStatus = useSignalRHub();
  const appDispatch = useAppDispatch();
  const agentScanCheckStatus = useSelector(selectAgentInfoRequestStatus);
  const hasAgentScan = useSelector(selectHasAgentScan);
  const currentUUID = useSelector(selectCurrentUuid);
  const connStatus = useSelector(selectSignalRHubConnectionStatus);
  const isSignalRConnected = connStatus === eConnectionStatus.Connected;
  const dataFetchComplete = agentScanCheckStatus === ReducerStatus.Succeeded;
  const hasApplicationError = useSelector(selectHasApplicationError);
  const history = useHistory();
  const [isShowingModal, setIsShowingModal] = useState(false);
  const hasActivatedAgent = !useSelector(selectRedirectToDownload);
  const isAdmin = useSelector(selectIsAdmin);
  const isBeta = useSelector(selectIsBeta);
  const isRemote = useSelector(selectIsRemote);
  const userDescriptor = useSelector(selectUserDescriptor);
  const congratsShownFlag = useSelector(selectCongratsShownFlag);
  const optimizationsAreFinalized = useSelector(selectOptimizationsFinalized);
  const hasMachineIntelligence = useSelector(selectHasMachineIntelligence);
  const agentInitState = useSelector(selectAgentInitStatus);
  const isForceDisconnected = useSelector(selectIsForceDisconnected);
  const agentInitComplete =
    agentInitState === ReducerStatus.Succeeded ||
    agentInitState === ReducerStatus.Failed;
  const hub = getSignalRHub();

  // create only a single signalr hub connector
  useEffect(() => {
    new SignalRHubConnector(hub);
  }, [hub]);

  //this triggers on UUID change
  useEffect(() => {
    if (currentUUID != null && currentUUID !== "") {
      appDispatch(initAgentCommunication());
      //TODO integrate this into init flow instead of seperate call
      //appDispatch(getCongratsShown());
    }
  }, [appDispatch, currentUUID]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
  });

  //If the subscription has expired it will display an alert
  const licenseData = useSelector(selectLicenses);
  const dateExpired = new Date(
    licenseData?.provisionedLicenses?.[0]?.dunningLicenseExpiration
  );

  const handleKeyPress = (e: { key: string }) => {
    if (e.key === "`") {
      setIsShowingModal(true);
    }
  };

  if (hasActivatedAgent && !agentInitComplete) {
    return <Loading data-qatag="Loading" />;
  }

  if (hasApplicationError) {
    return (
      <ErrorHost
        data-qatag="errorhost"
        content={
          <ErrorFallback
            data-qatag="errorfallback.component"
            onReload={() => history.go(0)}
          />
        }
      />
    );
  }

  //force disconnect was triggered by the agent (another user or browser tab connected)
  if (isForceDisconnected) {
    return <ForceDisconnectScreen data-qatag="forceDisconnectScreen" />;
  }

  const menus = getMenu({ isAdmin, isSignalRConnected, isBeta });

  return (
    <React.Fragment>
      <CongratsModal
        data-qatag="modalInformation"
        handleOnClose={() => {
          appDispatch(setCongratsShown());
        }}
        isOpen={
          dataFetchComplete && optimizationsAreFinalized && !congratsShownFlag
        }
      />
      <UuidSelector
        data-qatag="app.uuidSelector"
        isOpen={isShowingModal}
        onClose={() => setIsShowingModal(false)}
      />
      <RemoteBar
        data-qatag="app.remoteSlot"
        isVisible={isRemote}
        userInfo={userDescriptor}
        onDisconnect={() => (window.location.href = "/team")}
      />
      {!isRemote && (
        <div data-qatag="navSlot" id="navSlot" className={styles.navSlot}>
          <NavBar
            data-qatag="appNavBar"
            menuItems={menus.menuItems}
            dropdownItems={menus.dropdownItems}
          />
        </div>
      )}
      <div
        data-qatag="contentSlot"
        id="contentSlot"
        className={styles.contentSlot}
      >
        <AppRouter
          data-qatag="AppRouter"
          hasScan={hasAgentScan}
          hasSignalRConnection={isSignalRConnected}
          hasActivatedAgent={hasActivatedAgent}
          isRemote={isRemote}
          hasMachineIntelligence={hasMachineIntelligence}
        />
      </div>
      {/* Will display while the actual date is greater than the expired date*/}
      {/* TODO: Replace this with Date from the server */}
      <ExpiredAlert
        data-qatag="expiredAlert"
        isOpen={new Date().getTime() > dateExpired.getTime()}
        isAdmin={isAdmin}
      />
    </React.Fragment>
  );
};

export default App;
