import { RootState } from "app/store";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  AppButton,
  AppButtonStyles,
  OnOffSwitch,
  Panel,
} from "solveiq.designsystem";
import { ReducerStatus, IThunkStatus } from "model/IReducerState";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import headerImage from "assets/img/threeGears.svg";
import settingsIcon from "assets/icon/icn-settings-screen.svg";
import {
  fetchUserSettings,
  getSettingValueFromArray,
  selectSettings,
  selectStatus,
  updateSetting,
  updateUserSetting,
} from "./SettingsSlice";
import { IUserSettingModel } from "model/messaging/messages/AppSettingsMessages";
import { UserSettingTypes } from "core/enumerations/UserSettingTypes";
import { ScheduledScanOptionsDialog } from "./ScheduledScanOptionsDialog";
import { useHistory } from "react-router-dom";
import { selectCurrentUuid } from "session/SessionSlice";
import { selectAppVersion } from "app/Agent/AgentSlice";
import chevron from "assets/img/buttonCollapsed.png";

const useStyles = makeStyles((theme: Theme) => ({
  settingsScreen: {
    backgroundColor: "#f5f6f9",
    boxSizing: "border-box",
    padding: "26px 57px 0 68px",
    width: "100%",
    maxWidth: "1075px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "start",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: "18px",
    },
  },
  headerImage: {
    width: "120px",
    height: "88px",
    [theme.breakpoints.down("sm")]: {
      width: "63px",
      height: "46px",
    },
  },
  headerTextContainer: {
    display: "inline-flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    marginTop: "15px",
    marginLeft: "35px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  headerTitle: {
    color: "#535a6a",
  },
  headerDescription: {
    color: "#757e95",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "360px",
      margin: "13px auto",
    },
  },
  bodyTitleContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  bodyTitleIcon: {
    width: "20px",
    height: "20px",
    marginRight: "15px",
  },
  bodyTitle: {
    fontWeight: 600,
  },
  settingsGroup: {
    "&:not(:last-of-type)": {
      borderBottom: "1px solid #E5E5E5",
      paddingBottom: "30px",
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: {
        borderBottom: "unset",
        paddingBottom: "unset",
        marginBottom: "unset",
      },
    },
  },
  settingContainer: {
    display: "inline-flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    width: "100%",
    padding: "15px 10px",
    "&:nth-child(odd)": {
      backgroundColor: "#FAFAFA",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      justifyContent: "space-between",
      "&:not(:last-child)": {
        borderBottom: "1px solid #bdbdbd",
      },
      "&:nth-child(odd)": {
        backgroundColor: "unset",
      },
      "&$withButton": {
        flexDirection: "column",
      },
      "&$withButton $spacer": {
        display: "none",
      },
      "&$withButton $controlContainer": {
        marginTop: "10px",
      },
    },
  },
  settingName: {
    flexGrow: 0,
  },
  controlButton: {
    "&:not(.infoButton)": {
      width: "185px",
    },
  },
  mobileInfoButton: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
      cursor: "pointer",
      "& img": {
        transform: "rotate(270deg)",
      },
    },
  },
  appVersion: {
    color: "#007DBA",
  },
  withButton: {},
  infoButton: {},
  spacer: {
    flexGrow: 1,
    margin: "0 10px",
  },
  controlContainer: {
    flexGrow: 0,
  },
  uuidLabel: {
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  desktopButton: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export interface ISettingsScreenProps {
  settingsSelector?: (state: RootState) => IUserSettingModel[] | null;
  statusSelector?: (state: RootState) => IThunkStatus;
  uuidSelector?: (state: RootState) => string;
  appVersionSelector?: (state: RootState) => string;
}

export const SettingsScreen: React.FC<ISettingsScreenProps> = ({
  settingsSelector = selectSettings,
  statusSelector = selectStatus,
  uuidSelector = selectCurrentUuid,
  appVersionSelector = selectAppVersion,
}) => {
  const dispatch = useDispatch();
  const prefix = "settingsScreen.view";
  const history = useHistory();
  const classes = useStyles();
  const machinePickerPath = "/machinepicker";
  const scanScreenPath = "/scan";
  const accountInfoPath = "account-info-path";
  const privacyPolicyPath = "https://www.solveiq.com/privacy-policy/";
  const termsPath = "https://www.solveiq.com/terms-and-conditions/";

  const settings = useSelector(settingsSelector);
  const uuid = useSelector(uuidSelector);
  const appVersion = useSelector(appVersionSelector);

  useEffect(() => {
    dispatch(fetchUserSettings());
  }, [dispatch]);

  const [showScheduledScanDialog, setShowScheduledScanDialog] = useState(false);

  const onAccountInformationClicked = () => {
    history.push(accountInfoPath);
  };
  const onPrivacyPolicyClicked = () => {
    window.open(privacyPolicyPath);
  };
  const onTermsAndConditionsClicked = () => {
    window.open(termsPath);
  };
  const onUuidClicked = () => {
    navigator.clipboard.writeText(uuid);
  };

  return (
    <div data-qatag="settingsScreen" className={classes.settingsScreen}>
      <Panel data-qatag="header" className={classes.header}>
        <img
          data-qatag="headerImage"
          src={headerImage}
          className={classes.headerImage}
          alt=""
        />
        <div
          data-qatag="headerTextContainer"
          className={classes.headerTextContainer}
        >
          <Typography
            data-qatag="headerTitle"
            className={classes.headerTitle}
            variant="h4"
          >
            <FormattedMessage
              data-qatag="headerTitleMessage"
              id={`${prefix}.header.title`}
              defaultMessage="Settings"
            />
          </Typography>
          <Typography
            data-qatag="headerDescription"
            className={classes.headerDescription}
            variant="h6"
          >
            <FormattedMessage
              data-qatag="headerDescriptionMessage"
              id={`${prefix}.header.description`}
              defaultMessage=" "
            />
          </Typography>
        </div>
      </Panel>
      <Panel data-qatag="body" className={classes.body}>
        <div data-qatag="settingsGroup" className={classes.settingsGroup}>
          <div
            data-qatag="settingContainer"
            className={classes.settingContainer}
          >
            <Typography
              data-qatag="settingName"
              className={classes.settingName}
              variant="body2"
            >
              <FormattedMessage
                data-qatag="dnsProtectionMessage"
                id={`${prefix}.body.dnsProtection`}
                defaultMessage="DNS Protection"
              />
            </Typography>
            <div data-qatag="spacer" className={classes.spacer} />
            <div
              data-qatag="controlContainer"
              className={classes.controlContainer}
            >
              {settings && (
                <OnOffSwitch
                  data-qatag="dnsSwitch"
                  onClick={(
                    event: React.MouseEvent<HTMLElement>,
                    isOn: boolean
                  ) => {
                    dispatch(
                      updateUserSetting({
                        UpdatedSettings: [
                          {
                            SettingType: UserSettingTypes.EnableDnsProtector,
                            SettingTypeName:
                              UserSettingTypes[
                                UserSettingTypes.EnableDnsProtector
                              ],
                            NewSettingValue: isOn,
                            CurrentSettingValue: !isOn,
                          },
                        ],
                      })
                    );
                    dispatch(
                      updateSetting({
                        SettingType: UserSettingTypes.EnableDnsProtector,
                        SettingValue: isOn,
                      })
                    );
                  }}
                  isOnInitially={
                    settings.find(
                      (x) =>
                        x.SettingType === UserSettingTypes.EnableDnsProtector
                    )?.SettingValue
                  }
                />
              )}
            </div>
          </div>
          <div
            data-qatag="settingContainer"
            className={classes.settingContainer}
          >
            <Typography
              data-qatag="settingName"
              className={classes.settingName}
              variant="body2"
            >
              <FormattedMessage
                data-qatag="deceptorDetectorMessage"
                id={`${prefix}.body.deceptorDetector`}
                defaultMessage="Deceptor Blocking"
              />
            </Typography>
            <div data-qatag="spacer" className={classes.spacer} />
            <div
              data-qatag="controlContainer"
              className={classes.controlContainer}
            >
              {settings && (
                <OnOffSwitch
                  data-qatag="deceptorSwitch"
                  onClick={(
                    event: React.MouseEvent<HTMLElement>,
                    isOn: boolean
                  ) => {
                    dispatch(
                      updateUserSetting({
                        UpdatedSettings: [
                          {
                            SettingType:
                              UserSettingTypes.EnableDeceptorServices,
                            SettingTypeName:
                              UserSettingTypes[
                                UserSettingTypes.EnableDeceptorServices
                              ],
                            NewSettingValue: isOn,
                            CurrentSettingValue: !isOn,
                          },
                        ],
                      })
                    );
                    dispatch(
                      updateSetting({
                        SettingType: UserSettingTypes.EnableDeceptorServices,
                        SettingValue: isOn,
                      })
                    );
                  }}
                  isOnInitially={
                    settings.find(
                      (x) =>
                        x.SettingType ===
                        UserSettingTypes.EnableDeceptorServices
                    )?.SettingValue
                  }
                />
              )}
            </div>
          </div>
          <div
            data-qatag="settingContainer"
            className={classes.settingContainer}
          >
            <Typography
              data-qatag="settingName"
              className={classes.settingName}
              variant="body2"
            >
              <FormattedMessage
                data-qatag="applicationOptimizationMessage"
                id={`${prefix}.body.applicationOptimization`}
                defaultMessage="Application Optimization"
              />
            </Typography>
            <div data-qatag="spacer" className={classes.spacer} />
            <div
              data-qatag="controlContainer"
              className={classes.controlContainer}
            >
              {settings && (
                <OnOffSwitch
                  data-qatag="toggleSwitch"
                  onClick={(
                    event: React.MouseEvent<HTMLElement>,
                    isOn: boolean
                  ) => {
                    dispatch(
                      updateUserSetting({
                        UpdatedSettings: [
                          {
                            SettingType: UserSettingTypes.BrowsingState,
                            SettingTypeName:
                              UserSettingTypes[UserSettingTypes.BrowsingState],
                            NewSettingValue: isOn ? 1 : 0,
                            CurrentSettingValue: !isOn ? 1 : 0,
                          },
                        ],
                      })
                    );
                    dispatch(
                      updateSetting({
                        SettingType: UserSettingTypes.BrowsingState,
                        SettingValue: isOn ? 1 : 0,
                      })
                    );
                    dispatch(
                      updateUserSetting({
                        UpdatedSettings: [
                          {
                            SettingType: UserSettingTypes.ProductivityState,
                            SettingTypeName:
                              UserSettingTypes[
                                UserSettingTypes.ProductivityState
                              ],
                            NewSettingValue: isOn ? 1 : 0,
                            CurrentSettingValue: !isOn ? 1 : 0,
                          },
                        ],
                      })
                    );
                    dispatch(
                      updateSetting({
                        SettingType: UserSettingTypes.ProductivityState,
                        SettingValue: isOn ? 1 : 0,
                      })
                    );
                    dispatch(
                      updateUserSetting({
                        UpdatedSettings: [
                          {
                            SettingType: UserSettingTypes.GamingState,
                            SettingTypeName:
                              UserSettingTypes[UserSettingTypes.GamingState],
                            NewSettingValue: isOn ? 1 : 0,
                            CurrentSettingValue: !isOn ? 1 : 0,
                          },
                        ],
                      })
                    );
                    dispatch(
                      updateSetting({
                        SettingType: UserSettingTypes.GamingState,
                        SettingValue: isOn ? 1 : 0,
                      })
                    );
                  }}
                  isOnInitially={
                    settings.find(
                      (x) => x.SettingType === UserSettingTypes.BrowsingState
                    )?.SettingValue &&
                    settings.find(
                      (x) =>
                        x.SettingType === UserSettingTypes.ProductivityState
                    )?.SettingValue &&
                    settings.find(
                      (x) => x.SettingType === UserSettingTypes.GamingState
                    )?.SettingValue
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div data-qatag="settingsGroup" className={classes.settingsGroup}>
          <div
            data-qatag="settingContainer"
            className={`${classes.settingContainer} ${classes.withButton}`}
          >
            <Typography
              data-qatag="settingName"
              className={classes.settingName}
              variant="body2"
            >
              <FormattedMessage
                data-qatag="changeMachineMessage"
                id={`${prefix}.body.changeMachine`}
                defaultMessage="Change Machine Type"
              />
            </Typography>
            <div data-qatag="spacer" className={classes.spacer} />
            <div
              data-qatag="controlContainer"
              className={classes.controlContainer}
            >
              <AppButton
                data-qatag="changeMachineButton"
                buttonStyle={AppButtonStyles.Blue}
                className={classes.controlButton}
                onClick={() => history.push(machinePickerPath)}
              >
                <Typography data-qatag="" variant="body2">
                  <FormattedMessage
                    data-qatag="changeMachineButtonMessage"
                    id={`${prefix}.body.changeMachineButton`}
                    defaultMessage="Change Machine"
                  />
                </Typography>
              </AppButton>
            </div>
          </div>
          <div
            data-qatag="settingContainer"
            className={`${classes.settingContainer} ${classes.withButton}`}
          >
            <Typography
              data-qatag="settingName"
              className={classes.settingName}
              variant="body2"
            >
              <FormattedMessage
                data-qatag="rescanMessage"
                id={`${prefix}.body.rescan`}
                defaultMessage="Rescan Machine"
              />
            </Typography>
            <div data-qatag="spacer" className={classes.spacer} />
            <div
              data-qatag="controlContainer"
              className={classes.controlContainer}
            >
              <AppButton
                data-qatag="controlButton"
                buttonStyle={AppButtonStyles.Blue}
                className={classes.controlButton}
                onClick={() => history.push(scanScreenPath)}
              >
                <Typography data-qatag="" variant="body2">
                  <FormattedMessage
                    data-qatag="rescanButtonMessage"
                    id={`${prefix}.body.rescanButton`}
                    defaultMessage="Rescan Now"
                  />
                </Typography>
              </AppButton>
            </div>
          </div>
          <div
            data-qatag="settingContainer"
            className={`${classes.settingContainer} ${classes.withButton}`}
          >
            <Typography
              data-qatag="settingName"
              className={classes.settingName}
              variant="body2"
            >
              <FormattedMessage
                data-qatag="scheduleScanMessage"
                id={`${prefix}.body.scheduleScan`}
                defaultMessage="Schedule Scan"
              />
            </Typography>
            <div data-qatag="spacer" className={classes.spacer} />
            <div
              data-qatag="controlContainer"
              className={classes.controlContainer}
            >
              <AppButton
                data-qatag="controlButton"
                buttonStyle={AppButtonStyles.Blue}
                className={classes.controlButton}
                onClick={() => setShowScheduledScanDialog(true)}
              >
                <Typography data-qatag="" variant="body2">
                  <FormattedMessage
                    data-qatag="scheduleScanMessage"
                    id={`${prefix}.body.scheduleScanButton`}
                    defaultMessage="Scan Options"
                  />
                </Typography>
              </AppButton>
            </div>
          </div>
        </div>
        <div data-qatag="settingsGroup" className={classes.settingsGroup}>
          {/* <div
            data-qatag="settingContainer"
            className={`${classes.settingContainer}`}
          >
            <Typography
              data-qatag="settingName"
              className={classes.settingName}
              variant="body2"
            >
              <FormattedMessage
                data-qatag="accountInformationMessage"
                id={`${prefix}.body.accountInformationLabel`}
                defaultMessage="Account Information"
              />
            </Typography>
            <div data-qatag="spacer" className={classes.spacer} />
            <div
              data-qatag="controlContainer"
              className={classes.controlContainer}
            >
              <div
                data-qatag="mobileInfoButton"
                className={classes.mobileInfoButton}
                onClick={onAccountInformationClicked}
              >
                <img data-qatag="" src={chevron} alt="" />
              </div>
              <AppButton
                data-qatag="accountInformationButton"
                buttonStyle={AppButtonStyles.LinkBlue}
                className={`${classes.controlButton} ${classes.infoButton} ${classes.desktopButton}`}
                onClick={onAccountInformationClicked}
              >
                <Typography data-qatag="" variant="body2">
                  <FormattedMessage
                    data-qatag="accountInformationButtonMessage"
                    id={`${prefix}.body.accountInformationButton`}
                    defaultMessage="Account Information"
                  />
                </Typography>
              </AppButton>
            </div>
          </div> */}
          <div
            data-qatag="settingContainer"
            className={`${classes.settingContainer}`}
          >
            <Typography
              data-qatag="settingName"
              className={classes.settingName}
              variant="body2"
            >
              <FormattedMessage
                data-qatag="applicationVersionMessage"
                id={`${prefix}.body.applicationVersionLabel`}
                defaultMessage="Application Version"
              />
            </Typography>
            <div data-qatag="spacer" className={classes.spacer} />
            <div
              data-qatag="controlContainer"
              className={classes.controlContainer}
            >
              <Typography
                data-qatag=""
                variant="body2"
                className={classes.appVersion}
              >
                {appVersion}
              </Typography>
            </div>
          </div>
          <div
            data-qatag="settingContainer"
            className={`${classes.settingContainer}`}
          >
            <Typography
              data-qatag="settingName"
              className={classes.settingName}
              variant="body2"
            >
              <FormattedMessage
                data-qatag="privacyPolicyMessage"
                id={`${prefix}.body.privacyPolicyLabel`}
                defaultMessage="Privacy Policy"
              />
            </Typography>
            <div data-qatag="spacer" className={classes.spacer} />
            <div
              data-qatag="controlContainer"
              className={classes.controlContainer}
            >
              <div
                data-qatag="mobileInfoButton"
                className={classes.mobileInfoButton}
                onClick={onPrivacyPolicyClicked}
              >
                <img data-qatag="" src={chevron} alt="" />
              </div>
              <AppButton
                data-qatag="privacyPolicyButton"
                buttonStyle={AppButtonStyles.LinkBlue}
                className={`${classes.controlButton} ${classes.infoButton} ${classes.desktopButton}`}
                onClick={onPrivacyPolicyClicked}
              >
                <Typography data-qatag="" variant="body2">
                  <FormattedMessage
                    data-qatag="privacyPolicyButtonMessage"
                    id={`${prefix}.body.privacyPolicyButton`}
                    defaultMessage="Privacy Policy"
                  />
                </Typography>
              </AppButton>
            </div>
          </div>
          <div
            data-qatag="settingContainer"
            className={`${classes.settingContainer}`}
          >
            <Typography
              data-qatag="settingName"
              className={classes.settingName}
              variant="body2"
            >
              <FormattedMessage
                data-qatag="termsAndConditionsMessage"
                id={`${prefix}.body.termsAndConditionsLabel`}
                defaultMessage="Terms and Conditions"
              />
            </Typography>
            <div data-qatag="spacer" className={classes.spacer} />
            <div
              data-qatag="controlContainer"
              className={classes.controlContainer}
            >
              <div
                data-qatag="mobileInfoButton"
                className={classes.mobileInfoButton}
                onClick={onTermsAndConditionsClicked}
              >
                <img data-qatag="" src={chevron} alt="" />
              </div>
              <AppButton
                data-qatag="termsAndConditionsButton"
                buttonStyle={AppButtonStyles.LinkBlue}
                className={`${classes.controlButton} ${classes.infoButton} ${classes.desktopButton}`}
                onClick={onTermsAndConditionsClicked}
              >
                <Typography data-qatag="" variant="body2">
                  <FormattedMessage
                    data-qatag="termsAndConditionsButtonMessage"
                    id={`${prefix}.body.termsAndConditionsButton`}
                    defaultMessage="Terms and Conditions"
                  />
                </Typography>
              </AppButton>
            </div>
          </div>
          <div
            data-qatag="settingContainer"
            className={`${classes.settingContainer}`}
          >
            <Typography
              data-qatag="settingName"
              className={`${classes.settingName} ${classes.uuidLabel}`}
              variant="body2"
            >
              <FormattedMessage
                data-qatag="uuidMessage"
                id={`${prefix}.body.uuidLabel`}
                defaultMessage="UUID: {uuid}"
                values={{
                  uuid: uuid,
                }}
              />
            </Typography>
            <div data-qatag="spacer" className={classes.spacer} />
            <div
              data-qatag="controlContainer"
              className={classes.controlContainer}
            >
              <AppButton
                data-qatag="uuidButton"
                buttonStyle={AppButtonStyles.LinkBlue}
                className={`${classes.controlButton} ${classes.infoButton}`}
                onClick={onUuidClicked}
              >
                <Typography data-qatag="" variant="body2">
                  <FormattedMessage
                    data-qatag="uuidButtonMessage"
                    id={`${prefix}.body.uuidButton`}
                    defaultMessage="Copy to clipboard"
                  />
                </Typography>
              </AppButton>
            </div>
          </div>
        </div>
      </Panel>
      {settings && (
        <ScheduledScanOptionsDialog
          data-qatag="ScheduledScanOptionsDialog"
          isOpen={showScheduledScanDialog}
          closeDialogHandler={() => setShowScheduledScanDialog(false)}
          scanEnabled={getSettingValueFromArray(
            settings,
            UserSettingTypes.EnableScheduledScans
          )}
          scanPeriod={getSettingValueFromArray(
            settings,
            UserSettingTypes.ScheduledScanPeriod
          )}
          scanTime={getSettingValueFromArray(
            settings,
            UserSettingTypes.ScheduledScanTime
          )}
          dayOfMonth={getSettingValueFromArray(
            settings,
            UserSettingTypes.ScheduledScanMonth
          )}
          dayOfWeek={getSettingValueFromArray(
            settings,
            UserSettingTypes.ScheduledScanDay
          )}
        />
      )}
    </div>
  );
};
