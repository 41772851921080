import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";
import IUser from "model/user/IUser";
import { selectCurrentUser } from "session/SessionSlice";

export const updateUserRole = createAsyncThunk<
  { data: number; error: string },
  {
    user: IUser;
    role: number;
  },
  {
    state: RootState;
  }
>("users/updateUserRole", async ({ user, role = 1 }, thunkApi) => {
  // User = 1,
  // Admin = 2,
  // Billing = 4
  try {
    //Get currentRegKey
    const state = thunkApi.getState();
    const currentRegKey = state?.session?.data?.currentRegKey;

    // Retrieve all users
    const getUsersUrl = `/api/organization/users/${currentRegKey}`;

    const response = await RESTGatewayAPI.get<IUser[]>(getUsersUrl);
    const responsePayload = response.data;

    /*
     * If we only have one admin and our current user to modify is the only admin,
     * we won't allow to modify the role
     */

    const adminUsers = responsePayload.filter((userRecord) =>
      userRecord.attributes?.toLowerCase()?.includes("admin")
    );

    // Current user email
    const currentUser = selectCurrentUser(state);
    const currentEmail = currentUser?.email;

    if (user.email === currentEmail) {
      return {
        error: "You can't demote yourself",
        data: 2,
      };
    }

    if (adminUsers.length === 1 && adminUsers?.[0]?.email === user.email) {
      return {
        error: "You can't modify the last admin",
        data: 2,
      };
    }

    // URL to update user Role
    const url = `/api/User/${user.email}/role`;

    const options = {
      headers: {
        "Content-Type": "text/json",
      },
    };
    const apiResponse = await RESTGatewayAPI.patch(url, role, options);
    return { data: apiResponse.data, error: "" };
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to change the role : ${error}`);
  }
});

const initialState: IReducerState<number> = {
  data: 1,
  status: {
    [updateUserRole.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<number>) => {
      state.data = action.payload;
    },
    setRoleStatus: (state) => {
      state.status[updateUserRole.typePrefix] = ReducerStatus.Idle;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserRole.pending, (state) => {
      state.status[updateUserRole.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(updateUserRole.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.error = action.payload.error;
      if (action.payload.error) {
        state.status[updateUserRole.typePrefix] = ReducerStatus.Failed;
      } else {
        state.status[updateUserRole.typePrefix] = ReducerStatus.Succeeded;
      }
    });
  },
});

export const { set, setRoleStatus } = roleSlice.actions;

export const selectRole = (state: RootState) => state.role.data;
export const selectRoleStatus = (state: RootState) =>
  state.role.status[updateUserRole.typePrefix];
export const selectStatus = (state: RootState) => state.role.status;
export const selectError = (state: RootState) => state.role.error;

export default roleSlice.reducer;
