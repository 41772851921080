import { IDeviceInfo } from "model/scan/DeviceInfo";
import moment from "moment";

export class ViewHelpers {
  public static GetReadableFileSizeString(bytes: number, longUnits = false) {
    let i = 0;
    const byteUnits = longUnits
      ? [
          " Bytes",
          " Kilobytes",
          " Megabytes",
          " Gigabytes",
          " Terabytes",
          " Petabytes",
        ]
      : [" B", " kB", " MB", " GB", " TB", " PB"];
    while (bytes > 1024) {
      bytes = bytes / 1024;
      i++;
    }

    return bytes.toFixed(1) + byteUnits[i];
  }
}

export function getParameterByName(name: string) {
  try {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    const regexS = "[\\?&]" + name + "=([^&#]*)";
    const regex = new RegExp(regexS);
    const results = regex.exec(window.location.search);
    if (results == null) return "";
    else return decodeURIComponent(results[1].replace(/\+/g, " "));
  } catch (e) {
    return "";
  }
}

export function humanFileSize(bytes: number, si: boolean): string {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + " " + units[u];
}

export function sortByDeviceClass(a: IDeviceInfo, b: IDeviceInfo) {
  return a.classRank < b.classRank
    ? 1
    : a.classRank === b.classRank
    ? a.friendlyName > b.friendlyName
      ? 1
      : -1
    : -1;
}

export function debounce(f: (...args: any[]) => void, ms: number) {
  let id = 0;
  return (...args: any[]) => {
    clearTimeout(id);
    id = window.setTimeout(() => f(...args), ms);
  };
}

export function formatBytes(bytes: number, decimals: number): string {
  if (bytes === 0) {
    return "0 Bytes";
  }
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function formatLastScanTime(scanTime: string) {
  return moment(scanTime).fromNow();
}
