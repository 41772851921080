export interface IReducerState<T> {
  status: IThunkStatus;
  error?: string;
  data: T;
}

export interface IThunkStatus {
  [key: string]: ReducerStatus;
}

export enum ReducerStatus {
  Idle = "Idle",
  Loading = "Loading",
  Succeeded = "Succeeded",
  Failed = "Failed",
}
