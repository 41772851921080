import { useIntl } from "react-intl";
import { Typography, Box } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Modal } from "solveiq.designsystem";
import styles from "./LockedFilesDialog.module.css";
import headerImage from "assets/img/fileCleaning/icn-locked-files.svg";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";

export interface ILockedFilesEntry {
  name: string;
  size: string;
}

export interface ILockedFilesDialogProps {
  isOpen: boolean;
  files: ILockedFilesEntry[];
  totalSize: string;
  closeDialogHandler: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileName: {
      color: theme.palette.secondary.light,
      lineHeight: 3,
    },
    buttonText: {
      lineHeight: 1,
    },
    totalSize: {
      [theme.breakpoints.down("md")]: {
        margin: "0 auto",
      },
    },
  })
);

export const LockedFilesDialog: React.FC<ILockedFilesDialogProps> = (props) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "fileCleaning.lockedFilesDialog";
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  return (
    <Modal
      data-qatag="lockedFilesDialog"
      open={props.isOpen}
      onClose={props.closeDialogHandler}
      className={styles.lockedFilesDialog}
    >
      <div
        data-qatag="lockedFilesDialogContent"
        className={styles.lockedFilesDialogContent}
      >
        <img
          data-qatag="headerImage"
          src={headerImage}
          className={styles.headerImage}
          alt=""
        />
        <Typography data-qatag="lockedFilesDialogTitleTypography" variant="h4">
          {formatMessage({
            id: `${prefix}.title`,
            defaultMessage: "Locked Files",
          })}
        </Typography>
        <Box
          data-qatag="lockedFilesDescriptionBox"
          width="80%"
          mt={1}
          mb={2}
          textAlign="center"
        >
          {formatMessage({
            id: `${prefix}.description`,
            defaultMessage:
              "The following files were unable to be cleaned because they were locked.",
          })}
        </Box>
        <div data-qatag="lockedFiles" className={styles.lockedFiles}>
          {props.files.map((file, index) => {
            return (
              <div
                data-qatag={`lockedFilesEntry_${index}`}
                className={styles.lockedFilesEntry}
                key={`locked-files-entry-${file.name}-${file.size}`}
              >
                <Box
                  data-qatag={`lockedFilesDialogFileNameBox_${index}`}
                  flexBasis="80%"
                >
                  <Typography
                    data-qatag={`lockedFilesDialogFileNameTypography_${index}`}
                    align="left"
                    className={`${classes.fileName} ${styles.fileName}`}
                  >
                    {file.name}
                  </Typography>
                </Box>
                <Typography
                  data-qatag={`lockedFilesDialogFileSizeTypography_${index}`}
                >
                  {file.size}
                </Typography>
              </div>
            );
          })}
        </div>
        <div data-qatag="footer" className={styles.footer}>
          <Typography
            data-qatag="totalSize"
            variant="h4"
            className={classes.totalSize}
          >
            {formatMessage({ id: `${prefix}.total`, defaultMessage: "Total:" })}{" "}
            {props.totalSize}
          </Typography>
          <AppButton
            data-qatag="backButton"
            dataQatag="lockedFilesDialogBackButton"
            buttonStyle={AppButtonStyles.Blue}
            onClick={props.closeDialogHandler}
            className={styles.backButton}
          >
            <Typography
              data-qatag="buttonText"
              variant="body2"
              className={classes.buttonText}
            >
              {formatMessage({
                id: `${prefix}.buttonText`,
                defaultMessage: "Back To Summary",
              })}
            </Typography>
          </AppButton>
        </div>
      </div>
    </Modal>
  );
};
