import { PaymentMethod } from "features/Billing/types";
import visaIcon from "assets/icon/icn-visa.svg";
import mastercardIcon from "assets/icon/icn-mastercard.svg";
import discoverIcon from "assets/icon/icn-discover.svg";
import amexIcon from "assets/icon/icn-amex.svg";
import PayPalLogo from "assets/img/PayPalLogo.png";

export function getIcon(paymentType: string) {
  if (paymentType === PaymentMethod[PaymentMethod.Amex]) return amexIcon;
  if (paymentType === PaymentMethod[PaymentMethod.Discover])
    return discoverIcon;
  if (paymentType === PaymentMethod[PaymentMethod.MasterCard])
    return mastercardIcon;
  if (paymentType === PaymentMethod[PaymentMethod.Visa]) return visaIcon;
  return PayPalLogo;
}
