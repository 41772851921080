import { Alert, AlertIcon } from "solveiq.designsystem";
import { useIntl } from "react-intl";

export interface IAlertSuccess {
  /* Controls to open the alert */
  isOpen: boolean;
  /* Callback to close the alert*/
  closeHandler: () => void;
  /* Title of the alert */
  title: string;
  /* Description */
  description: string;
}

const AlertSuccess: React.FC<IAlertSuccess> = ({
  closeHandler,
  isOpen,
  title,
  description,
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "alert.add.team.members.success";

  return (
    <Alert
      data-qatag=""
      icon={AlertIcon.Success}
      title={title}
      text={description}
      buttonText={formatMessage({
        id: `${prefix}.button`,
      })}
      approveHandler={closeHandler}
      isOpen={isOpen}
      closeHandler={closeHandler}
    />
  );
};

export default AlertSuccess;
