import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { IntlProvider } from "./components/providers";
import { defaultTheme } from "solveiq.designsystem";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { AuthProvider } from "siq-oidc-react";
import { ErrorFallback } from "main/ErrorFallback";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import styles from "./main/App.module.css";
import SessionWrapper from "./session/SessionWrapper";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { SignalRHubProvider } from "app/SignalRHub/signalRHubProvider";
import App from "main/App";
import { getParameterByName } from "core/Helpers";

declare let authServer: string;
declare let traceSampleRate: number;
declare let authReturnUrl: string;
declare let basename: string;

const history = createBrowserHistory({ basename: basename });
const qsToken = getParameterByName("refToken");

Sentry.init({
  dsn:
    "https://d2f2c8b594714f40bf73fe1ac9e40beb@o94524.ingest.sentry.io/5748009",
  release: "solve-iq-business-ui@" + process.env.REACT_APP_VERSION,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: traceSampleRate,
});

ReactDOM.render(
  <React.StrictMode data-qatag="strictMode">
    <Sentry.ErrorBoundary data-qatag="errorBoundary" fallback={ErrorFallback}>
      <Provider data-qatag="provider" store={store}>
        <IntlProvider data-qatag="intlProvider">
          <ThemeProvider theme={defaultTheme}>
            <CssBaseline>
              <AuthProvider
                data-qatag="authProvider"
                scope="openid profile email solveiq_business_gateway_scope"
                authority={authServer}
                clientId="solveiq-business-spa-client"
                redirectUri={authReturnUrl}
                additionalQueryArgs={{ refToken: qsToken }}
              >
                <div
                  data-qatag="appRoot"
                  id="appRoot"
                  className={styles.appRoot}
                >
                  <Router data-qatag="Router" history={history}>
                    <SessionWrapper data-qatag="SessionWrapper">
                      <App data-qatag="MainApp" />
                    </SessionWrapper>
                  </Router>
                </div>
              </AuthProvider>
            </CssBaseline>
          </ThemeProvider>
        </IntlProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
