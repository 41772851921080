import { Box, Typography, IconButton, Icon } from "@material-ui/core";
import { useIntl } from "react-intl";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import styles from "./PaymentMethods.module.css";
import {
  TabPanel,
  AppButton,
  AppButtonStyles,
  IRouteState,
  Spinner,
  Alert,
  AlertIcon,
} from "solveiq.designsystem";
import { IPaymentMethod } from "./types";
import { ReducerStatus } from "model/IReducerState";
import { EmptyState } from "features/Optimization";
import {
  fetchPaymentMethods,
  updatePaymentMethod,
  getPaymentMethods,
  getRequestStatus,
  getUpdateStatus,
  resetUpdate,
} from "./PaymentMethodsSlice";
import AddPaymentMethod from "../AddPaymentMethod/AddPaymentMethod";
import { RootState } from "app/store";
import { useEffect, useState } from "react";
import { PaymentMethod } from "../types";
import { useDispatch, useSelector } from "react-redux";
import paymentIcon from "assets/icon/icn-payment-method.svg";
import addPaymentIcon from "assets/icon/add-payment-method.svg";
import editIcon from "assets/icon/icn-edit-cc.svg";
import { useHistory } from "react-router";
import DeleteButton from "./DeleteButton";
import { validateCardDate } from "utils/validateCardDate";
import { getIcon } from "utils/getCreditCardIcon";
import EditPaymentMethodModal from "../EditPaymentMethodModal";

export interface IPaymentMethods {
  /* Active tab number */
  tabNumber: number;
  /* Corresponding tab index */
  tabIndex: number;
  /* Payment Methods */
  paymentMethodsSelector?: (state: RootState) => IPaymentMethod[];
  /* Payment Methods Request Status */
  requestStatusSelector?: (state: RootState) => ReducerStatus;
  /* Status to update default payment method */
  updateStatusSelector?: (state: RootState) => ReducerStatus;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      order: 2,
      flex: "100%",
    },
    titleText: {
      color: theme.palette.secondary.light,
      fontWeight: 600,
    },
    noValidPayment: {
      color: theme.palette.error.dark,
    },
    addPaymentButton: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      flexBasis: "50%",
      marginTop: 40,
      marginBottom: 32,
      justifyContent: "flex-end",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        flex: "100%",
        order: 1,
      },
    },
    buttonText: {
      lineHeight: 1,
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",
      flexBasis: "50%",
      marginTop: 40,
      marginBottom: 32,
      [theme.breakpoints.down("md")]: {
        flex: "100%",
        marginTop: 32,
        justifyContent: "center",
      },
    },
    saveButton: {
      width: "100%",
      textAlign: "center",
      marginTop: 96,
      [theme.breakpoints.down("md")]: {
        flex: "100%",
        marginTop: 0,
        order: 2,
      },
    },
    container: {
      display: "flex",
      marginBottom: 48,
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginRight: 32,
      marginLeft: 32,
      [theme.breakpoints.down("md")]: {
        flex: "100%",
        marginTop: 0,
        order: 2,
        marginRight: 0,
        marginLeft: 0,
      },
    },
    paymentContainer: {
      textAlign: "center",
      width: "100%",
      display: "flex",
      alignItems: "center",
      height: 56,
      padding: "20px 32px 20px 20px",
      justifyContent: "space-evenly",
      [theme.breakpoints.down("md")]: {
        padding: "20px 10px 20px 8px",
        height: 72,
      },
    },
    closeIcon: {
      paddingRight: 12,
      paddingLeft: 12,
      [theme.breakpoints.down("md")]: {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    editIcon: {
      width: 30,
      height: 30,
      marginTop: -3,
    },
  })
);

const TabOptimization: React.FC<IPaymentMethods> = ({
  tabNumber,
  tabIndex,
  paymentMethodsSelector = (state: RootState) => getPaymentMethods(state),
  requestStatusSelector = (state: RootState) => getRequestStatus(state),
  updateStatusSelector = (state: RootState) => getUpdateStatus(state),
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "body.app.optimization";
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const dispatch = useDispatch();
  //Redirect to Payment method edit
  const history = useHistory<IRouteState>();

  useEffect(() => {
    dispatch(fetchPaymentMethods());
  }, [dispatch]);

  // State to display the add Payment Modal
  const [openAddModal, setOpenAddModal] = useState(false);
  // State for selected payment method using the input radio button
  const [selectedMethodId, setSelectedMethodId] = useState("");
  // Id for the edit payment method modal
  const [selectedMethodIdToEdit, setSelectedMethodIdToEdit] = useState("");

  /* Retrieve data from the store */

  /* Payment Methods Request Status */
  const requestStatus = useSelector(requestStatusSelector);
  /* Payment methods */
  const paymentMethods = useSelector(paymentMethodsSelector);
  /* Update Default payment method status */
  const updateStatus = useSelector(updateStatusSelector);

  // side effect to determine selected payment method
  const defaultPaymentMethod = paymentMethods?.filter(
    (paymentMethod) => paymentMethod?.isDefault
  );
  useEffect(() => {
    if (ReducerStatus[requestStatus] === ReducerStatus.Succeeded) {
      setSelectedMethodId(defaultPaymentMethod?.[0]?.zID);
    }
  }, [paymentMethods, requestStatus]); // should be with only these 2 dependencies

  // State to display edit payment method modal
  const [showEditPaymentModal, setShowEditPaymentModal] = useState(false);

  return (
    <TabPanel
      data-qatag="tabPanel"
      value={tabNumber}
      index={tabIndex}
      className={classes.tabPanel}
    >
      <Box data-qatag="container" className={classes.container}>
        <Box data-qatag="logoContainer" className={classes.logoContainer}>
          <img
            data-qatag="titleIcon"
            className={styles.titleIcon}
            src={paymentIcon}
            alt="wire-frame"
          />
          <Typography
            data-qatag="titleText"
            variant="h6"
            className={classes.titleText}
          >
            Payment Methods
          </Typography>
        </Box>
        <Box
          data-qatag="addPaymentButton"
          className={classes.addPaymentButton}
          onClick={() => {
            setOpenAddModal((prevState) => !prevState);
          }}
        >
          <img
            data-qatag="addIcon"
            className={styles.addIcon}
            src={addPaymentIcon}
            alt="wire-frame"
          />
          <Typography
            data-qatag="titleText"
            variant="h6"
            className={classes.titleText}
          >
            Add Payment Method
          </Typography>
        </Box>
        {paymentMethods?.length > 0 ? (
          <>
            {[...paymentMethods]
              ?.sort((a, b) => {
                // toLocale will be more readable but for compatibility ...
                const idA = a?.zID?.toLowerCase();
                const idB = b?.zID?.toLowerCase();
                if (idA < idB) return -1;
                return 0; //default return value (no sorting)
              })
              ?.sort((a, b) => {
                if (b.isDefault) return 1;
                return -1; //default return value (no sorting)
              })
              ?.map((info: IPaymentMethod, index: number) => {
                const lastDigits = info.creditCard?.cardNumber
                  ?.replaceAll("*", "")
                  ?.trim();
                return (
                  <Box
                    data-qatag="paymentContainer"
                    key={index}
                    className={`${classes.paymentContainer} ${
                      info.isDefault ? styles.defaultPayment : ""
                    }`}
                  >
                    <Box
                      data-qatag="paymentDetails"
                      display="flex"
                      alignItems="center"
                      className={styles.paymentDetails}
                    >
                      <input
                        data-qatag="lastDigits"
                        type="radio"
                        value={lastDigits}
                        name="paymentMethod"
                        checked={selectedMethodId === info?.zID}
                        defaultChecked={info.isDefault}
                        onChange={() => setSelectedMethodId(info?.zID)}
                        disabled={validateCardDate(
                          info?.creditCard?.cardExpirationYear,
                          info?.creditCard?.cardExpirationMonth
                        )}
                      />
                      <img
                        data-qatag="creditCardIcon"
                        className={styles.creditCardIcon}
                        src={getIcon(info?.creditCard?.creditCardType)}
                        alt="Visa"
                      />
                      <Box
                        data-qatag="cardTypeText"
                        className={styles.cardTypeText}
                      >
                        {info?.creditCard?.creditCardType !==
                        PaymentMethod[PaymentMethod.PayPal]
                          ? info?.creditCard?.creditCardType
                          : ""}
                      </Box>
                      <Box
                        data-qatag="lastDigitsText"
                        className={styles.lastDigitsText}
                      >
                        **** {lastDigits}
                      </Box>
                    </Box>
                    <Box
                      data-qatag="defaultBox"
                      className={`${styles.defaultBox} ${classes.titleText}`}
                    >
                      {info?.isDefault ? "Default" : ""}
                    </Box>

                    <Box
                      data-qatag="expireDateBox"
                      className={`${styles.expireDateBox} ${
                        validateCardDate(
                          info?.creditCard?.cardExpirationYear,
                          info?.creditCard?.cardExpirationMonth
                        )
                          ? classes.noValidPayment
                          : ""
                      }`}
                    >
                      {info?.creditCard?.cardExpirationMonth &&
                      info?.creditCard?.cardExpirationYear
                        ? `${
                            validateCardDate(
                              info?.creditCard?.cardExpirationYear,
                              info?.creditCard?.cardExpirationMonth
                            )
                              ? "Expired"
                              : "Expires"
                          } ${info?.creditCard?.cardExpirationMonth?.toLocaleString(
                            "en-US",
                            {
                              minimumIntegerDigits: 2,
                              useGrouping: false,
                            }
                          )}/${info?.creditCard?.cardExpirationYear}`
                        : ""}
                    </Box>
                    {/* Actions */}
                    <Box
                      data-qatag="editAction"
                      className={styles.editAction}
                      onClick={() => {
                        setSelectedMethodIdToEdit(info?.zID);
                        setShowEditPaymentModal(true);
                      }}
                    >
                      <IconButton data-qatag="IconButton">
                        <Icon
                          data-qatag="editIcon"
                          className={`MuiSelect-icon MuiSvgIcon-root ${classes.editIcon}`}
                        >
                          <img data-qatag="edit" src={editIcon} alt="edit" />
                        </Icon>
                      </IconButton>
                      <span
                        data-qatag="editLabelPaymentMethod"
                        className={styles.editLabelPaymentMethod}
                      >
                        Edit
                      </span>
                    </Box>
                    <DeleteButton
                      data-qatag="deleteButton"
                      lastDigits={lastDigits}
                      paymentMethodId={info?.zID}
                    />
                  </Box>
                );
              })}
            {/* Button */}
            <Box data-qatag="saveButton" className={classes.saveButton}>
              <AppButton
                data-qatag="handleChangePaymentMethod"
                buttonStyle={AppButtonStyles.Green}
                className={`${styles.customButton}`}
                isDisabled={defaultPaymentMethod?.[0]?.zID === selectedMethodId}
                onClick={() => {
                  dispatch(
                    updatePaymentMethod({
                      zPaymentMethodID: selectedMethodId,
                      setAsDefault: true,
                    })
                  );
                }}
              >
                <Typography
                  data-qatag="buttonText"
                  align="center"
                  className={classes.buttonText}
                  variant="body2"
                >
                  Use This Payment Method
                </Typography>
              </AppButton>
            </Box>
          </>
        ) : ReducerStatus[requestStatus] !== ReducerStatus.Idle ? (
          <EmptyState
            data-qatag="EmptyState"
            error={formatMessage({
              id: `${prefix}.error.title`,
            })}
            suggestion={"You don't have a payment method"}
          />
        ) : (
          ""
        )}
      </Box>
      {/* Loading modal */}
      <Spinner
        data-qatag="loadingModal"
        text="Loading..."
        isActive={
          ReducerStatus[requestStatus] === ReducerStatus.Loading ||
          ReducerStatus[updateStatus] === ReducerStatus.Loading
        }
      />
      {/* Add Payment Method Modal */}
      <AddPaymentMethod
        data-qatag="addPaymentMethod"
        isOpen={openAddModal}
        handleOnClose={() => {
          setOpenAddModal((prevState) => !prevState);
        }}
      />
      {/* Alert error */}
      <Alert
        data-qatag="alertError"
        icon={AlertIcon.Warning}
        title="Something went wrong"
        text="Note: you can't default payment method"
        buttonText="Continue"
        approveHandler={() => {
          dispatch(resetUpdate());
        }}
        isOpen={ReducerStatus[updateStatus] === ReducerStatus.Failed}
        closeHandler={() => {
          dispatch(resetUpdate());
        }}
      />
      {/* Success Alert */}
      <Alert
        data-qatag="successAlert"
        icon={AlertIcon.Success}
        title="Complete"
        text="Default payment method was changed"
        buttonText="Close"
        approveHandler={() => {
          dispatch(resetUpdate());
          dispatch(fetchPaymentMethods());
        }}
        isOpen={ReducerStatus[updateStatus] === ReducerStatus.Succeeded}
        closeHandler={() => {
          dispatch(resetUpdate());
          dispatch(fetchPaymentMethods());
        }}
      />
      {/* EditPaymentMethodModal */}
      <EditPaymentMethodModal
        data-qatag="EditPaymentMethodModal"
        isOpen={showEditPaymentModal}
        handleOnClose={() => setShowEditPaymentModal(false)}
        paymentMethodId={selectedMethodIdToEdit}
      />
    </TabPanel>
  );
};

export default TabOptimization;
