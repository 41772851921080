import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { IDeviceInfo } from "model/scan/DeviceInfo";
import React from "react";
import { FormattedMessage } from "react-intl";
import { DriverContactForm, IContactSubmitDetails } from "./DriverContactForm";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      width: "707px",
      height: "783px",
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        width: "330px",
        height: "650px",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(2),
      },
    },
    actionButton: {
      width: "255px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    closeButton: {
      position: "absolute",
      top: "0px",
      right: "0px",
    },
    title_container: {
      alignSelf: "flex-start",
    },
    description_container: {
      marginTop: theme.spacing(2),
      alignSelf: "flex-start",
    },
  })
);
//#endregion Component Styles

//#region Component Props
export interface IDriverContactViewProps {
  device: IDeviceInfo;
  initialName: string;
  initialEmail: string;
  onClose: () => void;
  onSubmit: (details: IContactSubmitDetails) => void;
}
//#endregion Component Props

//#region Component
export const DriverContactView: React.FC<IDriverContactViewProps> = (
  props: IDriverContactViewProps
) => {
  const classes = useStyles();
  return (
    <Box
      data-qatag="driver.install.contact"
      id="driver.install.contact"
      className={classes.modalRoot}
    >
      <Box
        data-qatag="driver.install.contact.title.container"
        id="driver.install.contact.title.container"
        className={classes.title_container}
      >
        <FormattedMessage
          data-qatag="drivers.install.contact.title"
          id="drivers.install.contact.title"
          defaultMessage="Contact Us"
        >
          {(text) => (
            <Typography
              data-qatag="drivers.install.contact.title_value"
              id="drivers.install.contact.title_value"
              variant="h4"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
      <Box
        data-qatag="driver.install.contact.description_container"
        id="driver.install.contact.description_container"
        className={classes.description_container}
      >
        <FormattedMessage
          data-qatag="driver.install.contact.description"
          id="driver.install.contact.description"
          defaultMessage="Please fill out the form below to ask us a question about your {device}."
          values={{ device: props.device.friendlyName }}
        >
          {(text) => (
            <Typography
              data-qatag="driver.install.contact.description_text"
              id="driver.install.contact.description_text"
              variant="body2"
              color="primary"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>

      <DriverContactForm
        data-qatag="driver.contact.form.component"
        initialName={props.initialName}
        device={props.device}
        initialEmail={props.initialEmail}
        onSubmit={async (details: IContactSubmitDetails) => {
          await props.onSubmit(details);
        }}
      />
      <IconButton
        data-qatag="driver.contact.iconButton"
        onClick={() => {
          props.onClose();
        }}
        className={classes.closeButton}
      >
        <Close data-qatag="driver.contact.form.closeButton" />
      </IconButton>
    </Box>
  );
};
//#endregion Component
