import {
  getAgentDownloadUrl,
  selectDownloadUrlStatus,
} from "app/Agent/AgentSlice";
import { RootState } from "app/store";
import { DownloadView } from "features/DownloadView";
import { Loading } from "main/Loading";
import { ReducerStatus } from "model/IReducerState";
import IUser from "model/user/IUser";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectCurrentUser } from "session/SessionSlice";

export interface IDownloadPageProps {
  userSelector?: (state: RootState) => IUser | null;
  downloadUrlStatusSelector?: (state: RootState) => ReducerStatus;
}

export const DownloadPage: React.FC<IDownloadPageProps> = ({
  userSelector = selectCurrentUser,
  downloadUrlStatusSelector = selectDownloadUrlStatus,
}) => {
  const user = useSelector(userSelector);
  const name = user ? user.firstName : "User";
  const history = useHistory();
  const dlCallback = () => {
    history.push("/lander");
  };
  const dispatch = useDispatch();
  const downloadUrlRequestStatus = useSelector(downloadUrlStatusSelector);

  useEffect(() => {
    dispatch(getAgentDownloadUrl());
  }, [dispatch]);

  const styleHack = {
    backgroundColor: "#007da0",
    width: "100%",
    marginTop: "-25px",
  };

  return (
    <div data-qatag="" style={styleHack}>
      {downloadUrlRequestStatus != ReducerStatus.Succeeded && (
        <Loading data-qatag="" />
      )}
      {downloadUrlRequestStatus === ReducerStatus.Succeeded && (
        <DownloadView data-qatag="" name={name} downloadHandler={dlCallback} />
      )}
    </div>
  );
};
