import { FormattedMessage } from "react-intl";
import { Typography, Box } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import deviceIcon from "assets/img/status/deviceIcon.svg";
import osIcon from "assets/img/status/operatingSystemIcon.svg";
import ispIcon from "assets/img/status/ispIcon.svg";
import internetSpeedIcon from "assets/img/status/internetSpeedIcon.svg";
import { Panel } from "solveiq.designsystem";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";
import styles from "./DeviceOverview.module.css";
import { rebootComputer } from "model/system/SystemSlice";
import { useDispatch, useSelector } from "react-redux";
import { IRebootPayload } from "model/messaging/payloads/IRebootPayload";
import { RootState } from "app/store";
import {
  selectOptimizationsFinalized,
  selectRebootRequired,
} from "app/Agent/AgentSlice";
import { selectIsAgentLocal } from "app/Agent/AgentSlice";
import { RebootDialog } from "../rebootDialog/RebootDialog";
import { useState } from "react";
import pingIcon from "assets/img/status/icn-ping.png";
import downloadIcon from "assets/img/status/icn-download.png";
import uploadIcon from "assets/img/status/icn-upload.png";
import speedTestLogo from "assets/img/status/speedtest-logo-w.png";

export interface IDeviceOverviewProps {
  deviceName: string;
  operatingSystem: string;
  internetProvider: string;
  downloadSpeed: string;
  uploadSpeed: string;
  ping: string;
  networkScanTime: string;
  pcImage: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 600,
    },
    unBold: {
      fontWeight: 400,
    },
    buttonText: {
      lineHeight: 1,
    },
    overviewNetworkContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    networkSpeedContainer: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignItems: "center",
      minWidth: "280px",
      [theme.breakpoints.down("md")]: {
        borderTop: "1px solid #DEDEDE",
        paddingTop: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        paddingBottom: "20px",
        borderBottom: "1px solid #DEDEDE",
      },
    },
    speedDisplayContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      width: "100%",
    },
    speedDisplay: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      flexBasis: "33.33333%",
      textAlign: "center",
      alignItems: "center",
      "& img": {
        width: "16px",
        height: "16px",
      },
    },
    speedTestLogo: {
      width: "140px",
      height: "17px",
      marginTop: "14px",
    },
    blue: {
      color: "#0087EA",
    },
  })
);

export const DeviceOverview: React.FC<IDeviceOverviewProps> = ({
  ...props
}) => {
  /* Localization prefix */
  const prefix = "device.overview";
  /* Custom styles to overwrite theme */
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSpeedTestClick = () => {
    window.open("https://speedtest.net", "_blank")?.focus();
  };

  return (
    <Panel data-qatag="deviceOverview" className={styles.deviceOverview}>
      <Box data-qatag="deviceOverviewTitleBox" mb={3} mt={1}>
        <Typography
          data-qatag="deviceOverviewTitleTypography"
          variant="h5"
          align="center"
        >
          <FormattedMessage
            data-qatag="deviceOverviewTitleFormattedMessage"
            id={`${prefix}.title`}
            defaultMessage="Overview"
          />
        </Typography>
      </Box>
      <div
        data-qatag="deviceOverviewItems"
        className={styles.deviceOverviewItems}
      >
        <div
          data-qatag="deviceOverviewImage"
          className={styles.deviceOverviewImage}
        >
          <img
            data-qatag="computerImage"
            src={props.pcImage}
            alt=""
            className={styles.computerImage}
          />
        </div>
        <div
          data-qatag="deviceOverviewDivider"
          className={`${styles.deviceOverviewDivider} ${styles.leftDivider}`}
        ></div>
        <div
          data-qatag="overviewNetworkContainer"
          className={classes.overviewNetworkContainer}
        >
          <div
            data-qatag="deviceOverviewInfo"
            className={styles.deviceOverviewInfo}
          >
            <div
              data-qatag="deviceOverviewInfoField"
              className={styles.deviceOverviewInfoField}
            >
              <img
                data-qatag="deviceOverviewInfoFieldIcon"
                className={styles.deviceOverviewInfoFieldIcon}
                src={deviceIcon}
                alt=""
              />
              <div
                data-qatag="deviceOverviewInfoFieldText"
                className={styles.deviceOverviewInfoFieldText}
              >
                <Typography
                  data-qatag="deviceOverviewInfoFieldTypography"
                  variant="body2"
                >
                  <span data-qatag="bold" className={classes.bold}>
                    <FormattedMessage
                      data-qatag="deviceOverviewInfoFieldFormattedMessage"
                      id={`${prefix}.device`}
                      defaultMessage="Device: "
                    />
                  </span>
                  {props.deviceName}
                </Typography>
              </div>
            </div>
            <div
              data-qatag="deviceOverviewInfoField"
              className={styles.deviceOverviewInfoField}
            >
              <img
                data-qatag="deviceOverviewInfoFieldIcon"
                className={styles.deviceOverviewInfoFieldIcon}
                src={osIcon}
                alt=""
              />
              <div
                data-qatag="deviceOverviewInfoFieldText"
                className={styles.deviceOverviewInfoFieldText}
              >
                <Typography
                  data-qatag="deviceOverviewInfoFieldTypography"
                  variant="body2"
                >
                  <span data-qatag="bold" className={classes.bold}>
                    <FormattedMessage
                      data-qatag="deviceOverviewInfoFieldFormattedMessage"
                      id={`${prefix}.operating.system`}
                      defaultMessage="Operating System: "
                    />
                  </span>
                  {props.operatingSystem}
                </Typography>
              </div>
            </div>
            <div
              data-qatag="deviceOverviewInfoField"
              className={styles.deviceOverviewInfoField}
            >
              <img
                data-qatag="deviceOverviewInfoFieldIcon"
                className={styles.deviceOverviewInfoFieldIcon}
                src={ispIcon}
                alt=""
              />
              <div
                data-qatag="deviceOverviewInfoFieldText"
                className={styles.deviceOverviewInfoFieldText}
              >
                <Typography
                  data-qatag="deviceOverviewInfoFieldTypography"
                  variant="body2"
                >
                  <span data-qatag="bold" className={classes.bold}>
                    <FormattedMessage
                      data-qatag="deviceOverviewInfoFieldFormattedMessage"
                      id={`${prefix}.internet.provider`}
                      defaultMessage="Internet Service Provider: "
                    />
                  </span>
                  {props.internetProvider}
                </Typography>
              </div>
            </div>
          </div>
          <div
            data-qatag="deviceOverviewDivider"
            className={`${styles.deviceOverviewDivider} ${styles.rightDivider}`}
          ></div>
          <div
            data-qatag="networkSpeedContainer"
            className={classes.networkSpeedContainer}
          >
            <div
              data-qatag="speedDisplayContainer"
              className={classes.speedDisplayContainer}
            >
              <div data-qatag="speedDisplay" className={classes.speedDisplay}>
                <img data-qatag="" src={pingIcon} alt="" />
                <Typography data-qatag="" variant="body1">
                  <FormattedMessage
                    data-qatag=""
                    id={`${prefix}.ping`}
                    defaultMessage="Ping"
                  />
                </Typography>
                <Typography data-qatag="" variant="h4" className={classes.blue}>
                  {props.ping}
                </Typography>
                <Typography
                  data-qatag=""
                  variant="body2"
                  className={classes.blue}
                >
                  <FormattedMessage
                    data-qatag=""
                    id={`${prefix}.ms`}
                    defaultMessage="Ms"
                  />
                </Typography>
              </div>
              <div data-qatag="speedDisplay" className={classes.speedDisplay}>
                <img data-qatag="" src={downloadIcon} alt="" />
                <Typography data-qatag="" variant="body1">
                  <FormattedMessage
                    data-qatag=""
                    id={`${prefix}.download`}
                    defaultMessage="Download"
                  />
                </Typography>
                <Typography data-qatag="" variant="h4" className={classes.blue}>
                  {props.downloadSpeed}
                </Typography>
                <Typography
                  data-qatag=""
                  variant="body2"
                  className={classes.blue}
                >
                  <FormattedMessage
                    data-qatag=""
                    id={`${prefix}.mbps`}
                    defaultMessage="Mbps"
                  />
                </Typography>
              </div>
              <div data-qatag="speedDisplay" className={classes.speedDisplay}>
                <img data-qatag="" src={uploadIcon} alt="" />
                <Typography data-qatag="" variant="body1">
                  <FormattedMessage
                    data-qatag=""
                    id={`${prefix}.upload`}
                    defaultMessage="Upload"
                  />
                </Typography>
                <Typography data-qatag="" variant="h4" className={classes.blue}>
                  {props.uploadSpeed}
                </Typography>
                <Typography
                  data-qatag=""
                  variant="body2"
                  className={classes.blue}
                >
                  <FormattedMessage
                    data-qatag=""
                    id={`${prefix}.mbps`}
                    defaultMessage="Mbps"
                  />
                </Typography>
              </div>
            </div>
            <img
              data-qatag="speedTestLogo"
              className={classes.speedTestLogo}
              src={speedTestLogo}
              onClick={onSpeedTestClick}
              alt=""
            />
            <Typography data-qatag="" variant="body1">
              {props.networkScanTime}
            </Typography>
          </div>
        </div>
      </div>
    </Panel>
  );
};
