import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import IActivatedLicense from "./IActivatedLicense";
import IProvisionedLicense from "./IProvisionedLicense";
import IAllocatedLicense from "./IAllocatedLicense";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";
import { selectCurrentUser } from "session/SessionSlice";

declare let productId: number;

export interface ILicenseData {
  provisionedLicenses: IProvisionedLicense[];
  allocatedLicenses: IAllocatedLicense[];
  activatedLicenses: IActivatedLicense[];
  availableLicenses: number;
}

export const fetchOrganizationLicenses = createAsyncThunk<
  ILicenseData,
  string | undefined,
  {
    state: RootState;
  }
>("licenses/fetchOrganizationLicenses", async (regKeyOverride, thunkApi) => {
  let regKey = regKeyOverride;
  if (regKey == null) {
    const state = thunkApi.getState();
    regKey = selectCurrentUser(state)?.registrationKey;
  }

  const url = `/api/license/GetOrganizationLicenses/${productId}/${regKey}`;

  try {
    const apiResponse = await RESTGatewayAPI.get<ILicenseData>(url);

    return apiResponse.data;
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch file cleanup scan : ${error}`
    );
  }
});

const initialState: IReducerState<ILicenseData> = {
  data: {
    provisionedLicenses: [],
    allocatedLicenses: [],
    activatedLicenses: [],
    availableLicenses: 0,
  },
  status: {
    [fetchOrganizationLicenses.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const licenseSlice = createSlice({
  name: "license",
  initialState,
  reducers: {
    setLicenseStatus: (state) => {
      state.status[fetchOrganizationLicenses.typePrefix] = ReducerStatus.Idle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizationLicenses.pending, (state) => {
      return {
        ...state,
        status: {
          ...state.status,
          [fetchOrganizationLicenses.typePrefix]: ReducerStatus.Loading,
        },
      };
    });
    builder.addCase(fetchOrganizationLicenses.fulfilled, (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          provisionedLicenses: action.payload.provisionedLicenses,
          allocatedLicenses: action.payload.allocatedLicenses,
          activatedLicenses: action.payload.activatedLicenses,
          availableLicenses: action.payload.availableLicenses,
        },
        status: {
          ...state.status,
          [fetchOrganizationLicenses.typePrefix]: ReducerStatus.Succeeded,
        },
      };
    });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { setLicenseStatus } = licenseSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = (amount: number): AppThunk => dispatch => {
//   setTimeout(() => {
//     dispatch(set(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLicenses = (state: RootState) => state.licenses.data;
export const selectProvisionedLicenses = (state: RootState) =>
  state.licenses.data?.provisionedLicenses;
export const selectAllocatedLicenses = (state: RootState) =>
  state.licenses.data?.allocatedLicenses;
export const selectActivatedLicenses = (state: RootState) =>
  state.licenses.data?.activatedLicenses;
export const selectAvailableLicenses = (state: RootState) =>
  state.licenses.data?.availableLicenses ?? 0;
export const selectTotalLicenseCount = (state: RootState) =>
  state.licenses.data?.provisionedLicenses.reduce(
    (val: number, obj: IProvisionedLicense) => val + obj.licenseCount,
    0
  ) ?? 0;
export const selectStatus = (state: RootState) => state.licenses.status;

export default licenseSlice.reducer;
