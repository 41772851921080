import styles from "./DeceptorDetails.module.css";
import deceptorImage from "assets/img/deceptor/icn-deceptors.svg";
import infoImage from "assets/img/info-icon.png";
import appEsteemImage from "assets/img/deceptor/AppEsteem.png";
import { Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { AppButton, AppButtonStyles, Panel } from "solveiq.designsystem";
import { useEffect, useState } from "react";
import {
  allowSoftware,
  blockSoftware,
  fetchAllowedSoftware,
  fetchDeceptorsBlocked,
  fetchDeceptorsFound,
  IDeceptorInfo,
  selectDeceptor,
  selectStatus,
} from "./DeceptorProtectionSlice";
import { RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IThunkStatus, ReducerStatus } from "model/IReducerState";
import queryString from "query-string";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";

export interface IDeceptorDetailsProps {
  deceptorSelector?: (state: RootState, id: string) => IDeceptorInfo | null;
  statusSelector?: (state: RootState) => IThunkStatus;
}

export const DeceptorDetails: React.FC<IDeceptorDetailsProps> = ({
  deceptorSelector = (state: RootState, id: string) =>
    selectDeceptor(state, id),
  statusSelector = (state: RootState) => selectStatus(state),
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { id } = queryString.parse(location.search);
  const prefix = "deceptorProtection.view";
  const protectionPath = "/deceptorprotection";

  const deceptor = useSelector((state: RootState) =>
    deceptorSelector(state, id as string)
  );

  useEffect(() => {
    dispatch(fetchDeceptorsBlocked());
    dispatch(fetchDeceptorsFound());
    dispatch(fetchAllowedSoftware());
  }, [dispatch]);

  const allowText = formatMessage({
    id: `${prefix}.allowSoftware`,
    defaultMessage: "Allow Software",
  });
  const blockText = formatMessage({
    id: `${prefix}.blockSoftware`,
    defaultMessage: "Block Software",
  });
  const buttonText = useSelector(() => {
    return deceptor?.IsAllowed ? blockText : allowText;
  });

  const [showAppEsteemHover, setShowAppEsteemHover] = useState(false);

  const onButtonClick = async () => {
    if (!deceptor) return;

    if (deceptor.IsAllowed) {
      await Promise.all([dispatch(blockSoftware(deceptor))]);
    } else {
      await Promise.all([dispatch(allowSoftware(deceptor))]);
    }
    dispatch(fetchAllowedSoftware());
    dispatch(fetchDeceptorsBlocked());
    dispatch(fetchDeceptorsFound());
    history.push(protectionPath);
  };

  const breadcrumbEndpoint = `deceptordetails?id=${deceptor?.Id}`;

  return (
    <div
      data-qatag="deceptorDetailsPageContainer"
      className={styles.deceptorDetailsPageContainer}
    >
      <Breadcrumbs data-qatag="deceptorDetailsBreadcrumbs" />
      <Panel data-qatag="deceptorDetails" className={styles.deceptorDetails}>
        <div data-qatag="header" className={styles.header}>
          <div data-qatag="titleContainer" className={styles.titleContainer}>
            <img
              data-qatag="deceptorImage"
              src={deceptorImage}
              className={styles.deceptorImage}
              alt=""
            />
            <div
              data-qatag="titleTextContainer"
              className={styles.titleTextContainer}
            >
              <Typography
                data-qatag="title"
                className={styles.title}
                variant="h5"
              >
                {deceptor?.Name}
              </Typography>
              <div
                data-qatag="filePathContainer"
                className={styles.filePathContainer}
              >
                <Typography
                  data-qatag="filePathLabel"
                  className={styles.filePathLabel}
                  variant="body2"
                >
                  <FormattedMessage
                    data-qatag="filePathMessage"
                    id={`${prefix}.details.filePath`}
                    defaultMessage="File Path: "
                  />
                </Typography>
                <Typography
                  data-qatag="filePathValue"
                  className={styles.filePathValue}
                  variant="body2"
                >
                  {deceptor?.FilePath}
                </Typography>
              </div>
            </div>
          </div>
          <div
            data-qatag="mobileFilePathContainer"
            className={styles.mobileFilePathContainer}
          >
            <Typography
              data-qatag="mobileFilePath"
              className={styles.mobileFilePath}
              variant="body2"
            >
              <span
                data-qatag="mobileFilePathLabel"
                className={styles.mobileFilePathLabel}
              >
                {formatMessage({
                  id: `${prefix}.details.filePath`,
                  defaultMessage: "File Path: ",
                })}
              </span>
              {deceptor?.FilePath}
            </Typography>
          </div>

          <div data-qatag="buttonContainer" className={styles.buttonContainer}>
            <AppButton
              data-qatag="actionButton"
              className={styles.actionButton}
              buttonStyle={AppButtonStyles.Green}
              onClick={onButtonClick}
            >
              <Typography data-qatag="actionButtonTypography" variant="body2">
                {buttonText}
              </Typography>
            </AppButton>
            <div
              data-qatag="appEsteemInfoContainer"
              className={styles.appEsteemInfoContainer}
              onMouseEnter={() => setShowAppEsteemHover(true)}
              onMouseLeave={() => setShowAppEsteemHover(false)}
            >
              {showAppEsteemHover && (
                <div
                  data-qatag="appEsteemHoverContainer"
                  className={styles.appEsteemHoverContainer}
                >
                  <div
                    data-qatag="appEsteemHoverText"
                    className={styles.appEsteemHoverText}
                  >
                    <span
                      data-qatag="appEsteemHoverName"
                      className={styles.appEsteemHoverName}
                    >
                      <FormattedMessage
                        data-qatag="appEsteemHoverNameMessage"
                        id={`${prefix}.appEsteemHover.name`}
                        defaultMessage="AppEsteem"
                      />
                    </span>
                    <FormattedMessage
                      data-qatag="appEsteemHoverMessage"
                      id={`${prefix}.appEsteemHover.text`}
                      defaultMessage="hunts for apps and websites they believe are misleading, cheating, and deceiving consumers. They use their publicly available checklist to do an evaluation, and when they find a deceptor, they publish the app or site, along with the reasons why they listed it, on their own deceptor page. This page was generated from AppEsteem’s publicly available data."
                    />
                  </div>
                </div>
              )}
              <img
                data-qatag="infoImage"
                src={infoImage}
                className={styles.infoImage}
                alt=""
              />
              <Typography data-qatag="poweredBy" variant="body2">
                <FormattedMessage
                  data-qatag="poweredByMessage"
                  id={`${prefix}.details.poweredBy`}
                  defaultMessage="Powered By: "
                />
              </Typography>
              <img
                data-qatag="appEsteemImage"
                src={appEsteemImage}
                className={styles.appEsteemImage}
                alt=""
              />
            </div>
          </div>
        </div>
        <div data-qatag="avListContainer" className={styles.avListContainer}>
          <Typography
            data-qatag="blockedBy"
            className={styles.blockedBy}
            variant="h6"
          >
            <FormattedMessage
              data-qatag="blockedByMessage"
              id={`${prefix}.details.blockedBy`}
              defaultMessage="Blocked by Anti-Virus programs: "
            />
          </Typography>
          <ul data-qatag="avList" className={styles.avList}>
            {deceptor?.AvBlockList.map((name: string, index: number) => {
              return (
                <li
                  data-qatag="avListItem"
                  className={styles.avListItem}
                  key={index}
                >
                  <Typography data-qatag="avListItemTypography" variant="body2">
                    {name}
                  </Typography>
                </li>
              );
            })}
          </ul>
        </div>
      </Panel>
    </div>
  );
};
