import { Alert, AlertIcon } from "solveiq.designsystem";
import { useIntl } from "react-intl";

export interface IAlertError {
  /* Controls to open the alert */
  isOpen: boolean;
  /* Callback to close the alert*/
  closeHandler: () => void;
  /* Error message */
  text: string;
}

const AlertError: React.FC<IAlertError> = ({ closeHandler, isOpen, text }) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "alert.add.team.members.error";

  return (
    <Alert
      data-qatag="errorMessage"
      icon={AlertIcon.Warning}
      title="Something went wrong"
      text={text}
      buttonText={formatMessage({
        id: `${prefix}.button`,
      })}
      approveHandler={closeHandler}
      isOpen={isOpen}
      closeHandler={closeHandler}
    />
  );
};

export default AlertError;
