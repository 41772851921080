import {
  DriverDownloadStatus,
  IDriverUpdateModel,
} from "model/driver/DriverUpdateModel";
import { DriverProgressBar } from "solveiq.designsystem";
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { MouseEventHandler } from "react";
import { FormattedMessage } from "react-intl";
import { CSSProperties } from "@material-ui/styles";
import failedIcon from "assets/icon/icn-failed-state.svg";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    cancel_container: {
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    error_container: {
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    progressBar_container: {
      width: "236px",
      marginLeft: theme.spacing(1),
    },
    progressBar_text_container: {
      marginTop: theme.spacing(1),
    },
    status_container: {
      marginRight: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        marginRight: theme.spacing(0),
      },
    },
    state_image: {
      marginRight: theme.spacing(2),
    },
    description_container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      marginRight: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        marginRight: theme.spacing(0),
      },
    },
  })
);
//#endregion Component Styles

//#region Component Props
export interface IDownloaderProps {
  model: IDriverUpdateModel;
  ProgressBarStyle?: CSSProperties;
  onRetry: (e: React.MouseEvent<HTMLElement>) => void;
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
}
//#endregion Component Props

//#region Component
export const Downloader: React.FC<IDownloaderProps> = (props) => {
  const classes = useStyles();

  const downloadRecord = props.model.downloadRecord;

  if (downloadRecord == null) {
    // this *should* never happen, but I'm sure it will
    return (
      <FormattedMessage
        data-qatag="driver.downloader.unknown"
        id="driver.downloader.unknown"
        defaultMessage="Unknown"
      >
        {(text) => (
          <Typography
            data-qatag="driver.downloader.unknown.text"
            id="driver.downloader.unknown.text"
            variant="body2"
            color="secondary"
            style={{ fontWeight: 600 }}
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
    );
  }

  if (downloadRecord.downloadStatus === DriverDownloadStatus.Initializing) {
    return (
      <Box
        data-qatag="driver.downloader.initializing.status_container"
        id="driver.downloader.initializing.status_container"
        className={classes.status_container}
      >
        <FormattedMessage
          data-qatag="driver.downloader.initializing"
          id="driver.downloader.initializing"
          defaultMessage="Initializing"
        >
          {(text) => (
            <Typography
              data-qatag="driver.downloader.initializing.text"
              id="driver.downloader.initializing.text"
              variant="body2"
              color="textPrimary"
              style={{ fontWeight: 600 }}
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
    );
  }

  if (downloadRecord.downloadStatus === DriverDownloadStatus.Cancelling) {
    return (
      <Box
        data-qatag="driver.downloader.cancelling.status_container"
        id="driver.downloader.cancelling.status_container"
        className={classes.status_container}
      >
        <FormattedMessage
          data-qatag="driver.downloader.cancelling"
          id="driver.downloader.cancelling"
          defaultMessage="Cancelling"
        >
          {(text) => (
            <Typography
              data-qatag="driver.downloader.cancelling.text"
              id="driver.downloader.cancelling.text"
              variant="body2"
              color="textPrimary"
              style={{ fontWeight: 600 }}
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
    );
  }

  if (downloadRecord.downloadStatus === DriverDownloadStatus.Cancelled) {
    return (
      <Box
        data-qatag="driver.downloader.cancelled.container"
        id="driver.downloader.cancelled.container"
        className={classes.error_container}
      >
        <Box
          data-qatag="driver.downloader.cancelled.description_container"
          id="driver.downloader.cancelled.description_container"
          className={classes.description_container}
        >
          <FormattedMessage
            data-qatag="driver.downloader.cancelled"
            id="driver.downloader.cancelled"
            defaultMessage="Download Cancelled"
          >
            {(text) => (
              <Typography
                data-qatag="driver.downloader.cancelled.text"
                id="driver.downloader.cancelled.text"
                variant="body2"
                color="textPrimary"
                style={{ fontWeight: 600 }}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </Box>
        <Button
          data-qatag="driver.downloader.cancelled.retryButton"
          id="driver.downloader.cancelled.retryButton"
          onClick={(e) => {
            e.preventDefault();
            props.onRetry(e);
          }}
        >
          <FormattedMessage
            data-qatag="driver.downloader.cancelled.retryButton.content"
            id="driver.downloader.cancelled.retryButton.content"
            defaultMessage="Retry"
          >
            {(text) => (
              <Typography
                data-qatag="driver.downloader.cancelled.retryButton.content.text"
                id="driver.downloader.cancelled.retryButton.content.text"
                variant="body2"
                color="secondary"
                style={{ fontWeight: 600 }}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </Button>
      </Box>
    );
  }

  if (downloadRecord.downloadStatus === DriverDownloadStatus.Error) {
    return (
      <Box
        data-qatag="driver.downloader.error.container"
        id="driver.downloader.error.container"
        className={classes.error_container}
      >
        <Box
          data-qatag="driver.downloader.error.description_container"
          id="driver.downloader.error.description_container"
          className={classes.description_container}
        >
          <img
            data-qatag="driver.downloader.error.image"
            id="driver.downloader.error.image"
            src={failedIcon}
            alt="download failed"
            className={classes.state_image}
          />
          <FormattedMessage
            data-qatag="driver.downloader.error"
            id="driver.downloader.error"
            defaultMessage="Download Error"
          >
            {(text) => (
              <Typography
                data-qatag="driver.downloader.error.text"
                id="driver.downloader.error.text"
                variant="body2"
                color="textPrimary"
                style={{ fontWeight: 600 }}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </Box>
        <Button
          data-qatag="driver.downloader.error.retryButton"
          id="driver.downloader.error.retryButton"
          onClick={(e) => {
            e.preventDefault();
            props.onRetry(e);
          }}
        >
          <FormattedMessage
            data-qatag="driver.downloader.error.retryButton.content"
            id="driver.downloader.error.retryButton.content"
            defaultMessage="Retry"
          >
            {(text) => (
              <Typography
                data-qatag="driver.downloader.error.retryButton.content.text"
                id="driver.downloader.error.retryButton.content.text"
                variant="body2"
                color="secondary"
                style={{ fontWeight: 600 }}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </Button>
      </Box>
    );
  }

  return (
    <Box
      data-qatag="driver.downloader.cancel.container"
      id="driver.downloader.cancel.container"
      className={classes.cancel_container}
    >
      <Box
        data-qatag="driver.downloader.progressBar.container"
        id="driver.downloader.progressBar.container"
        className={classes.progressBar_container}
        style={props.ProgressBarStyle}
      >
        <DriverProgressBar
          data-qatag="driver.downloader.progressBar"
          current={downloadRecord.downloadPercentProgress}
          total={100}
          backerHeight={4}
          backerColor="#D7E1EA"
          fillHeight={4}
          fillColor="linear-gradient(90deg, #0CA15D 0%, #0FD179 100%)"
        />
        <Box
          data-qatag="driver.downloader.progressBar.text.container"
          id="driver.downloader.progressBar.text.container"
          className={classes.progressBar_text_container}
        >
          <Typography
            data-qatag="driver.downloader.progressBar.text"
            id="driver.downloader.progressBar.text"
            variant="body1"
            color="primary"
            align="center"
          >{`${downloadRecord.downloadPercentProgress}% (${downloadRecord.downloadCompletionETA})`}</Typography>
        </Box>
      </Box>
      <Button
        data-qatag="driver.downloader.cancelButton"
        id="driver.downloader.cancelButton"
        onClick={(e) => {
          e.preventDefault();
          props.onCancel(e);
        }}
      >
        <FormattedMessage
          data-qatag="driver.downloader.cancelButton.content"
          id="driver.downloader.cancelButton.content"
          defaultMessage="Cancel"
        >
          {(text) => (
            <Typography
              data-qatag="driver.downloader.cancelButton.content.text"
              id="driver.downloader.cancelButton.content.text"
              variant="body2"
              color="secondary"
              style={{ fontWeight: 600 }}
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Button>
    </Box>
  );
};
//#endregion Component
