import { useCallback } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Typography } from "@material-ui/core";
import { useStyles } from "solveiq.designsystem";
import { Alert, AlertIcon } from "solveiq.designsystem";

export interface IAlertWarning {
  /* Callback to accept the alert */
  approveHandler: () => void;
  /* Controls to open the alert */
  isOpen: boolean;
  /* Callback to close the alert*/
  closeHandler: () => void;
  /* User name to delete */
  name: string;
}

const AlertWarning: React.FC<IAlertWarning> = ({
  closeHandler,
  approveHandler,
  isOpen,
  name,
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "alert.add.team.members.warning";
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const personalizedTitle = useCallback(
    () => (
      <FormattedMessage
        data-qatag="personalizedTitle"
        id={`${prefix}.title`}
        values={{
          name: (
            <span data-qatag="bold" className={classes.bold}>
              {name}
            </span>
          ),
        }}
      />
    ),
    [classes.bold, name]
  );

  const personalizedText = useCallback(
    () => (
      <FormattedMessage
        data-qatag="formattedMessage"
        id={`${prefix}.message`}
        values={{
          name: (
            <span data-qatag="bold" className={classes.bold}>
              {name}
            </span>
          ),
        }}
      />
    ),
    [classes.bold, name]
  );

  return (
    <Alert
      data-qatag="alertWarning"
      icon={AlertIcon.Warning}
      title={personalizedTitle()}
      text={personalizedText()}
      buttonText={formatMessage({
        id: `${prefix}.button`,
      })}
      approveHandler={approveHandler}
      isOpen={isOpen}
      closeHandler={closeHandler}
      cancelHandler={closeHandler}
      cancelButtonText={formatMessage({
        id: `${prefix}.cancel`,
      })}
    />
  );
};

export default AlertWarning;
