export function removeDuplicatesBy<T>(
  keyFn: (arg0: T) => number | string,
  array: T[]
) {
  const mySet = new Set();
  if (!array) return [];
  return array.filter((x: T) => {
    const key = keyFn(x),
      isNew = !mySet.has(key);
    if (isNew) mySet.add(key);
    return isNew;
  });
}
