import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IUser } from "./IUser";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";
import { selectCurrentUser } from "session/SessionSlice";

export const fetchOrganizationUsers = createAsyncThunk<
  IUser[],
  void,
  {
    state: RootState;
  }
>("users/fetchOrganizationUsers", async (_, thunkApi) => {
  const state = thunkApi.getState();

  try {
    const url = `/api/organization/users/${
      selectCurrentUser(state)?.registrationKey
    }`;

    const apiResponse = await RESTGatewayAPI.get(url);

    return apiResponse.data;
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch file cleanup scan : ${error}`
    );
  }
});

export const fetchUsersOnlineStatus = createAsyncThunk<
  {
    isOnline: boolean | undefined;
    uuid: string;
    // Field to match the user
    userID: number;
  }[],
  void,
  {
    state: RootState;
  }
>("users/fetchMachineUsers", async (_, thunkApi) => {
  const state = thunkApi.getState();

  //Licenses
  const licenses = state?.licenses?.data;
  //Only Activated licenses have UUID
  const activatedLicenses = licenses?.activatedLicenses;

  try {
    const requestUsersOnlineStatus = activatedLicenses.map((license) => {
      const url = `/api/OnlineStatus/${license.uuid}`;
      return RESTGatewayAPI.get(url);
    });
    // Responses from the API
    const apiResponse = await Promise.all(requestUsersOnlineStatus);

    const usersOnline = apiResponse.map((response, index) => ({
      isOnline: response?.data,
      uuid: activatedLicenses?.[index]?.uuid,
      userID: activatedLicenses?.[index]?.userID,
    }));

    return usersOnline;
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch file cleanup scan : ${error}`
    );
  }
});

export const fetchUsersMachine = createAsyncThunk<
  {
    machineDisplayName: string;
    uuid: string;
    // Field to match the user
    userID: number;
  }[],
  void,
  {
    state: RootState;
  }
>("users/fetchUsersOnlineStatus", async (_, thunkApi) => {
  const state = thunkApi.getState();

  //Licenses
  const licenses = state?.licenses?.data;
  //Only Activated licenses have UUID
  const activatedLicenses = licenses?.activatedLicenses;

  try {
    const requestUsersOnlineStatus = activatedLicenses.map((license) => {
      const url = `/api/core/machineintelligence/persisted/${license.uuid}`;
      return RESTGatewayAPI.get(url);
    });
    // Responses from the API
    const apiResponse = await Promise.all(requestUsersOnlineStatus);

    const usersMachine = apiResponse.map((response, index) => ({
      machineDisplayName: response?.data?.machineDisplayName,
      uuid: activatedLicenses?.[index]?.uuid,
      userID: activatedLicenses?.[index]?.userID,
    }));

    return usersMachine;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch users machine : ${error}`);
  }
});

const initialState: IReducerState<IUser[]> = {
  data: [],
  status: {
    [fetchOrganizationUsers.typePrefix]: ReducerStatus.Idle,
    [fetchUsersOnlineStatus.typePrefix]: ReducerStatus.Idle,
    [fetchUsersMachine.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<IUser[]>) => {
      state.data = action.payload;
    },
    setUsersRoleStatus: (state) => {
      state.status[fetchOrganizationUsers.typePrefix] = ReducerStatus.Idle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizationUsers.pending, (state) => {
      state.status[fetchOrganizationUsers.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(fetchOrganizationUsers.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status[fetchOrganizationUsers.typePrefix] = ReducerStatus.Succeeded;
    });
    // This case will add loading for the online status
    builder.addCase(fetchUsersOnlineStatus.pending, (state) => {
      state.status[fetchUsersOnlineStatus.typePrefix] = ReducerStatus.Loading;
    });
    // This case will add the online status
    builder.addCase(fetchUsersOnlineStatus.fulfilled, (state, action) => {
      state.data = state.data.map((user) => {
        // Filter on the payload if the user is online
        const hasOnlineRecord = action.payload.filter(
          (onlineStatus) => onlineStatus.userID === user.id
        );
        return {
          ...user,
          isOnline: hasOnlineRecord?.[0]?.isOnline ?? undefined,
        };
      });
    });
    // This case will add loading for the users machine
    builder.addCase(fetchUsersMachine.pending, (state) => {
      state.status[fetchUsersMachine.typePrefix] = ReducerStatus.Loading;
    });
    // This case will add the online status
    builder.addCase(fetchUsersMachine.fulfilled, (state, action) => {
      state.data = state.data.map((user) => {
        // Filter on the payload if the user is online
        const hasMachineRecord = action.payload.filter(
          (onlineStatus) => onlineStatus.userID === user.id
        );
        return {
          ...user,
          machineDisplayName:
            hasMachineRecord?.[0]?.machineDisplayName ?? "Unknown",
        };
      });
    });
  },
});

export const { set, setUsersRoleStatus } = userSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = (amount: number): AppThunk => dispatch => {
//   setTimeout(() => {
//     dispatch(set(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUsers = (state: RootState) => state.users.data;
export const selectUserById = (state: RootState, id: number) =>
  state.users.data?.find((u) => u.id === id);
export const selectFetchUsersStatus = (state: RootState) =>
  state.users.status[fetchOrganizationUsers.typePrefix];
export const selectStatus = (state: RootState) => state.users.status;

export default userSlice.reducer;
