import { selectDeviceById } from "features/scan/ScanSlice";
import { selectCurrentUser } from "session/SessionSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeContactModal,
  selectCurrentContactDeviceId,
} from "../DriverSlice";
import { submitContactForm } from "../Thunks";
import { IContactSubmitDetails } from "./DriverContactForm";
import { DriverContactView } from "./DriverContactView";

//#region Component

export const DriverContactModal: React.FC = () => {
  const deviceId = useSelector(selectCurrentContactDeviceId);
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const deviceInfo = useSelector(selectDeviceById(deviceId));

  if (deviceInfo == null) {
    return null;
  }

  let fullName = "";
  let email = "";
  if (user != null) {
    fullName = user.firstName + " " + user.lastName;
    email = user.email;
  }

  return (
    <DriverContactView
      data-qatag="driver.contact.view"
      device={deviceInfo}
      initialName={fullName}
      initialEmail={email}
      onClose={() => dispatch(closeContactModal())}
      onSubmit={(details: IContactSubmitDetails) => {
        dispatch(submitContactForm(details));
      }}
    />
  );
};

//#endregion Component
