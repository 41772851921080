import React from "react";
import { IntlProvider as Provider } from "react-intl";
import { ENMessages } from "solveiq.designsystem";

type IntlProviderProps = {
  children: React.ReactNode;
};

function IntlProvider({ children }: IntlProviderProps) {
  return (
    <Provider data-qatag="intlProvider" locale="en" messages={ENMessages}>
      {children}
    </Provider>
  );
}

export default IntlProvider;
