import { Field, Form, Formik } from "formik";
import { object, string, number } from "yup";
import { Typography, Box, LinearProgress } from "@material-ui/core";
import {
  AppButton,
  AppButtonStyles,
  Select,
  SelectOptionInterface,
  getCountries,
  Alert,
  AlertIcon,
  Spinner,
  IRouteState,
} from "solveiq.designsystem";
import { makeStyles, Theme } from "@material-ui/core/styles";
import cvvIcon from "assets/icon/cvv-security.svg";
import styles from "./CardForm.module.css";
import { SimpleTextField } from "solveiq.designsystem";
import { useEffect } from "react";
import paymentIcon from "assets/icon/icn-payment-method.svg";
import billingIcon from "assets/img/billing/icn-billing-overview.svg";
import { IPaymentMethod } from "../PaymentMethods/types";
import { ReducerStatus } from "model/IReducerState";
import {
  fetchPaymentMethods,
  updatePaymentMethod,
  getPaymentMethods,
  getRequestStatus,
  getUpdateStatus,
  resetUpdate,
} from "../PaymentMethods/PaymentMethodsSlice";
import { RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

export interface ICardForm {
  /* Payment Methods */
  paymentMethodsSelector?: (state: RootState) => IPaymentMethod[];
  /* Payment Methods Request Status */
  fetchStatusSelector?: (state: RootState) => ReducerStatus;
  /* Status to update default payment method */
  updateStatusSelector?: (state: RootState) => ReducerStatus;
}

/* Custom style for Dialog */
const useStyles = makeStyles((theme: Theme) => ({
  buttonText: {
    lineHeight: 1,
  },
  important: {
    color: "#BE2222",
  },
  error: {
    color: "#BE2222",
    marginLeft: 8,
  },
  responsiveElement: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "50%",
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "45%",
    },
  },
  cvvField: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "50%",
      paddingLeft: 0,
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "70%",
      paddingLeft: 24,
    },
  },
  cvvImageContainer: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "50%",
      textAlign: "start",
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "30%",
      textAlign: "end",
    },
  },
  responsiveContainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      marginTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
      marginTop: 8,
    },
  },
  titleText: {
    color: theme.palette.secondary.light,
    fontWeight: 600,
    marginLeft: 8,
  },
}));

function getMonths(startMonth = 0): SelectOptionInterface[] {
  const options = [];
  for (let i = startMonth; i < 12; i++) {
    options.push({
      value: i.toString(),
      label: (i + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
    });
  }

  return options;
}

function getYears(): SelectOptionInterface[] {
  const options = [];
  const currentYear = new Date().getFullYear();
  for (let i = 0; i < 20; i++) {
    options.push({
      value: (currentYear + i).toString(),
      label: (currentYear + i).toString(),
    });
  }
  return options;
}

const validationSchema = object({
  name: string().required("Name is required"),
  cardNumber: string(),
  monthExpiration: number(),
  yearExpiration: number(),
  securityCode: string(),
  country: string().required("Country is required"),
  email: string().required("Email is required").email("Invalid email address"),
  phoneNumber: string().required("Phone Number is required"),
  companyName: string().required("The company name is required"),
});

const CardForm: React.FC<ICardForm> = ({
  paymentMethodsSelector = (state: RootState) => getPaymentMethods(state),
  fetchStatusSelector = (state: RootState) => getRequestStatus(state),
  updateStatusSelector = (state: RootState) => getUpdateStatus(state),
}) => {
  // Custom styles
  const classes = useStyles();
  // Dispatch for the redux store
  const dispatch = useDispatch();

  //Redirect to Payment method edit
  const history = useHistory<IRouteState>();

  /* Retrieve data from the store */

  /* Payment Methods Request Status */
  const fetchStatus = useSelector(fetchStatusSelector);
  /* Payment methods */
  const paymentMethods = useSelector(paymentMethodsSelector);
  /* Update Default payment method status */
  const updateStatus = useSelector(updateStatusSelector);

  useEffect(() => {
    dispatch(fetchPaymentMethods());
  }, [dispatch]);

  // Payment method ID to modify
  const paymentMethodId = window?.location?.pathname?.split("/")?.[3];

  const selectedPaymentMethod = paymentMethods?.filter(
    (paymentMethod) => paymentMethod.zID === paymentMethodId
  )?.[0];

  const countryCode = getCountries?.filter(
    (country) => country?.name === selectedPaymentMethod?.paymentInfo?.country
  )?.[0]?.code;

  /* method to update payment methods */
  const handleSubmit = async (values: {
    name: string;
    cardNumber: string;
    monthExpiration: number;
    yearExpiration: number;
    securityCode: string;
    country: string;
    email: string;
    phoneNumber: string;
    companyName: string;
    zipCode: string;
  }) => {
    dispatch(
      updatePaymentMethod({
        zPaymentMethodID: paymentMethodId,
        creditCardHolderName: values?.name,
        creditCardExpirationYear: values?.yearExpiration,
        creditCardCountry: values?.country,
        creditCardPostalCode: values?.zipCode,
        creditCardExpirationMonth:
          parseInt(values?.monthExpiration?.toString()) + 1,
      })
    );
  };

  const yearToVerify = selectedPaymentMethod?.creditCard?.cardExpirationYear?.toString();
  const monthToVerify = (
    selectedPaymentMethod?.creditCard?.cardExpirationMonth - 1
  )?.toString();

  return (
    <Formik
      data-qatag="formikForm"
      enableReinitialize={true}
      initialValues={{
        name: selectedPaymentMethod?.paymentInfo?.name,
        cardNumber: `**** **** **** ${selectedPaymentMethod?.creditCard?.cardNumber
          ?.replaceAll("*", "")
          ?.trim()}`,
        monthExpiration: selectedPaymentMethod?.creditCard?.cardExpirationMonth,
        yearExpiration: selectedPaymentMethod?.creditCard?.cardExpirationYear,
        securityCode: Array(
          selectedPaymentMethod?.creditCard?.cardCVC?.toString()?.length || 1
        )
          ?.fill("*")
          ?.join(""),
        zipCode: selectedPaymentMethod?.paymentInfo?.zipCode,
        country: selectedPaymentMethod?.paymentInfo?.country,
        email: selectedPaymentMethod?.paymentInfo?.email || "",
        phoneNumber: selectedPaymentMethod?.paymentInfo?.phoneNumber || "",
        companyName: selectedPaymentMethod?.paymentInfo?.businessName || "",
      }}
      className={styles.formContainer}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting, errors, setFieldValue, touched }) => (
        <Form data-qatag="form" className={styles.form}>
          <div data-qatag="fieldContainer" className={styles.fieldContainer}>
            {/* Title */}
            <Box
              data-qatag="titleForm"
              display="flex"
              alignItems="center"
              mt={3}
            >
              <img
                data-qatag="titleIcon"
                className={styles.titleIcon}
                src={billingIcon}
                alt="wire-frame"
              />
              <Typography
                data-qatag="titleText"
                variant="h6"
                className={classes.titleText}
                color="primary"
              >
                Billing Info
              </Typography>
            </Box>
            {/* Email Address */}
            <Box
              data-qatag="emailAddress"
              mt={3}
              className={styles.responsiveField}
            >
              <Field
                data-qatag="field"
                component={SimpleTextField}
                name="email"
                type="text"
                label="Email Address"
                className={styles.field}
              />
            </Box>

            {/* Name */}
            <Box
              data-qatag="nameField"
              mt={4}
              className={styles.responsiveField}
            >
              <Field
                data-qatag="field"
                component={SimpleTextField}
                name="name"
                type="text"
                label="Name as it appears on card"
                className={styles.field}
              />
            </Box>

            {/* Phone Number */}
            <Box
              data-qatag="phoneNumber"
              mt={4}
              className={styles.responsiveField}
            >
              <Field
                data-qatag="field"
                component={SimpleTextField}
                name="phoneNumber"
                type="text"
                label="Phone Number"
                className={styles.field}
              />
            </Box>

            {/* Company Name */}
            <Box
              data-qatag="companyName"
              className={styles.responsiveField}
              mt={4}
            >
              <Field
                data-qatag="field"
                component={SimpleTextField}
                name="companyName"
                type="text"
                label="Company Name"
                className={styles.field}
              />
            </Box>
          </div>

          <div
            data-qatag="fieldContainer"
            className={`${styles.fieldContainer} ${styles.creditCardInfo}`}
          >
            {/* Title */}
            <Box
              data-qatag="titleForm"
              display="flex"
              alignItems="center"
              mt={3}
              className={styles.cardInfoField}
            >
              <img
                data-qatag="titleIcon"
                className={styles.titleIcon}
                src={paymentIcon}
                alt="wire-frame"
              />
              <Typography
                data-qatag="titleText"
                variant="h6"
                className={classes.titleText}
                color="primary"
              >
                Credit Card Info
              </Typography>
            </Box>
            {/* Card Number */}
            <Box
              data-qatag="cardNumber"
              mt={3}
              className={`${styles.responsiveField} ${styles.cardInfoField}`}
            >
              <Field
                data-qatag="field"
                component={SimpleTextField}
                name="cardNumber"
                type="text"
                label="Card Number"
                className={styles.field}
                disabled
              />
            </Box>

            <Box
              data-qatag="responsiveContainer"
              className={`${styles.responsiveField} ${styles.dateField}`}
            >
              <Box data-qatag="responsiveBox" className={styles.responsiveBox}>
                <Box
                  data-qatag="responsiveBox"
                  className={styles.dateContainer}
                >
                  <Box data-qatag="dateContainer" display="flex">
                    <Box
                      data-qatag="responsiveElement"
                      className={classes.responsiveElement}
                    >
                      <Select
                        data-qatag="monthExpiration"
                        name="monthExpiration"
                        onChange={(month) => {
                          setFieldValue("monthExpiration", month);
                        }}
                        defaultValue={
                          selectedPaymentMethod?.creditCard
                            ?.cardExpirationMonth !== undefined
                            ? (
                                selectedPaymentMethod?.creditCard
                                  ?.cardExpirationMonth - 1
                              )?.toString()
                            : "0"
                        }
                        options={getMonths()}
                        noneOption={false}
                        label="Month"
                      />
                      {errors.monthExpiration && touched.monthExpiration && (
                        <Typography
                          data-qatag="error"
                          variant="body1"
                          className={classes.error}
                        >
                          {errors.monthExpiration}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      data-qatag="responsiveElement"
                      className={classes.responsiveElement}
                      ml={1}
                    >
                      <Select
                        data-qatag="yearExpiration"
                        name="yearExpiration"
                        onChange={(year) => {
                          setFieldValue("yearExpiration", year);
                        }}
                        defaultValue={
                          selectedPaymentMethod?.creditCard?.cardExpirationYear?.toString() ||
                          new Date().getFullYear()?.toString()
                        }
                        options={getYears()}
                        noneOption={false}
                        label="Year"
                      />
                      {errors.yearExpiration && touched.yearExpiration && (
                        <Typography
                          data-qatag="error"
                          variant="body1"
                          className={classes.error}
                        >
                          {errors.yearExpiration}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box data-qatag="responsiveBox" className={styles.responsiveBox}>
                <Box
                  data-qatag="cvvContainer"
                  mt={2}
                  className={styles.cvvContainer}
                >
                  <Box
                    data-qatag="responsiveElement"
                    className={classes.cvvField}
                  >
                    <Field
                      data-qatag="field"
                      component={SimpleTextField}
                      name="securityCode"
                      type="text"
                      label="CVV"
                      disabled
                      className={styles.field}
                    />
                  </Box>

                  <img
                    data-qatag="cvvIcon"
                    className={`${styles.cvvIcon} ${classes.cvvImageContainer}`}
                    src={cvvIcon}
                    alt="Security Code"
                  />
                </Box>
              </Box>
            </Box>
            {/*Zip Code*/}
            <Box
              data-qatag="zipCodeContainer"
              mt={4}
              className={`${styles.responsiveField} ${styles.zipCodeField}`}
            >
              <Field
                data-qatag="field"
                component={SimpleTextField}
                name="zipCode"
                type="text"
                label="Zip Code"
                className={styles.field}
              />
            </Box>
            {/* Country */}
            <Box
              data-qatag="countrySelectContainer"
              mt={2}
              className={`${styles.responsiveField} ${styles.cardInfoField}`}
            >
              <Select
                data-qatag="countrySelect"
                name="countrySelect"
                onChange={(country) => {
                  setFieldValue("country", country);
                }}
                value={countryCode !== undefined ? countryCode : "US"}
                options={getCountries.map((country) => ({
                  value: country?.code,
                  label: country?.name,
                }))}
                noneOption={false}
              />
              {errors.country && touched.country && (
                <Typography
                  data-qatag="error"
                  variant="body1"
                  className={classes.error}
                >
                  {errors.country}
                </Typography>
              )}
            </Box>

            {isSubmitting && <LinearProgress data-qatag="linearProgress" />}
          </div>
          {/* Button */}
          <Box
            data-qatag="button"
            width={1}
            textAlign="center"
            mb={4}
            className={styles.buttonContainer}
          >
            <AppButton
              data-qatag="submitButton"
              buttonStyle={AppButtonStyles.Green}
              className={`${styles.customButton}`}
              disabled={isSubmitting}
              onClick={submitForm}
            >
              <Typography
                data-qatag="buttonText"
                align="center"
                className={classes.buttonText}
                variant="body2"
              >
                Save Payment Method
              </Typography>
            </AppButton>
            {/* Loading modal */}
            <Spinner
              data-qatag="loadingModal"
              isActive={
                ReducerStatus[fetchStatus] === ReducerStatus.Loading ||
                ReducerStatus[updateStatus] === ReducerStatus.Loading
              }
              text="Loading..."
            />
            {/* Success Alert */}
            <Alert
              data-qatag="alertWarning"
              icon={AlertIcon.Success}
              title="Complete"
              text="Payment method was modified"
              buttonText="Close"
              approveHandler={() => {
                dispatch(resetUpdate());
                history.push("/billing");
              }}
              isOpen={
                ReducerStatus[updateStatus] === ReducerStatus.Succeeded &&
                selectedPaymentMethod !== undefined
              }
              closeHandler={() => {
                dispatch(resetUpdate());
                history.push("/billing");
              }}
            />
            {/* Alert error */}
            <Alert
              data-qatag="alertError"
              icon={AlertIcon.Warning}
              title="Error"
              text={
                selectedPaymentMethod === undefined
                  ? "Invalid Id"
                  : "Something went wrong"
              }
              buttonText={
                selectedPaymentMethod === undefined ? "Redirect" : "Continue"
              }
              approveHandler={() => {
                dispatch(resetUpdate());
                if (selectedPaymentMethod === undefined) {
                  history.push("/billing");
                }
              }}
              isOpen={
                ReducerStatus[updateStatus] === ReducerStatus.Failed ||
                (ReducerStatus[fetchStatus] === ReducerStatus.Succeeded &&
                  selectedPaymentMethod === undefined)
              }
              closeHandler={() => {
                dispatch(resetUpdate());
                if (selectedPaymentMethod === undefined) {
                  history.push("/billing");
                }
              }}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CardForm;
