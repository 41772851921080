import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { EmptyState } from "features/Optimization";
import { IThunkStatus, ReducerStatus } from "model/IReducerState";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Panel, Spinner } from "solveiq.designsystem";
import styles from "./TicketsScreen.module.css";
import {
  fetchTickets,
  ITicket,
  selectTicketById,
  selectTicketsStatus,
} from "./TicketsSlice";
import ticketIcon from "assets/img/tickets/ticket.png";
import userIcon from "assets/img/tickets/user.png";
import { getDateAndTime, getStatus } from "./TicketsScreen";
import { RootState } from "app/store";
import { FormattedMessage } from "react-intl";
import { Motion, spring } from "react-motion";
import chevron from "assets/img/buttonCollapsed.png";
import { makeStyles, Theme, Typography } from "@material-ui/core";

export interface ITicketDetailsProps {
  ticketSelector?: (id: string) => (state: RootState) => ITicket | null;
  ticketsStatusSelector?: (state: RootState) => IThunkStatus;
}

const useStyles = makeStyles((theme: Theme) => ({
  viewAllContainer: {
    gridColumn: 3,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignSelf: "flex-end",
  },
  expander: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "75px",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "5px",
    },
  },
  emailTable: {
    display: "table",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tableHeader: {
    fontWeight: 600,
  },
  mobileMessagesContainer: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  mobileMessage: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
  mobileMessageSubject: {
    backgroundColor: "#F5F5F5",
    color: "#007DBA",
  },
  mobileMessageField: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    borderBottom: "1px solid #F5F5F5",
    padding: "10px",
  },
  mobileMessageLabel: {
    width: "120px",
    marginRight: "20px",
  },
  mobileMessageBody: {
    overflowWrap: "anywhere",
  },
}));

export const TicketDetails: React.FC<ITicketDetailsProps> = ({
  ticketSelector = selectTicketById,
  ticketsStatusSelector = selectTicketsStatus,
}) => {
  const { ticketId = "" } = useParams<{ ticketId: string }>();
  const ticket = useSelector(ticketSelector(ticketId));
  const [loading, setLoading] = useState(false);
  const ticketsStatus = useSelector(ticketsStatusSelector);
  const dispatch = useDispatch();
  const prefix = "ticketdetails.screen";
  const classes = useStyles();

  useEffect(() => {
    if (ticketsStatus[fetchTickets.typePrefix] === ReducerStatus.Loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [ticketsStatus]);

  useEffect(() => {
    if (ticket === null) {
      dispatch(fetchTickets());
    }
  }, [dispatch, ticket]);

  const hasMessages =
    ticket != null &&
    ticket.emailMessages != null &&
    ticket.emailMessages.totalSize > 0;

  const [showMessages, setShowMessages] = useState(false);

  return (
    <div
      data-qatag="ticket_details_container"
      className={styles.detailsContainer}
    >
      <Spinner data-qatag="loadingModal" isActive={loading} text="Loading..." />
      <Breadcrumbs
        data-qatag="ticketDetailsBreadcrumbs"
        customPath={`tickets/Ticket # ${ticket ? ticket.caseNumber : "..."}`}
        pathsMap={{ tickets: "Tickets" }}
      />
      {ticket ? (
        <Panel data-qatag="ticketDetails" className={styles.ticketDetails}>
          <header
            data-qatag="ticketDetails_header"
            className={styles.ticketDetailsHeader}
          >
            <img
              data-qatag="ticketDetails_header_icon"
              src={ticketIcon}
              alt=""
            />
            <h1
              data-qatag="ticketDetailsTitle"
              className={styles.ticketDetailsTitle}
            >
              <FormattedMessage
                data-qatag=""
                id={`${prefix}.ticketNumber`}
                defaultMessage="Ticket #{ticketNumber}"
                values={{ ticketNumber: ticket.caseNumber }}
              />
            </h1>
          </header>
          <div
            data-qatag="ticketDetailsRow"
            className={styles.ticketDetailsRow}
          >
            <span data-qatag="labelCreatedBy" className={styles.label}>
              <FormattedMessage
                data-qatag=""
                id={`${prefix}.createdBy`}
                defaultMessage="Created By"
              />
            </span>
            <span
              data-qatag="createdByValue"
              className={`${styles.createdByValue} ${styles.value}`}
            >
              <img data-qatag="userIcon" src={userIcon} alt="User" />
              <span data-qatag="username" className={styles.username}>
                {ticket.suppliedName}
              </span>
              , {getDateAndTime(ticket.createdDate)}
            </span>
          </div>
          <div
            data-qatag="ticketDetailsRow"
            className={styles.ticketDetailsRow}
          >
            <span data-qatag="labelCaseNumber" className={styles.label}>
              <FormattedMessage
                data-qatag=""
                id={`${prefix}.caseNumber`}
                defaultMessage="Case Number"
              />
            </span>
            <span data-qatag="caseNumber" className={styles.value}>
              {ticket.caseNumber}
            </span>
          </div>
          <div
            data-qatag="ticketDetailsRow"
            className={styles.ticketDetailsRow}
          >
            <span data-qatag="labelStatus" className={styles.label}>
              <FormattedMessage
                data-qatag=""
                id={`${prefix}.status`}
                defaultMessage="Status"
              />
            </span>
            <span data-qatag="status">{getStatus(ticket.status)}</span>
          </div>
          <div
            data-qatag="ticketDetailsRow"
            className={styles.ticketDetailsRow}
          >
            <span data-qatag="labelPriority" className={styles.label}>
              <FormattedMessage
                data-qatag=""
                id={`${prefix}.priority`}
                defaultMessage="Priority"
              />
            </span>
            <span data-qatag="priority">{ticket.priority}</span>
          </div>
          <div
            data-qatag="ticketDetailsRow"
            className={styles.ticketDetailsRow}
          >
            <span data-qatag="labelSubject" className={styles.label}>
              <FormattedMessage
                data-qatag=""
                id={`${prefix}.subject`}
                defaultMessage="Subject"
              />
            </span>
            <span data-qatag="subject">{ticket.subject}</span>
          </div>
          <div
            data-qatag="ticketDetailsRow"
            className={styles.ticketDetailsRow}
          >
            <span data-qatag="labelDescription" className={styles.label}>
              <FormattedMessage
                data-qatag=""
                id={`${prefix}.description`}
                defaultMessage="Description"
              />
            </span>
            <span data-qatag="description">{ticket.description}</span>
          </div>
          <div
            data-qatag="ticketDetailsRow"
            className={styles.ticketDetailsRow}
          >
            <span data-qatag="labelDateTimeOpened" className={styles.label}>
              <FormattedMessage
                data-qatag=""
                id={`${prefix}.dateTimeOpened`}
                defaultMessage="Date / Time Opened"
              />
            </span>
            <span data-qatag="dateTimeOpened">
              {getDateAndTime(ticket.createdDate)}
            </span>
          </div>
          {ticket.isClosed && ticket.closedDate != null && (
            <div
              data-qatag="ticketDetailsRow"
              className={styles.ticketDetailsRow}
            >
              <span data-qatag="labelDateTimeClosed" className={styles.label}>
                <FormattedMessage
                  data-qatag=""
                  id={`${prefix}.dateTimeClosed`}
                  defaultMessage="Date / Time Closed"
                />
              </span>
              <span data-qatag="dateTimeClosed">
                {getDateAndTime(ticket.closedDate)}
              </span>
            </div>
          )}

          <div
            data-qatag="ticketDetailsRow"
            className={`${styles.ticketDetailsRow} ${styles.messagesRow}`}
          >
            <span data-qatag="labelDateTime" className={styles.label}>
              <FormattedMessage
                data-qatag=""
                id={`${prefix}.emailCorrespondence`}
                defaultMessage="Email Correspondence ({amount})"
                values={{ amount: ticket.emailMessages?.totalSize ?? 0 }}
              />
            </span>
            {hasMessages && (
              <div
                data-qatag="viewAllContainer"
                className={classes.viewAllContainer}
              >
                <span data-qatag="label" className={styles.label}>
                  <FormattedMessage
                    data-qatag=""
                    id={`${prefix}.viewAll`}
                    defaultMessage="View All"
                  />
                </span>
                <div
                  data-qatag=""
                  onClick={() => setShowMessages(!showMessages)}
                  className={classes.expander}
                >
                  <Motion
                    data-qatag=""
                    style={{ rotationAngle: spring(showMessages ? 180 : 0) }}
                  >
                    {({ rotationAngle }) => (
                      <img
                        data-qatag=""
                        style={{ transform: `rotate( ${rotationAngle}deg )` }}
                        src={chevron}
                        alt=""
                      />
                    )}
                  </Motion>
                </div>
              </div>
            )}
          </div>
          {hasMessages && showMessages && (
            <div data-qatag="messagesContainer">
              <table
                data-qatag="emailTable"
                className={`${styles.emailTable} ${classes.emailTable}`}
              >
                <thead data-qatag="">
                  <tr data-qatag="">
                    <th data-qatag="">
                      <Typography
                        data-qatag=""
                        variant="body2"
                        className={classes.tableHeader}
                      >
                        <FormattedMessage
                          data-qatag=""
                          id={`${prefix}.tableHeader.subject`}
                          defaultMessage="Subject"
                        />
                      </Typography>
                    </th>
                    <th data-qatag="">
                      <Typography
                        data-qatag=""
                        variant="body2"
                        className={classes.tableHeader}
                      >
                        <FormattedMessage
                          data-qatag=""
                          id={`${prefix}.tableHeader.body`}
                          defaultMessage="Message Body"
                        />
                      </Typography>
                    </th>
                    <th data-qatag="">
                      <Typography
                        data-qatag=""
                        variant="body2"
                        className={classes.tableHeader}
                      >
                        <FormattedMessage
                          data-qatag=""
                          id={`${prefix}.tableHeader.date`}
                          defaultMessage="Message Date"
                        />
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody data-qatag="">
                  {ticket.emailMessages?.records.map((record, index) => {
                    return (
                      <tr data-qatag="" key={`ticketMessageRow${index}`}>
                        <td data-qatag="">
                          <Typography data-qatag="" variant="body2">
                            {`${index + 1}. ${record.subject}`}
                          </Typography>
                        </td>
                        <td data-qatag="">
                          <Typography data-qatag="" variant="body2">
                            {record.textBody}
                          </Typography>
                        </td>
                        <td data-qatag="">
                          <Typography data-qatag="" variant="body2">
                            {getDateAndTime(record.messageDate)}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div
                data-qatag="mobileMessagesContainer"
                className={classes.mobileMessagesContainer}
              >
                {ticket.emailMessages?.records.map((record, index) => {
                  return (
                    <div
                      data-qatag="mobileMessage"
                      key={`mobileTicketMessage${index}`}
                      className={classes.mobileMessage}
                    >
                      <Typography
                        data-qatag="mobileMessageSubject"
                        variant="h4"
                        className={classes.mobileMessageSubject}
                      >
                        {`${index + 1}. ${record.subject}`}
                      </Typography>
                      <div
                        data-qatag="mobileMessageField"
                        className={classes.mobileMessageField}
                      >
                        <Typography
                          data-qatag=""
                          variant="h4"
                          className={classes.mobileMessageLabel}
                        >
                          <FormattedMessage
                            data-qatag=""
                            id={`${prefix}.tableHeader.body`}
                            defaultMessage="Message Body"
                          />
                        </Typography>
                        <Typography
                          data-qatag=""
                          variant="body2"
                          className={classes.mobileMessageBody}
                        >
                          {record.textBody}
                        </Typography>
                      </div>
                      <div
                        data-qatag="mobileMessageField"
                        className={classes.mobileMessageField}
                      >
                        <Typography
                          data-qatag=""
                          variant="h4"
                          className={classes.mobileMessageLabel}
                        >
                          <FormattedMessage
                            data-qatag=""
                            id={`${prefix}.tableHeader.date`}
                            defaultMessage="Message Date"
                          />
                        </Typography>
                        <Typography data-qatag="" variant="body2">
                          {getDateAndTime(record.messageDate)}
                        </Typography>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Panel>
      ) : (
        <EmptyState
          data-qatag="noTicketDetailsEmptyState"
          error="Not found"
          suggestion={`Ticket with ID #${ticketId} does not exist.`}
        />
      )}
    </div>
  );
};

export default TicketDetails;
