import { Field, Form, Formik } from "formik";
import { object, string, number } from "yup";
import { Typography, Box } from "@material-ui/core";
import {
  AppButton,
  AppButtonStyles,
  Select,
  SelectOptionInterface,
  getCountries,
  Spinner,
  Alert,
  AlertIcon,
} from "solveiq.designsystem";
import { makeStyles, Theme } from "@material-ui/core/styles";
import cvvIcon from "assets/icon/cvv-security.svg";
import styles from "./CardForm.module.css";
import { SimpleTextField } from "solveiq.designsystem";
import { ReducerStatus } from "model/IReducerState";
import { RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { IPaymentMethodToAdd } from "./types";
import { useEffect } from "react";
import { IPaymentMethod } from "../PaymentMethods/types";
import {
  fetchPaymentMethods,
  updatePaymentMethod,
  getPaymentMethods,
  getRequestStatus,
  getUpdateStatus,
  resetUpdate,
} from "../PaymentMethods/PaymentMethodsSlice";

export interface ICardForm {
  /* Callback to update the isOpen on the parent*/
  handleOnClose: () => void;
  /* Payment method ID to retrieve  */
  paymentMethodId: string;
  /* Payment Methods */
  paymentMethodsSelector?: (state: RootState) => IPaymentMethod[];
  /* Payment Methods Request Status */
  fetchStatusSelector?: (state: RootState) => ReducerStatus;
  /* Status to update default payment method */
  updateStatusSelector?: (state: RootState) => ReducerStatus;
}

/* Custom style for Dialog */
const useStyles = makeStyles((theme: Theme) => ({
  buttonText: {
    lineHeight: 1,
  },
  important: {
    color: "#BE2222",
  },
  error: {
    color: "#BE2222",
    marginLeft: 8,
  },
  responsiveElement: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "50%",
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "45%",
    },
  },
  cvvImageContainer: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "50%",
      textAlign: "start",
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "30%",
      textAlign: "end",
    },
  },
  cvvContainer: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "50%",
      paddingLeft: 0,
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "70%",
      paddingLeft: 24,
    },
  },
  responsiveBox: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "100%",
      marginTop: 16,
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "50%",
    },
  },
  responsiveContainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      marginTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
      marginTop: 8,
    },
  },
  button: {
    [theme.breakpoints.down("md")]: {
      marginTop: 24,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 28,
    },
  },
}));

function getMonths(startMonth = 0): SelectOptionInterface[] {
  const options = [];
  for (let i = startMonth; i < 12; i++) {
    options.push({
      value: i.toString(),
      label: (i + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
    });
  }

  return options;
}

function getYears(): SelectOptionInterface[] {
  const options = [];
  const currentYear = new Date().getFullYear();
  for (let i = 0; i < 20; i++) {
    options.push({
      value: (currentYear + i).toString(),
      label: (currentYear + i).toString(),
    });
  }
  return options;
}

const validationSchema = object({
  name: string().required("Name is required"),
  cardNumber: string(),
  monthExpiration: number().required("Required"),
  yearExpiration: number().required("Required"),
  zipCode: string()
    .required("Zip Code is required")
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Please enter a valid zip code")
    .max(9, "9 digits"),
});

const CardForm: React.FC<ICardForm> = ({
  paymentMethodId,
  handleOnClose,
  paymentMethodsSelector = (state: RootState) => getPaymentMethods(state),
  fetchStatusSelector = (state: RootState) => getRequestStatus(state),
  updateStatusSelector = (state: RootState) => getUpdateStatus(state),
}) => {
  // Custom styles
  const classes = useStyles();

  const dispatch = useDispatch();

  // Function to validate form data
  const validate = (values: IPaymentMethodToAdd) => {
    const errors: { monthExpiration?: string } = {};

    // Validate Month and year
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    if (values.monthExpiration && values.yearExpiration) {
      if (
        Number(values.yearExpiration) === currentYear &&
        Number(values.monthExpiration) < currentMonth
      ) {
        errors.monthExpiration = "Invalid Month";
      }
    }

    return errors;
  };

  /* Payment Methods Request Status */
  const fetchStatus = useSelector(fetchStatusSelector);
  /* Payment methods */
  const paymentMethods = useSelector(paymentMethodsSelector);
  /* Update Default payment method status */
  const updateStatus = useSelector(updateStatusSelector);

  useEffect(() => {
    dispatch(fetchPaymentMethods());
  }, [dispatch]);

  const selectedPaymentMethod = paymentMethods?.filter(
    (paymentMethod) => paymentMethod.zID === paymentMethodId
  )?.[0];

  /* method to update payment methods */
  const handleSubmit = async (values: {
    name: string;
    cardNumber: string;
    monthExpiration: number;
    yearExpiration: number;
    securityCode: string;
    country: string;
    email: string;
    phoneNumber: string;
    companyName: string;
    zipCode: string;
  }) => {
    dispatch(
      updatePaymentMethod({
        zPaymentMethodID: paymentMethodId,
        creditCardHolderName: values?.name,
        creditCardExpirationYear: values?.yearExpiration,
        creditCardCountry: values?.country,
        creditCardPostalCode: values?.zipCode,
        creditCardExpirationMonth:
          parseInt(values?.monthExpiration?.toString()) + 1,
      })
    );
  };

  return (
    <Formik
      data-qatag="Formik"
      enableReinitialize={true}
      initialValues={{
        name: selectedPaymentMethod?.paymentInfo?.name,
        cardNumber: `**** **** **** ${selectedPaymentMethod?.creditCard?.cardNumber
          ?.replaceAll("*", "")
          ?.trim()}`,
        monthExpiration:
          Number(selectedPaymentMethod?.creditCard?.cardExpirationMonth || 1) -
          1,
        yearExpiration: selectedPaymentMethod?.creditCard?.cardExpirationYear,
        securityCode: Array(
          selectedPaymentMethod?.creditCard?.cardCVC?.toString()?.length > 1
            ? selectedPaymentMethod?.creditCard?.cardCVC?.toString()?.length
            : 3
        )
          ?.fill("*")
          ?.join(""),
        zipCode: selectedPaymentMethod?.paymentInfo?.zipCode,
        country: selectedPaymentMethod?.paymentInfo?.country,
        email: selectedPaymentMethod?.paymentInfo?.email || "",
        phoneNumber: selectedPaymentMethod?.paymentInfo?.phoneNumber || "",
        companyName: selectedPaymentMethod?.paymentInfo?.businessName || "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ isSubmitting, errors, setFieldValue, touched, values }) => (
        <Form data-qatag="form" className={styles.form}>
          <div data-qatag="fieldContainer" className={styles.fieldContainer}>
            <Box data-qatag="nameContainer" width="100%" mt={4}>
              <Field
                data-qatag="field"
                component={SimpleTextField}
                name="name"
                type="text"
                label="Name as it appears on card"
                className={styles.field}
              />
            </Box>
            <Box data-qatag="cardNumberBox" width="100%" mt={3}>
              <Field
                data-qatag="field"
                component={SimpleTextField}
                name="cardNumber"
                type="text"
                label="Card Number"
                className={styles.field}
                disabled
              />
            </Box>

            <Box
              data-qatag="responsiveContainer"
              className={classes.responsiveContainer}
            >
              <Box data-qatag="responsiveBox" className={styles.dateContainer}>
                <Box data-qatag="dateContainer" display="flex">
                  <Box
                    data-qatag="responsiveElement"
                    className={classes.responsiveElement}
                  >
                    <Select
                      data-qatag="monthExpiration"
                      name="monthExpiration"
                      onChange={(month) => {
                        setFieldValue("monthExpiration", month);
                      }}
                      defaultValue={new Date().getMonth().toString()}
                      value={values?.monthExpiration?.toString()}
                      options={getMonths()}
                      noneOption={false}
                      label="Month"
                    />
                    {errors.monthExpiration && (
                      <Typography
                        data-qatag="error"
                        variant="body1"
                        className={classes.error}
                      >
                        {errors.monthExpiration}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    data-qatag="responsiveElement"
                    className={classes.responsiveElement}
                    ml={1}
                  >
                    <Select
                      data-qatag="yearExpiration"
                      name="yearExpiration"
                      onChange={(year) => {
                        setFieldValue("yearExpiration", year);
                      }}
                      defaultValue={new Date().getFullYear().toString()}
                      value={values?.yearExpiration?.toString()}
                      options={getYears()}
                      noneOption={false}
                      label="Year"
                    />
                    {errors.yearExpiration && touched.yearExpiration && (
                      <Typography
                        data-qatag="error"
                        variant="body1"
                        className={classes.error}
                      >
                        {errors.yearExpiration}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                data-qatag="responsiveBox"
                mt={2}
                className={classes.responsiveBox}
              >
                <Box data-qatag="securityCodeBox" display="flex">
                  <Box
                    data-qatag="cvvContainer"
                    className={classes.cvvContainer}
                  >
                    <Field
                      data-qatag="field"
                      component={SimpleTextField}
                      name="securityCode"
                      type="text"
                      label="CVV"
                      disabled
                      className={styles.field}
                    />
                  </Box>
                  <Box
                    data-qatag="cvvImageContainer"
                    className={classes.cvvImageContainer}
                    ml={2}
                    textAlign="end"
                  >
                    <img
                      data-qatag="cvvIcon"
                      className={styles.cvvIcon}
                      src={cvvIcon}
                      alt="Security Code"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box data-qatag="zipCodeBox" width="100%" mt={3}>
              <Field
                data-qatag="field"
                component={SimpleTextField}
                name="zipCode"
                type="text"
                label="Zip Code"
                className={styles.field}
              />
            </Box>
            {/* Country */}
            <Box
              data-qatag="countrySelectContainer"
              mt={1}
              className={`${styles.responsiveField} ${styles.cardInfoField}`}
            >
              <Select
                data-qatag="countrySelect"
                name="countrySelect"
                onChange={(country) => {
                  setFieldValue("country", country);
                }}
                options={getCountries.map((country) => ({
                  value: country.code,
                  label: country.name,
                }))}
                defaultValue={"US"}
                noneOption={false}
              />
            </Box>
          </div>
          {/* Button */}
          <Box
            data-qatag="button"
            width={1}
            textAlign="center"
            className={classes.button}
          >
            <AppButton
              data-qatag="cardFormButton"
              buttonStyle={AppButtonStyles.Green}
              className={`${styles.customButton}`}
              disabled={isSubmitting}
              type="submit"
            >
              <Typography
                data-qatag="buttonText"
                align="center"
                className={classes.buttonText}
                variant="body2"
              >
                Save Payment Method
              </Typography>
            </AppButton>
          </Box>
          {/* Loading modal */}
          <Spinner
            data-qatag="loadingModal"
            text={
              ReducerStatus[fetchStatus] === ReducerStatus.Loading
                ? "Loading..."
                : "Saving..."
            }
            isActive={
              ReducerStatus[updateStatus] === ReducerStatus.Loading ||
              ReducerStatus[fetchStatus] === ReducerStatus.Loading
            }
          />
          {/* Alert error */}
          <Alert
            data-qatag="alertError"
            icon={AlertIcon.Warning}
            title="Error"
            text="Something went wrong"
            buttonText="Continue"
            approveHandler={() => {
              dispatch(resetUpdate());
            }}
            isOpen={ReducerStatus[updateStatus] === ReducerStatus.Failed}
            closeHandler={() => {
              dispatch(resetUpdate());
            }}
          />
          {/* Success Alert */}
          <Alert
            data-qatag="successAlert"
            icon={AlertIcon.Success}
            title="Payment method saved!"
            text="Your payment method has been successfully updated and can now be used for transaction."
            buttonText="Close"
            approveHandler={() => {
              dispatch(resetUpdate());
              dispatch(fetchPaymentMethods());
              handleOnClose();
            }}
            isOpen={ReducerStatus[updateStatus] === ReducerStatus.Succeeded}
            closeHandler={() => {
              dispatch(resetUpdate());
              dispatch(fetchPaymentMethods());
              handleOnClose();
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CardForm;
