import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";

export const fetchSubscription = createAsyncThunk<
  {
    data: string;
    error: string;
    succeeded: boolean;
  },
  void,
  {
    state: RootState;
  }
>("subscription/status", async (_, thunkApi) => {
  try {
    const url = `/api/Subscription/status`;

    const apiResponse = await RESTGatewayAPI.get(url);

    return apiResponse?.data;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to Subscription : ${error}`);
  }
});

export const resumeSubscription = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>("subscription/resume", async (_, thunkApi) => {
  try {
    const url = `/api/Subscription/resume`;

    const apiResponse = await RESTGatewayAPI.put(url);

    return apiResponse?.data;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to resume Subscription : ${error}`);
  }
});

export const suspenseSubscription = createAsyncThunk<
  { data: string; succeeded: boolean; error: string },
  void,
  {
    state: RootState;
  }
>("seats/suspendSubscription", async (_, thunkApi) => {
  try {
    const retrieveStatus = `/api/Subscription/status`;

    const apiResponseStatus = await RESTGatewayAPI.get(retrieveStatus);
    // Condition if the account is already suspend
    if (apiResponseStatus?.data?.data === "Suspended") {
      return {
        data: "Suspended",
        error: "you've already suspended the subscription",
        succeeded: false,
      };
    }

    // Suspend Subscription
    const url = "/api/Subscription/suspend";

    const options = {
      headers: {
        "Content-Type": "text/json",
      },
    };
    const apiResponse = await RESTGatewayAPI.put(url, {}, options);
    return { data: apiResponse.data, error: "", succeeded: true };
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to suspend subscription : ${error}`
    );
  }
});

export const updateSubscription = createAsyncThunk<
  { succeeded: boolean; error: string },
  number,
  {
    state: RootState;
  }
>("seats/updateSubscription", async (seatsLength, thunkApi) => {
  try {
    if (seatsLength <= 0) {
      return {
        error: "Invalid number",
        succeeded: false,
      };
    }

    // URL to update seats length
    const url = "/api/Subscription/update";

    const options = {
      headers: {
        "Content-Type": "text/json",
      },
    };
    const apiResponse = await RESTGatewayAPI.put(url, seatsLength, options);
    return { data: apiResponse.data, error: "", succeeded: true };
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to update seats length : ${error}`);
  }
});

const initialState: IReducerState<{
  data: string;
  error: string;
  succeeded: boolean;
}> = {
  data: {
    data: "Active",
    error: "",
    succeeded: true,
  },
  status: {
    [fetchSubscription.typePrefix]: ReducerStatus.Idle,
    [updateSubscription.typePrefix]: ReducerStatus.Idle,
    [suspenseSubscription.typePrefix]: ReducerStatus.Idle,
    [resumeSubscription.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const myPlanSlice = createSlice({
  name: "receipt",
  initialState,
  reducers: {
    set: (
      state,
      action: PayloadAction<{
        data: string;
        error: string;
        succeeded: boolean;
      }>
    ) => {
      state.data = action.payload;
    },
    setFetchSubscription: (state) => {
      state.status[fetchSubscription.typePrefix] = ReducerStatus.Idle;
    },
    setUpdateSubscription: (state) => {
      state.status[updateSubscription.typePrefix] = ReducerStatus.Idle;
    },
    // reset suspend subscription state
    resetSuspend: (state) => {
      state.status[suspenseSubscription.typePrefix] = ReducerStatus.Idle;
      state.error = undefined;
    },
    // reset resume request status
    resetResume: (state) => {
      state.status[resumeSubscription.typePrefix] = ReducerStatus.Idle;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    //Loading to retrieve status of the subscription
    builder.addCase(fetchSubscription.pending, (state) => {
      state.status[fetchSubscription.typePrefix] = ReducerStatus.Loading;
    });
    //what to do if the status is retrieved
    builder.addCase(fetchSubscription.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status[fetchSubscription.typePrefix] = ReducerStatus.Succeeded;
    });
    //Loading for the update seats length
    builder.addCase(updateSubscription.pending, (state) => {
      state.status[updateSubscription.typePrefix] = ReducerStatus.Loading;
    });
    //Callback for when the seats are updated
    builder.addCase(updateSubscription.fulfilled, (state, action) => {
      state.data.succeeded = action.payload.succeeded;
      state.error = action.payload.error;
      if (action.payload.error) {
        state.status[updateSubscription.typePrefix] = ReducerStatus.Failed;
      } else {
        state.status[updateSubscription.typePrefix] = ReducerStatus.Succeeded;
      }
    });
    //If we got an error updating the seats
    builder.addCase(updateSubscription.rejected, (state) => {
      state.status[updateSubscription.typePrefix] = ReducerStatus.Failed;
    });
    //Loading to retrieve status of the subscription
    builder.addCase(suspenseSubscription.pending, (state) => {
      state.status[suspenseSubscription.typePrefix] = ReducerStatus.Loading;
    });
    //what to do if the status is retrieved
    builder.addCase(suspenseSubscription.fulfilled, (state, action) => {
      state.error = action.payload.error;
      state.data.data = action.payload.data;
      state.data.succeeded = action.payload.succeeded;
      state.status[suspenseSubscription.typePrefix] = ReducerStatus.Succeeded;
    });
    //If we got an error updating the seats
    builder.addCase(resumeSubscription.rejected, (state) => {
      state.status[resumeSubscription.typePrefix] = ReducerStatus.Failed;
    });
    //Loading to retrieve status of the subscription
    builder.addCase(resumeSubscription.pending, (state) => {
      state.status[resumeSubscription.typePrefix] = ReducerStatus.Loading;
    });
    //what to do if the status is retrieved
    builder.addCase(resumeSubscription.fulfilled, (state) => {
      state.status[resumeSubscription.typePrefix] = ReducerStatus.Succeeded;
    });
  },
});

export const {
  set,
  setUpdateSubscription,
  setFetchSubscription,
  resetSuspend,
  resetResume,
} = myPlanSlice.actions;

export const selectSubscription = (state: RootState) =>
  state?.subscription?.data?.data;
export const selectFetchSubscriptionStatus = (state: RootState) =>
  state.subscription.status[fetchSubscription.typePrefix];

// State to update the seats
export const selectUpdateSubscriptionStatus = (state: RootState) =>
  state.subscription.status[updateSubscription.typePrefix];

// Status suspense subscription
export const selectSuspenseSubscriptionStatus = (state: RootState) =>
  state.subscription.status[suspenseSubscription.typePrefix];

// Status resume subscription
export const selectResumeSubscriptionStatus = (state: RootState) =>
  state.subscription.status[resumeSubscription.typePrefix];

// Retrieve error
export const selectError = (state: RootState) => state.subscription.error;

export default myPlanSlice.reducer;
