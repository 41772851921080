import { Box } from "@material-ui/core";
import { useIntl } from "react-intl";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Spinner, TabPanel } from "solveiq.designsystem";
import { AOProfileFlag, TriggerInfo } from "../types";
import { SoftwareBox, EmptyState } from "..";
import { ReducerStatus } from "model/IReducerState";

export interface ITabOptimization {
  /* Active tab number */
  tabNumber: number;
  /* Corresponding tab index */
  tabIndex: number;
  /* Software Optimization to display */
  software: TriggerInfo[];
  /* Status of the request */
  gamingStatus: ReducerStatus;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      order: 2,
      flex: "100%",
    },
    softwareBox: {
      [theme.breakpoints.down("md")]: {
        flexBasis: "100%",
        alignItems: "center",
      },
      [theme.breakpoints.up("md")]: {
        flexBasis: "50%",
      },
      [theme.breakpoints.up("lg")]: {
        flexBasis: "33%",
      },
    },
  })
);

const TabOptimization: React.FC<ITabOptimization> = ({
  tabNumber,
  software,
  gamingStatus,
  tabIndex,
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "body.app.optimization";
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  return (
    <TabPanel
      data-qatag="tabPanel"
      value={tabNumber}
      index={tabIndex}
      className={classes.tabPanel}
    >
      <Box
        data-qatag="tabContainer"
        display="flex"
        mb={6}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        {software.length ? (
          software?.map((info: TriggerInfo, index: number) => (
            <Box
              data-qatag="softwareBox"
              className={classes.softwareBox}
              textAlign="center"
              key={index}
            >
              <SoftwareBox
                data-qatag="softwareBox"
                title={info.JitTrigger.ManagedSoftware.Description}
                image={
                  info.JitTrigger.ManagedSoftware.ResourceFiles?.[0]
                    ?.DownloadURI
                }
                metrics={[
                  {
                    title: formatMessage({
                      id: `${prefix}.install.location`,
                    }),
                    metric: info.InstalledExes?.[0]?.FilePathName,
                  },
                  {
                    title: formatMessage({
                      id: `${prefix}.version`,
                    }),
                    metric: info.InstalledExes?.[0]?.FileInfo.FileVersion,
                  },
                  {
                    title: formatMessage({
                      id: `${prefix}.publisher`,
                    }),
                    metric: info.InstalledExes?.[0]?.FileInfo.CompanyName,
                  },
                  // Optional metric if the profile flag is different a notSet or undefined
                  ...(AOProfileFlag[info.JitTrigger.ProfileFlag] &&
                  AOProfileFlag[0] !==
                    AOProfileFlag[info.JitTrigger.ProfileFlag]
                    ? [
                        {
                          title: formatMessage({
                            id: `${prefix}.optimized.for`,
                          }),
                          metric: AOProfileFlag[info.JitTrigger.ProfileFlag],
                        },
                      ]
                    : []),
                ]}
              />
            </Box>
          ))
        ) : ReducerStatus[gamingStatus] !== ReducerStatus.Idle ? (
          <EmptyState
            data-qatag="emptyState"
            error={formatMessage({
              id: `${prefix}.error.title`,
            })}
            suggestion={`There is currently no ${
              tabIndex === 0 ? "Productivity Software" : ""
            }${tabIndex === 1 ? "Gaming Software" : ""}${
              tabIndex === 2 ? "Browsing Software" : ""
            } data at this current time. Check back periodically for updates.`}
          />
        ) : (
          ""
        )}
      </Box>
      {/* Loading modal */}
      <Spinner
        data-qatag="loadingModal"
        isActive={ReducerStatus[gamingStatus] === ReducerStatus.Idle}
        text="Loading..."
      />
    </TabPanel>
  );
};

export default TabOptimization;
