import { Typography, Box, Modal, IconButton } from "@material-ui/core";
import cancelIcon from "assets/img/icn-payment-failed.svg";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./PaymentOnFile.module.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppButton,
  AppButtonStyles,
  useMatchTabletVertical,
  Alert,
  AlertIcon,
  Spinner,
} from "solveiq.designsystem";
import {
  fetchPaymentMethods,
  updatePaymentMethod,
  getPaymentMethods,
  getRequestStatus,
  getUpdateStatus,
  resetUpdate,
} from "../PaymentMethods/PaymentMethodsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ReducerStatus } from "model/IReducerState";
import { IPaymentMethod } from "../PaymentMethods/types";
import { PaymentMethod } from "../types";
import { validateCardDate } from "utils/validateCardDate";
import { getIcon } from "utils/getCreditCardIcon";
import { EmptyState } from "features/Optimization";
import {
  selectResumeSubscriptionStatus,
  resetResume,
} from "features/teamManagement/SubscriptionSlice";

export interface IPaymentOnFile {
  /* Callback to accept the alert */
  approveHandler: () => void;
  /* Controls to open the alert */
  isOpen: boolean;
  /* Callback to close the alert*/
  closeHandler: () => void;
  /* If true displays a button to cancel the action */
  editHandler?: () => void;
}

const useStyles = makeStyles(() => ({
  paper: { maxWidth: "516px", width: "90%" },
}));

export const PaymentOnFile: React.FC<IPaymentOnFile> = ({
  closeHandler,
  approveHandler,
  isOpen,
  editHandler,
}) => {
  /* Responsive web design */
  const matchTablet = useMatchTabletVertical();
  // Custom styles
  const classes = useStyles();

  /* Payment Methods Request Status */
  const requestStatus = useSelector(getRequestStatus);

  const dispatch = useDispatch();

  useEffect(() => {
    if (ReducerStatus[requestStatus] !== ReducerStatus.Succeeded) {
      dispatch(fetchPaymentMethods());
    }
  }, [dispatch, requestStatus]);

  /* Payment methods */
  const paymentMethods = useSelector(getPaymentMethods);

  // State for selected payment method using the input radio button
  const [selectedMethodId, setSelectedMethodId] = useState("");

  // side effect to determine selected payment method
  const defaultPaymentMethod = paymentMethods?.filter(
    (paymentMethod) => paymentMethod?.isDefault
  );

  useEffect(() => {
    if (ReducerStatus[requestStatus] === ReducerStatus.Succeeded) {
      setSelectedMethodId(defaultPaymentMethod?.[0]?.zID);
    }
  }, [paymentMethods, requestStatus]); // should be with only these 2 dependencies

  const validPaymentMethods = paymentMethods?.filter(
    (method) =>
      !validateCardDate(
        method?.creditCard?.cardExpirationYear,
        method?.creditCard?.cardExpirationMonth
      )
  );

  /* Update Default payment method status */
  const updateStatus = useSelector(getUpdateStatus);

  /* Status of approveHandler or Resume subscription*/
  const resumeSubscriptionStatus = useSelector(selectResumeSubscriptionStatus);

  useEffect(() => {
    // If the request was successfully go now with the resume request
    if (ReducerStatus[updateStatus] === ReducerStatus.Succeeded && isOpen) {
      approveHandler();
      dispatch(resetUpdate());
      closeHandler();
    }
  }, [approveHandler, closeHandler, dispatch, isOpen, updateStatus]);

  return (
    <Modal
      data-qatag="Modal"
      open={isOpen}
      onClose={closeHandler}
      classes={{ paper: classes.paper }}
    >
      <div data-qatag="container" className={styles.container}>
        <div data-qatag="content" className={styles.content}>
          <div
            data-qatag="closeIconContainer"
            className={styles.closeIconContainer}
          >
            <IconButton
              data-qatag="closeIcon"
              aria-label="close"
              onClick={closeHandler}
              className={styles.closeIcon}
            >
              <CloseIcon data-qatag="closeIconSvg" />
            </IconButton>
          </div>
          <img
            data-qatag="icon"
            className={styles.icon}
            src={cancelIcon}
            alt="cancel"
          />
          <Box data-qatag="titleContainer" mt={matchTablet ? 3 : 2}>
            <Typography data-qatag="titleText" align="center" variant="h3">
              Payment Method On File
            </Typography>
          </Box>
          <Box data-qatag="textMessage" mt={2} textAlign="center" mb={3}>
            Would you like to use this payment method?
          </Box>
          {validPaymentMethods?.length > 0 ? (
            <Box
              data-qatag="paymentContainer"
              className={styles.paymentMethodsContainer}
            >
              {defaultPaymentMethod
                ?.slice()
                ?.sort((a, b) => {
                  // toLocale will work better but for compatibility
                  const idA = a?.zID?.toLowerCase();
                  const idB = b?.zID?.toLowerCase();
                  if (b.isDefault) return 1;
                  //sort string ascending
                  if (idA < idB) return -1;
                  //  if (idA > idB) return 1; If I want descending
                  return 0; //default return value (no sorting)
                })
                ?.map((info: IPaymentMethod, index: number) => {
                  const lastDigits = info.creditCard?.cardNumber
                    ?.replaceAll("*", "")
                    ?.trim();
                  return (
                    <Box
                      data-qatag="paymentContainer"
                      key={index}
                      className={`${styles.paymentContainer} ${
                        info.isDefault ? styles.defaultPayment : ""
                      }`}
                    >
                      <Box
                        data-qatag="paymentDetails"
                        display="flex"
                        alignItems="center"
                        className={styles.paymentDetails}
                      >
                        <input
                          data-qatag="lastDigits"
                          type="radio"
                          value={lastDigits}
                          name="paymentMethod"
                          checked={selectedMethodId === info?.zID}
                          defaultChecked={info.isDefault}
                          onChange={() => setSelectedMethodId(info?.zID)}
                          disabled={validateCardDate(
                            info?.creditCard?.cardExpirationYear,
                            info?.creditCard?.cardExpirationMonth
                          )}
                        />
                        <img
                          data-qatag="creditCardIcon"
                          className={styles.creditCardIcon}
                          src={getIcon(info?.creditCard?.creditCardType)}
                          alt="Visa"
                        />
                        <Box
                          data-qatag="cardTypeText"
                          className={styles.cardTypeText}
                        >
                          {info?.creditCard?.creditCardType !==
                          PaymentMethod[PaymentMethod.PayPal]
                            ? info?.creditCard?.creditCardType
                            : ""}
                        </Box>
                        <Box
                          data-qatag="lastDigitsText"
                          className={styles.lastDigitsText}
                        >
                          **** {lastDigits}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          ) : ReducerStatus[requestStatus] !== ReducerStatus.Idle ? (
            <EmptyState
              data-qatag="EmptyState"
              error="Something went wrong"
              suggestion="You don't have a payment method"
            />
          ) : (
            ""
          )}
          <Box data-qatag="buttonText" textAlign={"center"} mt={4}>
            <AppButton
              data-qatag="customButton"
              buttonStyle={AppButtonStyles.Green}
              className={styles.customButton}
              onClick={() => {
                // If the payment method is the same just continue updating the plan to summarize
                if (defaultPaymentMethod?.[0]?.zID === selectedMethodId) {
                  approveHandler();
                  dispatch(resetUpdate());
                  closeHandler();
                } else {
                  dispatch(
                    updatePaymentMethod({
                      zPaymentMethodID: selectedMethodId,
                      setAsDefault: true,
                    })
                  );
                }
              }}
            >
              <Typography
                data-qatag="buttonText"
                align="center"
                variant="body2"
              >
                Use This Payment
              </Typography>
            </AppButton>
            {editHandler ? (
              <Box data-qatag="cancelHandler" mt={1} textAlign={"center"}>
                <AppButton
                  data-qatag="customButton"
                  buttonStyle={AppButtonStyles.TransparentWhite}
                  className={styles.customButton}
                  onClick={editHandler}
                >
                  <Typography
                    data-qatag="cancelButtonText"
                    align="center"
                    variant="body2"
                    className={styles.cancelButtonText}
                  >
                    Edit My Payment Method
                  </Typography>
                </AppButton>
              </Box>
            ) : null}
          </Box>
        </div>
        {/* Alert error */}
        <Alert
          data-qatag="alertError"
          icon={AlertIcon.Warning}
          title="Error"
          text={"Something went wrong"}
          buttonText="Continue"
          approveHandler={() => {
            dispatch(resetUpdate());
            dispatch(resetResume());
          }}
          isOpen={
            ReducerStatus[requestStatus] === ReducerStatus.Failed ||
            ReducerStatus[updateStatus] === ReducerStatus.Failed ||
            ReducerStatus[resumeSubscriptionStatus] === ReducerStatus.Failed
          }
          closeHandler={() => {
            dispatch(resetUpdate());
            dispatch(resetResume());
          }}
        />
        {/* Loading modal */}
        <Spinner
          data-qatag="loadingModal"
          isActive={
            ReducerStatus[updateStatus] === ReducerStatus.Loading && isOpen
          }
          text="Changing Payment Method..."
        />
      </div>
    </Modal>
  );
};

export default PaymentOnFile;
