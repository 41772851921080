import { Box, Typography, IconButton, TextField } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import styles from "./BillingHistory.module.css";
import { TabPanel, SearchTextField, Spinner } from "solveiq.designsystem";
import { ReducerStatus } from "model/IReducerState";
import { EmptyState } from "features/Optimization";
import { RootState } from "app/store";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import paymentIcon from "assets/icon/icn-payment-method.svg";
import SearchIcon from "@material-ui/icons/Search";
import Invoice from "./Invoice";
import {
  fetchReceipt,
  selectReceipts,
  selectReceiptsStatus,
} from "./BillingHistorySlice";
import { IReceipt } from "./types";

export interface IBillingHistoryTab {
  /* Active tab number */
  tabNumber: number;
  /* Corresponding tab index */
  tabIndex: number;
  /* Receipts */
  receiptSelector?: (state: RootState) => IReceipt[];
  /* Receipts Request Status */
  requestStatusSelector?: (state: RootState) => ReducerStatus;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      order: 2,
      flex: "100%",
    },
    titleText: {
      color: theme.palette.secondary.light,
      fontWeight: 600,
    },
    addPaymentButton: {
      display: "flex",
      alignItems: "center",
      flexBasis: "50%",
      marginTop: 40,
      marginBottom: 32,
      maxWidth: 328,
      justifyContent: "flex-end",
      [theme.breakpoints.down("md")]: {
        marginTop: 24,
        marginBottom: 24,
        marginLeft: -20,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 0,
      },
    },
    buttonText: {
      lineHeight: 1,
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",
      flexBasis: "50%",
      marginTop: 40,
      marginBottom: 32,
      [theme.breakpoints.down("md")]: {
        marginLeft: 20,
        marginBottom: 24,
        marginTop: 24,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 8,
        marginBottom: 0,
      },
    },
    container: {
      display: "flex",
      marginBottom: 48,
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginRight: 32,
      marginLeft: 32,
      [theme.breakpoints.down("md")]: {
        flex: "100%",
        marginTop: 0,
        order: 2,
        marginRight: 0,
        marginLeft: 0,
      },
    },
    searchIcon: {
      color: theme.palette.info.light,
    },
    textField: {
      width: 100,
      marginRight: 4,
      [theme.breakpoints.down("sm")]: {
        width: 70,
      },
      [theme.breakpoints.up("sm")]: {
        width: 90,
      },
    },
    searchMobile: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        marginBottom: 20,
      },
    },
    searchTablet: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

const BillingHistory: React.FC<IBillingHistoryTab> = ({
  tabNumber,
  tabIndex,
  receiptSelector = (state: RootState) => selectReceipts(state),
  requestStatusSelector = (state: RootState) => selectReceiptsStatus(state),
}) => {
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReceipt());
  }, [dispatch]);

  // Search Component
  const [searchText, setSearchText] = useState("");

  /* Retrieve data from the store */
  /* Receipts or Invoices */
  const receipts = useSelector(receiptSelector);

  /* Receipts Request Status */
  const requestStatus = useSelector(requestStatusSelector);

  // Filtered History Billing
  const filteredBilling = useMemo(
    () =>
      receipts?.filter(
        (info: IReceipt) =>
          info?.invoiceDate
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase()) ||
          info?.paymentAmount
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase())
      ),
    [receipts, searchText]
  );

  return (
    <TabPanel
      data-qatag="tabPanel"
      value={tabNumber}
      index={tabIndex}
      className={classes.tabPanel}
    >
      <Box data-qatag="container" className={classes.container}>
        <Box data-qatag="logoContainer" className={classes.logoContainer}>
          <img
            data-qatag="titleIcon"
            className={styles.titleIcon}
            src={paymentIcon}
            alt="wire-frame"
          />
          <Typography
            data-qatag="titleText"
            variant="h6"
            className={classes.titleText}
          >
            Invoices
          </Typography>
        </Box>
        {/* Search Component */}
        <Box data-qatag="addPaymentButton" className={classes.addPaymentButton}>
          {/* Search Component Tablet */}
          <SearchTextField
            data-qatag="searchFieldTablet"
            value={searchText}
            onChange={(value) => setSearchText(value)}
            placeholder="Search"
            containerClassName={classes.searchTablet}
          />
          {/* Search Component for mobile */}
          <Box
            data-qatag="searchMobile"
            display="flex"
            alignItems="center"
            className={classes.searchMobile}
          >
            <IconButton data-qatag="searchIcon" aria-label="searchIcon">
              <SearchIcon
                data-qatag="searchIcon"
                className={classes.searchIcon}
              />
            </IconButton>
            <TextField
              data-qatag="standard-basic"
              id="standard-basic"
              onChange={(e) => setSearchText(e.target.value)}
              className={classes.textField}
              value={searchText}
              label={!searchText ? "Search" : ""}
            />
          </Box>
        </Box>
        {filteredBilling?.length ? (
          <>
            <Box data-qatag="tableHeader" className={styles.tableHeader}>
              <Box data-qatag="headerDate" className={styles.headerDate}>
                Date
              </Box>
              <Box data-qatag="headerTotal" className={styles.headerTotal}>
                Total
              </Box>
              <Box data-qatag="headerPlan" className={styles.headerPlan}>
                Plan
              </Box>
              <Box
                data-qatag="headerDownload"
                className={styles.headerDownload}
              >
                Download
              </Box>
            </Box>
            {filteredBilling?.map((info: IReceipt, index: number) => (
              <Invoice
                data-qatag="invoice"
                info={info}
                index={index}
                key={info.invoiceId}
              />
            ))}
          </>
        ) : ReducerStatus[requestStatus] !== ReducerStatus.Idle ? (
          <EmptyState
            data-qatag="noRecords"
            error="Empty"
            suggestion="You don't billing history"
          />
        ) : (
          ""
        )}
      </Box>
      {/* Loading modal */}
      <Spinner
        data-qatag="loadingModal"
        isActive={ReducerStatus[requestStatus] === ReducerStatus.Idle}
        text="Loading..."
      />
    </TabPanel>
  );
};

export default BillingHistory;
