import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import RESTGatewayAPI from "api/gatewayAPI";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { RootState } from "app/store";
import { IBlockedAppInfo } from "model/deceptorDetector/IBlockedAppInfo";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import { IServiceMessage, ServiceMessage, WSMessageType } from "ui.common";
import {
  IBlockedProcessCollectionMessage,
  IDeceptorInfoMessage,
  IDriverBlockProcessMessage,
  IJitDriverBlockedProcsReceivedMessage,
} from "./DeceptorProtectionMessages";
import { selectCurrentUuid } from "session/SessionSlice";

interface IDeceptorProtectionState {
  deceptorsFound: IDeceptorInfo[];
  deceptorsBlocked: IDeceptorInfo[];
  allowedSoftware: IDeceptorInfo[];
  currentDeceptor: IDeceptorInfo | null;
}

export interface IDeceptorInfo {
  Id: string;
  Name: string;
  FilePath: string;
  BlockDate: string | null;
  AppEsteemViolations: string[];
  AppEsteemNonDeceptorViolations: string[];
  AvBlockList: string[];
  AvAllowList: string[];
  IsAllowed: boolean;
}

interface IDeceptorFoundPayloadEntry {
  Deceptors: IDeceptorFoundPayloadEntryDeceptor[];
  LocalFileName: string;
  LocalFilePathName: string;
}

interface IDeceptorFoundPayloadEntryDeceptor {
  DeceptorId: string;
  Name: string;
  AppEsteemViolations: string[];
  AppEsteemNonDeceptorViolations: string[];
  Samples: IDeceptorFoundPayloadEntryDeceptorSamplesItem[];
}

interface IDeceptorFoundPayloadEntryDeceptorSamplesItem {
  AvBlockList: string[];
  AvAllowList: string[];
  FileName: string;
  FileVersion: string;
}

interface IFetchAllowedSoftwareResponse {
  AllowedProceses: IAllowedProcess[]; //yes, it's misspelled
}

interface IAllowedProcess {
  DateBlocked: string;
  FilePathName: string;
  Hash: string;
  Tag: string;
}

export const fetchDeceptorsBlocked = createAsyncThunk<
  IDeceptorInfo[],
  void,
  { state: RootState }
>("deceptorProtection/fetchDeceptorsBlocked", async (_, thunkApi) => {
  try {
    const srhub = getSignalRHub();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.GET_BLOCKED_PROC_HISTORY;
    const response: IBlockedProcessCollectionMessage = (
      await srhub.SendAsync(message)
    ).Payload;

    const retVal: IDeceptorInfo[] = [];
    for (const [key, value] of Object.entries(response)) {
      const deceptor = value[value.length - 1]; //use the most recent entry
      const newVal: IDeceptorInfo = {
        ...deceptor,
        Id: deceptor.InstanceID,
        Name: deceptor.DeceptorInfo.Deceptors[0]?.Name ?? deceptor.ProcName,
        FilePath: key,
        BlockDate: new Date(deceptor.BlockDate).toLocaleDateString(), //convert UTC timestamp to nice date string
        IsAllowed: false,
      };
      retVal.push(newVal);
    }

    return retVal;
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch blocked deceptors : ${error}`
    );
  }
});

export const fetchDeceptorsFound = createAsyncThunk<
  IDeceptorInfo[],
  void,
  { state: RootState }
>("deceptorProtection/fetchDeceptorsFound", async (_, thunkApi) => {
  try {
    const state = thunkApi.getState();
    const uuid = selectCurrentUuid(state);
    const url = `/api/scan/deceptor/${uuid}`;
    const apiResponse = await RESTGatewayAPI.get(url);
    const responseObj = JSON.parse(apiResponse.data.payload);
    const results: IDeceptorFoundPayloadEntry[] =
      responseObj.EventData.Deceptors;
    const retVal: IDeceptorInfo[] = [];
    for (const result of results) {
      const newVal: IDeceptorInfo = {
        Name: result.Deceptors[0]?.Name ?? result.LocalFileName,
        FilePath: result.LocalFilePathName,
        AppEsteemViolations: result.Deceptors.reduce(
          (acc: string[], curr: IDeceptorFoundPayloadEntryDeceptor) => {
            acc.push(...curr.AppEsteemViolations);
            return acc;
          },
          []
        ),
        AppEsteemNonDeceptorViolations: result.Deceptors.reduce(
          (acc: string[], curr: IDeceptorFoundPayloadEntryDeceptor) => {
            acc.push(...curr.AppEsteemNonDeceptorViolations);
            return acc;
          },
          []
        ),
        Id: result.Deceptors[0]?.DeceptorId ?? "no deceptorId found",
        BlockDate: null,
        AvAllowList: result.Deceptors.reduce(
          (acc: string[], curr: IDeceptorFoundPayloadEntryDeceptor) => {
            acc.push(
              ...curr.Samples.reduce(
                (
                  acc: string[],
                  curr: IDeceptorFoundPayloadEntryDeceptorSamplesItem
                ) => {
                  curr.AvAllowList.forEach((x) => acc.push(x));
                  return acc;
                },
                []
              )
            );
            return acc;
          },
          []
        ),
        AvBlockList: result.Deceptors.reduce(
          (acc: string[], curr: IDeceptorFoundPayloadEntryDeceptor) => {
            acc.push(
              ...curr.Samples.reduce(
                (
                  acc: string[],
                  curr: IDeceptorFoundPayloadEntryDeceptorSamplesItem
                ) => {
                  curr.AvBlockList.forEach((x) => acc.push(x));
                  return acc;
                },
                []
              )
            );
            return acc;
          },
          []
        ),
        IsAllowed: false,
      };
      if (!retVal.some((x) => x.Id === newVal.Id)) {
        retVal.push(newVal);
      }
    }
    return retVal;
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch found deceptors : ${error}`
    );
  }
});

export const fetchAllowedSoftware = createAsyncThunk<
  IDeceptorInfo[],
  void,
  { state: RootState }
>("deceptorProtection/fetchAllowedSoftware", async (_, thunkApi) => {
  try {
    const srhub = getSignalRHub();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.JIT_DRIVER_GET_BLOCKED_PROCS;
    const response: IFetchAllowedSoftwareResponse = (
      await srhub.SendAsync(message)
    ).Payload;

    const retVal: IDeceptorInfo[] = [];
    for (const process of response.AllowedProceses) {
      const newVal: IDeceptorInfo = {
        //using Tag instead of Hash for Id because the hash can contain "/" which breaks Breadcrumbs when it appears in the url
        Id: process.Tag,
        Name: process.Tag,
        FilePath: process.FilePathName,
        BlockDate: process.DateBlocked,
        AppEsteemViolations: [],
        AppEsteemNonDeceptorViolations: [],
        AvAllowList: [],
        AvBlockList: [],
        IsAllowed: true,
      };
      retVal.push(newVal);
    }

    return retVal;
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch allowed software : ${error}`
    );
  }
});

export const allowSoftware = createAsyncThunk<
  void,
  IDeceptorInfo,
  { state: RootState }
>("deceptorProtection/allowSoftware", async (deceptorInfo, thunkApi) => {
  try {
    const srhub = getSignalRHub();
    const addMessage: IServiceMessage = new ServiceMessage();
    addMessage.MessageType = WSMessageType.JIT_DRIVER_ADD_ALLOWED_PROC;
    addMessage.Payload = {
      FilePathName: deceptorInfo.FilePath,
      Tag: deceptorInfo.Name,
    };
    const addResponse = await srhub.SendAsync(addMessage);
    const removeMessage: IServiceMessage = new ServiceMessage();
    removeMessage.MessageType = WSMessageType.JIT_DRIVER_REMOVE_BLOCKED_PROC;
    removeMessage.Payload = deceptorInfo.FilePath;
    const removeResponse = await srhub.SendAsync(removeMessage);
    return removeResponse;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to allow software : ${error}`);
  }
});

export const blockSoftware = createAsyncThunk<
  void,
  IDeceptorInfo,
  { state: RootState }
>("deceptorProtection/blockSoftware", async (deceptorInfo, thunkApi) => {
  try {
    const srhub = getSignalRHub();
    const addMessage: IServiceMessage = new ServiceMessage();
    addMessage.MessageType = WSMessageType.JIT_DRIVER_ADD_BLOCKED_PROC;
    addMessage.Payload = {
      FilePathName: deceptorInfo.FilePath,
      Tag: deceptorInfo.Name,
    };
    const addResponse = await srhub.SendAsync(addMessage);
    const removeMessage: IServiceMessage = new ServiceMessage();
    removeMessage.MessageType = WSMessageType.JIT_DRIVER_REMOVE_ALLOWED_PROC;
    removeMessage.Payload = deceptorInfo.FilePath;
    const removeResponse = await srhub.SendAsync(removeMessage);
    return removeResponse;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to block software : ${error}`);
  }
});

//remove allowed software from blocked/found lists
//pull in missing data for allowed software that has been blocked/found
const consolidateStateData = (state: IDeceptorProtectionState) => {
  const data = { ...state };

  if (state.allowedSoftware.length === 0) {
    return data;
  }

  const uniqueConcat = (arr1: string[], arr2: string[]) => {
    return Array.from(new Set(arr1.concat(arr2)));
  };

  state.deceptorsBlocked.forEach((deceptor) => {
    const allowed = data.allowedSoftware.find((a) => a.Name === deceptor.Name);
    if (!allowed) {
      return;
    }

    allowed.AvAllowList = uniqueConcat(
      allowed.AvAllowList,
      deceptor.AvAllowList
    );
    allowed.AvBlockList = uniqueConcat(
      allowed.AvBlockList,
      deceptor.AvBlockList
    );
    allowed.AppEsteemViolations = uniqueConcat(
      allowed.AppEsteemViolations,
      deceptor.AppEsteemViolations
    );
    allowed.AppEsteemNonDeceptorViolations = uniqueConcat(
      allowed.AppEsteemNonDeceptorViolations,
      deceptor.AppEsteemNonDeceptorViolations
    );

    const index = data.deceptorsBlocked.findIndex((d) => d.Id === deceptor.Id);
    data.deceptorsBlocked.splice(index, 1);
  });

  state.deceptorsFound.forEach((deceptor) => {
    const allowed = data.allowedSoftware.find((a) => a.Name === deceptor.Name);
    if (!allowed) {
      return;
    }

    allowed.AvAllowList = uniqueConcat(
      allowed.AvAllowList,
      deceptor.AvAllowList
    );
    allowed.AvBlockList = uniqueConcat(
      allowed.AvBlockList,
      deceptor.AvBlockList
    );
    allowed.AppEsteemViolations = uniqueConcat(
      allowed.AppEsteemViolations,
      deceptor.AppEsteemViolations
    );
    allowed.AppEsteemNonDeceptorViolations = uniqueConcat(
      allowed.AppEsteemNonDeceptorViolations,
      deceptor.AppEsteemNonDeceptorViolations
    );

    const index = data.deceptorsFound.findIndex((d) => d.Id === deceptor.Id);
    data.deceptorsFound.splice(index, 1);
  });

  return data;
};

const initialState: IReducerState<IDeceptorProtectionState> = {
  data: {
    deceptorsFound: [],
    deceptorsBlocked: [],
    allowedSoftware: [],
    currentDeceptor: null,
  },
  status: {
    [fetchDeceptorsBlocked.typePrefix]: ReducerStatus.Idle,
    [fetchDeceptorsFound.typePrefix]: ReducerStatus.Idle,
    [fetchAllowedSoftware.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const deceptorProtectionSlice = createSlice({
  name: "deceptorProtection",
  initialState,
  reducers: {
    setCurrentDeceptor: (state, action: PayloadAction<IDeceptorInfo>) => {
      state.data.currentDeceptor = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeceptorsBlocked.pending, (state) => {
        state.status[fetchDeceptorsBlocked.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(fetchDeceptorsBlocked.fulfilled, (state, action) => {
        if (state.data) {
          state.data.deceptorsBlocked = action.payload;
          state.error = "";
          state.status[fetchDeceptorsBlocked.typePrefix] =
            ReducerStatus.Succeeded;

          state.data = consolidateStateData(state.data);
        }
      })
      .addCase(fetchDeceptorsBlocked.rejected, (state, action) => {
        state.status[fetchDeceptorsBlocked.typePrefix] = ReducerStatus.Failed;
        state.error = action.error.message;
      })
      .addCase(fetchDeceptorsFound.pending, (state) => {
        state.status[fetchDeceptorsFound.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(fetchDeceptorsFound.fulfilled, (state, action) => {
        if (state.data) {
          state.data.deceptorsFound = action.payload;
          state.error = "";
          state.status[fetchDeceptorsFound.typePrefix] =
            ReducerStatus.Succeeded;

          state.data = consolidateStateData(state.data);
        }
      })
      .addCase(fetchDeceptorsFound.rejected, (state, action) => {
        state.status[fetchDeceptorsFound.typePrefix] = ReducerStatus.Failed;
        state.error = action.error.message;
      })
      .addCase(fetchAllowedSoftware.pending, (state) => {
        state.status[fetchAllowedSoftware.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(fetchAllowedSoftware.fulfilled, (state, action) => {
        if (state.data) {
          state.data.allowedSoftware = action.payload;
          state.error = "";
          state.status[fetchAllowedSoftware.typePrefix] =
            ReducerStatus.Succeeded;

          state.data = consolidateStateData(state.data);
        }
      })
      .addCase(fetchAllowedSoftware.rejected, (state, action) => {
        state.status[fetchAllowedSoftware.typePrefix] = ReducerStatus.Failed;
        state.error = action.error.message;
      });
  },
});

export const { setCurrentDeceptor } = deceptorProtectionSlice.actions;

export const selectDeceptorsBlocked = (state: RootState) => {
  return state.deceptorProtection.data.deceptorsBlocked;
};

export const selectDeceptorsFound = (state: RootState) => {
  return state.deceptorProtection.data.deceptorsFound;
};

export const selectAllowedSoftware = (state: RootState) => {
  return state.deceptorProtection.data.allowedSoftware;
};

export const selectStatus = (state: RootState) => {
  return state.deceptorProtection.status;
};

export const selectDeceptor = (state: RootState, id: string) => {
  if (!id) {
    return null;
  }

  let target: IDeceptorInfo | null = null;

  state.deceptorProtection.data.deceptorsBlocked.forEach((x) => {
    if (x.Id === id) {
      target = x;
    }
  });
  if (target) return target;

  state.deceptorProtection.data.deceptorsFound.forEach((x) => {
    if (x.Id === id) {
      target = x;
    }
  });
  if (target) return target;

  state.deceptorProtection.data.allowedSoftware.forEach((x) => {
    if (x.Id === id) {
      target = x;
    }
  });
  return target;
};

export default deceptorProtectionSlice.reducer;
