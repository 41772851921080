import { UserSettingTypes } from "core/enumerations/UserSettingTypes";

export enum ScanPeriod {
  Weekly,
  Monthly,
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum ScanType {
  Quick,
  Full,
}

export enum Scans {
  Video = 1,
  Printers = 2,
  Audio = 4,
  Network = 8,
}

export interface IUserSettingInstance {
  SettingType: UserSettingTypes;
  SettingTypeName: string;
  // This should probably be genericized, but also probably isn't worth the effort to do so
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  NewSettingValue: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CurrentSettingValue: any;
}

export interface IUpdateUserSettingValueMessage {
  UpdatedSettings: IUserSettingInstance[];
}

export interface IUserSettingsMessage {
  ScanOptions: IScanOptionsMessage;
  ScheduledScanOptions: IScheduledScanOptionsMessage;
  DriverDownloadSettings: IDriverDownloadSettingsMessage;
  NotificationSettings: INotificationSettingsMessage;
  AOProfileSettings: UserProfileStates;
  StartWithWindows: boolean;
  EnableDeceptorServices: boolean;
  DnsProtectorSettings: IDnsProtectorUserSettings;
}

export interface IScanOptionsMessage {
  IncludeSystemDevices: boolean;
  IncludeUnpluggedDevices: boolean;
  UseWindowsUpdateScanner: boolean;
}

export interface IDnsProtectorUserSettings {
  EnableDnsProtector: boolean;
}

export interface IScheduledScanOptionsMessage {
  Period: ScanPeriod;
  DayOfWeek: DayOfWeek;
  DayOfMonth: number;
  ScanTime: string;
  ScanType: ScanType;
  Scans: Scans[];
  Enabled: boolean;
}

export interface IDriverDownloadSettingsMessage {
  DownloadFolder: string;
  MaxSimultaneousDownloads: number;
}

export interface INotificationSettingsMessage {
  EnableAlerts: boolean;
  MaximumWeeklyAlerts: number;
}

export enum ProfileState {
  Disabled,
  Active,
  Prompt,
}

export class UserProfileStates {
  public BrowsingState?: ProfileState;
  public ProductivityState?: ProfileState;
  public GamingState?: ProfileState;
}

export interface IUserSettingModel {
  SettingType: UserSettingTypes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SettingValue: any;
}
