import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { ReducerStatus } from "model/IReducerState";
import { selectActivatedLicenses } from "model/license/LicenseSlice";
import { IHardwareScanMessage } from "model/messaging/messages/scanMessages";
import { connectRemote } from "session/SessionSlice";

interface IEmployeeDetailState {
  data: {
    userId: string | undefined;
    hardwareScan: IHardwareScanMessage | undefined;
  };
  error: {
    dataFetchError: string | undefined;
  };
  status: {
    dataFetchStatus: ReducerStatus;
  };
}

const initialState: IEmployeeDetailState = {
  data: {
    userId: undefined,
    hardwareScan: undefined,
  },
  error: {
    dataFetchError: undefined,
  },
  status: {
    dataFetchStatus: ReducerStatus.Idle,
  },
};

export const employeeDetailSelected = createAsyncThunk<
  void,
  number,
  {
    state: RootState;
  }
>("userManagement/employeeDetailSelected", async (userId, thunkApi) => {
  const state = thunkApi.getState();
  try {
    const uuid = selectActivatedLicenses(state).find((l) => l.userID === userId)
      ?.uuid;

    if (uuid == null) {
      return thunkApi.rejectWithValue(
        "Unable to fetch hardware scan : invalid user id"
      );
    }

    await thunkApi.dispatch(connectRemote(uuid));

    //history.push(`/employeeDetail/${user.id}
    //   const url = `/api/scan/hardware/${uuid}`;

    //   const response = await RESTGatewayAPI.get(url);
    //   const apiResponse: IFetchScanPayload = response.data;

    //   if (!apiResponse.dataResult) {
    //     return thunkApi.rejectWithValue(
    //       "Unable to fetch hardware scan : response was empty"
    //     );
    //   }

    //   return JSON.parse(apiResponse.payload);
    // } catch (error) {
    //   return thunkApi.rejectWithValue(
    //     `Unable to fetch hardware scan : ${error}`
    //   );
    // }
  } catch (error) {
    // return thunkApi.rejectWithValue(
    //   `Error while fetching user info : ${error}`
    // );
  }
});

export const employeeDetailSlice = createSlice({
  name: "employeeDetail",
  initialState,
  reducers: {
    // resetResendInvite: (state) => {
    //   state.status.resendInvite = ReducerStatus.Idle;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(
      employeeDetailSelected.fulfilled,
      (state, action: PayloadAction) => {
        //state.data.hardwareScan = action.payload;
        state.status.dataFetchStatus = ReducerStatus.Succeeded;
      }
    );
    builder.addCase(employeeDetailSelected.pending, (state) => {
      // state.status.deleteUser = ReducerStatus.Loading;
    });
    builder.addCase(employeeDetailSelected.rejected, (state) => {});
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = employeeDetailSlice.actions;

// export const selectScan = (state: RootState) =>
//   state.employeeDetail.data.hardwareScan;

export const dataFetchStatus = (state: RootState) =>
  state.employeeDetail.status.dataFetchStatus;

export default employeeDetailSlice.reducer;
