import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import RESTGatewayAPI from "api/gatewayAPI";
import { AppDispatch, RootState } from "app/store";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import IActivatedLicense from "model/license/IActivatedLicense";
import { IUser } from "model/user/IUser";
import { User } from "oidc-client";
import * as Sentry from "@sentry/react";
import {
  fetchOrganizationLicenses,
  ILicenseData,
} from "model/license/LicenseSlice";
import IAllocatedLicense from "model/license/IAllocatedLicense";

declare global {
  interface Window {
    LiveChatWidget: any;
  }
}
export interface ISessionState {
  accessToken: string;
  loggedInUser: IUser | null;
  currentRegKey: string;
  allActivatedLicenses: IActivatedLicense[];
  currentLicense: IActivatedLicense | IAllocatedLicense | undefined;
  hasApplicationError: boolean;
  isAdmin: boolean;
  isBeta: boolean;
  isRemote: boolean;
}

export const connectRemote = createAsyncThunk<
  void,
  string,
  {
    state: RootState;
  }
>("session/connectRemote", async (uuid, thunkApi) => {
  if (uuid == null) {
    thunkApi.rejectWithValue("valid uuid required.");
  }

  const dispatch = thunkApi.dispatch;

  dispatch(setCurrentUUID(uuid));
});

export interface ILoginContext {
  user: User;
  uuid: string | null;
}

export const loginSuccessful = createAsyncThunk<
  ISessionState,
  ILoginContext,
  {
    state: RootState;
  }
>("session/loginSuccessful", async (context, thunkApi) => {
  const appDispatch = thunkApi.dispatch as AppDispatch;
  const user = context.user;
  if (
    user == null ||
    user.access_token == null ||
    user.profile == null ||
    user.profile.OrganizationID == null ||
    user.profile.Email == null ||
    user.profile.Role == null
  ) {
    thunkApi.rejectWithValue(
      new Error("Required claims are missing from user token.")
    );
  }

  // pull out access token from oidc user
  const accessToken = user.access_token;
  // pull out reg key from oidc user claims
  const regKey = user.profile.OrganizationID;
  // pull out email from oidc user claims
  const userEmail = user.profile.Email;
  // pull out roles from oidc user claims
  const roles: string[] =
    typeof user.profile.Role === "string"
      ? [user.profile.Role]
      : [...user.profile.Role];
  // check for admin role
  const hasAdminRole = roles.find((role) => {
    return role === "Admin";
  })
    ? true
    : false;

  // check for beta role
  const isBeta = roles.find((role) => {
    return role === "Beta";
  })
    ? true
    : false;

  let currentLicense = undefined;

  try {
    RESTGatewayAPI.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken}`;

    const url = `/api/user/${regKey}/${userEmail}`;
    const apiResponse = await RESTGatewayAPI.get(url);
    const currentUser = apiResponse.data as IUser;
    const userId = currentUser.id;

    const hasAdminAttribute = currentUser.attributes.split(",").find((attr) => {
      return attr === "Admin";
    })
      ? true
      : false;

    const orgLicenses = await appDispatch(
      fetchOrganizationLicenses(currentUser.registrationKey)
    );
    const orgLicenseData = orgLicenses.payload as ILicenseData;

    if (context.uuid != null) {
      // a uuid was present on the query string
      // try to match with a uuid in the org license
      currentLicense = orgLicenseData.activatedLicenses.find(
        (al) => al.uuid === context.uuid && al.userID === userId
      );
    } else {
      // no uuid on qs - look for an activated license first
      currentLicense = orgLicenseData.activatedLicenses.find(
        (al) => al.userID === userId
      );
      if (currentLicense === undefined) {
        // no activated licenses, so check to see if there's an allocated license
        currentLicense = orgLicenseData.allocatedLicenses.find(
          (al) => al.userID === userId
        );
      }
    }

    if (window.LiveChatWidget != null) {
      window.LiveChatWidget.call(
        "set_customer_name",
        `${currentUser.firstName} ${currentUser.lastName}`
      );
      window.LiveChatWidget.call("set_customer_email", currentUser.email);
    }

    return {
      ...thunkApi.getState().session.data,
      accessToken: accessToken,
      loggedInUser: currentUser,
      currentRegKey: regKey,
      allActivatedLicenses: orgLicenseData.activatedLicenses,
      currentLicense: currentLicense,
      isAdmin: hasAdminRole || hasAdminAttribute,
      isBeta: isBeta,
    } as ISessionState;
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to login on loginSuccessful:${error}`
    );
  }
});

const initialState: IReducerState<ISessionState> = {
  data: {
    accessToken: "",
    loggedInUser: null,
    currentRegKey: "",
    allActivatedLicenses: [],
    currentLicense: undefined,
    //isAgentLocal: false,
    //localHttpPort: "",
    hasApplicationError: false,
    isBeta: false,
    isAdmin: false,
    isRemote: false,
  },
  status: {
    [loginSuccessful.typePrefix]: ReducerStatus.Idle,
    // [uuidSelected.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const SessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    reportApplicationError: (state, action: PayloadAction<Error>) => {
      Sentry.captureException(action.payload);
      state.data.hasApplicationError = true;
    },
    setCurrentUUID: (state, action: PayloadAction<string>) => {
      const newLicense = state.data.allActivatedLicenses.find(
        (l) => l.uuid === action.payload
      );
      if (newLicense) {
        state.data.currentLicense = newLicense;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginSuccessful.pending, (state, action) => {
      state.status[loginSuccessful.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(loginSuccessful.rejected, (state, action) => {
      state.status[loginSuccessful.typePrefix] = ReducerStatus.Failed;
      state.error = action.payload as string;
    });
    builder.addCase(loginSuccessful.fulfilled, (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          accessToken: action.payload.accessToken,
          loggedInUser: action.payload.loggedInUser,
          allActivatedLicenses: action.payload.allActivatedLicenses,
          currentLicense: action.payload.currentLicense,
          currentRegKey: action.payload.currentRegKey,
          isAdmin: action.payload.isAdmin,
          isBeta: action.payload.isBeta,
        },
        status: {
          ...state.status,
          [loginSuccessful.typePrefix]: ReducerStatus.Succeeded,
        },
      };
    });
    builder.addCase(connectRemote.fulfilled, (state, action) => {
      state.data.isRemote = true;
    });
  },
});

// actions
// eslint-disable-next-line no-empty-pattern
export const {
  setCurrentUUID,
  //setLocalHttpPort,
  reportApplicationError,
} = SessionSlice.actions;

// selectors
export const selectAccessToken = (state: RootState) =>
  state.session.data.accessToken;

export const selectCurrentUser = (state: RootState) => {
  const currentId = state.session.data.currentLicense?.userID;
  if (currentId) {
    const returnUser = state.users.data.find((u) => u.id === currentId);
    if (returnUser) {
      return returnUser;
    }
    // if we can't find the user in the org users (possibly hasn't been fetched yet)
    // check against the one user we know exists - the oauth user
    if (state.session.data.loggedInUser?.id === currentId) {
      return state.session.data.loggedInUser;
    }
    return null;
  }
  return null;
};

// returns either the current uuid or "" if there is no activated license
export const selectCurrentUuid = (state: RootState) => {
  if (
    state.session.data.currentLicense &&
    state.session.data.currentLicense.licenseType === "Activated"
  ) {
    return (state.session.data.currentLicense as IActivatedLicense).uuid;
  }
  return "";
};

export const selectCurrentRegKey = (state: RootState) =>
  state.session.data.currentRegKey;

// based on active licenses only for backwards compatability with existing
// code that makes that assumption
export const selectAllUUIDs = (state: RootState) => {
  return state.session.data.allActivatedLicenses.map((l) => l.uuid);
};

export const selectLoginSuccessfulStatus = (state: RootState) =>
  state.session.status[loginSuccessful.typePrefix];

export const selectSessionStatus = (state: RootState) => state.session.status;

export const selectError = (state: RootState) => state.session.error;

// export const selectIsAgentLocal = (state: RootState) =>
//   state.session.data.isAgentLocal;

export const selectHasApplicationError = (state: RootState) =>
  state.session.data.hasApplicationError;

// based on active licenses only for backwards compatability with existing
// code that makes that assumption
export const selectAllLicenses = (state: RootState) =>
  state.session.data.allActivatedLicenses;

// if there is no current license (active or allocated) then redirect
// user to the 'contact your admin' page
export const selectRedirectToContactAdmin = (state: RootState) =>
  state.session.data.currentLicense === undefined;

export const selectRedirectToDownload = (state: RootState) =>
  state.session.data.currentLicense &&
  state.session.data.currentLicense.licenseType === "Allocated";

export const selectIsAdmin = (state: RootState) => state.session.data.isAdmin;

export const selectIsBeta = (state: RootState) => state.session.data.isBeta;

export const selectIsRemote = (state: RootState) => state.session.data.isRemote;

export const selectUserDescriptor = (state: RootState) => {
  const user = selectCurrentUser(state);
  if (user) {
    return `${user.firstName} ${user.lastName}'s - ${user.machineDisplayName}`;
  }
  return "";
};

export default SessionSlice.reducer;
