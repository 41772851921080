export interface IDriverUpdateModel {
  updateStatus: DriverUpdateStatus;
  // non-null if the driver is downloading
  downloadRecord: IDriverDownloadModel | null;
  // non-null if the driver is installing
  installRecord: IDriverInstallModel | null;
  // guid of the driver this being updated
  driverId: string;
  // device ids associated with this update
  devices: string[];
  // non-null if the driver update failed
  error: string | null;
}

export interface IDriverDownloadModel {
  // status of the download
  downloadStatus: DriverDownloadStatus;
  // download progress from 0-100%
  downloadPercentProgress: number;
  // describes the estimated amount of time remaining (non-localized) in the download
  downloadCompletionETA: string;
  // guid of the driver that is being downloaded
  driverId: string;
}

export interface IDriverInstallModel {
  // status of the install
  installStatus: DriverInstallStatus;
  // guid of the driver that is being installed
  driverId: string;
}

//
// messages - these data structures will go across the wire to/from the agent
//
export interface IResourceFile {
  ResourceFileID: string;
  ResourceFileSource: string;
  DownloadURI: string;
  Description: string;
  ResourceFileTypeID: number;
  ResourceFileTypeName: string;
  ResourceFileMapTypeID: number;
  ResourceFileMapTypeName: string;
  ResourceFileMapTypeCategoryID: number;
  ResourceFileMapTypeCategoryName: string;
  Culture: string;
}

export interface IDeviceMessage {
  Description: string;
  Manufacturer: string;
  DeviceKey: string;
  Class: string;
  ClassName: string;
  ClassGUID: string;
  DriverDate: string;
  DriverVersion: string;
  DriverDescription: string;
  DriverProvider: string;
  IsSystem: boolean;
  HasFriendlyName: boolean;
  HardwareIDs: string[];
  CompatibleIDs?: string[];
  InstanceID: string;
  IsUnplugged: boolean;
  DriverUpdateType: string;
  DeviceID: string;
  SupportMetaData?: string;
  ResourceFiles: IResourceFile[];
  WUDriverUpdateValues?: string[];
  DriverUpdates?: IDriverUpdateMessage;
  CategoryDisplayName: string;
  Category: string;
  DriverStatus: string;
  HasUpdateAvailable: boolean;
  ClassRank: number;
  IsPnp: boolean;
  LocalIP: string;
}

export interface IRecommendedDriverPackageFileMessage {
  FileID: number;
  FileName: string;
  FileSize: number; // bytes
  FileType: number;
  FileDate: string;
  FileVersion: string;
  Hash: string;
  Mirror: string;
  Mirror2: string;
  Provider: string;
}
export interface IDriverUpdateMessage {
  DriverProvider: string;
  RecommendedDriverGUID: string;
  RecommendedDriverVersion: string;
  RecommendedDriverDate: string;
  Severity: string;
  Class: string;
  Description: string;
  RecommendedDriverPackageFile: IRecommendedDriverPackageFileMessage;
}

export interface IDriverDownloadRecordMessage {
  UpdateID: string;
  Update: IDriverUpdateMessage;
  Devices: IDeviceMessage[];
  DownloadLocation: string;
  DownloadFileName: string;
  DownloadFilePathName: string;
  FileHash: string;
  IsValid: boolean;
}

export interface IDriverInstallUpdateMessage {
  Record: IDriverDownloadRecordMessage;
  Status: AgentDriverInstallStatus;
}

export interface IDriverStatusMessage {
  Update: IDriverUpdateMessage;
  Status: AgentDriverUpdateStatus;
  StatusName: string;
  DeviceID: string;
  CanAutoInstall: boolean;
}

//
// agent enums - enumerations defined agent-side
//
export enum AgentDriverInstallStatus {
  Unknown = 0,
  InstallationStarting = 1,
  LowDiscSpace = 2,
  UnzipStarted = 3,
  UnzipComplete = 4,
  UnzipFailed = 5,
  UnknownInstallerStarted = 6,
  InstallerRunning = 7,
  InstallerFailure = 8,
  InstallerTimeout = 9,
  InstallerFinished = 10,
  InstallerRequiresUser = 11,
  InstallerCancelled = 12,
  InstallerForcedComplete = 13,
  UpdateVerifying = 14,
  UpdateComplete = 15,
}

export enum AgentDriverUpdateStatus {
  Unknown = 0,
  AvailableForDownload = 1,
  Queued = 2,
  Downloading = 3,
  Downloaded = 4,
  Unzipping = 5,
  Unzipped = 6,
  Installing = 7,
  UserInstalling = 8,
  TimedOut = 9,
  Verifying = 10,
  Verified = 11,
  Failed = 12,
}

//
// client enums - enumerations used in the client react app
// agent enums are mapped to these
//
export enum DriverDownloadStatus {
  Cancelling = "Cancelling",
  Cancelled = "Cancelled",
  Error = "Error",
  Initializing = "Initializing",
  InProgress = "InProgress",
  Queued = "Queued",
  Unknown = "Unknown",
}

export enum DriverInstallStatus {
  Complete = "Complete",
  Error = "Error",
  InProgress = "InProgress",
  Interrupted = "Interrupted",
  UserInteraction = "UserInteraction",
  TimedOut = "TimedOut",
  Verifying = "Verifying",
  Unknown = "Unknown",
  UnknownInstaller = "UnknownInstaller",
  LowDiskSpace = "LowDiskSpace",
}

export enum DriverUpdateStatus {
  Queued = "Queued",
  Downloading = "Downloading",
  Installing = "Installing",
  Failed = "Failed",
  ReadyToDownload = "ReadyToDownload",
  ReadyToInstall = "ReadyToInstall",
  Complete = "Complete",
}

//
// enum mapping functions
//
export const mapUpdateToUpdateStatus = (
  status: AgentDriverUpdateStatus
): DriverUpdateStatus => {
  switch (status) {
    case AgentDriverUpdateStatus.AvailableForDownload:
      return DriverUpdateStatus.ReadyToDownload;
    case AgentDriverUpdateStatus.Downloaded:
      return DriverUpdateStatus.ReadyToInstall;
    case AgentDriverUpdateStatus.Downloading:
      return DriverUpdateStatus.Downloading;
    case AgentDriverUpdateStatus.Queued:
      return DriverUpdateStatus.Queued;
    case AgentDriverUpdateStatus.Failed:
      return DriverUpdateStatus.Failed;
    // just consider all these states as an in-progress install
    case AgentDriverUpdateStatus.Installing:
    case AgentDriverUpdateStatus.Unzipped:
    case AgentDriverUpdateStatus.Unzipping:
    case AgentDriverUpdateStatus.TimedOut:
    case AgentDriverUpdateStatus.UserInstalling:
    case AgentDriverUpdateStatus.Verifying:
      return DriverUpdateStatus.Installing;
    case AgentDriverUpdateStatus.Unknown:
    case AgentDriverUpdateStatus.Verified:
      return DriverUpdateStatus.Complete;
  }
};

export const mapUpdateToDownloadStatus = (
  status: AgentDriverUpdateStatus
): DriverDownloadStatus | null => {
  if (status === AgentDriverUpdateStatus.Downloading) {
    return DriverDownloadStatus.InProgress;
  } else {
    return null;
  }
};

export const mapUpdateToInstallStatus = (
  status: AgentDriverUpdateStatus
): DriverInstallStatus | null => {
  switch (status) {
    // downloading - install status is null;
    case AgentDriverUpdateStatus.AvailableForDownload:
    case AgentDriverUpdateStatus.Downloaded:
    case AgentDriverUpdateStatus.Downloading:
    case AgentDriverUpdateStatus.Queued:
      return null;
    // error
    case AgentDriverUpdateStatus.Failed:
      return DriverInstallStatus.Error;
    // just consider all these states as an in progress install
    case AgentDriverUpdateStatus.Installing:
    case AgentDriverUpdateStatus.Unzipped:
    case AgentDriverUpdateStatus.Unzipping:
      return DriverInstallStatus.InProgress;
    // timed-out
    case AgentDriverUpdateStatus.TimedOut:
      return DriverInstallStatus.TimedOut;
    // these cases aren't expected - so just return unknown
    case AgentDriverUpdateStatus.Unknown:
    case AgentDriverUpdateStatus.Verified:
      return DriverInstallStatus.Unknown;
    // user interaction required
    case AgentDriverUpdateStatus.UserInstalling:
      return DriverInstallStatus.UserInteraction;
    // verifying
    case AgentDriverUpdateStatus.Verifying:
      return DriverInstallStatus.Verifying;
  }
};

export const mapToUiUpdateStatus = (
  status: AgentDriverInstallStatus
): DriverUpdateStatus => {
  let returnStatus = DriverUpdateStatus.Installing;

  switch (status) {
    case AgentDriverInstallStatus.UpdateComplete:
      returnStatus = DriverUpdateStatus.Complete;
      break;
    case AgentDriverInstallStatus.InstallerCancelled:
    case AgentDriverInstallStatus.InstallerFailure:
    case AgentDriverInstallStatus.LowDiscSpace:
    case AgentDriverInstallStatus.UnzipFailed:
    case AgentDriverInstallStatus.Unknown:
      returnStatus = DriverUpdateStatus.Failed;
      break;
    default:
      returnStatus = DriverUpdateStatus.Installing;
  }

  return returnStatus;
};

export const mapToUiInstallStatus = (
  status: AgentDriverInstallStatus
): DriverInstallStatus => {
  let returnStatus = DriverInstallStatus.InProgress;

  switch (status) {
    case AgentDriverInstallStatus.InstallerCancelled:
    case AgentDriverInstallStatus.InstallerFailure:
    case AgentDriverInstallStatus.Unknown:
    case AgentDriverInstallStatus.UnzipFailed:
      returnStatus = DriverInstallStatus.Error;
      break;
    case AgentDriverInstallStatus.LowDiscSpace:
      returnStatus = DriverInstallStatus.LowDiskSpace;
      break;
    case AgentDriverInstallStatus.InstallerRequiresUser:
      returnStatus = DriverInstallStatus.UserInteraction;
      break;
    case AgentDriverInstallStatus.InstallerTimeout:
      returnStatus = DriverInstallStatus.TimedOut;
      break;
    case AgentDriverInstallStatus.UnknownInstallerStarted:
      returnStatus = DriverInstallStatus.UnknownInstaller;
      break;
    case AgentDriverInstallStatus.UpdateComplete:
      returnStatus = DriverInstallStatus.Complete;
      break;
    case AgentDriverInstallStatus.UpdateVerifying:
      returnStatus = DriverInstallStatus.Verifying;
      break;
    default:
      returnStatus = DriverInstallStatus.InProgress;
  }

  return returnStatus;
};

export const createDriverUpdateModel = (
  message: IDriverStatusMessage
): IDriverUpdateModel => {
  const newUpdateStatus = mapUpdateToUpdateStatus(message.Status);
  const newDownloadStatus = mapUpdateToDownloadStatus(message.Status);
  const newInstallStatus = mapUpdateToInstallStatus(message.Status);
  let newDownloadRecord = null;
  let newInstallRecord = null;

  if (newDownloadStatus != null) {
    newDownloadRecord = {
      downloadStatus: mapUpdateToDownloadStatus(message.Status),
      downloadCompletionETA: "",
      downloadPercentProgress: 0,
      driverId: message.Update.RecommendedDriverGUID,
    } as IDriverDownloadModel;
  }

  if (newInstallStatus != null) {
    newInstallRecord = {
      installStatus: newInstallStatus,
      driverId: message.Update.RecommendedDriverGUID,
    } as IDriverInstallModel;
  }

  return {
    driverId: message.Update.RecommendedDriverGUID,
    devices: [message.DeviceID],
    updateStatus: newUpdateStatus,
    downloadRecord: newDownloadRecord,
    installRecord: newInstallRecord,
  } as IDriverUpdateModel;
};
