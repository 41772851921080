import React from "react";
import styles from "./ReviewOrder.module.css";
import reviewIcon from "assets/img/billing/rocketman.png";
import detailsIcon from "assets/icon/icn-details-info.png";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";
import { Typography, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface IReviewOrder {
  /* Controller to display the modal*/
  isOpen: boolean;
  /* Price per seat */
  unitPrice?: number;
  /* Total seats numbers seats length*/
  seats?: number;
  /* Taxes to pay */
  tax?: number;
  /* Handler to close the modal */
  closeHandler: () => void;
  /* Seats affected in this operation positive or negative number */
  seatsAffected: number;
  /* Charge Amount for this transaction */
  chargeAmount: number;
  /* Does the user have pending credit */
  isCreditPending?: boolean;
  /* pending credit */
  credit?: number;
  /* Total to pay*/
  total: number;
  /* recurring charge */
  recurringCharge: number;
}

/* Custom style */
const useStyles = makeStyles(() => ({
  buttonText: {
    lineHeight: 1,
  },
}));

const ReviewOrder: React.FC<IReviewOrder> = ({
  isOpen,
  unitPrice = 19.99,
  seats = 5,
  tax = 0.99 * 5,
  closeHandler,
  credit = 0,
  seatsAffected,
  chargeAmount = 0,
  isCreditPending = false,
  total = 0,
  recurringCharge = 0,
}) => {
  // Custom styles
  const classes = useStyles();

  return (
    <Modal data-qatag="roleModal" open={isOpen} onClose={closeHandler}>
      <div data-qatag="container" className={styles.container}>
        <img data-qatag="reviewIcon" src={reviewIcon} alt="review" />
        <header data-qatag="header" className={styles.header}>
          <h1 data-qatag="title" className={styles.title}>
            Thank You for your order!
          </h1>
          <span data-qatag="subtitle" className={styles.subtitle}>
            You {seatsAffected > 0 ? "added" : "removed"}{" "}
            {Math.abs(seatsAffected)} {seatsAffected > 0 ? "additional" : ""}{" "}
            seat{seatsAffected > 1 ? "s" : ""} to your plan. We emailed you a
            confirmation.
          </span>
        </header>

        <div data-qatag="summary" className={styles.summary}>
          <header data-qatag="summaryHeader" className={styles.summaryHeader}>
            <h2 data-qatag="summaryTitle" className={styles.summaryTitle}>
              Purchase summary
            </h2>
            <span
              data-qatag="summarySubtitle"
              className={styles.summarySubtitle}
            >
              Team plan (${unitPrice} / Seat per month)
            </span>
          </header>
          <div data-qatag="row" className={styles.row}>
            <span data-qatag="label" className={styles.label}>
              Seat x {seats}
            </span>
            <span data-qatag="value" className={styles.value}>
              ${chargeAmount}
            </span>
          </div>
          {isCreditPending ? (
            <div data-qatag="row" className={styles.row}>
              <span data-qatag="label" className={styles.label}>
                Remaining credit from existing seats
              </span>
              <span data-qatag="value" className={styles.value}>
                {credit > 0 ? "" : "-"}${Math.abs(credit)}
              </span>
            </div>
          ) : (
            ""
          )}
          <div data-qatag="row" className={styles.row}>
            <span data-qatag="label" className={styles.label}>
              Tax
            </span>
            <span data-qatag="value" className={styles.value}>
              ${tax}
            </span>
          </div>
          <div
            data-qatag="rowTotal"
            className={`${styles.row} ${styles.rowTotal}`}
          >
            <span data-qatag="label" className={styles.label}>
              Total
            </span>
            <div data-qatag="total" className={styles.total}>
              <span data-qatag="totalValue" className={styles.totalValue}>
                ${total}
              </span>
              <span data-qatag="frequency" className={styles.frequency}>
                Billed monthly
              </span>
            </div>
          </div>
        </div>

        <div data-qatag="description" className={styles.description}>
          <img
            data-qatag="detailsIcon"
            className={styles.detailsIcon}
            src={detailsIcon}
            alt=""
          />
          <p data-qatag="renewContainer">
            This plan will{" "}
            <span data-qatag="renew" className={styles.renew}>
              auto-renew every month for ${recurringCharge}
            </span>
            , unless cancelled before the next billing cycle. All prices are in
            USD.
          </p>
        </div>

        <AppButton
          data-qatag="cardFormButton"
          buttonStyle={AppButtonStyles.Green}
          className={styles.customButton}
          onClick={closeHandler}
        >
          <Typography
            data-qatag="buttonText"
            align="center"
            className={classes.buttonText}
            variant="body2"
          >
            Close
          </Typography>
        </AppButton>
      </div>
    </Modal>
  );
};

export default ReviewOrder;
