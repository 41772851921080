import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import backgroundSVG from "assets/img/bg-blue.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      fontWeight: 500,
      margin: "auto",
      [theme.breakpoints.up("sm")]: {
        width: "256px",
      },
      [theme.breakpoints.up("md")]: {
        width: "462px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "600px",
      },
    },
    legend: {
      margin: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "240px",
      },
    },
    backgroundContainer: {
      background: `url(${backgroundSVG})`,
      backgroundSize: "cover",
    },
  })
);

export default useStyles;
