import { useMemo, useState } from "react";
import { Box, Typography, Divider } from "@material-ui/core";
import { Tabs } from "solveiq.designsystem";
import { PaymentMethods, BillingHistory, MyPlan } from ".";
import { makeStyles, Theme } from "@material-ui/core/styles";
import billingIcon from "assets/img/billing/icn-billing-overview.svg";
import styles from "./Billing.module.css";

/* Custom Styles */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    boxShadow: "none",
    color: theme.palette.text.secondary,
    backgroundColor: "white",
  },

  tabs: {
    backgroundColor: "white",
    flexBasis: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 22,
    },
    [theme.breakpoints.up("sm")]: {
      order: 0,
      flexGrow: 1,
      flexBasis: "50%",
    },
  },

  logo: {
    order: -1,
    flex: "8%",
    height: 48,
    [theme.breakpoints.down("md")]: {
      flex: "100%",
      justifyContent: "center",
    },
  },

  logoText: {
    marginLeft: 16,
    fontWeight: 600,
  },

  searchIcon: {
    color: theme.palette.info.light,
  },

  textField: {
    width: 100,
    marginRight: 4,
    [theme.breakpoints.down("sm")]: {
      width: 70,
    },
    [theme.breakpoints.up("sm")]: {
      width: 90,
    },
  },

  dividerBottom: {
    order: 2,
    [theme.breakpoints.down("md")]: {
      marginTop: 16,
    },
  },
}));

const Billing: React.FC = () => {
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const tabs = useMemo(
    () => ["My Plan", "Payment Methods", "Billing History"],
    []
  );
  // Tab number to be displayed
  const [tabNumber, setTabNumber] = useState(0);

  return (
    <Box data-qatag="billingContainer">
      <Box data-qatag="container" className={styles.container}>
        <div data-qatag="content" className={styles.content}>
          <Tabs
            data-qatag="tabs"
            tabs={tabs}
            onChange={setTabNumber}
            controlledValue={tabNumber}
            defaultValue={0}
            customClasses={{
              root: classes.root,
              tabs: classes.tabs,
            }}
            className={classes.tabs}
          >
            {/* Logo section */}
            <Box
              data-qatag="logo"
              className={classes.logo}
              display="flex"
              alignItems="center"
            >
              <img
                data-qatag="logoImage"
                src={billingIcon}
                className={styles.logoImage}
                alt=""
              />
              <Typography
                data-qatag="logoText"
                className={classes.logoText}
                variant="h6"
              >
                Billing Overview
              </Typography>
            </Box>

            {/* Divider */}
            <Box
              data-qatag="dividerBottom"
              width="100%"
              className={classes.dividerBottom}
            >
              <Divider data-qatag="divider" />
            </Box>
            {/* My Plan Tab */}
            <MyPlan
              data-qatag="myPlanTab"
              tabNumber={tabNumber}
              tabIndex={0}
              setTabNumber={setTabNumber}
            />
            {/* Payment Methods Tab */}
            <PaymentMethods
              data-qatag="paymentMethodsTab"
              tabNumber={tabNumber}
              tabIndex={1}
            />
            {/* Billing History Tab*/}
            <BillingHistory
              data-qatag="billingHistoryTab"
              tabNumber={tabNumber}
              tabIndex={2}
            />
          </Tabs>
        </div>
      </Box>
    </Box>
  );
};

export default Billing;
