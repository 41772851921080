import { IDeviceInfo } from "model/scan/DeviceInfo";
import { useDispatch, useSelector } from "react-redux";
import { openContactModal, selectDriverUpdateMap } from "./DriverSlice";
import {
  driverInstallRequested,
  requestDownloadCancel,
  requestDriverDownloads,
} from "./Thunks";
import { DeviceEntryView } from "./DeviceEntryView";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { IDriverUpdateModel } from "model/driver/DriverUpdateModel";
import React from "react";
import { DeviceEntryViewMobile } from "./DeviceEntryViewMobile";
import { RootState } from "app/store";

//#region Component Props
export interface IDeviceEntryViewProps {
  device: IDeviceInfo;
  driverUpdateModel: IDriverUpdateModel | null;
  onDownload: () => void;
  onInstall: () => void;
  onAbout: () => void;
  onDownloadCancel: () => void;
  onDownloadRetry: () => void;
}

export interface IDeviceEntryProps {
  device: IDeviceInfo;
  statusMapSelector: (state: RootState) => IDriverUpdateModel[];
}

//#endregion Component Props

//#region Component
export const DeviceEntry: React.FC<IDeviceEntryProps> = ({
  device,
  statusMapSelector,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const statusMap = useSelector(statusMapSelector);

  let model = null;
  if (device.hasUpdateAvailable) {
    model = statusMap.find((u) => u.driverId === device.latesetDriverGuid);
    if (model == null) {
      // throw new Error(
      //   "Unable to find an update status for device: " +
      //     device.deviceID +
      //     " that has an update available"
      // );
      return null;
    }
  }

  const onInstall = () => {
    dispatch(driverInstallRequested(device.deviceID));
  };

  const onDownload = () => {
    dispatch(requestDriverDownloads([device.deviceID]));
  };

  const onAbout = () => {
    dispatch(openContactModal(device.deviceID));
  };

  const onDownloadCancel = () => {
    dispatch(requestDownloadCancel(device.latesetDriverGuid));
  };

  return isMobile ? (
    <DeviceEntryViewMobile
      data-qatag="driver.device_view.device_entry.mobile"
      device={device}
      driverUpdateModel={model}
      onInstall={onInstall}
      onDownload={onDownload}
      onAbout={onAbout}
      onDownloadCancel={onDownloadCancel}
      onDownloadRetry={onDownload}
    />
  ) : (
    <DeviceEntryView
      data-qatag="driver.device_view.device_entry"
      device={device}
      driverUpdateModel={model}
      onInstall={onInstall}
      onDownload={onDownload}
      onAbout={onAbout}
      onDownloadCancel={onDownloadCancel}
      onDownloadRetry={onDownload}
    />
  );
};
//#endregion Component
