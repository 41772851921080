import { Typography, Box, Divider } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import editIcon from "assets/img/billing/icn-edit-cc.svg";
import styles from "./EditPaymentMethod.module.css";
import { CardForm } from ".";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";

export interface IEditPaymentMethod {
  /* Email Address */
  emailAddress?: string;
  /* Name as it appears on card */
  name?: string;
  /* Phone Number */
  phoneNumber?: string;
  /* Company Name */
  companyName?: string;
  /* Card Number */
  cardNumber?: string;
  /* Month */
  monthExpiration?: number;
  /* Year */
  yearExpiration?: number;
  /* CVC */
  securityCode?: string;
  /* Zip Code */
  zipCode?: string;
  /* Country */
  country?: string;
}

/* Custom style for Dialog */
const myStyles = makeStyles((theme: Theme) => ({
  paper: { maxWidth: "724px", width: "100%", height: "740px" },
  titleText: {
    color: theme.palette.secondary.light,
    fontWeight: 600,
    marginLeft: 8,
  },
}));

const AddPaymentMethod: React.FC<IEditPaymentMethod> = (props) => {
  // Custom styles
  const classes = myStyles();

  return (
    <Box
      data-qatag="newContainer"
      display="flex"
      flexWrap="wrap"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="column"
      width="100%;"
    >
      <Box
        data-qatag="box_breadcrumbs_container"
        mt={3}
        mb={3}
        width="90%"
        maxWidth="1064px"
      >
        <Breadcrumbs
          data-qatag="optimizationBreadcrumbs"
          pathsMap={{
            optimizations: "App Optimization",
          }}
        />
      </Box>
      <Box data-qatag="container" className={styles.container}>
        <Box data-qatag="content" className={styles.content}>
          {/* Title */}
          <Box
            data-qatag="titleEditPayment"
            className={styles.titleEditPayment}
            display="flex"
            alignItems="center"
          >
            <img
              data-qatag="titleIcon"
              className={styles.titleIcon}
              src={editIcon}
              alt="wire-frame"
            />
            <Typography
              data-qatag="titleText"
              variant="h6"
              className={classes.titleText}
              color="primary"
            >
              Edit Payment Method
            </Typography>
          </Box>
          {/* Divider */}
          <Box data-qatag="divider" mt={2} className={styles.divider}>
            <Divider data-qatag="dividerItem" />
          </Box>
          <CardForm data-qatag="cardForm" {...props} />
        </Box>
      </Box>
    </Box>
  );
};

export default AddPaymentMethod;
