import { useIntl, FormattedMessage } from "react-intl";
import { Typography, Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  AppButton,
  Panel,
  AppButtonStyles,
  Spinner,
} from "solveiq.designsystem";
import { ITeamMemberTableProps, TeamMemberTable } from "./TeamMemberTable";
import headerBackground1200 from "assets/img/team/headerBackground1200px.svg";
import headerBackground1024 from "assets/img/team/headerBackground1024px.svg";
import headerBackground768 from "assets/img/team/headerBackground768px.svg";
import headerBackground360 from "assets/img/team/headerBackground360px.svg";
import headerImage from "assets/img/team/headerImage.png";
import styles from "./Team.module.css";
import { ITeamMemberTableRowProps } from "./TeamMemberTableRow";
import defaultImage from "assets/img/team/avatar-default.svg";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "app/store";
import { IThunkStatus, ReducerStatus } from "model/IReducerState";
import React, { useEffect, useState } from "react";
import {
  fetchOrganizationUsers,
  fetchUsersOnlineStatus,
  fetchUsersMachine,
  selectStatus as selectUserStatus,
  selectUsers,
} from "model/user/UserSlice";
import AddTeamMemberModal, { IAddTeamMemberModal } from "./addTeamMemberModal";
import {
  fetchOrganizationLicenses,
  ILicenseData,
  selectLicenses,
  selectStatus as selectLicenseStatus,
} from "model/license/LicenseSlice";
import IUser from "model/user/IUser";
import { selectAddingTeamMember, setAddingTeamMember } from "./TeamSlice";
import {
  fetchMyPlan,
  selectMyPlan,
  selectMyPlanStatus,
} from "features/Billing/MyPlan/MyPlanSlice";
import { IBillingSummary } from "features/Billing/MyPlan/";
import { useHistory } from "react-router-dom";
import { selectIsBeta, connectRemote } from "session/SessionSlice";
import { employeeDetailSelected } from "./employeeDetail/EmployeeDetailSlice";

export interface ITeamProps {
  usersSelector?: (state: RootState) => IUser[];
  licenseDataSelector?: (state: RootState) => ILicenseData;
  addingTeamMemberSelector?: (state: RootState) => boolean;
  usersStatusSelector?: (state: RootState) => IThunkStatus;
  licensesStatusSelector?: (state: RootState) => IThunkStatus;
  /* My plan summary */
  myPlanSelector?: (state: RootState) => IBillingSummary;
  /* My plan summary Request Status */
  myPlanStatusSelector?: (state: RootState) => ReducerStatus;
}

//placeholder for mock data
export interface ILicense {
  totalSeats: number;
  usedSeats: number;
  availableSeats: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    buttonText: {
      lineHeight: 1,
    },
  })
);

export const Team: React.FC<ITeamProps> = ({
  usersSelector = (state) => selectUsers(state),
  licenseDataSelector = (state) => selectLicenses(state),
  addingTeamMemberSelector = (state) => selectAddingTeamMember(state),
  usersStatusSelector = (state) => selectUserStatus(state),
  licensesStatusSelector = (state) => selectLicenseStatus(state),
  myPlanSelector = (state: RootState) => selectMyPlan(state),
  myPlanStatusSelector = (state: RootState) => selectMyPlanStatus(state),
}) => {
  /* Localization prefix */
  const { formatMessage } = useIntl();
  const prefix = "team.management";
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const usersStatus = useSelector(usersStatusSelector);
  const licensesStatus = useSelector(licensesStatusSelector);
  const users = useSelector(usersSelector);
  const licenseData = useSelector(licenseDataSelector);
  const isBeta = useSelector(selectIsBeta);
  const history = useHistory();

  const tableProps = useSelector(() => {
    const userRows = users?.map(
      (user): ITeamMemberTableRowProps => {
        // Find license of the user
        const activatedLicenses = licenseData?.activatedLicenses?.find(
          (license) => license.userID === user.id
        );

        const allocatedLicenses = licenseData?.allocatedLicenses.find(
          (license) => license.userID === user.id
        );

        const licenseStatus = activatedLicenses
          ? formatMessage({
              id: `${prefix}.activated`,
            })
          : allocatedLicenses
          ? formatMessage({
              id: `${prefix}.allocated`,
            })
          : formatMessage({
              id: `${prefix}.pending`,
            });

        const licenseDate = activatedLicenses
          ? activatedLicenses.timestamp
          : allocatedLicenses
          ? allocatedLicenses.timestamp
          : "";

        const licenseIsGood = activatedLicenses
          ? activatedLicenses.enabled
          : allocatedLicenses
          ? allocatedLicenses.enabled
          : false;

        return {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          licenseStatus,
          licenseDate,
          licenseIsGood,
          device: user?.machineDisplayName || "Unknown",
          enabled: user?.enabled,
          attributes: user?.attributes,
          isOnline: user?.isOnline,
          image: defaultImage,
          onDetailsClicked: async () => {
            // we need to await this since we need to be sure the state
            // has changed before trying to navigate to the employee detail
            // page
            await dispatch(employeeDetailSelected(user.id));
            history.push(`/employeeDetail/${user.id}`);
          },
        };
      }
    );

    const tmtProps: ITeamMemberTableProps = {
      teamMembers: userRows || [],
    };
    return tmtProps;
  });

  useEffect(() => {
    if (usersStatus[fetchOrganizationUsers.typePrefix] === ReducerStatus.Idle) {
      dispatch(fetchOrganizationUsers());
    }

    if (
      licensesStatus[fetchOrganizationLicenses.typePrefix] ===
      ReducerStatus.Idle
    ) {
      dispatch(fetchOrganizationLicenses());
    }

    // If licenses Status is success we can retrieve the online status of the users
    if (
      licensesStatus[fetchOrganizationLicenses.typePrefix] ===
      ReducerStatus.Succeeded
    ) {
      dispatch(fetchUsersOnlineStatus());
      dispatch(fetchUsersMachine());
    }
  }, [usersStatus, licensesStatus, dispatch]);

  const [showAddTeamMemberModal, setShowAddTeamMemberModal] = useState<boolean>(
    useSelector(addingTeamMemberSelector) ?? false
  );
  const onAddTeamMemberClick = () => {
    setShowAddTeamMemberModal(true);
  };

  const addTeamMemberModalProps: IAddTeamMemberModal = {
    isOpen: showAddTeamMemberModal,
    fieldNumbers: 1,
    step: 1,
    handleOnClose: () => {
      setShowAddTeamMemberModal(false);
      dispatch(setAddingTeamMember(false));
    },
  };

  /* My Plan detail */
  // const myPlanSummary = useSelector(myPlanSelector);

  /* My Plan Request Status */
  const requestStatusMyPlan = useSelector(myPlanStatusSelector);

  // Retrieve summary plan to determine seats length
  // useEffect(() => {
  //   if (ReducerStatus[requestStatusMyPlan] === ReducerStatus.Idle && !isBeta) {
  //     dispatch(fetchMyPlan());
  //   }
  // }, [dispatch, isBeta, requestStatusMyPlan]);

  // seats length in use
  const seatsAllocated = tableProps?.teamMembers?.filter(
    (teamMember) => teamMember?.enabled
  )?.length;

  // Seat count if the user is a beta I'll use this one
  const seatCount = licenseData?.provisionedLicenses?.[0]?.licenseCount;

  return (
    <div data-qatag="teamScreen" className={styles.teamScreen}>
      <div data-qatag="teamHeader" className={styles.teamHeader}>
        <div
          data-qatag="teamHeaderBackgroundContainer"
          className={styles.teamHeaderBackgroundContainer}
        >
          <img
            data-qatag="teamHeaderBackground1200"
            className={`${styles.teamHeaderBackground} ${styles.teamHeaderBackground1200}`}
            src={headerBackground1200}
            alt=""
          />
          <img
            data-qatag="teamHeaderBackground1024"
            className={`${styles.teamHeaderBackground} ${styles.teamHeaderBackground1024}`}
            src={headerBackground1024}
            alt=""
          />
          <img
            data-qatag="teamHeaderBackground768"
            className={`${styles.teamHeaderBackground} ${styles.teamHeaderBackground768}`}
            src={headerBackground768}
            alt=""
          />
          <img
            data-qatag="teamHeaderBackground360"
            className={`${styles.teamHeaderBackground} ${styles.teamHeaderBackground360}`}
            src={headerBackground360}
            alt=""
          />
        </div>
        <div
          data-qatag="teamHeaderContent"
          className={styles.teamHeaderContent}
        >
          <div
            data-qatag="teamHeaderImageContainer"
            className={styles.teamHeaderImageContainer}
          >
            <img
              data-qatag="teamHeaderImage"
              className={styles.teamHeaderImage}
              src={headerImage}
              alt=""
            />
          </div>
          <div
            data-qatag="teamHeaderTextContainer"
            className={styles.teamHeaderTextContainer}
          >
            <Box
              data-qatag="teamAddMembersBox"
              mb={2}
              className={styles.teamAddMembersBox}
            >
              <FormattedMessage
                data-qatag="teamAddMembersMessage"
                id={`${prefix}.add.members`}
              />
            </Box>
            You have {seatCount - seatsAllocated} of {seatCount} remaining team
            members you can add.
            <div
              data-qatag="teamHeaderButtonContainer"
              className={`${styles.teamHeaderButtonContainer} ${styles.mobile}`}
            >
              <AppButton
                data-qatag="teamHeaderActionButton"
                buttonStyle={AppButtonStyles.TransparentWhite}
                className={styles.teamHeaderActionButton}
                onClick={onAddTeamMemberClick}
              >
                <Typography
                  data-qatag="buttonText"
                  variant="body2"
                  className={classes.buttonText}
                >
                  <FormattedMessage
                    data-qatag="teamAddTeamMemberMessage"
                    id={`${prefix}.add.team.member`}
                  />
                </Typography>
              </AppButton>
            </div>
          </div>
          <div
            data-qatag="teamHeaderButtonContainer"
            className={styles.teamHeaderButtonContainer}
          >
            <AppButton
              data-qatag="teamHeaderActionButton"
              buttonStyle={AppButtonStyles.TransparentWhite}
              className={styles.teamHeaderActionButton}
              onClick={onAddTeamMemberClick}
            >
              <Typography
                data-qatag="buttonText"
                variant="body1"
                className={classes.buttonText}
              >
                <FormattedMessage
                  data-qatag="teamAddTeamMemberMessage"
                  id={`${prefix}.add.team.member`}
                />
              </Typography>
            </AppButton>
          </div>
        </div>
      </div>
      <Panel data-qatag="teamBody" className={styles.teamBody}>
        <TeamMemberTable data-qatag="teamTeamMemberTable" {...tableProps} />
      </Panel>
      <AddTeamMemberModal
        data-qatag="teamAddTeamMemberModal"
        {...addTeamMemberModalProps}
        usedSeats={seatsAllocated}
        seatCount={seatCount}
        isBeta={isBeta}
      />
      {/* Loading modal */}
      <Spinner
        data-qatag="loadingModal"
        isActive={
          ReducerStatus[requestStatusMyPlan] === ReducerStatus.Loading ||
          usersStatus[fetchOrganizationUsers.typePrefix] ===
            ReducerStatus.Loading
        }
        text="Loading..."
      />
    </div>
  );
};
