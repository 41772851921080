import Header from "./Header";
import Body from "./Body";
import Modal, { IMetric, IModal } from "./Modal";
import SoftwareBox, { ISoftwareBox } from "./SoftwareBox";
import { fetchJittriggersScan } from "./OptimizationSlice";
import Optimization from "./Optimization";
import Settings from "./Settings";
import TabOptimization, { ITabOptimization } from "./TabOptimization";
import EmptyState, { IEmptyState } from "./EmptyState";

export type { ISoftwareBox, IMetric, IModal, ITabOptimization, IEmptyState };
export {
  Header,
  Body,
  SoftwareBox,
  Modal,
  fetchJittriggersScan,
  Settings,
  TabOptimization,
  EmptyState,
};
export default Optimization;
