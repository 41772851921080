import { useIntl } from "react-intl";
import { Typography, Box } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Modal } from "solveiq.designsystem";
import styles from "./FileListDialog.module.css";
import headerImage from "assets/img/fileCleaning/icon-cache-cleanup.svg";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";
import { ICleanUpFileMessage } from "model/messaging/messages/FileCleanupMessages";
import { ViewHelpers } from "core/Helpers";

export interface IFileListDialogProps {
  isOpen: boolean;
  title: string;
  files: ICleanUpFileMessage[];
  closeDialogHandler: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileName: {
      color: theme.palette.secondary.light,
      lineHeight: 3,
    },
    buttonText: {
      lineHeight: 1,
    },
  })
);

export const FileListDialog: React.FC<IFileListDialogProps> = (props) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "fileCleaning.fileListDialog";
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  return (
    <Modal
      data-qatag="fileListDialog"
      open={props.isOpen}
      onClose={props.closeDialogHandler}
      className={styles.fileListDialog}
    >
      <div
        data-qatag="fileListDialogContent"
        className={styles.fileListDialogContent}
      >
        <img
          data-qatag="headerImage"
          src={headerImage}
          className={styles.headerImage}
          alt=""
        />
        <Typography data-qatag="fileListDialogTitleTypography" variant="h4">
          {props.title}
        </Typography>
        <Box data-qatag="fileListDialogDescriptionBox" mt={1} mb={2}>
          {formatMessage({
            id: `${prefix}.description`,
            defaultMessage: "The following will be cleaned",
          })}
        </Box>
        <div data-qatag="fileList" className={styles.fileList}>
          {props.files.map((file, index) => {
            return (
              <div
                data-qatag={`fileListEntry_${index}`}
                className={styles.fileListEntry}
                key={`file-list-entry-${file.FileName}-${file.Length}`}
              >
                <Typography
                  data-qatag={`fileName_${index}`}
                  className={classes.fileName}
                  /*onClick={() => alert("open file or whatever")}*/
                >
                  {file.FilePathName}
                </Typography>
                {ViewHelpers.GetReadableFileSizeString(file.Length)}
              </div>
            );
          })}
        </div>
        <AppButton
          data-qatag="cleanButton"
          dataQatag="fileListDialogCleanButton"
          buttonStyle={AppButtonStyles.Blue}
          onClick={props.closeDialogHandler}
          className={styles.cleanButton}
        >
          <Typography
            data-qatag="buttonText"
            variant="body2"
            className={classes.buttonText}
          >
            {formatMessage({
              id: `${prefix}.buttonText`,
              defaultMessage: "Back To Analysis",
            })}
          </Typography>
        </AppButton>
      </div>
    </Modal>
  );
};
