import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "siq-oidc-react";
import { Spinner } from "solveiq.designsystem";

export const Logout: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    const onLogout = async () => {
      await auth.signOutRedirect();
    };

    onLogout();
  }, []);
  return (
    <Spinner data-qatag="loadingSpinner" isActive text="Logging You Out..." />
  );
};
