import { Modal, Paper } from "@material-ui/core";
import { useAppDispatch } from "app/store";
import { useSelector } from "react-redux";
import {
  selectAllUUIDs,
  selectCurrentUuid,
  setCurrentUUID,
} from "session/SessionSlice";
import { Select, SelectOptionInterface } from "solveiq.designsystem";

export interface IUuidSelectorProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UuidSelector: React.FC<IUuidSelectorProps> = (props) => {
  const appDispatch = useAppDispatch();
  const currentUUID = useSelector(selectCurrentUuid);
  const allUuids = useSelector(selectAllUUIDs);

  const getUUIDs = () => {
    const options: SelectOptionInterface[] = [];
    allUuids.forEach((uuid) => {
      options.push({
        value: uuid,
        label: uuid,
      });
    });
    return options;
  };

  function getModalContent() {
    return (
      <Select
        data-qatag="uuidSelect"
        name="uuidSelect"
        onChange={handleChange}
        options={getUUIDs()}
        noneOption={false}
        defaultValue={currentUUID}
      />
    );
  }
  const handleChange = (uuid: string) => {
    appDispatch(setCurrentUUID(uuid));
  };

  return (
    <Modal
      data-qatag="UUIDContainer"
      open={props.isOpen}
      onClose={props.onClose}
      style={{
        width: "400px",
        marginTop: "30px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Paper
        data-qatag="selectUUIDContainer"
        style={{ padding: "20px", outline: "none" }}
      >
        <h3 data-qatag="selectUUIDText">Select UUID</h3>
        {getModalContent()}
      </Paper>
    </Modal>
  );
};
