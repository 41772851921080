import { FormattedMessage } from "react-intl";
import {
  Typography,
  Box,
  IconButton,
  Dialog,
  Divider,
} from "@material-ui/core";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";
import { makeStyles, Theme } from "@material-ui/core/styles";
import defaultGaming from "assets/icon/icn-gaming-default.svg";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./Modal.module.css";

export interface IMetric {
  title: string;
  metric: string;
}

export interface IModal {
  /* Software Title */
  title: string;
  /* Description of the software */
  subtitle: string;
  /* Metrics objects to present */
  metrics: IMetric[];
  /* Controls to open the modal */
  isOpen: boolean;
  /* Callback to update the isOpen on the parent*/
  handleOnClose: () => void;
  /* Image to be displayed */
  image: string | null;
}

/* Custom style for Dialog */
const useStyles = makeStyles((theme: Theme) => ({
  paper: { maxWidth: "588px", width: "100%", height: "616px", margin: 12 },
  buttonText: {
    lineHeight: 1,
  },
  titleText: {
    color: "#535A6A",
  },
  metricText: {
    color: theme.palette.secondary.light,
    width: 120,
    textAlign: "right",
    flexBasis: "40%",
  },
  metricContainer: {
    flexBasis: "100%",
    marginRight: 8,
  },
  metric: {
    flexBasis: "100%",
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    justifyContent: "center",
  },
  metricDescription: {
    color: "#757E95",
    wordWrap: "break-word",
  },
  locationText: {
    color: "#757E95",
    wordWrap: "break-word",
    maxWidth: "85%",
    margin: "auto",
  },
}));

const Modal: React.FC<IModal> = ({
  title,
  subtitle,
  metrics = [],
  isOpen = true,
  handleOnClose,
  image,
}) => {
  /* Localization prefix */
  const prefix = "software.optimization.modal";
  // Custom styles
  const classes = useStyles();

  return (
    <Dialog
      data-qatag="modalDetails"
      open={isOpen}
      onClose={handleOnClose}
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <div data-qatag="container" className={styles.container}>
        <div data-qatag="content" className={styles.content}>
          {/* Close Icon */}
          <div
            data-qatag="closeIconContainer"
            className={styles.closeIconContainer}
          >
            <IconButton
              data-qatag="closeIcon"
              aria-label="close"
              onClick={handleOnClose}
              className={styles.closeIcon}
            >
              <CloseIcon data-qatag="CloseIcon" />
            </IconButton>
          </div>

          {/* Title */}
          <Box data-qatag="TitleBox" textAlign="center">
            <img
              data-qatag="titleIcon"
              className={styles.titleIcon}
              src={image || defaultGaming}
              alt="wire-frame"
            />
            <Box data-qatag="titleTextBox" mt={2}>
              <Typography
                data-qatag="titleText"
                variant="h6"
                className={classes.titleText}
                color="primary"
              >
                {title}
              </Typography>
              <Box
                data-qatag="metricDescription"
                mt={1}
                className={classes.metricDescription}
              >
                {subtitle}
              </Box>
            </Box>
          </Box>

          {/* Metrics */}
          <Box
            data-qatag="MetricsBox"
            display="flex"
            mt={2}
            justifyContent="space-between"
            flexWrap="wrap"
          >
            {metrics?.slice(1)?.map(({ title, metric }) => (
              <Box data-qatag="metric" key={title} className={classes.metric}>
                <Box
                  data-qatag="titleBox"
                  display="flex"
                  justifyContent="center"
                  flexBasis="100%"
                  mt={2}
                >
                  <span data-qatag="metricText" className={classes.metricText}>
                    {title}
                  </span>
                  <Box data-qatag="metricDescriptionBox" ml={3} flexBasis="50%">
                    <span
                      data-qatag="metricDescription"
                      className={classes.metricDescription}
                    >
                      {metric ?? "Information no available"}
                    </span>
                  </Box>
                </Box>
              </Box>
            ))}
            {/* Divider */}
            <Box
              data-qatag="dividerBottom"
              width="100%"
              className={styles.dividerBottomModal}
              mt={2}
            >
              <Divider data-qatag="divider" />
            </Box>
          </Box>
          <Box
            data-qatag="metric"
            key={title}
            className={classes.metric}
            maxWidth="100%"
          >
            <Box
              data-qatag="titleBox"
              mt={2}
              textAlign="center"
              maxWidth="100%"
            >
              <span data-qatag="metricText" className={classes.metricText}>
                {metrics?.[0]?.title}
              </span>

              <Box data-qatag="locationTextBox" mt={1} maxWidth="100%">
                <span
                  data-qatag="locationText"
                  className={classes.locationText}
                >
                  {metrics?.[0]?.metric}
                </span>
              </Box>
            </Box>
          </Box>

          <Box
            data-qatag="closeButtonBox"
            width={1}
            textAlign="center"
            mt={2.5}
          >
            <AppButton
              data-qatag="closeButton"
              buttonStyle={AppButtonStyles.Green}
              className={`${styles.customButton}`}
              onClick={handleOnClose}
            >
              <Typography
                data-qatag="buttonText"
                align="center"
                className={classes.buttonText}
                variant="body2"
              >
                <FormattedMessage
                  data-qatag="closeText"
                  id={`${prefix}.close`}
                />
              </Typography>
            </AppButton>
          </Box>
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;
