import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IOrganization } from "../organization/IOrganization";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";

interface IOrganizationData {
  registrationKey: string;
  org: IOrganization | null;
}

export const fetchOrganizations = createAsyncThunk(
  "organizations/fetchOrganization",
  async () => {
    const response = await RESTGatewayAPI.get("/fakeApi/organization");
    return response.data.organizations;
  }
);

const initialState: IReducerState<IOrganizationData> = {
  data: { registrationKey: "", org: null },
  status: {
    [fetchOrganizations.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<IOrganization>) => {
      state.data = { ...state.data, org: action.payload };
    },
    setRegistrationKey: (state, action: PayloadAction<string>) => {
      state.data = { ...state.data, registrationKey: action.payload };
    },
  },
});

export const {
  setOrganization,
  setRegistrationKey,
} = organizationSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = (amount: number): AppThunk => dispatch => {
//   setTimeout(() => {
//     dispatch(set(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectOrganization = (state: RootState) => state.organization;

export default organizationSlice.reducer;
