import { ReactElement } from "react";
import { Typography, Box, makeStyles, createStyles } from "@material-ui/core";
import backgroundImage from "assets/img/bg-machine-picker-header-web.png";
import backgroundImage_laptop from "assets/img/bg-machine-picker-header-laptop.png";
import backgroundImage_tablet from "assets/img/bg-machine-picker-header-tablet.png";
import backgroundImage_mobile from "assets/img/bg-machine-picker-header-mobile.png";

export interface IMachinePickerHeader {
  /* Principal message of the header */
  message: string | ReactElement;
  /* Computer model, description of the image o the header */
  computerModel: string | ReactElement;
  headerImage: string;
}

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "center",
      backgroundImage: `url(${backgroundImage})`,
      width: "1075px",
      height: "183px",
      borderRadius: "14px",
      [theme.breakpoints.down("lg")]: {
        width: "960px",
        backgroundImage: `url(${backgroundImage_laptop})`,
      },
      [theme.breakpoints.down("md")]: {
        width: "720px",
        backgroundImage: `url(${backgroundImage_tablet})`,
      },
      [theme.breakpoints.down("sm")]: {
        width: "330px",
        height: "237px",
        backgroundImage: `url(${backgroundImage_mobile})`,
      },
    },
    content: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    message: {
      [theme.breakpoints.down("sm")]: {
        textAlignment: "center",
        fontSize: "14px",
      },
    },
    icon: {
      marginTop: theme.spacing(2),
      maxWidth: "152px",
      maxHeight: "128px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "112px",
        maxHeight: "100px",
      },
    },
    model: {
      [theme.breakpoints.down("sm")]: {
        textAlignment: "center",
      },
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
        marginTop: theme.spacing(2),
      },
    },
  })
);
//#endregion Component Styles

const MachinePickerHeader: React.FC<IMachinePickerHeader> = ({
  message,
  computerModel,
  headerImage,
}) => {
  const classes = useStyles();

  return (
    <div data-qatag="container" className={classes.container}>
      <div data-qatag="content" className={classes.content}>
        <img
          data-qatag="icon"
          className={classes.icon}
          src={headerImage}
          alt="Laptop"
        />
        <div data-qatag="message" className={classes.textContainer}>
          <Box data-qatag="messageContainer" mt={1}>
            <Typography
              data-qatag="textMessage"
              variant="h6"
              className={classes.message}
              color="primary"
            >
              {message}
            </Typography>
          </Box>
          <Box data-qatag="computerModelContainer" mt={1}>
            <Typography
              data-qatag="computerModelText"
              variant="h4"
              className={classes.model}
              color="textPrimary"
            >
              {computerModel}
            </Typography>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default MachinePickerHeader;
