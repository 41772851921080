import { Alert, AlertIcon } from "solveiq.designsystem";
import { useIntl } from "react-intl";

export interface IAlertSuccess {
  /* Controls to open the alert */
  isOpen: boolean;
  /* Callback to close the alert*/
  closeHandler: () => void;
}

const AlertSuccess: React.FC<IAlertSuccess> = ({ closeHandler, isOpen }) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "alert.add.team.members.success";

  return (
    <Alert
      data-qatag="alertSuccessAddMember"
      icon={AlertIcon.Team}
      title={formatMessage({
        id: `${prefix}.title`,
      })}
      text=""
      buttonText={formatMessage({
        id: `${prefix}.button`,
      })}
      approveHandler={closeHandler}
      isOpen={isOpen}
      closeHandler={closeHandler}
    />
  );
};

export default AlertSuccess;
