import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "model/user/UserSlice";
import organizationReducer from "model/organization/OrganizationSlice";
import machinePickerSlice from "features/MachinePicker/MachinePickerSlice";
import optimizationSlice from "features/Optimization/OptimizationSlice";
import licenseReducer from "model/license/LicenseSlice";
import scanReducer from "features/scan/ScanSlice";
import sessionReducer from "session/SessionSlice";
import fileCleanupReducer from "features/fileCleaning/FileCleaningSlice";
import driverReducer from "features/driverInstall/DriverSlice";
import deceptorProtectionReducer from "features/deceptorProtection/DeceptorProtectionSlice";
import billingReducer from "features/Billing/BillingSlice";
import PayPalSlice from "features/Billing/AddPaymentMethod/CheckoutSlice";
import roleReducer from "features/Billing/RoleSlice";
import historyBillingSlice from "features/Billing/BillingHistory/BillingHistorySlice";
import invoiceFileSlice from "features/Billing/BillingHistory/InvoiceFileSlice";
import myPlanSlice from "features/Billing/MyPlan/MyPlanSlice";
import billingQuoteSlice from "features/Billing/MyPlan/BillingQuoteSlice";
import servicesSummarySlice from "features/CancelFlow/ServiceSummarySlice";
import paymentMethodsSlice from "features/Billing/PaymentMethods/PaymentMethodsSlice";
import teamReducer from "features/teamManagement/TeamSlice";
import subscriptionSlice from "features/teamManagement/SubscriptionSlice";
import settingsReducer from "features/settings/SettingsSlice";

import agentReducer from "./Agent/AgentSlice";
import ticketsReducer from "features/tickets/TicketsSlice";
// import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";
import EmployeeDetailSlice from "features/teamManagement/employeeDetail/EmployeeDetailSlice";

// const sentryReduxEnhancer = Sentry.createReduxEnhancer({
//   // Optionally pass options
// });

export const store = configureStore({
  reducer: {
    users: userReducer,
    organization: organizationReducer,
    licenses: licenseReducer,
    machinePicker: machinePickerSlice,
    optimization: optimizationSlice,
    scan: scanReducer,
    fileCleanupScan: fileCleanupReducer,
    session: sessionReducer,
    driver: driverReducer,
    deceptorProtection: deceptorProtectionReducer,
    billing: billingReducer,
    tickets: ticketsReducer,
    role: roleReducer,
    team: teamReducer,

    settings: settingsReducer,
    // signalRHub: signalRHubReducer,
    agent: agentReducer,

    //Billing History Data
    receipts: historyBillingSlice,
    // sentryReduxEnhancer,
    // Should contain seatCount, unitPrice, Billing Dates, paymentMethod, part of billing view
    myPlanSummary: myPlanSlice,
    billingQuote: billingQuoteSlice,
    // Seats length from the subscription API
    subscription: subscriptionSlice,
    // Payment methods
    paymentMethods: paymentMethodsSlice,
    // Blob object to the invoice selected
    invoice: invoiceFileSlice,
    // PayPal Slice
    checkout: PayPalSlice,
    employeeDetail: EmployeeDetailSlice,
    // Service Summary data
    servicesSummary: servicesSummarySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["auth/setUser"],
        ignoredPaths: ["auth.data.user"],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// get a correctly typed dispatch
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
