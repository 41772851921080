import { Panel } from "solveiq.designsystem";
import {
  Box,
  createStyles,
  Dialog,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import gears from "assets/img/threeGears.svg";
import styles from "./DriverInstallScreen.module.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHardwareScan,
  selectAllDevices,
  selectDevicesWithUpdates,
  selectFetchHardwareScanStatus,
  selectOutOfDateDriversCount,
  selectScanStatus,
} from "features/scan/ScanSlice";
import { InstallConductor } from "./InstallConductor";
import { DriverList } from "./DriverList";
import { fetchDriverState } from "./Thunks";
import { DriverContactModal } from "./modals/DriverContactModal";
import {
  selectCurrentContactDeviceId,
  selectDriverStateStatus,
} from "./DriverSlice";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { IDeviceInfo } from "model/scan/DeviceInfo";
import {
  selectCurrentUser,
  selectIsRemote,
  selectUserDescriptor,
} from "session/SessionSlice";
import { RouterBreadcrumbs } from "features/teamManagement/employeeDetail/RouterBreadcrumbs";
import { ReducerStatus } from "model/IReducerState";
import { Loading } from "main/Loading";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    paper: { borderRadius: "6px" },
    container: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "1075px",
      width: "100%",
      padding: "20px",

      [theme.breakpoints.down("sm")]: {
        padding: "0px",
      },
    },
    headerPanel: {
      display: "flex",
      alignItems: "center",
      height: "155px",
      margin: "0px",
      marginTop: "20px",
      padding: "0px 44px",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        background: "transparent",
        margin: "0",
        padding: "25px",
      },
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: theme.spacing(2),
    },
    title: {
      marginBottom: "5px",
    },
    headerImage: {
      width: "120px",

      marginTop: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        alignSelf: "flex-start",
        width: "54px",
      },
    },
    subTitleCount: {
      color: "#f1582d",
      fontSize: "16px",
      fontWeight: 600,
    },
    subTitleCountGreen: {
      color: "#2ECC5F",
      fontSize: "16px",
      fontWeight: 600,
    },
  })
);
//#endregion Component Styles

//#region Component
export const DriverInstallScreen: React.FC = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  //const outOfDateDriverCount = useSelector(selectOutOfDateDriversCount);
  const allDevices = useSelector(selectAllDevices);
  const devicesWithUpdates = useSelector(selectDevicesWithUpdates);
  const outOfDateDriverCount = devicesWithUpdates.length;
  const contactModalDeviceId = useSelector(selectCurrentContactDeviceId);
  const isRemote = useSelector(selectIsRemote);
  const user = useSelector(selectCurrentUser);
  const userDescriptor = useSelector(selectUserDescriptor);
  const scanStatus = useSelector(selectFetchHardwareScanStatus);
  const driverStateStatus = useSelector(selectDriverStateStatus);

  useEffect(() => {
    dispatch(fetchHardwareScan());
    dispatch(fetchDriverState());
  }, [dispatch]);

  useEffect(() => {
    setIsFilterVisible(devicesWithUpdates.length > 0);
  }, [devicesWithUpdates]);

  const classes = useStyles();

  if (
    scanStatus === ReducerStatus.Loading ||
    driverStateStatus === ReducerStatus.Loading
  ) {
    return <Loading data-qatag="driver.loading" />;
  }

  return (
    <Box
      data-qatag="driver.screen"
      id="driver.screen"
      className={classes.container}
    >
      <InstallConductor data-qatag="driver.screen.install_conductor" />
      <Dialog
        data-qatag="driver.screen.contact_host"
        id="driver.screen.contact_host"
        open={contactModalDeviceId != null}
        maxWidth="md"
        classes={{ paper: classes.paper }}
      >
        {contactModalDeviceId != null && (
          <DriverContactModal data-qatag="driver.screen.contact_modal" />
        )}
      </Dialog>
      {isRemote ? (
        <RouterBreadcrumbs
          data-qatag="driver.screen.remoteBreadcrumbs"
          style={{ width: "100%", height: "42px", alignSelf: "center" }}
          descriptors={[
            {
              to: `/employeeDetail/${user?.id}`,
              label: userDescriptor,
            },
            {
              to: "/",
              label: "Device Updates",
            },
          ]}
        ></RouterBreadcrumbs>
      ) : (
        <Breadcrumbs
          data-qatag="driver.screen.breadcrumbs"
          pathsMap={{ drivers: "Device Updates" }}
        />
      )}

      <Panel
        data-qatag="driver.screen.header.root"
        className={classes.headerPanel}
      >
        <img
          data-qatag="driver.screen.header.image"
          id="driver.screen.header.image"
          src={gears}
          alt="Gears"
          className={classes.headerImage}
        />
        <Box
          data-qatag="driver.screen.header.title_container"
          id="driver.screen.header.title_container"
          className={classes.titleContainer}
        >
          <FormattedMessage
            data-qatag="driver.screen.header.title"
            id="driver.screen.header.title"
            defaultMessage="Device Updates"
          >
            {(text) => (
              <Typography
                data-qatag="driver.screen.header.title.text"
                id="driver.screen.header.title.text"
                variant="h4"
                className={classes.title}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>

          {devicesWithUpdates.length === 0 ? (
            <FormattedMessage
              data-qatag="driver.screen.header.subtitle.noUpdates"
              id="driver.screen.header.subtitle.noUpdates"
              defaultMessage="All devices are up-to-date"
            >
              {(text) => (
                <Typography
                  data-qatag="driver.screen.header.subtitle.noUpdats.text"
                  id="driver.screen.header.subtitle.noUpdates.text"
                  variant="body2"
                  color="primary"
                >
                  {text}
                </Typography>
              )}
            </FormattedMessage>
          ) : (
            <FormattedMessage
              data-qatag="driver.screen.header.subtitle"
              id="driver.screen.header.subtitle"
              defaultMessage="There {verb} {count} out-of-date device {noun} on your PC"
              values={{
                verb:
                  outOfDateDriverCount === 1
                    ? formatMessage({
                        id: `driver.screen.header.subtitle.is`,
                        defaultMessage: "is",
                      })
                    : formatMessage({
                        id: `driver.screen.header.subtitle.are`,
                        defaultMessage: "are",
                      }),
                count: (
                  <span
                    data-qatag="driver.screen.header.subtitleCount"
                    className={
                      outOfDateDriverCount === 0
                        ? classes.subTitleCountGreen
                        : classes.subTitleCount
                    }
                  >
                    {outOfDateDriverCount}
                  </span>
                ),
                noun:
                  outOfDateDriverCount === 1
                    ? formatMessage({
                        id: `driver.screen.header.subtitle.device`,
                        defaultMessage: "device",
                      })
                    : formatMessage({
                        id: `driver.screen.header.subtitle.devices`,
                        defaultMessage: "devices",
                      }),
              }}
            >
              {(text) => (
                <Typography
                  data-qatag="driver.screen.header.subtitle.text"
                  id="driver.screen.header.subtitle.text"
                  variant="body2"
                  color="primary"
                >
                  {text}
                </Typography>
              )}
            </FormattedMessage>
          )}
        </Box>
      </Panel>

      <DriverList
        data-qatag="driver.screen.driver_list"
        allDevices={allDevices}
        devicesWithUpdates={devicesWithUpdates}
        isDeviceFilterVisible={isFilterVisible}
        keys={[
          "friendlyName",
          "categoryName",
          "class",
          "description",
          "driverProvider",
        ]}
      />
    </Box>
  );
};
//#endregion Component
