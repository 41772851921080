import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useAuth } from "siq-oidc-react";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import { Loading } from "../main/Loading";
import {
  loginSuccessful,
  selectIsAdmin,
  selectLoginSuccessfulStatus,
  selectRedirectToContactAdmin,
} from "session/SessionSlice";
import { ReducerStatus } from "model/IReducerState";
import { ContactAdmin } from "main/ContactAdmin";

const SessionWrapper: React.FC = ({ children }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const loginStatus = useSelector(selectLoginSuccessfulStatus);
  const [uuidQS] = useState(
    new URLSearchParams(useLocation().search).get("uuid")
  );

  const redirectToContactAdmin = useSelector(selectRedirectToContactAdmin);
  const isAdmin = useSelector(selectIsAdmin);

  useEffect(() => {
    if (auth.userData) {
      dispatch(loginSuccessful({ user: auth.userData, uuid: uuidQS }));
      // type file says .email but actual object is .Email
      // since its a custom claim
      if (auth?.userData?.profile?.Email) {
        Sentry.setUser({ email: auth?.userData?.profile?.Email });
      }
    }
  }, [auth.userData, dispatch, uuidQS]);

  // keep from flashing the UI while the auth library is loading
  // and looking for cached creds
  if (auth.isLoading || loginStatus === ReducerStatus.Loading) {
    return <Loading data-qatag="sessionLoading" />;
  }

  // very unlikely but possible
  if (loginStatus === ReducerStatus.Failed) {
    // what do?
    <Redirect data-qatag="contactAdminRedirect" to="/contactAdmin" />;
  }

  return (
    <Switch data-qatag="OuterSwitch">
      <Route data-qatag="callback.redirect" path="/callback">
        {isAdmin && (
          <Redirect data-qatag="callback.redirect.admin" to="/team" />
        )}
        {!isAdmin && (
          <Redirect data-qatag="callback.redirect.employee" to="/status" />
        )}
      </Route>
      <Route data-qatag="contactAdminRoute" path="/contactAdmin">
        <ContactAdmin data-qatag="contactAdminComponent" />
      </Route>
      {redirectToContactAdmin && (
        <Redirect data-qatag="contactAdminRedirect" to="/contactAdmin" />
      )}
      {children}
    </Switch>
  );
};

export default SessionWrapper;
