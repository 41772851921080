import { useIntl } from "react-intl";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import { Modal } from "solveiq.designsystem";
import styles from "./AnalysisCompleteDialog.module.css";
import headerImage from "assets/img/icn-green-check.svg";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";

export interface IAnalysisCategory {
  name: string;
  size: string;
  files: number;
  onClick: () => void;
}

export interface IAnalysisCompleteDialogProps {
  isOpen: boolean;
  categories: IAnalysisCategory[];
  closeDialogHandler: () => void;
  cleanHandler: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoryText: {
      color: theme.palette.secondary.light,
    },
  })
);

export const AnalysisCompleteDialog: React.FC<IAnalysisCompleteDialogProps> = (
  props
) => {
  /* Custom styles to overwrite theme */
  const classes = useStyles();
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "fileCleaning.analysisCompleteDialog";

  const onCategoryClicked = (category: IAnalysisCategory) => {
    if (category.files > 0) {
      category.onClick();
    }
  };

  return (
    <Modal
      data-qatag="analysisCompleteDialog"
      open={props.isOpen}
      onClose={props.closeDialogHandler}
      className={styles.analysisCompleteDialog}
    >
      <div
        data-qatag="analysisCompleteDialogContent"
        className={styles.analysisCompleteDialogContent}
      >
        <img
          data-qatag="headerImage"
          src={headerImage}
          className={styles.headerImage}
          alt=""
        />
        <Typography
          data-qatag="analysisCompleteDialogTitleTypography"
          align="center"
          variant="h4"
        >
          {formatMessage({
            id: `${prefix}.title`,
            defaultMessage: "Analysis Complete",
          })}
        </Typography>
        <Box
          data-qatag="AnalysisCompleteDialogDescriptionBox"
          mt={1}
          mb={2}
          textAlign="center"
        >
          {formatMessage({
            id: `${prefix}.description`,
            defaultMessage: "The following locations will be cleaned.",
          })}
        </Box>
        <div data-qatag="analysis" className={styles.analysis}>
          {props.categories.map((category, index) => {
            return (
              <div
                data-qatag={`analysisCategory_${index}`}
                className={`${styles.analysisCategory} ${styles.categoryName} ${
                  category.files === 0 ? styles.noClick : ""
                }`}
                key={`analysis-category-${category.name}`}
              >
                <Box data-qatag={`categoryNameBox_${index}`} flexBasis={"33%"}>
                  <Typography
                    data-qatag={`categoryText_${index}`}
                    variant="body2"
                    className={classes.categoryText}
                    onClick={() => onCategoryClicked(category)}
                    align="left"
                  >
                    {category.name}
                  </Typography>
                </Box>
                <Box
                  data-qatag={`categorySizeBox_${index}`}
                  flexBasis={"33%"}
                  textAlign="center"
                >
                  {category.size}
                </Box>
                <Box data-qatag={`categoryFilesBox_${index}`} flexBasis={"33%"}>
                  <Typography
                    data-qatag={`categoryText_${index}`}
                    variant="body2"
                    className={classes.categoryText}
                    onClick={() => onCategoryClicked(category)}
                    align="right"
                  >
                    {category.files}{" "}
                    {formatMessage({
                      id: `${prefix}.files`,
                      defaultMessage: "files",
                    })}
                  </Typography>
                </Box>
              </div>
            );
          })}
        </div>
        <AppButton
          data-qatag="cleanButton"
          dataQatag="analysisCompleteDialogCleanButton"
          buttonStyle={AppButtonStyles.Green}
          onClick={props.cleanHandler}
          className={styles.cleanButton}
        >
          <Typography
            data-qatag="analysisCompleteDialogCleanTypography"
            variant="body2"
          >
            {formatMessage({
              id: `${prefix}.clean`,
              defaultMessage: "Clean Up Now",
            })}
          </Typography>
        </AppButton>
        <AppButton
          data-qatag="skipButton"
          dataQatag="analysisCompleteDialogSkipButton"
          buttonStyle={AppButtonStyles.LinkGray}
          onClick={props.closeDialogHandler}
          className={styles.skipButton}
        >
          <Typography
            data-qatag="analysisCompleteDialogCancelTypography"
            variant="body2"
          >
            {formatMessage({
              id: `${prefix}.cancel`,
              defaultMessage: "Cancel",
            })}
          </Typography>
        </AppButton>
      </div>
    </Modal>
  );
};
