import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
  AppButton,
  AppButtonStyles,
  ExpanderBlock,
} from "solveiq.designsystem";
import { IDeviceEntryViewProps } from "./DeviceEntry";
import { Updater } from "./Updater";

const useMobileStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      padding: theme.spacing(2),
    },
    deviceImage: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(0),
      maxHeight: "90px",
      maxWidth: "90px",
    },
    header_container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: "35px",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    friendlyName: {
      fontWeight: 600,
    },
    outOfDateText: {
      color: "#F1582D",
      fontWeight: "bold",
    },
    expander_content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    metaContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    metaValueContainer: {
      display: "flex",
    },
    metaValueText: {
      marginLeft: theme.spacing(1),
    },
    aboutButton_container: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    aboutText: {
      color: "#F1582D",
      fontWeight: 600,
    },
    updateContainer: {
      marginTop: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    divider: {
      width: "85%",
      marginLeft: "22px",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
  })
);

export const DeviceEntryViewMobile: React.FC<IDeviceEntryViewProps> = (
  props
) => {
  const mobileClasses = useMobileStyles();

  const image =
    props.device.deviceImageURI == null || props.device.deviceImageURI === ""
      ? props.device.logoBase64
      : props.device.deviceImageURI;

  return (
    <Paper
      data-qatag="driver.device_entry.mobile.root"
      id="driver.device_entry.mobile.root"
      className={mobileClasses.paper}
    >
      <img
        data-qatag="driver.device_entry.mobile.device_image"
        id="driver.device_entry.mobile.device_image"
        className={mobileClasses.deviceImage}
        src={image}
        alt={props.device.categoryName}
      />
      <Box
        data-qatag="driver.device_entry.mobile.expander.container"
        id="driver.device_entry.mobile.expander.container"
        alignSelf="stretch"
      >
        <ExpanderBlock
          data-qatag="driver.device_entry.mobile.expander"
          IsExpandedInitially
          IsEmpty={false}
          Header={
            <Box
              data-qatag="driver.device_entry.mobile.expander.header.container"
              id="driver.device_entry.mobile.expander.header.container"
              className={mobileClasses.header_container}
            >
              <Typography
                data-qatag="driver.device_entry.mobile.friendly_name.text"
                id="driver.device_entry.mobile.friendly_name.text"
                variant="h6"
                align="center"
                className={mobileClasses.friendlyName}
              >
                {props.device.friendlyName}
              </Typography>

              {props.device.hasUpdateAvailable &&
                props.driverUpdateModel != null && (
                  <FormattedMessage
                    data-qatag="driver.device_entry.mobile.header.out_of_date"
                    id="driver.device_entry.mobile.header.out_of_date"
                    defaultMessage="Out of date"
                  >
                    {(text) => (
                      <Typography
                        data-qatag="driver.device_entry.mobile.header.out_of_date.text"
                        id="driver.device_entry.mobile.header.out_of_date.text"
                        variant="body2"
                        classes={{ root: mobileClasses.outOfDateText }}
                      >
                        {text}
                      </Typography>
                    )}
                  </FormattedMessage>
                )}
            </Box>
          }
          HeaderStyle={{ margin: "0px", padding: "0px" }}
          RootStyle={{ margin: "0px", padding: "0px" }}
          ButtonStyle={{
            alignSelf: "flex-start",
            marginTop: "5px",
            cursor: "pointer",
          }}
        >
          <Box
            data-qatag="driver.device_entry.mobile.content.meta.container"
            id="driver.device_entry.mobile.content.meta.container"
            className={mobileClasses.expander_content}
          >
            <Box
              data-qatag="driver.device_entry.mobile.content.meta.installed_version_container"
              id="driver.device_entry.mobile.content.meta.installed_version_container"
              className={mobileClasses.metaValueContainer}
            >
              <FormattedMessage
                data-qatag="driver.device_entry.mobile.content.meta.installed_version"
                id="driver.device_entry.mobile.content.meta.installed_version"
                defaultMessage="Installed Version: "
              >
                {(text) => (
                  <Typography
                    data-qatag="driver.device_entry.mobile.content.meta.installed_version.text"
                    id="driver.device_entry.mobile.content.meta.installed_version.text"
                    variant="body2"
                    color="primary"
                  >
                    {text}
                  </Typography>
                )}
              </FormattedMessage>
              <Typography
                data-qatag="driver.device_entry.mobile.content.meta.installed_version.value"
                id="driver.device_entry.mobile.content.meta.installed_version.value"
                variant="body2"
                color="primary"
                classes={{ root: mobileClasses.metaValueText }}
              >
                {props.device.installedDriverVersion}
              </Typography>
            </Box>
            <Box
              data-qatag="driver.device_entry.mobile.content.meta.latest_driver_date_container"
              id="driver.device_entry.mobile.content.meta.latest_driver_date_container"
              className={mobileClasses.metaValueContainer}
            >
              <FormattedMessage
                data-qatag="driver.device_entry.mobile.content.meta.latest_driver_date"
                id="driver.device_entry.mobile.content.meta.latest_driver_date"
                defaultMessage="Latest Driver Date: "
              >
                {(text) => (
                  <Typography
                    data-qatag="driver.device_entry.mobile.content.meta.latest_driver_date.text"
                    id="driver.device_entry.mobile.content.meta.latest_driver_date.text"
                    variant="body2"
                    color="primary"
                  >
                    {text}
                  </Typography>
                )}
              </FormattedMessage>
              <Typography
                data-qatag="driver.device_entry.mobile.content.meta.latest_driver_date.value"
                id="driver.device_entry.mobile.content.meta.latest_driver_date.value"
                variant="body2"
                color="primary"
                classes={{ root: mobileClasses.metaValueText }}
              >
                {props.device.latestDriverDate}
              </Typography>
            </Box>

            <Box
              data-qatag="driver.device_entry.mobile.content.meta.driver_size_container"
              id="driver.device_entry.mobile.content.meta.driver_size_container"
              className={mobileClasses.metaValueContainer}
            >
              <FormattedMessage
                data-qatag="driver.device_entry.mobile.content.meta.driver_size"
                id="driver.device_entry.mobile.content.meta.driver_size"
                defaultMessage="File Size: "
              >
                {(text) => (
                  <Typography
                    data-qatag="driver.device_entry.mobile.content.meta.driver_size.text"
                    id="driver.device_entry.mobile.content.meta.driver_size.text"
                    variant="body2"
                    color="primary"
                  >
                    {text}
                  </Typography>
                )}
              </FormattedMessage>
              <Typography
                data-qatag="driver.device_entry.mobile.content.meta.driver_size.value"
                id="driver.device_entry.mobile.content.meta.driver_size.value"
                variant="body2"
                color="primary"
                classes={{ root: mobileClasses.metaValueText }}
              >
                {props.device.latestDriverFileSize}
              </Typography>
            </Box>

            <Box
              data-qatag="driver.device_entry.mobile.content.aboutButton_container"
              id="driver.device_entry.mobile.content.aboutButton_container"
              className={mobileClasses.aboutButton_container}
            >
              <AppButton
                data-qatag="driver.device_entry.mobile.content.aboutButton"
                id="driver.device_entry.mobile.content.aboutButton"
                buttonStyle={AppButtonStyles.LinkBlue}
                onClick={props.onAbout}
              >
                <FormattedMessage
                  data-qatag="driver.device_entry.mobile.content.aboutButton.content"
                  id="driver.device_entry.mobile.content.aboutButton.content"
                  defaultMessage="Question About This Device"
                >
                  {(text) => (
                    <Typography
                      data-qatag="driver.device_entry.mobile.content.aboutButton.content.text"
                      id="driver.device_entry.mobile.content.aboutButton.content.text"
                      variant="body1"
                      className={mobileClasses.aboutText}
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
              </AppButton>
            </Box>
          </Box>
        </ExpanderBlock>
        <Divider
          data-qatag="driver.device_entry.mobile.divider"
          id="driver.device_entry.mobile.divider"
          className={mobileClasses.divider}
        />
        {props.device.hasUpdateAvailable && props.driverUpdateModel && (
          <Box
            data-qatag="driver.device_entry.mobile.content.update_container"
            id="driver.device_entry.mobile.content.update_container"
            className={mobileClasses.updateContainer}
          >
            <Updater
              data-qatag="driver.device_entry.mobile.content.updater"
              statusModel={props.driverUpdateModel}
              onDownload={props.onDownload}
              onUpdate={props.onInstall}
              onDownloadCancel={props.onDownloadCancel}
              onDownloadRetry={props.onDownloadRetry}
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
};
