import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, IconButton, Icon, Divider } from "@material-ui/core";
import downloadIcon from "assets/icon/icn-download-pdf.svg";
import {
  Accordion,
  AppButton as Button,
  AppButtonStyles,
  Spinner,
  Alert,
  AlertIcon,
} from "solveiq.designsystem";

import calendarIcon from "assets/icon/icn-date.svg";
import styles from "./Invoice.module.css";
import { Fragment, useEffect } from "react";
import { IReceipt } from "./types";
import {
  fetchInvoice,
  resetFetch,
  getFetchStatus,
  // getBlobObject,
} from "./InvoiceFileSlice";
import { RootState } from "app/store";
import { ReducerStatus } from "model/IReducerState";
import { useDispatch, useSelector } from "react-redux";

export interface IInvoice {
  info: IReceipt;
  index: number;
  /* Fetch status blob object to generate invoice */
  fetchStatusSelector?: (state: RootState) => ReducerStatus;
  /* Blob object to generate the PDF */
  blobObjectSelector?: (state: RootState) => Blob;
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      color: theme.palette.secondary.light,
      fontWeight: 600,
    },
    buttonText: {
      lineHeight: 1,
    },
    paymentContainer: {
      textAlign: "center",
      width: "100%",
      display: "flex",
      alignItems: "center",
      height: 80,
      padding: "20px 32px 20px 20px",
      [theme.breakpoints.down("md")]: {
        padding: "20px 10px 20px 8px",
        height: 72,
      },
    },
    downloadIcon: {
      width: 30,
      height: 30,
      marginTop: -16,
    },
    downloadLabel: {
      fontStyle: "italic",
      marginTop: -12,
    },
  })
);

const Invoice: React.FC<IInvoice> = ({
  info,
  index,
  fetchStatusSelector = (state: RootState) => getFetchStatus(state),
  // blobObjectSelector = (state: RootState) => getBlobObject(state),
}) => {
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const dispatch = useDispatch();

  // Date to format from SQL Server format
  const date = new Date(info.invoiceDate);
  const dateToDisplay = `${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;

  /* Payment Methods Request Status */
  const fetchStatus = useSelector(fetchStatusSelector);

  return (
    <Fragment key={info.invoiceDate}>
      <div data-qatag="invoiceRow" className={styles.invoiceRow}>
        <Box
          data-qatag="invoiceContainer"
          key={info.invoiceDate}
          mt={index % 2 !== 0 ? 0 : 2}
          mb={index % 2 !== 0 ? 0 : 2}
          className={`${classes.paymentContainer} ${
            index % 2 !== 0 ? styles.coloredRow : ""
          }`}
        >
          <Box
            data-qatag="dateTextContainer"
            display="flex"
            alignItems="center"
            className={styles.dateTextContainer}
          >
            <img
              data-qatag="calendarIcon"
              className={styles.calendarIcon}
              src={calendarIcon}
              alt="calendar"
            />
            <Box
              data-qatag="dateText"
              className={`${classes.titleText} ${styles.dateText}`}
            >
              {dateToDisplay}
            </Box>
          </Box>
          <Box data-qatag="totalText" className={styles.totalText}>
            ${info.paymentAmount.toFixed(2)}
          </Box>
          <Box data-qatag="planText" className={styles.planText}>
            Team Plan
          </Box>

          {/* Actions */}
          <Box
            data-qatag="downloadContainer"
            className={styles.downloadContainer}
            onClick={() => {
              dispatch(
                fetchInvoice({
                  fileId: info?.invoiceFiles?.[0]?.pdfFileUrl,
                  invoiceName: dateToDisplay,
                })
              );
            }}
          >
            <IconButton data-qatag="DownloadButton" aria-label="Download">
              <Icon
                data-qatag="downloadIconContainer"
                className={`MuiSelect-icon MuiSvgIcon-root ${classes.downloadIcon}`}
              >
                <img data-qatag="downloadIcon" src={downloadIcon} alt="" />
              </Icon>
            </IconButton>
            <Typography
              data-qatag="downloadLabel"
              variant="body1"
              className={classes.downloadLabel}
            >
              Download
            </Typography>
          </Box>
        </Box>
      </div>
      {/* Accordion for mobile */}
      <div data-qatag="accordion" className={styles.accordion}>
        <Accordion
          data-qatag="accordionMobile"
          summary={
            <Box
              data-qatag="dateTextContainer"
              display="flex"
              alignItems="center"
              width="100%"
              className={styles.dateTextContainer}
            >
              <img
                data-qatag="calendarIcon"
                className={styles.calendarIcon}
                src={calendarIcon}
                alt="calendar"
              />
              <Box
                data-qatag="mobileDateText"
                className={`${classes.titleText} ${styles.dateText}`}
              >
                {dateToDisplay}
              </Box>
            </Box>
          }
          details={
            <Box data-qatag="totalContainerMobile" mt={2.75} width="100%">
              <Box data-qatag="totalTitleMobile">
                <b data-qatag="totalTitleMobileText">Total</b>
                <span
                  data-qatag="accordionTotalAmount"
                  className={styles.accordionTotalAmount}
                >
                  ${info?.paymentAmount?.toFixed(2)}
                </span>
              </Box>
              {/* Divider */}
              <Box data-qatag="dividerMobileContainer" width="100%" mt={2.75}>
                <Divider data-qatag="dividerMobile" />
              </Box>
              <Box data-qatag="planContainerMobile" mt={2.75}>
                <b data-qatag="planTextMobile">Plan</b>{" "}
                <span
                  data-qatag="accordionTotalAmount"
                  className={styles.accordionTotalAmount}
                >
                  Team Plan
                </span>
              </Box>
              {/* Divider */}
              <Box data-qatag="dividerMobileContainer" width="100%" mt={2.75}>
                <Divider data-qatag="dividerMobile" />
              </Box>
              <Box data-qatag="downloadButtonMobile" mt={2.75}>
                <Box data-qatag="downloadButtonContainer" textAlign="center">
                  <Button
                    data-qatag="downloadButtonMobile"
                    buttonStyle={AppButtonStyles.Green}
                    className={`${styles.customButton}`}
                    onClick={() => {
                      dispatch(
                        fetchInvoice({
                          fileId: info?.invoiceFiles?.[0]?.pdfFileUrl,
                          invoiceName: dateToDisplay,
                        })
                      );
                    }}
                  >
                    <Typography
                      data-qatag="buttonText"
                      align="center"
                      className={classes.buttonText}
                      variant="body2"
                    >
                      Download
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          }
        />
      </div>
      {/* Alert error */}
      <Alert
        data-qatag="alertError"
        icon={AlertIcon.Warning}
        title="Something went wrong"
        text="Error generating the Invoice"
        buttonText="Close"
        approveHandler={() => {
          dispatch(resetFetch());
        }}
        isOpen={ReducerStatus[fetchStatus] === ReducerStatus.Failed}
        closeHandler={() => {
          dispatch(resetFetch());
        }}
      />
      <Spinner
        data-qatag="loadingModal"
        isActive={ReducerStatus[fetchStatus] === ReducerStatus.Loading}
        text="Loading..."
      />
    </Fragment>
  );
};

export default Invoice;
