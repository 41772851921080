import { Typography, Box } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import headerIcon from "assets/icon/icn-device-driver.svg";
import styles from "./Header.module.css";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

// Override styles.
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      color: theme.palette.secondary.light,
    },
    indicator: {
      color: theme.palette.secondary.light,
    },
  })
);

export interface IHeaderOptimization {
  /* Software Gaming */
  appsNumber?: number;
  /* Title  */
  title?: string | React.ReactNode;
  /* Subtitle string or component */
  subtitle?: string | React.ReactNode;
}

const Header: React.FC<IHeaderOptimization> = ({
  appsNumber = 0,
  title,
  subtitle,
}) => {
  /* Custom styles to overwrite theme */
  const classes = useStyles();
  /* Localization prefix */
  const prefix = "header.app.optimization";

  return (
    <Box data-qatag="container" className={styles.container}>
      <div data-qatag="content" className={styles.content}>
        <img
          data-qatag="icon"
          className={styles.icon}
          src={headerIcon}
          alt="wire-frame"
        />
        <Box data-qatag="titleTextBox">
          <Typography
            data-qatag="titleText"
            variant="h4"
            className={classes.titleText}
          >
            {title ? (
              title
            ) : (
              <FormattedMessage data-qatag="titleText" id={`${prefix}.title`} />
            )}
          </Typography>
          <Box data-qatag="indicatorBox" mt={1} mb={1}>
            {subtitle ? (
              subtitle
            ) : (
              <FormattedMessage
                data-qatag="body.app.optimization.description"
                id="body.app.optimization.description"
                values={{
                  number: (
                    <span data-qatag="indicator" className={classes.indicator}>
                      {appsNumber}
                    </span>
                  ),
                }}
              />
            )}
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default Header;
