import { useIntl } from "react-intl";
import { Typography, Box } from "@material-ui/core";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";
import useStyles from "./useStylesDownloadView";
import addTeamIllustration from "assets/img/illustration-addteam.svg";
import styles from "./DownloadView.module.css";

export interface IDownloadView {
  /* User name. */
  name: string;
  /* Callback to download the software */
  downloadHandler: () => void;
}

const DownloadView: React.FC<IDownloadView> = ({ name, downloadHandler }) => {
  /* Localization function */
  const { formatMessage } = useIntl();
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  return (
    <Box
      data-qatag="downloadView.container"
      className={`${styles.container} ${classes.backgroundContainer}`}
      margin="auto"
      display="flex"
      alignItems="center"
      borderRadius={15}
    >
      <Box
        data-qatag="content"
        className={styles.content}
        margin="auto"
        alignItems="center"
        borderRadius={14}
      >
        {/* Illustration */}
        <Box data-qatag="illustration" mt={12} textAlign="center">
          <img
            data-qatag="addTeamIllustration"
            className={styles.addTeamIllustration}
            src={addTeamIllustration}
            alt=""
          />
        </Box>

        {/* Title */}
        <Box data-qatag="titleContainer" mt={4}>
          <Typography data-qatag="title" align="center" variant="h3">
            {`${formatMessage({
              id: "download.view.welcome",
            })} ${name}!`}
          </Typography>
        </Box>

        {/* Subtitle */}
        <Box data-qatag="subtitleContainer" mt={4}>
          <Typography
            data-qatag="subtitle"
            align="center"
            variant="h5"
            className={classes.subtitle}
          >
            {formatMessage({ id: "download.view.title" })}
          </Typography>
        </Box>

        {/* Legend */}
        <Box data-qatag="legendContainer" mt={4}>
          <Typography
            data-qatag="legend"
            align="center"
            variant="h6"
            className={classes.legend}
          >
            {formatMessage({ id: "download.view.legend" })}
          </Typography>
        </Box>

        {/* Button */}
        <Box data-qatag="buttonContainer" mt={6} textAlign="center">
          <AppButton
            data-qatag="customButton"
            buttonStyle={AppButtonStyles.Green}
            className={styles.customButton}
            onClick={downloadHandler}
          >
            <Typography data-qatag="buttonText" align="center">
              {formatMessage({ id: "download.view.download" })}
            </Typography>
          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};

export default DownloadView;
