import Cleave from "cleave.js/react";

const CardInput = (props: any) => {
  const { inputRef, ...rest } = props;
  return (
    <Cleave
      data-qatag="cardNumberBox"
      placeholder="Enter credit card number"
      options={{
        creditCard: true,
      }}
      htmlRef={inputRef}
      {...rest}
    />
  );
};

export default CardInput;
