import {
  AppButton,
  AppButtonStyles,
  Modal,
  Select,
} from "solveiq.designsystem";
import styles from "./ScheduledScanOptionsDialog.module.css";
import titleIcon from "assets/icon/icn-schedule-opt.svg";
import { FormattedMessage, useIntl } from "react-intl";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { updateUserSetting } from "./SettingsSlice";
import { startAllScans } from "features/scan/ScanSlice";
import { useDispatch } from "react-redux";
import {
  DayOfWeek,
  ScanPeriod,
} from "model/messaging/messages/AppSettingsMessages";
import { OptionInterface } from "solveiq.designsystem/lib/components/Select/Select";
import { useState } from "react";
import { UserSettingTypes } from "core/enumerations/UserSettingTypes";
import { useHistory } from "react-router-dom";

export enum ScanFrequencyValue {
  Monthly,
  Weekly,
  Disabled,
}

export interface IScheduledScanOptionsDialogProps {
  isOpen: boolean;
  closeDialogHandler: () => void;
  dayOfMonth: number;
  dayOfWeek: DayOfWeek;
  scanTime: string;
  scanEnabled: boolean;
  scanPeriod: ScanPeriod;
}

export const ScheduledScanOptionsDialog: React.FC<IScheduledScanOptionsDialogProps> = ({
  ...props
}) => {
  const { formatMessage } = useIntl();
  const prefix = "settingsScreen.scheduledScanDialog";

  const dispatch = useDispatch();
  const history = useHistory();
  const scanScreenPath = "/scan";

  const frequencyOptions: OptionInterface[] = [
    {
      value: ScanFrequencyValue.Monthly.toString(),
      label: formatMessage({
        id: `${prefix}.monthly`,
        defaultMessage: "Monthly",
      }),
    },
    {
      value: ScanFrequencyValue.Weekly.toString(),
      label: formatMessage({
        id: `${prefix}.weekly`,
        defaultMessage: "Weekly",
      }),
    },
    {
      value: ScanFrequencyValue.Disabled.toString(),
      label: formatMessage({
        id: `${prefix}.disabled`,
        defaultMessage: "Disabled",
      }),
    },
  ];
  const dayOfMonthOptions: OptionInterface[] = Array.from(
    { length: 31 },
    (v, k) => {
      return {
        value: (k + 1).toString(),
        label: (k + 1).toString(),
      };
    }
  );
  const dayOfWeekOptions: OptionInterface[] = [
    {
      value: DayOfWeek.Sunday.toString(),
      label: formatMessage({
        id: `days.sunday`,
        defaultMessage: "Sunday",
      }),
    },
    {
      value: DayOfWeek.Monday.toString(),
      label: formatMessage({
        id: `days.monday`,
        defaultMessage: "Monday",
      }),
    },
    {
      value: DayOfWeek.Tuesday.toString(),
      label: formatMessage({
        id: `days.tuesday`,
        defaultMessage: "Tuesday",
      }),
    },
    {
      value: DayOfWeek.Wednesday.toString(),
      label: formatMessage({
        id: `days.wednesday`,
        defaultMessage: "Wednesday",
      }),
    },
    {
      value: DayOfWeek.Thursday.toString(),
      label: formatMessage({
        id: `days.thursday`,
        defaultMessage: "Thursday",
      }),
    },
    {
      value: DayOfWeek.Friday.toString(),
      label: formatMessage({
        id: `days.friday`,
        defaultMessage: "Friday",
      }),
    },
    {
      value: DayOfWeek.Saturday.toString(),
      label: formatMessage({
        id: `days.saturday`,
        defaultMessage: "Saturday",
      }),
    },
  ];

  let defaultFrequency: ScanFrequencyValue;
  if (props.scanEnabled) {
    defaultFrequency =
      props.scanPeriod === ScanPeriod.Weekly
        ? ScanFrequencyValue.Weekly
        : ScanFrequencyValue.Monthly;
  } else {
    defaultFrequency = ScanFrequencyValue.Disabled;
  }

  const [frequency, setFrequency] = useState<ScanFrequencyValue>(
    defaultFrequency
  );
  const [dayOfMonth, setDayOfMonth] = useState<number>(props.dayOfMonth);
  const [dayOfWeek, setDayOfWeek] = useState<DayOfWeek>(props.dayOfWeek);
  const [scanTime, setScanTime] = useState<string>(props.scanTime);

  const onSaveClick = () => {
    dispatch(
      updateUserSetting({
        UpdatedSettings: [
          {
            SettingType: UserSettingTypes.EnableScheduledScans,
            SettingTypeName:
              UserSettingTypes[UserSettingTypes.EnableScheduledScans],
            NewSettingValue: frequency !== ScanFrequencyValue.Disabled,
            CurrentSettingValue: props.scanEnabled,
          },
          {
            SettingType: UserSettingTypes.ScheduledScanPeriod,
            SettingTypeName:
              UserSettingTypes[UserSettingTypes.ScheduledScanPeriod],
            NewSettingValue:
              frequency === ScanFrequencyValue.Weekly
                ? ScanPeriod.Weekly
                : ScanPeriod.Monthly,
            CurrentSettingValue: props.scanPeriod,
          },
          {
            SettingType: UserSettingTypes.ScheduledScanDay,
            SettingTypeName:
              UserSettingTypes[UserSettingTypes.ScheduledScanDay],
            NewSettingValue: dayOfWeek,
            CurrentSettingValue: props.dayOfWeek,
          },
          {
            SettingType: UserSettingTypes.ScheduledScanMonth,
            SettingTypeName:
              UserSettingTypes[UserSettingTypes.ScheduledScanMonth],
            NewSettingValue: dayOfMonth,
            CurrentSettingValue: props.dayOfMonth,
          },
          {
            SettingType: UserSettingTypes.ScheduledScanTime,
            SettingTypeName:
              UserSettingTypes[UserSettingTypes.ScheduledScanTime],
            NewSettingValue: scanTime,
            CurrentSettingValue: props.scanTime,
          },
        ],
      })
    );
    props.closeDialogHandler();
  };

  return (
    <Modal
      data-qatag="scheduledScanOptionsDialog"
      open={props.isOpen}
      onClose={props.closeDialogHandler}
      className={styles.scheduledScanOptionsDialog}
    >
      <div
        data-qatag="scheduledScanOptionsDialogContent"
        className={styles.scheduledScanOptionsDialogContent}
      >
        <div data-qatag="titleContainer" className={styles.titleContainer}>
          <img
            data-qatag="titleIcon"
            src={titleIcon}
            alt=""
            className={styles.titleIcon}
          />
          <Typography
            data-qatag="settingName"
            className={styles.title}
            variant="h6"
          >
            <FormattedMessage
              data-qatag="titleMessage"
              id={`${prefix}.title`}
              defaultMessage="Scheduled Scan Options"
            />
          </Typography>
        </div>
        <div data-qatag="controlContainer" className={styles.controlContainer}>
          <div
            data-qatag="frequencyContainer"
            className={styles.frequencyContainer}
          >
            <Typography
              data-qatag="frequencyLabelTypography"
              className={styles.frequencyLabel}
              variant="body2"
            >
              <FormattedMessage
                data-qatag="frequencyLabelMessage"
                id={`${prefix}.frequency`}
                defaultMessage="Scan Frequency"
              />
            </Typography>
            <RadioGroup
              data-qatag="radioGroup"
              name={"frequency"}
              className={styles.frequencyRadioGroup}
              value={frequency}
              onChange={(event, value) => setFrequency(parseInt(value))}
            >
              <FormControlLabel
                data-qatag="monthlyFormLabel"
                value={ScanFrequencyValue.Monthly}
                control={<Radio data-qatag="monthlyRadio" />}
                label={formatMessage({
                  id: `${prefix}.monthly`,
                  defaultMessage: "Monthly",
                })}
              />
              <FormControlLabel
                data-qatag="weeklyFormLabel"
                value={ScanFrequencyValue.Weekly}
                control={<Radio data-qatag="weeklyRadio" />}
                label={formatMessage({
                  id: `${prefix}.weekly`,
                  defaultMessage: "Weekly",
                })}
              />
              <FormControlLabel
                data-qatag="weeklyFormLabel"
                value={ScanFrequencyValue.Disabled}
                control={<Radio data-qatag="weeklyRadio" />}
                label={formatMessage({
                  id: `${prefix}.disabled`,
                  defaultMessage: "Disabled",
                })}
              />
            </RadioGroup>
            <div
              data-qatag="frequencySelectContainer"
              className={styles.frequencySelectContainer}
            >
              <Select
                data-qatag="frequencySelect"
                options={frequencyOptions}
                noneOption={false}
                defaultValue={frequency.toString()}
                onChange={(value) => setFrequency(parseInt(value))}
              />
            </div>
          </div>
          {frequency !== ScanFrequencyValue.Disabled && (
            <div
              data-qatag="dayAndTimeContainer"
              className={styles.dayAndTimeContainer}
            >
              <div data-qatag="dayContainer" className={styles.dayContainer}>
                {frequency === ScanFrequencyValue.Monthly && (
                  <div
                    data-qatag="dayOfMonthContainer"
                    className={styles.dayOfMonthContainer}
                  >
                    <Typography
                      data-qatag="dayOfMonthLabelTypography"
                      className={styles.dayOfMonthLabel}
                      variant="body2"
                    >
                      <FormattedMessage
                        data-qatag="dayOfMonthLabelMessage"
                        id={`${prefix}.dayOfMonth`}
                        defaultMessage="Scan Day of Month"
                      />
                    </Typography>
                    <Select
                      data-qatag="dayOfMonthSelect"
                      options={dayOfMonthOptions}
                      onChange={(value) => setDayOfMonth(parseInt(value))}
                      defaultValue={dayOfMonth.toString()}
                      noneOption={false}
                    />
                  </div>
                )}
                {frequency === ScanFrequencyValue.Weekly && (
                  <div
                    data-qatag="dayOfWeekContainer"
                    className={styles.dayOfWeekContainer}
                  >
                    <Typography
                      data-qatag="dayOfWeekLabelTypography"
                      className={styles.dayOfWeekLabel}
                      variant="body2"
                    >
                      <FormattedMessage
                        data-qatag="dayOfWeekLabelMessage"
                        id={`${prefix}.dayOfWeek`}
                        defaultMessage="Scan Day of Week"
                      />
                    </Typography>
                    <Select
                      data-qatag="dayOfWeekSelect"
                      options={dayOfWeekOptions}
                      onChange={(value) => setDayOfWeek(parseInt(value))}
                      defaultValue={dayOfWeek?.toString() ?? DayOfWeek.Monday}
                      noneOption={false}
                    />
                  </div>
                )}
              </div>
              <div data-qatag="timeContainer" className={styles.timeContainer}>
                <Typography
                  data-qatag="dayOfMonthLabelTypography"
                  className={styles.dayOfMonthLabel}
                  variant="body2"
                >
                  <FormattedMessage
                    data-qatag="timeLabelMessage"
                    id={`${prefix}.time`}
                    defaultMessage="Scan Time (Approx.)"
                  />
                </Typography>
                <TextField
                  data-qatag="time"
                  className={styles.timeInput}
                  id="time"
                  type="time"
                  defaultValue={scanTime}
                  onChange={(e) => setScanTime(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </div>
            </div>
          )}
          <AppButton
            data-qatag="saveButton"
            buttonStyle={AppButtonStyles.Green}
            className={styles.saveButton}
            onClick={onSaveClick}
          >
            <Typography data-qatag="saveButtonTypography" variant="body2">
              <FormattedMessage
                data-qatag="saveButtonMessage"
                id={`${prefix}.saveButton`}
                defaultMessage="Save"
              />
            </Typography>
          </AppButton>
          <AppButton
            data-qatag="rescanButton"
            buttonStyle={AppButtonStyles.LinkGray}
            className={styles.rescanButton}
            onClick={() => history.push(scanScreenPath)}
          >
            <Typography data-qatag="rescanButtonTypography" variant="body2">
              <FormattedMessage
                data-qatag="rescanButtonMessage"
                id={`${prefix}.rescanButton`}
                defaultMessage="Rescan My PC Now"
              />
            </Typography>
          </AppButton>
        </div>
      </div>
    </Modal>
  );
};
