import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Typography } from "@material-ui/core";
import { useStyles } from "solveiq.designsystem";
import { Field } from "formik";
import { AlertDeleteField } from "features/customAlerts/AddTeamMember";
import { SimpleTextField } from "solveiq.designsystem";
import deleteIcon from "assets/icon/icn-delete.svg";
import styles from "./TeamMemberField.module.css";

export interface ITeamMemberField {
  /* Field ID */
  fieldId: string;
  /* Index ID to delete from the FieldArray */
  index: number;
  /* Callback to remove the index from the FieldArray */
  remove: (id: number) => void;
  /* Callback to delete the id */
  removeField: (id: string) => void;
  /* fieldsLength condition to show the remove */
  fieldsLength?: number;
}

const TeamMemberField: React.FC<ITeamMemberField> = ({
  fieldId,
  index,
  remove,
  removeField,
  fieldsLength = 1,
}) => {
  const [openModal, setOpenModal] = useState(false);
  /* Localization function and prefix */
  const prefix = "add.team.members.view";

  /* Custom style for Field component */
  const classes = useStyles();

  return (
    <div data-qatag="fieldRow" key={fieldId} className={styles.fieldRow}>
      <Field
        data-qatag="emailAddressContainer"
        component={SimpleTextField}
        name={`seats.${index}.email`}
        type="text"
        label={
          <FormattedMessage
            data-qatag="emailField"
            id={`${prefix}.email.address`}
          />
        }
        className={`${styles.field} ${classes.customField}`}
      />
      <Field
        data-qatag="nameContainer"
        component={SimpleTextField}
        name={`seats.${index}.name`}
        type="text"
        label={"First name Last name"}
        className={`${styles.field} ${classes.customField}`}
      />
      {fieldsLength > 1 ? (
        <div
          data-qatag="removeContainer"
          className={styles.removeContainer}
          onClick={() => {
            setOpenModal((prevState) => !prevState);
          }}
        >
          <img
            data-qatag="removeIcon"
            className={styles.removeIcon}
            src={deleteIcon}
            alt=""
          />
          <Typography
            data-qatag="textRemove"
            align="center"
            variant="body1"
            className={styles.textRemove}
          >
            <FormattedMessage data-qatag="deleteText" id={`${prefix}.delete`} />
          </Typography>
        </div>
      ) : (
        ""
      )}
      <div data-qatag="fieldBarContainer" className={styles.fieldBarContainer}>
        <hr data-qatag="fieldBar" className={styles.fieldBar} />
      </div>
      <AlertDeleteField
        data-qatag="AlertDeleteField"
        approveHandler={() => {
          remove(index);
          removeField(fieldId);
        }}
        closeHandler={() => {
          setOpenModal((prevState) => !prevState);
        }}
        isOpen={openModal}
      />
    </div>
  );
};

export default TeamMemberField;
