import React, { useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import styles from "./Dashboard.module.css";
import { BigBoxButton, IRouteState, Spinner } from "solveiq.designsystem";
import { Carousel } from "solveiq.designsystem";
import headerBackground from "assets/img/dashboard/headerBackground.svg";
import mobileHeaderBackground from "assets/img/dashboard/banner-darkteal.svg";
import orangeCircle from "assets/img/orangeCircle.svg";
import headerImage from "assets/img/dashboard/headerImage.png";
import helpImage from "assets/img/dashboard/needHelpImage.svg";
import ticketsImage from "assets/img/dashboard/ticketsImage.svg";
import tasksImage from "assets/img/dashboard/tasksImage.svg";
import { ICarouselItemProps } from "solveiq.designsystem";
import { Panel } from "solveiq.designsystem";
import { useHistory } from "react-router";
import { RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHardwareScan,
  selectIsScanAvailable,
  selectOutOfDateDriversCount,
} from "features/scan/ScanSlice";
import { setAddingTeamMember } from "features/teamManagement/TeamSlice";
import { useLocation } from "react-router-dom";
import IUser from "model/user/IUser";
import { selectCurrentUser, selectIsAdmin } from "session/SessionSlice";
import { Typography } from "@material-ui/core";
import {
  fetchTickets,
  selectOpenTicketCount,
} from "features/tickets/TicketsSlice";
import CongratsModal from "features/Congrats/CongratsModal";

export type DashboardProps = {
  userSelector?: (state: RootState) => IUser | null;
  isAdminSelector?: (state: RootState) => boolean;
  isScanAvailableSelector?: (state: RootState) => boolean;
  ticketCountSelector?: (state: RootState) => number;
};

export const Dashboard: React.FC<DashboardProps> = ({
  userSelector = selectCurrentUser,
  isAdminSelector = selectIsAdmin,
  isScanAvailableSelector = selectIsScanAvailable,
  ticketCountSelector = selectOpenTicketCount,
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "dashboard";
  const dispatch = useDispatch();
  const history = useHistory<IRouteState>();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(fetchHardwareScan());
    dispatch(fetchTickets());
  }, [dispatch]);

  const ticketsNeedAttention = useSelector(ticketCountSelector);
  const totalNeedAttention = ticketsNeedAttention;
  const user = useSelector(userSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isScanAvailable = useSelector(isScanAvailableSelector);

  const totalAttentionClass =
    totalNeedAttention > 0 ? styles.needsAttention : "";
  const ticketsAttentionClass =
    ticketsNeedAttention > 0 ? styles.needsAttention : "";

  const testCarouselItems: ICarouselItemProps[] = [
    {
      active: true,
      titleElement: (
        <Typography
          data-qatag=""
          variant="h1"
          className={`${styles.carouselItemTitle}`}
        >
          <FormattedMessage
            data-qatag=""
            id={`${prefix}.welcome`}
            defaultMessage="{userName}, Welcome To {solveIq}"
            values={{
              userName: (
                <span data-qatag="welcomeName" className={styles.welcomeName}>
                  {user?.firstName}
                </span>
              ),
              solveIq: (
                <span
                  data-qatag="welcomeSolveIq"
                  className={styles.welcomeSolveIq}
                >
                  Solve iQ!
                </span>
              ),
            }}
          />
        </Typography>
      ),
      descriptionText:
        "A one-click, all-inclusive health PC checkup for threats, updates and issues.",
      showButton: isAdmin,
      buttonClass: "transparentWhite",
      buttonText: "Add Team Member",
      descriptionClass: styles.carouselItemDescription,
      onButtonClick: () => {
        dispatch(setAddingTeamMember(true));
        history.push("/team", { from: pathname, friendlyName: "/Team" });
      },
    },
  ];

  const carouselItems = useSelector<RootState, ICarouselItemProps[]>(
    (state) => {
      //TODO: where does this come from?
      return testCarouselItems;
    }
  );

  const helpClick = () => {
    history.push("/help", { from: pathname, friendlyName: "/Dashboard" });
  };
  const ticketsClick = () => {
    history.push("/tickets", { from: pathname, friendlyName: "/Dashboard" });
  };
  const tasksClick = () => {
    history.push("/status", { from: pathname, friendlyName: "/Dashboard" });
  };

  return (
    <div data-qatag="dashboard" className={styles.dashboard}>
      <div data-qatag="dashboardHeader" className={styles.dashboardHeader}>
        <div
          data-qatag="dashboardHeaderBackgroundContainer"
          className={styles.dashboardHeaderBackgroundContainer}
        >
          <img
            data-qatag="dashboardHeaderBackground"
            className={styles.dashboardHeaderBackground}
            src={headerBackground}
            alt=""
          />
        </div>
        <div
          data-qatag="dashboardHeaderMobileBackgroundContainer"
          className={styles.dashboardHeaderMobileBackgroundContainer}
        >
          <img
            data-qatag="dashboardHeaderMobileBackground"
            className={styles.dashboardHeaderMobileBackground}
            src={mobileHeaderBackground}
            alt=""
          />
        </div>
        <div
          data-qatag="dashboardHeaderContent"
          className={styles.dashboardHeaderContent}
        >
          <div
            data-qatag="dashboardHeaderCarousel"
            className={styles.dashboardHeaderCarousel}
          >
            <Carousel
              data-qatag="dashboardCarousel"
              items={carouselItems}
              showPips={false}
            />
          </div>
          <div
            data-qatag="dashboardHeaderImageContainer"
            className={styles.dashboardHeaderImageContainer}
          >
            <img
              data-qatag="dashboardHeaderImage"
              className={styles.dashboardHeaderImage}
              src={headerImage}
              alt=""
            />
          </div>
        </div>
      </div>
      <Panel data-qatag="dashboardBody" className={styles.dashboardBody}>
        <div data-qatag="dashboardItems" className={styles.dashboardItems}>
          <BigBoxButton
            data-qatag=""
            dataQatag="dashboardButton1"
            titleClass={styles.bbbTitle}
            title={formatMessage({
              id: `${prefix}.need.help`,
              defaultMessage: "Need Help?",
            })}
            description={formatMessage({
              id: `${prefix}.help.today`,
              defaultMessage: "What can we help you with today?",
            })}
            image={helpImage}
            onClick={helpClick}
          />
          <BigBoxButton
            data-qatag=""
            dataQatag="dashboardButton2"
            titleClass={`${styles.bbbTitle} ${ticketsAttentionClass}`}
            title={formatMessage({
              id: `${prefix}.tickets`,
              defaultMessage: "Tickets",
            })}
            descriptionElement={
              <span
                className={styles.ticketsDescription}
                data-qatag="dashboardButton2Span"
              >
                {ticketsNeedAttention === 0 && (
                  <FormattedMessage
                    data-qatag="dashboardTicketsMessage"
                    id={`${prefix}.zeroTicketsDescription`}
                    defaultMessage="View your ticket history."
                  />
                )}
                {ticketsNeedAttention > 0 && (
                  <FormattedMessage
                    data-qatag="dashboardTicketsMessage"
                    id={`${prefix}.ticketsDescription`}
                    defaultMessage="There {verb} {amount} open {noun}."
                    values={{
                      verb:
                        ticketsNeedAttention === 1
                          ? formatMessage({
                              id: `${prefix}.ticketDescription.is`,
                              defaultMessage: "is",
                            })
                          : formatMessage({
                              id: `${prefix}.ticketDescription.are`,
                              defaultMessage: "are",
                            }),
                      amount: (
                        <span
                          data-qatag="ticketNumber"
                          className={`${styles.ticketNumber} ${ticketsAttentionClass}`}
                        >
                          {ticketsNeedAttention}
                        </span>
                      ),
                      noun:
                        ticketsNeedAttention === 1
                          ? formatMessage({
                              id: `${prefix}.ticketDescription.singularItem`,
                              defaultMessage: "ticket",
                            })
                          : formatMessage({
                              id: `${prefix}.ticketDescription.pluralItem`,
                              defaultMessage: "tickets",
                            }),
                    }}
                  />
                )}
              </span>
            }
            image={ticketsImage}
            onClick={ticketsClick}
            emblemImage={ticketsNeedAttention > 0 ? orangeCircle : undefined}
            emblemText={
              ticketsNeedAttention > 0
                ? ticketsNeedAttention.toString()
                : undefined
            }
          />
          <BigBoxButton
            data-qatag=""
            dataQatag="dashboardButton3"
            titleClass={`${styles.bbbTitle}`}
            title={formatMessage({
              id: `${prefix}.tasks`,
              defaultMessage: "System Status",
            })}
            image={tasksImage}
            onClick={tasksClick}
            descriptionElement={
              <span
                className={styles.tasksDescription}
                data-qatag="dashboardButton3Span"
              >
                <FormattedMessage
                  data-qatag="dashboardTasksMessage"
                  id={`${prefix}.tasksDescription`}
                  defaultMessage="View the status of your system"
                />
              </span>
            }
          />
        </div>
      </Panel>
      <Spinner
        data-qatag="dashboardSpinner"
        text="Loading..."
        isActive={!isScanAvailable}
      />
    </div>
  );
};
