export enum UserSettingTypes {
  // Scan Options
  UseWindowsUpdateScanner = 0,
  IncludeSystemDevices,
  IncludeUnpluggedDevices,

  // Schedule Scan Options
  ScheduledScanPeriod,
  ScheduledScanDay,
  ScheduledScanMonth,
  ScheduledScanTime,
  ScheduledScanType,
  ScheduledScanCategories,
  EnableScheduledScans,

  // Driver Download Settings
  DownloadFolder,
  MaxSimultaneousDownloads,

  // Notification Settings
  EnableAlerts,
  MaximumWeeklyAlerts,

  // User Profiles
  BrowsingState,
  ProductivityState,
  GamingState,

  // Windows startup options
  StartWithWindows,

  EnableDeceptorServices,
  OnlyDHCPEnabled,
  EnableDnsProtector,
}
