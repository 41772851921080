import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
  AppButton,
  AppButtonStyles,
  ExpanderBlock,
} from "solveiq.designsystem";
import { IDeviceEntryViewProps } from "./DeviceEntry";
import { Updater } from "./Updater";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    expanderRoot: {
      borderTop: "1px solid #E5E5E5",
      gridTemplateColumns: "max-content 1fr",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      paddingTop: theme.spacing(4),
    },
    outOfDateText: {
      color: "#F1582D",
      fontWeight: "bold",
    },
    metaValueText: {
      marginLeft: theme.spacing(1),
    },
    linkButton: {
      height: "40px",
    },
    linkText: {
      color: "#F1582D",
      fontWeight: 600,
    },
    deviceImage: {
      marginTop: "10px",
      marginLeft: "10px",
      maxWidth: "100px",
      maxHeight: "100px",
      minWidth: "100px",
    },
    expander_container: {
      flexGrow: 1,
      marginLeft: theme.spacing(4),
    },
    header_container: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(3),
      alignItems: "center",
    },
    content_container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginRight: theme.spacing(8),
    },
    meta_value_container: {
      display: "flex",
    },
  })
);
//#endregion Component Styles

//#region Component
export const DeviceEntryView: React.FC<IDeviceEntryViewProps> = (
  props: IDeviceEntryViewProps
) => {
  const classes = useStyles();

  const image =
    props.device.deviceImageURI == null || props.device.deviceImageURI === ""
      ? props.device.logoBase64
      : props.device.deviceImageURI;

  let deviceDescription: string = props.device.description;

  if (deviceDescription.length > 40) {
    deviceDescription = deviceDescription.slice(0, 40) + "...";
  }

  return (
    <Box
      data-qatag="driver.device_entry.root"
      id="driver.device_entry.root"
      display="grid"
      className={classes.expanderRoot}
    >
      <img
        data-qatag="driver.device_entry.device_image"
        id="driver.device_entry.device_image"
        className={classes.deviceImage}
        src={image}
        alt={props.device.categoryName}
      />
      <Box
        data-qatag="driver.device_entry.expander.container"
        id="driver.device_entry.expander.container"
        className={classes.expander_container}
      >
        <ExpanderBlock
          data-qatag="driver.device_entry.mobile.expander"
          IsExpandedInitially
          IsEmpty={false}
          Header={
            <Box
              data-qatag="driver.device_entry.expander.header.container"
              id="driver.device_entry.expander.header.container"
              className={classes.header_container}
            >
              <Box
                data-qatag="driver.device_entry.friendly_name_container"
                id="driver.device_entry.friendly_name_container"
              >
                <Typography
                  data-qatag="driver.device_entry.friendly_name.text"
                  id="driver.device_entry.friendly_name.text"
                  variant="h6"
                >
                  {props.device.friendlyName}
                </Typography>

                {props.device.hasUpdateAvailable &&
                  props.driverUpdateModel != null && (
                    <FormattedMessage
                      data-qatag="driver.device_entry.header.out_of_date"
                      id="driver.device_entry.header.out_of_date"
                      defaultMessage="Out of date"
                    >
                      {(text) => (
                        <Typography
                          data-qatag="driver.device_entry.header.out_of_date.text"
                          id="driver.device_entry.header.out_of_date.text"
                          variant="body2"
                          classes={{ root: classes.outOfDateText }}
                        >
                          {text}
                        </Typography>
                      )}
                    </FormattedMessage>
                  )}
              </Box>
              {props.device.hasUpdateAvailable && props.driverUpdateModel && (
                <Box
                  data-qatag="driver.device_entry.header.update_container"
                  id="driver.device_entry.header.update_container"
                >
                  <Updater
                    data-qatag="driver.device_entry.header.updater"
                    statusModel={props.driverUpdateModel}
                    onDownload={props.onDownload}
                    onUpdate={props.onInstall}
                    onDownloadCancel={props.onDownloadCancel}
                    onDownloadRetry={props.onDownloadRetry}
                  />
                </Box>
              )}
            </Box>
          }
          HeaderStyle={{ margin: "0px", padding: "0px" }}
          RootStyle={{ margin: "0px", padding: "0px" }}
          ButtonStyle={{
            alignSelf: "flex-start",
            marginTop: "5px",
            cursor: "pointer",
          }}
        >
          <Box
            data-qatag="driver.device_entry.content.container"
            id="driver.device_entry.content.container"
            className={classes.content_container}
          >
            <Box
              data-qatag="driver.device_entry.content.meta.container"
              id="driver.device_entry.content.meta.container"
            >
              <Box
                data-qatag="driver.device_entry.content.meta.device_description_container"
                id="driver.device_entry.content.meta.device_description_container"
                className={classes.meta_value_container}
              >
                <FormattedMessage
                  data-qatag="driver.device_entry.content.meta.device_description"
                  id="driver.device_entry.content.meta.device_description"
                  defaultMessage="Device Description: "
                >
                  {(text) => (
                    <Typography
                      data-qatag="driver.device_entry.content.meta.device_description.text"
                      id="driver.device_entry.content.meta.device_description.text"
                      variant="body2"
                      color="primary"
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
                <Typography
                  data-qatag="driver.device_entry.content.meta.device_description.value"
                  id="driver.device_entry.content.meta.device_description.value"
                  variant="body2"
                  color="primary"
                  classes={{ root: classes.metaValueText }}
                >
                  {deviceDescription}
                </Typography>
              </Box>
              {/* ip address for network printers */}
              {props.device.localIP && (
                <Box
                  data-qatag="driver.device_entry.content.meta.ip_container"
                  id="driver.device_entry.content.meta.ip_container"
                  className={classes.meta_value_container}
                >
                  <FormattedMessage
                    data-qatag="driver.device_entry.content.meta.ip"
                    id="driver.device_entry.content.meta.ip"
                    defaultMessage="IP Address: "
                  >
                    {(text) => (
                      <Typography
                        data-qatag="driver.device_entry.content.meta.ip.text"
                        id="driver.device_entry.content.meta.ip.text"
                        variant="body2"
                        color="primary"
                      >
                        {text}
                      </Typography>
                    )}
                  </FormattedMessage>
                  <Typography
                    data-qatag="driver.device_entry.content.meta.device_description.value"
                    id="driver.device_entry.content.meta.device_description.value"
                    variant="body2"
                    color="primary"
                    classes={{ root: classes.metaValueText }}
                  >
                    {props.device.localIP}
                  </Typography>
                </Box>
              )}
              <Box
                data-qatag="driver.device_entry.content.meta.installed_version_container"
                id="driver.device_entry.content.meta.installed_version_container"
                className={classes.meta_value_container}
              >
                <FormattedMessage
                  data-qatag="driver.device_entry.content.meta.installed_version"
                  id="driver.device_entry.content.meta.installed_version"
                  defaultMessage="Installed Version: "
                >
                  {(text) => (
                    <Typography
                      data-qatag="driver.device_entry.content.meta.installed_version.text"
                      id="driver.device_entry.content.meta.installed_version.text"
                      variant="body2"
                      color="primary"
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
                <Typography
                  data-qatag="driver.device_entry.content.meta.installed_version.value"
                  id="driver.device_entry.content.meta.installed_version.value"
                  variant="body2"
                  color="primary"
                  classes={{ root: classes.metaValueText }}
                >
                  {props.device.installedDriverVersion}
                </Typography>
              </Box>

              <Box
                data-qatag="driver.device_entry.content.meta.latest_driver_date_container"
                id="driver.device_entry.content.meta.latest_driver_date_container"
                className={classes.meta_value_container}
              >
                <FormattedMessage
                  data-qatag="driver.device_entry.content.meta.latest_driver_date"
                  id="driver.device_entry.content.meta.latest_driver_date"
                  defaultMessage="Latest Driver Date: "
                >
                  {(text) => (
                    <Typography
                      data-qatag="drivers.device_meta.drivers.device_meta.latest_driver_date_text"
                      id="drivers.device_meta.drivers.device_meta.latest_driver_date_text"
                      variant="body2"
                      color="primary"
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
                <Typography
                  data-qatag="driver.device_entry.content.meta.latest_driver_date.text"
                  id="driver.device_entry.content.meta.latest_driver_date.text"
                  variant="body2"
                  color="primary"
                  classes={{ root: classes.metaValueText }}
                >
                  {props.device.latestDriverDate}
                </Typography>
              </Box>

              <Box
                data-qatag="driver.device_entry.content.meta.driver_size_container"
                id="driver.device_entry.content.meta.driver_size_container"
                className={classes.meta_value_container}
              >
                <FormattedMessage
                  data-qatag="driver.device_entry.content.meta.driver_size"
                  id="driver.device_entry.content.meta.driver_size"
                  defaultMessage="File Size: "
                >
                  {(text) => (
                    <Typography
                      data-qatag="driver.device_entry.content.meta.driver_size.text"
                      id="driver.device_entry.content.meta.driver_size.text"
                      variant="body2"
                      color="primary"
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
                <Typography
                  data-qatag="driver.device_entry.content.meta.driver_size.value"
                  id="driver.device_entry.content.meta.driver_size.value"
                  variant="body2"
                  color="primary"
                  classes={{ root: classes.metaValueText }}
                >
                  {props.device.latestDriverFileSize}
                </Typography>
              </Box>
            </Box>
            <AppButton
              data-qatag="driver.device_entry.content.aboutButton"
              id="driver.device_entry.content.aboutButton"
              buttonStyle={AppButtonStyles.LinkBlue}
              className={classes.linkButton}
              onClick={props.onAbout}
            >
              <FormattedMessage
                data-qatag="driver.device_entry.content.aboutButton.content"
                id="driver.device_entry.content.aboutButton.content"
                defaultMessage="Question About This Device"
              >
                {(text) => (
                  <Typography
                    data-qatag="driver.device_entry.content.aboutButton.content.text"
                    id="driver.device_entry.content.aboutButton.content.text"
                    variant="body1"
                    className={classes.linkText}
                  >
                    {text}
                  </Typography>
                )}
              </FormattedMessage>
            </AppButton>
          </Box>
        </ExpanderBlock>
      </Box>
    </Box>
  );
};
//#endregion Component
