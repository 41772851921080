import { createStyles, Dialog, makeStyles } from "@material-ui/core";
import { DriverInstallStatus } from "model/driver/DriverUpdateModel";
import {
  driverInstallComplete,
  forceInstallWait,
  resetDriverInstall,
  selectCurrentInstallingDeviceId,
  selectDriverUpdateMap,
} from "./DriverSlice";
import { useDispatch, useSelector } from "react-redux";
import { InstallComplete } from "./modals/InstallComplete";
import { InstallFailed } from "./modals/InstallFailed";
import { InstallInProgress } from "./modals/InstallInProgress";
import { InstallTimedOut } from "./modals/InstallTimedOut";
import { InstallUserInteraction } from "./modals/InstallUserInteraction";
import { InstallVerifying } from "./modals/installVerifying";
import React from "react";
import { UnknownInstaller } from "./modals/UnknownInstaller";
import { InstallLowDiskSpace } from "./modals/InstallLowDiskSpace";
import { forceInstallComplete } from "./Thunks";
import { useHistory } from "react-router-dom";

const InstallModalRenderer: React.FC<{ deviceId: string }> = ({ deviceId }) => {
  const map = useSelector(selectDriverUpdateMap);
  const dispatch = useDispatch();
  const history = useHistory();
  const entry = map.find((e) => e.devices.includes(deviceId));

  const status = entry?.installRecord?.installStatus;
  //const status = DriverInstallStatus.LowDiskSpace as DriverInstallStatus;

  // if (status == null || entry == null) {
  //   throw new Error(
  //     "Could not find install status for currently installing driver: " +
  //       driverId
  //   );
  // }

  switch (status) {
    case DriverInstallStatus.Complete:
      return (
        <InstallComplete
          data-qatag="driver.modal.complete"
          onContinue={() => {
            dispatch(driverInstallComplete(deviceId));
          }}
        />
      );
    case DriverInstallStatus.Error:
      return (
        <InstallFailed
          data-qatag="driver.modal.error"
          onContinue={() => {
            dispatch(resetDriverInstall);
          }}
        />
      );
    case DriverInstallStatus.InProgress:
      return <InstallInProgress data-qatag="driver.modal.inprogress" />;
    case DriverInstallStatus.Interrupted:
      return <p data-qatag="driver.modal.interrupted">interrupted</p>;
    case DriverInstallStatus.TimedOut:
      return (
        <InstallTimedOut
          data-qatag="driver.modal.timedout"
          onWait={() => dispatch(forceInstallWait(deviceId))}
          onForceInstall={() => dispatch(forceInstallComplete(deviceId))}
        />
      );
    case DriverInstallStatus.Unknown:
      return <p data-qatag="unknown">unknown</p>;
    case DriverInstallStatus.UnknownInstaller:
      return <UnknownInstaller data-qatag="driver.modal.unknownInstaller" />;
    case DriverInstallStatus.UserInteraction:
      return (
        <InstallUserInteraction
          data-qatag="driver.modal.userinterfaction"
          onFinish={() => dispatch(forceInstallComplete(deviceId))}
        />
      );
    case DriverInstallStatus.Verifying:
      return <InstallVerifying data-qatag="driver.modal.unknownInstaller" />;
    case DriverInstallStatus.LowDiskSpace:
      return (
        <InstallLowDiskSpace
          data-qatag="driver.modal.lowdiskspace"
          onCancel={() => {
            dispatch(resetDriverInstall);
          }}
          onRequestSystemClean={() => {
            dispatch(resetDriverInstall);
            history.push("/filecleaning");
          }}
        />
      );
    default:
      return null;
  }
};

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: { borderRadius: "6px" },
    [theme.breakpoints.down("sm")]: {
      margin: "0px",
    },
  })
);

export const InstallConductor: React.FC = () => {
  const classes = useStyles();
  const deviceId = useSelector(selectCurrentInstallingDeviceId);
  //const deviceId = "test";
  return (
    <Dialog
      data-qatag="driver.modal.host"
      open={deviceId != null}
      maxWidth="md"
      classes={{ paper: classes.paper }}
    >
      {deviceId != null && (
        <InstallModalRenderer
          data-qatag="driver.modal.renderer"
          deviceId={deviceId}
        />
      )}
    </Dialog>
  );
};
