import { FormattedMessage } from "react-intl";
import {
  Typography,
  Box,
  IconButton,
  Dialog,
  Divider,
} from "@material-ui/core";
import {
  AppButton,
  AppButtonStyles,
  OnOffSwitch,
  useMatchTabletVertical,
  Alert,
  AlertIcon,
  Spinner,
} from "solveiq.designsystem";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import settingsIcon from "assets/icon/icn-settings.svg";
import browsingIcon from "assets/icon/icn-browsing-software.svg";
import productivityIcon from "assets/icon/icn-productivity-software.svg";
import gamingIcon from "assets/icon/icn-gaming-software.svg";
import styles from "./Settings.module.css";
import {
  fetchUserSettings,
  selectBrowsingProfileSettingValue,
  selectGamingProfileSettingValue,
  selectProductivityProfileSettingValue,
  updateSetting,
  updateUserSetting,
  selectStatus,
  resetStatus,
} from "features/settings/SettingsSlice";
import { UserSettingTypes } from "core/enumerations/UserSettingTypes";
import { useDispatch, useSelector } from "react-redux";
import { ProfileState } from "model/messaging/messages/AppSettingsMessages";
import { useEffect } from "react";
import { ReducerStatus } from "model/IReducerState";
import {
  eConnectionStatus,
  selectSignalRHubConnectionStatus,
} from "app/Agent/AgentSlice";

export interface ISettings {
  /* Controls to open the modal */
  isOpen: boolean;
  /* Callback to update the isOpen on the parent*/
  handleOnClose: () => void;
}

/* Custom style for Dialog */
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: "744px",
    width: "100%",
    margin: 12,
    paddingBottom: 20,
    minHeight: 760,
    [theme.breakpoints.up("md")]: {
      minHeight: 560,
    },
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      marginTop: 16,
      flexWrap: "wrap",
      marginBottom: 24,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 48,
      marginBottom: 48,
      flexWrap: "nowrap",
    },
  },
  buttonText: {
    lineHeight: 1,
  },
  titleText: {
    color: theme.palette.secondary.light,
  },
  settingContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexBasis: "100%",
      alignItems: "center",
      flexWrap: "nowrap",
    },
    [theme.breakpoints.up("md")]: {
      flexWrap: "wrap",
    },
  },
  dividerContainer: {
    [theme.breakpoints.down("md")]: {
      height: 8,
      marginTop: 8,
      marginBottom: 8,
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      height: 120,
      marginTop: 64,
      marginBottom: 0,
      width: "auto",
    },
  },
  divider: {
    [theme.breakpoints.down("md")]: {
      orientation: "horizontal",
    },
    [theme.breakpoints.up("md")]: {
      orientation: "vertical",
    },
  },
  switchContainer: {
    flexBasis: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 24,
    },
  },
}));

const Settings: React.FC<ISettings> = ({ isOpen = true, handleOnClose }) => {
  /* Localization prefix */
  const prefix = "settings.app.optimization";
  // Custom styles
  const classes = useStyles();
  // Match Tablet size
  const matchTablet = useMatchTabletVertical();
  const gamingSetting = useSelector(selectGamingProfileSettingValue);
  const gamingStatus = useSelector(selectStatus);
  const browsingSetting = useSelector(selectBrowsingProfileSettingValue);
  const productivitySetting = useSelector(
    selectProductivityProfileSettingValue
  );
  const dispatch = useDispatch();
  const isSignalRConnected =
    useSelector(selectSignalRHubConnectionStatus) ===
    eConnectionStatus.Connected;

  useEffect(() => {
    if (isSignalRConnected) {
      if (!gamingSetting) dispatch(fetchUserSettings());
    }
  }, [dispatch, isSignalRConnected, gamingSetting]);

  useEffect(() => {
    if (
      ReducerStatus[gamingStatus[fetchUserSettings.typePrefix]] ===
      ReducerStatus.Idle
    ) {
      dispatch(fetchUserSettings());
    }
  }, [dispatch, gamingStatus]);

  return (
    <Dialog
      data-qatag="settingsModal"
      open={isOpen}
      onClose={handleOnClose}
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <div data-qatag="container" className={styles.container}>
        <div data-qatag="content" className={styles.content}>
          {/* Close Icon */}
          <div
            data-qatag="closeIconContainer"
            className={styles.closeIconContainer}
          >
            <IconButton
              data-qatag="closeIcon"
              aria-label="close"
              onClick={handleOnClose}
              className={styles.closeIcon}
            >
              <CloseIcon data-qatag="close" />
            </IconButton>
          </div>

          {/* Title */}
          <Box data-qatag="titleContainer" textAlign="center">
            <Box
              data-qatag="settingsContainer"
              mt={3}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img data-qatag="settings" src={settingsIcon} alt="settings" />
              <Typography
                data-qatag="titleText"
                variant="h4"
                className={classes.titleText}
              >
                <FormattedMessage
                  data-qatag="FormattedMessage"
                  id={`${prefix}.title`}
                />
              </Typography>
            </Box>
            <Box data-qatag="title" mt={1}>
              You have full control over which optimization profiles are
              important to you, remember you can have them all set to “On”, we
              only activate the profile when needed.
            </Box>
          </Box>

          {/* Divider */}
          <Box data-qatag="dividerContainer" mt={4}>
            <Divider data-qatag="divider" />
          </Box>

          {/* Controllers */}
          <Box data-qatag="root" className={classes.root}>
            {/*Gaming Software*/}
            <Box
              data-qatag="settingContainer"
              className={classes.settingContainer}
            >
              <Box data-qatag="gamingBox" textAlign="center" flexBasis="100%">
                <img data-qatag="gamingIcon" src={gamingIcon} alt="Gaming" />
              </Box>
              <Box data-qatag="gamingTextBox" textAlign="center">
                <Typography
                  data-qatag="gamingText"
                  className={`${classes.titleText} ${styles.settingsText}`}
                  variant="h6"
                >
                  <FormattedMessage
                    data-qatag="gaming"
                    id={`${prefix}.gaming.software`}
                  />
                </Typography>
              </Box>
              <Box
                data-qatag="switchContainer"
                className={classes.switchContainer}
              >
                <OnOffSwitch
                  data-qatag="switch"
                  className={styles.switch}
                  onClick={() => toggleGaming(gamingSetting)}
                  isDisabled={false}
                  isOnInitially={gamingSetting === ProfileState.Active}
                />
              </Box>
            </Box>

            {/* Divider */}
            <Box
              data-qatag="dividerContainer"
              className={classes.dividerContainer}
            >
              <Divider
                data-qatag="divider"
                orientation={matchTablet ? "vertical" : "horizontal"}
                className={classes.divider}
              />
            </Box>

            {/*Productivity Software*/}
            <Box
              data-qatag="settingContainer"
              className={classes.settingContainer}
            >
              <Box
                data-qatag="productivityIconBox"
                textAlign="center"
                flexBasis="100%"
              >
                <img
                  data-qatag="productivityIcon"
                  src={productivityIcon}
                  alt="Productivity"
                />
              </Box>
              <Box data-qatag="settingsTextBox" textAlign="center">
                <Typography
                  data-qatag="settingsText"
                  className={`${classes.titleText} ${styles.settingsText}`}
                  variant="h6"
                >
                  <FormattedMessage
                    data-qatag="settings"
                    id={`${prefix}.productivity.software`}
                  />
                </Typography>
              </Box>
              <Box
                data-qatag="switchContainer"
                className={classes.switchContainer}
              >
                <OnOffSwitch
                  data-qatag="switch"
                  className={styles.switch}
                  onClick={() => toggleProductivity(productivitySetting)}
                  isDisabled={false}
                  isOnInitially={productivitySetting === ProfileState.Active}
                />
              </Box>
            </Box>

            {/* Divider */}
            <Box
              data-qatag="dividerContainer"
              className={classes.dividerContainer}
            >
              <Divider
                data-qatag="divider"
                className={classes.divider}
                orientation={matchTablet ? "vertical" : "horizontal"}
              />
            </Box>

            {/*Browsing Software*/}
            <Box
              data-qatag="settingContainer"
              className={classes.settingContainer}
            >
              <Box
                data-qatag="browsingIconBox"
                textAlign="center"
                flexBasis="100%"
              >
                <img
                  data-qatag="browsingIcon"
                  src={browsingIcon}
                  alt="Browsing"
                />
              </Box>
              <Box data-qatag="browsingTextBox" textAlign="center">
                <Typography
                  data-qatag="browsingText"
                  className={`${classes.titleText} ${styles.settingsText}`}
                  variant="h6"
                >
                  <FormattedMessage
                    data-qatag="browsing"
                    id={`${prefix}.browsing.software`}
                  />
                </Typography>
              </Box>
              <Box
                data-qatag="switchContainer"
                className={classes.switchContainer}
              >
                <OnOffSwitch
                  data-qatag="switch"
                  className={styles.switch}
                  onClick={() => toggleBrowsing(browsingSetting)}
                  isDisabled={false}
                  isOnInitially={browsingSetting === ProfileState.Active}
                />
              </Box>
            </Box>
          </Box>

          {/* Divider */}
          <Box data-qatag="dividerBox" width="100%">
            <Divider data-qatag="divider" />
          </Box>

          <Box
            data-qatag="buttonContainer"
            width={1}
            textAlign="center"
            mt={5}
            mb={2}
          >
            <AppButton
              data-qatag="button"
              buttonStyle={AppButtonStyles.Green}
              className={`${styles.customButton}`}
              onClick={handleOnClose}
            >
              <Typography
                data-qatag="buttonTextLabel"
                align="center"
                className={classes.buttonText}
                variant="body2"
              >
                <FormattedMessage
                  data-qatag="buttonText"
                  id={`${prefix}.close`}
                />
              </Typography>
            </AppButton>
          </Box>
        </div>
      </div>
      {/* Loading modal */}
      <Spinner
        data-qatag="loadingModal"
        isActive={
          ReducerStatus[gamingStatus[fetchUserSettings.typePrefix]] ===
          ReducerStatus.Loading
        }
        text="Updating..."
      />
      {/* Alert error */}
      <Alert
        data-qatag="alertError"
        icon={AlertIcon.Warning}
        title="Error..."
        text="Something went wrong"
        buttonText="Continue"
        approveHandler={() => {
          dispatch(resetStatus());
        }}
        isOpen={
          ReducerStatus[gamingStatus[fetchUserSettings.typePrefix]] ===
          ReducerStatus.Failed
        }
        closeHandler={() => {
          dispatch(resetStatus());
        }}
      />
    </Dialog>
  );

  function toggleGaming(currentSettingValue: ProfileState) {
    const csvBool = !!currentSettingValue;
    const payload = {
      UpdatedSettings: [
        {
          CurrentSettingValue: csvBool
            ? ProfileState.Active
            : ProfileState.Disabled,
          NewSettingValue: !csvBool
            ? ProfileState.Active
            : ProfileState.Disabled,
          SettingType: UserSettingTypes.GamingState,
          SettingTypeName: UserSettingTypes[UserSettingTypes.GamingState],
        },
      ],
    };
    dispatch(
      updateSetting({
        SettingType: UserSettingTypes.GamingState,
        SettingValue: !csvBool ? ProfileState.Active : ProfileState.Disabled,
      })
    );
    dispatch(updateUserSetting(payload));
  }

  function toggleProductivity(currentSettingValue: ProfileState) {
    const csvBool = !!currentSettingValue;
    const payload = {
      UpdatedSettings: [
        {
          CurrentSettingValue: csvBool
            ? ProfileState.Active
            : ProfileState.Disabled,
          NewSettingValue: !csvBool
            ? ProfileState.Active
            : ProfileState.Disabled,
          SettingType: UserSettingTypes.ProductivityState,
          SettingTypeName: UserSettingTypes[UserSettingTypes.ProductivityState],
        },
      ],
    };
    dispatch(
      updateSetting({
        SettingType: UserSettingTypes.GamingState,
        SettingValue: !csvBool ? ProfileState.Active : ProfileState.Disabled,
      })
    );
    dispatch(updateUserSetting(payload));
  }

  function toggleBrowsing(currentSettingValue: ProfileState) {
    const csvBool = !!currentSettingValue;
    const payload = {
      UpdatedSettings: [
        {
          CurrentSettingValue: csvBool
            ? ProfileState.Active
            : ProfileState.Disabled,
          NewSettingValue: !csvBool
            ? ProfileState.Active
            : ProfileState.Disabled,
          SettingType: UserSettingTypes.BrowsingState,
          SettingTypeName: UserSettingTypes[UserSettingTypes.BrowsingState],
        },
      ],
    };
    dispatch(
      updateSetting({
        SettingType: UserSettingTypes.GamingState,
        SettingValue: !csvBool ? ProfileState.Active : ProfileState.Disabled,
      })
    );
    dispatch(updateUserSetting(payload));
  }
};

export default Settings;
