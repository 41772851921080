import { MenuItemEntry } from "features/nav/NavBar";
import dashboardIcon from "assets/icon/icn-menu-dashboard.svg";
import teamIcon from "assets/icon/icn-menu-team.svg";
import ticketsIcon from "assets/icon/icn-menu-tickets.svg";
import statusIcon from "assets/icon/icn-menu-system-status.svg";
import helpIcon from "assets/icon/icn-help-menu.svg";
import billingIcon from "assets/icon/icn-billing-menu.svg";
import seatsIcon from "assets/icon/icn-device-menu.svg";
import settingsIcon from "assets/icon/icn-settings-screen.svg";
import logoutIcon from "assets/icon/icn-menu-logout.svg";

export const getMenu = ({
  isAdmin,
  isSignalRConnected,
  isBeta,
}: {
  isAdmin: boolean;
  isSignalRConnected: boolean;
  isBeta: boolean;
}) => {
  let menuItems: MenuItemEntry[] = [
    { to: "/help", label: "Help", icon: helpIcon },
    { to: "/tickets", label: "Tickets", icon: ticketsIcon },
  ];
  let dropdownItems: MenuItemEntry[] = [
    { to: "/settings", label: "Settings", icon: settingsIcon },
    "divider",
    { to: "/logout", label: "Log Out", icon: logoutIcon },
  ];

  if (isAdmin) {
    menuItems = [
      { to: "/help", label: "Help", icon: helpIcon },
      { to: "/tickets", label: "Tickets", icon: ticketsIcon },
      "divider",
      { to: "/team", label: "Team", icon: teamIcon },
    ];
    dropdownItems = [
      { to: "/seats", label: "Seats", icon: seatsIcon },
      { to: "/settings", label: "Settings", icon: settingsIcon },
      "divider",
      { to: "/logout", label: "Log Out", icon: logoutIcon },
    ];
  }

  //full menu if we have a signalR connection and are an admin
  if (isSignalRConnected) {
    menuItems = [
      { to: "/admin", label: "Dashboard", icon: dashboardIcon },
      { to: "/help", label: "Help", icon: helpIcon },
      { to: "/tickets", label: "Tickets", icon: ticketsIcon },
      { to: "/status", label: "System Status", icon: statusIcon },
      "divider",
    ];
    dropdownItems = [
      { to: "/settings", label: "Settings", icon: settingsIcon },
      "divider",
      { to: "/logout", label: "Log Out", icon: logoutIcon },
    ];
  }

  //full menu if we have a signalR connection and are an admin
  if (isSignalRConnected && isAdmin) {
    menuItems = [
      { to: "/admin", label: "Dashboard", icon: dashboardIcon },
      { to: "/help", label: "Help", icon: helpIcon },
      { to: "/tickets", label: "Tickets", icon: ticketsIcon },
      { to: "/status", label: "System Status", icon: statusIcon },
      "divider",
      { to: "/team", label: "Team", icon: teamIcon },
    ];
    dropdownItems = [
      { to: "/seats", label: "Seats", icon: seatsIcon },
      { to: "/settings", label: "Settings", icon: settingsIcon },
      "divider",
      { to: "/logout", label: "Log Out", icon: logoutIcon },
    ];
  }

  if (isAdmin && !isBeta) {
    dropdownItems.push({ to: "/billing", label: "Billing", icon: billingIcon });
  }

  return { menuItems, dropdownItems };
};

//base menu
