import { Box, IconButton } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import styles from "./PaymentMethods.module.css";
import { ReducerStatus } from "model/IReducerState";
import {
  fetchPaymentMethods,
  getDeleteStatus,
  deletePaymentMethod,
  resetDelete,
} from "./PaymentMethodsSlice";
import { RootState } from "app/store";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Close";
import { AlertError } from "features/customAlerts/AddTeamMember";
import { AlertWarning, AlertSuccess } from "features/customAlerts/Billing";
import { Spinner, Alert, AlertIcon } from "solveiq.designsystem";

export interface IPaymentMethods {
  /* Payment Methods Delete Status */
  deleteStatusSelector?: (state: RootState) => ReducerStatus;
  /* Payment method ID to delete */
  paymentMethodId: string;
  /* Credit card identifier */
  lastDigits: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      paddingRight: 12,
      paddingLeft: 12,
      [theme.breakpoints.down("md")]: {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
  })
);

const PaymentMethodDelete: React.FC<IPaymentMethods> = ({
  //   paymentMethodsSelector = (state: RootState) => getPaymentMethods(state),
  deleteStatusSelector = (state: RootState) => getDeleteStatus(state),
  paymentMethodId,
  lastDigits,
}) => {
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const dispatch = useDispatch();

  // Alerts status
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [error, setError] = useState("");

  // Handle to delete Payment method
  function handleDeleteMethod() {
    //Do something to delete
    dispatch(deletePaymentMethod(paymentMethodId));
    setOpenWarningModal((prevState) => !prevState);
  }

  /* Retrieve data from the store */
  /* Payment Methods Request Status */
  const deleteStatus = useSelector(deleteStatusSelector);

  return (
    <>
      <Box
        data-qatag="deleteAction"
        className={styles.deleteAction}
        onClick={() => {
          setOpenWarningModal((prevState) => !prevState);
        }}
      >
        <IconButton
          data-qatag="closeIcon"
          aria-label="close"
          onClick={() => {}}
          className={classes.closeIcon}
        >
          <DeleteIcon data-qatag="DeleteIcon" />
        </IconButton>
      </Box>
      {/* Loading modal */}
      <Spinner
        data-qatag="loadingModal"
        isActive={ReducerStatus[deleteStatus] === ReducerStatus.Loading}
        text="Deleting..."
      />

      <AlertError
        data-qatag="alertError"
        isOpen={ReducerStatus[deleteStatus] === ReducerStatus.Failed}
        closeHandler={() => {
          dispatch(resetDelete());
        }}
        text={error}
      />
      <AlertSuccess
        data-qatag="alertSuccess"
        isOpen={ReducerStatus[deleteStatus] === ReducerStatus.Succeeded}
        title={"Completed"}
        description={"The payment method was removed"}
        closeHandler={() => {
          dispatch(resetDelete());
          dispatch(fetchPaymentMethods());
        }}
      />
      <Alert
        data-qatag="alertDelete"
        icon={AlertIcon.Warning}
        title="Remove Payment method"
        text={`Are you sure to delete the ${lastDigits} payment method`}
        buttonText="Continue"
        approveHandler={handleDeleteMethod}
        isOpen={openWarningModal}
        closeHandler={() => setOpenWarningModal((prevState) => !prevState)}
        cancelHandler={() => setOpenWarningModal((prevState) => !prevState)}
        cancelButtonText="Cancel"
      />
    </>
  );
};

export default PaymentMethodDelete;
