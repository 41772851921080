import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ToggleRadioButtonMui, ToggleSide } from "solveiq.designsystem";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    filter_root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    filter_paper: {
      borderRadius: "0px",
    },
    button_container: {
      marginLeft: "10px",
    },
    filter_image: {
      marginRight: theme.spacing(1),
    },
    disabled: {
      opacity: 0.45,
    },
  })
);
//#endregion Component Styles

//#region Component Props
export interface IFilterBarProps {
  onFilterSelected: () => void;
  onAllSelected: () => void;
  SetFilterInitially: boolean;
  isVisible: boolean;
  isDisabled: boolean;
  style?: CSSProperties;
}
//#endregion Component Props

//#region Component
export const FilterBar: React.FC<IFilterBarProps> = (props) => {
  const classes = useStyles();
  return (
    <Paper
      data-qatag="driver.filterbar.paper"
      id="driver.filterbar.paper"
      elevation={0}
      classes={{ root: classes.filter_paper }}
      style={{ visibility: props.isVisible ? "visible" : "hidden" }}
    >
      <Box
        data-qatag="driver.filterbar.root"
        id="driver.filterbar.root"
        className={classes.filter_root}
      >
        <FormattedMessage
          data-qatag="driver.filterbar.label"
          id="driver.filterbar.label"
          defaultMessage="Filter Devices"
        >
          {(text) => (
            <Typography
              data-qatag="driver.filterbar.label.text"
              id="driver.filterbar.label.text"
              variant="body2"
              color="primary"
              className={props.isDisabled ? classes.disabled : ""}
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
        <Box
          data-qatag="driver.filterbar.togglebutton.container"
          id="driver.filterbar.togglebutton.container"
          className={classes.button_container}
        >
          <ToggleRadioButtonMui
            data-qatag="driver.filterbar.togglebutton"
            initialSideSelected={
              props.SetFilterInitially ? ToggleSide.left : ToggleSide.right
            }
            onToggle={(selectedSide) => {
              if (selectedSide === ToggleSide.left) {
                props.onFilterSelected();
              } else {
                props.onAllSelected();
              }
            }}
            // trackStyle={{
            //   marginLeft: "10px",
            //   width: "185px",
            //   height: "44px",
            // }}
            trackStyle={props.style}
            isDisabled={props.isDisabled}
            leftElement="Issues"
            rightElement="All"
          />
        </Box>
      </Box>
    </Paper>
  );
};
//#endregion Component
