import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import RESTGatewayAPI from "api/gatewayAPI";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import { IAgentInfo } from "model/scan/AgentInfo";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { IServiceMessage, ServiceMessage, WSMessageType } from "ui.common";
import { IServiceMessageGeneric } from "model/messaging/messages/IServiceMessageGeneric";
import {
  reportApplicationError,
  selectCurrentUser,
  selectCurrentUuid,
} from "session/SessionSlice";
import { AgentCacheItem } from "model/cache/AgentCacheItem";
import { IMachineIntelligence } from "model/messaging/messages/scanMessages";
import axios from "axios";
import { allScansCompleted } from "features/scan/ScanSlice";
import {
  submitMachineIntelligence,
  updateMachineIntelligence,
} from "features/MachinePicker/MachinePickerSlice";

export enum eConnectionStatus {
  Disconnected,
  Connecting,
  Connected,
}

export interface IUuidInfo {
  uuid: string;
  // is the uuid selected associated with the same machine that the agent is on
  isLocalAgent: boolean;
}

interface IAgentInitState {
  isOnline: boolean;
  isLocal: boolean;
  machineIntelligence: IMachineIntelligence;
  agentInfo: IAgentInfo;
  hasShownCongrats: boolean;
}

interface IAgentState {
  downloadUrl: string;
  // agent info message that was sent by the currently connectd agent
  agentInfo: IAgentInfo;
  optimizationsFinalized: boolean;
  rebootRequired: boolean;
  hasShownCongrats: boolean;
  agentOnlineRequestStatus: ReducerStatus;
  isAgentOnline: boolean;
  isAgentLocal: boolean;
  agentOnlineRequestError: string;
  connectionStatus: eConnectionStatus;
  machineIntelligence: IMachineIntelligence;
  hasAgentScan: boolean;
  lastScanTime: string;
  isForceDisconnected: boolean;
}

export const requestAgentInfo = createAsyncThunk<
  IAgentInfo,
  void,
  { state: RootState }
>("agent/requestAgentInfo", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const uuid = selectCurrentUuid(state);
  if (uuid == null) {
    //thunkApi.rejectWithValue("Unable to resolve uuid from state.");
    throw new Error("Unable to resolve uuid from state");
  }

  try {
    const srhub = getSignalRHub();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.GET_AGENT_INFO;

    const response = (await srhub.SendAsync(
      message
    )) as IServiceMessageGeneric<IAgentInfo>;

    return response.Payload;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch agent info: ${error}`);
  }
});

export const setCongratsShown = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("agent/setCongratsShown", async (_, thunkApi) => {
  try {
    //lets update our store optimistically so the modal closes quickly
    //thunkApi.dispatch(setCongratsShownLocal());

    //now update agent cache
    const srhub = getSignalRHub();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.UPSERT_CACHE;
    message.Payload = {
      CacheItem: 1,
      Expiration: null,
      CollectionName: "SMBUICache",
      ItemID: "CongratsShown",
    };

    await srhub.SendAsync(message);
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to set agent cache : ${error}`);
  }
});

export const getAgentDownloadUrl = createAsyncThunk<
  string,
  void,
  { state: RootState }
>("agent/getAgentDownloadUrl", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const email = selectCurrentUser(state)?.email;

  try {
    const url = `/api/autoinstall/${email}`;

    const response = await RESTGatewayAPI.get(url);
    const apiResponse: string = response.data;

    return apiResponse;
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch agent download url : ${error}`
    );
  }
});

export const initAgentCommunication = createAsyncThunk<
  IAgentInitState,
  void,
  { state: RootState }
>("agent/initAgentCommunication", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const uuid = selectCurrentUuid(state);

  const returnAgentState = {
    agentInfo: {},
    isOnline: false,
    isLocal: false,
    //hasMachineIntelligence: false,
    machineIntelligence: {},
  } as IAgentInitState;

  try {
    // clean up any possibly orphaned connections
    await getSignalRHub().Disconnect();

    try {
      const url = `/api/onlinestatus/${uuid}`;

      const response = await RESTGatewayAPI.get(url);
      const apiResponse: boolean = response.data;
      returnAgentState.isOnline = apiResponse;
    } catch (error) {
      return thunkApi.rejectWithValue(
        `Unable to fetch agent online status : ${error}`
      );
    }

    if (!returnAgentState.isOnline) {
      return thunkApi.rejectWithValue("agent not online");
    }

    try {
      await getSignalRHub().Connect(uuid);
    } catch (error) {
      return thunkApi.rejectWithValue(`Agent connection timed out : ${error}`);
    }

    try {
      const srhub = getSignalRHub();
      const message: IServiceMessage = new ServiceMessage();
      message.MessageType = WSMessageType.GET_AGENT_INFO;

      const response = (await srhub.SendAsync(
        message
      )) as IServiceMessageGeneric<IAgentInfo>;

      returnAgentState.agentInfo = response.Payload;
    } catch (error) {
      return thunkApi.rejectWithValue(`Unable to fetch agent info: ${error}`);
    }

    // request machine intelligence

    try {
      const srhub = getSignalRHub();
      const message: IServiceMessage = new ServiceMessage();
      message.MessageType = WSMessageType.GET_MACHINE_INTELLIGENCE;

      const response = (await srhub.SendAsync(
        message
      )) as IServiceMessageGeneric<IMachineIntelligence>;

      returnAgentState.machineIntelligence = response.Payload;
    } catch (error) {
      thunkApi.rejectWithValue(`Unable to fetch agent info: ${error}`);
    }

    const httpPort = returnAgentState.agentInfo.RegState.SvcLocalHttp;

    const url = `http://localhost:${httpPort}/${uuid}`;

    const conn = axios.create({
      baseURL: url,
    });
    try {
      const response = await conn.get(url);

      returnAgentState.isLocal = response.status === 200;
    } catch (e) {
      returnAgentState.isLocal = false;
    }

    try {
      const srhub = getSignalRHub();
      const message: IServiceMessage = new ServiceMessage();
      message.MessageType = WSMessageType.GET_CACHE_REQUEST;
      message.Payload = {
        CollectionName: "SMBUICache",
        ItemID: "CongratsShown",
      };

      const response = (await srhub.SendAsync(
        message
      )) as IServiceMessageGeneric<AgentCacheItem>;

      const hasShownCongrats = response.Payload.CacheItem === "1";
      returnAgentState.hasShownCongrats = hasShownCongrats;
    } catch (error) {
      thunkApi.rejectWithValue(`Unable to fetch showCongrats state: ${error}`);
    }
  } catch (e: any) {
    thunkApi.dispatch(reportApplicationError(e));
    return thunkApi.rejectWithValue(
      "An error occurred while trying to initialize the agent: " + e.message
    );
  }
  return returnAgentState;
});

export const getIsAgentOnline = createAsyncThunk<
  boolean,
  void,
  { state: RootState }
>("signalR/getIsAgentOnline", async (_, thunkApi) => {
  const state = thunkApi.getState();
  //const uuid = state.session.data.currentUuid;
  const uuid = selectCurrentUuid(state);

  try {
    const url = `/api/onlinestatus/${uuid}`;

    const response = await RESTGatewayAPI.get(url);
    const apiResponse: boolean = response.data;

    return apiResponse;
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch agent online status : ${error}`
    );
  }
});

const initialState: IReducerState<IAgentState> = {
  data: {
    downloadUrl: "",
    agentInfo: {} as IAgentInfo,
    rebootRequired: false,
    optimizationsFinalized: false,
    hasShownCongrats: false,
    agentOnlineRequestStatus: ReducerStatus.Idle,
    isAgentOnline: false,
    isAgentLocal: false,
    agentOnlineRequestError: "",
    connectionStatus: eConnectionStatus.Disconnected,
    machineIntelligence: {} as IMachineIntelligence,
    hasAgentScan: false,
    lastScanTime: "",
    isForceDisconnected: false,
  },
  status: {
    [getAgentDownloadUrl.typePrefix]: ReducerStatus.Idle,
    [requestAgentInfo.typePrefix]: ReducerStatus.Idle,
    [getIsAgentOnline.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    // agentScanReceived: (state) => {
    //   state.data.hasAgentScan = true;
    // },
    resetAgentOnlineRequestStatus: (state) => {
      //we've observed this state and want to reset to idle status
      state.data.agentOnlineRequestStatus = ReducerStatus.Idle;
    },
    doForceDisconnect: (state) => {
      state.data.isForceDisconnected = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCongratsShown.pending, (state) => {
      state.data.hasShownCongrats = true;
    });
    builder.addCase(setCongratsShown.rejected, (state) => {
      state.data.hasShownCongrats = false;
    });
    builder.addCase(setCongratsShown.fulfilled, (state, action) => {
      // this is unnecessary since we optimisitically set it to true in pending state
      //state.data.hasShownCongrats = true;
    });
    builder.addCase(initAgentCommunication.pending, (state) => {
      state.status[initAgentCommunication.typePrefix] = ReducerStatus.Loading;
      state.data.connectionStatus = eConnectionStatus.Connecting;
    });
    builder.addCase(initAgentCommunication.rejected, (state, action) => {
      state.status[initAgentCommunication.typePrefix] = ReducerStatus.Failed;
      state.data.connectionStatus = eConnectionStatus.Disconnected;
      // state.error = action.payload as string;
      //throw new Error(action.payload as string);
    });
    builder.addCase(
      initAgentCommunication.fulfilled,
      (state, action: PayloadAction<IAgentInitState>) => {
        state.status[initAgentCommunication.typePrefix] =
          ReducerStatus.Succeeded;
        state.data.connectionStatus = eConnectionStatus.Connected;
        state.data.agentInfo = action.payload.agentInfo;
        state.data.machineIntelligence = action.payload.machineIntelligence;
        state.data.isAgentLocal = action.payload.isLocal;
        state.data.isAgentOnline = action.payload.isOnline;
        state.data.hasAgentScan =
          action.payload.agentInfo.RegState.Current.AllScansInfo
            .LastStartTime != null;
        state.data.lastScanTime =
          action.payload.agentInfo.RegState.Current.AllScansInfo.LastStartTime;
        state.data.hasShownCongrats = action.payload.hasShownCongrats;
        state.data.rebootRequired = action.payload.agentInfo.RebootRequired;
        state.data.optimizationsFinalized =
          action.payload.agentInfo.InstallSate.Calculated.OptimizationsAreFinalized;
      }
    );
    builder.addCase(requestAgentInfo.pending, (state) => {
      state.status[requestAgentInfo.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(requestAgentInfo.rejected, (state, action) => {
      state.status[requestAgentInfo.typePrefix] = ReducerStatus.Failed;
      state.error = action.payload as string;
    });
    builder.addCase(requestAgentInfo.fulfilled, (state, action) => {
      state.status[requestAgentInfo.typePrefix] = ReducerStatus.Succeeded;
      state.data.agentInfo = action.payload;

      state.data.rebootRequired = action.payload.RebootRequired;
      state.data.optimizationsFinalized =
        action.payload.InstallSate.Calculated.OptimizationsAreFinalized;
      state.data.hasAgentScan =
        action.payload.RegState.Current.AllScansInfo.LastStartTime != null;
      state.data.lastScanTime =
        action.payload.RegState.Current.AllScansInfo.LastStartTime;
    });

    builder.addCase(getAgentDownloadUrl.pending, (state) => {
      state.status[getAgentDownloadUrl.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(getAgentDownloadUrl.rejected, (state, action) => {
      state.status[getAgentDownloadUrl.typePrefix] = ReducerStatus.Failed;
      state.error = action.payload as string;
    });
    builder.addCase(getAgentDownloadUrl.fulfilled, (state, action) => {
      state.status[getAgentDownloadUrl.typePrefix] = ReducerStatus.Succeeded;
      state.data.downloadUrl = action.payload;
    });
    builder.addCase(getIsAgentOnline.pending, (state) => {
      state.data.agentOnlineRequestStatus = ReducerStatus.Loading;
    });
    builder.addCase(getIsAgentOnline.rejected, (state, action) => {
      state.data.agentOnlineRequestStatus = ReducerStatus.Failed;
      state.data.agentOnlineRequestError = action.payload as string;
    });
    builder.addCase(getIsAgentOnline.fulfilled, (state, action) => {
      state.data.agentOnlineRequestStatus = ReducerStatus.Succeeded;
      //state.isAgentOnline = action.payload;
    });
    builder.addCase(allScansCompleted.fulfilled, (state, action) => {
      state.data.hasAgentScan = true;
    });
    builder.addCase(updateMachineIntelligence.fulfilled, (state, action) => {
      if (action.payload.Payload?.MachineIntelligence) {
        state.data.machineIntelligence =
          action.payload.Payload.MachineIntelligence;
      }
    });
  },
});

export const { doForceDisconnect } = agentSlice.actions;

export default agentSlice.reducer;

export const selectDownloadUrl = (state: RootState) =>
  state.agent.data.downloadUrl;

export const selectDownloadUrlStatus = (state: RootState) =>
  state.agent.status[getAgentDownloadUrl.typePrefix];

export const selectAgentInfoRequestStatus = (state: RootState) =>
  state.agent.status[requestAgentInfo.typePrefix];

export const selectAgentInitStatus = (state: RootState) =>
  state.agent.status[initAgentCommunication.typePrefix];

export const selectHasAgentScan = (state: RootState) =>
  state.agent.data.hasAgentScan;

export const selectAgentInfo = (state: RootState) => state.agent.data.agentInfo;

export const selectAppVersion = (state: RootState) =>
  state.agent.data?.agentInfo?.AppVersion ?? "";

export const selectLastStartTime = (state: RootState) =>
  state.agent.data.lastScanTime;

export const selectSignalRHubConnectionStatus = (state: RootState) =>
  state.agent.data.connectionStatus;

export const selectAgentOnlineStatus = (state: RootState) =>
  state.agent.data.isAgentOnline;

export const selectAgentOnlineRequestStatus = (state: RootState) =>
  state.agent.data.agentOnlineRequestStatus;

export const selectIsAgentLocal = (state: RootState) =>
  state.agent.data.isAgentLocal;

export const selectHasMachineIntelligence = (state: RootState) => {
  const mi = state.agent.data.machineIntelligence;
  return !(
    mi == null ||
    (mi.IntelligenceType !== "None" && mi.MatchType !== "Model")
  );
};

export const selectMachineIntelligence = (state: RootState) =>
  state.agent.data.machineIntelligence;

export const selectCongratsShownFlag = (state: RootState) =>
  state.agent.data.hasShownCongrats;

export const selectOptimizationsFinalized = (state: RootState) =>
  state.agent.data.optimizationsFinalized;

export const selectRebootRequired = (state: RootState) =>
  state.agent.data.rebootRequired;

export const selectIsForceDisconnected = (state: RootState) =>
  state.agent.data.isForceDisconnected;
