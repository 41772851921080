import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import warningIcon from "assets/icon/icon-warning.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      width: "504px",
      height: "387px",
      paddingTop: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "327px",
        height: "396px",
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        paddingTop: theme.spacing(6),
      },
    },
    actionButton: {
      width: "255px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    title_container: {
      marginTop: theme.spacing(3),
    },
    description_container: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    button_container: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  })
);
//#endregion Component Styles

//#region Component Props
export interface IInstallLowDiskSpaceProps {
  onRequestSystemClean: () => void;
  onCancel: () => void;
}
//#endregion Component Props

//#region Component
export const InstallLowDiskSpace: React.FC<IInstallLowDiskSpaceProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <Box
      data-qatag="driver.modal.install.low_disk_space"
      id="driver.modal.install.low_disk_space"
      className={classes.modalRoot}
    >
      <img
        data-qatag="driver.modal.install.low_disk_space.icon"
        id="driver.modal.install.low_disk_space.icon"
        src={warningIcon}
        alt=""
      />

      <Box
        data-qatag="driver.modal.install.low_disk_space.title.container"
        id="driver.modal.install.low_disk_space.title.container"
        className={classes.title_container}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.low_disk_space.title"
          id="driver.modal.install.low_disk_space.title"
          defaultMessage="Could not complete your driver download due to insufficient space."
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.low_disk_space.title.text"
              id="driver.modal.install.low_disk_space.title.text"
              variant="h5"
              color="textPrimary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
      <Box
        data-qatag="driver.modal.install.low_disk_space.description.container"
        id="driver.modal.install.low_disk_space.description.container"
        className={classes.description_container}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.low_disk_space.description"
          id="driver.modal.install.low_disk_space.description"
          defaultMessage="Please create some space {br} on your hard drive and try again."
          values={{
            br: (
              <br
                data-qatag="driver.modal.install.low_disk_space.break"
                id="driver.modal.install.low_disk_space.break"
              />
            ),
          }}
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.low_disk_space.description.text"
              id="driver.modal.install.low_disk_space.description.text"
              variant="body2"
              color="primary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
      <Box
        data-qatag="driver.modal.install.low_disk_space.button_container"
        id="driver.modal.install.low_disk_space.button_container"
        className={classes.button_container}
      >
        <AppButton
          data-qatag="driver.modal.install.low_disk_space.systemClean_button"
          id="driver.modal.install.low_disk_space.systemClean_button"
          className={classes.actionButton}
          buttonStyle={AppButtonStyles.Green}
          onClick={props.onRequestSystemClean}
        >
          <FormattedMessage
            data-qatag="driver.modal.install.low_disk_space.systemClean_button.content"
            id="driver.modal.install.low_disk_space.systemClean_button.content"
            defaultMessage="Try A System Clean"
          >
            {(text) => (
              <Typography
                data-qatag="driver.modal.install.low_disk_space.systemClean_button.content.text"
                id="driver.modal.install.low_disk_space.systemClean_button.content.text"
                variant="body2"
                align="center"
                style={{ fontWeight: 600 }}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </AppButton>
        <AppButton
          data-qatag="driver.modal.install.low_disk_space.noThanks_button"
          id="driver.modal.install.low_disk_space.noThanks_button"
          className={classes.actionButton}
          buttonStyle={AppButtonStyles.LinkGray}
          onClick={props.onCancel}
        >
          <FormattedMessage
            data-qatag="driver.modal.install.low_disk_space.noThanks_button.content"
            id="driver.modal.install.low_disk_space.noThanks_button.content"
            defaultMessage="No, Thanks"
          >
            {(text) => (
              <Typography
                data-qatag="driver.modal.install.low_disk_space.noThanks_button.content.text"
                id="driver.modal.install.low_disk_space.noThanks_button.content.text"
                variant="body2"
                align="center"
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </AppButton>
      </Box>
    </Box>
  );
};
//#endregion Component
