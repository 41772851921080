import { Box, createStyles, makeStyles, Paper } from "@material-ui/core";
import { SearchTextField } from "solveiq.designsystem";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

//#region Component Style
const useStyles = makeStyles((theme) =>
  createStyles({
    search_paper: {
      borderRadius: "0px",
      [theme.breakpoints.down("sm")]: {
        background: "transparent",
        marginTop: "15px",
        marginBottom: "15px",
      },
    },
    search_root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    search_bar: {
      height: "42px",
      borderRadius: "30px",
      width: "443px",
      [theme.breakpoints.between("md", "lg")]: {
        width: "254px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "333px",
        // background: "#ffffff",
      },
    },
  })
);

//#endregion Component Style

//#region Component Props
export interface ISearchBarProps {
  onSearchType: (e: string) => void;
  value: string;
  style?: CSSProperties;
}
//#endregion Component Props

//#region Component
export const SearchBar: React.FC<ISearchBarProps> = (props) => {
  const classes = useStyles();
  return (
    <Paper
      data-qatag="driver.serachbar.paper"
      className={classes.search_paper}
      style={props.style}
      elevation={0}
    >
      <Box data-qatag="driver.searchbar.root" className={classes.search_root}>
        <SearchTextField
          data-qatag="driver.searchbar.searchfield"
          containerClassName={classes.search_bar}
          placeholder={"Search Device"}
          onChange={props.onSearchType}
          displayDivider={false}
          value={props.value}
        />
      </Box>
    </Paper>
  );
};
//#endregion Component
