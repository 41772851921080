import { useIntl } from "react-intl";
import { Typography, Box } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";
import { Modal } from "solveiq.designsystem";
import styles from "./CleanupCompleteDialog.module.css";
import headerImage from "assets/img/fileCleaning/icn-pc-cleanup.svg";
import checkImage from "assets/img/icn-green-check-nobg.svg";

export interface ICleanupCategory {
  name: string;
  size: string;
}

export interface ICleanupCompleteDialogProps {
  isOpen: boolean;
  spaceFreed: string;
  hasLockedFiles: boolean;
  categories: ICleanupCategory[];
  closeDialogHandler: () => void;
  viewLockedFilesHandler: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    semiBold: {
      fontWeight: 500,
    },
    spaceFreed: {
      color: theme.palette.input?.dark,
      fontWeight: 500,
    },
    alert: {
      color: theme.palette.error.dark,
      fontWeight: 600,
    },
    closeButton: {
      lineHeight: 1,
    },
  })
);

export const CleanupCompleteDialog: React.FC<ICleanupCompleteDialogProps> = (
  props
) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "fileCleaning.cleanupCompleteDialog";
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  return (
    <Modal
      data-qatag="cleanupCompleteDialog"
      open={props.isOpen}
      onClose={props.closeDialogHandler}
      className={styles.cleanupCompleteDialog}
    >
      <div
        data-qatag="cleanupCompleteDialogContent"
        className={styles.cleanupCompleteDialogContent}
      >
        <img
          data-qatag="headerImage"
          src={headerImage}
          className={styles.headerImage}
          alt=""
        />

        {/* PC Cleanup Complete! */}
        {!props.hasLockedFiles ? (
          <Typography
            data-qatag="cleanupCompleteDialogSuccessTypography"
            align="center"
            variant="h4"
          >
            {formatMessage({
              id: `${prefix}.successTitle`,
              defaultMessage: "PC Cleanup Complete!",
            })}
          </Typography>
        ) : (
          ""
        )}

        {/* System Cleanup Complete */}
        {props.hasLockedFiles ? (
          <Typography
            data-qatag="cleanupCompleteDialogLockedFilesTypography"
            align="center"
            variant="h4"
          >
            {formatMessage({
              id: `${prefix}.lockedFilesTitle`,
              defaultMessage: "System Cleanup Complete",
            })}
          </Typography>
        ) : (
          ""
        )}
        <Box
          data-qatag="cleanupCompleteDescriptionBox"
          mb={2}
          mt={0.5}
          textAlign="center"
        >
          {formatMessage({
            id: `${prefix}.description`,
            defaultMessage:
              "Your PC has been cleaned and space has been freed.",
          })}
        </Box>

        <div
          data-qatag="mobileSpaceFreedContainer"
          className={`${styles.spaceFreedContainer} ${styles.mobile}`}
        >
          <img
            data-qatag="spaceFreedCheck"
            className={styles.spaceFreedCheck}
            src={checkImage}
            alt=""
          />
          <div data-qatag="spaceFreedText" className={styles.spaceFreedText}>
            <Typography data-qatag="semiBold" className={classes.semiBold}>
              {formatMessage({
                id: `${prefix}.spaceFreed`,
                defaultMessage: "Total Space Freed",
              })}
            </Typography>

            <Typography
              data-qatag="spaceFreed"
              variant="h2"
              className={classes.spaceFreed}
            >
              {props.spaceFreed}
            </Typography>
          </div>
        </div>
        {props.hasLockedFiles ? (
          <Typography
            data-qatag="alert"
            variant="body2"
            onClick={props.viewLockedFilesHandler}
            className={`${classes.alert} ${styles.viewLockedFiles}`}
          >
            {formatMessage({
              id: `${prefix}.lockedFilesMessage`,
              defaultMessage: "View locked files that cannot be cleaned",
            })}
          </Typography>
        ) : (
          ""
        )}
        <div
          data-qatag="categoriesContainer"
          className={styles.categoriesContainer}
        >
          {props.categories.map((category) => {
            return (
              <div
                data-qatag="cleanupCategory"
                className={styles.cleanupCategory}
                key={`cleanup-category-${category.name}`}
              >
                <Typography
                  data-qatag="categoryName"
                  align="left"
                  variant="body2"
                  className={styles.categoryName}
                >
                  {category.name}
                </Typography>
                <div
                  data-qatag="categorySizeContainer"
                  className={styles.categorySizeContainer}
                >
                  <Box
                    data-qatag="categorySizeBox"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Typography
                      data-qatag="categorySize"
                      variant="body2"
                      className={styles.categorySize}
                    >
                      {category.size}
                    </Typography>
                    <img
                      data-qatag="categoryCheck"
                      className={styles.categoryCheck}
                      src={checkImage}
                      alt=""
                    />
                  </Box>
                </div>
              </div>
            );
          })}
        </div>
        <div data-qatag="footer" className={styles.footer}>
          <div
            data-qatag="desktopSpaceFreedContainer"
            className={`${styles.spaceFreedContainer} ${styles.desktop}`}
          >
            <img
              data-qatag="spaceFreedCheck"
              className={styles.spaceFreedCheck}
              src={checkImage}
              alt=""
            />
            <div data-qatag="spaceFreedText" className={styles.spaceFreedText}>
              <Typography
                data-qatag="spaceFreedLabel"
                variant="body2"
                className={styles.spaceFreedLabel}
              >
                {formatMessage({
                  id: `${prefix}.spaceFreed`,
                  defaultMessage: "Total Space Freed",
                })}
              </Typography>
              <Typography
                data-qatag="spaceFreed"
                variant="h2"
                className={classes.spaceFreed}
              >
                {props.spaceFreed}
              </Typography>
            </div>
          </div>
          <AppButton
            data-qatag="closeButton"
            dataQatag="cleanupCompleteDialogCloseButton"
            buttonStyle={AppButtonStyles.Blue}
            onClick={props.closeDialogHandler}
            className={styles.closeButton}
          >
            <Typography
              data-qatag="closeButtonTypography"
              variant="body2"
              className={classes.closeButton}
            >
              {formatMessage({
                id: `${prefix}.close`,
                defaultMessage: "Close",
              })}
            </Typography>
          </AppButton>
        </div>
      </div>
    </Modal>
  );
};
