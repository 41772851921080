import {
  Box,
  makeStyles,
  createStyles,
  useTheme,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";

import { StatusTile, StatusTileTypes, tileTypes } from "./StatusTile";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  fetchUserSettings,
  selectIsAppOptimizationActive,
  selectIsDecptorBlockingActive,
  selectIsDnsProtectionActive,
  selectStatus,
} from "features/settings/SettingsSlice";
import {
  fetchHardwareScan,
  fetchSystemInfoScan,
  selectOutOfDateDriversCount,
  selectScanStatus,
} from "features/scan/ScanSlice";
import { ReducerStatus } from "model/IReducerState";
import { useAppDispatch } from "app/store";
import { selectIsFileCleaningTileActive } from "features/fileCleaning/FileCleaningSlice";
import { FormattedMessage } from "react-intl";
import { selectNumServicesThatNeedAttention } from "../TeamSlice";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    statusTileContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      width: "765px",

      [theme.breakpoints.down("md")]: {
        width: "675px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        width: "inherit",
      },
    },
    statusTile: {
      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    },
    needAttentionCount: {
      color: theme.palette.error.main,
    },
    service_summary_text: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);
//#endregion Component Styles

//#region Component
export const EmployeeDetailStatusPane: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isAppOptimizationActive = useSelector(selectIsAppOptimizationActive);
  const isDnsProtectionActive = useSelector(selectIsDnsProtectionActive);
  const isDeceptorBlockingActive = useSelector(selectIsDecptorBlockingActive);
  const outOfDateDriverCount = useSelector(selectOutOfDateDriversCount);
  const isFileCleaningTileActive = useSelector(selectIsFileCleaningTileActive);
  const needsAttentionCount = useSelector(selectNumServicesThatNeedAttention);
  // tablet or smaller
  const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(fetchUserSettings());
    dispatch(fetchSystemInfoScan());
    dispatch(fetchHardwareScan());
  }, [dispatch]);

  const getDisplayMode = () => {
    if (isMobile) {
      return "mobile";
    }
    if (isTabletOrSmaller) {
      return "tablet";
    }
    return "medium";
  };

  const handleTileClick = (tileType: StatusTileTypes) => {
    switch (tileType) {
      case "app_optimization":
        history.push("/optimizations");
        break;
      case "deceptor_blocking":
        history.push("/deceptorprotection");
        break;
      case "device_updates":
        history.push("/drivers");
        break;
      case "dns_protection":
        history.push("/dnsprotection");
        break;
      case "file_cleaning":
        history.push("/filecleaning");
        break;
      case "windows_enhancements":
        history.push("/windowsenhancements");
        break;
    }
  };

  const isTileActive = (tileType: StatusTileTypes) => {
    switch (tileType) {
      case "app_optimization":
        return isAppOptimizationActive;
      case "deceptor_blocking":
        return isDeceptorBlockingActive;
      case "device_updates":
        return outOfDateDriverCount === 0;
      case "dns_protection":
        return isDnsProtectionActive;
      case "file_cleaning":
        return isFileCleaningTileActive;
      case "windows_enhancements":
        return true;
      default:
        return false;
    }
  };

  return (
    <Box
      data-qatag="employeeDetail.status.root"
      id="employeeDetail.status.root"
      className={classes.root}
    >
      {needsAttentionCount === 0 ? (
        <FormattedMessage
          data-qatag="empoyeeDetail.status.services_summary.all_activated"
          id="empoyeeDetail.status.services_summary.all_activated"
          defaultMessage="All services are currently activated!"
        >
          {(text) => (
            <Typography
              data-qatag="vitalsEntry.title.text"
              id="vitalsEntry.title.text"
              variant="h6"
              color="textPrimary"
              className={classes.service_summary_text}
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      ) : (
        <FormattedMessage
          data-qatag="employeeDetail.stauts.services_summary.needs_attention"
          id="employeeDetail.stauts.services_summary.needs_attention"
          defaultMessage={`There {needsAttentionCount, plural, 
            one {is currently {formattedCount} service that needs attention!}
            other {are currently {formattedCount} services that need attention!}}`}
          values={{
            needsAttentionCount: needsAttentionCount,
            formattedCount: (
              <span
                data-qatag="employeeDetail.stauts.services_summary.needs_attention.count"
                id="employeeDetail.stauts.services_summary.needs_attention.count"
                className={classes.needAttentionCount}
              >
                {needsAttentionCount}
              </span>
            ),
          }}
        >
          {(text) => (
            <Typography
              data-qatag="employeeDetail.stauts.services_summary.needs_attention.text"
              id="employeeDetail.stauts.services_summary.needs_attention.text"
              variant="h6"
              color="textPrimary"
              className={classes.service_summary_text}
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      )}
      <Box
        data-qatag="employeeDetail.statusTileContainer"
        id="employeeDetail.statusTileContainer"
        className={classes.statusTileContainer}
      >
        {tileTypes.map((t) => (
          <Box key={t} data-qatag="statusTile" className={classes.statusTile}>
            <StatusTile
              data-qatag="employeeDetail.statusTile"
              isActive={isTileActive(t)}
              displayMode={getDisplayMode()}
              statusName={t}
              onClick={() => handleTileClick(t)}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
//#endregion Component
