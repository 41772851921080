export function getDate(date: string, mask?: "m/dd/yy"): string {
  const d = new Date(date);
  return d.toLocaleDateString("m/dd/yy");
}

export function getDateAndTime(date: string): string {
  const d = new Date(date);
  return d.toLocaleString([], {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}
