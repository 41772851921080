/*eslint max-len: ["error", { "code": 160 }]*/
import { useIntl, FormattedMessage } from "react-intl";
import { Typography, Box } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { IRingGraphProps, Panel, Modal, Spinner } from "solveiq.designsystem";
import styles from "./FileCleaning.module.css";
import gears from "assets/img/threeGears.svg";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";
import { RingGraph } from "solveiq.designsystem";
import { OnOffSwitch } from "solveiq.designsystem";
import { useEffect, useState } from "react";
import { CleaningUpDialog } from "./CleaningUpDialog";
import { AnalysisCompleteDialog } from "./AnalysisCompleteDialog";
import { FileListDialog } from "./FileListDialog";
import { CleanupCompleteDialog } from "./CleanupCompleteDialog";
import { LockedFilesDialog } from "./LockedFilesDialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { IThunkStatus, ReducerStatus } from "model/IReducerState";
import {
  clearResults,
  doFileCleanup,
  fetchFileCleanupScan,
  fetchLast30DaysReport,
  getFileCleanupDetails,
  selectFileCleaningDetails,
  selectDoingFileCleanup,
  selectDoingScan,
  selectFileCleanupResults,
  selectFileCleanupScan,
  selectLast30DaysReport,
  selectStatus,
  startFileCleanupScan,
  openRecycleBin,
  selectFileCleanupScanOptions,
  IFileCleanupOptions,
  fetchScanOptions,
  setScanOptions,
  selectInternetFilesEnabled,
  selectTempFilesEnabled,
  selectRecyclingEnabled,
  fetchFileCleanupResults,
} from "./FileCleaningSlice";
import { IFileCleanupScan } from "model/scan/IFileCleanupScan";
import { ViewHelpers } from "core/Helpers";
import { IFileScanCleanupPayload } from "model/messaging/payloads/IFileScanCleanupPayload";
import {
  ICleanUpFileMessage,
  IDirectoryCleanUpScanDetailsMessage,
  IFileCleanUpRemovalResultsMessage,
} from "model/messaging/messages/FileCleanupMessages";
import { ILast30DaysFileCleanupReport } from "./ILast30DaysFileCleanupReport";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import checkIcon from "assets/icon/icn-green-chck.svg";
import {
  selectCurrentUser,
  selectIsRemote,
  selectUserDescriptor,
} from "session/SessionSlice";
import { RouterBreadcrumbs } from "features/teamManagement/employeeDetail/RouterBreadcrumbs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unBold: {
      fontWeight: 400,
    },
    bold: {
      fontWeight: 600,
    },
    controlLabel: {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  })
);

export interface IFileCleaningProps {
  fileCleanupScanSelector?: (state: RootState) => IFileCleanupScan | null;
  fileCleanupResultsSelector?: (
    state: RootState
  ) => IFileCleanUpRemovalResultsMessage | null;
  detailsSelector?: (
    state: RootState
  ) => IDirectoryCleanUpScanDetailsMessage | null;
  last30DaysReportSelector?: (
    state: RootState
  ) => ILast30DaysFileCleanupReport | null;
  doingCleanupSelector?: (state: RootState) => boolean;
  doingScanSelector?: (state: RootState) => boolean;
  internetEnabledSelector?: (state: RootState) => boolean;
  tempFilesEnabledSelector?: (state: RootState) => boolean;
  recyclingEnabledSelector?: (state: RootState) => boolean;
  optionsSelector?: (state: RootState) => IFileCleanupOptions | null;
  thunkStatusSelector?: (state: RootState) => IThunkStatus;
}

export const FileCleaning: React.FC<IFileCleaningProps> = ({
  fileCleanupScanSelector = selectFileCleanupScan,
  fileCleanupResultsSelector = selectFileCleanupResults,
  detailsSelector = selectFileCleaningDetails,
  last30DaysReportSelector = selectLast30DaysReport,
  doingCleanupSelector = selectDoingFileCleanup,
  doingScanSelector = selectDoingScan,
  internetEnabledSelector = selectInternetFilesEnabled,
  tempFilesEnabledSelector = selectTempFilesEnabled,
  recyclingEnabledSelector = selectRecyclingEnabled,
  optionsSelector = selectFileCleanupScanOptions,
  thunkStatusSelector = selectStatus,
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "fileCleaning.view";
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const dispatch = useDispatch();

  const scan = useSelector(fileCleanupScanSelector);
  const results = useSelector(fileCleanupResultsSelector);
  const details = useSelector(detailsSelector);
  const last30DaysReport = useSelector(last30DaysReportSelector);
  const status = useSelector(thunkStatusSelector);
  const isRemote = useSelector(selectIsRemote);
  const userDescriptor = useSelector(selectUserDescriptor);
  const user = useSelector(selectCurrentUser);
  const options = useSelector(optionsSelector);
  const internetEnabled = useSelector(internetEnabledSelector);
  const tempFilesEnabled = useSelector(tempFilesEnabledSelector);
  const recyclingEnabled = useSelector(recyclingEnabledSelector);

  useEffect(() => {
    dispatch(fetchFileCleanupScan());
    dispatch(fetchLast30DaysReport());
    dispatch(getFileCleanupDetails());
    dispatch(fetchScanOptions());
    dispatch(fetchFileCleanupResults());
  }, [dispatch]);

  //#region state declarations
  const [
    showAnalysisCompleteDialog,
    setShowAnalysisCompleteDialog,
  ] = useState<boolean>(false);
  const [showFileListDialog, setShowFileListDialog] = useState<boolean>(false);
  const [fileListTitle, setFileListTitle] = useState<string>("File List");
  const [fileListFiles, setFileListFiles] = useState<ICleanUpFileMessage[]>([]);
  const [hideCleaningDialog, setHideCleaningDialog] = useState<boolean>(false); //user has manually closed the dialog
  const showCleaningDialog =
    useSelector(doingCleanupSelector) && !hideCleaningDialog;
  const [hideScanningDialog, setHideScanningDialog] = useState<boolean>(false); //user has manually closed the dialog
  const showScanningDialog =
    useSelector(doingScanSelector) && !hideScanningDialog;
  const [showLockedFilesDialog, setShowLockedFilesDialog] = useState<boolean>(
    false
  );
  const [userWantsResults, setUserWantsResults] = useState<boolean>(false);
  const showCleanupCompleteDialog =
    results != null && !showLockedFilesDialog && userWantsResults;
  /*   const [
    showCleanupCompleteDialog,
    setShowCleanupCompleteDialog,
  ] = useState<boolean>(false);
  if (results != null && !showLockedFilesDialog) {
    setShowCleanupCompleteDialog(true);
  } */
  const [shouldClearResults, setShouldClearResults] = useState<boolean>(false);
  //#endregion

  //#region data
  const resultsDate = useSelector<RootState, string>(() => {
    if (results == null) return "";

    const date = new Date(results.CleanUpDate);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  });
  const freedLast30Days = useSelector<RootState, string>(() => {
    return ViewHelpers.GetReadableFileSizeString(
      last30DaysReport?.totalSpaceFreed ?? 0
    );
  });
  const graphValues = useSelector(() => {
    const values = {
      internet:
        scan != null
          ? internetEnabled
            ? scan.TemporaryInternetFiles.TotalFileSize +
              scan.GoogleFiles.TotalFileSize
            : 0
          : last30DaysReport?.internetSize ?? 0,
      temp:
        scan != null
          ? tempFilesEnabled
            ? scan.WindowsTempFiles.TotalFileSize +
              scan.ApplicationTempFiles.TotalFileSize +
              scan.Thumbnails.TotalFileSize
            : 0
          : last30DaysReport?.tempFilesSize ?? 0,
      recycling:
        scan != null
          ? recyclingEnabled
            ? scan.RecycleBin.TotalFileSize
            : 0
          : last30DaysReport?.recyclingSize ?? 0,
      total: 0,
    };
    values.total = values.internet + values.temp + values.recycling;
    return values;
  });
  const ringGraphProps: IRingGraphProps = {
    radius: 100,
    strokeSize: 10,
    spaceSizePercent: 3,
    minimumSizePercent: 3,
    displayTotal: true,
    totalLabelText:
      scan != null
        ? formatMessage({
            id: `${prefix}.totalSpace`,
            defaultMessage: "Total Space",
          })
        : formatMessage({
            id: `${prefix}.totalSpaceFreed`,
            defaultMessage: "Total Space Freed",
          }),
    totalValueText: ViewHelpers.GetReadableFileSizeString(graphValues.total),
    entries: [
      {
        labelText: formatMessage({
          id: `${prefix}.internet`,
          defaultMessage: "Internet Files",
        }),
        value: graphValues.internet,
        valueText: ViewHelpers.GetReadableFileSizeString(graphValues.internet),
        isEnabled: scan != null ? internetEnabled : true,
      },
      {
        labelText: formatMessage({
          id: `${prefix}.tempFiles`,
          defaultMessage: "Windows Temp Files",
        }),
        value: graphValues.temp,
        valueText: ViewHelpers.GetReadableFileSizeString(graphValues.temp),
        isEnabled: scan != null ? tempFilesEnabled : true,
      },
      {
        labelText: formatMessage({
          id: `${prefix}.recycling`,
          defaultMessage: "Recycle Bin",
        }),
        value: graphValues.recycling,
        valueText: ViewHelpers.GetReadableFileSizeString(graphValues.recycling),
        isEnabled: scan != null ? recyclingEnabled : true,
      },
    ],
  };
  const lockedFiles =
    results?.DirectoryCleanUp?.FilesWithErrors?.map((x) => {
      const info = {
        name: x.FileName ?? "",
        size: ViewHelpers.GetReadableFileSizeString(x.Length),
        sizeNum: x.Length,
      };
      return info;
    }) ?? [];
  const lockedSize = ViewHelpers.GetReadableFileSizeString(
    lockedFiles.reduce((acc, cur) => acc + cur.sizeNum, 0)
  );
  const spaceFreedLastCleanup = ViewHelpers.GetReadableFileSizeString(
    results?.TotalCleanUpSize ?? 0
  );
  const analysisValues = {
    internet: {
      size:
        scan && internetEnabled
          ? scan.TemporaryInternetFiles.TotalFileSize +
            scan.GoogleFiles.TotalFileSize
          : 0,
      files:
        scan && internetEnabled
          ? scan.TemporaryInternetFiles.NumberCleanUpFiles +
            scan.GoogleFiles.NumberCleanUpFiles
          : 0,
    },
    temp: {
      size:
        scan && tempFilesEnabled
          ? scan.WindowsTempFiles.TotalFileSize +
            scan.ApplicationTempFiles.TotalFileSize +
            scan.Thumbnails.TotalFileSize
          : 0,
      files:
        scan && tempFilesEnabled
          ? scan.WindowsTempFiles.NumberCleanUpFiles +
            scan.ApplicationTempFiles.NumberCleanUpFiles +
            scan.Thumbnails.NumberCleanUpFiles
          : 0,
    },
    recycling: {
      size: scan && recyclingEnabled ? scan.RecycleBin.TotalFileSize : 0,
      files: scan && recyclingEnabled ? scan.RecycleBin.NumberCleanUpFiles : 0,
    },
  };
  const analysisCategories = [
    {
      name: formatMessage({
        id: `${prefix}.categories.browserCache`,
        defaultMessage: "Internet Files",
      }),
      size: ViewHelpers.GetReadableFileSizeString(analysisValues.internet.size),
      files: analysisValues.internet.files,
      onClick: () => {
        setShowAnalysisCompleteDialog(false);
        setFileListTitle(
          formatMessage({
            id: `${prefix}.categories.browserCache`,
            defaultMessage: "Internet Files",
          })
        );
        const files = details
          ? [
              ...details.TempInternetFilesCleanUpFiles,
              ...details.DownloadsCleanUpFiles,
              ...details.GoogleCleanUpFiles,
            ]
          : [];
        setFileListFiles(files);
        setShowFileListDialog(true);
      },
    },
    {
      name: formatMessage({
        id: `${prefix}.categories.tempFiles`,
        defaultMessage: "Temporary Files",
      }),
      size: ViewHelpers.GetReadableFileSizeString(analysisValues.temp.size),
      files: analysisValues.temp.files,
      onClick: () => {
        setShowAnalysisCompleteDialog(false);
        setFileListTitle(
          formatMessage({
            id: `${prefix}.categories.tempFiles`,
            defaultMessage: "Temporary Files",
          })
        );
        const files = details
          ? [
              ...details.WindowsTempCleanUpFiles,
              ...details.ApplicationTempCleanUpFiles,
              ...details.CachedThumbnailCleanUpFiles,
            ]
          : [];
        setFileListFiles(files);
        setShowFileListDialog(true);
      },
    },
    {
      name: formatMessage({
        id: `${prefix}.categories.recycling`,
        defaultMessage: "Recycle Bin",
      }),
      size: ViewHelpers.GetReadableFileSizeString(
        analysisValues.recycling.size
      ),
      files: analysisValues.recycling.files,
      onClick: () => {
        dispatch(openRecycleBin());
      },
    },
  ];
  const cleanupCompleteValues = {
    internet:
      (results?.DirectoryCleanUp?.TempInternetFilesCleanUpResults
        ?.TotalCleanUpSize ?? 0) +
      (results?.DirectoryCleanUp?.GoogleCleanUpResults?.TotalCleanUpSize ?? 0) +
      (results?.DirectoryCleanUp?.DownloadsCleanUpResults?.TotalCleanUpSize ??
        0),
    temp:
      (results?.DirectoryCleanUp?.WindowsTempCleanUpResults?.TotalCleanUpSize ??
        0) +
      (results?.DirectoryCleanUp?.ApplicationTempCleanUpResults
        ?.TotalCleanUpSize ?? 0) +
      (results?.DirectoryCleanUp?.CachedThumbnailCleanUpResults
        ?.TotalCleanUpSize ?? 0) +
      (results?.HibernationFileSize ?? 0),
    recycling: results?.RecycleBinSizeSize ?? 0,
  };
  const cleanupCompleteCategories = [
    {
      name: formatMessage({
        id: `${prefix}.categories.browserCache`,
        defaultMessage: "Internet Files",
      }),
      size: ViewHelpers.GetReadableFileSizeString(
        cleanupCompleteValues.internet
      ),
    },
    {
      name: formatMessage({
        id: `${prefix}.categories.tempFiles`,
        defaultMessage: "Temporary Files",
      }),
      size: ViewHelpers.GetReadableFileSizeString(cleanupCompleteValues.temp),
    },
    {
      name: formatMessage({
        id: `${prefix}.categories.recycling`,
        defaultMessage: "Recycle Bin",
      }),
      size: ViewHelpers.GetReadableFileSizeString(
        cleanupCompleteValues.recycling
      ),
    },
  ];
  //#endregion

  const location = useLocation();

  useEffect(() => {
    if (shouldClearResults) {
      dispatch(clearResults());
      setShouldClearResults(false);
    }
  }, [shouldClearResults, dispatch]);

  //#region action handlers
  const cleanUpNowHandler = async () => {
    setShowAnalysisCompleteDialog(false);
    setUserWantsResults(true);
    doCleanup();
  };
  const doCleanup = () => {
    const payload: IFileScanCleanupPayload = {
      CleanUpApplicationTemp: tempFilesEnabled,
      CleanUpDownloads: internetEnabled,
      CleanUpGoogleFiles: internetEnabled,
      CleanUpCachedThumbnails: tempFilesEnabled,
      CleanUpTempInternetFiles: internetEnabled,
      EmptyRecycleBin: recyclingEnabled,
      DisableHibernation: tempFilesEnabled && !scan?.IsLaptop,
      CleanUpWindowsTemp: tempFilesEnabled,
      CloseOpenApps: false,
      AppsToClose: [],
    };
    setHideCleaningDialog(false);
    dispatch(doFileCleanup(payload));
  };
  const onRescanClick = async () => {
    dispatch(startFileCleanupScan());
    setHideScanningDialog(false);
  };
  const onCleanupClick = async () => {
    setShowAnalysisCompleteDialog(true);
  };
  const viewLockedFilesHandler = () => {
    //setShowCleanupCompleteDialog(false);
    setShowLockedFilesDialog(true);
  };

  const onInternetSwitchClick = (
    e: React.MouseEvent<HTMLElement>,
    isOn: boolean
  ) => {
    dispatch(
      setScanOptions({
        internetFilesEnabled: isOn,
        tempFilesEnabled: tempFilesEnabled,
        recyclingEnabled: recyclingEnabled,
      })
    );
  };
  const onTempFilesSwitchClick = (
    e: React.MouseEvent<HTMLElement>,
    isOn: boolean
  ) => {
    dispatch(
      setScanOptions({
        internetFilesEnabled: internetEnabled,
        tempFilesEnabled: isOn,
        recyclingEnabled: recyclingEnabled,
      })
    );
  };
  const onRecyclingSwitchClick = (
    e: React.MouseEvent<HTMLElement>,
    isOn: boolean
  ) => {
    dispatch(
      setScanOptions({
        internetFilesEnabled: internetEnabled,
        tempFilesEnabled: tempFilesEnabled,
        recyclingEnabled: isOn,
      })
    );
  };
  const onViewResultsClick = () => {
    setUserWantsResults(true);
  };
  //#endregion

  return (
    <div data-qatag="fileCleaning" className={styles.fileCleaning}>
      {isRemote ? (
        <RouterBreadcrumbs
          data-qatag="filecleaning.screen.remoteBreadcrumbs"
          style={{ width: "100%", height: "42px", alignSelf: "center" }}
          descriptors={[
            {
              to: `/employeeDetail/${user?.id}`,
              label: userDescriptor,
            },
            {
              to: "/",
              label: "File Cleaning",
            },
          ]}
        ></RouterBreadcrumbs>
      ) : (
        <Breadcrumbs
          data-qatag="fileCleaningBreadcrumbs"
          pathsMap={{ filecleaning: "File Cleaning" }}
        />
      )}

      <Panel
        data-qatag="fileCleaningHeader"
        className={styles.fileCleaningHeader}
      >
        <Box
          data-qatag="gearsImageContainer"
          className={styles.gearsImageContainer}
          display="flex"
          alignItems="center"
          mb={1}
        >
          <img
            data-qatag="gearsImage"
            className={styles.gearsImage}
            src={gears}
            alt=""
          />
          <Typography
            data-qatag="headerTitle"
            variant="h4"
            className={styles.headerTitle}
          >
            {formatMessage({
              id: `${prefix}.header.title`,
              defaultMessage: "File Cleaning",
            })}
          </Typography>
        </Box>
        <div data-qatag="headerText" className={styles.headerText}>
          <Typography
            data-qatag="headerTitle"
            variant="h4"
            className={styles.headerTitle}
          >
            {formatMessage({
              id: `${prefix}.header.title`,
              defaultMessage: "File Cleaning",
            })}
          </Typography>
          {scan != null && (
            <span data-qatag="">
              <FormattedMessage
                data-qatag="fileCleaningHeaderDescription"
                id={`${prefix}.header.descriptionWithScan`}
                defaultMessage="We can free up to {size} of hard drive space."
                values={{
                  size: (
                    <span
                      data-qatag="fileCleaningHeaderSizeSpan"
                      className={`${classes.bold} ${styles.highlight}`}
                    >
                      {ViewHelpers.GetReadableFileSizeString(
                        graphValues.total,
                        true
                      )}
                    </span>
                  ),
                }}
              />
            </span>
          )}
          {scan == null && (
            <FormattedMessage
              data-qatag="fileCleaningHeaderDescription"
              id={`${prefix}.header.descriptionWithoutScan`}
              defaultMessage="Clean up your temporary browsing files, OS temp folders, and other temporary storage locations to free up hard drive space."
            />
          )}
          {results != null && (
            <div
              data-qatag="resultsMessageAndButtonContainer"
              className={styles.resultsMessageAndButtonContainer}
            >
              <div
                data-qatag="lastCleanedMessageContainer"
                className={styles.lastCleanedMessageContainer}
              >
                <img
                  data-qatag="checkMark"
                  className={styles.checkMark}
                  src={checkIcon}
                  alt=""
                />
                <FormattedMessage
                  data-qatag="fileCleaningHeaderDescription"
                  id={`${prefix}.header.lastCleaned`}
                  defaultMessage="Your {pc} was last cleaned on {date}"
                  values={{
                    pc: (
                      <span data-qatag="bold" className={classes.bold}>
                        {formatMessage({
                          id: `${prefix}.header.lastCleaned.pc`,
                          defaultMessage: "PC",
                        })}
                      </span>
                    ),
                    date: (
                      <span data-qatag="bold" className={classes.bold}>
                        {resultsDate}
                      </span>
                    ),
                  }}
                />
              </div>
              <AppButton
                data-qatag="viewResultsButton"
                dataQatag="fileCleaningViewResultsButton"
                className={styles.viewResultsButton}
                buttonStyle={AppButtonStyles.LinkBlue}
                onClick={onViewResultsClick}
              >
                <Typography
                  data-qatag="fileCleaningViewResultsButtonTypography"
                  variant="body2"
                >
                  {formatMessage({
                    id: `${prefix}.header.viewResultsButtonText`,
                    defaultMessage: "View Results",
                  })}
                </Typography>
              </AppButton>
            </div>
          )}
        </div>
        <div data-qatag="buttonContainer" className={styles.buttonContainer}>
          <AppButton
            data-qatag="rescanButton"
            dataQatag="fileCleaningRescanButton"
            className={styles.rescanButton}
            buttonStyle={AppButtonStyles.Green}
            onClick={onRescanClick}
          >
            <Typography
              data-qatag="fileCleaningRescanButtonTypography"
              variant="body2"
            >
              {formatMessage({
                id: `${prefix}.header.rescanButtonText`,
                defaultMessage: "Rescan",
              })}
            </Typography>
          </AppButton>
          {scan != null && (
            <AppButton
              data-qatag="cleanupButton"
              dataQatag="fileCleaningCleanupButton"
              className={styles.cleanupButton}
              buttonStyle={AppButtonStyles.Green}
              onClick={onCleanupClick}
            >
              <Typography
                data-qatag="fileCleaningCleanupButtonTypography"
                variant="body2"
              >
                {formatMessage({
                  id: `${prefix}.header.cleanupButtonText`,
                  defaultMessage: "Clean Up Now",
                })}
              </Typography>
            </AppButton>
          )}
          {results != null && (
            <div
              data-qatag="resultsMessageAndButtonContainer"
              className={`${styles.resultsMessageAndButtonContainer} ${styles.mobileResultsContainer}`}
            >
              <div
                data-qatag="lastCleanedMessageContainer"
                className={styles.lastCleanedMessageContainer}
              >
                <img
                  data-qatag="checkMark"
                  className={styles.checkMark}
                  src={checkIcon}
                  alt=""
                />
                <FormattedMessage
                  data-qatag="fileCleaningHeaderDescription"
                  id={`${prefix}.header.lastCleaned`}
                  defaultMessage="Your {pc} was last cleaned on {date}"
                  values={{
                    pc: (
                      <span data-qatag="bold" className={classes.bold}>
                        {formatMessage({
                          id: `${prefix}.header.lastCleaned.pc`,
                          defaultMessage: "PC",
                        })}
                      </span>
                    ),
                    date: (
                      <span data-qatag="bold" className={classes.bold}>
                        {resultsDate}
                      </span>
                    ),
                  }}
                />
              </div>
              <AppButton
                data-qatag="viewResultsButton"
                dataQatag="fileCleaningViewResultsButton"
                className={styles.viewResultsButton}
                buttonStyle={AppButtonStyles.LinkBlue}
                onClick={onViewResultsClick}
              >
                <Typography
                  data-qatag="fileCleaningViewResultsButtonTypography"
                  variant="body2"
                >
                  {formatMessage({
                    id: `${prefix}.header.viewResultsButtonText`,
                    defaultMessage: "View Results",
                  })}
                </Typography>
              </AppButton>
            </div>
          )}
        </div>
      </Panel>
      <Panel data-qatag="fileCleaningBody" className={styles.fileCleaningBody}>
        <div data-qatag="graphAndText" className={styles.graphAndText}>
          <RingGraph
            data-qatag=""
            dataQatag="fileCleaningRingGraph"
            {...ringGraphProps}
          />
          <div
            data-qatag="bodyGraphTextSpacer"
            className={styles.bodyGraphTextSpacer}
          />
          <div
            data-qatag="fileCleaningBodyText"
            className={styles.fileCleaningBodyText}
          >
            <Box
              data-qatag="bodySummary"
              className={styles.bodySummary}
              display="flex"
              flexWrap="wrap"
            >
              <Typography
                data-qatag="unBold"
                variant="h6"
                className={classes.unBold}
              >
                {!scan && (
                  <FormattedMessage
                    data-qatag="fileCleaningSummaryHistoryMessage"
                    id={`${prefix}.summaryHistory`}
                    defaultMessage="{freedLast30Days} has been {freed} across {yourComputer} in the last {days}."
                    values={{
                      freedLast30Days: (
                        <span
                          data-qatag="bold-highlight"
                          className={`${classes.bold} ${styles.highlightGreen}`}
                        >
                          {freedLast30Days}
                        </span>
                      ),
                      freed: (
                        <span data-qatag="bold" className={classes.bold}>
                          <FormattedMessage
                            data-qatag="fileCleaningSpaceFreedMessage"
                            id={`${prefix}.freed`}
                            defaultMessage=" FREED"
                          />
                        </span>
                      ),
                      yourComputer: (
                        <span data-qatag="bold" className={classes.bold}>
                          <FormattedMessage
                            data-qatag="fileCleaningYourPCMessage"
                            id={`${prefix}.your.pc`}
                            defaultMessage=" your PC"
                          />
                        </span>
                      ),
                      days: (
                        <span data-qatag="bold" className={classes.bold}>
                          <FormattedMessage
                            data-qatag="fileCleaningDaysMessage"
                            id={`${prefix}.days`}
                            defaultMessage="{days} Days"
                            values={{ days: 30 }}
                          />
                        </span>
                      ),
                    }}
                  />
                )}
                {scan && (
                  <FormattedMessage
                    data-qatag="fileCleaningSummaryScanMessage"
                    id={`${prefix}.summaryScan`}
                    defaultMessage="Up to {scanSize} of hard drive space can be {freed} up from {yourComputer} if you clean up now."
                    values={{
                      scanSize: (
                        <span
                          data-qatag="fileCleaningScanSizeSpan"
                          className={`${classes.bold} ${styles.highlight}`}
                        >
                          {ViewHelpers.GetReadableFileSizeString(
                            graphValues.total,
                            true
                          )}
                        </span>
                      ),
                      freed: (
                        <span data-qatag="bold" className={classes.bold}>
                          <FormattedMessage
                            data-qatag="fileCleaningSpaceFreedMessage"
                            id={`${prefix}.freed`}
                            defaultMessage=" FREED"
                          />
                        </span>
                      ),
                      yourComputer: (
                        <span data-qatag="bold" className={classes.bold}>
                          <FormattedMessage
                            data-qatag="fileCleaningYourPcMessage"
                            id={`${prefix}.your.pc`}
                            defaultMessage=" your PC"
                          />
                        </span>
                      ),
                    }}
                  />
                )}
              </Typography>
            </Box>
            {scan && (
              <Box data-qatag="fileCleaningScanDescriptionBox" mt={2}>
                {formatMessage({
                  id: `${prefix}.description`,
                  defaultMessage:
                    "Clean up unused files to free up storage space",
                })}
              </Box>
            )}
          </div>
        </div>
        {options && (
          <div data-qatag="controls" className={styles.controls}>
            <Box
              data-qatag="fileCleaningControlsTitleBox"
              mb={2}
              flexBasis="100%"
            >
              <Typography
                data-qatag="fileCleaningControlsTitleTypography"
                variant="h6"
              >
                {formatMessage({
                  id: `${prefix}.controls.title`,
                  defaultMessage: "Cleanup Location Options",
                })}
              </Typography>
            </Box>
            <div
              data-qatag="controlContainer"
              className={styles.controlContainer}
            >
              <Typography
                data-qatag="controlLabel"
                variant="h6"
                className={`${classes.controlLabel} ${styles.controlLabel}`}
              >
                {formatMessage({
                  id: `${prefix}.controls.internet`,
                  defaultMessage: "Internet Files",
                })}
              </Typography>
              <OnOffSwitch
                data-qatag="controlSwitch"
                dataQatag={`fileCleaningInternetFilesSwitch}`}
                className={styles.controlSwitch}
                isOnInitially={internetEnabled}
                onClick={onInternetSwitchClick}
                isDisabled={false}
              />
            </div>
            <div
              data-qatag="tempFilesSwitchContainer"
              className={styles.controlContainer}
            >
              <Typography
                data-qatag="tempFilesSwitchLabel"
                variant="h6"
                className={`${classes.controlLabel} ${styles.controlLabel}`}
              >
                {formatMessage({
                  id: `${prefix}.controls.tempFiles`,
                  defaultMessage: "Windows Temp Files",
                })}
              </Typography>
              <OnOffSwitch
                data-qatag="controlSwitch"
                dataQatag={`fileCleaningTemporaryFilesSwitch`}
                className={styles.controlSwitch}
                isOnInitially={tempFilesEnabled}
                onClick={onTempFilesSwitchClick}
                isDisabled={false}
              />
            </div>
            <div
              data-qatag="recycleBinSwitchContainer"
              className={styles.controlContainer}
            >
              <Typography
                data-qatag="recycleBinSwitchLabel"
                variant="h6"
                className={`${classes.controlLabel} ${styles.controlLabel}`}
              >
                {formatMessage({
                  id: `${prefix}.controls.recycling`,
                  defaultMessage: "Recycle Bin",
                })}
              </Typography>

              <OnOffSwitch
                data-qatag="controlSwitch"
                dataQatag={`fileCleaningRecycleBinSwitch`}
                className={styles.controlSwitch}
                isOnInitially={recyclingEnabled}
                onClick={onRecyclingSwitchClick}
                isDisabled={false}
              />
            </div>
          </div>
        )}
      </Panel>
      <AnalysisCompleteDialog
        data-qatag="fileCleaningAnalysisCompleteDialog"
        isOpen={showAnalysisCompleteDialog}
        closeDialogHandler={() => setShowAnalysisCompleteDialog(false)}
        cleanHandler={cleanUpNowHandler}
        categories={analysisCategories}
      />
      <FileListDialog
        data-qatag="fileCleaningFileListDialog"
        isOpen={showFileListDialog}
        title={fileListTitle}
        closeDialogHandler={() => {
          setShowFileListDialog(false);
          setShowAnalysisCompleteDialog(true);
        }}
        files={fileListFiles}
      />
      <CleaningUpDialog
        data-qatag="fileCleaningCleaningDialog"
        isOpen={showCleaningDialog}
        closeDialogHandler={() => setHideCleaningDialog(true)}
      />
      <CleanupCompleteDialog
        data-qatag="fileCleaningCleanupCompleteDialog"
        isOpen={showCleanupCompleteDialog}
        closeDialogHandler={() => {
          //setShouldClearResults(true);
          setUserWantsResults(false);
        }}
        viewLockedFilesHandler={viewLockedFilesHandler}
        spaceFreed={spaceFreedLastCleanup}
        categories={cleanupCompleteCategories}
        hasLockedFiles={lockedFiles.length > 0}
      />
      <LockedFilesDialog
        data-qatag="fileCleaningLockedFilesDialog"
        isOpen={showLockedFilesDialog}
        closeDialogHandler={() => setShowLockedFilesDialog(false)}
        files={lockedFiles}
        totalSize={lockedSize}
      />
      <Spinner
        data-qatag="scanningDialog"
        text="Scanning..."
        isActive={showScanningDialog}
      />
      <Spinner
        data-qatag="loadingDialog"
        text="Loading..."
        isActive={
          status[fetchFileCleanupScan.typePrefix] === ReducerStatus.Loading
        }
      />
    </div>
  );
};
