import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, useMediaQuery } from "@material-ui/core";
import styles from "./ScanStages.module.css";
import deviceUpdates from "assets/img/status/deviceUpdatesImage.svg";
import fileCleaning from "assets/img/status/fileCleaningImage.svg";
import deceptorBlocking from "assets/img/status/deceptorBlockingImage.svg";
import appOptimization from "assets/img/status/appOptimizationImage.svg";
import windowsEnhancements from "assets/img/status/windowsEnhancementsImage.svg";
import dnsProtection from "assets/img/status/dnsProtectionImage.svg";
import greenCheck from "assets/img/greenCheck.svg";
import horizontalTealBackground from "assets/img/horizontal-stages-bg.png";
import verticalTealBackground from "assets/img/vertical-stages-bg.png";

const prefix = "scanStages.stages";

export interface IScreenStages {
  progress: number;
}

// Number of stages.
// IMPORTANT: Update it if a new stage is added/removed.
const NUM_STAGES = 6;

const ScanStages: React.FC<IScreenStages> = ({ progress }) => {
  const [stagesProgress, setStagesProgress] = useState(
    Array<boolean>(NUM_STAGES).fill(false)
  );

  const { formatMessage } = useIntl();
  const matchesTablet = useMediaQuery("(min-width: 401px)");

  useEffect(() => {
    setStagesProgress([
      progress >= 1 / NUM_STAGES,
      progress >= 2 / NUM_STAGES,
      progress >= 3 / NUM_STAGES,
      progress >= 4 / NUM_STAGES,
      progress >= 5 / NUM_STAGES,
      progress >= 6 / NUM_STAGES,
    ]);
  }, [progress]);

  return (
    <div data-qatag="container" className={styles.container}>
      <img
        data-qatag="scan_stages_tealBg"
        className={styles.tealBackground}
        src={matchesTablet ? horizontalTealBackground : verticalTealBackground}
        alt=""
      />
      <ScanStage
        data-qatag="scanStage0"
        complete={stagesProgress[0]}
        image={deviceUpdates}
        label={formatMessage({ id: `${prefix}.deviceUpdates` })}
      />
      <ScanStageSeparator
        data-qatag="scanStageSeparator"
        portion={1}
        progress={progress}
      />
      <ScanStage
        data-qatag="scanStage1"
        complete={stagesProgress[1]}
        image={fileCleaning}
        label={formatMessage({ id: `${prefix}.fileCleaning` })}
      />
      <ScanStageSeparator
        data-qatag="2canStageSeparator"
        portion={2}
        progress={progress}
      />
      <ScanStage
        data-qatag="scanStage2"
        complete={stagesProgress[2]}
        image={deceptorBlocking}
        label={formatMessage({ id: `${prefix}.deceptor` })}
      />
      <ScanStageSeparator
        data-qatag="scanStageSeparator"
        portion={3}
        progress={progress}
      />
      <ScanStage
        data-qatag="scanStage2"
        complete={stagesProgress[3]}
        image={appOptimization}
        label={formatMessage({ id: `${prefix}.appOptimization` })}
      />
      <ScanStageSeparator
        data-qatag="scanStageSeparator"
        portion={4}
        progress={progress}
      />
      <ScanStage
        data-qatag="scanStage4"
        complete={stagesProgress[4]}
        image={windowsEnhancements}
        label={formatMessage({ id: `${prefix}.windowsEnhancements` })}
      />
      <ScanStageSeparator
        data-qatag="scanStageSeparator"
        portion={5}
        progress={progress}
      />
      <ScanStage
        data-qatag="scanStage5"
        complete={stagesProgress[5]}
        image={dnsProtection}
        label={formatMessage({ id: `${prefix}.dnsProtections` })}
      />
    </div>
  );
};

export default ScanStages;

export interface IScanStage {
  image: string;
  complete: boolean;
  label: string;
}

export const ScanStage: React.FC<IScanStage> = ({ image, complete, label }) => (
  <div
    data-qatag="stage"
    className={styles.stage}
    style={{ opacity: complete ? 1 : 0.5 }}
  >
    <img
      data-qatag="stageImage"
      className={styles.stageImage}
      src={image}
      alt={label}
    />
    {complete && (
      <img
        data-qatag="greenCheck"
        className={styles.greenCheck}
        src={greenCheck}
        alt="Complete"
      />
    )}
    <Typography
      data-qatag="stageLabel"
      variant="body1"
      className={styles.stageLabel}
    >
      {label}
    </Typography>
  </div>
);

interface IScanStageSeparator {
  /**
   * Represents the portion of the whole progress bar.
   * Value must be `n > 0 && n < NUM_STAGES`.
   */
  portion: number;
  progress: number;
}

const ScanStageSeparator: React.FC<IScanStageSeparator> = ({
  portion,
  progress,
}) => {
  if (portion < 1 && portion >= NUM_STAGES) {
    throw new Error(
      "'portion' must be greater than 0 and less than 'NUM_STAGES'."
    );
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [relativeProgress, setRelativeProgress] = useState(
    progress * containerWidth
  );

  // Recompute container width on window resize.
  useEffect(() => {
    if (containerRef && containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }

    const onResize = () => {
      if (containerRef && containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    if (containerRef && containerRef.current) {
      window.addEventListener("resize", onResize);
    }

    return () => window.removeEventListener("resize", onResize);
  }, []);

  // Compute progress for each portion.
  useEffect(() => {
    const numPortions = NUM_STAGES - 1;
    const limits = [0, 0];
    if (portion === 1) {
      limits[1] = portion / numPortions;
    } else {
      limits[0] = (portion - 1) / numPortions;
      limits[1] = portion / numPortions;
    }

    const portionSize = limits[1] - limits[0];

    if (progress <= limits[0]) {
      setRelativeProgress(0);
    } else if (progress >= limits[1]) {
      setRelativeProgress(containerWidth);
    } else {
      setRelativeProgress(
        ((portionSize - (limits[1] - progress)) / portionSize) * containerWidth
      );
    }
  }, [portion, progress, containerWidth]);

  return (
    <div
      data-qatag="separatorContainer"
      ref={containerRef}
      className={styles.separatorContainer}
    >
      <div
        data-qatag="separatorProgress"
        className={styles.separatorProgress}
        style={{ width: relativeProgress }}
      ></div>
    </div>
  );
};
