import { Fragment } from "react";
import styles from "./Breadcrumbs.module.css";
import { Link, useLocation } from "react-router-dom";
import { IRouteState } from "solveiq.designsystem";

type PathsMap = { [key: string]: string };

export interface IBreadcrumbs {
  /** Render path from prop instead of location from react-router. */
  customPath?: string;
  /** Maps each path location to a custom friendlier name. */
  pathsMap?: PathsMap;
  /**
   * Route state to use when there is a hard reset, page refresh, etc. which
   * erases the navigation history.
   * Default: { from: "/admin", friendlyName: "/Dashboard" }
   * */
  defaultRouteState?: IRouteState;
}

const Breadcrumbs: React.FC<IBreadcrumbs> = ({
  customPath,
  pathsMap,
  defaultRouteState = { from: "/admin", friendlyName: "/Dashboard" },
}) => {
  const { pathname, state = defaultRouteState } = useLocation<IRouteState>();
  let newState: IRouteState = state;
  if (newState === null) {
    newState = defaultRouteState;
  }
  const fullPath = customPath ? customPath : `${newState.from}${pathname}`;

  pathsMap = {
    "/": "Root",
    ...getPathMapFromRouteState(newState),
    ...pathsMap,
  };

  return (
    <div data-qatag="breadcrumbs_container" className={styles.container}>
      {buildPath(fullPath, pathsMap)}
    </div>
  );
};

export default Breadcrumbs;

const Separator = () => (
  <svg
    data-qatag="svg_separator"
    className={styles.svgContainer}
    viewBox="0 0 10.393159 15.7"
    height="15.7"
    width="10.393159"
  >
    <g data-qatag="svg_group" transform="rotate(90,3.937,4.933)">
      <path
        data-qatag="svg_path"
        className={styles.gt}
        transform="rotate(180,518.092,30.844)"
        d="m 1022.48,53.818 6.9,7.87 6.8,-7.87"
      />
    </g>
  </svg>
);

function buildPath(path: string, pathsMap: PathsMap) {
  const locations = path.split("/");

  if (locations.length === 2 && locations[1] === "") {
    return (
      <Link data-qatag="breadcrumbs_link" className={styles.path} to={path}>
        {getFriendlyPathName("/", pathsMap)}
      </Link>
    );
  }
  const cleanedLocations = locations.filter((e) => e);
  return cleanedLocations.map((location, index) => {
    const isLast = index === cleanedLocations.length - 1;
    if (isLast) {
      return (
        <span
          key={index}
          data-qatag="breadcrumbs_link"
          className={styles.lastPath}
        >
          {getFriendlyPathName(location, pathsMap)}
        </span>
      );
    }

    if (index === 0) {
      return (
        <Fragment key={index}>
          <Link
            data-qatag="breadcrumbs_link"
            to={`/${location}`}
            className={styles.path}
          >
            {getFriendlyPathName(location, pathsMap)}
          </Link>
          <Separator data-qatag="breadcrums_separator" />
        </Fragment>
      );
    }

    return (
      <Fragment key={index}>
        <Link
          data-qatag="breadcrumbs_link"
          to={location}
          className={styles.path}
        >
          {getFriendlyPathName(location, pathsMap)}
        </Link>
        <Separator data-qatag="breadcrums_separator" />
      </Fragment>
    );
  });
}

/**
 * Returns friendly path name if exists in the map.
 * @param pathName location path name
 * @param pathsMap map of the fiendly names for each path location
 */
function getFriendlyPathName(pathName: string, pathsMap: PathsMap) {
  return pathsMap[pathName] || pathName;
}

/**
 * Gets the route state, and creates a map between the path and its friendly name.
 * @param state Route state.
 * @returns Map of paths. Maps the path with the friendly name alternative.
 */
function getPathMapFromRouteState(state: IRouteState): PathsMap {
  const friendlyNameArray = state.friendlyName.split("/").filter((e) => e);
  if (friendlyNameArray.length === 0) {
    return {};
  }
  const fromArray = state.from.split("/").filter((e) => e);
  const pathsMap: PathsMap = {};
  for (let index = 0; index < fromArray.length; index++) {
    pathsMap[fromArray[index]] = friendlyNameArray[index];
  }
  return pathsMap;
}
