import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { RootState } from "app/store";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import { IRebootPayload } from "model/messaging/payloads/IRebootPayload";
import { IServiceMessage, ServiceMessage, WSMessageType } from "ui.common";
import { selectCurrentUuid } from "session/SessionSlice";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISystemState {}

export const rebootComputer = createAsyncThunk<
  void,
  IRebootPayload,
  {
    state: RootState;
  }
>("system/rebootComputer", async (payload, thunkApi) => {
  try {
    const srhub = getSignalRHub();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.DO_SYSTEM_REBOOT;
    message.Payload = payload;
    return srhub.SendAsync(message);
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to reboot PC : ${error}`);
  }
});

const initialState: IReducerState<ISystemState> = {
  data: {},
  status: {
    [rebootComputer.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const SystemSlice = createSlice({
  name: "System",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(rebootComputer.pending, (state) => {
      state.status[rebootComputer.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(rebootComputer.rejected, (state, action) => {
      state.status[rebootComputer.typePrefix] = ReducerStatus.Failed;
      state.error = action.payload as string;
    });
    builder.addCase(rebootComputer.fulfilled, (state) => {
      state.status[rebootComputer.typePrefix] = ReducerStatus.Succeeded;
    });
  },
});

// actions
// eslint-disable-next-line no-empty-pattern
export const {} = SystemSlice.actions;

export default SystemSlice.reducer;
