import { LogLevel } from "@microsoft/signalr";
import { SignalRPeerMessageHub } from "ui.common";

declare let gatewayUrl: string;

const signalRHub = new SignalRPeerMessageHub(
  `${gatewayUrl}/webUiHub`,
  "",
  LogLevel.Trace,
  true
);

export const getSignalRHub = () => signalRHub;
