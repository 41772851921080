import styles from "./HelpScreen.module.css";
import headerBackground from "assets/img/banner-darktolightteal.svg";
import phoneImage from "assets/img/help/icn-phone-contact.svg";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import {
  AppButton,
  AppButtonStyles,
  Panel,
  SimpleTextField,
  Spinner,
} from "solveiq.designsystem";
import { Field, Form, Formik } from "formik";
import { RootState } from "app/store";
import IUser from "model/user/IUser";
import {
  selectCurrentUser,
  selectIsRemote,
  selectUserDescriptor,
} from "session/SessionSlice";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import * as yup from "yup";
import { submitHelpForm } from "./HelpSlice";
import { TicketSubmittedDialog } from "./TicketSubmittedDialog";
import { RouterBreadcrumbs } from "features/teamManagement/employeeDetail/RouterBreadcrumbs";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { useHistory } from "react-router-dom";

export interface IHelpForm {
  name: string;
  email: string;
  subject: string;
  description: string;
}

export interface IHelpScreenProps {
  userSelector?: (state: RootState) => IUser | null;
}

export const HelpScreen: React.FC<IHelpScreenProps> = ({
  userSelector = selectCurrentUser,
}) => {
  const prefix = "helpScreen.view";
  const history = useHistory();
  const ticketsPath = "/tickets";
  const user = useSelector(userSelector);
  const isRemote = useSelector(selectIsRemote);
  const userDescriptor = useSelector(selectUserDescriptor);
  const dispatch = useDispatch();

  const initialValues: IHelpForm = useMemo(
    () => ({
      name: user ? `${user?.firstName} ${user.lastName}` : "",
      email: user?.email ?? "",
      subject: "",
      description: "",
    }),
    [user]
  );
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
    subject: yup.string().required("Subject is required"),
    description: yup.string().required("Description is required"),
  });

  const [showSubmittedDialog, setShowSubmittedDialog] = useState<boolean>(
    false
  );
  const [showSubmittingDialog, setShowSubmittingDialog] = useState<boolean>(
    false
  );

  const onSubmit = async (values: IHelpForm) => {
    setShowSubmittingDialog(true);
    await dispatch(submitHelpForm(values));
    setShowSubmittingDialog(false);
    setShowSubmittedDialog(true);
  };

  const onSubmittedDialogClose = () => {
    setShowSubmittedDialog(false);
    history.push(ticketsPath);
  };

  return (
    <div data-qatag="helpScreen" className={styles.helpScreen}>
      <div data-qatag="header" className={styles.header}>
        {isRemote ? (
          <RouterBreadcrumbs
            data-qatag="helpRemoteBreadcrumbs"
            style={{ width: "100%", height: "42px", alignSelf: "center" }}
            descriptors={[
              {
                to: `/employeeDetail/${user?.id}`,
                label: userDescriptor,
              },
              {
                to: "/",
                label: "Help",
              },
            ]}
          ></RouterBreadcrumbs>
        ) : (
          <Breadcrumbs
            data-qatag="helpBreadcrumbs"
            pathsMap={{ help: "Help" }}
          />
        )}
        <div data-qatag="headerBackground" className={styles.headerBackground}>
          <img data-qatag="" src={headerBackground} alt="" />
        </div>
        <Typography
          data-qatag="headerTitle"
          className={styles.headerTitle}
          variant="h2"
        >
          <FormattedMessage
            data-qatag="headerTitleMessage"
            id={`${prefix}.header.title`}
            defaultMessage="How can we {emphasis} today?"
            values={{
              emphasis: (
                <span
                  data-qatag="titleEmphasis"
                  className={styles.titleEmphasis}
                >
                  <FormattedMessage
                    data-qatag="headerTitleEmphasisMessage"
                    id={`${prefix}.header.title.emphasis`}
                    defaultMessage="help you"
                  />
                </span>
              ),
            }}
          />
        </Typography>
        <Typography
          data-qatag="headerDescription"
          className={styles.headerDescription}
          variant="h6"
        >
          <FormattedMessage
            data-qatag="headerDescriptionMessage"
            id={`${prefix}.header.description`}
            defaultMessage="Select your preferred contact method below."
          />
        </Typography>
      </div>
      <Panel data-qatag="body" className={styles.body}>
        <div data-qatag="formWrapper" className={styles.formWrapper}>
          <Typography
            data-qatag="bodyTitle"
            className={styles.bodyTitle}
            variant="h4"
          >
            <FormattedMessage
              data-qatag="bodyTitleMessage"
              id={`${prefix}.body.title`}
              defaultMessage="Submit a Ticket"
            />
          </Typography>
          <Typography
            data-qatag="bodyDescription"
            className={styles.bodyDescription}
            variant="body2"
          >
            <FormattedMessage
              data-qatag="bodyDescriptionMessage"
              id={`${prefix}.body.description`}
              defaultMessage="What can we help you with today?"
            />
          </Typography>
          <Formik
            data-qatag=""
            onSubmit={(values, { resetForm }) => {
              onSubmit(values);
              resetForm({ values: initialValues });
            }}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ submitForm, isSubmitting }) => (
              <Form data-qatag="" className={styles.helpForm}>
                <Field
                  data-qatag="help.form.field.subject"
                  id="help.form.field.subject"
                  component={SimpleTextField}
                  name="name"
                  type="text"
                  label="Name"
                  disabled={true}
                />
                <Field
                  data-qatag="help.form.field.subject"
                  id="help.form.field.subject"
                  component={SimpleTextField}
                  name="email"
                  type="email"
                  label="Email Address"
                  disabled={true}
                />
                <Field
                  data-qatag="help.form.field.subject"
                  id="help.form.field.subject"
                  component={SimpleTextField}
                  name="subject"
                  type="text"
                  label="Subject"
                />
                <Field
                  data-qatag="help.form.field.description"
                  id="help.form.field.description"
                  component={SimpleTextField}
                  type="text"
                  label="Description"
                  name="description"
                  multiline
                  rows={10}
                />
                <Typography
                  data-qatag="formInstructions"
                  className={styles.formInstructions}
                  variant="body2"
                >
                  <FormattedMessage
                    data-qatag="formInstructionsMessage"
                    id={`${prefix}.body.formInstructions`}
                    defaultMessage="Please enter the details of your request. A member of our support staff will respond as soon as possible."
                  />
                </Typography>
                <AppButton
                  data-qatag=""
                  buttonStyle={AppButtonStyles.Green}
                  disabled={isSubmitting}
                  className={styles.submitButton}
                >
                  <Typography
                    data-qatag="submitButtonText"
                    className={styles.submitButtonText}
                    variant="body2"
                  >
                    <FormattedMessage
                      data-qatag="submitButtonMessage"
                      id={`${prefix}.body.submitButton`}
                      defaultMessage="Submit"
                    />
                  </Typography>
                </AppButton>
              </Form>
            )}
          </Formik>
        </div>
        <div data-qatag="footer" className={styles.footer}>
          <img
            data-qatag=""
            src={phoneImage}
            className={styles.phoneImage}
            alt=""
          />
          <div
            data-qatag="footerTextContainer"
            className={styles.footerTextContainer}
          >
            <Typography
              data-qatag="footerTitle"
              className={styles.footerTitle}
              variant="body2"
            >
              <FormattedMessage
                data-qatag="footerTitleMessage"
                id={`${prefix}.footer.title`}
                defaultMessage="Your Contact Options"
              />
            </Typography>
            <Typography
              data-qatag="phoneNumber"
              className={styles.phoneNumber}
              variant="body1"
            >
              <FormattedMessage
                data-qatag=""
                id={`${prefix}.footer.phoneNumber`}
                defaultMessage="Phone: 1-855-430-9401"
              />
            </Typography>
            <Typography
              data-qatag="phoneSupport"
              className={styles.phoneSupport}
              variant="body1"
            >
              <FormattedMessage
                data-qatag=""
                id={`${prefix}.footer.phoneSupport`}
                defaultMessage="Phone support is available 7 days a week from 7AM-8PM (CST)"
              />
            </Typography>
          </div>
        </div>
      </Panel>
      <Spinner
        data-qatag=""
        isActive={showSubmittingDialog}
        text="Submitting..."
      />
      <TicketSubmittedDialog
        data-qatag=""
        isOpen={showSubmittedDialog}
        closeDialogHandler={onSubmittedDialogClose}
      />
    </div>
  );
};
