import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  SelectOptionInterface as IOption,
  Spinner,
  Alert,
  AlertIcon,
  IRouteState,
} from "solveiq.designsystem";
import { RootState } from "app/store";
import Form from "./Form";
import {
  getMachineBrand,
  getProductLine,
  getProductModel,
  fetchManufacturers,
  resetUpdate,
  getLoadingStatus,
  getUpdateStatus,
} from "./MachinePickerSlice";
import {
  fetchSystemInfoScan,
  selectDeviceOverview,
} from "features/scan/ScanSlice";
import { useMatchTabletVertical } from "solveiq.designsystem";
import { IManufacturers, IProductLine, IModel } from "./types";
import { ISystemInfoInfo } from "model/scan/SystemInfo";
import { IntelligenceTypes } from "model/messaging/payloads/IUpdateMachineIntelligencePayload";
import { ReducerStatus } from "model/IReducerState";
import { useHistory } from "react-router";
import MachinePickerHeader from "./Header/Header";
import backgroundImage_large from "assets/img/bg-machine-picker_1920.svg";
import backgroundImage from "assets/img/bg-machine-picker_1200.svg";
import backgroundImage_laptop from "assets/img/bg-machine-picker_1024.svg";
import backgroundImage_tablet from "assets/img/bg-machine-picker_768.svg";
import backgroundImage_mobile from "assets/img/bg-machine-picker_360.svg";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    background: {},
    backgroundContainer: {
      position: "absolute",

      top: "-25px",
      width: "100%",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        top: "-5px",
      },
    },
    container: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        background: "#FFFFFF",
      },
    },
    headerContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: 10,
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px",
      },
    },
    formContainer: { zIndex: 10 },
  })
);
//#endregion Component Styles

export interface IMachinePicker {
  /* Select Your Machine Brand */
  machineBrandSelector?: (state: RootState) => IManufacturers[];
  /* Select Your Product Line */
  productLineSelector?: (state: RootState) => IProductLine[];
  /* Select Your Product Model */
  productModelSelector?: (state: RootState) => IModel[];
  /* Retrieve default system info */
  getSystemInfoSelector?: (state: RootState) => ISystemInfoInfo[];
  /* Default option for Intelligence Type Options for select component */
  defaultIntelligence?: IntelligenceTypes;
  /* Default option for Select Your Machine Brand */
  defaultMachineBrand?: string;
  /* Default option for Select Your Product Line */
  defaultProductLine?: string;
  /* Default option for Select Your Product Model */
  defaultProductModel?: string;
  /* Skip machine picker handler in case the computer is totally detected */
  skipHandler?: () => void;
}

const MachinePicker: React.FC<IMachinePicker> = ({
  machineBrandSelector = (state: RootState) => getMachineBrand(state),
  productLineSelector = (state: RootState) => getProductLine(state),
  productModelSelector = (state: RootState) => getProductModel(state),
  getSystemInfoSelector = (state: RootState) => selectDeviceOverview(state),
  defaultIntelligence = IntelligenceTypes.OEM,
  defaultMachineBrand,
  defaultProductLine,
  defaultProductModel,
  skipHandler,
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "machine.picker";
  /* Responsive web design */
  const matchTablet = useMatchTabletVertical();
  /* Redux Store dispatcher */
  const dispatch = useDispatch();
  //Redirect to Scan view
  const history = useHistory<IRouteState>();

  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchManufacturers());
  }, [dispatch]);

  /* Select Your Machine Brand */
  const machineBrand = useSelector<RootState>(
    machineBrandSelector
  ) as IManufacturers[];
  /* Select Your Product Line */
  const productLine = useSelector<RootState>(productLineSelector) as IOption[];
  /* Select Your Product Model */
  const productModel = useSelector<RootState>(
    productModelSelector
  ) as IOption[];
  /* Computer model */
  const deviceOverview = useSelector(selectDeviceOverview);

  useEffect(() => {
    dispatch(fetchSystemInfoScan());
  }, [dispatch]);

  // Status for loading a dependency
  const statusDependency = useSelector(getLoadingStatus);
  // Status Update machine intelligence
  const statusUpdateMachine = useSelector(getUpdateStatus);

  useEffect(() => {
    if (statusUpdateMachine === ReducerStatus.Succeeded) {
      dispatch(resetUpdate()); // To enable revisit the machine picker
      history.push("/scan");
    }
  }, [dispatch, history, statusUpdateMachine]);

  /*
   * useEffect to determine if we need to use the skipHandler.
   * Should run only on first render, after updating the cascading selects
   * or when users are specifying their computer manually this useEffect shouldn't be executed.
   */
  /* TODO: Computer information to validate may change after implementation with agent */
  useEffect(() => {
    // If brand, line and model are defined we can use the skipHandler
    if (productLine.length && machineBrand.length && productModel.length) {
      if (skipHandler) skipHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isLaptop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  let bgImage = backgroundImage_large;
  if (isDesktop) {
    bgImage = backgroundImage;
  }
  if (isLaptop) {
    bgImage = backgroundImage_laptop;
  }
  if (isTablet) {
    bgImage = backgroundImage_tablet;
  }
  if (isMobile) {
    bgImage = backgroundImage_mobile;
  }

  return (
    <div data-qatag="container" className={classes.container}>
      <Box
        data-qatag="machinepicker.background_container"
        id="machinepicker.background_container"
        className={classes.backgroundContainer}
      >
        <div
          data-qatag="machinepicker.background.gutter_left"
          id="machinepicker.background.gutter_left"
          style={{ background: "#0080A2", flexGrow: 1 }}
        />
        <img
          data-qatag="machinepicker.container.background"
          id="machinepicker.container.background"
          src={bgImage}
          className={classes.background}
          alt="background"
        />
        <div
          data-qatag="machinepicker.background.gutter_left"
          id="machinepicker.background.gutter_left"
          style={{ background: "#00CACD", flexGrow: 1 }}
        />
      </Box>
      <Box data-qatag="headerContainer" className={classes.headerContainer}>
        <MachinePickerHeader
          data-qatag="header"
          message={formatMessage({
            id: `${prefix}.driver.support`,
            defaultMessage: "Solve iQ has identified your computer as a",
          })}
          computerModel={deviceOverview.deviceName ?? "Custom Machine"}
          headerImage={deviceOverview.pcImage}
        />
      </Box>
      <Box
        data-qatag="formContainer"
        mt={3}
        mr={matchTablet ? 3 : 2}
        ml={matchTablet ? 3 : 2}
        className={classes.formContainer}
      >
        <Form
          data-qatag="form"
          defaultIntelligence={defaultIntelligence}
          machineBrandSelector={machineBrandSelector}
          productLineSelector={productLineSelector}
          productModelSelector={productModelSelector}
        />
      </Box>
      {/* Success Alert*/}
      {/* <Alert
        data-qatag="alertSuccess"
        icon={AlertIcon.Success}
        title="Complete"
        text="Your machine intelligence was saved"
        buttonText="Continue"
        approveHandler={() => {
          // Reset Update Status
          dispatch(resetUpdate());
          // Redirect to scan view
          history.push("/scan");
        }}
        isOpen={ReducerStatus[statusUpdateMachine] === ReducerStatus.Succeeded}
        closeHandler={() => {
          // Reset Update Status
          dispatch(resetUpdate());
          // Redirect to scan view
          history.push("/scan");
        }}
      /> */}
      {/* Alert error */}
      <Alert
        data-qatag="alertError"
        icon={AlertIcon.Warning}
        title="Something went wrong"
        text="Unable to update machine intelligence"
        buttonText="Continue"
        approveHandler={() => {
          // Reset Update Status
          dispatch(resetUpdate());
        }}
        isOpen={ReducerStatus[statusUpdateMachine] === ReducerStatus.Failed}
        closeHandler={() => {
          // Reset Update Status
          dispatch(resetUpdate());
        }}
      />
      {/* Loading Modal */}
      <Spinner
        data-qatag="loadingModal"
        isActive={
          ReducerStatus[statusUpdateMachine] === ReducerStatus.Loading ||
          ReducerStatus[statusDependency] === ReducerStatus.Loading
        }
        text="Loading..."
      />
    </div>
  );
};

export default MachinePicker;
