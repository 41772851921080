import { Typography, Box, IconButton, Dialog } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import visaIcon from "assets/icon/icn-visa.svg";
import mastercardIcon from "assets/icon/icn-mastercard.svg";
import discoverIcon from "assets/icon/icn-discover.svg";
import amexIcon from "assets/icon/icn-amex.svg";
import paymentIcon from "assets/icon/icn-payment-method.svg";
import PayPalLogo from "assets/img/PayPalLogo2x.png";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./AddPaymentMethod.module.css";
import { useState } from "react";
import { CardForm, PayPalForm } from ".";

export interface IAddPaymentMethod {
  /* Controls to open the modal */
  isOpen: boolean;
  /* Callback to update the isOpen on the parent*/
  handleOnClose: () => void;
}

/* Custom style for Dialog */
const useStyles = makeStyles((theme: Theme) => ({
  paper: { maxWidth: "624px", width: "100%", height: "740px" },
  titleText: {
    color: theme.palette.secondary.light,
    fontWeight: 600,
    marginLeft: 8,
  },
}));

const AddPaymentMethod: React.FC<IAddPaymentMethod> = ({
  isOpen = true,
  handleOnClose,
}) => {
  // Custom styles
  const classes = useStyles();
  // State to display either the card form or the PayPal view
  const [paymentMethod, setPaymentMethod] = useState("card");

  return (
    <Dialog
      data-qatag="Dialog"
      open={isOpen}
      onClose={handleOnClose}
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <div data-qatag="container" className={styles.container}>
        <div data-qatag="content" className={styles.content}>
          {/* Close Icon */}
          <div
            data-qatag="closeIconContainer"
            className={styles.closeIconContainer}
          >
            <IconButton
              data-qatag="closeIcon"
              aria-label="close"
              onClick={handleOnClose}
              className={styles.closeIcon}
            >
              <CloseIcon data-qatag="CloseIcon" />
            </IconButton>
          </div>

          {/* Title */}
          <Box data-qatag="Title" display="flex" alignItems="center" mt={7}>
            <img
              data-qatag="titleIcon"
              className={styles.titleIcon}
              src={paymentIcon}
              alt="wire-frame"
            />
            <Typography
              data-qatag="titleText"
              variant="h6"
              className={classes.titleText}
              color="primary"
            >
              Add Payment Method
            </Typography>
          </Box>

          {/* Radio button options */}
          <Box
            data-qatag="options"
            display="flex"
            alignItems="center"
            mt={3}
            justifyContent="space-between"
          >
            <Box data-qatag="optionPayment" display="flex" alignItems="center">
              <input
                data-qatag="card"
                id="card"
                type="radio"
                value="card"
                name="payment"
                checked={paymentMethod === "card"}
                onChange={(e) => setPaymentMethod(e.target.value)}
              />
              <label
                data-qatag="labelContainer"
                htmlFor="card"
                className={styles.labelContainer}
              >
                <span data-qatag="cardText" className={styles.cardText}>
                  Credit/Debit Card
                </span>
                <img
                  data-qatag="creditCardIcon"
                  className={styles.creditCardIcon}
                  src={visaIcon}
                  alt="Visa"
                />
                <img
                  data-qatag="creditCardIcon"
                  className={styles.creditCardIcon}
                  src={mastercardIcon}
                  alt="Mastercard"
                />
                <img
                  data-qatag="creditCardIcon"
                  className={styles.creditCardIcon}
                  src={discoverIcon}
                  alt="Discover"
                />
                <img
                  data-qatag="creditCardIcon"
                  className={styles.creditCardIcon}
                  src={amexIcon}
                  alt="American Express"
                />
              </label>
            </Box>
            <Box data-qatag="PayPalBox" display="flex" alignItems="center">
              <input
                data-qatag="PayPal"
                id="PayPal"
                type="radio"
                value="PayPal"
                name="payment"
                checked={paymentMethod === "PayPal"}
                onChange={(e) => setPaymentMethod(e.target.value)}
              />
              <label
                data-qatag="labelContainer"
                htmlFor="PayPal"
                className={styles.labelContainer}
              >
                <img
                  data-qatag="PayPalIcon"
                  className={styles.PayPalIcon}
                  src={PayPalLogo}
                  alt="American Express"
                />
              </label>
            </Box>
          </Box>
          {paymentMethod === "card" ? (
            <CardForm data-qatag="CardForm" handleOnClose={handleOnClose} />
          ) : (
            <PayPalForm data-qatag="PayPalForm" handleOnClose={handleOnClose} />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default AddPaymentMethod;
