import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Panel,
  SearchTextField,
  Accordion,
  AppButton,
  AppButtonStyles,
  Spinner,
} from "solveiq.designsystem";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  IconButton,
  TextField,
  Divider,
  Typography,
} from "@material-ui/core";
import styles from "./WindowsEnhancements.module.css";
import filterOptions from "assets/img/category-settings.png";
import gears from "assets/img/threeGears.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSystemOptimizationScan,
  fetchSystemOptimizationScanStatus,
  selectAllOptimizations,
  selectSystemIssueCount,
} from "features/scan/ScanSlice";
import { ISystemOptimizationInfo } from "model/scan/SystemOptimizationInfo";
import settingsCategory from "assets/img/category-settings.png";
import Fuse from "fuse.js";
import { debounce } from "core/Helpers";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { RouterBreadcrumbs } from "features/teamManagement/employeeDetail/RouterBreadcrumbs";
import {
  selectIsRemote,
  selectCurrentUser,
  selectUserDescriptor,
} from "session/SessionSlice";
import Header from "features/Optimization/Header";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { RootState } from "app/store";
import IUser from "model/user/IUser";
import {
  selectIsAgentLocal,
  selectOptimizationsFinalized,
  selectRebootRequired,
} from "app/Agent/AgentSlice";
import alertIcon from "assets/icon/icn-orangeAlert.svg";
import { RebootDialog } from "features/status/rebootDialog/RebootDialog";
import { ReducerStatus } from "model/IReducerState";

const prefix = "windowsEnhancements.view";

export interface IWindowsEnhancementsProps {
  optimizationsSelector?: (state: RootState) => ISystemOptimizationInfo[];
  issueCountSelector?: (state: RootState) => number;
  isRemoteSelector?: (state: RootState) => boolean;
  currentUserSelector?: (state: RootState) => IUser | null;
  userDescriptorSelector?: (state: RootState) => string;
  rebootRequiredSelector?: (state: RootState) => boolean;
  isAgentLocalSelector?: (state: RootState) => boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  rebootPanel: {},
  rebootPanelContent: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-evenly",
  },
  desktopTextContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    textAlign: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    "& img": {
      width: "24px",
      height: "24px",
      marginRight: "15px",
    },
  },
  rebootPanelTitle: {
    fontWeight: 600,
  },
  rebootButtonContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    textAlign: "center",
    alignItems: "center",
  },
  rebootButton: {
    width: "255px",
    [theme.breakpoints.down("sm")]: {
      margin: "5px 0",
    },
  },
  mobileText: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
  },
  titleText: {
    color: theme.palette.secondary.light,
  },
  indicator: {
    color: theme.palette.secondary.light,
  },
  searchIcon: {
    color: theme.palette.info.light,
  },
}));

const WindowsEnhancements: React.FC<IWindowsEnhancementsProps> = ({
  optimizationsSelector = selectAllOptimizations,
  issueCountSelector = selectSystemIssueCount,
  isRemoteSelector = selectIsRemote,
  currentUserSelector = selectCurrentUser,
  userDescriptorSelector = selectUserDescriptor,
  rebootRequiredSelector = (state: RootState) =>
    !selectOptimizationsFinalized(state),
  isAgentLocalSelector = selectIsAgentLocal,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const allOptimizations = useSelector(optimizationsSelector);
  const systemIssueCount = useSelector(issueCountSelector);
  const isRemote = useSelector(isRemoteSelector);
  const user = useSelector(currentUserSelector);
  const userDescriptor = useSelector(userDescriptorSelector);
  const rebootRequired = useSelector(rebootRequiredSelector);
  const isAgentLocal = useSelector(isAgentLocalSelector);

  const [showRebootDialog, setShowRebootDialog] = useState(false);

  useEffect(() => {
    dispatch(fetchSystemOptimizationScan());
  }, [dispatch]);

  const fetchStatus = useSelector(fetchSystemOptimizationScanStatus);

  return (
    <div data-qatag="container" className={styles.container}>
      {isRemote ? (
        <RouterBreadcrumbs
          data-qatag="windowEnhancements.screen.remoteBreadcrumbs"
          style={{ width: "100%", height: "42px", alignSelf: "center" }}
          descriptors={[
            {
              to: `/employeeDetail/${user?.id}`,
              label: userDescriptor,
            },
            {
              to: "/",
              label: "Active OS Enhancements",
            },
          ]}
        ></RouterBreadcrumbs>
      ) : (
        <Breadcrumbs
          data-qatag="windowsEnhancementsBreadcrumbs"
          pathsMap={{
            windowsenhancements: "Active OS Enhancements",
          }}
        />
      )}

      <Header
        data-qatag="headerWindowsEnhancements"
        appsNumber={systemIssueCount}
        title={
          <FormattedMessage
            data-qatag="headerTitleWindowsEnhancements"
            id={`${prefix}.header.wh-title`}
            description="Windows Enhancements title"
            defaultMessage="Active OS Enhancements"
          />
        }
        subtitle={
          <FormattedMessage
            data-qatag="windowsenhancements.subtitle"
            id="windowsenhancements.subtitle"
            defaultMessage="The following {systemIssueCount} system-wide modifications {modificationState} to improve networking throughput and system responsiveness."
            values={{
              systemIssueCount: (
                <span data-qatag="subtitleCount" className={classes.indicator}>
                  {systemIssueCount}
                </span>
              ),
              modificationState: rebootRequired
                ? "will be applied after reboot"
                : "have been applied",
            }}
          />
        }
      />
      {rebootRequired && (
        <Panel data-qatag="rebootPanel" className={styles.rebootPanel}>
          <div
            data-qatag="rebootPanelContent"
            className={classes.rebootPanelContent}
          >
            <div
              data-qatag="desktopTextContainer"
              className={classes.desktopTextContainer}
            >
              <div
                data-qatag="titleContainer"
                className={classes.titleContainer}
              >
                <img data-qatag="alertIcon" src={alertIcon} alt="alert" />
                <Typography
                  data-qatag="rebootPanelTitle"
                  variant="body2"
                  className={classes.rebootPanelTitle}
                >
                  <FormattedMessage
                    data-qatag="rebootPanel.title"
                    id={`${prefix}.rebootPanel.title`}
                    defaultMessage="Reboot Required"
                  />
                </Typography>
              </div>
              <Typography data-qatag="body2" variant="body2">
                <FormattedMessage
                  data-qatag="rebootPanel.description"
                  id={`${prefix}.rebootPanel.description`}
                  defaultMessage={`There {settingsCount, plural, 
              one {is {formattedCount} Windows setting that requires}
              other {are {formattedCount} Windows settings that require}}
              a reboot`}
                  values={{
                    settingsCount: systemIssueCount,
                    formattedCount: (
                      <span
                        data-qatag="subtitleCount"
                        className={styles.subtitleCount}
                      >
                        {systemIssueCount}
                      </span>
                    ),
                  }}
                />
              </Typography>
            </div>
            <div
              data-qatag="rebootButtonContainer"
              className={classes.rebootButtonContainer}
            >
              <Typography
                data-qatag="rebootPanelTitle"
                variant="body2"
                className={`${classes.rebootPanelTitle} ${classes.mobileText}`}
              >
                <FormattedMessage
                  data-qatag="reboot.Required"
                  id={`${prefix}.rebootPanel.title`}
                  defaultMessage="Reboot Required"
                />
              </Typography>
              {isAgentLocal && (
                <AppButton
                  data-qatag="rebootButton"
                  buttonStyle={AppButtonStyles.Green}
                  className={classes.rebootButton}
                  onClick={() => setShowRebootDialog(true)}
                >
                  <Typography data-qatag="buttonText" variant="body2">
                    <FormattedMessage
                      data-qatag="rebootButtonMessage"
                      id={`${prefix}.rebootPanel.button`}
                      defaultMessage="Reboot Now"
                    />
                  </Typography>
                </AppButton>
              )}
              <Typography
                data-qatag="mobileText"
                variant="body2"
                className={classes.mobileText}
              >
                <FormattedMessage
                  data-qatag="formattedMessage"
                  id={`${prefix}.rebootPanel.description`}
                  defaultMessage={`There {settingsCount, plural, 
              one {is {formattedCount} Windows setting that requires}
              other {are {formattedCount} Windows settings that require}}
              a reboot`}
                  values={{
                    settingsCount: systemIssueCount,
                    formattedCount: (
                      <span
                        data-qatag="subtitleCount"
                        className={styles.subtitleCount}
                      >
                        {systemIssueCount}
                      </span>
                    ),
                  }}
                />
              </Typography>
            </div>
            <RebootDialog
              data-qatag="rebootDialog"
              isOpen={showRebootDialog}
              closeDialogHandler={() => setShowRebootDialog(false)}
            />
          </div>
        </Panel>
      )}
      <OptimizationList
        data-qatag="optimizationList"
        allOptimizations={allOptimizations}
        keys={["friendlyName", "description"]}
      />
      <Spinner
        data-qatag="loadingModal"
        text="Loading..."
        isActive={fetchStatus === ReducerStatus.Loading}
      />
    </div>
  );
};

export default WindowsEnhancements;

interface IOptimizationList {
  allOptimizations: ISystemOptimizationInfo[];
  /**
   * Properties of the SystemOptimizationInfo model to use when
   * searching for an optimization
   */
  keys: Array<keyof ISystemOptimizationInfo>;
}

/**
 * Transforms each optimization to a Optimization component
 * @param optimizations List of optimizations
 */
const optimizationsToComponents = (optimizations: ISystemOptimizationInfo[]) =>
  [...optimizations].map((opt, index) => (
    <Optimization
      data-qatag="optimizationItem"
      key={`${opt.friendlyName}_${index}`}
      optimization={opt}
    />
  ));

const OptimizationList: React.FC<IOptimizationList> = ({
  allOptimizations,
  keys,
}) => {
  const { formatMessage } = useIntl();
  const [searchText, setSearchText] = useState("");
  const [filteredOptimizations, setFilteredOptimizations] = useState<
    React.ReactElement[]
  >(optimizationsToComponents(allOptimizations));

  /* Custom styles to overwrite theme */
  const classes = useStyles();

  // Builds a new Fuse object to filter optimizations
  const optimizationsToFilter = useMemo(() => {
    const options = { ignoreLocation: true, includeScore: true, keys };
    return new Fuse(allOptimizations, options);
  }, [keys, allOptimizations]);

  // Memoized debounced function to set filtered optimizations
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedSetFilteredOptimizations = useCallback(
    debounce((values: React.ReactElement[]) => {
      setFilteredOptimizations(values);
    }, 300),
    [setFilteredOptimizations]
  );

  useEffect(() => {
    // User is not searching for optimizations
    if (searchText.trim().length === 0) {
      memoizedSetFilteredOptimizations(
        optimizationsToComponents(allOptimizations)
      );
    }
    // User is searching for optimizations
    else {
      memoizedSetFilteredOptimizations(
        optimizationsToFilter
          .search(searchText)
          .filter((item) => (item.score || 1) < 0.1)
          .map((element, index) => (
            <Optimization
              data-qatag="optimizationItem"
              key={`${element.item.friendlyName}_${index}`}
              optimization={element.item}
            />
          ))
      );
    }
  }, [
    searchText,
    allOptimizations,
    memoizedSetFilteredOptimizations,
    optimizationsToFilter,
  ]);

  const onSearchType = (searchValue: string) => {
    setSearchText(searchValue);
  };

  return (
    <Panel data-qatag="settings" className={styles.settings}>
      <Box data-qatag="filters" className={styles.filters}>
        <div data-qatag="label" className={styles.label}>
          <img
            data-qatag="filterOptions"
            className={styles.filterOptions}
            src={filterOptions}
            alt="Settings"
          />
          <span data-qatag="filterLabel" className={styles.filterLabel}>
            {formatMessage({ id: `${prefix}.devices.filters.label` })}
          </span>
        </div>
        {/* Search for tablet */}
        <div data-qatag="searchComponent" className={styles.searchTablet}>
          <SearchTextField
            data-qatag="textSearchOptimizations"
            containerClassName={styles.search}
            placeholder={formatMessage({ id: `${prefix}.devices.textSearch` })}
            value={searchText}
            onChange={onSearchType}
            displayDivider={false}
          />
        </div>
        {/* Search for Mobile */}
        <Box
          data-qatag="actions"
          className={styles.searchMobileContainer}
          display="flex"
          alignItems="center"
        >
          <IconButton
            data-qatag="closeIcon"
            aria-label="close"
            className={styles.closeIcon}
          >
            <SearchIcon
              data-qatag="searchIcon"
              className={classes.searchIcon}
            />
          </IconButton>
          <TextField
            data-qatag="standard-basic"
            id="standard-basic"
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            className={styles.searchTextField}
            label="Search"
          />
        </Box>
      </Box>
      <Box
        data-qatag="dividerMobileContainer"
        width="100%"
        mb={2}
        mt={2}
        className={styles.dividerMobile}
      >
        <Divider data-qatag="dividerMobile" />
      </Box>
      {filteredOptimizations.length > 0
        ? filteredOptimizations
        : "No results were found"}
    </Panel>
  );
};

interface IOptimization {
  optimization: ISystemOptimizationInfo;
}

const Optimization: React.FC<IOptimization> = ({ optimization }) => {
  return (
    <>
      {/* Normal */}
      <div data-qatag="optimization" className={styles.optimization}>
        <img
          data-qatag="deviceImage"
          className={styles.deviceImage}
          src={settingsCategory}
          alt="Optimization"
        />
        <div data-qatag="details" className={styles.details}>
          <p data-qatag="optimizationName" className={styles.optimizationName}>
            {optimization.friendlyName.trim()}
          </p>
          <p
            data-qatag="optimizationDescription"
            className={styles.optimizationDescription}
          >
            {optimization.description}
          </p>
        </div>
      </div>
      <div data-qatag="accordion" className={styles.accordion}>
        <Accordion
          details={
            <Box data-qatag="totalContainerMobile" mt={2.75} width="100%">
              <div data-qatag="details" className={styles.details}>
                <p
                  data-qatag="optimizationDescription"
                  className={styles.optimizationDescription}
                >
                  {optimization.description}
                </p>
              </div>
            </Box>
          }
          summary={
            <Box
              data-qatag="optimizationContainer"
              display="flex"
              alignItems="center"
              width="100%"
              className={styles.optimizationContainer}
            >
              <img
                data-qatag="deviceImage"
                className={`${styles.deviceImage} ${styles.deviceImageMobile}`}
                src={settingsCategory}
                alt="Optimization"
              />
              <p
                data-qatag="optimizationName"
                className={styles.optimizationName}
              >
                {optimization.friendlyName.trim()}
              </p>
            </Box>
          }
          data-qatag="accordionMobile"
        />
      </div>
      <Box
        data-qatag="dividerMobileContainer"
        width="100%"
        mt={2}
        className={styles.dividerMobile}
      >
        <Divider data-qatag="dividerMobile" />
      </Box>
    </>
  );
};
