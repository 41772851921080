import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import noResults from "assets/img/driver/icn-no-results.svg";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    emptyTitle: {
      color: "#0084A3",
    },
    noResults_image: {
      marginBottom: theme.spacing(2),
    },
    noSearchResults_container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      flexGrow: 1,
      marginTop: theme.spacing(0),
    },
  })
);
//#endregion Component Styles

//#region Component Props

//#endregion Component Props

//#region Component
export const NoSearchResults: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      data-qatag="driver.device_list.search.no_results.root"
      id="driver.device_list.search.no_results.root"
      className={classes.noSearchResults_container}
    >
      <img
        data-qatag="driver.device_list.search.no_results.image"
        id="driver.device_list.search.no_results.image"
        src={noResults}
        alt="no search results"
        className={classes.noResults_image}
      />
      <FormattedMessage
        data-qatag="driver.device_list.searc.hno_results"
        id="driver.device_list.search.no_results"
        defaultMessage="No results were found."
      >
        {(text) => (
          <Typography
            data-qatag="driver.device_list.search.no_results.text"
            id="driver.device_list.search.no_results.text"
            variant="h5"
            className={classes.emptyTitle}
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <FormattedMessage
        data-qatag="driver.device_list.search.no_results.description"
        id="driver.device_list.search.no_results.description"
        defaultMessage="Your search did not return any results."
      >
        {(text) => (
          <Typography
            data-qatag="driver.device_list.search.no_results.description.text"
            id="driver.device_list.search.no_results.description.text"
            variant="body2"
            color="primary"
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
    </Box>
  );
};
//#endregion Component
