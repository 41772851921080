import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import { Modal } from "solveiq.designsystem";
import styles from "./CleaningUpDialog.module.css";
import headerImage from "assets/img/fileCleaning/icn-pc-cleanup.svg";

export interface ICleaningUpDialogProps {
  isOpen: boolean;
  closeDialogHandler: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customText: {
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  })
);

export const CleaningUpDialog: React.FC<ICleaningUpDialogProps> = (props) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "fileCleaning.cleaningUpDialog";
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const [ellipsis, setEllipsis] = useState<string>("");

  useEffect(() => {
    const updateEllipsis = () => {
      let newVal = ellipsis + ".";

      if (newVal.length > 3) {
        newVal = "";
      }

      setEllipsis(newVal);
    };

    const interval = setInterval(updateEllipsis, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [ellipsis, setEllipsis]);

  return (
    <Modal
      data-qatag="cleaningUpDialog"
      open={props.isOpen}
      onClose={props.closeDialogHandler}
      className={styles.cleaningUpDialog}
    >
      <div
        data-qatag="cleaningUpDialogContent"
        className={styles.cleaningUpDialogContent}
      >
        <img
          data-qatag="headerImage"
          src={headerImage}
          className={styles.headerImage}
          alt=""
        />
        <Box
          data-qatag="cleaningUpDialogTitleBox"
          display="flex"
          alignItems="flex-end"
        >
          <Typography
            data-qatag="customText"
            variant="h4"
            align="center"
            className={classes.customText}
          >
            {formatMessage({
              id: `${prefix}.title`,
              defaultMessage: "Cleaning Up Your PC",
            })}
          </Typography>
          <span data-qatag="ellipsis" className={styles.ellipsis}>
            {ellipsis}
          </span>
        </Box>
      </div>
    </Modal>
  );
};
