import "./Spinner.css";
export const Spinner: React.FC = () => {
  return (
    <div
      data-qatag="spinner_container"
      id="spinner_container"
      className="lds-ellipsis"
    >
      <div data-qatag="driver.spinner.div1"></div>
      <div data-qatag="driver.spinner.div2"></div>
      <div data-qatag="driver.spinner.div3"></div>
      <div data-qatag="driver.spinner.div4"></div>
    </div>
  );
};
