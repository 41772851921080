import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import warningIcon from "assets/icon/icon-warning.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Spinner } from "../Spinner";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      width: "501px",
      height: "344px",
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "330px",
        height: "366px",
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        paddingTop: theme.spacing(4),
      },
    },
    actionButton: {
      width: "255px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    title_container: {
      marginTop: theme.spacing(4),
    },
    description_container: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(0),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  })
);
//#endregion Component Styles

//#region Component
export const UnknownInstaller: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      data-qatag="driver.modal.install.unknown_installer"
      id="driver.modal.install.unknown_installer"
      className={classes.modalRoot}
    >
      <img
        data-qatag="driver.modal.install.unknown_installer.icon"
        id="driver.modal.install.unknown_installer.icon"
        src={warningIcon}
        alt=""
      />

      <Box
        data-qatag="driver.modal.install.unknown_installer.title_container"
        id="driver.modal.install.unknown_installer.title_container"
        className={classes.title_container}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.unknown_installer.title"
          id="driver.modal.install.unknown_installer.title"
          defaultMessage="Please complete your installation inside the driver installation application."
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.unknown_installer.title.text"
              id="driver.modal.install.unknown_installer.title.text"
              variant="h5"
              color="textPrimary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
      <Box
        data-qatag="driver.modal.install.unknown_installer.description_container"
        id="driver.modal.install.unknown_installer.description_container"
        className={classes.description_container}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.unknown_installer.description"
          id="driver.modal.install.unknown_installer.description"
          defaultMessage="If you do not see the installer it may be behind the Solve iQ Business application or blinking in your TaskBar."
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.unknown_installer.description.text"
              id="driver.modal.install.unknown_installer.description.text"
              variant="body2"
              color="primary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
      <Spinner data-qatag="driver.modal.install.unknown_installer.spinner" />
    </Box>
  );
};
//#endregion Component
