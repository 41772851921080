import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IBillingQuote } from ".";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";

export const fetchQuote = createAsyncThunk<
  IBillingQuote,
  number,
  {
    state: RootState;
  }
>("billing/fetchQuote", async (seatsLength, thunkApi) => {
  try {
    const url = `/api/Billing/preview/${seatsLength}`;

    const apiResponse = await RESTGatewayAPI.get(url);

    return apiResponse?.data?.data;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch seats quote : ${error}`);
  }
});

const initialState: IReducerState<IBillingQuote> = {
  data: {
    UnitPrice: 19.99,
    SeatCount: 0,
    Subtotal: 0,
    Tax: 0,
    Total: 0,
    Credit: 0,
    PreviewType: "Downgrade",
    RecurringCharge: 0,
    BillingStartDate: "",
    BillingEndDate: "",
    ChargeAmount: 0,
    ChargeTax: 0,
    ChargeCredit: 0,
    TaxCredit: 0,
    DefaultPaymentMethod: {
      CardType: "",
      CardNumber: "",
      Id: "",
    },
  },
  status: {
    [fetchQuote.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const billingQuoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<IBillingQuote>) => {
      state.data = action.payload;
    },
    setQuoteStatus: (state) => {
      state.status[fetchQuote.typePrefix] = ReducerStatus.Idle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuote.pending, (state) => {
      state.status[fetchQuote.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(fetchQuote.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status[fetchQuote.typePrefix] = ReducerStatus.Succeeded;
    });
    builder.addCase(fetchQuote.rejected, (state) => {
      state.status[fetchQuote.typePrefix] = ReducerStatus.Failed;
    });
  },
});

export const { set, setQuoteStatus } = billingQuoteSlice.actions;

export const selectQuote = (state: RootState) => state.billingQuote.data;
export const selectQuoteStatus = (state: RootState) =>
  state.billingQuote.status[fetchQuote.typePrefix];

export default billingQuoteSlice.reducer;
