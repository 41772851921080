import { FormattedMessage, useIntl } from "react-intl";
import { AppButton, AppButtonStyles, Modal } from "solveiq.designsystem";
import styles from "./TicketSubmittedDialog.module.css";
import image from "assets/img/help/icn-submitted-ticket.svg";
import { Typography } from "@material-ui/core";

export interface ITicketSubmittedDialogProps {
  isOpen: boolean;
  closeDialogHandler: () => void;
}

export const TicketSubmittedDialog: React.FC<ITicketSubmittedDialogProps> = (
  props
) => {
  /* Localization function and prefix */
  const prefix = "helpScreen.ticketSubmittedDialog";

  return (
    <Modal
      data-qatag="ticketSubmittedDialog"
      open={props.isOpen}
      onClose={props.closeDialogHandler}
      className={styles.ticketSubmittedDialog}
    >
      <div
        data-qatag="ticketSubmittedDialogContent"
        className={styles.ticketSubmittedDialogContent}
      >
        <img
          data-qatag=""
          src={image}
          alt=""
          className={styles.ticketSubmittedImage}
        />
        <div data-qatag="textContainer" className={styles.textContainer}>
          <Typography
            data-qatag="ticketSubmittedDialogTitleTypography"
            variant="h4"
            className={styles.title}
          >
            <FormattedMessage
              data-qatag="ticketSubmittedDialogTitleMessage"
              id={`${prefix}.title`}
              defaultMessage="Your ticket has been submitted!"
            />
          </Typography>
          <Typography
            data-qatag="ticketSubmittedDialogDescriptionTypography"
            variant="body2"
            className={styles.description}
          >
            <FormattedMessage
              data-qatag="ticketSubmittedDialogDescriptionMessage"
              id={`${prefix}.description`}
              defaultMessage="We have received your submission and we’ll be in touch soon."
            />
          </Typography>
        </div>
        <AppButton
          data-qatag="closeButton"
          buttonStyle={AppButtonStyles.Green}
          className={styles.closeButton}
          onClick={props.closeDialogHandler}
        >
          <Typography
            data-qatag="ticketSubmittedDialogCloseButtonText"
            className={styles.closeButtonText}
            variant="body2"
          >
            <FormattedMessage
              data-qatag="ticketSubmittedDialogCloseButtonMessage"
              id={`${prefix}.closeButton`}
              defaultMessage="Close"
            />
          </Typography>
        </AppButton>
      </div>
    </Modal>
  );
};
