import { Box, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import errorBanner from "assets/img/cancelFlow/icons-warning.svg";

/* Custom style for Dialog */
const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundColor: "#007da0",
    width: "100%",
    height: "100%",
  },
  titleText: {
    color: "#535A6A",
    fontSize: "28px",
    fontWeight: 600,
    paddingRight: "20%",
    paddingLeft: "20%",

    [theme.breakpoints.down("sm")]: {
      fontSize: "21px",
      paddingRight: "5%",
      paddingLeft: "5%",
    },
  },
  subTitleText: {
    fontSize: "16px",
    color: "#757E95",
    paddingRight: "20%",
    paddingLeft: "20%",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "5%",
      paddingLeft: "5%",
    },
  },
  errorBanner: {
    maxWidth: "290px",
    maxHeight: "250px",
    width: "10%",
    flexGrow: 1,
  },
  container: {
    maxWidth: "784px",
    width: "100%",
    marginTop: "50px",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "40px",
    textAlign: "center",
    borderRadius: "14px",
    height: "300px",
    backgroundColor: "#fff",
    outline: 0,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "500px",
      height: "270px",
    },
  },
}));

export const ForceDisconnectScreen: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.background} data-qatag="forceDisconnectBG">
      <Paper className={classes.container} data-qatag="forceDisconnectWrapper">
        {/* Title */}
        <Box data-qatag="TitleBox" textAlign="center">
          <img
            data-qatag="titleIcon"
            className={classes.errorBanner}
            src={errorBanner}
            alt="wire-frame"
          />
          <Box data-qatag="titleTextBox" mt={2}>
            <FormattedMessage
              data-qatag="forceDisconnect.title"
              id="forceDisconnect.title"
              defaultMessage="Agent connection limit reached"
            >
              {(text) => (
                <Typography
                  data-qatag="titleText"
                  variant="h6"
                  className={classes.titleText}
                  color="primary"
                >
                  {text}
                </Typography>
              )}
            </FormattedMessage>
          </Box>
          <Box data-qatag="subTitleTextBox" mt={2}>
            <FormattedMessage
              data-qatag="forceDisconnect.subTitle"
              id="forceDisconnect.subTitle"
              defaultMessage="Another user or browser tab has connected to your agent. Please close this window or relaunch the UI to reconnect."
            >
              {(text) => (
                <Typography
                  data-qatag="titleText"
                  variant="h6"
                  className={classes.subTitleText}
                  color="secondary"
                >
                  {text}
                </Typography>
              )}
            </FormattedMessage>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};
