import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import styles from "./CancelFlow.module.css";
import {
  AppButton as Button,
  AppButtonStyles,
  Alert,
  AlertIcon,
  Spinner,
} from "solveiq.designsystem";
import cancelIllustration from "assets/img/cancelFlow/cancel-illustration.svg";
import TheWho from "./TheWho";
import TheWhat from "./TheWhat";
import TheWhy from "./TheWhy";
import { useEffect, useState } from "react";
import {
  suspenseSubscription,
  selectSuspenseSubscriptionStatus,
  selectError,
  resetSuspend,
  fetchSubscription,
} from "features/teamManagement/SubscriptionSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReducerStatus } from "model/IReducerState";
import { RootState } from "app/store";
import { submitFeedbackForm } from "features/help/HelpSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      color: theme.palette.secondary.light,
      fontWeight: 600,
    },
    buttonText: {
      lineHeight: 1,
    },
  })
);

export interface ICancelFlow {
  /* Callback to cancel, cancel flow */
  keepServiceCallback: () => void;
  /* Suspend Request Status */
  selectorStatusSuspense?: (state: RootState) => ReducerStatus;
  /* If we retrieve an error */
  selectorError?: (state: RootState) => string;
  /* Next billing cycle date */
  billingCycleDate: string;
}

enum CancellationFlow {
  TheWho,
  TheWhat,
  TheWhy,
}

const formOptions = [
  { label: "Too Expensive", value: "0" },
  { label: "Did not solve enough issues for my team", value: "1" },
  { label: "Not enough features", value: "2" },
  { label: "Other", value: "3" },
];

const CancelFlow: React.FC<ICancelFlow> = ({
  keepServiceCallback,
  selectorStatusSuspense = (state: RootState) =>
    selectSuspenseSubscriptionStatus(state),
  selectorError = (state: RootState) => selectError(state),
  billingCycleDate,
}) => {
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  // Step to be present
  const [step, setStep] = useState(CancellationFlow.TheWho);

  // display modal to continue with the flow
  const [openWarning, setOpenWarning] = useState(false);
  // Confirmation modal about suspend the service
  const [openError, setError] = useState(false);

  const dispatch = useDispatch();

  /* Status Suspense request */
  const requestStatusUpdateSeats = useSelector(selectorStatusSuspense);
  /* De we get an error? */
  const error = useSelector(selectorError);

  // If the request status change
  useEffect(() => {
    if (error) {
      setError(true);
    }
  }, [dispatch, error, requestStatusUpdateSeats]);

  // Lifting state for submit feedback
  // State for cancellation reason
  const [reason, setReason] = useState<number>(-1);
  // Description
  const [feedBack, setFeedBack] = useState("");

  return (
    <Box data-qatag="container" width={1}>
      <Box data-qatag="logoContainer" width={1} mt={4} textAlign="center">
        <img
          data-qatag="cancelIllustration"
          src={cancelIllustration}
          alt="cancel"
        />
      </Box>
      <Box data-qatag="question" width={1} mt={2.5} textAlign="center">
        <Typography
          data-qatag="titleText"
          variant="h4"
          className={classes.titleText}
        >
          Have questions about your service or technical issues?
        </Typography>
      </Box>
      <Box data-qatag="access" width={1} mt={1} textAlign="center">
        <Typography data-qatag="titleText" variant="body2">
          Your service includes access to our friendly experts at
        </Typography>
      </Box>
      <Box data-qatag="phoneNumber" width={1} mt={1} textAlign="center">
        <Typography data-qatag="titleText" variant="h3">
          1-888-301-5977
        </Typography>
      </Box>

      {/* The Who */}
      {step === CancellationFlow.TheWho ? <TheWho data-qatag="TheWho" /> : ""}

      {/* The What */}
      {step === CancellationFlow.TheWhat ? <TheWhat data-qatag="TheWho" /> : ""}

      {/* The TheWhy */}
      {step === CancellationFlow.TheWhy ? (
        <TheWhy
          data-qatag="TheWhy"
          formOptions={formOptions}
          setReason={setReason}
          setFeedBack={setFeedBack}
        />
      ) : (
        ""
      )}

      <Box data-qatag="logoContainer" width={1} mt={5} textAlign="center">
        <Typography data-qatag="titleText" variant="body2">
          Are you sure you want to cancel service for everyone?
        </Typography>
      </Box>

      {/* Buttons */}

      <Box
        data-qatag="buttonContainer"
        mt={2}
        mb={5}
        width={1}
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
      >
        <Button
          data-qatag="cancelButton"
          buttonStyle={AppButtonStyles.TransparentWhite}
          className={`${styles.cancelButton}`}
          onClick={() => {
            const nextStep = step + 1;
            if (step + 1 <= 2) {
              setStep(
                nextStep > CancellationFlow.TheWhy
                  ? CancellationFlow.TheWhy
                  : nextStep
              );
            } else {
              setOpenWarning(true);
            }
          }}
        >
          <Typography
            data-qatag="buttonLabel"
            align="center"
            className={`${classes.buttonText} ${styles.cancelButtonText}`}
            variant="body2"
          >
            End My Benefits
          </Typography>
        </Button>
        <Button
          data-qatag="confirmButton"
          buttonStyle={AppButtonStyles.Green}
          className={`${styles.customButton} ${styles.keepServiceButton}`}
          onClick={keepServiceCallback}
        >
          <Typography
            data-qatag="buttonLabel"
            align="center"
            className={classes.buttonText}
            variant="h6"
          >
            Keep My Service
          </Typography>
        </Button>
      </Box>
      {/* Error Modal */}
      <Alert
        data-qatag="alertWarning"
        icon={AlertIcon.Warning}
        title="Error"
        text={error || "Something went wrong"}
        buttonText="Close"
        approveHandler={() => {
          dispatch(resetSuspend());
          setError(false);
        }}
        isOpen={openError}
        closeHandler={() => setError(false)}
      />
      {/* Warning Modal */}
      <Alert
        data-qatag="alertWarning"
        icon={AlertIcon.Warning}
        title="End My Benefits"
        text="Would you like to continue?"
        buttonText="Continue"
        approveHandler={() => {
          dispatch(suspenseSubscription());
          setOpenWarning(false);
          // submit feedback
          // Conditions to dispatch the feedback if message is different than null and
          if (feedBack !== "" || reason >= 0) {
            const option = reason >= 0 ? reason : 3;
            dispatch(
              submitFeedbackForm({
                subject: `Solve iQ Business - Cancel Feedback Form : ${formOptions?.[option]?.label}`,
                description: `Solve iQ Business Cancel Feedback Form \r\n
                            Cancel Reason: ${formOptions?.[option]?.label} \r\n
                            Message:\r\n ${feedBack}`,
              })
            );
          }
        }}
        isOpen={openWarning}
        closeHandler={() => setOpenWarning(false)}
        cancelHandler={() => setOpenWarning(false)}
        cancelButtonText="Cancel"
      />
      {/* Confirmation Modal */}
      <Alert
        data-qatag="alertWarning"
        icon={AlertIcon.Cancel}
        title="You’ve Canceled Your Service"
        text={
          <>
            Your service with Solve iQ Business will be terminated at the end of
            this billing cycle, <b data-qatag="bold">{billingCycleDate}.</b>
          </>
        }
        buttonText="Close"
        approveHandler={() => {
          dispatch(resetSuspend());
          dispatch(fetchSubscription());
          keepServiceCallback();
        }}
        isOpen={
          ReducerStatus[requestStatusUpdateSeats] === ReducerStatus.Succeeded &&
          !error
        }
        closeHandler={() => {
          dispatch(resetSuspend());
          dispatch(fetchSubscription());
          keepServiceCallback();
        }}
      />
      {/* Loading modal */}
      <Spinner
        data-qatag="loadingModal"
        isActive={
          ReducerStatus[requestStatusUpdateSeats] === ReducerStatus.Loading
        }
        text="Processing..."
      />
    </Box>
  );
};

export default CancelFlow;
