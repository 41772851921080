import {
  Box,
  makeStyles,
  createStyles,
  Paper,
  Badge,
  Typography,
} from "@material-ui/core";
import React from "react";
import check from "assets/img/greenCheck.svg";
import bang from "assets/img/orangeBang.svg";
import { defineMessages, FormattedMessage } from "react-intl";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import deviceUpdatesImage from "assets/img/status/deviceUpdatesImage.svg";
import fileCleaningImage from "assets/img/status/fileCleaningImage.svg";
import deceptorBlockingImage from "assets/img/status/deceptorBlockingImage.svg";
import appOptimizationImage from "assets/img/status/appOptimizationImage.svg";
import windowsEnhancementsImage from "assets/img/status/windowsEnhancementsImage.svg";
import dnsProtectionImage from "assets/img/status/dnsProtectionImage.svg";
import deviceUpdatesImage_small from "assets/img/status/deviceUpdatesImage_small.svg";
import fileCleaningImage_small from "assets/img/status/fileCleaningImage_small.svg";
import deceptorBlockingImage_small from "assets/img/status/deceptorBlockingImage_small.svg";
import appOptimizationImage_small from "assets/img/status/appOptimizationImage_small.svg";
import windowsEnhancementsImage_small from "assets/img/status/windowsEnhancementsImage_small.svg";
import dnsProtectionImage_small from "assets/img/status/dnsProtectionImage_small.svg";
import deviceUpdatesImage_mobile from "assets/img/status/deviceUpdatesImage_mobile.svg";
import fileCleaningImage_mobile from "assets/img/status/fileCleaningImage_mobile.svg";
import deceptorBlockingImage_mobile from "assets/img/status/deceptorBlockingImage_mobile.svg";
import appOptimizationImage_mobile from "assets/img/status/appOptimizationImage_mobile.svg";
import windowsEnhancementsImage_mobile from "assets/img/status/windowsEnhancementsImage_mobile.svg";
import dnsProtectionImage_mobile from "assets/img/status/dnsProtectionImage_mobile.svg";
import { ArrowForward, ArrowForwardIos } from "@material-ui/icons";

//#region Exported Types
export const tileTypes = [
  "device_updates",
  "file_cleaning",
  "deceptor_blocking",
  "app_optimization",
  "windows_enhancements",
  "dns_protection",
] as const;
export type StatusTileTypes = typeof tileTypes[number];
export type TileDisplayMode =
  | "large"
  | "medium"
  | "square"
  | "tablet"
  | "mobile";

export interface IStatusTileProps {
  isActive: boolean;
  //tileIcon: string;
  displayMode: TileDisplayMode;
  statusName: StatusTileTypes;
  style?: CSSProperties;
  onClick: () => void;
}
//#endregion Exported Types

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      // paddingLeft: theme.spacing(2),
      // paddingRight: theme.spacing(2),
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "12px",
      flexGrow: 1,
      "&:hover": {
        boxShadow:
          "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
      },
      cursor: "pointer",
    },
    paper_mobile: {
      display: "flex",
      alignItems: "center",
      borderRadius: "12px",
      flexGrow: 1,
      "&:hover": {
        boxShadow:
          "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
      },

      cursor: "pointer",
    },
    title_needs_attention: {
      color: "#DD4A21",
      fontWeight: 600,
    },
    title_enabled: {
      color: "#007DBA",
      fontWeight: 600,
    },
    text_container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    text_container_mobile: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    badge: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    arrow: {
      color: "#707070",
      height: "17px",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  })
);
//#endregion Component Styles

//#region Component Specific Messages
const messages = defineMessages({
  enabled: {
    id: "status_tile_enabled",
    description: "status tile state: enabled",
    defaultMessage: "Enabled",
  },
  needs_attention: {
    id: "status_tile_needs_attention",
    description: "status tile state: needs attention",
    defaultMessage: "Needs Attention",
  },
  app_optimization: {
    id: "status_tile.title.appOptimization",
    description: "status tile title: App Optimization",
    defaultMessage: "App Optimization",
  },
  deceptor_blocking: {
    id: "status_tile.title.deceptorBlocking",
    description: "status tile title: Deceptor Blocking",
    defaultMessage: "Deceptor Blocking",
  },
  device_updates: {
    id: "status_tile.title.deviceUpdates",
    description: "status tile title: Device Updates",
    defaultMessage: "Device Updates",
  },
  dns_protection: {
    id: "status_tile.title.dnsProtection",
    description: "status tile title: DNS Protection",
    defaultMessage: "DNS Protection",
  },
  file_cleaning: {
    id: "status_tile.title.fileCleaning",
    description: "status tile title: File Cleaning",
    defaultMessage: "File Cleaning",
  },
  windows_enhancements: {
    id: "status_tile.title.windowsEnahncements",
    description: "status tile title: Windows Enhancements",
    defaultMessage: "Windows Enhancements",
  },
});
//#endregion Component Specific Messages

const getDimensions = (displayMode: TileDisplayMode) => {
  let returnWidth = "235px";
  let returnHeight = "166px";
  switch (displayMode) {
    case "large":
      returnWidth = "295px";
      returnHeight = "240px";
      break;
    case "medium":
      returnWidth = "235px";
      returnHeight = "166px";
      break;
    case "square":
      returnWidth = "220px";
      returnHeight = "220px";
      break;
    case "tablet":
      returnWidth = "318px";
      returnHeight = "124px";
      break;
    case "mobile":
      returnWidth = "inherit";
      returnHeight = "100px";
      break;
  }

  return { height: returnHeight, width: returnWidth };
};

const getBadgeContent = (hasProblems: boolean) => {
  const source = hasProblems ? bang : check;
  return <img data-qatag="" src={source} />;
};

const getStatusNameVariant = (displayMode: TileDisplayMode) => {
  switch (displayMode) {
    case "large":
      return "h6";
    // case "tablet":
    //   return "17px";
    default:
      return "body2";
  }
};

const getStatusStateVariant = (displayMode: TileDisplayMode) => {
  switch (displayMode) {
    case "mobile":
      return "body1";
    default:
      return "body2";
  }
};

const getTileImage = (
  tileType: StatusTileTypes,
  displayMode: TileDisplayMode
) => {
  switch (tileType) {
    case "app_optimization":
      switch (displayMode) {
        case "large":
        case "square":
          return appOptimizationImage;
        case "medium":
          return appOptimizationImage_small;
        case "tablet":
        case "mobile":
          return appOptimizationImage_mobile;
      }
      break;
    case "deceptor_blocking":
      switch (displayMode) {
        case "large":
        case "square":
          return deceptorBlockingImage;
        case "medium":
          return deceptorBlockingImage_small;
        case "tablet":
        case "mobile":
          return deceptorBlockingImage_mobile;
      }
      break;
    case "device_updates":
      switch (displayMode) {
        case "large":
        case "square":
          return deviceUpdatesImage;
        case "medium":
          return deviceUpdatesImage_small;
        case "tablet":
        case "mobile":
          return deviceUpdatesImage_mobile;
      }
      break;
    case "dns_protection":
      switch (displayMode) {
        case "large":
        case "square":
          return dnsProtectionImage;
        case "medium":
          return dnsProtectionImage_small;
        case "tablet":
        case "mobile":
          return dnsProtectionImage_mobile;
      }
      break;
    case "file_cleaning":
      switch (displayMode) {
        case "large":
        case "square":
          return fileCleaningImage;
        case "medium":
          return fileCleaningImage_small;
        case "tablet":
        case "mobile":
          return fileCleaningImage_mobile;
      }
      break;
    case "windows_enhancements":
      switch (displayMode) {
        case "large":
        case "square":
          return windowsEnhancementsImage;
        case "medium":
          return windowsEnhancementsImage_small;
        case "tablet":
        case "mobile":
          return windowsEnhancementsImage_mobile;
      }
      break;
    default:
      switch (displayMode) {
        case "large":
        case "square":
          return deviceUpdatesImage;
        case "medium":
          return deviceUpdatesImage_small;
        case "tablet":
        case "mobile":
          return deviceUpdatesImage_mobile;
      }
  }
};
//#region Component
export const StatusTile: React.FC<IStatusTileProps> = (props) => {
  const stateId = props.isActive ? "enabled" : "needs_attention";
  const stateDescriptor = messages[stateId];
  const titleDescriptor = messages[props.statusName];
  const classes = useStyles();

  return (
    <Box
      data-qatag="statusTile.root"
      id="statusTile.root"
      className={classes.root}
      // passed-in height/width via style prop will override the getDimensions method
      style={{ ...getDimensions(props.displayMode), ...props.style }}
    >
      <Paper
        data-qatag="statusTile.paper"
        id="statusTile.paper"
        className={
          props.displayMode === "mobile" || props.displayMode === "tablet"
            ? classes.paper_mobile
            : classes.paper
        }
        onClick={props.onClick}
      >
        <Badge
          data-qatag="statusTile.status.badge"
          id="statusTile.status.badge"
          overlap="circle"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          badgeContent={getBadgeContent(!props.isActive)}
          className={classes.badge}
        >
          <img
            data-qatag="statusTile.tile_icon"
            id="statusTile.tile_icon"
            src={getTileImage(props.statusName, props.displayMode)}
            alt="status icon"
          />
        </Badge>

        <Box
          data-qatag="statusTile.textContainer"
          id="statusTile.textContainer"
          className={
            props.displayMode === "mobile" || props.displayMode === "tablet"
              ? classes.text_container_mobile
              : classes.text_container
          }
        >
          <FormattedMessage
            data-qatag="statusTile.statusName"
            {...titleDescriptor}
          >
            {(text) => (
              <Typography
                data-qatag="statusTile.statusName.text"
                id="statusTile.statusName.text"
                variant={getStatusNameVariant(props.displayMode)}
                className={
                  props.isActive
                    ? classes.title_enabled
                    : classes.title_needs_attention
                }
              >
                {" "}
                {text}
              </Typography>
            )}
          </FormattedMessage>

          <FormattedMessage data-qatag="statusTile.state" {...stateDescriptor}>
            {(text) => (
              <Typography
                data-qatag="statusTile.state.text"
                id="statusTile.state.text"
                variant={getStatusStateVariant(props.displayMode)}
                color="primary"
                align="left"
                //   className={classes.entry_title}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </Box>
        {props.displayMode === "mobile" && (
          <ArrowForwardIos data-qatag="sdf" className={classes.arrow} />
        )}
      </Paper>
    </Box>
  );
};
//#endregion Component
