import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPayment, PaymentMethod, IBillingHistory, ISeat } from "./types";
import { RootState } from "app/store";
import { ReducerStatus } from "model/IReducerState";
import { selectCurrentUuid } from "session/SessionSlice";

declare let gatewayUrl: string;

interface PaymentMethodsState {
  /* Payment Methods */
  paymentMethods: { data: IPayment[] };
  /* Request status Payment */
  requestStatus: ReducerStatus;
  /* Billing History */
  billingHistory: { data: IBillingHistory[] };
  /* Request status Billing History */
  requestStatusHistory: ReducerStatus;
  /* Users with Rol */
  usersWithRol: { data: ISeat[] };
  /* Request status Users with Rol */
  requestStatusUsers: ReducerStatus;
}

// export const fetchPaymentMethods = createAsyncThunk<
//   { data: IPayment[] },
//   void,
//   { state: RootState }
// >("scan/fetchPaymentMethods", async (_, thunkApi) => {
//   const { accessToken, currentUuid } = thunkApi.getState().session.data;
//   const url = `${gatewayUrl}/api/paymentMethods/${currentUuid}`;
//   // const response: IPayment[] = await authClient(url, accessToken);
//   //TODO: Replace this mock data when the endpoint is completed
//   const response = [
//     // {
//     //   paymentType: PaymentMethod.visa,
//     //   lastDigits: 4242,
//     //   isDefault: true,
//     //   expireMonth: 6,
//     //   expireYear: 2024,
//     // },
//     // {
//     //   paymentType: PaymentMethod.mastercard,
//     //   lastDigits: 3877,
//     //   isDefault: false,
//     //   expireMonth: 2,
//     //   expireYear: 2026,
//     // },
//     // {
//     //   paymentType: PaymentMethod.amex,
//     //   lastDigits: 3643,
//     //   isDefault: false,
//     //   expireMonth: 2,
//     //   expireYear: 2021,
//     // },
//     // {
//     //   paymentType: PaymentMethod.PayPal,
//     //   lastDigits: undefined,
//     //   isDefault: false,
//     //   expireMonth: undefined,
//     //   expireYear: undefined,
//     // },
//   ];
//   if (!response?.length) {
//     Promise.reject("Unable to fetch payment methods");
//   }
//   return { data: response };
// });

export const fetchBillingHistory = createAsyncThunk<
  { data: IBillingHistory[] },
  void,
  { state: RootState }
>("scan/fetchBillingHistory", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const accessToken = state.session.data.accessToken;
  const currentUuid = selectCurrentUuid(state);
  const url = `${gatewayUrl}/api/billingHistory/${currentUuid}`;
  // const response: IPayment[] = await authClient(url, accessToken);
  //TODO: Replace this mock data when the endpoint is completed
  const response = [
    {
      date: "March 20, 2021",
      total: 60.0,
      plan: "Team Plan",
      downloadURL: "",
    },
    {
      date: "February 20, 2021",
      total: 60.0,
      plan: "Team Plan",
      downloadURL: "",
    },
    {
      date: "January 20, 2021",
      total: 60.0,
      plan: "Team Plan",
      downloadURL: "",
    },
    {
      date: "December 20, 2020",
      total: 60.0,
      plan: "Team Plan",
      downloadURL: "",
    },
    {
      date: "November 20, 2020",
      total: 10.0,
      plan: "Individual Plan",
      downloadURL: "",
    },
  ];
  if (!response?.length) {
    Promise.reject("Unable to billing history");
  }
  return { data: response };
});

export const fetchSeats = createAsyncThunk<
  { data: ISeat[] },
  void,
  { state: RootState }
>("scan/fetchSeats", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const accessToken = state.session.data.accessToken;
  const currentUuid = selectCurrentUuid(state);
  const url = `${gatewayUrl}/api/users/${currentUuid}`;
  // const response: IPayment[] = await authClient(url, accessToken);
  //TODO: Replace this mock data when the endpoint is completed
  const response = [
    {
      name: "Michael Scott",
      active: true,
      role: "Admin",
    },
    {
      name: "Pam Beesly",
      active: true,
      role: "Admin",
    },
    {
      name: "Jim Halpert",
      active: false,
      role: "Employee",
    },
    {
      name: "Dwight Schrute",
      active: true,
      role: "Employee",
    },
    {
      name: "Kevin Malone",
      active: false,
      role: "Employee",
    },
  ];
  if (!response?.length) {
    Promise.reject("No users");
  }
  return { data: response };
});

const initialState: PaymentMethodsState = {
  paymentMethods: { data: [] },
  requestStatus: ReducerStatus.Idle,
  billingHistory: { data: [] },
  requestStatusHistory: ReducerStatus.Idle,
  usersWithRol: { data: [] },
  requestStatusUsers: ReducerStatus.Idle,
};

const billingSlice = createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(fetchPaymentMethods.fulfilled, (state, action) => {
    //   state.paymentMethods = {
    //     ...action.payload,
    //   };
    //   state.requestStatus = ReducerStatus.Succeeded;
    // });
    builder.addCase(fetchBillingHistory.fulfilled, (state, action) => {
      state.billingHistory = {
        ...action.payload,
      };
      state.requestStatusHistory = ReducerStatus.Succeeded;
    });
    builder.addCase(fetchSeats.fulfilled, (state, action) => {
      state.usersWithRol = {
        ...action.payload,
      };
      state.requestStatusUsers = ReducerStatus.Succeeded;
    });
  },
});

export default billingSlice.reducer;

export const getPaymentMethods = (state: RootState) =>
  state.billing.paymentMethods;
export const getRequestStatus = (state: RootState) =>
  state.billing.requestStatus;
export const getBillingHistory = (state: RootState) =>
  state.billing.billingHistory;
export const getRequestStatusHistory = (state: RootState) =>
  state.billing.requestStatusHistory;
export const getUsersWithRol = (state: RootState) => state.billing.usersWithRol;
export const getRequestStatusUsers = (state: RootState) =>
  state.billing.requestStatusUsers;
