import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import titleIcon from "assets/img/driver/icn-home-tasks.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      width: "504px",
      height: "385px",
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(9),
      paddingRight: theme.spacing(9),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "330px",
        height: "385px",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
      },
    },
    actionButton: {
      width: "201px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    description_container: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
  })
);
//#endregion Component Styles

//#region Component Props
export interface IInstallUserInteractionProps {
  onFinish: () => void;
}
//#endregion Component Props

//#region Component
export const InstallUserInteraction: React.FC<IInstallUserInteractionProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <Box
      data-qatag="driver.modal.install.user_interaction"
      id="driver.modal.install.user_interaction"
      className={classes.modalRoot}
    >
      <img
        data-qatag="driver.modal.install.user_interaction.icon"
        id="driver.modal.install.user_interaction.icon"
        src={titleIcon}
        alt=""
      />

      <FormattedMessage
        data-qatag="driver.modal.install.user_interaction.title"
        id="driver.modal.install.user_interaction.title"
        defaultMessage="Please look in File Explorer {br} (It may be behind this application) to find a file similarly named setup.exe or readme.txt"
        values={{
          br: (
            <br
              data-qatag="driver.modal.install.user_interaction.break"
              id="driver.modal.install.user_interaction.break"
            />
          ),
        }}
      >
        {(text) => (
          <Typography
            data-qatag="driver.modal.install.user_interaction.title.text"
            id="driver.modal.install.user_interaction.title.text"
            variant="h5"
            color="textPrimary"
            align="center"
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <Box
        data-qatag="driver.modal.install.user_interaction.description_container"
        id="driver.modal.install.user_interaction.description_container"
        className={classes.description_container}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.user_interaction.description"
          id="driver.modal.install.user_interaction.description"
          defaultMessage="Open the file manually for additional instructions"
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.user_interaction.description.text"
              id="driver.modal.install.user_interaction.description.text"
              variant="body2"
              color="primary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
      <AppButton
        data-qatag="driver.modal.install.user_interaction.finishButton"
        id="driver.modal.install.user_interaction.finishButton"
        className={classes.actionButton}
        buttonStyle={AppButtonStyles.Green}
        onClick={props.onFinish}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.user_interaction.finishButton.content"
          id="driver.modal.install.user_interaction.finishButton.content"
          defaultMessage="Finish"
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.user_interaction.finishButton.content.text"
              id="driver.modal.install.user_interaction.finishButton.content.text"
              variant="body2"
              align="center"
              style={{ fontWeight: 600 }}
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </AppButton>
    </Box>
  );
};
//#endregion Component
