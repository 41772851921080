import { Typography, Box, IconButton, Dialog } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import paymentIcon from "assets/icon/icn-payment-method.svg";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./EditPaymentMethodModal.module.css";
import { CardForm } from ".";

export interface IEditPaymentMethodModal {
  /* Controls to open the modal */
  isOpen: boolean;
  /* Callback to update the isOpen on the parent*/
  handleOnClose: () => void;
  /* Payment method ID to retrieve  */
  paymentMethodId: string;
}

/* Custom style for Dialog */
const useStyles = makeStyles((theme: Theme) => ({
  paper: { maxWidth: "624px", width: "100%", height: "740px" },
  titleText: {
    color: theme.palette.secondary.light,
    fontWeight: 600,
    marginLeft: 8,
  },
}));

const EditPaymentMethodModal: React.FC<IEditPaymentMethodModal> = ({
  isOpen = true,
  handleOnClose,
  paymentMethodId,
}) => {
  // Custom styles
  const classes = useStyles();

  return (
    <Dialog
      data-qatag="Dialog"
      open={isOpen}
      onClose={handleOnClose}
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <div data-qatag="container" className={styles.container}>
        <div data-qatag="content" className={styles.content}>
          {/* Close Icon */}
          <div
            data-qatag="closeIconContainer"
            className={styles.closeIconContainer}
          >
            <IconButton
              data-qatag="closeIcon"
              aria-label="close"
              onClick={handleOnClose}
              className={styles.closeIcon}
            >
              <CloseIcon data-qatag="CloseIcon" />
            </IconButton>
          </div>

          {/* Title */}
          <Box data-qatag="Title" display="flex" alignItems="center" mt={7}>
            <img
              data-qatag="titleIcon"
              className={styles.titleIcon}
              src={paymentIcon}
              alt="wire-frame"
            />
            <Typography
              data-qatag="titleText"
              variant="h6"
              className={classes.titleText}
              color="primary"
            >
              Edit Payment Method
            </Typography>
          </Box>
          <CardForm
            data-qatag="CardForm"
            handleOnClose={handleOnClose}
            paymentMethodId={paymentMethodId}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default EditPaymentMethodModal;
