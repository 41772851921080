import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IGaming, TriggerInfo } from "./types";
import { RootState } from "app/store";
import { ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";
import { selectCurrentUuid } from "session/SessionSlice";
import { removeDuplicatesBy } from "utils/removeDuplicates";

interface OptimizationState {
  /* Software Gaming */
  gaming: IGaming[];
  /* status */
  gamingStatus: ReducerStatus;
}

export const fetchJittriggersScan = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("scan/fetchJittriggersScan", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const uuid = selectCurrentUuid(state);
  const url = `/api/scan/jittriggers/${uuid}`;
  let apiResponse = null;

  await RESTGatewayAPI.get(url)
    .then((response) => {
      apiResponse = JSON.parse(response.data.payload);
    })
    .catch((error) => {
      return thunkApi.rejectWithValue(
        `Unable to fetch JIT Trigger scan : ${error}`
      );
    });

  if (apiResponse == null) {
    return thunkApi.rejectWithValue(
      `Unable to fetch JIT Trigger scan : response was empty`
    );
  }

  return apiResponse;
});

const initialState: OptimizationState = {
  gaming: [],
  gamingStatus: ReducerStatus.Idle,
};

const optimizationSlice = createSlice({
  name: "teamMembers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchJittriggersScan.fulfilled, (state, action) => {
      state.gaming = {
        ...action.payload,
        TriggerInfo: removeDuplicatesBy(
          (x: TriggerInfo) => x?.JitTrigger?.ManagedSoftwareID,
          action?.payload?.TriggerInfo
        )?.sort((a, b) => {
          // toLocale will work better but for compatibility
          const idA = a?.JitTrigger?.ManagedSoftware?.Description?.toLowerCase();
          const idB = b?.JitTrigger?.ManagedSoftware?.Description?.toLowerCase();
          //sort string ascending
          if (idA < idB) return -1;
          //  if (idA > idB) return 1; If I want descending
          return 0; //default return value (no sorting)
        }),
      };
      state.gamingStatus = ReducerStatus.Succeeded;
    });
  },
});

export default optimizationSlice.reducer;

export const getGamingSoftware = (state: RootState) =>
  state.optimization.gaming;
export const getGamingStatus = (state: RootState) =>
  state.optimization.gamingStatus;
