import {
  Box,
  Modal,
  CircularProgress,
  Typography,
  IconButton,
  TextField,
  Divider,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import styles from "./SeatManagement.module.css";
import { SearchTextField } from "solveiq.designsystem";
import { ReducerStatus, IThunkStatus } from "model/IReducerState";
import { EmptyState } from "features/Optimization";
import { RootState } from "app/store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import seatsIcon from "assets/icon/icn-seats.svg";
import userManagementIcon from "assets/icon/icn-user-management.svg";
import { AlertError } from "features/customAlerts/AddTeamMember";
import { AlertWarning, AlertSuccess } from "features/customAlerts/Billing";
import SearchIcon from "@material-ui/icons/Search";
import Seat from "./Seat";
import { debounce } from "core/Helpers";
import {
  fetchOrganizationUsers,
  fetchUsersOnlineStatus,
  selectFetchUsersStatus,
  selectUsers,
} from "model/user/UserSlice";
import {
  fetchOrganizationLicenses,
  selectStatus as selectLicenseStatus,
} from "model/license/LicenseSlice";
import IUser from "model/user/IUser";

export interface ISeatManagement {
  /* Users with rol */
  usersWithRolSelector?: (state: RootState) => IUser[];
  fetchUsersStatusSelector?: (state: RootState) => ReducerStatus;
  licensesStatusSelector?: (state: RootState) => IThunkStatus;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      color: theme.palette.secondary.light,
      fontWeight: 600,
    },
    addPaymentButton: {
      display: "flex",
      alignItems: "center",
      flexBasis: "50%",
      marginTop: 24,
      marginBottom: 32,
      justifyContent: "flex-end",
      [theme.breakpoints.down("md")]: {
        marginTop: 24,
        marginBottom: 24,
        marginLeft: -20,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 0,
      },
    },
    buttonText: {
      lineHeight: 1,
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",
      flexBasis: "50%",
      marginTop: 24,
      marginBottom: 32,
      [theme.breakpoints.down("md")]: {
        marginLeft: 20,
        marginBottom: 24,
        marginTop: 24,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 8,
        marginBottom: 0,
      },
    },
    container: {
      marginBottom: 48,
      maxWidth: 1020,
      width: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      backgroundColor: "white",
      borderRadius: 14,
      padding: "40px 24px 40px 24px",
      [theme.breakpoints.down("md")]: {
        padding: "40px 16px 40px 16px",
        marginTop: 0,
        order: 2,
        marginRight: 0,
        marginLeft: 0,
      },
      [theme.breakpoints.down("sm")]: {
        padding: "32px 8px 32px 8px",
      },
    },
    searchIcon: {
      color: theme.palette.info.light,
    },
    textField: {
      width: 100,
      marginRight: 4,
      [theme.breakpoints.down("sm")]: {
        width: 70,
      },
      [theme.breakpoints.up("sm")]: {
        width: 90,
      },
    },
    searchMobile: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        marginBottom: 20,
      },
    },
    searchTablet: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    bold: {
      fontWeight: 600,
    },
  })
);

const SeatManagement: React.FC<ISeatManagement> = ({
  usersWithRolSelector = (state: RootState) => selectUsers(state),
  fetchUsersStatusSelector = (state: RootState) =>
    selectFetchUsersStatus(state),
  // Retrieve license  status to retrieve UUID users
  licensesStatusSelector = (state) => selectLicenseStatus(state),
}) => {
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const dispatch = useDispatch();
  const usersStatus = useSelector(fetchUsersStatusSelector);
  const licensesStatus = useSelector(licensesStatusSelector);

  useEffect(() => {
    if (usersStatus === ReducerStatus.Idle) {
      dispatch(fetchOrganizationUsers());
    }
    if (
      licensesStatus[fetchOrganizationLicenses.typePrefix] ===
      ReducerStatus.Idle
    ) {
      dispatch(fetchOrganizationLicenses());
    }
    // If licenses Status is success we can retrieve the online status of the users
    if (
      licensesStatus[fetchOrganizationLicenses.typePrefix] ===
      ReducerStatus.Succeeded
    ) {
      dispatch(fetchUsersOnlineStatus());
    }
  }, [usersStatus, dispatch, licensesStatus]);

  // Alerts status
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [error] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  // For success alert
  const [alertTitle, setAlertTitle] = useState("Completed");
  const [alertDescription, setAlertDescription] = useState(
    "The operation completed successfully"
  );
  // Search Component
  const [searchText, setSearchText] = useState("");

  // Handle to delete Payment method
  function handleDeleteMethod() {
    //Do something to delete
    setOpenWarningModal((prevState) => !prevState);
    setAlertTitle("Completed");
    setAlertDescription("The Payment method was removed");
    setOpenSuccessModal((prevState) => !prevState);
  }

  /* Retrieve data from the store */

  /* User list */
  const users = useSelector(usersWithRolSelector);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((inputValue) => setSearchText(inputValue), 500),
    []
  );

  // Enable Users
  const enableUsers = useMemo(
    () => users.filter((teamMember) => teamMember.enabled),
    [users]
  );
  // Filtered seats
  const filteredUsers = useMemo(
    () =>
      enableUsers.filter(
        (teamMember) =>
          teamMember?.firstName
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase()) ||
          teamMember?.firstName
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase()) ||
          teamMember?.email?.toLowerCase()?.includes(searchText?.toLowerCase())
      ),
    [enableUsers, searchText]
  );

  return (
    <Box data-qatag="container" className={classes.container}>
      {/* User Management Title */}
      <Box data-qatag="logoContainer" display="flex" mt={2}>
        <img
          data-qatag="titleIcon"
          className={styles.titleIcon}
          src={userManagementIcon}
          alt="wire-frame"
        />
        <Typography
          data-qatag="titleText"
          variant="h6"
          className={classes.bold}
        >
          User Management
        </Typography>
      </Box>
      {/* Divider */}
      <Box data-qatag="divider" mt={1.5} className={styles.divider}>
        <Divider data-qatag="dividerItem" />
      </Box>
      <Box display="flex" data-qatag="controllersAndTitle">
        {/* Seats Title */}
        <Box data-qatag="logoContainer" className={classes.logoContainer}>
          <img
            data-qatag="titleIcon"
            className={styles.titleIcon}
            src={seatsIcon}
            alt="wire-frame"
          />
          <Typography
            data-qatag="titleText"
            variant="h6"
            className={classes.titleText}
          >
            Seats
          </Typography>
        </Box>
        {/* Search Component */}
        <Box data-qatag="addPaymentButton" className={classes.addPaymentButton}>
          {/* Search Component Tablet */}
          <SearchTextField
            data-qatag="searchTextField"
            value={searchText}
            onChange={(value) => setSearchText(value)}
            placeholder="Search"
            containerClassName={classes.searchTablet}
          />
          {/* Search Component for mobile */}
          <Box
            data-qatag="searchMobile"
            display="flex"
            alignItems="center"
            className={classes.searchMobile}
          >
            <IconButton data-qatag="searchIcon" aria-label="searchIcon">
              <SearchIcon
                data-qatag="searchIcon"
                className={classes.searchIcon}
              />
            </IconButton>
            <TextField
              data-qatag="standard-basic"
              id="standard-basic"
              onChange={(e) => debouncedSearch(e.target.value)}
              className={classes.textField}
              value={searchText}
              label={!searchText ? "Search" : ""}
            />
          </Box>
        </Box>
      </Box>
      {filteredUsers?.length ? (
        <>
          <Box data-qatag="tableHeader" className={styles.tableHeader}>
            <Box data-qatag="headerDate" className={styles.headerDate}>
              {`Users (${enableUsers?.length})`}
            </Box>
            <Box data-qatag="headerTotal" className={styles.headerRole}>
              Role
            </Box>
            <Box data-qatag="headerSettings" className={styles.headerSettings}>
              Settings
            </Box>
          </Box>
          {filteredUsers
            ?.sort((a, b) => {
              if (b.attributes?.toLowerCase()?.includes("admin")) return 1;
              return -1; //default return value (no sorting)
            })
            ?.map((info: IUser, index: number) => (
              <Seat
                data-qatag="seat"
                info={info}
                index={index}
                key={`${info.email}-${index}`}
              />
            ))}
        </>
      ) : usersStatus !== ReducerStatus.Idle ? (
        <EmptyState
          data-qatag="EmptyState"
          error="Empty"
          suggestion="No users were found"
        />
      ) : (
        ""
      )}
      {/* Loading modal */}
      <Modal
        data-qatag="loadingModal"
        open={usersStatus === ReducerStatus.Idle}
      >
        <div
          data-qatag="progressContainer"
          className={styles.progressContainer}
        >
          <CircularProgress data-qatag="circularProgress" color="secondary" />
        </div>
      </Modal>
      <AlertError
        data-qatag="alertError"
        isOpen={openErrorModal}
        closeHandler={() => {
          setOpenErrorModal((prevState) => !prevState);
        }}
        text={error}
      />
      <AlertSuccess
        data-qatag="alertSuccess"
        isOpen={openSuccessModal}
        title={alertTitle}
        description={alertDescription}
        closeHandler={() => {
          setOpenSuccessModal((prevState) => !prevState);
          dispatch(fetchOrganizationUsers());
        }}
      />
      <AlertWarning
        data-qatag="alertWarning"
        approveHandler={handleDeleteMethod}
        isOpen={openWarningModal}
        closeHandler={() => {
          setOpenWarningModal((prevState) => !prevState);
        }}
      />
    </Box>
  );
};

export default SeatManagement;
