import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";
import { IServicesSummary } from "./TheWhat/types";

export const fetchServicesSummary = createAsyncThunk<
  IServicesSummary,
  void,
  {
    state: RootState;
  }
>("organization/fetchPaymentMethods", async (_, thunkApi) => {
  try {
    const url = "/api/Organization/servicesummary";

    const apiResponse = await RESTGatewayAPI.get(url);

    return apiResponse?.data;
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch payment methods : ${error}`
    );
  }
});

const initialState: IReducerState<IServicesSummary> = {
  data: {},
  status: {
    [fetchServicesSummary.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const paymentMethodsSlice = createSlice({
  name: "paymentMethod",
  initialState,
  reducers: {
    resetFetch: (state) => {
      state.status[fetchServicesSummary.typePrefix] = ReducerStatus.Idle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServicesSummary.pending, (state) => {
      state.status[fetchServicesSummary.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(fetchServicesSummary.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status[fetchServicesSummary.typePrefix] = ReducerStatus.Succeeded;
    });
    builder.addCase(fetchServicesSummary.rejected, (state) => {
      state.status[fetchServicesSummary.typePrefix] = ReducerStatus.Failed;
    });
  },
});

export const { resetFetch } = paymentMethodsSlice.actions;

export const getServicesSummary = (state: RootState) =>
  state.servicesSummary.data;
export const getFetchStatus = (state: RootState) =>
  state.servicesSummary.status[fetchServicesSummary.typePrefix];

export default paymentMethodsSlice.reducer;
