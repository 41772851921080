import { store } from "app/store";
import {
  downloadCancelled,
  downloadComplete,
  downloadError,
  downloadProgressUpdated,
  downloadQueued,
  downloadStarting,
  driverInstallStarting,
  driverInstallUpdated,
} from "features/driverInstall/DriverSlice";
import {
  updateProgress,
  allScansCompleted,
  hardwareScanReceived,
} from "features/scan/ScanSlice";
import {
  completeFileCleanup,
  fetchFileCleanupScan,
  fetchLast30DaysReport,
  completeScan as completeFileCleanupScan,
} from "features/fileCleaning/FileCleaningSlice";

import { IServiceMessage, SignalRMessageHub, WSMessageType } from "ui.common";
import { driverStateResponseReceived } from "features/driverInstall/Thunks";
import { doForceDisconnect } from "app/Agent/AgentSlice";

export class SignalRHubConnector {
  signalRHub: SignalRMessageHub;

  constructor(hub: SignalRMessageHub) {
    this.signalRHub = hub;
    this.signalRHub.MessageReceived.Subscribe(this.messageReceived, this);
    this.signalRHub.ForceDisconnected.Subscribe(this.forceDisconnect, this);
  }

  private messageReceived(message: IServiceMessage) {
    switch (message.MessageType) {
      case WSMessageType.SCAN_PROGRESS:
        store.dispatch(updateProgress(message.Payload));
        break;
      case WSMessageType.ALL_SCANS_COMPLETE:
        store.dispatch(allScansCompleted(message.Payload));
        break;
      case WSMessageType.HARDWARE_SCAN_COMPLETE:
        store.dispatch(hardwareScanReceived(message.Payload));
        break;
      case WSMessageType.UPDATE_DRIVER_STARTING:
        store.dispatch(driverInstallStarting(message.Payload));
        break;
      case WSMessageType.DRIVER_INSTALL_UPDATE:
        store.dispatch(driverInstallUpdated(message.Payload));
        break;
      case WSMessageType.DRIVER_STATE_RESPONSE:
        store.dispatch(driverStateResponseReceived(message.Payload));
        break;
      case WSMessageType.DOWNLOAD_DRIVER_PROGRESS:
        store.dispatch(downloadProgressUpdated(message.Payload));
        break;
      case WSMessageType.DOWNLOAD_DRIVER_QUEUED:
        store.dispatch(downloadQueued(message.Payload));
        break;
      case WSMessageType.DOWNLOAD_DRIVER_CANCELLED:
        store.dispatch(downloadCancelled(message.Payload));
        break;
      case WSMessageType.DOWNLOAD_DRIVER_STARTING:
        store.dispatch(downloadStarting(message.Payload));
        break;
      case WSMessageType.DOWNLOAD_DRIVER_COMPLETE:
        store.dispatch(downloadComplete(message.Payload));
        break;
      case WSMessageType.DOWNLOAD_DRIVER_ERROR:
        store.dispatch(downloadError(message.Payload));
        break;
      case WSMessageType.DELETE_CLEAN_UP_FILES_COMPLETED:
        store.dispatch(completeFileCleanup(message.Payload));
        store.dispatch(fetchLast30DaysReport());
        break;
      case WSMessageType.FILE_CLEANUP_SCAN_COMPLETE:
        store.dispatch(completeFileCleanupScan());
        store.dispatch(fetchFileCleanupScan());
        break;
    }
  }

  private forceDisconnect() {
    store.dispatch(doForceDisconnect());
  }
}
