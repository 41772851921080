import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import titleIcon from "assets/img/driver/icn-driver-updated.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      width: "419px",
      height: "321px",
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "330px",
        height: "321px",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(3),
      },
    },
    actionButton: {
      width: "255px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    description_container: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);
//#endregion Component Styles

//#region Component Props
export interface IInstallCompleteProps {
  onContinue: () => void;
}
//#endregion Component Props

//#region Component
export const InstallComplete: React.FC<IInstallCompleteProps> = (props) => {
  const classes = useStyles();
  return (
    <Box
      data-qatag="driver.modal.install.complete"
      id="driver.modal.install.complete"
      className={classes.modalRoot}
    >
      <img
        data-qatag="driver.modal.install.complete.icon"
        id="driver.modal.install.complete.icon"
        src={titleIcon}
        alt=""
      />

      <FormattedMessage
        data-qatag="driver.modal.install.complete.title"
        id="driver.modal.install.complete.title"
        defaultMessage="Driver Successfully Updated!"
      >
        {(text) => (
          <Typography
            data-qatag="driver.modal.install.complete.title.text"
            id="driver.modal.install.complete.title.text"
            variant="h5"
            color="textPrimary"
            align="center"
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <Box
        data-qatag="driver.modal.install.complete.description_container"
        id="driver.modal.install.complete.description_container"
        className={classes.description_container}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.complete.description"
          id="driver.modal.install.complete.description"
          defaultMessage="You have successfully updated this driver. {br} Great job and keep it up!"
          values={{
            br: (
              <br
                data-qatag="driver.modal.install.complete.break"
                id="driver.modal.install.complete.break"
              />
            ),
          }}
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.complete.description.text"
              id="driver.modal.install.complete.description.text"
              variant="body2"
              color="primary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
      <AppButton
        data-qatag="driver.modal.install.complete.completeButton"
        id="driver.modal.install.complete.completeButton"
        className={classes.actionButton}
        buttonStyle={AppButtonStyles.Green}
        onClick={props.onContinue}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.complete.completeButton.content"
          id="driver.modal.install.complete.completeButton.content"
          defaultMessage="Continue"
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.complete.completeButton.content.text"
              id="driver.modal.install.complete.completeButton.content.text"
              variant="body2"
              align="center"
              style={{ fontWeight: 600 }}
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </AppButton>
    </Box>
  );
};
//#endregion Component
