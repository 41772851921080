import { Field, Form, Formik } from "formik";
import { object, string, number } from "yup";
import { Typography, Box } from "@material-ui/core";
import {
  AppButton,
  AppButtonStyles,
  Select,
  SelectOptionInterface,
  getCountries,
  Spinner,
  Alert,
  AlertIcon,
} from "solveiq.designsystem";
import { makeStyles, Theme } from "@material-ui/core/styles";
import cvvIcon from "assets/icon/cvv-security.svg";
import styles from "./CardForm.module.css";
import { SimpleTextField } from "solveiq.designsystem";
import {
  submitPaymentMethod,
  getSubmitStatus,
  fetchPaymentMethods,
  resetSubmit,
} from "../PaymentMethods/PaymentMethodsSlice";
import CardInput from "./CardField";
import { ReducerStatus } from "model/IReducerState";
import { RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { IPaymentMethodToAdd } from "./types";

export interface ICardForm {
  /* Name as it appears on the card */
  name?: string;
  /* Card number */
  cardNumber?: number;
  /* Month Expiration */
  monthExpiration?: number;
  /* Year Expiration */
  yearExpiration?: number;
  /* CVV Security Code */
  securityCode?: number;
  /* Zip Code */
  zipCode?: string | number;
  /* Submit Payment method Request Status */
  submitStatusSelector?: (state: RootState) => ReducerStatus;
  /* Callback to update the isOpen on the parent*/
  handleOnClose: () => void;
}

/* Custom style for Dialog */
const useStyles = makeStyles((theme: Theme) => ({
  buttonText: {
    lineHeight: 1,
  },
  important: {
    color: "#BE2222",
  },
  error: {
    color: "#BE2222",
    marginLeft: 8,
  },
  responsiveElement: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "50%",
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "45%",
    },
  },
  cvvImageContainer: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "50%",
      textAlign: "start",
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "30%",
      textAlign: "end",
    },
  },
  cvvContainer: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "50%",
      paddingLeft: 0,
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "70%",
      paddingLeft: 24,
    },
  },
  responsiveBox: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "100%",
      marginTop: 16,
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "50%",
    },
  },
  responsiveContainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      marginTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
      marginTop: 8,
    },
  },
  button: {
    [theme.breakpoints.down("md")]: {
      marginTop: 24,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 28,
    },
  },
}));

function getMonths(startMonth = 0): SelectOptionInterface[] {
  const options = [];
  for (let i = startMonth; i < 12; i++) {
    options.push({
      value: i.toString(),
      label: (i + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
    });
  }

  return options;
}

function getYears(): SelectOptionInterface[] {
  const options = [];
  const currentYear = new Date().getFullYear();
  for (let i = 0; i < 20; i++) {
    options.push({
      value: (currentYear + i).toString(),
      label: (currentYear + i).toString(),
    });
  }
  return options;
}

const validationSchema = object({
  name: string().required("Name is required"),
  monthExpiration: number().required("Required"),
  yearExpiration: number().required("Required"),
  securityCode: string()
    .required("Required")
    .matches(/^[0-9]{3,4}$/, "Invalid CVV"),
  zipCode: string()
    .required("Zip Code is required")
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Please enter a valid zip code")
    .max(9, "9 digits"),
});

const CardForm: React.FC<ICardForm> = ({
  name = "",
  cardNumber,
  monthExpiration,
  yearExpiration,
  securityCode,
  zipCode = "",
  submitStatusSelector = (state: RootState) => getSubmitStatus(state),
  handleOnClose,
}) => {
  // Custom styles
  const classes = useStyles();

  const dispatch = useDispatch();

  /* method to submit new payment Method */
  const handleSubmit = async (values: IPaymentMethodToAdd) => {
    dispatch(submitPaymentMethod(values));
  };

  // Function to validate form data
  const validate = (values: IPaymentMethodToAdd) => {
    const errors: { cardNumber?: string; monthExpiration?: string } = {};

    // Validate Card
    if (!values.cardNumber) {
      errors.cardNumber = "Please enter a credit card";
    } else {
      // Validate type cards
      const cardWithoutFormat = values.cardNumber.replace(/\s/g, "");
      // We should evaluate not to use REGEX but I need a card type to submit

      if (
        // Visa Master Card
        !(
          /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/.test(
            cardWithoutFormat
          ) ||
          // Visa Card
          /^4[0-9]{12}(?:[0-9]{3})?$/.test(cardWithoutFormat) ||
          // Mastercard
          /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
            cardWithoutFormat
          ) ||
          // Discover Card
          /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/.test(
            cardWithoutFormat
          ) ||
          // Amex Card
          /^3[47][0-9]{13}$/.test(cardWithoutFormat)
        )
      ) {
        errors.cardNumber = "Unrecognized card type";
      }
    }

    // Validate Month and year
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    if (values.monthExpiration && values.yearExpiration) {
      if (
        Number(values.yearExpiration) === currentYear &&
        Number(values.monthExpiration) < currentMonth
      ) {
        errors.monthExpiration = "Invalid Month";
      }
    }

    return errors;
  };

  /* Payment Methods Request Status */
  const submitStatus = useSelector(submitStatusSelector);
  return (
    <Formik
      data-qatag="Formik"
      initialValues={{
        name,
        cardNumber: "",
        monthExpiration: new Date().getMonth(),
        yearExpiration: new Date().getFullYear(),
        securityCode: "",
        zipCode: "",
        country: "US",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ isSubmitting, errors, setFieldValue, touched }) => (
        <Form data-qatag="form" className={styles.form}>
          <div data-qatag="fieldContainer" className={styles.fieldContainer}>
            <Box data-qatag="nameContainer" width="100%" mt={4}>
              <Field
                data-qatag="field"
                component={SimpleTextField}
                name="name"
                type="text"
                label="Name as it appears on card"
                className={styles.field}
              />
            </Box>
            <Box data-qatag="cardNumberBox" width="100%" mt={3}>
              <Field
                data-qatag="field"
                component={SimpleTextField}
                name="cardNumber"
                type="text"
                label="Card Number"
                className={styles.field}
                InputProps={{
                  inputComponent: CardInput,
                }}
              />
            </Box>

            <Box
              data-qatag="responsiveContainer"
              className={classes.responsiveContainer}
            >
              <Box data-qatag="responsiveBox" className={styles.dateContainer}>
                <Box data-qatag="dateContainer" display="flex">
                  <Box
                    data-qatag="responsiveElement"
                    className={classes.responsiveElement}
                  >
                    <Select
                      data-qatag="monthExpiration"
                      name="monthExpiration"
                      onChange={(month) => {
                        setFieldValue("monthExpiration", month);
                      }}
                      defaultValue={new Date().getMonth().toString()}
                      options={getMonths()}
                      noneOption={false}
                      label="Month"
                    />
                    {errors.monthExpiration && touched.monthExpiration && (
                      <Typography
                        data-qatag="error"
                        variant="body1"
                        className={classes.error}
                      >
                        {errors.monthExpiration}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    data-qatag="responsiveElement"
                    className={classes.responsiveElement}
                    ml={1}
                  >
                    <Select
                      data-qatag="yearExpiration"
                      name="yearExpiration"
                      onChange={(year) => {
                        setFieldValue("yearExpiration", year);
                      }}
                      defaultValue={new Date().getFullYear().toString()}
                      options={getYears()}
                      noneOption={false}
                      label="Year"
                    />
                    {errors.yearExpiration && touched.yearExpiration && (
                      <Typography
                        data-qatag="error"
                        variant="body1"
                        className={classes.error}
                      >
                        {errors.yearExpiration}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                data-qatag="responsiveBox"
                mt={2}
                className={classes.responsiveBox}
              >
                <Box data-qatag="securityCodeBox" display="flex">
                  <Box
                    data-qatag="cvvContainer"
                    className={classes.cvvContainer}
                  >
                    <Field
                      data-qatag="field"
                      component={SimpleTextField}
                      name="securityCode"
                      type="text"
                      label="CVV"
                      className={styles.field}
                    />
                  </Box>
                  <Box
                    data-qatag="cvvImageContainer"
                    className={classes.cvvImageContainer}
                    ml={2}
                    textAlign="end"
                  >
                    <img
                      data-qatag="cvvIcon"
                      className={styles.cvvIcon}
                      src={cvvIcon}
                      alt="Security Code"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box data-qatag="zipCodeBox" width="100%" mt={3}>
              <Field
                data-qatag="field"
                component={SimpleTextField}
                name="zipCode"
                type="text"
                label="Zip Code"
                className={styles.field}
              />
            </Box>
            {/* Country */}
            <Box
              data-qatag="countrySelectContainer"
              mt={1}
              className={`${styles.responsiveField} ${styles.cardInfoField}`}
            >
              <Select
                data-qatag="countrySelect"
                name="countrySelect"
                onChange={(country) => {
                  setFieldValue("country", country);
                }}
                options={getCountries.map((country) => ({
                  value: country.code,
                  label: country.name,
                }))}
                defaultValue={"US"}
                noneOption={false}
              />
            </Box>
          </div>
          {/* Button */}
          <Box
            data-qatag="button"
            width={1}
            textAlign="center"
            className={classes.button}
          >
            <AppButton
              data-qatag="cardFormButton"
              buttonStyle={AppButtonStyles.Green}
              className={`${styles.customButton}`}
              disabled={isSubmitting}
              type="submit"
            >
              <Typography
                data-qatag="buttonText"
                align="center"
                className={classes.buttonText}
                variant="body2"
              >
                Save Payment Method
              </Typography>
            </AppButton>
          </Box>
          {/* Loading modal */}
          <Spinner
            data-qatag="loadingModal"
            text="Saving..."
            isActive={ReducerStatus[submitStatus] === ReducerStatus.Loading}
          />
          {/* Alert error */}
          <Alert
            data-qatag="alertError"
            icon={AlertIcon.Warning}
            title="Error"
            text="Something went wrong"
            buttonText="Continue"
            approveHandler={() => {
              dispatch(resetSubmit());
            }}
            isOpen={ReducerStatus[submitStatus] === ReducerStatus.Failed}
            closeHandler={() => {
              dispatch(resetSubmit());
            }}
          />
          {/* Success Alert */}
          <Alert
            data-qatag="successAlert"
            icon={AlertIcon.Success}
            title="Payment method saved!"
            text="Your payment method has been successfully saved and can now be used for transaction."
            buttonText="Close"
            approveHandler={() => {
              dispatch(resetSubmit());
              dispatch(fetchPaymentMethods());
              handleOnClose();
            }}
            isOpen={ReducerStatus[submitStatus] === ReducerStatus.Succeeded}
            closeHandler={() => {
              dispatch(resetSubmit());
              dispatch(fetchPaymentMethods());
              handleOnClose();
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CardForm;
