import { FormattedMessage, useIntl } from "react-intl";
import { Typography, Box } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";
import { Modal } from "solveiq.designsystem";
import headerImage from "assets/icon/icn-rebootPC.svg";
import { IRebootPayload } from "model/messaging/payloads/IRebootPayload";
import { rebootComputer } from "model/system/SystemSlice";
import { useDispatch } from "react-redux";

export interface IRebootDialogProps {
  isOpen: boolean;
  closeDialogHandler: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.up("sm")]: {
        width: "420px",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "330px",
      alignItems: "center",
      margin: "0 auto",
      textAlign: "center",
      paddingBottom: "30px",
    },
    image: {
      width: "74px",
      height: "74px",
    },
    rebootButton: {
      width: "245px",
      marginTop: "20px",
      marginBottom: "20px",
    },
  })
);

export const RebootDialog: React.FC<IRebootDialogProps> = (props) => {
  const prefix = "rebootDialog";
  const classes = useStyles();
  const dispatch = useDispatch();

  const rebootNow = () => {
    const payload: IRebootPayload = {
      ShutdownReason: "User requested",
      DelaySeconds: 0,
      ShowUiOnRestart: true,
    };
    dispatch(rebootComputer(payload));
  };

  return (
    <Modal
      data-qatag="rebootDialog"
      open={props.isOpen}
      onClose={props.closeDialogHandler}
      className={classes.dialog}
    >
      <div data-qatag="rebootDialogContainer" className={classes.container}>
        <img data-qatag="" src={headerImage} alt="" className={classes.image} />
        <Typography data-qatag="rebootDialogTitleTypography" variant="h4">
          <FormattedMessage
            data-qatag="rebootDialogTitleMessage"
            id={`${prefix}.title`}
            defaultMessage="Reboot Your Computer"
          />
        </Typography>
        <Typography
          data-qatag="rebootDialogDescriptionTypography"
          variant="body2"
        >
          <FormattedMessage
            data-qatag="rebootDialogDescriptionMessage"
            id={`${prefix}.description`}
            defaultMessage="Are you sure you want to reboot your computer now?"
          />
        </Typography>
        <AppButton
          data-qatag=""
          buttonStyle={AppButtonStyles.Green}
          onClick={rebootNow}
          className={classes.rebootButton}
        >
          <Typography
            data-qatag="rebootDialogRebootButtonTypography"
            variant="body2"
          >
            <FormattedMessage
              data-qatag="rebootDialogRebootButtonMessage"
              id={`${prefix}.rebootNow`}
              defaultMessage="Reboot Now"
            />
          </Typography>
        </AppButton>
        <AppButton
          data-qatag=""
          buttonStyle={AppButtonStyles.LinkGray}
          onClick={props.closeDialogHandler}
        >
          <Typography
            data-qatag="rebootDialogCancelButtonTypography"
            variant="body2"
          >
            <FormattedMessage
              data-qatag="rebootDialogCancelButtonMessage"
              id={`${prefix}.cancel`}
              defaultMessage="Cancel"
            />
          </Typography>
        </AppButton>
      </div>
    </Modal>
  );
};
