import { createAsyncThunk } from "@reduxjs/toolkit";
import RESTGatewayAPI from "api/gatewayAPI";
import { RootState } from "app/store";
import { ISupportCasePayload } from "model/messaging/payloads/ISupportCasePayload";
import { selectCurrentUser } from "session/SessionSlice";
import { IHelpForm } from "./HelpScreen";

export const submitHelpForm = createAsyncThunk<
  string,
  IHelpForm,
  {
    state: RootState;
  }
>("help/submitHelpForm", async (details, thunkApi) => {
  const { currentRegKey } = thunkApi.getState().session.data;

  // this is currently necessary for the support case call
  // this should be removed when contact creation has been automated
  // temp code
  const firstName =
    details.name.indexOf(" ") > 0
      ? details.name.split(" ").slice(0, 1)
      : details.name;
  const lastName =
    details.name.indexOf(" ") > 0 ? details.name.split(" ").slice(1, 2) : "";
  const addContactUrl = `/api/salesforce/contact`;
  const addContactPayload = {
    RegistrationKey__c: currentRegKey,
    FirstName: Array.isArray(firstName) ? firstName[0] : firstName,
    LastName: Array.isArray(lastName) ? lastName[0] : lastName,
    Email: details.email,
    Description: "Blake help screen test",
  };
  try {
    await RESTGatewayAPI.post(addContactUrl, addContactPayload);
  } catch (e) {
    return thunkApi.rejectWithValue({
      email: details.email,
      error: e,
    });
  }
  // end temp code

  const url = `/api/salesforce/cases/${currentRegKey}`;
  const payload = {
    Origin: "App",
    Reason: "Help Request",
    Description: details.description,
    Priority: "Normal",
    Status: "New",
    Subject: details.subject,
    Type: "Help Request",
    SuppliedEmail: details.email,
    SuppliedName: details.name,
    Product_Name__c: "Solve iQ Business",
    Product_Id__c: 5,
  } as ISupportCasePayload;

  try {
    const response = await (await RESTGatewayAPI.post(url, payload)).data;
    return response.id as string;
  } catch (e) {
    return thunkApi.rejectWithValue({
      email: details.email,
      submitPayload: payload,
      error: e,
    });
  }
});

export const submitFeedbackForm = createAsyncThunk<
  string,
  {
    name?: string;
    email?: string;
    subject: string;
    description: string;
  },
  {
    state: RootState;
  }
>("cancelFlow/submitFeedback", async (details, thunkApi) => {
  const state = thunkApi.getState();
  const { currentRegKey } = state.session.data;
  const defaultEmail = selectCurrentUser(state)?.email;

  const url = `/api/salesforce/cases/${currentRegKey}`;
  const payload = {
    Origin: "App",
    Reason: "Cancel Subscription",
    Description: details.description,
    Priority: "Normal",
    Status: "New",
    Subject: details.subject,
    Type: "Cancel Subscription",
    SuppliedEmail: details?.email || defaultEmail,
    Product_Name__c: "Solve iQ Business",
    Product_Id__c: 5,
  } as ISupportCasePayload;

  try {
    const response = await (await RESTGatewayAPI.post(url, payload)).data;
    return response.id as string;
  } catch (e) {
    return thunkApi.rejectWithValue({
      email: details.email,
      submitPayload: payload,
      error: e,
    });
  }
});
