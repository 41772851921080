import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import titleIcon from "assets/img/driver/icn-install-problem.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      width: "496px",
      height: "427px",
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "330px",
        height: "427px",
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: theme.spacing(3),
      },
    },
    actionButton: {
      width: "255px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    description_container: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(7),
    },
  })
);
//#endregion Component Styles

//#region Component Props
export interface IInstallFailedProps {
  onContinue: () => void;
}
//#endregion Component Props

//#region Component
export const InstallFailed: React.FC<IInstallFailedProps> = (props) => {
  const classes = useStyles();
  return (
    <Box
      data-qatag="driver.modal.install.failed"
      id="driver.modal.install.failed"
      className={classes.modalRoot}
    >
      <img
        data-qatag="driver.modal.install.failed.icon"
        id="driver.modal.install.failed.icon"
        src={titleIcon}
        alt=""
      />
      <FormattedMessage
        data-qatag="driver.modal.install.failed.title"
        id="driver.modal.install.failed.title"
        defaultMessage="There was a problem {br} during the install..."
        values={{
          br: (
            <br
              data-qatag="driver.modal.install.failed.break"
              id="driver.modal.install.failed.break"
            />
          ),
        }}
      >
        {(text) => (
          <Typography
            data-qatag="driver.modal.install.failed.title.text"
            id="driver.modal.install.failed.title.text"
            variant="h4"
            color="textPrimary"
            align="center"
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <Box
        data-qatag="driver.modal.install.failed.description.container"
        id="driver.modal.install.failed.description.container"
        className={classes.description_container}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.failed.description"
          id="driver.modal.install.failed.description"
          defaultMessage="An error report has been sent to our engineering team and will be looked at shortly"
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.failed.description.text"
              id="driver.modal.install.failed.description.text"
              variant="body2"
              color="primary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
      <AppButton
        data-qatag="driver.modal.install.failed.continueButton"
        id="driver.modal.install.failed.continueButton"
        className={classes.actionButton}
        buttonStyle={AppButtonStyles.Green}
        onClick={props.onContinue}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.failed.continueButton.content"
          id="driver.modal.install.failed.continueButton.content"
          defaultMessage="Continue"
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.failed.continueButton.content.text"
              id="driver.modal.install.failed.continueButton.content.text"
              variant="body2"
              align="center"
              style={{ fontWeight: 600 }}
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </AppButton>
    </Box>
  );
};
//#endregion Component
