import { Box, Typography, IconButton, Icon, Divider } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import styles from "./MyPlan.module.css";
import {
  TabPanel,
  NumberInput,
  AppButton as Button,
  AppButtonStyles,
  Spinner,
  Alert,
  AlertIcon,
} from "solveiq.designsystem";
import { ReducerStatus } from "model/IReducerState";
import { RootState } from "app/store";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import myPlanIcon from "assets/icon/icn-subscription.svg";
import checkIcon from "assets/icon/icn-green-chck.svg";
import { PaymentMethod } from "../types";
import visaIcon from "assets/icon/icn-visa.svg";
import mastercardIcon from "assets/icon/icn-mastercard.svg";
import discoverIcon from "assets/icon/icn-discover.svg";
import amexIcon from "assets/icon/icn-amex.svg";
import PayPalLogo from "assets/img/PayPalLogo.png";
import editIcon from "assets/icon/icn-edit-cc.svg";
import detailsIcon from "assets/icon/icn-details-info.svg";
import { fetchMyPlan, selectMyPlan, selectMyPlanStatus } from "./MyPlanSlice";
import {
  fetchQuote,
  selectQuote,
  selectQuoteStatus,
  setQuoteStatus,
} from "./BillingQuoteSlice";
import {
  updateSubscription,
  selectUpdateSubscriptionStatus,
  setUpdateSubscription,
} from "features/teamManagement/SubscriptionSlice";
import { IBillingSummary, IBillingQuote } from ".";
import ReviewOrder from "../ReviewOrder";
import { debounce } from "core/Helpers";
import CancelFlow from "features/CancelFlow";
import { fetchReceipt } from "../BillingHistory/BillingHistorySlice";
import {
  fetchSubscription,
  selectSubscription,
  selectResumeSubscriptionStatus,
  resumeSubscription,
  resetResume,
  selectFetchSubscriptionStatus,
} from "features/teamManagement/SubscriptionSlice";
import PaymentOnFile from "../PaymentOnFile";
import { fetchPaymentMethods } from "../PaymentMethods/PaymentMethodsSlice";
import EditPaymentMethodModal from "../EditPaymentMethodModal";

export interface IMyPlan {
  /* Active tab number */
  tabNumber: number;
  /* Set tab number */
  setTabNumber: (tab: number) => void;
  /* Corresponding tab index */
  tabIndex: number;
  /* My plan summary */
  myPlanSelector?: (state: RootState) => IBillingSummary;
  /* My plan summary Request Status */
  myPlanStatusSelector?: (state: RootState) => ReducerStatus;
  /* Billing Quote  depending seats length */
  quoteSelector?: (state: RootState) => IBillingQuote;
  /* My plan summary Request Status */
  quoteStatusSelector?: (state: RootState) => ReducerStatus;
  /* My plan summary Request Status */
  subscriptionUpdateStatusSelector?: (state: RootState) => ReducerStatus;
}

function getIcon(paymentType: string) {
  if (paymentType === PaymentMethod[PaymentMethod.Amex]) return amexIcon;
  if (paymentType === PaymentMethod[PaymentMethod.Discover])
    return discoverIcon;
  if (paymentType === PaymentMethod[PaymentMethod.MasterCard])
    return mastercardIcon;
  if (paymentType === PaymentMethod[PaymentMethod.Visa]) return visaIcon;
  return PayPalLogo;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      order: 2,
      flex: "100%",
    },
    titleText: {
      color: theme.palette.secondary.light,
      fontWeight: 600,
    },
    buttonText: {
      lineHeight: 1,
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",
      marginTop: 40,
      marginBottom: 24,
      [theme.breakpoints.down("md")]: {
        marginLeft: 20,
        marginTop: 24,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 8,
        marginBottom: 0,
      },
    },
    container: {
      display: "flex",
      marginBottom: 48,
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginRight: 32,
      marginLeft: 32,
      [theme.breakpoints.down("md")]: {
        flex: "100%",
        marginTop: 0,
        order: 2,
        marginRight: 0,
        marginLeft: 0,
      },
    },
    editIcon: {
      width: 30,
      height: 30,
      marginTop: -3,
    },
  })
);

export enum quoteTypeEnum {
  Upgrade = 0,
  Downgrade = 1,
}

const MyPlanTab: React.FC<IMyPlan> = ({
  tabNumber,
  setTabNumber,
  tabIndex,
  myPlanSelector = (state: RootState) => selectMyPlan(state),
  myPlanStatusSelector = (state: RootState) => selectMyPlanStatus(state),
  quoteSelector = (state: RootState) => selectQuote(state),
  quoteStatusSelector = (state: RootState) => selectQuoteStatus(state),
  subscriptionUpdateStatusSelector = (state: RootState) =>
    selectUpdateSubscriptionStatus(state),
}) => {
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  // State to display either the billing or the cancel flow
  const [showCancelFlow, setCancelFlow] = useState(false);

  // For success alert
  const [seatsNumbers, setSeatsNumbers] = useState(0);
  // Alert to confirm purchase
  const [showAlertModal, setShowAlertModal] = useState(false);
  // State to display review modal
  const [showReviewModal, setShowReviewModal] = useState(false);

  /* Retrieve data from the service */
  const dispatch = useDispatch();

  /* My Plan detail */
  const myPlanSummary = useSelector(myPlanSelector);

  /* My Plan Request Status */
  const requestStatus = useSelector(myPlanStatusSelector);

  /* Retrieve data from the service */
  useEffect(() => {
    if (ReducerStatus[requestStatus] === ReducerStatus.Idle) {
      dispatch(fetchMyPlan());
    }
  }, [dispatch, requestStatus]);

  /* Seats Length Status */
  const requestStatusUpdateSeats = useSelector(
    subscriptionUpdateStatusSelector
  );

  // If the requestStatusUpdateSeats is success we should retrieve again the summary
  useEffect(() => {
    // If seats length change and values are different to the default slice values, dispatch
    if (ReducerStatus[requestStatusUpdateSeats] === ReducerStatus.Succeeded) {
      setShowReviewModal(true);
      setShowAlertModal(false);
    }
  }, [dispatch, requestStatusUpdateSeats, seatsNumbers]);

  // Unit price per seat for a specific user
  const pricePerSeat = myPlanSummary?.UnitPrice;
  // Quantity of seats for a specific user
  const seatsLength = myPlanSummary?.SeatCount;
  // End Date current billing cycle,
  const billingEndDate = myPlanSummary?.BillingEndDate;
  // Payment method Type
  const cardType = myPlanSummary?.PaymentMethod?.CardType;
  // Credit card last 4 digit
  const creditCard = myPlanSummary?.PaymentMethod?.CardNumber?.replaceAll(
    "*",
    ""
  )?.trim();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDispatch = useCallback(
    debounce((input) => dispatch(fetchQuote(input)), 850),
    []
  );

  // Use Effect to generate a quote when the seats length change
  useEffect(() => {
    // If seats length change and values are different to the default slice values, dispatch
    if (
      seatsNumbers !== myPlanSummary?.SeatCount &&
      myPlanSummary?.SeatCount !== 0 &&
      seatsNumbers !== 0
    ) {
      debouncedDispatch(seatsNumbers);
    }
  }, [debouncedDispatch, dispatch, myPlanSummary?.SeatCount, seatsNumbers]);

  /* Quote per seats */
  const quoteSummary = useSelector(quoteSelector);

  /* My Plan Request Status */
  const quoteStatus = useSelector(quoteStatusSelector);

  // Data from the quote to substitute the summary data

  // Unit price per seat for a specific user from Quote
  const quotePricePerSeat = quoteSummary?.UnitPrice;
  // Quantity of seats for a specific user
  const quoteSeatsLength = quoteSummary?.SeatCount;
  // Taxes to pay in the quote
  const taxesQuote = quoteSummary?.Tax;

  // side effect when the seatsLength change
  useEffect(() => {
    setSeatsNumbers(seatsLength);
  }, [seatsLength]);

  const [quoteType, setQuoteType] = useState(quoteTypeEnum.Downgrade);

  useEffect(() => {
    setQuoteType(
      quoteTypeEnum[quoteTypeEnum.Downgrade] === quoteSummary?.PreviewType
        ? quoteTypeEnum.Downgrade
        : quoteTypeEnum.Upgrade
    );
  }, [quoteSummary?.PreviewType]);

  // Seats to be added on downgrade is 0
  const seatsToAdd = quoteType === quoteTypeEnum.Downgrade ? 0 : seatsNumbers;

  // If the operation is a Downgrade present 0
  const taxesPerSeats =
    (quoteType === quoteTypeEnum.Downgrade || seatsNumbers === seatsLength
      ? 0
      : 1) *
    (quoteType === quoteTypeEnum.Upgrade && seatsNumbers !== seatsLength
      ? taxesQuote
      : 0);
  // Total to present in the quote
  const totalPerSeats =
    (quoteType === quoteTypeEnum.Downgrade || seatsNumbers === seatsLength
      ? 0
      : 1) *
    (seatsNumbers === seatsLength ? myPlanSummary?.Total : quoteSummary?.Total);

  // Code to resume flow
  useEffect(() => {
    // Dispatch action to retrieve status when loading
    dispatch(fetchSubscription());
  }, [dispatch]);

  /* Subscription Status */
  const subscriptionStatus = useSelector(selectSubscription);

  /* Subscription Resume Status (request)*/
  const resumeStatus = useSelector(selectResumeSubscriptionStatus);
  /* Subscription Resume Status (request)*/
  const resumeFetchStatus = useSelector(selectFetchSubscriptionStatus);

  //  State for resume flow
  const [showResumeFLow, setShowResumeFlow] = useState(false);

  // State to display edit payment method modal
  const [showEditPaymentModal, setShowEditPaymentModal] = useState(false);

  const isLoading =
    ReducerStatus[requestStatus] === ReducerStatus.Loading ||
    ReducerStatus[quoteStatus] === ReducerStatus.Loading ||
    ReducerStatus[requestStatusUpdateSeats] === ReducerStatus.Loading ||
    ReducerStatus[resumeStatus] === ReducerStatus.Loading ||
    ReducerStatus[resumeFetchStatus] === ReducerStatus.Loading;
  return (
    <TabPanel
      data-qatag="tabPanel.billingHistory"
      value={tabNumber}
      index={tabIndex}
      className={classes.tabPanel}
    >
      {!showCancelFlow ? (
        <>
          <Box data-qatag="container" className={classes.container}>
            {subscriptionStatus === "Suspended" ? (
              <Box
                data-qatag="cancelPlanMobile"
                mt={4}
                mb={3}
                className={styles.cancelPlanMobile}
              >
                Your plan will be canceled, but is still available until the end
                of your billing period on{" "}
                <b data-qatag="boldTextResume">
                  {myPlanSummary?.BillingEndDate}.
                </b>
                <br data-qatag="line" />
                <br data-qatag="line" />
                <b data-qatag="boldTextResume">
                  If you change your mind, you can renew your subscription.
                </b>
              </Box>
            ) : (
              ""
            )}
            <Box
              data-qatag="logoContainer"
              width={1}
              className={classes.logoContainer}
            >
              <img
                data-qatag="titleIcon"
                className={styles.titleIcon}
                src={myPlanIcon}
                alt="my-plan-icon"
              />
              <Typography
                data-qatag="titleText"
                variant="h6"
                className={classes.titleText}
              >
                Current Subscription
              </Typography>
              {subscriptionStatus === "Suspended" ? (
                <div
                  data-qatag="canceledIndicator"
                  className={styles.canceledIndicator}
                >
                  Canceled
                </div>
              ) : (
                ""
              )}
            </Box>
            <Box
              data-qatag="featuresContainer"
              className={`${styles.featuresContainer} ${
                subscriptionStatus === "Suspended" ? styles.disabled : ""
              }`}
            >
              {/* Billing cycle */}
              <Box
                data-qatag="billingCycleContainer"
                className={styles.billingCycleContainer}
              >
                <Box data-qatag="billingCycle">
                  <Typography
                    variant="body2"
                    data-qatag="billingCycleTitle"
                    className={classes.titleText}
                  >
                    Billing cycle
                  </Typography>
                </Box>
                <Box
                  data-qatag="featureText"
                  className={styles.billingCycleTextContainer}
                >
                  <img
                    data-qatag="checkIcon"
                    src={checkIcon}
                    alt="check-icon"
                  />
                  <span
                    data-qatag="billingCycleText"
                    className={styles.checkMarkText}
                  >
                    Monthly
                  </span>
                </Box>
              </Box>
              {/* Plan type */}
              <Box
                data-qatag="planTypeContainer"
                className={styles.planTypeContainer}
              >
                <Box
                  data-qatag="planTypeTitle"
                  className={styles.planTypeTitle}
                >
                  <Typography
                    variant="body2"
                    data-qatag="billingCycleTitle"
                    className={classes.titleText}
                  >
                    Plan type
                  </Typography>
                </Box>
                <Box
                  data-qatag="planType"
                  className={styles.billingCycleTextContainer}
                >
                  <img
                    data-qatag="checkIcon"
                    src={checkIcon}
                    alt="check-icon"
                  />
                  <span
                    data-qatag="planTypeText"
                    className={styles.checkMarkText}
                  >
                    Team - $
                    {quoteType === quoteTypeEnum.Upgrade
                      ? quotePricePerSeat
                      : pricePerSeat}
                    /<b data-qatag="boldTypePlan"> Seats billed monthly</b>
                  </span>
                </Box>
              </Box>
              {/* Quantity */}
              <Box
                data-qatag="planTypeContainer"
                className={styles.planTypeContainer}
              >
                <Box data-qatag="billingCycle" className={styles.planTypeTitle}>
                  <Typography
                    variant="body2"
                    data-qatag="billingCycleTitle"
                    className={classes.titleText}
                  >
                    Quantity - ({seatsLength} Seats)
                  </Typography>
                </Box>
                <Box
                  data-qatag="planType"
                  className={styles.billingCycleTextContainer}
                >
                  Number of seats you currently have.
                </Box>
                <Box data-qatag="inputNumberContainer" mt={1.5}>
                  <NumberInput
                    data-qatag="inputNumber"
                    defaultValue={seatsLength}
                    onChange={setSeatsNumbers}
                    maxValue={99}
                    value={seatsNumbers}
                  />
                  {subscriptionStatus === "Suspended" ? (
                    <Box data-qatag="overlay" className={styles.overlay} />
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
              {/* Gray box text */}
              {seatsNumbers !== seatsLength ? (
                <Box
                  data-qatag="planTypeContainer"
                  className={styles.planTypeContainer}
                >
                  <Box
                    data-qatag="quantityInfoContainer"
                    className={styles.quantityInfoContainer}
                  >
                    {/* When the user increased the seats length */}
                    {quoteType === quoteTypeEnum.Upgrade && !isLoading ? (
                      <>
                        <b data-qatag="seatsNumbersBold">
                          {/* Original */}
                          {seatsToAdd} seat
                          {Math.abs(seatsNumbers - seatsLength) > 1 ? "s" : ""}
                        </b>{" "}
                        will be added to your plan immediately and you will be
                        charged an amount of{" "}
                        <b data-qatag="totalPayBold">${totalPerSeats}</b> today.
                      </>
                    ) : (
                      ""
                    )}

                    {/* When the user decreased the seats length */}
                    {quoteType === quoteTypeEnum.Downgrade && !isLoading ? (
                      <>
                        Your seat count{" "}
                        {seatsLength === seatsNumbers
                          ? seatsLength <= 1
                            ? "is"
                            : "are"
                          : "will be updated to"}
                        : <b data-qatag="seatsToRemove">{seatsNumbers}</b> at
                        the end of your current contract period.
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </Box>
            {/* Divider */}
            <Box
              data-qatag="divider"
              width="100%"
              mt={2}
              mb={3}
              className={styles.dividerTablet}
            >
              <Divider data-qatag="dividerItem" />
            </Box>
            {/* Billing Information */}
            <Box
              data-qatag="featuresContainer"
              className={styles.featuresContainer}
            >
              {subscriptionStatus !== "Suspended" ? (
                /* Billing Information */
                <>
                  <Box
                    data-qatag="billingSection"
                    className={styles.billingSection}
                  >
                    <Box data-qatag="billingInformationContainer">
                      <Typography
                        variant="body2"
                        data-qatag="billingInformationTitle"
                        className={classes.titleText}
                      >
                        Billing Information
                      </Typography>
                    </Box>
                    <Box
                      data-qatag="featureText"
                      className={styles.billingCycleTextContainer}
                    >
                      <img
                        data-qatag="creditCardIcon"
                        className={styles.creditCardIcon}
                        src={getIcon(cardType)}
                        alt="Visa"
                      />
                      <Box
                        data-qatag="cardTypeText"
                        className={styles.cardTypeText}
                      >
                        {cardType}
                      </Box>
                      <Box
                        data-qatag="lastDigitsText"
                        className={styles.lastDigitsText}
                      >
                        **** {creditCard}
                      </Box>
                      {/* Actions */}
                      <Box
                        data-qatag="editAction"
                        className={styles.editActionBillingCard}
                      >
                        <IconButton
                          data-qatag="iconButton"
                          aria-label="edit"
                          onClick={() => setShowEditPaymentModal(true)}
                        >
                          <Icon
                            data-qatag="editIcon"
                            className={`MuiSelect-icon MuiSvgIcon-root ${classes.editIcon}`}
                          >
                            <img
                              data-qatag="editIconSrc"
                              src={editIcon}
                              alt=""
                            />
                          </Icon>
                        </IconButton>
                        <span
                          data-qatag="editLabel"
                          className={styles.editLabel}
                          onClick={() => setShowEditPaymentModal(true)}
                        >
                          Edit
                        </span>
                      </Box>
                      {/* EditPaymentMethodModal */}
                      <EditPaymentMethodModal
                        data-qatag="EditPaymentMethodModal"
                        isOpen={showEditPaymentModal}
                        handleOnClose={() => setShowEditPaymentModal(false)}
                        paymentMethodId={myPlanSummary?.PaymentMethod?.Id}
                      />
                    </Box>
                  </Box>
                  <Box
                    data-qatag="billingSection"
                    className={styles.billingSection}
                  >
                    <Box
                      mt={2}
                      data-qatag="purchaseSummaryTitleContainer"
                      className={styles.purchaseSummaryTitleContainer}
                    >
                      <Typography
                        variant="body2"
                        data-qatag="purchaseSummaryTitle"
                        className={classes.titleText}
                      >
                        Purchase summary
                      </Typography>
                    </Box>
                    <Box
                      data-qatag="planType"
                      className={styles.purchaseSummaryText}
                    >
                      <span data-qatag="planTypeText">
                        {quoteType === quoteTypeEnum.Upgrade ? (
                          <b data-qatag="upgradePeriod">
                            Period: {quoteSummary?.BillingStartDate} -{" "}
                            {quoteSummary?.BillingEndDate}
                          </b>
                        ) : (
                          ""
                        )}
                      </span>
                      <Box
                        data-qatag="summaryPerSeat"
                        width={1}
                        mt={1}
                        display="flex"
                        justifyContent="space-between"
                      >
                        {`Seats x ${seatsToAdd}`}
                        <span
                          data-qatag="summaryTotalPerSeat"
                          className={styles.summaryTotalPerSeat}
                        >
                          $
                          {ReducerStatus[quoteStatus] ===
                            ReducerStatus.Succeeded &&
                          seatsNumbers !== seatsLength
                            ? quoteSummary?.ChargeAmount.toFixed(2)
                            : "0.00"}
                        </span>
                      </Box>
                      {quoteType === quoteTypeEnum.Upgrade &&
                      seatsNumbers !== seatsLength ? (
                        <>
                          {/* Divider */}
                          <Box data-qatag="divider" width="100%" mt={2}>
                            <Divider data-qatag="dividerItem" />
                          </Box>
                          <Box
                            data-qatag="summaryPerSeat"
                            width={1}
                            mt={1}
                            display="flex"
                            justifyContent="space-between"
                          >
                            Remaining credit from existing seats
                            <span
                              data-qatag="summaryTotalPerSeat"
                              className={styles.summaryTotalPerSeat}
                            >
                              {quoteSummary?.Credit > 0 ? "" : "-"}$
                              {Math.abs(quoteSummary?.ChargeCredit)}
                            </span>
                          </Box>{" "}
                        </>
                      ) : (
                        ""
                      )}
                      {/* Divider */}
                      <Box data-qatag="divider" width="100%" mt={2}>
                        <Divider data-qatag="dividerItem" />
                      </Box>
                      <Box
                        data-qatag="summaryPerSeat"
                        width={1}
                        mt={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box
                          data-qatag="iconTextTax"
                          display="flex"
                          alignItems="center"
                        >
                          <img
                            data-qatag="detailsIcon"
                            className={styles.detailsIcon}
                            src={detailsIcon}
                            alt=""
                          />
                          {quoteType === quoteTypeEnum.Upgrade
                            ? "Sales Tax"
                            : "Ext. Tax"}
                        </Box>
                        <span
                          data-qatag="summaryTotalTax"
                          className={styles.summaryTotalTax}
                        >
                          ${taxesPerSeats.toFixed(2)}
                        </span>
                      </Box>
                      {/* Divider */}
                      <Box data-qatag="divider" width="100%" mt={2}>
                        <Divider data-qatag="dividerItem" />
                      </Box>
                      <Box
                        data-qatag="summaryTotal"
                        width={1}
                        mt={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        Total
                        <span
                          data-qatag="summaryTotalPerSeat"
                          className={styles.summaryTotal}
                        >
                          <Typography
                            variant="body2"
                            data-qatag="purchaseSummaryTitle"
                            className={classes.titleText}
                          >
                            ${totalPerSeats.toFixed(2)}
                          </Typography>
                        </span>
                      </Box>
                      <Box data-qatag="billedMonthly" width={1} textAlign="end">
                        <span
                          data-qatag="billedMonthlyText"
                          className={styles.billedMonthlyText}
                        >
                          {quoteType === quoteTypeEnum.Upgrade
                            ? "Billed today"
                            : ""}
                        </span>
                      </Box>
                      <Box
                        data-qatag="summaryPerSeat"
                        width={1}
                        mt={1}
                        display="flex"
                        alignItems="center"
                      >
                        <img
                          data-qatag="detailsIcon"
                          className={styles.detailsIconBottom}
                          src={detailsIcon}
                          alt=""
                        />
                        <Box
                          data-qatag="messagePlanDetails"
                          className={styles.messagePlanDetails}
                        >
                          This plan will
                          <b
                            data-qatag="planBoldText"
                            className={styles.planBoldText}
                          >
                            auto-renew every month for $
                            {seatsNumbers !== seatsLength
                              ? quoteSummary?.RecurringCharge
                              : myPlanSummary?.RecurringCharge}
                            ,
                          </b>
                          unless cancelled before the next billing cycle. All
                          prices are in USD.
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                /* Resume Plan */
                <Box data-qatag="resumePlan" width={1}>
                  <span
                    data-qatag="cancelPlanTablet"
                    className={styles.cancelPlanTablet}
                  >
                    Your plan will be canceled, but is still available until the
                    end of your billing period on{" "}
                    <b data-qatag="boldTextResume">
                      {myPlanSummary?.BillingEndDate}.
                    </b>
                    <br data-qatag="line" />
                    <br data-qatag="line" />
                    <b data-qatag="boldTextResume">
                      If you change your mind, you can renew your subscription.
                    </b>
                  </span>
                  <Button
                    data-qatag="confirmButton"
                    buttonStyle={AppButtonStyles.Green}
                    className={styles.resumeButton}
                    onClick={() => setShowResumeFlow(true)}
                  >
                    <Typography
                      data-qatag="buttonLabel"
                      align="center"
                      className={classes.buttonText}
                      variant="body2"
                    >
                      Resume Plan
                    </Typography>
                  </Button>
                </Box>
              )}
            </Box>
            {subscriptionStatus === "Suspended" ? (
              ""
            ) : (
              <Box
                data-qatag="buttonContainer"
                mt={4}
                width={1}
                display="flex"
                flexWrap="wrap"
                justifyContent="space-evenly"
              >
                <Button
                  data-qatag="cancelButton"
                  buttonStyle={AppButtonStyles.TransparentWhite}
                  className={`${styles.cancelButton}`}
                  onClick={() => setCancelFlow(true)}
                  isDisabled={subscriptionStatus === "Suspended"}
                >
                  <Typography
                    data-qatag="buttonLabel"
                    align="center"
                    className={`${classes.buttonText} ${styles.cancelButtonText}`}
                    variant="body2"
                  >
                    Cancel Subscription
                  </Typography>
                </Button>
                <Button
                  data-qatag="confirmButton"
                  buttonStyle={AppButtonStyles.Green}
                  className={`${styles.customButton}`}
                  onClick={() => setShowAlertModal(true)}
                  isDisabled={seatsLength === seatsNumbers}
                >
                  <Typography
                    data-qatag="buttonLabel"
                    align="center"
                    className={classes.buttonText}
                    variant="body2"
                  >
                    Confirm
                  </Typography>
                </Button>
              </Box>
            )}
            {/* Loading modal */}
            <Spinner
              data-qatag="loadingModal"
              isActive={isLoading}
              text={
                ReducerStatus[resumeStatus] === ReducerStatus.Loading
                  ? "Resuming Plan..."
                  : "Loading..."
              }
            />
          </Box>
          {/* Alert to continue */}
          <Alert
            data-qatag="alertWarning"
            icon={AlertIcon.Success}
            title="Process order"
            text="Would you like to continue?"
            buttonText="Continue"
            approveHandler={() => {
              dispatch(updateSubscription(seatsNumbers));
              setShowAlertModal(false);
            }}
            isOpen={showAlertModal}
            closeHandler={() => setShowAlertModal(false)}
            cancelHandler={() => setShowAlertModal(false)}
            cancelButtonText="Cancel"
          />
          {/* Alert error */}
          <Alert
            data-qatag="alertError"
            icon={AlertIcon.Warning}
            title="Something went wrong"
            text={
              ReducerStatus[resumeStatus] === ReducerStatus.Failed
                ? "Error on the request"
                : "Please check if your subscription is not suspend"
            }
            buttonText="Continue"
            approveHandler={() => {
              dispatch(setUpdateSubscription());
              dispatch(setQuoteStatus());
              setSeatsNumbers(seatsLength);
            }}
            isOpen={
              ReducerStatus[requestStatusUpdateSeats] ===
                ReducerStatus.Failed ||
              ReducerStatus[quoteStatus] === ReducerStatus.Failed ||
              ReducerStatus[resumeStatus] === ReducerStatus.Failed
            }
            closeHandler={() => {
              dispatch(setUpdateSubscription());
              dispatch(setQuoteStatus());
              setSeatsNumbers(seatsLength);
            }}
          />
          {/* Success Alert */}
          <ReviewOrder
            data-qatag="reviewOrder"
            isOpen={showReviewModal}
            closeHandler={() => {
              setShowReviewModal(false);
              dispatch(fetchReceipt());
              dispatch(fetchMyPlan());
              // Reset state to requestStatusUpdateSeats to Idle
              dispatch(setUpdateSubscription());
            }}
            seatsAffected={
              ReducerStatus[quoteStatus] === ReducerStatus.Succeeded
                ? quoteSeatsLength - seatsLength
                : 0
            }
            seats={
              ReducerStatus[quoteStatus] === ReducerStatus.Succeeded
                ? quoteSeatsLength
                : seatsLength
            }
            tax={taxesPerSeats}
            unitPrice={pricePerSeat}
            total={totalPerSeats}
            chargeAmount={
              ReducerStatus[quoteStatus] === ReducerStatus.Succeeded &&
              seatsNumbers !== seatsLength
                ? quoteSummary?.ChargeAmount
                : 0
            }
            isCreditPending={
              quoteType === quoteTypeEnum.Upgrade &&
              seatsNumbers !== seatsLength
            }
            credit={quoteSummary?.ChargeCredit}
            recurringCharge={
              seatsNumbers !== seatsLength
                ? quoteSummary?.RecurringCharge
                : myPlanSummary?.RecurringCharge
            }
          />
          {/* Credit Cards modals for resume flow*/}
          <PaymentOnFile
            data-qatag="paymentOnFile"
            isOpen={showResumeFLow}
            approveHandler={() => dispatch(resumeSubscription())}
            closeHandler={() => setShowResumeFlow(false)}
            editHandler={() => {
              setShowResumeFlow(false);
              setTabNumber(1); // Go to the Payment methods
            }}
          />
          {/* Success resume Subscription  */}
          <Alert
            data-qatag="alertWarning"
            icon={AlertIcon.PaymentUpdated}
            title="Your payment has been updated!"
            text="Your payment has been successfully updated and can now be used for transaction."
            buttonText="Close"
            approveHandler={() => {
              dispatch(resetResume());
              dispatch(fetchMyPlan());
              dispatch(fetchSubscription());
              dispatch(fetchPaymentMethods());
            }}
            isOpen={ReducerStatus[resumeStatus] === ReducerStatus.Succeeded}
            closeHandler={() => {
              dispatch(resetResume());
              dispatch(fetchMyPlan());
              dispatch(fetchSubscription());
              dispatch(fetchPaymentMethods());
            }}
          />
        </>
      ) : (
        <CancelFlow
          data-qatag="cancelFlow"
          keepServiceCallback={() => setCancelFlow(false)}
          billingCycleDate={billingEndDate}
        />
      )}
    </TabPanel>
  );
};

export default MyPlanTab;
