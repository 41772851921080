import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IPaymentMethod } from "./types";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";
import {
  IPaymentMethodToAdd,
  IPaymentMethodToUpdate,
} from "../AddPaymentMethod/types";
import { getCardType } from "utils/getCardType";
import { selectCurrentUser } from "session/SessionSlice";

export const fetchPaymentMethods = createAsyncThunk<
  IPaymentMethod[],
  void,
  {
    state: RootState;
  }
>("payment/fetchPaymentMethods", async (_, thunkApi) => {
  try {
    const url = `/api/Billing/paymentmethod`;

    const apiResponse = await RESTGatewayAPI.get(url);

    return apiResponse?.data?.data;
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to fetch payment methods : ${error}`
    );
  }
});

export const submitPaymentMethod = createAsyncThunk<
  void,
  IPaymentMethodToAdd,
  {
    state: RootState;
  }
>("payment/submitPaymentMethods", async (values, thunkApi) => {
  try {
    const state = thunkApi.getState();

    // Current user email
    const user = selectCurrentUser(state);
    const email = user?.email;
    const phoneNumber = user?.phoneNumber;

    const url = `/api/Billing/paymentmethod`;

    const cardWithoutFormat = values.cardNumber.replace(/\s/g, "");

    const body = {
      creditCard: {
        cardNumber: cardWithoutFormat,
        cardExpirationMonth: parseInt(values?.monthExpiration?.toString()) + 1,
        cardExpirationYear: values?.yearExpiration,
        cardCVC: values?.securityCode,
        creditCardType: getCardType(cardWithoutFormat),
      },
      paymentInfo: {
        email: email,
        name: values?.name,
        phoneNumber: phoneNumber,
        zipCode: values?.zipCode,
        country: values?.country,
      },
    };

    const apiResponse = await RESTGatewayAPI.post(url, body);
    if (apiResponse?.status !== 200) {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to register payment method : ${error}`
    );
  }
});

export const deletePaymentMethod = createAsyncThunk<
  {
    error?: string;
  },
  string,
  {
    state: RootState;
  }
>("payment/deletePaymentMethods", async (paymentMethodId, thunkApi) => {
  try {
    const url = `/api/Billing/paymentmethod/${paymentMethodId}`;

    const apiResponse = await RESTGatewayAPI.delete(url);
    if (apiResponse?.status !== 200) {
      throw new Error("Something went wrong");
    }

    return {};
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to delete payment method : ${error}`
    );
  }
});

export const updatePaymentMethod = createAsyncThunk<
  void,
  IPaymentMethodToUpdate,
  {
    state: RootState;
  }
>("payment/updatePaymentMethods", async (values, thunkApi) => {
  try {
    // Registration Key
    const { currentRegKey } = thunkApi.getState().session.data;

    const url = `/api/Billing/paymentmethod`;

    const body = {
      registrationKey: currentRegKey,
      ...values,
    };

    const apiResponse = await RESTGatewayAPI.put(url, body);
    if (apiResponse?.status !== 200) {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    return thunkApi.rejectWithValue(
      `Unable to register payment method : ${error}`
    );
  }
});

const initialState: IReducerState<IPaymentMethod[]> = {
  data: [],
  status: {
    [fetchPaymentMethods.typePrefix]: ReducerStatus.Idle,
    [submitPaymentMethod.typePrefix]: ReducerStatus.Idle,
    [deletePaymentMethod.typePrefix]: ReducerStatus.Idle,
    [updatePaymentMethod.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const paymentMethodsSlice = createSlice({
  name: "paymentMethod",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<IPaymentMethod[]>) => {
      state.data = action.payload;
    },
    resetRequest: (state) => {
      state.status[fetchPaymentMethods.typePrefix] = ReducerStatus.Idle;
    },
    resetSubmit: (state) => {
      state.status[submitPaymentMethod.typePrefix] = ReducerStatus.Idle;
    },
    resetDelete: (state) => {
      state.status[deletePaymentMethod.typePrefix] = ReducerStatus.Idle;
      state.error = "";
    },
    resetUpdate: (state) => {
      state.status[updatePaymentMethod.typePrefix] = ReducerStatus.Idle;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentMethods.pending, (state) => {
      state.status[fetchPaymentMethods.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(fetchPaymentMethods.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status[fetchPaymentMethods.typePrefix] = ReducerStatus.Succeeded;
    });
    builder.addCase(fetchPaymentMethods.rejected, (state) => {
      state.status[fetchPaymentMethods.typePrefix] = ReducerStatus.Failed;
    });
    // Reducers to submit a payment method
    builder.addCase(submitPaymentMethod.pending, (state) => {
      state.status[submitPaymentMethod.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(submitPaymentMethod.fulfilled, (state) => {
      state.status[submitPaymentMethod.typePrefix] = ReducerStatus.Succeeded;
    });
    builder.addCase(submitPaymentMethod.rejected, (state) => {
      state.status[submitPaymentMethod.typePrefix] = ReducerStatus.Failed;
    });
    // Reducers to delete a Payment method
    builder.addCase(deletePaymentMethod.pending, (state) => {
      state.status[deletePaymentMethod.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(deletePaymentMethod.fulfilled, (state) => {
      state.status[deletePaymentMethod.typePrefix] = ReducerStatus.Succeeded;
    });
    builder.addCase(deletePaymentMethod.rejected, (state) => {
      state.status[deletePaymentMethod.typePrefix] = ReducerStatus.Failed;
    });
    // Reducers to update a payment method
    builder.addCase(updatePaymentMethod.pending, (state) => {
      state.status[updatePaymentMethod.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(updatePaymentMethod.fulfilled, (state) => {
      state.status[updatePaymentMethod.typePrefix] = ReducerStatus.Succeeded;
    });
    builder.addCase(updatePaymentMethod.rejected, (state) => {
      state.status[updatePaymentMethod.typePrefix] = ReducerStatus.Failed;
    });
  },
});

export const {
  set,
  resetRequest,
  resetSubmit,
  resetDelete,
  resetUpdate,
} = paymentMethodsSlice.actions;

export const getPaymentMethods = (state: RootState) =>
  state.paymentMethods.data;
export const getRequestStatus = (state: RootState) =>
  state.paymentMethods.status[fetchPaymentMethods.typePrefix];
export const getSubmitStatus = (state: RootState) =>
  state.paymentMethods.status[submitPaymentMethod.typePrefix];
export const getDeleteStatus = (state: RootState) =>
  state.paymentMethods.status[deletePaymentMethod.typePrefix];
export const getUpdateStatus = (state: RootState) =>
  state.paymentMethods.status[updatePaymentMethod.typePrefix];

export default paymentMethodsSlice.reducer;
