import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import installIcon from "assets/img/driver/icn-driver-install.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Spinner } from "../Spinner";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    modalRoot: {
      width: "419px",
      height: "321px",
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "330px",
        height: "321px",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(3),
      },
    },
    actionButton: {
      width: "255px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    description_container: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  })
);
//#endregion Component Styles

//#region Component
export const InstallInProgress: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      data-qatag="driver.modal.install.in_progress"
      id="driver.modal.install.in_progress"
      className={classes.modalRoot}
    >
      <img
        data-qatag="driver.modal.install.in_progress.icon"
        id="driver.modal.install.in_progress.icon"
        src={installIcon}
        alt=""
      />

      <FormattedMessage
        data-qatag="driver.modal.install.in_progress.title"
        id="driver.modal.install.in_progress.title"
        defaultMessage="Installing your driver install..."
      >
        {(text) => (
          <Typography
            data-qatag="driver.modal.install.in_progress.title.text"
            id="driver.modal.install.in_progress.title.text"
            variant="h5"
            color="textPrimary"
            align="center"
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <Box
        data-qatag="driver.modal.install.in_progress.description_container"
        id="driver.modal.install.in_progress.description_container"
        className={classes.description_container}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.in_progress.description"
          id="driver.modal.install.in_progress.description"
          defaultMessage="The driver installation may take serveral {br} minutes to complete. Please wait..."
          values={{
            br: (
              <br
                data-qatag="driver.modal.install.in_progress.break"
                id="driver.modal.install.in_progress.break"
              />
            ),
          }}
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.in_progress.description.text"
              id="driver.modal.install.in_progress.description.text"
              variant="body2"
              color="primary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
      <Spinner data-qatag="driver.modal.install.in_progress.spinner" />
    </Box>
  );
};
//#endregion Component
