import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import styles from "./TheWhat.module.css";
import { Spinner } from "solveiq.designsystem";
import { ReducerStatus } from "model/IReducerState";
import { RootState } from "app/store";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import serviceIcon from "assets/img/cancelFlow/icn-FPO.svg";
import {
  getFetchStatus,
  getServicesSummary,
  fetchServicesSummary,
} from "../ServiceSummarySlice";
import { IServicesSummary } from "./types";
import { humanFileSize } from "utils/formatFileSize";

export interface ITheWhat {
  serviceSummarySelector?: (state: RootState) => IServicesSummary;
  fetchServiceSummary?: (state: RootState) => ReducerStatus;
  fetchStatusSelector?: (state: RootState) => ReducerStatus;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      color: theme.palette.secondary.light,
      fontWeight: 600,
    },
    buttonText: {
      lineHeight: 1,
    },
  })
);

const TheWhat: React.FC<ITheWhat> = ({
  serviceSummarySelector = (state: RootState) => getServicesSummary(state),
  fetchStatusSelector = (state: RootState) => getFetchStatus(state),
}) => {
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const dispatch = useDispatch();

  // Dispatch action to retrieve data from the endpoint
  useEffect(() => {
    dispatch(fetchServicesSummary());
  }, [dispatch]);

  /* Services Summary fetch status */
  // const requestStatus = useSelector(fetchStatusSelector);
  /* Services Summary */
  const servicesSummary = useSelector(serviceSummarySelector);

  const serviceSummary = [
    {
      service: "Device Updates",
      value: servicesSummary?.devicesUpdated ?? "Loading...",
    },
    {
      service: "Support Tickets",
      value: servicesSummary?.supportTickets ?? "Loading...",
    },
    {
      service: "DNS Protection",
      value: servicesSummary?.dnsProtection ?? "Loading...",
    },
    {
      service: "Deceptive App Blocks",
      value: servicesSummary?.deceptiveAppsBlocked ?? "Loading...",
    },
    {
      service: "Hard Drive Space Freed",
      value:
        servicesSummary?.hardDriveSpaceFreed !== undefined
          ? humanFileSize(servicesSummary?.hardDriveSpaceFreed)
          : "Loading...",
    },
    {
      service: "Apps Optimized",
      value: servicesSummary?.appsOptimized ?? "Loading...",
    },
    {
      service: "Memory Freed",
      value:
        servicesSummary?.memoryFreed !== undefined
          ? humanFileSize(servicesSummary?.memoryFreed)
          : "Loading...",
    },
    {
      service: "Ads / Trackers Blocked",
      value: servicesSummary?.adsBlocked ?? "Loading...",
    },
  ];

  return (
    <>
      {/* Covered Team Members */}

      <Box
        data-qatag="featuresContainer"
        mt={4}
        className={styles.coveredTeamMembers}
        ml="auto"
        mr="auto"
      >
        <Box
          data-qatag="logoContainer"
          width={1}
          textAlign="center"
          display="flex"
          justifyContent="center"
        >
          <Typography
            data-qatag="titleText"
            variant="h4"
            className={`${classes.titleText} ${styles.coverMembersText}`}
          >
            Service Summary
          </Typography>
        </Box>
        <Box data-qatag="logoContainer" width={1} mt={1.5} textAlign="center">
          <Typography data-qatag="titleText" variant="body2">
            Here is a snapshot of benefits your team has received over in the
            last 30 days.
          </Typography>
        </Box>
        <Box
          data-qatag="coverMembers"
          width={1}
          className={styles.serviceBoxContainer}
        >
          {/* Render users */}
          {serviceSummary?.map((service) => (
            <Box
              data-qatag="userBox"
              key={service.service}
              className={styles.serviceBox}
            >
              <Box data-qatag="image" width={1} textAlign="center">
                <img data-qatag="userImage" src={serviceIcon} alt="" />
              </Box>
              <Box
                data-qatag="image"
                width={1}
                textAlign="center"
                mt={1}
                className={classes.titleText}
              >
                <Typography data-qatag="serviceName" variant="h3">
                  {service.value}
                </Typography>
              </Box>
              {/* Computer Device */}
              <Box
                data-qatag="logoContainer"
                width={1}
                mt={2}
                textAlign="center"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  data-qatag="titleText"
                  variant="body2"
                  className={styles.coverMembersText}
                >
                  {service.service}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Loading modal */}
      <Spinner data-qatag="loadingModal" isActive={false} text="Loading..." />
    </>
  );
};

export default TheWhat;
