import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, Box } from "@material-ui/core";
import { AppButton, AppButtonStyles, Spinner } from "solveiq.designsystem";
import { AlertWarning, AlertError } from "features/customAlerts/AddTeamMember";
import deviceImage from "assets/img/monitorIcon.svg";
import goodStatusImage from "assets/img/greenCheckTransparentBackground.png";
// import badStatusImage from "assets/img/greenCheckTransparentBackground.png";
import deleteIcon from "assets/icon/icn-remove-user.svg";
import adminIcon from "assets/icon/icn-admin.svg";
import pendingIcon from "assets/img/team/status-pending-w-resend-stamp.svg";
import resendEmailIcon from "assets/img/team/status-resend-email.svg";
import styles from "./TeamMemberTableRow.module.css";
import {
  deleteUsers,
  statusDeleteUser,
  errorDeleteUser,
  dataDeleteUser,
  setDeleteStatus,
  // Resend invite request
  getResendStatus,
  resendInvite,
  resetResendInvite,
} from "./TeamSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { ReducerStatus } from "model/IReducerState";
import IUser from "model/user/IUser";
import { selectCurrentUser } from "session/SessionSlice";
import { getDateAndTime } from "utils/formatDates";

export interface ITeamMemberTableRowProps {
  name: string;
  email: string;
  licenseStatus: string;
  licenseDate: string;
  licenseIsGood: boolean;
  device: string;
  isOnline?: boolean;
  image: string;
  enabled: boolean;
  attributes: string;
  onDetailsClicked: () => void;
  userSelector?: (state: RootState) => IUser;
}

export const TeamMemberTableRow: React.FC<ITeamMemberTableRowProps> = ({
  name,
  email,
  licenseStatus,
  licenseDate,
  licenseIsGood,
  device,
  isOnline,
  image,
  onDetailsClicked,
  attributes,
  userSelector = selectCurrentUser,
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "team.member.table.row";
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const dispatch = useDispatch();

  // Retrieve session user
  const user = useSelector(userSelector);

  /* Request Status */
  const deleteStatus = useSelector(statusDeleteUser);
  /* If we have a custom error on deleting the user */
  const deleteError = useSelector(errorDeleteUser);
  /* Current user deleting for the useEffect */
  const deleteEmail = useSelector(dataDeleteUser);

  // Resend invite status
  const resendInviteStatus = useSelector(getResendStatus);

  useEffect(() => {
    if (deleteStatus !== ReducerStatus[ReducerStatus.Idle]) {
      setOpenWarningModal(false);
    }
  }, [deleteError, deleteStatus, formatMessage, deleteEmail]);

  /* method to delete users */
  const handleDelete = async ({ email }: { email: string }) => {
    dispatch(
      deleteUsers({
        email: email,
      })
    );
  };

  return (
    <>
      <tr data-qatag="teamMemberTableRow" className={styles.teamMemberTableRow}>
        <td
          data-qatag="teamMemberTableRowInfo"
          className={styles.teamMemberTableRowInfo}
        >
          <div
            data-qatag="teamMemberTableRowCellContent"
            className={styles.teamMemberTableRowCellContent}
          >
            <div
              data-qatag="teamMemberTableRowInfoImageContainer"
              className={styles.teamMemberTableRowInfoImageContainer}
            >
              <img
                data-qatag="teamMemberTableRowInfoImage"
                className={styles.teamMemberTableRowInfoImage}
                src={image}
                alt=""
              />
              <div
                data-qatag="teamMemberTableRowOnlineIndicator"
                className={`${styles.teamMemberTableRowPendingIndicator} ${
                  isOnline === true
                    ? styles.teamMemberTableRowOnlineIndicator
                    : ""
                } ${
                  isOnline === false
                    ? styles.teamMemberTableRowOfflineIndicator
                    : ""
                }`}
              />
            </div>
            <div data-qatag="teamMemberTableRowNameContainer">
              {name}
              <Typography
                data-qatag="teamMemberTableRowSubText"
                variant="body1"
                className={styles.teamMemberTableRowSubText}
              >
                {isOnline === true
                  ? formatMessage({
                      id: `${prefix}.online`,
                    })
                  : ""}
                {isOnline === false
                  ? formatMessage({
                      id: `${prefix}.offline`,
                    })
                  : ""}
                {isOnline === undefined ? "Pending" : ""}
              </Typography>
            </div>
          </div>
        </td>
        <td
          data-qatag="teamMemberTableRowDevice"
          className={styles.teamMemberTableRowDevice}
        >
          <div
            data-qatag="teamMemberTableRowCellContent"
            className={styles.teamMemberTableRowCellContent}
          >
            <img
              data-qatag="teamMemberTableRowDeviceImage"
              className={`${styles.teamMemberTableRowDeviceImage} ${
                licenseStatus !== "Activated" ? styles.pendingIcon : ""
              }`}
              src={licenseStatus === "Activated" ? deviceImage : pendingIcon}
              alt="device or pending"
            />
            <div
              data-qatag="teamMemberTableRowDeviceText"
              className={styles.teamMemberTableRowDeviceText}
            >
              {licenseStatus === "Activated" ? device : "Pending..."}
            </div>
          </div>
        </td>
        <td
          data-qatag="teamMemberTableRowStatus"
          className={styles.teamMemberTableRowStatus}
        >
          <div
            data-qatag="teamMemberTableRowCellContent"
            className={styles.statusCell}
          >
            {/* Status */}
            <div
              data-qatag="teamMemberTableRowStatusContainer"
              className={styles.teamMemberTableRowStatusContainer}
            >
              <Box
                data-qatag="teamMemberTableRowstatusImageBox"
                display="flex"
                alignContent="center"
              >
                <img
                  data-qatag="teamMemberTableRowstatusImage"
                  className={styles.teamMemberTableRowstatusImage}
                  src={
                    licenseStatus === "Activated"
                      ? goodStatusImage
                      : pendingIcon
                  }
                  alt=""
                />
                {licenseStatus === "Activated" ? "Active" : "Pending..."}
              </Box>
              <Typography
                data-qatag="teamMemberTableRowSubText"
                variant="body1"
                className={styles.teamMemberTableRowSubText}
              >
                {getDateAndTime(licenseDate)}
              </Typography>
              <span
                data-qatag="teamMemberTableRowSubText"
                className={styles.teamMemberTableRowSubText}
              ></span>
            </div>
            {/* Resend invite email */}

            {licenseStatus !== "Activated" ? (
              <div
                data-qatag="resendCell"
                className={styles.resendCell}
                onClick={() => {
                  dispatch(
                    resendInvite({
                      email: email,
                    })
                  );
                }}
              >
                {/* TODO refactor Callback to send email */}
                <Box
                  data-qatag="teamMemberTableRowstatusImageBox"
                  display="flex"
                  alignContent="center"
                  justifyContent="center"
                >
                  <img
                    data-qatag="teamMemberTableRowstatusImage"
                    src={resendEmailIcon}
                    alt=""
                  />
                </Box>
                <Typography
                  data-qatag="teamMemberTableRowSubText"
                  variant="body1"
                  className={styles.teamMemberTableRowSubText}
                >
                  Resend
                </Typography>
                <span
                  data-qatag="teamMemberTableRowSubText"
                  className={styles.teamMemberTableRowSubText}
                ></span>
              </div>
            ) : (
              ""
            )}
          </div>
        </td>
        <td
          data-qatag="teamMemberTableRowDelete"
          className={styles.teamMemberTableRowDelete}
        >
          <div
            data-qatag="teamMemberTableRowCellContent"
            className={styles.teamMemberTableRowCellContent}
          >
            {attributes?.toLowerCase()?.includes("admin") ? (
              <button
                data-qatag="teamMemberTableRowAdminButton"
                className={styles.teamMemberTableRowAdminButton}
              >
                <img
                  data-qatag="teamMemberTableRowAdminImage"
                  src={adminIcon}
                  alt=""
                />
                <Typography
                  data-qatag="teamMemberTableRowSubText"
                  variant="body1"
                  className={styles.teamMemberTableRowSubText}
                >
                  Admin
                </Typography>
              </button>
            ) : (
              <button
                data-qatag="teamMemberTableRowDeleteButton"
                className={styles.teamMemberTableRowDeleteButton}
                onClick={() => {
                  setOpenWarningModal((prevState) => !prevState);
                }}
              >
                <img
                  data-qatag="teamMemberTableRowDeleteImage"
                  src={deleteIcon}
                  alt=""
                />
                <Typography
                  data-qatag="teamMemberTableRowSubText"
                  variant="body1"
                  className={`${styles.teamMemberTableRowSubText} ${styles.removeMessage}`}
                >
                  Remove
                </Typography>
              </button>
            )}
          </div>
        </td>
        <td
          data-qatag="teamMemberTableRowAction"
          className={styles.teamMemberTableRowAction}
        >
          {user?.email !== email ? (
            <AppButton
              data-qatag="teamMemberTableRowDetailsButton"
              buttonStyle={
                licenseStatus !== "Activated"
                  ? AppButtonStyles.GrayWithoutBorder
                  : AppButtonStyles.Green
              }
              className={`${styles.teamMemberTableRowDetailsButton} ${
                licenseStatus !== "Activated" && !isOnline
                  ? styles.disabledButton
                  : ""
              }`}
              onClick={onDetailsClicked}
              isDisabled={licenseStatus !== "Activated" && !isOnline}
            >
              <Typography
                data-qatag="teamMemberTableRowDetailsTypography"
                variant="body2"
              >
                Connect
              </Typography>
            </AppButton>
          ) : (
            ""
          )}
        </td>
      </tr>
      <AlertError
        data-qatag="alertError"
        isOpen={
          deleteStatus === ReducerStatus[ReducerStatus.Failed] ||
          resendInviteStatus === ReducerStatus[ReducerStatus.Failed]
        }
        closeHandler={() => {
          // Reset delete status
          if (deleteStatus === ReducerStatus[ReducerStatus.Failed]) {
            dispatch(setDeleteStatus());
          }
          if (resendInviteStatus === ReducerStatus[ReducerStatus.Failed]) {
            dispatch(resetResendInvite());
          }
        }}
        text={
          deleteError
            ? deleteError
            : formatMessage({
                id: `${prefix}.unable.delete`,
              })
        }
      />
      <AlertWarning
        data-qatag="alertWarning"
        approveHandler={() => {
          handleDelete({ email });
        }}
        isOpen={openWarningModal}
        name={name}
        closeHandler={() => {
          setOpenWarningModal((prevState) => !prevState);
        }}
      />
    </>
  );
};
