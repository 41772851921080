export function getCardType(cardNumber: string): string {
  // Amex Card
  if (/^3[47][0-9]{13}$/.test(cardNumber)) return "Amex";

  // Discover Card
  if (
    /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/.test(
      cardNumber
    )
  ) {
    return "Discover";
  }

  // Mastercard
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      cardNumber
    )
  ) {
    return "MasterCard";
  }
  // Visa Card
  if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cardNumber)) {
    return "Visa";
  }
  // Visa Master Card
  if (/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/.test(cardNumber)) {
    return "Visa";
  }
  return "";
}
