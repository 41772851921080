import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import { defineMessages, FormattedMessage } from "react-intl";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    entry_root: {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
      },
    },
    entry_title: {
      fontWeight: 600,
      marginRight: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    entry_image: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(3),
    },
    entry_content: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  })
);
//#endregion Component Styles

//#region Component Specific Messages
const messages = defineMessages({
  employee: {
    id: "vital_employee",
    description: "entry title - employee",
    defaultMessage: "Employee:",
  },
  device: {
    id: "vital_device",
    description: "entry title - device",
    defaultMessage: "Device:",
  },
  os: {
    id: "vital_operating_system",
    description: "entry title - os",
    defaultMessage: "Operating System:",
  },
  location: {
    id: "vital_location",
    description: "entry title - location",
    defaultMessage: "Location:",
  },
});
//#endregion Component Specific Messages

//#region Component Props
interface IVitalEntryProps {
  image: string;
  titleId: "employee" | "device" | "os" | "location";
  content: string;
}
//#endregion Component Props

//#region Component
export const VitalsEntry: React.FC<IVitalEntryProps> = (props) => {
  const descriptor = messages[props.titleId];
  const classes = useStyles();

  return (
    <Box
      data-qatag="vitalsEntry.root"
      id="vitalsEntry.root"
      className={classes.entry_root}
    >
      <img
        data-qatag="vitalsEntry.image"
        id="vitalsEntry.image"
        src={props.image}
        alt=""
        className={classes.entry_image}
      />
      <FormattedMessage data-qatag="vitalsEntry.title" {...descriptor}>
        {(text) => (
          <Typography
            data-qatag="vitalsEntry.title.text"
            id="vitalsEntry.title.text"
            variant="body2"
            color="textPrimary"
            className={classes.entry_title}
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <Typography
        data-qatag="vitalsEntry.content.text"
        id="vitalsEntry.content.text"
        variant="body2"
        color="textPrimary"
        className={classes.entry_content}
      >
        {props.content}
      </Typography>
    </Box>
  );
};
//#endregion Component
