import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IBillingSummary } from ".";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";

export const fetchMyPlan = createAsyncThunk<
  IBillingSummary,
  void,
  {
    state: RootState;
  }
>("myPlan/fetchMyPlan", async (_, thunkApi) => {
  try {
    const url = `/api/Billing/summary`;

    const apiResponse = await RESTGatewayAPI.get(url);

    return apiResponse?.data?.data;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch plan summary : ${error}`);
  }
});

const initialState: IReducerState<IBillingSummary> = {
  data: {
    // default price per seat
    UnitPrice: 0,
    SeatCount: 0,
    Subtotal: 0,
    Tax: 0,
    Total: 0,
    RecurringCharge: 0,
    BillingStartDate: "",
    BillingEndDate: "",
    PaymentMethod: {
      CardType: "",
      CardNumber: "",
      Id: "",
    },
  },
  status: {
    [fetchMyPlan.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const myPlanSlice = createSlice({
  name: "receipt",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<IBillingSummary>) => {
      state.data = action.payload;
    },
    setMyPlanStatus: (state) => {
      state.status[fetchMyPlan.typePrefix] = ReducerStatus.Idle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMyPlan.pending, (state) => {
      state.status[fetchMyPlan.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(fetchMyPlan.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status[fetchMyPlan.typePrefix] = ReducerStatus.Succeeded;
    });
    builder.addCase(fetchMyPlan.rejected, (state) => {
      state.status[fetchMyPlan.typePrefix] = ReducerStatus.Failed;
    });
  },
});

export const { set, setMyPlanStatus } = myPlanSlice.actions;

export const selectMyPlan = (state: RootState) => state.myPlanSummary.data;
export const selectMyPlanStatus = (state: RootState) =>
  state.myPlanSummary.status[fetchMyPlan.typePrefix];

export default myPlanSlice.reducer;
