import {
  Box,
  Divider,
  makeStyles,
  Paper,
  createStyles,
  Avatar,
  Badge,
  Typography,
} from "@material-ui/core";
import { useAppDispatch } from "app/store";
import { Loading } from "main/Loading";
import { ReducerStatus } from "model/IReducerState";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { dataFetchStatus, employeeDetailSelected } from "./EmployeeDetailSlice";
import defaultImage from "assets/img/team/avatar-default.svg";
import deviceIcon from "assets/img/status/deviceIcon.svg";
import locationIcon from "assets/img/status/ispIcon.svg";
import memberIcon from "assets/img/status/internetSpeedIcon.svg";
import osIcon from "assets/img/status/operatingSystemIcon.svg";
import ticketsIcon from "assets/icon/icn-tickets.svg";
import tasksIcon from "assets/icon/icn-tasks.svg";
import { VitalsEntry } from "./VitalsEntry";
import { FormattedMessage } from "react-intl";
import {
  selectOutOfDateDriversCount,
  selectScan,
} from "features/scan/ScanSlice";
import { selectCurrentUser, selectUserDescriptor } from "session/SessionSlice";
import { selectLastStartTime } from "app/Agent/AgentSlice";
import { RouterBreadcrumbs } from "./RouterBreadcrumbs";
import { formatLastScanTime } from "core/Helpers";
import { EmployeeDetailStatusPane } from "./EmployeeDetailStatusPane";
import {
  fetchTickets,
  selectAllTickets,
  selectOpenTicketCount,
  selectResolvedTicketCount,
} from "features/tickets/TicketsSlice";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    summary_root: {
      display: "flex",
      borderRadius: "14px 14px 0 0",
      alignSelf: "center",
      marginTop: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    inset_container: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      width: "100%",
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    vitals_root: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    avatar_root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //padding: theme.spacing(10),
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(1),
      },
    },
    divider: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    mobile_divider: {
      display: "none",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    work_title: {
      fontWeight: 600,
    },
    work_content: {},
    work_text_container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(5),
      },
    },
    work_container: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    work_root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(3),
      },
    },
    services_root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flexGrow: 1,
      borderRadius: 0,
      [theme.breakpoints.down("sm")]: {
        alignItems: "stretch",
      },
    },
    status_pane_container: { marginBottom: theme.spacing(4) },
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    lastScanTime: {
      marginBottom: theme.spacing(3),
    },
  })
);
//#endregion Component Styles

//#region Component
export const EmployeeDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();
  const status = useSelector(dataFetchStatus);

  const scan = useSelector(selectScan);
  const user = useSelector(selectCurrentUser);
  const lastScanTime = useSelector(selectLastStartTime);
  const userDescriptor = useSelector(selectUserDescriptor);
  const resolvedTicketCount = useSelector(selectResolvedTicketCount);
  const openTicketCount = useSelector(selectOpenTicketCount);
  const outOfDateDriverCount = useSelector(selectOutOfDateDriversCount);

  const classes = useStyles();

  useEffect(() => {
    dispatch(employeeDetailSelected(+id));
    dispatch(fetchTickets());
  }, [dispatch, id]);

  if (status === ReducerStatus.Loading) {
    return <Loading data-qatag="sf" />;
  }

  return (
    <Box
      data-qatag="employeeDetail.root"
      id="employeeDetail.root"
      className={classes.root}
    >
      <Box
        data-qatag="employeeDetail.inset_container"
        id="employeeDetail.inset_container"
        className={classes.inset_container}
      >
        <RouterBreadcrumbs
          data-qatag="sdf"
          style={{ width: "inherit", height: "42px", alignSelf: "center" }}
          descriptors={[
            {
              to: "/",
              label: userDescriptor,
            },
          ]}
        />
        <Paper
          data-qatag="employeeDetail.summary.root"
          id="employeeDetail.summary.root"
          className={classes.summary_root}
          elevation={0}
        >
          <Box
            data-qatag="employeeDetail.summary.avatar_container"
            id="employeeDetail.summary.avatar_container"
            className={classes.avatar_root}
          >
            <Avatar
              data-qatag="employeeDetail.summary.avatar"
              id="employeeDetail.summary.avatar"
              style={{ height: "75px", width: "75px" }}
            >
              <img
                data-qatag="employeeDetail.summary.avatar.image"
                id="employeeDetail.summary.avatar.image"
                src={defaultImage}
                alt="avatar"
                style={{ height: "75px", width: "75px" }}
              />
            </Avatar>
          </Box>
          <Divider
            data-qatag="employeeDetail.summary.divider1"
            id="employeeDetail.summary.divider1"
            orientation="vertical"
            flexItem
            className={classes.divider}
          />
          <Box
            data-qatag="employeeDetail.summary.vitals"
            id="employeeDetail.summary.vitals"
            className={classes.vitals_root}
          >
            <VitalsEntry
              data-qatag="ff"
              image={memberIcon}
              titleId="employee"
              content={`${user?.firstName} ${user?.lastName}`}
            />
            <VitalsEntry
              data-qatag="ff"
              image={deviceIcon}
              titleId="device"
              content={`${user?.machineDisplayName}`}
            />
            <VitalsEntry
              data-qatag="ff"
              image={osIcon}
              titleId="os"
              content={scan ? scan.operatingSystem : "Windows"}
            />
            {/* <VitalsEntry
            data-qatag="ff"
            image={locationIcon}
            titleId="location"
            content={user.location}
          /> */}
          </Box>
          <Divider
            data-qatag="employeeDetail.summary.divider1"
            id="employeeDetail.summary.divider1"
            orientation="vertical"
            flexItem
            className={classes.divider}
          />
          <Box
            data-qatag="employeeDetail.summary.work_root"
            id="employeeDetail.summary.work_root"
            className={classes.work_root}
          >
            <Divider
              data-qatag="employeeDetail.summary.mobile.divider1"
              id="employeeDetail.summary.mobile.divider1"
              className={classes.mobile_divider}
            />
            <Box
              data-qatag="employeeDetail.summary.tickets_container"
              id="employeeDetail.summary.tickets_container"
              className={classes.work_container}
            >
              <img
                data-qatag="employeeDetail.summary.tickets.image"
                id="employeeDetail.summary.tickets.image"
                src={ticketsIcon}
                alt="tickets"
              />
              <Box
                data-qatag="employeeDetail.summary.tickets.text_container"
                id="employeeDetail.summary.tickets.text_container"
                className={classes.work_text_container}
              >
                <FormattedMessage
                  data-qatag="employeeDetail.summary.tickets.title"
                  id="employeeDetail.summary.tickets.title"
                  defaultMessage="Tickets"
                >
                  {(text) => (
                    <Typography
                      data-qatag="employeeDetail.summary.tickets.title.text"
                      id="employeeDetail.summary.tickets.title.text"
                      variant="body2"
                      color="textPrimary"
                      className={classes.work_title}
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
                <FormattedMessage
                  data-qatag="employeeDetail.summary.tickets.open"
                  id="employeeDetail.summary.tickets.open"
                  defaultMessage={`{open} Open {open, plural,
                    one {Ticket}
                    other {Tickets}}`}
                  values={{
                    open: openTicketCount,
                  }}
                >
                  {(text) => (
                    <Typography
                      data-qatag="employeeDetail.summary.tickets.open.text"
                      id="employeeDetail.summary.tickets.open.text"
                      variant="body1"
                      color="primary"
                      className={classes.work_content}
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
                <FormattedMessage
                  data-qatag="employeeDetail.summary.tickets.resolved"
                  id="employeeDetail.summary.tickets.resolved"
                  defaultMessage={`{resolved} Resolved {resolved, plural,
                    one {Ticket}
                    other {Tickets}}`}
                  values={{
                    resolved: resolvedTicketCount,
                  }}
                >
                  {(text) => (
                    <Typography
                      data-qatag="employeeDetail.summary.tickets.resolved.text"
                      id="employeeDetail.summary.tickets.resolved.text"
                      variant="body1"
                      color="primary"
                      className={classes.work_content}
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
              </Box>
            </Box>
            <Divider
              data-qatag="employeeDetail.summary.mobile.divider2"
              id="employeeDetail.summary.mobile.divider2"
              className={classes.mobile_divider}
            />
            <Box
              data-qatag="employeeDetail.summary.tasks_container"
              id="employeeDetail.summary.tasks_container"
              className={classes.work_container}
            >
              <img
                data-qatag="employeeDetail.summary.tasks.image"
                id="employeeDetail.summary.tasks.image"
                src={tasksIcon}
                alt="tasks"
              />
              <Box
                data-qatag="employeeDetail.summary.tasks.text_container"
                id="employeeDetail.summary.tasks.text_container"
                className={classes.work_text_container}
              >
                <FormattedMessage
                  data-qatag="employeeDetail.summary.tasks.title"
                  id="employeeDetail.summary.tasks.title"
                  defaultMessage="Tasks"
                >
                  {(text) => (
                    <Typography
                      data-qatag="employeeDetail.summary.tasks.title.text"
                      id="employeeDetail.summary.tasks.title.text"
                      variant="body2"
                      color="textPrimary"
                      className={classes.work_title}
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
                <FormattedMessage
                  data-qatag="employeeDetail.summary.tasks.need_attention"
                  id="employeeDetail.summary.tasks.need_attention"
                  defaultMessage={`{needs_attention} {needs_attention, plural,
                    one {item that needs attention}
                    other {items that need attention}}`}
                  values={{
                    needs_attention: outOfDateDriverCount,
                  }}
                >
                  {(text) => (
                    <Typography
                      data-qatag="employeeDetail.summary.tasks.need_attention.text"
                      id="employeeDetail.summary.tasks.need_attention.text"
                      variant="body1"
                      color="primary"
                      className={classes.work_content}
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
              </Box>
            </Box>
            <Divider
              data-qatag="employeeDetail.summary.mobile.divider3"
              id="employeeDetail.summary.mobile.divider3"
              className={classes.mobile_divider}
            />
          </Box>
        </Paper>
      </Box>
      <Paper
        data-qatag="employeeDetail.services.root"
        id="employeeDetail.services.root"
        elevation={0}
        className={classes.services_root}
      >
        <Box
          data-qatag="employeeDetail.services.status_pane_container"
          id="employeeDetail.services.status_pane_container"
          className={classes.status_pane_container}
        >
          {/* <StatusPane data-qatag="sdf" selector="asdf" /> */}
          <EmployeeDetailStatusPane data-qatag="sfd" />
        </Box>

        <Box
          data-qatag="employeeDetail.lastScanTime.container"
          id="employeeDetail.lastScanTime.container"
          className={classes.lastScanTime}
        >
          <FormattedMessage
            data-qatag="employeeDetail.lastScanTime"
            id="employeeDetail.lastScanTime"
            defaultMessage="Last Scan: {lastScanTime}"
            values={{
              lastScanTime: formatLastScanTime(lastScanTime),
            }}
          >
            {(text) => (
              <Typography
                data-qatag="employeeDetail.lastScanTime.text"
                id="employeeDetail.lastScanTime.text"
                variant="body2"
                color="textPrimary"
                align="center"
                className={classes.work_title}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </Box>
      </Paper>
    </Box>
  );
};
//#endregion Component
