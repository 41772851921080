import { Box } from "@material-ui/core";
import NoAgentIllustration from "assets/img/agent/illustration-notactive.svg";
import styles from "./NoAgentAvailable.module.css";

export interface INoAgentAvailable {
  /* Error Message */
  title?: string;
  /* Suggestion for the error */
  suggestion?: string;
}

const NoAgentAvailable: React.FC<INoAgentAvailable> = ({
  title = "The Solve iQ service is not present or active on the device you are logged in from.",
  suggestion = "You many only view last known System Status and request help.",
}) => {
  return (
    <div data-qatag="container" className={styles.container}>
      <Box data-qatag="imageContainer" mt={1} textAlign="center">
        <img
          data-qatag="icon"
          className={styles.icon}
          src={NoAgentIllustration}
          alt=""
        />
      </Box>
      <Box
        data-qatag="errorContainer"
        mt={3}
        className={styles.title}
        textAlign="center"
        maxWidth="90%"
        marginLeft="auto"
        marginRight="auto"
      >
        {title}
      </Box>
      <Box
        data-qatag="suggestion"
        mt={0.5}
        textAlign="center"
        className={styles.suggestion}
        maxWidth="90%"
        marginLeft="auto"
        marginRight="auto"
      >
        {suggestion}
      </Box>
    </div>
  );
};

export default NoAgentAvailable;
