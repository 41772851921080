import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";

export const openPayPalTab = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>("checkout/PayPal", async (_, thunkApi) => {
  try {
    const url = "/api/Checkout/expresscheckouturl";

    const apiResponse = await RESTGatewayAPI.post(url, {});

    const link = document.createElement("a");
    link.setAttribute("href", apiResponse?.data?.data?.ExpressCheckoutUrl);

    link.setAttribute("target", "_blank");
    link.click();
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to download invoice : ${error}`);
  }
});

const initialState: IReducerState<ReducerStatus[]> = {
  data: [],
  status: {
    [openPayPalTab.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const checkoutSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    resetPost: (state) => {
      state.status[openPayPalTab.typePrefix] = ReducerStatus.Idle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openPayPalTab.pending, (state) => {
      state.status[openPayPalTab.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(openPayPalTab.fulfilled, (state) => {
      state.status[openPayPalTab.typePrefix] = ReducerStatus.Succeeded;
    });
    builder.addCase(openPayPalTab.rejected, (state) => {
      state.status[openPayPalTab.typePrefix] = ReducerStatus.Failed;
    });
  },
});

export const { resetPost } = checkoutSlice.actions;

export const getPostStatus = (state: RootState) =>
  state.checkout.status[openPayPalTab.typePrefix];

export default checkoutSlice.reducer;
