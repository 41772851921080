import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import { Typography } from "@material-ui/core";
import { IRouteState, Panel } from "solveiq.designsystem";
import { BigBoxButton } from "solveiq.designsystem";
import styles from "./SystemStatusPanel.module.css";
import check from "assets/img/greenCheck.svg";
import bang from "assets/img/orangeBang.svg";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

export interface ISystemStatusPanelProps {
  items: IStatusItem[];
}

export interface IStatusItem {
  title: string | ReactElement;
  image: string;
  needsAttention: boolean;
  routeName: string;
}

export const SystemStatusPanel: React.FC<ISystemStatusPanelProps> = ({
  ...props
}) => {
  /* Localization function and prefix */
  const prefix = "system.status.panel";
  const { formatMessage } = useIntl();
  const history = useHistory<IRouteState>();
  const { pathname } = useLocation();

  const needsAttentionNumber = props.items.filter((si) => si.needsAttention)
    .length;

  let title = (
    <Typography data-qatag="systemStatusPanelTitle" variant="h6" align="center">
      {formatMessage({
        id: `${prefix}.services.activated`,
        defaultMessage: "All services are currently activated!",
      })}
    </Typography>
  );

  if (needsAttentionNumber > 0) {
    const title1 =
      needsAttentionNumber === 1
        ? formatMessage({
            id: `${prefix}.is.currently`,
            defaultMessage: "There is currently ",
          })
        : formatMessage({
            id: `${prefix}.are.currently`,
            defaultMessage: "There are currently ",
          });
    const title2 =
      needsAttentionNumber === 1
        ? formatMessage({
            id: `${prefix}.service.attention`,
            defaultMessage: " service that needs attention!",
          })
        : formatMessage({
            id: `${prefix}.services.attention`,
            defaultMessage: " services that need attention!",
          });
    title = (
      <Typography
        data-qatag="systemStatusPanelTitle"
        variant="h6"
        align="center"
      >
        {title1}
        <span
          data-qatag="systemStatusPanelNeedsAttentionNumber"
          className={`${styles.attentionNumber} ${
            needsAttentionNumber > 0 ? styles.needsAttention : ""
          }`}
        >
          {needsAttentionNumber}
        </span>
        {title2}
      </Typography>
    );
  }

  const statusItemClick = (routeName: string) => {
    history.push(`/${routeName}`, {
      from: pathname,
      friendlyName: "/System Status",
    });
  };

  return (
    <Panel data-qatag="systemStatusPanel" className={styles.systemStatusPanel}>
      <p
        data-qatag="systemStatusPanelTitle"
        className={styles.systemStatusPanelTitle}
      >
        {title}
      </p>
      <div data-qatag="statusItems" className={styles.statusItems}>
        {props.items &&
          props.items.map((item, i) => {
            return (
              <div
                data-qatag="statusItemContainer"
                className={styles.statusItemContainer}
                key={"status-item-" + i}
              >
                <BigBoxButton
                  data-qatag={`statusItemButton${i}`}
                  dataQatag={`statusItemButton${i}`}
                  key={"bbb-status-" + i}
                  title={item.title}
                  onClick={() => statusItemClick(item.routeName)}
                  titleClass={`${styles.bbbTitle} ${
                    item.needsAttention ? styles.needsAttention : ""
                  }`}
                  description={
                    item.needsAttention
                      ? item.routeName !== "optimizations"
                        ? formatMessage({
                            id: `${prefix}.needs.attention`,
                            defaultMessage: "Needs Attention",
                          })
                        : "Disabled"
                      : formatMessage({
                          id: `${prefix}.enabled`,
                          defaultMessage: "Enabled",
                        })
                  }
                  image={item.image}
                  emblemImage={item.needsAttention ? bang : check}
                />
              </div>
            );
          })}
      </div>
    </Panel>
  );
};
