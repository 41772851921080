import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import {
  DriverInstallStatus,
  DriverUpdateStatus,
  IDriverUpdateModel,
} from "model/driver/DriverUpdateModel";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";
import { Downloader } from "./Downloader";
import queuedIcon from "assets/icon/icn-queued-state.svg";
import failedIcon from "assets/icon/icn-failed-state.svg";
import { useSelector } from "react-redux";
import { selectIsAgentLocal } from "app/Agent/AgentSlice";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    render_root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginRight: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        marginRight: theme.spacing(0),
      },
    },
    state_image: {
      marginRight: theme.spacing(1),
    },
    action_button: {
      width: "181px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "10px",
    },
  })
);
//#endregion Component Styles

//#region Component Props
export interface IUpdaterProps {
  statusModel: IDriverUpdateModel;
  onUpdate: () => void;
  onDownload: () => void;
  onDownloadCancel: () => void;
  onDownloadRetry: () => void;
}
//#endregion Component Props

//#region Component
export const Updater: React.FC<IUpdaterProps> = (props) => {
  const classes = useStyles();
  const isAgentLocal = useSelector(selectIsAgentLocal);

  switch (props.statusModel.updateStatus) {
    case DriverUpdateStatus.ReadyToDownload:
      return (
        <AppButton
          isDisabled={!isAgentLocal}
          data-qatag="driver.update_renderer.downloadButton"
          id="driver.update_renderer.downloadButton"
          buttonStyle={AppButtonStyles.Green}
          className={classes.action_button}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            props.onDownload();
          }}
        >
          <FormattedMessage
            data-qatag="driver.update_renderer.downloadButton.content"
            id="driver.update_renderer.downloadButton.content"
            defaultMessage="Download"
          >
            {(text) => (
              <Typography
                data-qatag="driver.update_renderer.downloadButton.content.text"
                id="driver.update_renderer.downloadButton.content.text"
                variant="h6"
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </AppButton>
      );
    case DriverUpdateStatus.ReadyToInstall:
      return (
        <AppButton
          isDisabled={!isAgentLocal}
          data-qatag="driver.update_renderer.updateButton"
          id="driver.update_renderer.updateButton"
          buttonStyle={AppButtonStyles.Green}
          className={classes.action_button}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            props.onUpdate();
          }}
        >
          <FormattedMessage
            data-qatag="driver.update_renderer.updateButton.content"
            id="driver.update_renderer.updateButton.content"
            defaultMessage="Update"
          >
            {(text) => (
              <Typography
                data-qatag="driver.update_renderer.updateButton.content.text"
                id="driver.update_renderer.updateButton.content.text"
                variant="h6"
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </AppButton>
      );
    case DriverUpdateStatus.Downloading:
      return (
        <Downloader
          data-qatag="driver.update_renderer.downloader"
          model={props.statusModel}
          ProgressBarStyle={{ width: "220px" }}
          onCancel={props.onDownloadCancel}
          onRetry={props.onDownloadRetry}
        />
      );
    case DriverUpdateStatus.Failed:
      return (
        <Box
          data-qatag="driver.update_renderer.failed"
          id="driver.update_renderer.failed"
          className={classes.render_root}
        >
          <img
            data-qatag="driver.update_renderer.failed.image"
            id="driver.update_renderer.failed.image"
            src={failedIcon}
            alt="update failed"
            className={classes.state_image}
          />
          <FormattedMessage
            data-qatag="driver.update_renderer.failed"
            id="driver.update_renderer.failed"
            defaultMessage="Update Failed"
          >
            {(text) => (
              <Typography
                data-qatag="driver.update_renderer.failed.text"
                id="driver.update_renderer.failed.text"
                variant="body2"
                color="textPrimary"
                style={{ fontWeight: 600 }}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </Box>
      );
    case DriverUpdateStatus.Installing: {
      const status = props.statusModel.installRecord?.installStatus;
      if (status != null) {
        return (
          <p
            data-qatag="driver.update_renderer.installing.install_status"
            id="driver.update_renderer.installing.install_status"
          >
            {DriverInstallStatus[status]}
          </p>
        );
      }
      return (
        <p
          data-qatag="driver.update_renderer.installing.unknown"
          id="driver.update_renderer.installing.unknown"
        >
          Unknown Install Status
        </p>
      );
    }
    case DriverUpdateStatus.Queued:
      return (
        <Box
          data-qatag="driver.update_renderer.queued"
          id="driver.update_renderer.queued"
          className={classes.render_root}
        >
          <img
            data-qatag="driver.update_renderer.queued.image"
            id="driver.update_renderer.queued.image"
            src={queuedIcon}
            alt="update queued"
            className={classes.state_image}
          />
          <FormattedMessage
            data-qatag="driver.update_renderer.queued.label"
            id="driver.update_renderer.queued.label"
            defaultMessage="Queued"
          >
            {(text) => (
              <Typography
                data-qatag="driver.update_renderer.queued.label.text"
                id="driver.update_renderer.queued.label.text"
                variant="body2"
                color="textPrimary"
                style={{ fontWeight: 600 }}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </Box>
      );
    default:
      return null;
  }
};
//#endregion Component
