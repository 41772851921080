import { Box, Typography } from "@material-ui/core";
import SeatIllustration from "assets/img/illustration-seats.svg";
import { makeStyles, Theme } from "@material-ui/core/styles";
import styles from "./EmptyState.module.css";

export interface IEmptyState {
  /* Error Message */
  error: string;
  /* Suggestion for the error */
  suggestion: string;
}

/* Custom Styles */
const useStyles = makeStyles((theme: Theme) => ({
  errorDescription: {
    color: theme.palette.text.disabled,
  },
}));

const EmptyState: React.FC<IEmptyState> = ({ error, suggestion }) => {
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  return (
    <div data-qatag="container" className={styles.container}>
      <Box data-qatag="" mt={1} textAlign={"center"}>
        <img
          data-qatag="icon"
          className={styles.icon}
          src={SeatIllustration}
          alt=""
        />
      </Box>
      <Box data-qatag="" mt={3}>
        <Typography data-qatag="" align="center" variant="h3">
          {error}
        </Typography>
      </Box>
      <Box
        data-qatag="errorDescription"
        mt={2}
        maxWidth={440}
        margin="auto"
        mb={4}
        textAlign="center"
        className={classes.errorDescription}
      >
        {suggestion}
      </Box>
    </div>
  );
};

export default EmptyState;
