import React, { useState } from "react";
import styles from "./NavBar.module.css";
import logo from "assets/img/logo.png";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { MenuItem } from "./MenuItem";
import hamburgerMenu from "assets/icon/icn-menu.svg";
import { RootState } from "app/store";
import { selectCurrentUser, selectIsAdmin } from "session/SessionSlice";
import IUser from "model/user/IUser";
import { useSelector } from "react-redux";
import defaultUserImage from "assets/img/team/avatar-default.svg";
import chevron from "assets/img/buttonCollapsed.png";
import { NavLink, useHistory } from "react-router-dom";

export type MenuItemEntry = MenuItemDescriptor | "divider";

export type MenuItemDescriptor = {
  icon?: string;
  to: string;
  label: string;
  badge?: JSX.Element;
  additionalLocationMatches?: string[];
};

export type NavBarProps = {
  menuItems: MenuItemEntry[];
  dropdownItems: MenuItemEntry[];
  userSelector?: (state: RootState) => IUser | null;
};

export const NavBar: React.FC<NavBarProps> = ({
  userSelector = selectCurrentUser,
  ...props
}) => {
  const history = useHistory();
  // replace with the hooks call in solveiq.designsystem when its properly exposed
  const isTabletOrSmaller = useMediaQuery("(max-width: 1024px)");

  const user = useSelector(userSelector);
  const isAdmin = useSelector(selectIsAdmin);

  const [drawerState, setDrawerState] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setDrawerState(open);
  };

  return (
    <Paper data-qatag="navBar" elevation={25} square className={styles.navBar}>
      <Button
        data-qatag="navBar.home.button"
        id="navBar.home.button"
        onClick={() => history.push(isAdmin ? "/admin" : "/team")}
      >
        <img
          data-qatag="navBarLogo"
          className={styles.navBarLogo}
          src={logo}
          alt="navBar Logo"
        />
      </Button>

      {isTabletOrSmaller ? (
        <Box data-qatag="navMenuWrapper">
          <Button data-qatag="toggleDrawerButton" onClick={toggleDrawer(true)}>
            <img data-qatag="menu" src={hamburgerMenu} alt="menu" />
          </Button>
          <Drawer
            data-qatag="navMenuDrawer"
            anchor="right"
            open={drawerState}
            onClose={toggleDrawer(false)}
          >
            <div
              data-qatag="mobileWrapper"
              className={styles.mobileWrapper}
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              {props.menuItems.map((entry) => {
                if (entry === "divider") {
                  return null;
                } else {
                  const desc = entry as MenuItemDescriptor;
                  const menuItemKey = `menuItem${desc.to}${desc.label}`;
                  return (
                    <MenuItem
                      data-qatag="menuItem"
                      fullWidth
                      indicatorLocation="left"
                      key={menuItemKey}
                      descriptor={desc}
                    />
                  );
                }
              })}
              <div
                data-qatag="mobileDivider"
                className={styles.mobileDivider}
              />
              {props.dropdownItems.map((entry) => {
                if (entry === "divider") {
                  return null;
                } else {
                  const desc = entry as MenuItemDescriptor;
                  const menuItemKey = `menuItem${desc.to}${desc.label}`;
                  return (
                    <MenuItem
                      data-qatag="menuItem"
                      fullWidth
                      indicatorLocation="left"
                      key={menuItemKey}
                      descriptor={desc}
                    />
                  );
                }
              })}
            </div>
          </Drawer>
        </Box>
      ) : (
        <Box data-qatag="navBarActions" className={styles.navBarActions}>
          {props.menuItems.map((entry) => {
            if (entry === "divider") {
              return (
                <Divider
                  data-qatag="menuItemDivider"
                  key="divider"
                  orientation="vertical"
                />
              );
            } else {
              const desc = entry as MenuItemDescriptor;
              return (
                <MenuItem
                  data-qatag="menuItem"
                  indicatorLocation="bottom"
                  key={`menuItem${desc.to}${desc.label}`}
                  descriptor={desc}
                />
              );
            }
          })}
          <div
            data-qatag="userContainer"
            className={styles.userContainer}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <img
              data-qatag="userImage"
              src={defaultUserImage}
              className={styles.userImage}
              alt="User"
            />
            <Typography
              data-qatag="userName"
              className={styles.userName}
              variant="body2"
            >
              {`${user?.firstName} ${user?.lastName}`}
            </Typography>
            <img
              alt="chevron"
              data-qatag="chevron"
              src={chevron}
              className={styles.chevron}
            />
          </div>
          <div
            data-qatag="dropdownContainer"
            className={`${styles.dropdownContainer} ${
              showDropdown ? "" : styles.hidden
            }`}
            onMouseLeave={() => setShowDropdown(false)}
            onClick={() => setShowDropdown(false)}
          >
            {props.dropdownItems.map((entry) => {
              if (entry === "divider") {
                return (
                  <div
                    data-qatag="dropdownDivider"
                    key="divider"
                    className={styles.dropdownDivider}
                  />
                );
              } else {
                const desc = entry as MenuItemDescriptor;
                const key = `dropdownItem${desc.to}${desc.label}`;
                return (
                  <NavLink
                    data-qatag="dropdownItem"
                    className={styles.dropdownItem}
                    key={key}
                    to={entry.to}
                  >
                    {entry.icon && (
                      <img
                        src={entry.icon}
                        alt=""
                        data-qatag="dropdownItemIcon"
                        className={styles.dropdownItemIcon}
                      />
                    )}
                    <Typography data-qatag="dropdownItemName" variant="body2">
                      {entry.label}
                    </Typography>
                  </NavLink>
                );
              }
            })}
          </div>
        </Box>
      )}
    </Paper>
  );
};
