import { FormattedMessage } from "react-intl";
import styles from "./Status.module.css";
import {
  DeviceOverview,
  IDeviceOverviewProps,
} from "./deviceOverview/DeviceOverview";
import {
  SystemStatusPanel,
  ISystemStatusPanelProps,
} from "./systemStatusPanel/SystemStatusPanel";
import deviceUpdatesImage from "assets/img/status/deviceUpdatesImage.svg";
import fileCleaningImage from "assets/img/status/fileCleaningImage.svg";
import deceptorBlockingImage from "assets/img/status/deceptorBlockingImage.svg";
import appOptimizationImage from "assets/img/status/appOptimizationImage.svg";
import windowsEnhancementsImage from "assets/img/status/windowsEnhancementsImage.svg";
import dnsProtectionImage from "assets/img/status/dnsProtectionImage.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHardwareScan,
  fetchSystemInfoScan,
  selectDeviceOverview,
  selectOutOfDateDriversCount,
  selectScanStatus,
} from "features/scan/ScanSlice";
import { useEffect, useState } from "react";
import { RootState } from "app/store";
import { IUserSettingModel } from "model/messaging/messages/AppSettingsMessages";
import {
  fetchUserSettings,
  selectSettings,
  selectStatus as selectSettingsStatus,
} from "features/settings/SettingsSlice";
import { IThunkStatus, ReducerStatus } from "model/IReducerState";
import { UserSettingTypes } from "core/enumerations/UserSettingTypes";
import {
  selectIsAgentLocal,
  selectOptimizationsFinalized,
  selectRebootRequired,
} from "app/Agent/AgentSlice";
import { IFileCleanupScan } from "model/scan/IFileCleanupScan";
import {
  fetchFileCleanupResults,
  fetchFileCleanupScan,
  selectFileCleaningNeedsAttention,
  selectFileCleanupScan,
  selectIsFileCleaningTileActive,
  selectStatus as selectFileCleanupStatus,
} from "features/fileCleaning/FileCleaningSlice";
import { RebootDialog } from "./rebootDialog/RebootDialog";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { AppButton, AppButtonStyles, Panel } from "solveiq.designsystem";

export interface IStatusScreenProps {
  deviceOverviewSelector?: (state: RootState) => IDeviceOverviewProps;
  settingsSelector?: (state: RootState) => IUserSettingModel[] | null;
  settingsStatusSelector?: (state: RootState) => IThunkStatus;
  scanStatusSelector?: (state: RootState) => IThunkStatus;
  outOfDateDriversCountSelector?: (state: RootState) => number;
  rebootRequiredSelector?: (state: RootState) => boolean;
  isAgentLocalSelector?: (state: RootState) => boolean;
  optimizationsFinalizedSelector?: (state: RootState) => boolean;
  fileCleaningNeedsAttentionSelector?: (state: RootState) => boolean;
  fileCleanupStatusSelector?: (state: RootState) => IThunkStatus;
}

const useStyles = makeStyles((theme: Theme) => ({
  rebootPanel: {},
  rebootPanelContent: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-evenly",
    alignItems: "center",
    maxWidth: "520px",
    margin: "0 auto",
  },
  rebootMessage: {
    maxWidth: "200px",
    textAlign: "center",
  },
  rebootButton: {
    width: "255px",
    [theme.breakpoints.down("sm")]: {
      margin: "5px 0",
    },
  },
}));

export const StatusScreen: React.FC<IStatusScreenProps> = ({
  deviceOverviewSelector = selectDeviceOverview,
  settingsSelector = selectSettings,
  settingsStatusSelector = selectSettingsStatus,
  scanStatusSelector = selectScanStatus,
  outOfDateDriversCountSelector = selectOutOfDateDriversCount,
  rebootRequiredSelector = selectRebootRequired,
  optimizationsFinalizedSelector = selectOptimizationsFinalized,
  isAgentLocalSelector = selectIsAgentLocal,
  fileCleaningNeedsAttentionSelector = selectFileCleaningNeedsAttention,
  fileCleanupStatusSelector = selectFileCleanupStatus,
}) => {
  const prefix = "system.status";
  const deviceOverview = useSelector(deviceOverviewSelector);
  const settings = useSelector(settingsSelector);
  const optimizationsFinalized = useSelector(optimizationsFinalizedSelector);
  const outOfDateDriverCount = useSelector(outOfDateDriversCountSelector);
  const isFileCleaningTileActive = useSelector(
    fileCleaningNeedsAttentionSelector
  );
  const rebootRequired = useSelector(rebootRequiredSelector);
  const isAgentLocal = useSelector(isAgentLocalSelector);

  const dispatch = useDispatch();
  const classes = useStyles();

  const [showRebootDialog, setShowRebootDialog] = useState(false);

  useEffect(() => {
    dispatch(fetchUserSettings());
    dispatch(fetchSystemInfoScan());
    dispatch(fetchFileCleanupScan());
    dispatch(fetchFileCleanupResults());
    dispatch(fetchHardwareScan());
  }, [dispatch]);

  const appOptimizationsNeedAttention =
    settings === null
      ? false
      : !settings.find((x) => x.SettingType === UserSettingTypes.BrowsingState)
          ?.SettingValue &&
        !settings.find(
          (x) => x.SettingType === UserSettingTypes.ProductivityState
        )?.SettingValue &&
        !settings.find((x) => x.SettingType === UserSettingTypes.GamingState)
          ?.SettingValue;
  const systemStatusPanelProps: ISystemStatusPanelProps = useSelector(() => {
    return {
      items: [
        {
          title: (
            <FormattedMessage
              data-qatag="deviceUpdatesMessage"
              id={`${prefix}.device.updates`}
              defaultMessage="Device Updates"
            />
          ),
          needsAttention: outOfDateDriverCount > 0,
          image: deviceUpdatesImage,
          routeName: "drivers",
        },
        {
          title: (
            <FormattedMessage
              data-qatag="fileCleaningMessage"
              id={`${prefix}.file.cleaning`}
              defaultMessage="File Cleaning"
            />
          ),
          needsAttention: isFileCleaningTileActive,
          image: fileCleaningImage,
          routeName: "filecleaning",
        },
        {
          title: (
            <FormattedMessage
              data-qatag="deceptorBlockingMessage"
              id={`${prefix}.deceptor.blocking`}
              defaultMessage="Deceptor Blocking"
            />
          ),
          needsAttention:
            !settings?.find(
              (x) => x.SettingType === UserSettingTypes.EnableDeceptorServices
            )?.SettingValue ?? false,
          image: deceptorBlockingImage,
          routeName: "deceptorprotection",
        },
        {
          title: (
            <FormattedMessage
              data-qatag="appOptimizationMessage"
              id={`${prefix}.app.optimization`}
              defaultMessage="App Optimization"
            />
          ),
          needsAttention: appOptimizationsNeedAttention,
          image: appOptimizationImage,
          routeName: "optimizations",
        },
        {
          title: (
            <FormattedMessage
              data-qatag="windowsEnhancementsMessage"
              id={`${prefix}.windows.enhancements`}
              defaultMessage="Windows Enhancements"
            />
          ),
          needsAttention: !optimizationsFinalized,
          image: windowsEnhancementsImage,
          routeName: "windowsenhancements",
        },
        {
          title: (
            <FormattedMessage
              data-qatag="dnsProtectionMessage"
              id={`${prefix}.dns.protection`}
              defaultMessage="DNS Protection"
            />
          ),
          needsAttention:
            !settings?.find(
              (x) => x.SettingType === UserSettingTypes.EnableDnsProtector
            )?.SettingValue ?? false,
          image: dnsProtectionImage,
          routeName: "dnsprotection",
        },
      ],
    };
  });

  return (
    <div data-qatag="statusScreen" className={styles.statusScreen}>
      <DeviceOverview
        data-qatag="statusScreenDeviceOverview"
        {...deviceOverview}
      />
      {rebootRequired && (
        <Panel data-qatag="rebootPanel" className={styles.rebootPanel}>
          <div
            data-qatag="rebootPanelContent"
            className={classes.rebootPanelContent}
          >
            <Typography
              data-qatag="body2"
              variant="body2"
              className={classes.rebootMessage}
            >
              <FormattedMessage
                data-qatag="rebootPanel.description"
                id={`${prefix}.rebootPanel.description`}
                defaultMessage="Restart your computer to finish optimizing"
              />
            </Typography>
            {isAgentLocal && (
              <AppButton
                data-qatag="systemStatusRebootButton"
                qaTag="systemStatusRebootButton"
                buttonStyle={AppButtonStyles.Green}
                className={classes.rebootButton}
                onClick={() => setShowRebootDialog(true)}
              >
                <Typography
                  data-qatag="systemStatusRebootButtonText"
                  variant="body2"
                >
                  <FormattedMessage
                    data-qatag="systemStatusRebootButtonMessage"
                    id={`${prefix}.rebootPanel.button`}
                    defaultMessage="Reboot Now"
                  />
                </Typography>
              </AppButton>
            )}
          </div>
          <RebootDialog
            data-qatag="rebootDialog"
            isOpen={showRebootDialog}
            closeDialogHandler={() => setShowRebootDialog(false)}
          />
        </Panel>
      )}
      <SystemStatusPanel
        data-qatag="StatusScreenSystemStatusPanel"
        {...systemStatusPanelProps}
      />
    </div>
  );
};
