import React from "react";
import styles from "./MenuItem.module.css";
import { NavLink, Route } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { MenuItemDescriptor } from "./NavBar";

const useStyles = makeStyles(() =>
  createStyles({
    bold: {
      fontWeight: 600,
    },
    fullWidth: {
      width: "100%",
    },
  })
);

export type IndicatorLocation = "left" | "right" | "top" | "bottom";

export type MenuItemProps = {
  descriptor: MenuItemDescriptor;
  indicatorLocation?: IndicatorLocation;
  fullWidth?: boolean;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  fullWidth,
  indicatorLocation,
  descriptor,
}) => {
  const classes = useStyles();

  if (indicatorLocation == null) {
    indicatorLocation = "bottom";
  }
  if (fullWidth == null) {
    fullWidth = false;
  }

  return (
    <Route data-qatag="route" path={descriptor.to}>
      {({ match }) => {
        const isExactMatch: boolean = (match && match.isExact) || false;
        return (
          <NavLink
            data-qatag="nav_menu_link_label"
            exact
            className={
              isExactMatch
                ? styles[`activeNavLink--${indicatorLocation}`]
                : styles[`navLink--${indicatorLocation}`]
            }
            to={descriptor.to}
          >
            <Box
              data-qatag="menuContainer"
              className={
                (isExactMatch ? classes.bold : "") +
                " " +
                (fullWidth ? classes.fullWidth : "")
              }
            >
              {descriptor.icon && fullWidth && (
                <img
                  data-qatag="menuItemIcon"
                  className={styles.menuItemIcon}
                  src={descriptor.icon}
                  alt=""
                />
              )}
              <Typography
                data-qatag="nav_menu_text_descriptor_label"
                variant={fullWidth ? "h6" : "body2"}
              >
                {descriptor.label}
                {descriptor.badge && fullWidth && descriptor.badge}
              </Typography>
            </Box>
          </NavLink>
        );
      }}
    </Route>
  );
};
