import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import errorImage from "assets/img/illustration - Oops Error.svg";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      padding: theme.spacing(6),
      background: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        width: "332px",
        height: "536px",
      },
      [theme.breakpoints.up("md")]: {
        width: "686px",
        height: "660px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "845px",
        height: "660px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "936px",
        height: "668px",
      },
    },
    title: {
      color: "#0084A3",
      fontSize: "60px",
    },
    image: {
      width: "485px",
      height: "256px",
      [theme.breakpoints.down("sm")]: {
        width: "283px",
        height: "150px",
      },
    },
    description: {},
    buttonText: { fontWeight: 600 },
    buttonContainer: {
      marginTop: theme.spacing(2),
    },
    reloadButton: {
      width: "217px",
      height: "44px",
    },
  })
);

export interface IErrorFallbackProps {
  onReload: () => void;
}

export const ErrorFallback: React.FC<IErrorFallbackProps> = (props) => {
  const classes = useStyles();

  return (
    <Paper data-qatag="errorFallback.root" className={classes.root}>
      <img
        data-qatag="errorFallback.image"
        id="errorFallback.image"
        src={errorImage}
        className={classes.image}
        alt=""
      />
      <FormattedMessage
        data-qatag="error.title"
        id="error.title"
        defaultMessage="Oops"
      >
        {(text) => (
          <Typography
            data-qatag="error.title.text"
            id="error.title.text"
            className={classes.title}
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <FormattedMessage
        data-qatag="error.description"
        id="error.description"
        defaultMessage="Something went wrong.{br}We're currently working on it!"
        values={{
          br: (
            <br
              data-qatag="error.description.break"
              id="error.description.break"
            />
          ),
        }}
      >
        {(text) => (
          <Typography
            data-qatag="error.title.text"
            id="error.title.text"
            variant="h6"
            color="primary"
            className={classes.description}
            align="center"
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <Box
        data-qatag="error.buttonContainer"
        id="error.buttonContainer"
        className={classes.buttonContainer}
      >
        <AppButton
          data-qatag="error.reloadButton"
          onClick={props.onReload}
          buttonStyle={AppButtonStyles.Clear}
          className={classes.reloadButton}
        >
          <FormattedMessage
            data-qatag="error.reloadButton.text"
            id="error.reloadButton.text"
            defaultMessage="Reload"
          >
            {(text) => (
              <Typography
                data-qatag="error.reloadButton.text.value"
                id="error.reloadButton.text.value"
                variant="body2"
                className={classes.buttonText}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </AppButton>
      </Box>
    </Paper>
  );
};
