import {
  Box,
  createStyles,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React from "react";
import {
  AppButton,
  AppButtonStyles,
  SimpleTextField,
} from "solveiq.designsystem";
import styles from "./DriverContactForm.module.css";
import * as yup from "yup";
import { IDeviceInfo } from "model/scan/DeviceInfo";
import { FormattedMessage } from "react-intl";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(0),
      },
    },
    field_container: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
  })
);
//#endregion Component Styles

//#region Validation

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email address"),
});

//#endregion Validation

//#region Component Interfaces
export interface IContactSubmitDetails {
  name: string;
  email: string;
  regarding: string;
  details: string;
  device: IDeviceInfo;
}

export interface IDriverContactForm {
  initialName: string;
  initialEmail: string;
  device: IDeviceInfo;
  onSubmit: (submitData: IContactSubmitDetails) => Promise<void>;
}
//#endregion Component Interfaces

//#region Component
export const DriverContactForm: React.FC<IDriverContactForm> = (
  props: IDriverContactForm
) => {
  const classes = useStyles();
  return (
    <Formik
      data-qatag="driver.contact.form.root"
      initialValues={{
        email: props.initialEmail ?? "",
        name: props.initialName ?? "",
        regarding: props.device?.friendlyName ?? "",
        details: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await props.onSubmit({
          name: values.name,
          email: values.email,
          regarding: values.regarding,
          details: values.details,
          device: props.device,
        });
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form
          data-qatag="driver.contact.form"
          id="driver.contact.form"
          className={classes.form}
        >
          <Box
            data-qatag="driver.contact.form.field_container"
            id="driver.contact.form.field_container"
            className={classes.field_container}
          >
            <Field
              data-qatag="driver.contact.form.field.name"
              id="driver.contact.form.field.name"
              component={SimpleTextField}
              name="name"
              type="text"
              label="Name"
            />
            <Field
              data-qatag="driver.contact.form.field.email"
              id="driver.contact.form.field.email"
              component={SimpleTextField}
              name="email"
              type="email"
              label="Email Address"
            />
            <Field
              data-qatag="driver.contact.form.field.regarding"
              id="driver.contact.form.field.regarding"
              component={SimpleTextField}
              name="regarding"
              type="text"
              label="Regarding"
              disabled
            />

            <Field
              data-qatag="driver.contact.form.field.details"
              id="driver.contact.form.field.details"
              component={SimpleTextField}
              type="text"
              label="Question Details"
              name="details"
              multiline
              rows={10}
            />
            {isSubmitting && (
              <LinearProgress
                data-qatag="driver.contact.form.spinner"
                id="driver.contact.form.spinner"
              />
            )}
          </Box>
          <AppButton
            data-qatag="driver.contact.form.submitButton"
            id="driver.contact.form.submitButton"
            buttonStyle={AppButtonStyles.Green}
            disabled={isSubmitting}
            onClick={submitForm}
            className={
              isSubmitting ? styles.disabledSubmitButton : styles.submitButton
            }
          >
            <FormattedMessage
              data-qatag="driver.contact.form.submitButton.text"
              id="driver.contact.form.submitButton.text"
              defaultMessage="Submit"
            >
              {(text) => (
                <Typography
                  data-qatag="driver.contact.form.submitButton.text.value"
                  id="driver.contact.form.submitButton.text.value"
                >
                  {text}
                </Typography>
              )}
            </FormattedMessage>
          </AppButton>
        </Form>
      )}
    </Formik>
  );
};
