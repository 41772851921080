import { Panel, OnOffSwitch } from "solveiq.designsystem";
import styles from "./DnsProtection.module.css";
import { useIntl } from "react-intl";
import infoImage from "assets/img/info-icon.png";
import headerImage from "assets/img/threeGears.svg";
import privateImage from "assets/img/dns/icn-DNS-private.svg";
import preventImage from "assets/img/dns/icn-DNS-prevent.svg";
import improveImage from "assets/img/dns/icn-DNS-improve.svg";
import quad9Image from "assets/img/dns/quad9-logo.png";
import { RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserSettings,
  selectSettings,
  selectStatus as selectSettingsStatus,
  updateSetting,
  updateUserSetting,
} from "features/settings/SettingsSlice";
import { IThunkStatus, ReducerStatus } from "model/IReducerState";
import React, { useEffect } from "react";
import { useState } from "react";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { IUserSettingModel } from "model/messaging/messages/AppSettingsMessages";
import { UserSettingTypes } from "core/enumerations/UserSettingTypes";
import { RouterBreadcrumbs } from "features/teamManagement/employeeDetail/RouterBreadcrumbs";
import {
  selectIsRemote,
  selectCurrentUser,
  selectUserDescriptor,
} from "session/SessionSlice";

export interface IDnsProtectionProps {
  settingsSelector?: (state: RootState) => IUserSettingModel[] | null;
  settingsStatusSelector?: (state: RootState) => IThunkStatus;
}

export const DnsProtection: React.FC<IDnsProtectionProps> = ({
  settingsSelector = selectSettings,
  settingsStatusSelector = selectSettingsStatus,
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const prefix = "dnsProtection.view";

  const settings = useSelector(settingsSelector);
  const isRemote = useSelector(selectIsRemote);
  const user = useSelector(selectCurrentUser);
  const userDescriptor = useSelector(selectUserDescriptor);

  useEffect(() => {
    dispatch(fetchUserSettings());
  }, [dispatch]);

  const [showQuad9Hover, setShowQuad9Hover] = useState<boolean>(false);

  const protectionEnabled = settings?.find(
    (x) => x.SettingType === UserSettingTypes.EnableDnsProtector
  )?.SettingValue;

  const onSwitchClick = async (
    e: React.MouseEvent<HTMLElement>,
    isOn: boolean
  ) => {
    dispatch(
      updateUserSetting({
        UpdatedSettings: [
          {
            SettingType: UserSettingTypes.EnableDnsProtector,
            SettingTypeName:
              UserSettingTypes[UserSettingTypes.EnableDnsProtector],
            NewSettingValue: isOn,
            CurrentSettingValue: protectionEnabled,
          },
        ],
      })
    );
    dispatch(
      updateSetting({
        SettingType: UserSettingTypes.EnableDnsProtector,
        SettingValue: isOn,
      })
    );
  };

  return (
    <div data-qatag="dnsProtection" className={styles.dnsProtection}>
      {isRemote ? (
        <RouterBreadcrumbs
          data-qatag="dnsProtection.screen.remoteBreadcrumbs"
          style={{ width: "100%", height: "42px", alignSelf: "center" }}
          descriptors={[
            {
              to: `/employeeDetail/${user?.id}`,
              label: userDescriptor,
            },
            {
              to: "/",
              label: "DNS Protection",
            },
          ]}
        ></RouterBreadcrumbs>
      ) : (
        <Breadcrumbs
          data-qatag="dnsProtectionBreadcrumbs"
          pathsMap={{ dnsprotection: "DNS Protection" }}
        />
      )}

      <Panel data-qatag="header" className={styles.header}>
        <img
          data-qatag="headerImage"
          src={headerImage}
          className={styles.headerImage}
          alt=""
        />
        <div
          data-qatag="headerTextContainer"
          className={styles.headerTextContainer}
        >
          <span data-qatag="headerTitle" className={styles.headerTitle}>
            {formatMessage({
              id: `${prefix}.header.title`,
              defaultMessage: "DNS Malware Protection",
            })}
          </span>
          <span
            data-qatag="headerDescription"
            className={styles.headerDescription}
          >
            {formatMessage({
              id: `${prefix}.header.description`,
              defaultMessage:
                "This service supplements your current security solution by preventing access to harmful websites and more.",
            })}
          </span>
        </div>
        {protectionEnabled != null && (
          <OnOffSwitch
            data-qatag="switch"
            className={styles.switch}
            onClick={onSwitchClick}
            isDisabled={false}
            isOnInitially={protectionEnabled}
          />
        )}
      </Panel>
      <Panel data-qatag="body" className={styles.body}>
        <div data-qatag="mobileHeader" className={styles.mobileHeader}>
          <img
            data-qatag="headerImage"
            src={headerImage}
            className={styles.headerImage}
            alt=""
          />
          <div
            data-qatag="headerTextContainer"
            className={styles.headerTextContainer}
          >
            <span data-qatag="headerTitle" className={styles.headerTitle}>
              {formatMessage({
                id: `${prefix}.header.title`,
                defaultMessage: "DNS Malware Protection",
              })}
            </span>
            <span
              data-qatag="headerDescription"
              className={styles.headerDescription}
            >
              {formatMessage({
                id: `${prefix}.header.description`,
                defaultMessage:
                  "This service supplements your current security solution by preventing access to harmful websites and more.",
              })}
            </span>
            {protectionEnabled !== null && (
              <OnOffSwitch
                data-qatag="switch"
                className={styles.switch}
                onClick={onSwitchClick}
                isDisabled={false}
                isOnInitially={protectionEnabled}
              />
            )}
          </div>
        </div>
        <span data-qatag="bodyTitle" className={styles.bodyTitle}>
          {formatMessage({
            id: `${prefix}.body.title`,
            defaultMessage: "Internet Security & Privacy",
          })}
        </span>
        <div data-qatag="benefitList" className={styles.benefitList}>
          <div data-qatag="benefit" className={styles.benefit}>
            <img
              data-qatag="benefitImage"
              src={privateImage}
              className={styles.benefitImage}
              alt=""
            />
            <span data-qatag="benefitText" className={styles.benefitText}>
              {formatMessage({
                id: `${prefix}.body.listItem1`,
                defaultMessage: "Keep your web browsing private",
              })}
            </span>
          </div>
          <div data-qatag="benefit" className={styles.benefit}>
            <img
              data-qatag="benefitImage"
              src={preventImage}
              className={styles.benefitImage}
              alt=""
            />
            <span data-qatag="benefitText" className={styles.benefitText}>
              {formatMessage({
                id: `${prefix}.body.listItem2`,
                defaultMessage: "Prevent access to malicious websites",
              })}
            </span>
          </div>
          <div data-qatag="benefit" className={styles.benefit}>
            <img
              data-qatag="benefitImage"
              src={improveImage}
              className={styles.benefitImage}
              alt=""
            />
            <span data-qatag="benefitText" className={styles.benefitText}>
              {formatMessage({
                id: `${prefix}.body.listItem3`,
                defaultMessage: "Improve website lookup speed worldwide",
              })}
            </span>
          </div>
        </div>
        <span data-qatag="explanationTitle" className={styles.explanationTitle}>
          {formatMessage({
            id: `${prefix}.body.explanationTitle`,
            defaultMessage: "How It Works",
          })}
        </span>
        <span data-qatag="explanation" className={styles.explanation}>
          {formatMessage({
            id: `${prefix}.body.explanation`,
            defaultMessage:
              "Quad9 routes website URLs (or DNS) queries through a secure network of servers around the globe. The system uses threat intelligence from more than a dozen of the industry's leading cyber security companies, to give a real-time perspective on what websites are safe, and what sites are known to include malware or other threats. If the system detects that the site you want to reach is known to be infected, you'll automatically be blocked from entry -- keeping your data and computer safe.",
          })}
        </span>
        <div data-qatag="footer" className={styles.footer}>
          <div
            data-qatag="quad9InfoContainer"
            className={styles.quad9InfoContainer}
            onMouseEnter={() => setShowQuad9Hover(true)}
            onMouseLeave={() => setShowQuad9Hover(false)}
          >
            {showQuad9Hover && (
              <div
                data-qatag="quad9HoverContainer"
                className={styles.quad9HoverContainer}
              >
                <div
                  data-qatag="quad9HoverText"
                  className={styles.quad9HoverText}
                >
                  {formatMessage({
                    id: `${prefix}.quad9hover.description`,
                    defaultMessage:
                      "Quad9 blocks against known malicious domains, preventing your computers and IoT devices from connecting to malware or phishing sites with the help of 19 different threat intelligence partners. Quad9 systems are also distributed worldwide in more than 145 locations in 88 nations which means faster DNS than almost any other. Finally, Quad9 collects no personally identifiable information improving your privacy over any other DNS service.",
                  })}
                </div>
              </div>
            )}
            <img
              data-qatag="infoImage"
              src={infoImage}
              className={styles.infoImage}
              alt=""
            />
            <span data-qatag="footerText" className={styles.footerText}>
              {formatMessage({
                id: `${prefix}.body.poweredBy`,
                defaultMessage: "Powered By:",
              })}
            </span>
            <img
              data-qatag="quad9Image"
              src={quad9Image}
              className={styles.quad9Image}
              alt=""
            />
          </div>
        </div>
      </Panel>
    </div>
  );
};
