import { Box, Typography, TextareaAutosize } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import styles from "./TheWhy.module.css";
import { Spinner, Select, SelectOptionInterface } from "solveiq.designsystem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      color: theme.palette.secondary.light,
      fontWeight: 600,
    },
    buttonText: {
      lineHeight: 1,
    },
  })
);

export interface ITheWhy {
  formOptions: SelectOptionInterface[];
  setReason: (e: number) => void;
  setFeedBack: (e: string) => void;
}

const TheWhy: React.FC<ITheWhy> = ({ formOptions, setReason, setFeedBack }) => {
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  return (
    <>
      {/* Covered Team Members */}

      <Box
        data-qatag="featuresContainer"
        mt={4}
        className={styles.coveredTeamMembers}
        ml="auto"
        mr="auto"
      >
        <Box
          data-qatag="logoContainer"
          width={1}
          textAlign="center"
          display="flex"
          justifyContent="center"
        >
          <Typography
            data-qatag="titleText"
            variant="h4"
            className={`${classes.titleText} ${styles.coverMembersText}`}
          >
            We are really sorry to see you leave.
          </Typography>
        </Box>
        <Box data-qatag="subtitle" width={1} mt={1.5} textAlign="center">
          <Typography data-qatag="titleText" variant="body2">
            Please fill out the form below so we can process your cancellation
            as soon as possible.
          </Typography>
        </Box>
        <Box data-qatag="subtitle" width={1} mt={4} mb={1} textAlign="center">
          <Typography data-qatag="titleText" variant="body2">
            What was the reason for your cancellation?
          </Typography>
        </Box>
        <Box
          data-qatag="subtitle"
          width={1}
          textAlign="center"
          maxWidth={588}
          marginLeft="auto"
          marginRight="auto"
        >
          <Select
            data-qatag="selectReasonCancellation"
            onChange={(e) => setReason(parseInt(e))}
            className={styles.cancellationOption}
            defaultValue="None"
            options={formOptions}
          />
        </Box>
        <Box
          data-qatag="subtitle"
          width={1}
          mt={5}
          textAlign="center"
          maxWidth={834}
          marginLeft="auto"
          marginRight="auto"
        >
          <TextareaAutosize
            data-qatag="cancellationMessage"
            aria-label="empty textarea"
            placeholder="Share with us your feedback"
            className={styles.textAreaMessage}
            onChange={(e) => setFeedBack(e.target.value)}
          />
        </Box>
      </Box>

      {/* Loading modal */}
      <Spinner data-qatag="loadingModal" isActive={false} text="Loading..." />
    </>
  );
};

export default TheWhy;
