import { useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";
import { Modal, IMetric } from "..";
import headerIcon from "assets/icon/icn-gaming-default.svg";
import styles from "./SoftwareBox.module.css";

export interface ISoftwareBox {
  /* Software title */
  title: string;
  /* Software description */
  description?: string;
  /* Image to be displayed */
  image: string | null;
  /* Metrics */
  metrics: IMetric[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonText: {
      lineHeight: 1,
    },
    description: {
      color: theme.palette.error.light,
    },
  })
);

const SoftwareBox: React.FC<ISoftwareBox> = ({
  title,
  description = " ",
  metrics,
  image,
}) => {
  /* Localization prefix */
  const prefix = "software.box";
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  // Specif scenario when the Download URI image is an ftp
  const ftpRegex = /^ftp:\/\//;

  const [openModal, setOpenModal] = useState(false);

  return (
    <Box data-qatag="container" className={styles.container}>
      <Box
        data-qatag="content"
        className={styles.content}
        display="flex"
        flexWrap="wrap"
        alignContent="center"
        alignItems="center"
      >
        <Box
          data-qatag="wireFrameContainer"
          width={1}
          textAlign="center"
          mt={4}
        >
          <img
            data-qatag="icon"
            className={styles.icon}
            src={image && !ftpRegex.test(image) ? image : headerIcon}
            alt="wire-frame"
          />
        </Box>

        <Box data-qatag="titleContainer" width={1} textAlign="center" mt={3}>
          <Typography data-qatag="titleText" variant="h6">
            {title}
          </Typography>
        </Box>
        <Box
          data-qatag="description"
          width={1}
          textAlign="center"
          height={25}
          className={classes.description}
        >
          {description}
        </Box>
        <Box data-qatag="customButtonBox" width={1} textAlign="center" mt={2}>
          <AppButton
            data-qatag="customButton"
            buttonStyle={AppButtonStyles.Green}
            className={`${styles.customButton}`}
            onClick={() => {
              setOpenModal((prevState) => !prevState);
            }}
          >
            <Typography
              data-qatag="buttonText"
              align="center"
              className={classes.buttonText}
              variant="body2"
            >
              <FormattedMessage
                data-qatag="FormattedMessage"
                id={`${prefix}.view.details`}
              />
            </Typography>
          </AppButton>
        </Box>
      </Box>
      <Modal
        data-qatag="modalInformation"
        title={title}
        image={image && !ftpRegex.test(image) ? image : headerIcon}
        subtitle={description}
        metrics={metrics}
        handleOnClose={() => {
          setOpenModal((prevState) => !prevState);
        }}
        isOpen={openModal}
      />
    </Box>
  );
};

export default SoftwareBox;
