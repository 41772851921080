import { CSSProperties, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgentDownloadUrl,
  selectDownloadUrl,
  selectDownloadUrlStatus,
} from "app/Agent/AgentSlice";
import { ReducerStatus } from "model/IReducerState";
import { Spinner as Loader } from "features/driverInstall/Spinner";
import styles from "./DownloadLander.module.css";
import logo from "assets/img/logo.png";
import bg from "assets/img/downloadLander/downloadBodyBackground.png";
import chromeStep01 from "assets/img/downloadLander/chrome_step_01.png";
import firefoxStep01 from "assets/img/downloadLander/firefox_step_01.png";
import edgeStep01 from "assets/img/downloadLander/edge_step_01.png";
import step02 from "assets/img/downloadLander/step_02.png";
import step03 from "assets/img/downloadLander/step_03.png";
import verticalIndicator from "assets/img/downloadLander/vertical_download_indicator.png";
import horizontalIndicator from "assets/img/downloadLander/horizontal_download_indicator.png";
import { RootState } from "app/store";

export interface IDownloadLangerProps {
  downloadUrlSelector?: (state: RootState) => string;
  downloadUrlStatusSelector?: (state: RootState) => ReducerStatus;
  getCurrentBrowserMethod?: () => string;
}

const DownloadLander: React.FC<IDownloadLangerProps> = ({
  downloadUrlSelector = selectDownloadUrl,
  downloadUrlStatusSelector = selectDownloadUrlStatus,
  getCurrentBrowserMethod = getCurrentBrowser,
}) => {
  const [currentBrowser, setCurrentBrowser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const downloadUrlRequestStatus = useSelector(downloadUrlStatusSelector);
  const downloadUrl = useSelector(downloadUrlSelector);
  const dispatch = useDispatch();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showVerticalIndicator, setShowVerticalIndicator] = useState(false);
  const [showHorizontalIndicator, setShowHorizontalIndicator] = useState(false);

  useEffect(() => {
    if (!downloadUrl || downloadUrl === "") {
      dispatch(getAgentDownloadUrl());
    }
  }, [dispatch, downloadUrl]);

  useEffect(() => {
    setIsLoading(downloadUrlRequestStatus === ReducerStatus.Loading);
  }, [downloadUrlRequestStatus]);

  useEffect(() => {
    setCurrentBrowser(getCurrentBrowserMethod);
  }, []);

  const playAnimation = useCallback(() => {
    switch (currentBrowser) {
      case "firefox":
        // No animations nor overlay.
        break;
      case "edge":
        setShowOverlay(true);
        setShowHorizontalIndicator(true);
        break;
      case "chrome":
      default:
        setShowOverlay(true);
        setShowVerticalIndicator(true);
    }
  }, [currentBrowser]);

  const downloadFile = useCallback(() => {
    window.location.href = downloadUrl;
    playAnimation();
  }, [downloadUrl, playAnimation]);

  // Trigger download 1.5 seconds after the URL is ready.
  useEffect(() => {
    let id: number;
    if (downloadUrl && downloadUrl !== "") {
      id = window.setTimeout(downloadFile, 1500);
    }
    return () => {
      clearTimeout(id);
    };
  }, [downloadFile, downloadUrl]);

  const stopAnimation = useCallback(() => {
    setShowOverlay(false);
    setShowVerticalIndicator(false);
    setShowHorizontalIndicator(false);
  }, []);

  return (
    <div data-qatag="container" className={styles.container}>
      <header data-qatag="downloadHeader">
        <h1 data-qatag="title" className={styles.title}>
          Almost done!
        </h1>
        <h2 data-qatag="subtitle" className={styles.subtitle}>
          Follow these <span data-qatag="downloadSubtitle">3 easy steps</span>{" "}
          to complete your installation
        </h2>
        {isLoading ? (
          <Loader data-qatag="loader" />
        ) : (
          <h3
            data-qatag="subtitleClickhere"
            className={styles.subtitleClickhere}
          >
            If your download hasn&apos;t already started,
            <button
              data-qatag="clickHere"
              className={styles.clickHere}
              onClick={downloadFile}
            >
              click here
            </button>
          </h3>
        )}
      </header>

      <div data-qatag="stepsContainer" className={styles.stepsContainer}>
        <img
          data-qatag="stepsBackground"
          className={styles.stepsBackground}
          src={bg}
          alt=""
        />
        <div data-qatag="steps" className={styles.steps}>
          <Step
            data-qatag="stepOne"
            order={1}
            title="Run the installer"
            image={getFirstStepImage(currentBrowser)}
            description={getFirstStepDescription(currentBrowser)}
          />
          <Step
            data-qatag="stepTwo"
            order={2}
            title="Confirm installation"
            image={step02}
            description='Click "Yes" on the system dialog window to confirm your installation'
          />
          <Step
            data-qatag="stepThree"
            order={3}
            title="Complete setup"
            image={step03}
            description="Follow the easy installation wizard and start optimizing!"
          />
        </div>
      </div>

      <footer data-qatag="landerFooter" className={styles.footer}>
        <div data-qatag="footerLogoLinks" className={styles.footerLogoLinks}>
          <img
            data-qatag="footerLogoLander"
            src={logo}
            alt="Solve iQ Business"
          />
          <div data-qatag="footerLinks" className={styles.footerLinks}>
            <a
              data-qatag="softwarePrinciples"
              href="http://www.solveiq.com/home/software-principles"
            >
              Software Principles
            </a>{" "}
            |{" "}
            <a
              data-qatag="privacyPolicy"
              href="http://www.solveiq.com/home/privacy-policy"
            >
              Privacy Policy
            </a>{" "}
            |{" "}
            <a
              data-qatag="termsAndConditions"
              href="http://www.solveiq.com/home/terms-and-conditions"
            >
              Terms and Conditions
            </a>{" "}
            |{" "}
            <a
              data-qatag="uninstallInstructions"
              href="http://www.solveiq.com/home/uninstall"
            >
              Uninstall Instructions
            </a>
          </div>
        </div>
        <p data-qatag="copyright" className={styles.copyright}>
          © Copyright 1996-2021 Solve iQ. All rights reserved. Solve iQ, the
          Solve iQ logo, and SOLVEIQ.COM are trademarks of Solve iQ and its
          affiliated companies. All third party products, brands or trademarks
          listed above are the sole property of their respective owner. No
          affiliation or endorsement is intended or implied.
        </p>
      </footer>

      {showOverlay && (
        <div
          data-qatag="overlay"
          className={styles.overlay}
          onClick={stopAnimation}
        ></div>
      )}
      {showVerticalIndicator && (
        <img
          data-qatag="vIndicator"
          className={`${styles.vIndicator} ${styles.verticalBounce}`}
          style={getVIndicatorInitialPosition(currentBrowser)}
          src={verticalIndicator}
          alt=""
        />
      )}
      {showHorizontalIndicator && (
        <img
          data-qatag="hIndicator"
          className={`${styles.hIndicator} ${styles.horizontalBounce}`}
          style={getHIndicatorInitialPosition(currentBrowser)}
          src={horizontalIndicator}
          alt=""
        />
      )}
    </div>
  );
};

export default DownloadLander;

interface IStep {
  order: number;
  title: string;
  image: string;
  description: string;
}

const Step: React.FC<IStep> = ({ order, title, image, description }) => (
  <div data-qatag="step" className={styles.step}>
    <header data-qatag="stepHeader" className={styles.stepHeader}>
      <div data-qatag="stepOrder" className={styles.stepOrder}>
        {order}
      </div>
      <div data-qatag="stepTitle" className={styles.stepTitle}>
        {title}
      </div>
    </header>
    <img
      data-qatag="stepImage"
      className={styles.stepImage}
      src={image}
      alt=""
    />
    <div data-qatag="stepDescription" className={styles.stepDescription}>
      {description}
    </div>
  </div>
);

function getFirstStepImage(browser: string): string {
  switch (browser) {
    case "firefox":
      return firefoxStep01;
    case "edge":
      return edgeStep01;
    case "chrome":
    default:
      return chromeStep01;
  }
}

function getFirstStepDescription(browser: string): string {
  switch (browser) {
    case "firefox":
      return 'Click "Save File" to download the software.';
    case "edge":
      return 'Click "SolveiQBusiness.exe" at the top right of your screen.';
    case "chrome":
    default:
      return 'Click "SolveiQBusiness.exe" at the left bottom of your screen';
  }
}

function getCurrentBrowser(): string {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.indexOf("firefox") > -1) {
    return "firefox";
  }
  if (userAgent.indexOf("edg") > -1) {
    return "edge";
  }
  return "chrome";
}

function getVIndicatorInitialPosition(browser: string): CSSProperties {
  switch (browser) {
    case "chrome":
      return {
        bottom: 1,
        left: "50px",
      };
    default:
      return {};
  }
}

function getHIndicatorInitialPosition(browser: string): CSSProperties {
  switch (browser) {
    case "edge":
      return {
        top: "24px",
        right: 768,
      };
    default:
      return {};
  }
}
