import {
  Box,
  Dialog,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";
import congratsBanner from "assets/img/congrats/congratsBanner.svg";

export interface ICongratsModalProps {
  /* Controls to open the modal */
  isOpen: boolean;
  /* Callback to update the isOpen on the parent*/
  handleOnClose: () => void;
}

/* Custom style for Dialog */
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: "784px",
    width: "100%",
    maxHeight: "640px",
    margin: 12,
  },
  buttonText: {
    fontSize: "16px",
    lineHeight: 1,
  },
  titleText: {
    color: "#535A6A",
    fontSize: "28px",
    fontWeight: 600,
    paddingRight: "20%",
    paddingLeft: "20%",

    [theme.breakpoints.down("sm")]: {
      fontSize: "21px",
      paddingRight: "5%",
      paddingLeft: "5%",
    },
  },
  subTitleText: {
    fontSize: "16px",
    color: "#757E95",
    paddingRight: "20%",
    paddingLeft: "20%",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "5%",
      paddingLeft: "5%",
    },
  },
  congratsBanner: {
    maxWidth: "290px",
    maxHeight: "250px",
    width: "70%",
    flexGrow: 1,
  },
  metricText: {
    color: theme.palette.secondary.light,
    width: 120,
    textAlign: "right",
    flexBasis: "40%",
  },
  metricContainer: {
    flexBasis: "100%",
    marginRight: 8,
  },
  metric: {
    flexBasis: "100%",
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    justifyContent: "center",
  },
  metricDescription: {
    color: "#757E95",
    wordWrap: "break-word",
  },
  locationText: {
    color: "#757E95",
    wordWrap: "break-word",
    maxWidth: "85%",
    margin: "auto",
  },
  container: {
    borderRadius: "14px",
    height: "640px",
    backgroundColor: "#fff",
    outline: 0,
    [theme.breakpoints.down("sm")]: {
      height: "600px",
    },
  },
  content: {
    margin: "24px 16px 56px 16px",
  },
  closeIconContainer: {
    width: "100%",
    height: "24px",
    display: "flex",
    justifyContent: "flex-end",
  },
  closeIcon: {
    minWidth: "50px",
    minHeight: "50px",
  },
  customButton: {
    width: "300px",
    outline: "none",
    height: "50px",

    [theme.breakpoints.down("sm")]: {
      width: "272px",
    },
  },
}));

const CongratsModal: React.FC<ICongratsModalProps> = ({
  handleOnClose,
  isOpen = true,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      data-qatag="modalDetails"
      open={isOpen}
      onClose={handleOnClose}
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <div data-qatag="container" className={classes.container}>
        <div data-qatag="content" className={classes.content}>
          {/* Close Icon */}
          <div
            data-qatag="closeIconContainer"
            className={classes.closeIconContainer}
          >
            <IconButton
              data-qatag="closeIcon"
              aria-label="close"
              onClick={handleOnClose}
              className={classes.closeIcon}
            >
              <CloseIcon data-qatag="CloseIcon" />
            </IconButton>
          </div>

          {/* Title */}
          <Box data-qatag="TitleBox" textAlign="center">
            <img
              data-qatag="titleIcon"
              className={classes.congratsBanner}
              src={congratsBanner}
              alt="wire-frame"
            />
            <Box data-qatag="titleTextBox" mt={2}>
              <FormattedMessage
                data-qatag="congratsModal.title"
                id="congratsModal.title"
                defaultMessage="Congratulations! Your PC has now been fully optimized!"
              >
                {(text) => (
                  <Typography
                    data-qatag="titleText"
                    variant="h6"
                    className={classes.titleText}
                    color="primary"
                  >
                    {text}
                  </Typography>
                )}
              </FormattedMessage>
            </Box>
            <Box data-qatag="subTitleTextBox" mt={2}>
              <FormattedMessage
                data-qatag="congratsModal.subTitle"
                id="congratsModal.subTitle"
                defaultMessage="We'll continue to monitor your system to provide driver updates, notifcations, and optimization improvements."
              >
                {(text) => (
                  <Typography
                    data-qatag="titleText"
                    variant="h6"
                    className={classes.subTitleText}
                    color="secondary"
                  >
                    {text}
                  </Typography>
                )}
              </FormattedMessage>
            </Box>
          </Box>

          {/* Button */}
          <Box
            data-qatag="closeButtonBox"
            width={1}
            textAlign="center"
            mt={6.5}
          >
            <AppButton
              data-qatag="closeButton"
              buttonStyle={AppButtonStyles.Green}
              className={classes.customButton}
              onClick={handleOnClose}
            >
              <FormattedMessage
                data-qatag="congratsModal.buttonText"
                id="congratsModal.buttonText"
                defaultMessage="See Your System Summary"
              >
                {(text) => (
                  <Typography
                    data-qatag="buttonText"
                    align="center"
                    className={classes.buttonText}
                    variant="body2"
                  >
                    {text}
                  </Typography>
                )}
              </FormattedMessage>
            </AppButton>
          </Box>
        </div>
      </div>
    </Dialog>
  );
};

export default CongratsModal;
