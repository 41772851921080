import { Box } from "@material-ui/core";
import NoAgentIllustration from "assets/img/icn-teams-emptystate.svg";
import styles from "./EmptyStateUser.module.css";

export interface INoAgentAvailable {
  /* Error Message */
  title?: string;
  /* Suggestion for the error */
  suggestion?: string;
}

const EmptyStateUser: React.FC<INoAgentAvailable> = ({
  title = "No Results Found",
  suggestion = "Sorry, we couldn’t find the user you are looking for.",
}) => {
  return (
    <div data-qatag="container" className={styles.container}>
      <Box data-qatag="imageContainer" mt={1} textAlign="center">
        <img
          data-qatag="icon"
          className={styles.icon}
          src={NoAgentIllustration}
          alt=""
        />
      </Box>
      <Box data-qatag="errorContainer" mt={3} className={styles.title}>
        {title}
      </Box>
      <Box
        data-qatag="suggestion"
        mt={0.5}
        textAlign="center"
        className={styles.suggestion}
      >
        {suggestion}
      </Box>
    </div>
  );
};

export default EmptyStateUser;
