import { RootState } from "app/store";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { OnOffSwitch, Panel, TabPanel, Tabs } from "solveiq.designsystem";
import styles from "./DeceptorProtection.module.css";
import headerImage from "assets/img/threeGears.svg";
import deceptorImage from "assets/img/deceptor/icn-deceptors.svg";
import deceptorFoundImage from "assets/img/deceptor/icn-deceptors-found.svg";
import allowedImage from "assets/img/deceptor/icn-no-deceptors-found.svg";
import {
  Box,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  fetchAllowedSoftware,
  fetchDeceptorsBlocked,
  fetchDeceptorsFound,
  IDeceptorInfo,
  selectAllowedSoftware,
  selectDeceptorsBlocked,
  selectDeceptorsFound,
  selectStatus as selectDeceptorStatus,
  setCurrentDeceptor,
} from "./DeceptorProtectionSlice";
import { ReducerStatus, IThunkStatus } from "model/IReducerState";
import { DeceptorTab } from "./DeceptorTab";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import {
  selectCurrentUser,
  selectIsRemote,
  selectUserDescriptor,
} from "session/SessionSlice";
import { RouterBreadcrumbs } from "features/teamManagement/employeeDetail/RouterBreadcrumbs";
import { IUserSettingModel } from "model/messaging/messages/AppSettingsMessages";
import {
  fetchUserSettings,
  selectSettings,
  selectStatus as selectSettingsStatus,
  updateSetting,
  updateUserSetting,
} from "features/settings/SettingsSlice";
import { UserSettingTypes } from "core/enumerations/UserSettingTypes";

/* Custom Styles */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    boxShadow: "none",
    color: theme.palette.text.secondary,
    backgroundColor: "white",
  },

  tabs: {
    backgroundColor: "white",
    flexBasis: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 22,
    },
    [theme.breakpoints.up("sm")]: {
      order: 0,
      flexGrow: 1,
      flexBasis: "33.33333%",
    },
  },

  logo: {
    order: -1,
    flex: "8%",
    height: 48,
    [theme.breakpoints.down("sm")]: {
      flex: "none",
    },
    [theme.breakpoints.down("md")]: {
      flex: "10%",
    },
  },

  actions: {
    order: 1,
    flex: "25%",
    height: 48,
    [theme.breakpoints.down("sm")]: {
      order: -1,
      flex: "25%",
    },
    [theme.breakpoints.down("md")]: {
      order: -1,
    },
    [theme.breakpoints.up("md")]: {
      flex: "25%",
    },
  },

  logoText: {
    color: theme.palette.secondary.light,
    marginLeft: 16,
  },

  searchIcon: {
    color: theme.palette.info.light,
  },

  textField: {
    width: 100,
    marginRight: 4,
    [theme.breakpoints.down("sm")]: {
      width: 70,
    },
    [theme.breakpoints.up("sm")]: {
      width: 281,
    },
  },

  dividerBottom: {
    order: 2,
    [theme.breakpoints.down("md")]: {
      marginTop: 16,
    },
  },

  tabPanel: {
    order: 2,
    flex: "100%",
  },
}));

export interface IDeceptorProtectionProps {
  blockedSelector?: (state: RootState) => IDeceptorInfo[];
  foundSelector?: (state: RootState) => IDeceptorInfo[];
  allowedSelector?: (state: RootState) => IDeceptorInfo[];
  settingsSelector?: (state: RootState) => IUserSettingModel[] | null;
  settingsStatusSelector?: (state: RootState) => IThunkStatus;
  deceptorStatusSelector?: (state: RootState) => IThunkStatus;
}

export const DeceptorProtection: React.FC<IDeceptorProtectionProps> = ({
  blockedSelector = selectDeceptorsBlocked,
  foundSelector = selectDeceptorsFound,
  allowedSelector = selectAllowedSoftware,
  settingsSelector = selectSettings,
  settingsStatusSelector = selectSettingsStatus,
  deceptorStatusSelector = selectDeceptorStatus,
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const prefix = "deceptorProtection.view";
  const classes = useStyles();
  const history = useHistory();

  const blocked = useSelector(blockedSelector);
  const found = useSelector(foundSelector);
  const allowed = useSelector(allowedSelector);
  const settings = useSelector(settingsSelector);
  const isRemote = useSelector(selectIsRemote);
  const userDescriptor = useSelector(selectUserDescriptor);
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    dispatch(fetchDeceptorsBlocked());
    dispatch(fetchDeceptorsFound());
    dispatch(fetchAllowedSoftware());
    dispatch(fetchUserSettings());
  }, [dispatch]);

  const protectionEnabled = settings?.find(
    (x) => x.SettingType === UserSettingTypes.EnableDeceptorServices
  )?.SettingValue;

  const onSwitchClick = async (
    e: React.MouseEvent<HTMLElement>,
    isOn: boolean
  ) => {
    dispatch(
      updateUserSetting({
        UpdatedSettings: [
          {
            SettingType: UserSettingTypes.EnableDeceptorServices,
            SettingTypeName:
              UserSettingTypes[UserSettingTypes.EnableDeceptorServices],
            NewSettingValue: isOn,
            CurrentSettingValue: protectionEnabled,
          },
        ],
      })
    );
    dispatch(
      updateSetting({
        SettingType: UserSettingTypes.EnableDeceptorServices,
        SettingValue: isOn,
      })
    );
  };

  const tabs = useMemo(
    () => [
      formatMessage({
        id: `${prefix}.blockedTab.tabText`,
        defaultMessage: "Deceptors Blocked/Found",
      }),
      /*formatMessage({
        id: `${prefix}.foundTab.tabText`,
        defaultMessage: "Deceptors Found",
      }),*/
      formatMessage({
        id: `${prefix}.allowedTab.tabText`,
        defaultMessage: "Allowed Deceptors",
      }),
    ],
    [formatMessage]
  );
  const [tabNumber, setTabNumber] = useState(0);
  const [searchText, setSearchText] = useState("");

  const filterByName = (items: IDeceptorInfo[], searchText: string) => {
    return items.filter((x) =>
      x.Name.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const filteredBlocked = useMemo(() => filterByName(blocked, searchText), [
    blocked,
    searchText,
    filterByName,
  ]);
  const filteredFound = useMemo(() => filterByName(found, searchText), [
    found,
    searchText,
  ]);
  const filteredAllowed = useMemo(() => filterByName(allowed, searchText), [
    allowed,
    searchText,
  ]);

  const onDetailsClick = (deceptor: IDeceptorInfo) => {
    dispatch(setCurrentDeceptor(deceptor));
    history.push(
      `/deceptorprotection/deceptordetails?id=${encodeURI(deceptor.Id)}`
    );
  };

  return (
    <div data-qatag="deceptorProtection" className={styles.deceptorProtection}>
      {isRemote ? (
        <RouterBreadcrumbs
          data-qatag="deceptorProtection.screen.remoteBreadcrumbs"
          style={{ width: "100%", height: "42px", alignSelf: "center" }}
          descriptors={[
            {
              to: `/employeeDetail/${user?.id}`,
              label: userDescriptor,
            },
            {
              to: "/",
              label: "Deceptor Protection",
            },
          ]}
        ></RouterBreadcrumbs>
      ) : (
        <Breadcrumbs
          data-qatag="deceptorProtectionBreadcrumbs"
          pathsMap={{ deceptorprotection: "Deceptor Protection" }}
        />
      )}

      <Panel data-qatag="header" className={styles.header}>
        <img
          data-qatag="headerImage"
          src={headerImage}
          className={styles.headerImage}
          alt=""
        />
        <div
          data-qatag="headerTextContainer"
          className={styles.headerTextContainer}
        >
          <Typography
            data-qatag="headerTitle"
            className={styles.headerTitle}
            variant="h4"
          >
            <FormattedMessage
              data-qatag="headerTitleMessage"
              id={`${prefix}.header.title`}
              defaultMessage="Deceptive App Protection"
            />
          </Typography>
          <Typography
            data-qatag="headerDescription"
            className={styles.headerDescription}
            variant="body2"
          >
            <FormattedMessage
              data-qatag="headerDescriptionMessage"
              id={`${prefix}.header.description`}
              defaultMessage="This service continuously prevents deceptive apps from stealing your data or impacting productivity."
            />
          </Typography>
        </div>
        {protectionEnabled != null && (
          <OnOffSwitch
            data-qatag="switch"
            className={styles.switch}
            onClick={onSwitchClick}
            isDisabled={false}
            isOnInitially={protectionEnabled}
          />
        )}
      </Panel>
      <Panel data-qatag="body" className={styles.body}>
        <Tabs
          data-qatag="tabs"
          tabs={tabs}
          onChange={setTabNumber}
          defaultValue={tabNumber}
          customClasses={{
            root: classes.root,
            tabs: classes.tabs,
          }}
          className={classes.tabs}
        >
          {/* Logo section */}
          <Box
            data-qatag="logo"
            className={classes.logo}
            display="flex"
            alignItems="center"
          >
            <img
              data-qatag="logoImage"
              src={deceptorImage}
              className={styles.logoImage}
              alt=""
            />
            <Typography
              data-qatag="logoText"
              className={classes.logoText}
              variant="h6"
            >
              <FormattedMessage
                data-qatag=""
                id={`${prefix}.body.title`}
                defaultMessage="Deceptors"
              />
            </Typography>
          </Box>

          {/* Actions Sections */}
          <Box
            data-qatag="actions"
            className={classes.actions}
            display="flex"
            alignItems="center"
          >
            <IconButton
              data-qatag="closeIcon"
              aria-label="close"
              className={styles.closeIcon}
            >
              <SearchIcon
                data-qatag="searchIcon"
                className={classes.searchIcon}
              />
            </IconButton>
            <TextField
              data-qatag="standard-basic"
              id="standard-basic"
              onChange={(e) => setSearchText(e.target.value)}
              className={classes.textField}
              label={formatMessage({
                id: `${prefix}.search`,
                defaultMessage: "Search",
              })}
            />
          </Box>

          {/* Divider */}
          <Box
            data-qatag="dividerBottom"
            width="100%"
            className={`${classes.dividerBottom} ${styles.dividerBottom}`}
          >
            <Divider data-qatag="deceptorProtectionBodyDivider" />
          </Box>
          <TabPanel
            data-qatag="tabPanelDeceptorsBlocked"
            value={tabNumber}
            index={0}
            className={`${classes.tabPanel} ${styles.tabPanel}`}
          >
            <DeceptorTab
              data-qatag="deceptorsBlockedTab"
              itemImage={deceptorImage}
              deceptors={filteredBlocked.concat(filteredFound)}
              showLastBlocked={true}
              emptyTitle={
                searchText == ""
                  ? formatMessage({
                      id: `${prefix}.blockedTab.emptyTitle`,
                      defaultMessage: "No Deceptors found on your PC.",
                    })
                  : formatMessage({
                      id: `${prefix}.emptySearchTitle`,
                      defaultMessage: "No results were found.",
                    })
              }
              emptyDescription={
                searchText == ""
                  ? formatMessage({
                      id: `${prefix}.blockedTab.emptyDescription`,
                      defaultMessage: "Blocked Deceptors will show up here.",
                    })
                  : formatMessage({
                      id: `${prefix}.emptySearchDescription`,
                      defaultMessage: "Your search did not return any results.",
                    })
              }
              onDetailsClick={onDetailsClick}
              description={
                <FormattedMessage
                  data-qatag="deceptorsBlockedTabDescriptionMessage"
                  id={`${prefix}.blockedTab.description`}
                  defaultMessage="These are the deceptors that have been {blocked} on your pc."
                  values={{
                    blocked: (
                      <span data-qatag="emphasis" className={styles.emphasis}>
                        <FormattedMessage
                          data-qatag="deceptorsBlockedTabDescriptionBlockedMessage"
                          id={`${prefix}.blockedTab.description.blocked`}
                          defaultMessage="blocked or found"
                        />
                      </span>
                    ),
                  }}
                />
              }
            />
          </TabPanel>
          {/*<TabPanel
            data-qatag="tabPanelDeceptorsFound"
            value={tabNumber}
            index={1}
            className={`${classes.tabPanel} ${styles.tabPanel}`}
          >
            <DeceptorTab
              data-qatag="deceptorsFoundTab"
              itemImage={deceptorFoundImage}
              deceptors={filteredFound}
              showLastBlocked={false}
              emptyTitle={
                searchText == ""
                  ? formatMessage({
                      id: `${prefix}.foundTab.emptyTitle`,
                      defaultMessage: "No Deceptors found on your PC.",
                    })
                  : formatMessage({
                      id: `${prefix}.emptySearchTitle`,
                      defaultMessage: "No results were found.",
                    })
              }
              emptyDescription={
                searchText == ""
                  ? formatMessage({
                      id: `${prefix}.foundTab.emptyDescription`,
                      defaultMessage: "Found Deceptors will show up here.",
                    })
                  : formatMessage({
                      id: `${prefix}.emptySearchDescription`,
                      defaultMessage: "Your search did not return any results.",
                    })
              }
              onDetailsClick={onDetailsClick}
              description={
                <FormattedMessage
                  data-qatag="deceptorsFoundTabDescriptionMessage"
                  id={`${prefix}.foundTab.description`}
                  defaultMessage="These are the deceptors that have been {found} on your pc."
                  values={{
                    found: (
                      <span data-qatag="emphasis" className={styles.emphasis}>
                        <FormattedMessage
                          data-qatag="deceptorsFoundTabDescriptionFoundMessage"
                          id={`${prefix}.foundTab.description.found`}
                          defaultMessage="found"
                        />
                      </span>
                    ),
                  }}
                />
              }
            />
            </TabPanel>*/}
          <TabPanel
            data-qatag="tabPanelAllowedSoftware"
            value={tabNumber}
            index={1}
            className={`${classes.tabPanel} ${styles.tabPanel}`}
          >
            <DeceptorTab
              data-qatag="allowedSoftwareTab"
              itemImage={allowedImage}
              deceptors={filteredAllowed}
              showLastBlocked={false}
              emptyTitle={
                searchText == ""
                  ? formatMessage({
                      id: `${prefix}.allowedTab.emptyTitle`,
                      defaultMessage: "No allowed deceptors found on your PC.",
                    })
                  : formatMessage({
                      id: `${prefix}.emptySearchTitle`,
                      defaultMessage: "No results were found.",
                    })
              }
              emptyDescription={
                searchText == ""
                  ? formatMessage({
                      id: `${prefix}.allowedTab.emptyDescription`,
                      defaultMessage: "Allowed deceptors will show up here.",
                    })
                  : formatMessage({
                      id: `${prefix}.emptySearchDescription`,
                      defaultMessage: "Your search did not return any results.",
                    })
              }
              onDetailsClick={onDetailsClick}
              description={
                <FormattedMessage
                  data-qatag="allowedSoftwareTabDescriptionMessage"
                  id={`${prefix}.allowedTab.description`}
                  defaultMessage="These are the deceptors that have been {allowed} on your pc."
                  values={{
                    allowed: (
                      <span data-qatag="emphasis" className={styles.emphasis}>
                        <FormattedMessage
                          data-qatag="deceptorsAllowedTabDescriptionAllowedMessage"
                          id={`${prefix}.allowedTab.description.allowed`}
                          defaultMessage="allowed"
                        />
                      </span>
                    ),
                  }}
                />
              }
            />
          </TabPanel>
        </Tabs>
      </Panel>
    </div>
  );
};
