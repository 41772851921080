import { Typography, Box } from "@material-ui/core";
import {
  AppButton,
  AppButtonStyles,
  Alert,
  AlertIcon,
  Spinner,
} from "solveiq.designsystem";
import { makeStyles, Theme } from "@material-ui/core/styles";
import shieldIcon from "assets/icon/shield-opt1.svg";
import styles from "./CardForm.module.css";
import { ReducerStatus } from "model/IReducerState";
import { RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { getPostStatus, openPayPalTab, resetPost } from "./CheckoutSlice";

/* Custom style for Dialog */
const useStyles = makeStyles((theme: Theme) => ({
  buttonText: {
    lineHeight: 1,
  },
}));

export interface IPayPalForm {
  /* Status to generate PayPal URL */
  postStatusSelector?: (state: RootState) => ReducerStatus;
  /* Callback to update the isOpen on the parent*/
  handleOnClose: () => void;
}

const PayPalForm: React.FC<IPayPalForm> = ({
  /* Status to generate PayPal URL */
  postStatusSelector = (state: RootState) => getPostStatus(state),
  handleOnClose,
}) => {
  // Custom styles
  const classes = useStyles();

  const dispatch = useDispatch();

  /* Request PayPal URL status */
  const postStatus = useSelector(postStatusSelector);

  return (
    <div data-qatag="fieldContainer" className={styles.fieldContainer}>
      <Box data-qatag="PayPalImageContainer" mt={12} textAlign="center">
        <img
          data-qatag="shieldIcon"
          className={styles.shieldIcon}
          src={shieldIcon}
          alt="PayPal"
        />
      </Box>

      <Box data-qatag="messagePayPal" textAlign="center" marginX="auto" mt={2}>
        You will be redirected to PayPal to complete your register securely.
      </Box>

      <Box data-qatag="buttonContainer" width={1} textAlign="center" mt={10}>
        <AppButton
          data-qatag="button"
          buttonStyle={AppButtonStyles.Green}
          className={`${styles.customButton}`}
          onClick={() => {
            dispatch(openPayPalTab());
          }}
        >
          <Typography
            data-qatag="buttonText"
            align="center"
            className={classes.buttonText}
            variant="body2"
          >
            Continue To PayPal
          </Typography>
        </AppButton>
      </Box>
      {/* Loading modal */}
      <Spinner
        data-qatag="loadingModal"
        text="Opening a PayPal Tab..."
        isActive={ReducerStatus[postStatus] === ReducerStatus.Loading}
      />
      {/* Alert error */}
      <Alert
        data-qatag="alertError"
        icon={AlertIcon.Warning}
        title="Error"
        text="Something went wrong"
        buttonText="Continue"
        approveHandler={() => {
          dispatch(resetPost());
        }}
        isOpen={ReducerStatus[postStatus] === ReducerStatus.Failed}
        closeHandler={() => {
          dispatch(resetPost());
        }}
      />
      {/* Success Alert */}
      <Alert
        data-qatag="successAlert"
        icon={AlertIcon.Success}
        title="Complete"
        text="A new tab should pop up"
        buttonText="Close"
        approveHandler={() => {
          dispatch(resetPost());
          handleOnClose();
        }}
        isOpen={ReducerStatus[postStatus] === ReducerStatus.Succeeded}
        closeHandler={() => {
          dispatch(resetPost());
          handleOnClose();
        }}
      />
    </div>
  );
};

export default PayPalForm;
