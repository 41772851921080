import SeatManagement from "features/Billing/SeatManagement";
import { Dashboard } from "features/dashboard/Dashboard";
import { DeceptorDetails } from "features/deceptorProtection/DeceptorDetails";
import { DeceptorProtection } from "features/deceptorProtection/DeceptorProtection";
import { DnsProtection } from "features/dnsProtection/DnsProtection";
import { DriverInstallScreen } from "features/driverInstall/DriverInstallScreen";
import { FileCleaning } from "features/fileCleaning/FileCleaning";
import Optimization from "features/Optimization";
import ScanScreen from "features/scan/ScanScreen";
import MachinePicker from "features/MachinePicker";
import { StatusScreen } from "features/status/Status";
import { Team } from "features/teamManagement/Team";
import WindowsEnhancements from "features/windowsEnhancements/WindowsEnhancements";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { DownloadPage } from "features/download/downloadPage";
import { SettingsScreen } from "features/settings/SettingsScreen";
import Billing from "features/Billing";
import { HelpScreen } from "features/help/HelpScreen";
import UpdatePaymentMethod from "features/Billing/EditPaymentMethod";
import { Logout } from "./Logout";
import { EmployeeDetail } from "features/teamManagement/employeeDetail/EmployeeDetail";
import TicketsScreen from "features/tickets/TicketsScreen";
import TicketDetails from "features/tickets/TicketDetails";
import DownloadLander from "features/downloadLander/DownloadLander";
import NoAgentAvailable from "./NoAgentAvailable/NoAgentAvailable";
// import MachineSelector from "features/machineSelector/MachineSelector";

interface IAppRouterProps {
  hasSignalRConnection: boolean;
  hasScan: boolean;
  hasActivatedAgent: boolean;
  isRemote: boolean;
  hasMachineIntelligence: boolean;
}

const AppRouter: React.FC<IAppRouterProps> = (props) => {
  return (
    <React.Fragment>
      {props.hasSignalRConnection &&
        getFullRoutes(
          props.hasScan,
          props.isRemote,
          props.hasMachineIntelligence
        )}
      {!props.hasSignalRConnection &&
        getNoAgentRoutes(props.hasActivatedAgent, props.isRemote)}
    </React.Fragment>
  );
};

function getFullRoutes(
  hasScan: boolean,
  isRemote: boolean,
  hasMachineIntelligence: boolean
): JSX.Element {
  return (
    <Switch data-qatag="innerSwitch">
      <Route data-qatag="machinePickerRoute" path="/machinepicker">
        <MachinePicker data-qatag="machinePicker" />
      </Route>
      {!hasMachineIntelligence && (
        <Redirect
          data-qatag="machinePicker.redirectRoute"
          to="/machinepicker"
        />
      )}
      <Route data-qatag="scanScreenRoute" path="/scan">
        <ScanScreen data-qatag="scanScreen" />
      </Route>
      {!hasScan && <Redirect data-qatag="redirectRoute" to="/scan" />}
      <Route data-qatag="teamRoute" path="/team">
        <Team data-qatag="team" />
      </Route>
      <Route data-qatag="dashboardRoute" path="/admin">
        <Dashboard data-qatag="adminRoute" />
      </Route>
      <Route data-qatag="optimizationRoute" path="/optimizations">
        <Optimization data-qatag="optimizations" />
      </Route>
      <Route
        data-qatag="deceptorDetailsRoute"
        path="/deceptorprotection/deceptordetails"
      >
        <DeceptorDetails data-qatag="deceptordetails" />
      </Route>
      <Route data-qatag="deceptorProtectionRoute" path="/deceptorprotection">
        <DeceptorProtection data-qatag="deceptorprotection" />
      </Route>
      <Route data-qatag="statusRoute" path="/status">
        <StatusScreen data-qatag="statusRoute" />
      </Route>
      <Route data-qatag="fileCleaningRoute" path="/filecleaning">
        <FileCleaning data-qatag="filecleaning" />
      </Route>
      <Route data-qatag="dnsProtectionRoute" path="/dnsprotection">
        <DnsProtection data-qatag="dnsprotection" />
      </Route>
      <Route data-qatag="windowsEnhancementsRoute" path="/windowsenhancements">
        <WindowsEnhancements data-qatag="windowsenhancements" />
      </Route>
      <Route data-qatag="driverInstallScreenRoute" path="/drivers">
        <DriverInstallScreen data-qatag="driverInstallScreen" />
      </Route>

      <Route data-qatag="helpRoute" path="/help">
        <HelpScreen data-qatag="help" />
      </Route>
      <Route exact data-qatag="ticketsRoute" path="/tickets">
        <TicketsScreen data-qatag="ticketsScreen" />
      </Route>
      <Route data-qatag="ticketDetailsRoute" path="/tickets/:ticketId">
        <TicketDetails data-qatag="ticketDetails" />
      </Route>
      {/* <Route data-qatag="machineSelectorRoute" path="/machineselector">
        <MachineSelector data-qatag="" />
      </Route> */}
      <Route data-qatag="seatManagementRoute" path="/seats">
        <SeatManagement data-qatag="seatManagement" />
      </Route>
      <Route data-qatag="settingsRoute" path="/settings">
        <SettingsScreen data-qatag="settings" />
      </Route>
      <Route data-qatag="billingRoute" path="/billing">
        <Billing data-qatag="billing" />
      </Route>
      <Route data-qatag="logoutRoute" path="/logout">
        <Logout data-qatag="logout" />
      </Route>
      <Route
        data-qatag="updatePaymentMethodRoute"
        path="/paymentMethod/edit/:id"
      >
        <UpdatePaymentMethod data-qatag="UpdatePaymentMethod" />
      </Route>
      <Route data-qatag="employeeDetailRoute" path="/employeeDetail/:id">
        {!isRemote ? (
          // this guards against the case that someone trys to directly navigate to
          // an employee detail page. the most likely case is someone refreshing while
          // on an employee detail page.
          <Redirect data-qatag="appRoute.employeeDetail.redirect" to="/team" />
        ) : (
          <EmployeeDetail data-qatag="employeeDetail" />
        )}
      </Route>
    </Switch>
  );
}

function getNoAgentRoutes(
  hasActivatedAgent: boolean,
  isRemote: boolean
): JSX.Element {
  return (
    <Switch data-qatag="innerSwitch">
      <Route data-qatag="downloadRoute" path="/download">
        <DownloadPage data-qatag="downloadPage" />
      </Route>
      <Route data-qatag="downloadLander" path="/lander">
        <DownloadLander data-qatag="downloadLander" />
      </Route>
      {!hasActivatedAgent && <Redirect data-qatag="download" to="/download" />}
      <Route data-qatag="teamRoute" path="/team">
        <Team data-qatag="teamView" />
      </Route>
      <Route data-qatag="logoutRoute" path="/logout">
        <Logout data-qatag="logout" />
      </Route>
      <Route
        data-qatag="updatePaymentMethodRoute"
        path="/paymentMethod/edit/:id"
      >
        <UpdatePaymentMethod data-qatag="UpdatePaymentMethod" />
      </Route>
      <Route data-qatag="employeeDetailRoute" path="/employeeDetail/:id">
        {!isRemote ? (
          // this guards against the case that someone trys to directly navigate to
          // an employee detail page. the most likely case is someone refreshing while
          // on an employee detail page.
          <Redirect data-qatag="appRoute.employeeDetail.redirect" to="/team" />
        ) : (
          <EmployeeDetail data-qatag="employeeDetail" />
        )}
      </Route>
      <Route data-qatag="billingRoute" path="/billing">
        <Billing data-qatag="billing" />
      </Route>
      <Route data-qatag="seatManagementRoute" path="/seats">
        <SeatManagement data-qatag="seatManagement" />
      </Route>
      <Route data-qatag="helpRoute" path="/help">
        <HelpScreen data-qatag="help" />
      </Route>
      <Route exact data-qatag="ticketsRoute" path="/tickets">
        <TicketsScreen data-qatag="ticketsScreen" />
      </Route>
      <Route data-qatag="ticketDetailsRoute" path="/tickets/:ticketId">
        <TicketDetails data-qatag="ticketDetails" />
      </Route>
      <Route data-qatag="noAgentAvailableRoute">
        <NoAgentAvailable data-qatag="noAgentAvailable" />
      </Route>
    </Switch>
  );
}

export default AppRouter;
