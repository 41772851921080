import {
  Box,
  makeStyles,
  createStyles,
  Breadcrumbs,
  Link,
  LinkProps,
  Typography,
  Paper,
} from "@material-ui/core";
import React, { CSSProperties } from "react";
import { Link as RouterLink } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

//#region Component Styles
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(3),
    },
    paper: {
      height: "inherit",
      width: "inherit",
      display: "flex",
      alignItems: "center",
      borderRadius: "8px",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  })
);
//#endregion Component Styles

export type BreadcrumbDescriptor = {
  to: string;
  label: string;
};

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <Link data-qatag="hh" {...props} component={RouterLink as any} />
);

export interface IRouterBreadcrumbsProps {
  descriptors: BreadcrumbDescriptor[];
  style?: CSSProperties;
}

//#region Component
export const RouterBreadcrumbs: React.FC<IRouterBreadcrumbsProps> = (props) => {
  const classes = useStyles();

  return (
    <Box
      data-qatag="routerBreadcrumbs.root"
      id="routerBreadcrumbs.root"
      className={classes.root}
      style={props.style}
    >
      <Paper data-qatag="paper" elevation={0} className={classes.paper}>
        <Breadcrumbs
          data-qatag="routerBreadcrumbs.muiBreadcrumb"
          separator={
            <NavigateNextIcon
              data-qatag="routerBreadcrumbs.navIcon"
              fontSize="small"
              style={{ color: "#D6D6D6" }}
            />
          }
        >
          {props.descriptors.map((descriptor, index) => {
            if (index === props.descriptors.length - 1) {
              return (
                <Typography
                  data-qatag="routerBreadcrumbs.breadcrumb.readonly"
                  id="routerBreadcrumbs.breadcrumb.readonly"
                  variant="body1"
                  color="textPrimary"
                  style={{ fontWeight: 600 }}
                >
                  {descriptor.label}
                </Typography>
              );
            }
            return (
              <LinkRouter
                data-qatag="routerBreadcrumbs.breadcrumb.link"
                key="asdf"
                to={descriptor.to}
                variant="body1"
                color="textPrimary"
              >
                {descriptor.label}
              </LinkRouter>
            );
          })}
        </Breadcrumbs>
      </Paper>
    </Box>
  );
};
//#endregion Component
