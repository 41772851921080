import React from "react";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "solveiq.designsystem";
import styles from "./DeceptorTab.module.css";
import emptyImage from "assets/img/deceptor/icn-no-deceptors-found.svg";
import { Typography } from "@material-ui/core";
import { IDeceptorInfo } from "./DeceptorProtectionSlice";

export interface IDeceptorTabProps {
  itemImage: string;
  description: JSX.Element;
  deceptors: IDeceptorInfo[];
  showLastBlocked: boolean;
  emptyTitle: string;
  emptyDescription: string;
  onDetailsClick: (deceptor: IDeceptorInfo) => void;
}

export const DeceptorTab: React.FC<IDeceptorTabProps> = ({
  itemImage,
  description,
  deceptors,
  showLastBlocked,
  emptyTitle,
  emptyDescription,
  onDetailsClick,
}) => {
  const prefix = "deceptorProtection.view";
  return (
    <div
      data-qatag="tabContentContainer"
      className={styles.tabContentContainer}
    >
      {deceptors.length === 0 && (
        <div
          data-qatag="emptyTabContent"
          className={`${styles.tabContent} ${styles.emptyContent}`}
        >
          <img
            data-qatag="emptyImage"
            src={emptyImage}
            alt=""
            className={styles.emptyImage}
          />
          <Typography
            data-qatag="emptyTitle"
            className={styles.emptyTitle}
            variant="h5"
          >
            {emptyTitle}
          </Typography>
          <Typography
            data-qatag="emptyDescription"
            className={styles.emptyDescription}
            variant="body1"
          >
            {emptyDescription}
          </Typography>
        </div>
      )}
      {deceptors.length > 0 && (
        <div data-qatag="tabContentWithDeceptors" className={styles.tabContent}>
          <span data-qatag="tabDescription" className={styles.tabDescription}>
            {description}
          </span>
          <div
            data-qatag="deceptorsContainer"
            className={styles.deceptorsContainer}
          >
            {deceptors.map((deceptor: IDeceptorInfo, index: number) => {
              return (
                <div
                  data-qatag="deceptorEntryContainer"
                  className={styles.deceptorEntryContainer}
                  key={index}
                >
                  <div
                    data-qatag="deceptorEntry"
                    className={`${styles.deceptorEntry} ${
                      showLastBlocked ? styles.showLastBlocked : ""
                    }`}
                  >
                    <img
                      data-qatag="deceptorEntryImage"
                      src={itemImage}
                      className={styles.deceptorEntryImage}
                      alt=""
                    />
                    <span
                      data-qatag="deceptorEntryTitle"
                      className={styles.deceptorEntryTitle}
                    >
                      {deceptor.Name}
                    </span>
                    {showLastBlocked && deceptor.BlockDate != null && (
                      <span
                        data-qatag="lastBlocked"
                        className={styles.lastBlocked}
                      >
                        <FormattedMessage
                          data-qatag="deceptorsBlockedEntryLastBlockedMessage"
                          id={`${prefix}.blockedTab.lastBlocked`}
                          defaultMessage="Last Blocked: "
                        />
                        <span data-qatag="">{deceptor.BlockDate}</span>
                      </span>
                    )}
                    <AppButton
                      data-qatag="deceptorsBlockedEntryDetailsButton"
                      buttonStyle={AppButtonStyles.Green}
                      className={styles.viewDetailsButton}
                      onClick={() => {
                        onDetailsClick(deceptor);
                      }}
                    >
                      <FormattedMessage
                        data-qatag="deceptorsBlockedEntryDetailsButtonMessage"
                        id={`${prefix}.viewDetails`}
                        defaultMessage="View Details"
                      />
                    </AppButton>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
