import { FormattedMessage } from "react-intl";
import { Typography, Box, TextField } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  TeamMemberTableRow,
  ITeamMemberTableRowProps,
} from "./TeamMemberTableRow";
import { Alert, AlertIcon, Spinner } from "solveiq.designsystem";
import styles from "./TeamMemberTable.module.css";
import teamIcon from "assets/img/peopleIcon.svg";
import searchIcon from "assets/img/magnifyingGlassIcon.svg";
import { useState } from "react";
import EmptyStateUser from "./EmptyStateUser/EmptyStateUser";
import {
  statusDeleteUser,
  setDeleteStatus,
  // Resend invite request
  getResendStatus,
  resetResendInvite,
} from "./TeamSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReducerStatus } from "model/IReducerState";
import { fetchOrganizationUsers } from "model/user/UserSlice";

export interface ITeamMemberTableProps {
  teamMembers: ITeamMemberTableRowProps[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 600,
    },
    textField: {
      width: 120,
      marginTop: -16,
    },
  })
);

export const TeamMemberTable: React.FC<ITeamMemberTableProps> = ({
  ...props
}) => {
  /* Localization prefix */
  const prefix = "team.member.table";
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const dispatch = useDispatch();

  // Filtering text
  const [searchText, setSearchText] = useState("");

  const filteredUsers = props.teamMembers
    .filter((teamMember) => teamMember?.enabled)
    .filter(
      (teamMember) =>
        teamMember?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
        teamMember?.email?.toLowerCase()?.includes(searchText?.toLowerCase())
    );

  /* Request Status */
  const deleteStatus = useSelector(statusDeleteUser);

  // Resend invite status
  const resendInviteStatus = useSelector(getResendStatus);

  return (
    <div
      data-qatag="teamMemberTableContainer"
      className={styles.teamMemberTableContainer}
    >
      <div
        data-qatag="teamMemberTableControls"
        className={styles.teamMemberTableControls}
      >
        <Box
          data-qatag="teamMemberTableControlsTeam"
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={styles.teamMemberTableControlsTeam}
        >
          <img data-qatag="teamIcon" src={teamIcon} alt="" />

          <b data-qatag="boldTextTeam">
            <FormattedMessage
              data-qatag="teamLengthMessage"
              id={`${prefix}.length`}
              values={{
                length: props.teamMembers?.filter((user) => user.enabled)
                  .length,
              }}
            />
          </b>
        </Box>
        <div
          data-qatag="teamMemberTableControlsSpacer"
          className={styles.teamMemberTableControlsSpacer}
        ></div>
        <Box data-qatag="teamMemberTableBox" display="flex" alignItems="center">
          <img data-qatag="searchIcon" src={searchIcon} alt="" />
          <Typography
            data-qatag="teamMemberTableSearchTypography"
            variant="body1"
            className={classes.bold}
          >
            <TextField
              data-qatag="standard-basic"
              id="standard-basic"
              onChange={(e) => setSearchText(e.target.value)}
              className={classes.textField}
              label={
                <FormattedMessage
                  data-qatag="teamMemberTableSearchMessage"
                  id={`${prefix}.search`}
                />
              }
            />
          </Typography>
        </Box>
      </div>
      <Typography
        data-qatag="teamMemberTableTypography"
        variant="body2"
        align="left"
      >
        <table data-qatag="teamMemberTable" className={styles.teamMemberTable}>
          <thead data-qatag="teamMemberTableThead">
            <tr data-qatag="teamMemberTableTr">
              <th data-qatag="teamMemberTableTh">
                <FormattedMessage
                  data-qatag="teamMemberTableMemberMessage"
                  id={`${prefix}.team.member`}
                />
              </th>
              <th
                data-qatag="teamMemberTableTh"
                className={styles.computerColumn}
              >
                Computer
              </th>
              <th
                data-qatag="teamMemberTableTh"
                className={styles.statusColumn}
              >
                <FormattedMessage
                  data-qatag="teamMemberTableStatusMessage"
                  id={`${prefix}.status`}
                />
              </th>
              <th data-qatag="teamMemberTableTh">Remove</th>
              <th
                data-qatag="teamMemberTableTh"
                className={styles.actionsColumns}
              >
                <FormattedMessage
                  data-qatag="teamMemberTableActionsMessage"
                  id={`${prefix}.actions`}
                />
              </th>
            </tr>
          </thead>
          <tbody data-qatag="teamMemberTableTbody">
            {filteredUsers
              ?.sort((a, b) => {
                if (b.attributes?.toLowerCase()?.includes("admin")) return 1;
                return -1; //default return value (no sorting)
              })
              ?.map((member, i) => {
                return (
                  <TeamMemberTableRow
                    data-qatag="teamMemberTableRow"
                    {...member}
                    key={"teamMember" + i}
                  />
                );
              })}
          </tbody>
        </table>
        {filteredUsers.length === 0 ? (
          <EmptyStateUser data-qatag="emptyStateUser" />
        ) : (
          ""
        )}
      </Typography>

      {/* Spinner global */}
      <Spinner
        data-qatag="loading"
        isActive={
          deleteStatus === ReducerStatus[ReducerStatus.Loading] ||
          resendInviteStatus === ReducerStatus[ReducerStatus.Loading]
        }
        text={
          deleteStatus === ReducerStatus[ReducerStatus.Loading]
            ? "Deleting user..."
            : resendInviteStatus === ReducerStatus[ReducerStatus.Loading]
            ? "Sending invite..."
            : "Loading..."
        }
      />

      {/* Remove alert global */}
      <Alert
        icon={AlertIcon.Success}
        title="Completed"
        buttonText="Close"
        text={
          deleteStatus === ReducerStatus[ReducerStatus.Succeeded]
            ? "The user was deleted"
            : resendInviteStatus === ReducerStatus[ReducerStatus.Succeeded]
            ? "If you still didn't receive the email, make sure that you've typed your email correctly and check your junk/spam folders."
            : "The process was complete"
        }
        data-qatag="alertSuccess"
        isOpen={
          deleteStatus === ReducerStatus[ReducerStatus.Succeeded] ||
          resendInviteStatus === ReducerStatus[ReducerStatus.Succeeded]
        }
        approveHandler={() => {
          if (deleteStatus === ReducerStatus[ReducerStatus.Succeeded]) {
            dispatch(setDeleteStatus());
            dispatch(fetchOrganizationUsers());
          }
          if (resendInviteStatus === ReducerStatus[ReducerStatus.Succeeded]) {
            dispatch(resetResendInvite());
          }
        }}
        closeHandler={() => {
          if (deleteStatus === ReducerStatus[ReducerStatus.Succeeded]) {
            dispatch(setDeleteStatus());
            dispatch(fetchOrganizationUsers());
          }
          if (resendInviteStatus === ReducerStatus[ReducerStatus.Succeeded]) {
            dispatch(resetResendInvite());
          }
        }}
      />
    </div>
  );
};
