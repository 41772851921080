import { Box, Typography, Divider } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import styles from "./TheWho.module.css";
import { Spinner } from "solveiq.designsystem";
import { ReducerStatus, IThunkStatus } from "model/IReducerState";
import { RootState } from "app/store";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import warningIcon from "assets/img/cancelFlow/icons-warning.svg";
import deviceIcon from "assets/img/cancelFlow/icn-device.svg";
import {
  fetchOrganizationUsers,
  selectFetchUsersStatus,
  fetchUsersMachine,
  selectUsers,
} from "model/user/UserSlice";
import {
  fetchOrganizationLicenses,
  selectStatus as selectLicenseStatus,
} from "model/license/LicenseSlice";
import IUser from "model/user/IUser";
import defaultImage from "assets/img/team/avatar-default.svg";

export interface ITheWho {
  usersSelector?: (state: RootState) => IUser[];
  fetchUsersStatusSelector?: (state: RootState) => ReducerStatus;
  licensesStatusSelector?: (state: RootState) => IThunkStatus;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      color: theme.palette.secondary.light,
      fontWeight: 600,
    },
    buttonText: {
      lineHeight: 1,
    },
  })
);

const TheWho: React.FC<ITheWho> = ({
  usersSelector = (state: RootState) => selectUsers(state),
  fetchUsersStatusSelector = (state: RootState) =>
    selectFetchUsersStatus(state),
  licensesStatusSelector = (state) => selectLicenseStatus(state),
}) => {
  /* Custom styles to overwrite theme */
  const classes = useStyles();

  const dispatch = useDispatch();
  const usersStatus = useSelector(fetchUsersStatusSelector);
  const licensesStatus = useSelector(licensesStatusSelector);

  /* User list */
  const users = useSelector(usersSelector);

  useEffect(() => {
    if (usersStatus === ReducerStatus.Idle) {
      dispatch(fetchOrganizationUsers());
    }

    if (
      licensesStatus[fetchOrganizationLicenses.typePrefix] ===
      ReducerStatus.Idle
    ) {
      dispatch(fetchOrganizationLicenses());
    }

    // If licenses Status is success we can retrieve the online status of the users
    if (
      licensesStatus[fetchOrganizationLicenses.typePrefix] ===
      ReducerStatus.Succeeded
    ) {
      dispatch(fetchUsersMachine());
    }
  }, [usersStatus, dispatch, licensesStatus]);

  return (
    <>
      {/* Covered Team Members */}

      <Box
        data-qatag="featuresContainer"
        mt={4}
        className={styles.coveredTeamMembers}
        ml="auto"
        mr="auto"
      >
        <Box
          data-qatag="logoContainer"
          width={1}
          textAlign="center"
          display="flex"
          justifyContent="center"
        >
          <img data-qatag="warningIcon" src={warningIcon} alt="cancel" />
          <Typography
            data-qatag="titleText"
            variant="h4"
            className={`${classes.titleText} ${styles.coverMembersText}`}
          >
            Currently Covered Team Members
          </Typography>
        </Box>
        <Box data-qatag="logoContainer" width={1} mt={1.5} textAlign="center">
          <Typography data-qatag="titleText" variant="body2">
            The following are tied to your subscription and will no longer be
            covered if you cancel.
          </Typography>
        </Box>
        <Box
          data-qatag="coverMembers"
          width={1}
          mt={3.5}
          className={styles.userBoxContainer}
        >
          {/* Render users */}
          {users
            ?.filter((user) => user.enabled)
            ?.map((user) => (
              <Box
                data-qatag="userBox"
                key={user.email}
                className={styles.userBox}
              >
                <Box data-qatag="image" width={1} textAlign="center">
                  <img data-qatag="userImage" src={defaultImage} alt="" />
                </Box>
                <Box
                  data-qatag="image"
                  width={1}
                  textAlign="center"
                  mt={1}
                  className={styles.name}
                >
                  {`${user.firstName} ${user.lastName}`}
                </Box>
                {/* Divider */}
                <Box
                  data-qatag="dividerBottom"
                  width="70%"
                  mt={2}
                  ml="auto"
                  mr="auto"
                >
                  <Divider data-qatag="divider" />
                </Box>
                {/* Computer Device */}
                <Box
                  data-qatag="logoContainer"
                  width={1}
                  mt={2}
                  textAlign="center"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img data-qatag="deviceIcon" src={deviceIcon} alt="device" />
                  <Typography
                    data-qatag="titleText"
                    variant="body2"
                    className={styles.coverMembersText}
                  >
                    {user?.machineDisplayName
                      ? user?.machineDisplayName
                      : "Loading..."}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>

      {/* Loading modal */}
      <Spinner
        data-qatag="loadingModal"
        isActive={ReducerStatus[usersStatus] === ReducerStatus.Loading}
        text="Loading..."
      />
    </>
  );
};

export default TheWho;
