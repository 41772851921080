import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { RootState } from "app/store";
import { UserSettingTypes } from "core/enumerations/UserSettingTypes";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import {
  IUpdateUserSettingValueMessage,
  IUserSettingModel,
  IUserSettingsMessage,
  ProfileState,
} from "model/messaging/messages/AppSettingsMessages";
import { IServiceMessage, ServiceMessage, WSMessageType } from "ui.common";
import { selectCurrentUuid } from "session/SessionSlice";

interface ISettingsState {
  settings: IUserSettingModel[] | null;
}

export const fetchUserSettings = createAsyncThunk<
  IUserSettingsMessage | null,
  void,
  { state: RootState }
>("userSettings/fetchUserSettings", async (_, thunkApi) => {
  const srhub = getSignalRHub();
  const message: IServiceMessage = new ServiceMessage();
  message.MessageType = WSMessageType.GET_USER_SETTINGS;
  const response = await srhub.SendAsync(message);
  return response.Payload as IUserSettingsMessage;
});

export const updateUserSetting = createAsyncThunk<
  void,
  IUpdateUserSettingValueMessage,
  { state: RootState }
>(
  "userSettings/updateUserSetting",
  async (data: IUpdateUserSettingValueMessage) => {
    const srhub = getSignalRHub();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.UPDATE_USER_SETTING_VALUE;
    message.Payload = data;
    await srhub.SendAsync(message);
  }
);

const initialState: IReducerState<ISettingsState> = {
  data: {
    settings: null,
  },
  status: {
    [fetchUserSettings.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateSetting: (state, action: PayloadAction<IUserSettingModel>) => {
      const newSettings = state.data.settings?.map((obj) =>
        obj.SettingType === action.payload.SettingType
          ? { ...obj, SettingValue: action.payload.SettingValue }
          : obj
      );
      if (newSettings) {
        state.data.settings = newSettings;
      }
      state.status[fetchUserSettings.typePrefix] = ReducerStatus.Idle;
    },
    resetStatus: (state) => {
      state.status[fetchUserSettings.typePrefix] = ReducerStatus.Idle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserSettings.pending, (state) => {
      state.status[fetchUserSettings.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(fetchUserSettings.fulfilled, (state, action) => {
      state.status[fetchUserSettings.typePrefix] = ReducerStatus.Succeeded;
      state.data.settings = InitUserSettings(action.payload);
    });
    builder.addCase(fetchUserSettings.rejected, (state) => {
      state.status[fetchUserSettings.typePrefix] = ReducerStatus.Failed;
    });
  },
});

export const { updateSetting, resetStatus } = settingsSlice.actions;

export const selectGamingProfileSettingValue = (state: RootState) => {
  return state.settings.data.settings?.find((setting) => {
    return setting.SettingType === UserSettingTypes.GamingState;
  })?.SettingValue as ProfileState;
};

export const selectBrowsingProfileSettingValue = (state: RootState) => {
  return state.settings.data.settings?.find((setting) => {
    return setting.SettingType === UserSettingTypes.BrowsingState;
  })?.SettingValue as ProfileState;
};

export const selectProductivityProfileSettingValue = (state: RootState) => {
  return state.settings.data.settings?.find((setting) => {
    return setting.SettingType === UserSettingTypes.ProductivityState;
  })?.SettingValue as ProfileState;
};

function InitUserSettings(
  userSettings: IUserSettingsMessage | null
): IUserSettingModel[] | null {
  try {
    if (userSettings === null) return null;
    const settings: IUserSettingModel[] = [];

    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.DownloadFolder,
        userSettings.DriverDownloadSettings.DownloadFolder
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.EnableAlerts,
        userSettings.NotificationSettings.EnableAlerts
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.IncludeSystemDevices,
        userSettings.ScanOptions.IncludeSystemDevices
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.IncludeUnpluggedDevices,
        userSettings.ScanOptions.IncludeUnpluggedDevices
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.MaximumWeeklyAlerts,
        userSettings.NotificationSettings.MaximumWeeklyAlerts
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.MaxSimultaneousDownloads,
        userSettings.DriverDownloadSettings.MaxSimultaneousDownloads
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.UseWindowsUpdateScanner,
        userSettings.ScanOptions.UseWindowsUpdateScanner
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.ScheduledScanPeriod,
        userSettings.ScheduledScanOptions.Period
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.ScheduledScanMonth,
        userSettings.ScheduledScanOptions.DayOfMonth
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.ScheduledScanDay,
        userSettings.ScheduledScanOptions.DayOfWeek
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.ScheduledScanCategories,
        userSettings.ScheduledScanOptions.Scans
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.ScheduledScanType,
        userSettings.ScheduledScanOptions.ScanType
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.ScheduledScanTime,
        userSettings.ScheduledScanOptions.ScanTime
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.EnableScheduledScans,
        userSettings.ScheduledScanOptions.Enabled
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.BrowsingState,
        userSettings.AOProfileSettings.BrowsingState
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.GamingState,
        userSettings.AOProfileSettings.GamingState
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.ProductivityState,
        userSettings.AOProfileSettings.ProductivityState
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.StartWithWindows,
        userSettings.StartWithWindows
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.EnableDeceptorServices,
        userSettings.EnableDeceptorServices
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.EnableDnsProtector,
        userSettings.DnsProtectorSettings.EnableDnsProtector
      )
    );

    return settings;
  } catch (ex) {
    //sentry
  }
  return null;
}

function BuildUserSettingModel(
  SettingType: UserSettingTypes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SettingValue: any
): IUserSettingModel {
  const returnModel = {
    SettingType,
    SettingValue,
  };
  return returnModel;
}

export const selectSettings = (state: RootState) => {
  return state.settings.data.settings;
};

export const selectStatus = (state: RootState) => {
  return state.settings.status;
};

// export const selectUserProfileSettings = (state: RootState) => {
//   return state.settings.data.settings?.filter(
//     (setting) =>
//       setting.SettingType === UserSettingTypes.BrowsingState ||
//       setting.SettingType === UserSettingTypes.GamingState ||
//       setting.SettingType === UserSettingTypes.ProductivityState
//   );
// };

export const selectIsAppOptimizationActive = (state: RootState) => {
  if (state.settings.data.settings == null) {
    return false;
  }

  const browsingSetting = state.settings.data.settings.find(
    (setting) => setting.SettingType === UserSettingTypes.BrowsingState
  );
  const productivitySetting = state.settings.data.settings.find(
    (setting) => setting.SettingType === UserSettingTypes.ProductivityState
  );
  const gamingSetting = state.settings.data.settings.find(
    (setting) => setting.SettingType === UserSettingTypes.GamingState
  );

  if (
    browsingSetting == null ||
    productivitySetting == null ||
    gamingSetting == null
  ) {
    return false;
  }

  // consider app optimizations active if all settings are true
  return (
    (browsingSetting.SettingValue as boolean) &&
    (productivitySetting.SettingValue as boolean) &&
    (gamingSetting.SettingValue as boolean)
  );
};

export const selectIsDnsProtectionActive = (state: RootState) => {
  if (state.settings.data.settings == null) {
    return false;
  }
  const deceptorSetting = state.settings.data.settings.find(
    (setting) => setting.SettingType === UserSettingTypes.EnableDnsProtector
  );

  if (deceptorSetting == null) {
    return false;
  }

  return deceptorSetting.SettingValue as boolean;
};

export const selectIsDecptorBlockingActive = (state: RootState) => {
  if (state.settings.data.settings == null) {
    return false;
  }
  const deceptorSetting = state.settings.data.settings.find(
    (setting) => setting.SettingType === UserSettingTypes.EnableDeceptorServices
  );

  if (deceptorSetting == null) {
    return false;
  }

  return deceptorSetting.SettingValue as boolean;
};

export default settingsSlice.reducer;

export const getSettingValueFromArray = (
  settings: IUserSettingModel[],
  settingType: UserSettingTypes
) => {
  return (
    settings.find((x) => x.SettingType === settingType)?.SettingValue ?? null
  );
};
