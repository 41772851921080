import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import icon from "assets/icon/icn-details-info-white.svg";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "50px",
      background: "#3D82F6",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    closeIcon: {
      color: "#FFFFFF",
    },
    contentContainer: {
      flexGrow: 1,
    },
    content: {
      color: "#FFFFFF",
    },
    userInfo: {
      color: "#FFFFFF",
      fontWeight: 600,
      flexGrow: 1,
    },
  })
);

export interface IRemoteBarProps {
  isVisible: boolean;
  userInfo: string;
  onDisconnect: () => void;
}

export const RemoteBar: React.FC<IRemoteBarProps> = (props) => {
  const classes = useStyles();

  if (!props.isVisible) {
    return null;
  }
  return (
    <Box
      data-qatag="remoteBar.root"
      id="remoteBar.root"
      className={classes.root}
    >
      <img
        data-qatag="remoteBar.infoIcon"
        id="remoteBar.infoIcon"
        src={icon}
        className={classes.icon}
        alt=""
      />
      <Box
        data-qatag="remoteBar.contentContainer"
        id="remoteBar.contentContainer"
        className={classes.contentContainer}
      >
        <FormattedMessage
          data-qatag="remoteBar.content"
          id="remoteBar.content"
          defaultMessage="Connected to {userInfo}"
          values={{
            userInfo: (
              <span data-qatag="userInfo" className={classes.userInfo}>
                {props.userInfo}
              </span>
            ),
          }}
        >
          {(text) => (
            <Typography
              data-qatag="remoteBar.content.text"
              id="remoteBar.content.text"
              variant="body2"
              className={classes.content}
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
      <FormattedMessage
        data-qatag="remoteBar.disconnect"
        id="remoteBar.disconnect"
        defaultMessage="Disconnect"
      >
        {(text) => (
          <Typography
            data-qatag="remoteBar.disconnect.text"
            id="remoteBar.disconnect.text"
            variant="body2"
            className={classes.content}
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <IconButton
        data-qatag="remoteBar.closeButton"
        id="remoteBar.closeButton"
        onClick={props.onDisconnect}
      >
        <Close
          data-qatag="remoteBar.closeIcon"
          id="remoteBar.closeIcon"
          className={classes.closeIcon}
        />
      </IconButton>
    </Box>
  );
};
