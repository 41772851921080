import { useIntl, FormattedMessage } from "react-intl";
import { Alert, AlertIcon } from "solveiq.designsystem";

export interface IAlertWarning {
  /* Callback to accept the alert */
  approveHandler: () => void;
  /* Controls to open the alert */
  isOpen: boolean;
  /* Callback to close the alert*/
  closeHandler: () => void;
}

const AlertWarning: React.FC<IAlertWarning> = ({
  closeHandler,
  approveHandler,
  isOpen,
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "alert.add.team.members.warning";

  return (
    <Alert
      data-qatag="alertWarning"
      icon={AlertIcon.Warning}
      title="Remove Payment method"
      text={"Are you sure to delete the payment method"}
      buttonText={formatMessage({
        id: `${prefix}.button`,
      })}
      approveHandler={approveHandler}
      isOpen={isOpen}
      closeHandler={closeHandler}
      cancelHandler={closeHandler}
      cancelButtonText={formatMessage({
        id: `${prefix}.cancel`,
      })}
    />
  );
};

export default AlertWarning;
