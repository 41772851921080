import { useCallback } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Typography } from "@material-ui/core";
import { Alert, AlertIcon } from "solveiq.designsystem";

export interface IAlertDeleteField {
  /* Callback to accept the alert */
  approveHandler: () => void;
  /* Controls to open the alert */
  isOpen: boolean;
  /* Callback to close the alert*/
  closeHandler: () => void;
}

const AlertDeleteField: React.FC<IAlertDeleteField> = ({
  closeHandler,
  approveHandler,
  isOpen,
}) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "alert.add.team.members.delete.field";

  return (
    <Alert
      data-qatag=""
      icon={AlertIcon.Warning}
      title={formatMessage({
        id: `${prefix}.remove`,
      })}
      text={formatMessage({
        id: `${prefix}.message`,
      })}
      buttonText={formatMessage({
        id: `${prefix}.button`,
      })}
      approveHandler={approveHandler}
      isOpen={isOpen}
      closeHandler={closeHandler}
      cancelHandler={closeHandler}
      cancelButtonText={formatMessage({
        id: `${prefix}.cancel`,
      })}
    />
  );
};

export default AlertDeleteField;
