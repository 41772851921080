export enum AOSettingValue {
  NotSet = 0,
  TcpWindowSize = 1,
  DefaultReceiveWindow = 2,
  DefaultSendWindow = 3,
  Tcp1323Opts = 4,
  EnableWsd = 5,
  TcpMaxDupAcks = 6,
  TcpMaxConnectRetransmissions = 7,
  TcpMaxDataRetransmissions = 8,
  SackOpts = 9,
  TcpAutotuning = 10,
  AutoTuningLevelLocal = 11,
  DisableWindowsScalingHeuristics = 12,
  AddOnCongestionControlProvider = 13,
  TcpChimneyOffload = 14,
  DirectCacheAccess = 15,
  ChecksumOffload = 16,
  ReceiveSideScalingState = 17,
  ReceiveSegmentCoalescingState = 18,
  LargeSendOffload = 19,
  EcnCapability = 20,
  TCP1323Timestamps = 21,
  InitialRTOandMinRTO = 22,
  NonSackRttResiliency = 23,
  MaxSynRetransmissions = 24,
  BandwidthReserveSetting = 25,
  QoS_DoNotUseNLA = 26,
  OptimizeMemoryConfiguration = 27,
  NetworkMemoryAllocation = 28,
  SystemResponsivenessGamingTweak = 29,
  NetworkThrottlingIndex = 30,
}

export interface ISystemOptimizationScanDocument {
  StartUpSettingsCount: number;
  WindowsSettingsCount: number;
  InternetSettingsCount: number;

  AvailableInternetSettingsCount: number;
  AvailableStartUpSettingsCount: number;
  AvailableWindowsSettingsCount: number;

  ActiveInternetSettingsCount: number;
  ActiveStartUpSettingsCount: number;
  ActiveWindowsSettingsCount: number;

  AllInternetSettings: IAOOptimizationSettingDocument[];
  AllStartUpSettings: IAOOptimizationSettingDocument[];
  AllSystemSettings: IAOOptimizationSettingDocument[];
  AllWindowsSettings: IAOOptimizationSettingDocument[];
  AvailableInternetSettings: IAOOptimizationSettingDocument[];
  AvailableStartUpSettings: IAOOptimizationSettingDocument[];
  AvailableWindowsSettings: IAOOptimizationSettingDocument[];
}

export function getNewSystemOptimizationScanDocument(): ISystemOptimizationScanDocument {
  return {
    StartUpSettingsCount: 0,
    WindowsSettingsCount: 0,
    InternetSettingsCount: 0,
    AvailableInternetSettingsCount: 0,
    AvailableStartUpSettingsCount: 0,
    AvailableWindowsSettingsCount: 0,
    ActiveInternetSettingsCount: 0,
    ActiveStartUpSettingsCount: 0,
    ActiveWindowsSettingsCount: 0,
    AllInternetSettings: [],
    AllStartUpSettings: [],
    AllSystemSettings: [],
    AllWindowsSettings: [],
    AvailableInternetSettings: [],
    AvailableStartUpSettings: [],
    AvailableWindowsSettings: [],
  };
}

export interface IAOOptimizationSetting {
  AOSettingsID: number;
  OSBitFlag: number;
  Name: string;
  IsActive: boolean;
  SettingValue: AOSettingValue;
  SettingTypeFlag: number;
  Description: string;
  SettingDescriptions: IAOOptimizationSettingDescription[];
}

export function getNewAOOptimizationSetting(): IAOOptimizationSetting {
  return {
    AOSettingsID: 0,
    OSBitFlag: 0,
    Name: "",
    IsActive: false,
    SettingValue: AOSettingValue.NotSet,
    SettingTypeFlag: 0,
    Description: "",
    SettingDescriptions: [],
  };
}

export interface IAOOptimizationSettingDocument {
  Setting: IAOOptimizationSetting;
  SettingState: string;
  IsAvailable: boolean;
  OriginalValue: string;
  CurrentValue: string;
  ProposedValue: string;
}

export function getNewAOOptimizationSettingDocument(): IAOOptimizationSettingDocument {
  return {
    Setting: getNewAOOptimizationSetting(),
    SettingState: "",
    IsAvailable: false,
    OriginalValue: "",
    CurrentValue: "",
    ProposedValue: "",
  };
}

export interface IAOOptimizationSettingDescription {
  Id: string;
  AOSettingsID: number;
  DisplayDescription: string;
  Culture: string;
}
